var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

import Immutable from "immutable";
import alt from "alt-instance";
import GatewayActions from "actions/GatewayActions";
import ls from "common/localStorage";

var STORAGE_KEY = "__graphene__";
var ss = new ls(STORAGE_KEY);

var GatewayStore = function () {
    function GatewayStore() {
        _classCallCheck(this, GatewayStore);

        this.backedCoins = Immutable.Map(ss.get("backedCoins", {}));
        this.bridgeCoins = Immutable.Map(Immutable.fromJS(ss.get("bridgeCoins", {})));
        /**
         * bridgeInputs limits the available depositable coins through
         * when using the "Buy" functionaility.
         *
         * While the application still makes sure the asset is possible to deposit,
         * this is to limit the app to display internal assets like bit-assets that
         * bt accept within their platform.
         */
        this.bridgeInputs = ["btc", "dash", "eth", "steem", "sbd", "doge", "bch", "ppy", "ltc"];
        this.down = Immutable.Map({});

        this.bindListeners({
            onFetchCoins: GatewayActions.fetchCoins,
            onFetchCoinsSimple: GatewayActions.fetchCoinsSimple,
            onFetchPairs: GatewayActions.fetchPairs
        });
    }

    _createClass(GatewayStore, [{
        key: "onFetchCoins",
        value: function onFetchCoins() {
            var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
                backer = _ref.backer,
                coins = _ref.coins,
                backedCoins = _ref.backedCoins,
                down = _ref.down;

            if (backer && coins) {
                this.backedCoins = this.backedCoins.set(backer, backedCoins);

                ss.set("backedCoins", this.backedCoins.toJS());

                this.down = this.down.set(backer, false);
            }

            if (down) {
                this.down = this.down.set(down, true);
            }
        }
    }, {
        key: "onFetchCoinsSimple",
        value: function onFetchCoinsSimple() {
            var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
                backer = _ref2.backer,
                coins = _ref2.coins,
                down = _ref2.down;

            if (backer && coins) {
                this.backedCoins = this.backedCoins.set(backer, coins);

                ss.set("backedCoins", this.backedCoins.toJS());

                this.down = this.down.set(backer, false);
            }

            if (down) {
                this.down = this.down.set(down, true);
            }
        }
    }, {
        key: "onFetchPairs",
        value: function onFetchPairs() {
            var _this = this;

            var _ref3 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
                coins = _ref3.coins,
                bridgeCoins = _ref3.bridgeCoins,
                wallets = _ref3.wallets,
                down = _ref3.down;

            if (coins && bridgeCoins && wallets) {
                var coins_by_type = {};
                coins.forEach(function (coin_type) {
                    return coins_by_type[coin_type.coinType] = coin_type;
                });
                bridgeCoins = bridgeCoins.filter(function (a) {
                    return a && coins_by_type[a.outputCoinType] && coins_by_type[a.outputCoinType].walletType === "bitshares2" && // Only use bitshares2 wallet types
                    _this.bridgeInputs.indexOf(a.inputCoinType) !== -1 // Only use coin types defined in bridgeInputs
                    ;
                }).forEach(function (coin) {
                    coin.isAvailable = wallets.indexOf(coins_by_type[coin.outputCoinType].walletType) !== -1;
                    _this.bridgeCoins = _this.bridgeCoins.setIn([coins_by_type[coin.outputCoinType].walletSymbol, coin.inputCoinType], Immutable.fromJS(coin));
                });
                ss.set("bridgeCoins", this.bridgeCoins.toJS());
            }
            if (down) {
                this.down = this.down.set(down, true);
            }
        }
    }]);

    return GatewayStore;
}();

export default alt.createStore(GatewayStore, "GatewayStore");