var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from "react";
import { connect } from "alt-react";
import AccountStore from "stores/AccountStore";
import SettingsStore from "stores/SettingsStore";
import AccountActions from "actions/AccountActions";
import SettingsActions from "actions/SettingsActions";
import counterpart from "counterpart";
import Translate from "react-translate-component";
import { Button, Modal, Icon, Popover, Tooltip } from "bitshares-ui-style-guide";

var AccountBrowsingMode = function (_React$Component) {
    _inherits(AccountBrowsingMode, _React$Component);

    function AccountBrowsingMode(props) {
        _classCallCheck(this, AccountBrowsingMode);

        var _this = _possibleConstructorReturn(this, (AccountBrowsingMode.__proto__ || Object.getPrototypeOf(AccountBrowsingMode)).call(this, props));

        _this.state = {
            previousAccountName: null,
            isModalVisible: false
        };

        _this.handleClose = _this.handleClose.bind(_this);
        _this.handleSwitchBack = _this.handleSwitchBack.bind(_this);
        _this.handleNeverShowAgain = _this.handleNeverShowAgain.bind(_this);
        return _this;
    }

    _createClass(AccountBrowsingMode, [{
        key: "componentDidUpdate",
        value: function componentDidUpdate(prevProps) {
            /* if user changed his account to not his own*/
            if (prevProps.currentAccount && this.props.currentAccount && this.props.currentAccount !== prevProps.currentAccount && !this.isMyAccount() && this.isMyAccount(prevProps.currentAccount)) {
                this.setState({
                    isModalVisible: this.props.viewOnlyMode !== false,
                    previousAccountName: prevProps.currentAccount
                });
            }
        }
    }, {
        key: "handleSwitchBack",
        value: function handleSwitchBack() {
            var myAccounts = AccountStore.getMyAccounts();

            var previousAccountName = this.state.previousAccountName;

            var switchToAccountName = null;

            if (this.isMyAccount(previousAccountName)) {
                switchToAccountName = previousAccountName;
            } else if (Array.isArray(myAccounts) && myAccounts.length) {
                switchToAccountName = myAccounts[0];
            }
            AccountActions.setCurrentAccount.defer(switchToAccountName);
        }
    }, {
        key: "isMyAccount",
        value: function isMyAccount(name) {
            var accountName = name ? name : this.props.currentAccount;

            var myAccounts = AccountStore.getMyAccounts();

            var isMyAccount = true;

            if (Array.isArray(myAccounts) && myAccounts.length && accountName) {
                isMyAccount = myAccounts.indexOf(accountName) >= 0;
            }

            return isMyAccount;
        }
    }, {
        key: "handleClose",
        value: function handleClose() {
            this.setState({
                isModalVisible: false
            });
        }
    }, {
        key: "handleNeverShowAgain",
        value: function handleNeverShowAgain() {
            this.handleClose();

            SettingsActions.changeSetting({
                setting: "viewOnlyMode",
                value: false
            });
        }
    }, {
        key: "render",
        value: function render() {
            var footer = [React.createElement(
                Button,
                { key: "ok", type: "primary", onClick: this.handleClose },
                counterpart.translate("modal.ok")
            ), React.createElement(
                Button,
                { key: "cancel", onClick: this.handleNeverShowAgain },
                counterpart.translate("account_browsing_mode.never_show_again")
            )];

            if (this.props.usernameViewIcon) {
                return window.innerWidth < 640 && !this.isMyAccount() ? React.createElement(
                    Popover,
                    {
                        content: React.createElement(Translate, { content: "account_browsing_mode.you_are_in_browsing_mode" }),
                        placement: "bottom"
                    },
                    React.createElement(Icon, {
                        style: { marginLeft: 10 },
                        className: "blue",
                        type: "eye",
                        onClick: this.handleSwitchBack
                    })
                ) : null;
            } else {
                return React.createElement(
                    "div",
                    { className: "account-browsing-mode" },
                    React.createElement(
                        Modal,
                        {
                            title: counterpart.translate("account_browsing_mode.modal_title"),
                            closable: false,
                            visible: this.state.isModalVisible,
                            footer: footer
                        },
                        counterpart.translate("account_browsing_mode.modal_description")
                    ),
                    !this.isMyAccount() ? React.createElement(
                        Tooltip,
                        {
                            placement: "bottom",
                            title: counterpart.translate("account_browsing_mode.you_are_in_browsing_mode")
                        },
                        React.createElement(
                            Button,
                            {
                                onClick: this.handleSwitchBack,
                                className: "hide-for-small-only account-browsing-mode--button"
                            },
                            counterpart.translate("account_browsing_mode.view_mode")
                        )
                    ) : null
                );
            }
        }
    }]);

    return AccountBrowsingMode;
}(React.Component);

export default connect(AccountBrowsingMode, {
    listenTo: function listenTo() {
        return [AccountStore, SettingsStore];
    },
    getProps: function getProps() {
        return {
            viewOnlyMode: SettingsStore.getState().settings.get("viewOnlyMode"),
            currentAccount: AccountStore.getState().currentAccount
        };
    }
});