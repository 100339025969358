import React from "react";
import Translate from "react-translate-component";
import TranslateWithLinks from "../../Utility/TranslateWithLinks";

var ShortObjectId = function ShortObjectId(_ref) {
    var objectId = _ref.objectId;

    if (typeof objectId === "string") {
        var parts = objectId.split(".");
        var length = parts.length;

        if (length > 0) return "#" + parts[length - 1];
    }
    return objectId;
};

export var ProposalUpdate = function ProposalUpdate(_ref2) {
    var op = _ref2.op,
        linkToAccount = _ref2.linkToAccount,
        fromComponent = _ref2.fromComponent;

    if (fromComponent === "proposed_operation") {
        return React.createElement(
            "span",
            null,
            React.createElement(Translate, {
                component: "span",
                content: "proposal.proposal_update"
            })
        );
    } else {
        var fields = ["active_approvals_to_add", "active_approvals_to_remove", "owner_approvals_to_add", "owner_approvals_to_remove", "key_approvals_to_add", "key_approvals_to_remove"];
        return React.createElement(
            "div",
            null,
            React.createElement(
                "span",
                null,
                React.createElement(TranslateWithLinks, {
                    string: "operation.proposal_update",
                    keys: [{
                        type: "account",
                        value: op[1].fee_paying_account,
                        arg: "account"
                    }, {
                        value: React.createElement(ShortObjectId, { objectId: op[1].proposal }),
                        arg: "proposal"
                    }]
                })
            ),
            React.createElement(
                "div",
                { className: "proposal-update" },
                fields.map(function (field) {
                    if (op[1][field].length) {
                        return React.createElement(
                            "div",
                            { key: field },
                            React.createElement(Translate, {
                                content: "proposal.updated." + field
                            }),
                            React.createElement(
                                "ul",
                                null,
                                op[1][field].map(function (value) {
                                    return React.createElement(
                                        "li",
                                        { key: value },
                                        field.startsWith("key") ? value : linkToAccount(value)
                                    );
                                })
                            )
                        );
                    } else return null;
                })
            )
        );
    }
};