var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from "react";
import QrReader from "react-qr-reader";
import counterpart from "counterpart";
import PropTypes from "prop-types";
import { Modal, Button, Icon } from "bitshares-ui-style-guide";

var QRScanner = function (_React$Component) {
    _inherits(QRScanner, _React$Component);

    function QRScanner(props) {
        _classCallCheck(this, QRScanner);

        var _this = _possibleConstructorReturn(this, (QRScanner.__proto__ || Object.getPrototypeOf(QRScanner)).call(this, props));

        _this.modalId = "qr_scanner_modal";
        _this.state = {
            visible: false
        };


        _this.retry = _this.retry.bind(_this);
        _this.submit = _this.submit.bind(_this);
        _this.handleClick = _this.handleClick.bind(_this);
        _this.handleClose = _this.handleClose.bind(_this);
        _this.onScanSuccess = _this.onScanSuccess.bind(_this);
        return _this;
    }

    _createClass(QRScanner, [{
        key: "handleClick",
        value: function handleClick() {
            this.setState({
                visible: true
            });
        }
    }, {
        key: "handleClose",
        value: function handleClose() {
            this.setState({
                visible: false
            });
        }
    }, {
        key: "isBitcoinAddress",
        value: function isBitcoinAddress(data) {
            return (/bitcoin:([a-zA-Z0-9]+)/.test(data)
            );
        }
    }, {
        key: "parseBitcoinAddress",
        value: function parseBitcoinAddress(str) {
            var address = str.match(/bitcoin:([a-zA-Z0-9]+)/);
            var amount = str.match(/amount=([0-9\.]+)/);
            return {
                address: address && address[1] || null,
                amount: amount && amount[1] || null
            };
        }
    }, {
        key: "onScanSuccess",
        value: function onScanSuccess(data) {
            if (this.isBitcoinAddress(data)) {
                var result = this.parseBitcoinAddress(data);
                if (result) {
                    this.setState({
                        address: result.address,
                        amount: result.amount
                    });
                }
            } else {
                this.setState({
                    address: data,
                    amount: null
                });
            }
        }
    }, {
        key: "retry",
        value: function retry() {
            this.setState({
                address: null,
                amount: null
            });
        }
    }, {
        key: "submit",
        value: function submit() {
            this.handleClose();
            if (typeof this.props.onSuccess === "function") {
                this.props.onSuccess({
                    address: this.state.address,
                    amount: this.state.amount
                });
            }
        }
    }, {
        key: "render",
        value: function render() {
            var _this2 = this;

            var handleError = function handleError(err) {
                if (typeof _this2.props.onError === "function") _this2.props.onError(err);
            };

            var handleScan = function handleScan(data) {
                if (data) {
                    _this2.onScanSuccess(data);
                }
            };

            return React.createElement(
                "div",
                { className: "qr-address-scanner" },
                React.createElement(Icon, {
                    type: "camera",
                    onClick: this.handleClick,
                    style: { fontSize: "24px", padding: 5 }
                }),
                React.createElement(
                    Modal,
                    {
                        visible: this.state.visible,
                        className: "qr-address-scanner-modal",
                        modalHeader: "global.scan_qr_code",
                        id: this.modalId,
                        overlay: true,
                        closable: false,
                        footer: !this.state.address ? React.createElement(
                            "div",
                            { style: { justifyContent: "center" } },
                            React.createElement(
                                Button,
                                { onClick: this.handleClose },
                                "Close"
                            )
                        ) : React.createElement(
                            "div",
                            { style: { justifyContent: "center" } },
                            [React.createElement(
                                Button,
                                { onClick: this.retry },
                                counterpart.translate("qr_address_scanner.retry")
                            ), React.createElement(
                                Button,
                                {
                                    type: "primary",
                                    onClick: this.submit
                                },
                                counterpart.translate("qr_address_scanner.use_address")
                            )]
                        ),
                        onCancel: this.handleClose
                    },
                    React.createElement(QrReader, {
                        delay: 100,
                        onError: handleError,
                        onScan: handleScan,
                        style: {
                            width: "calc(100% - 48px)",
                            margin: "0 24px"
                        }
                    }),
                    this.state.address && React.createElement(
                        "div",
                        null,
                        React.createElement(
                            "div",
                            { className: "qr-address-scanner-status" },
                            React.createElement(
                                "div",
                                { className: "qr-address-scanner-status-title" },
                                counterpart.translate("qr_address_scanner.address_found"),
                                ":"
                            ),
                            React.createElement(
                                "div",
                                { className: "qr-address-scanner-status-address" },
                                this.state.address
                            ),
                            this.state.amount && React.createElement(
                                "div",
                                { className: "qr-address-scanner-status-title" },
                                counterpart.translate("qr_address_scanner.amount")
                            ),
                            this.state.amount && React.createElement(
                                "div",
                                { className: "qr-address-scanner-status-amount" },
                                this.state.amount
                            )
                        )
                    )
                )
            );
        }
    }]);

    return QRScanner;
}(React.Component);

QRScanner.propTypes = {
    onSuccess: PropTypes.func,
    onError: PropTypes.func,
    label: PropTypes.string
};


export default QRScanner;