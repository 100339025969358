import _sanitize from "xss";
import asset_utils from "./asset_utils";

var numeral = require("numeral");
var id_regex = /\b\d+\.\d+\.(\d+)\b/;

import { ChainTypes } from "bitsharesjs";
var object_type = ChainTypes.object_type;


import { getAssetNamespaces, getAssetHideNamespaces } from "../../branding";

var Utils = {
    is_object_id: function is_object_id(obj_id) {
        if ("string" != typeof obj_id) return false;
        var match = id_regex.exec(obj_id);
        return match !== null && obj_id.split(".").length === 3;
    },

    is_object_type: function is_object_type(obj_id, type) {
        var prefix = object_type[type];
        if (!prefix || !obj_id) return null;
        prefix = "1." + prefix.toString();
        return obj_id.substring(0, prefix.length) === prefix;
    },

    get_asset_precision: function get_asset_precision(precision) {
        precision = precision.toJS ? precision.get("precision") : precision;
        return Math.pow(10, precision);
    },

    get_asset_amount: function get_asset_amount(amount, asset) {
        if (amount === 0) return amount;
        if (!amount) return null;
        return amount / this.get_asset_precision(asset.toJS ? asset.get("precision") : asset.precision);
    },

    convert_satoshi_to_typed: function convert_satoshi_to_typed(amount, asset) {
        if (amount === 0) return amount;
        if (!amount) return null;
        return amount / this.get_asset_precision(asset.toJS ? asset.get("precision") : asset.precision);
    },

    convert_typed_to_satoshi: function convert_typed_to_satoshi(amount, asset) {
        return amount * this.get_asset_precision(asset.toJS ? asset.get("precision") : asset.precision);
    },

    get_asset_price: function get_asset_price(quoteAmount, quoteAsset, baseAmount, baseAsset) {
        var inverted = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;

        if (!quoteAsset || !baseAsset) {
            return 1;
        }
        var price = this.get_asset_amount(quoteAmount, quoteAsset) / this.get_asset_amount(baseAmount, baseAsset);
        return inverted ? 1 / price : price;
    },

    format_volume: function format_volume(amount) {
        var precision = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 3;

        if (amount < 10000) {
            return this.format_number(amount, precision);
        } else if (amount < 1000000) {
            return (Math.round(amount / 10) / 100).toFixed(2) + "k";
        } else {
            return (Math.round(amount / 10000) / 100).toFixed(2) + "M";
        }
    },


    format_number: function format_number(number, decimals) {
        var trailing_zeros = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;

        if (isNaN(number) || !isFinite(number) || number === undefined || number === null) return "";
        var zeros = ".";
        for (var i = 0; i < decimals; i++) {
            zeros += "0";
        }
        var num = numeral(number).format("0,0" + zeros);
        if (num.indexOf(".") > 0 && !trailing_zeros) return num.replace(/0+$/, "").replace(/\.$/, "");
        return num;
    },

    format_asset: function format_asset(amount, asset, noSymbol) {
        var trailing_zeros = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;

        var symbol = void 0;
        var digits = 0;
        if (asset === undefined) return undefined;
        if ("symbol" in asset) {
            // console.log( "asset: ", asset )
            symbol = asset.symbol;
            digits = asset.precision;
        } else {
            // console.log( "asset: ", asset.toJS() )
            symbol = asset.get("symbol");
            digits = asset.get("precision");
        }
        var precision = this.get_asset_precision(digits);
        // console.log( "precision: ", precision )

        return "" + this.format_number(amount / precision, digits, trailing_zeros) + (!noSymbol ? " " + symbol : "");
    },

    price_text: function price_text(price, base, quote) {
        var maxDecimals = 8;
        var priceText = void 0;
        var quoteID = quote.toJS ? quote.get("id") : quote.id;
        var quotePrecision = quote.toJS ? quote.get("precision") : quote.precision;
        var baseID = base.toJS ? base.get("id") : base.id;
        var basePrecision = base.toJS ? base.get("precision") : base.precision;
        var fixedPrecisionAssets = {
            "1.3.113": 5, // bitCNY
            "1.3.121": 5 // bitUSD
        };
        if (quoteID === "1.3.0") {
            priceText = this.format_number(price, quotePrecision);
        } else if (baseID === "1.3.0") {
            priceText = this.format_number(price, Math.min(maxDecimals, quotePrecision + 2));
        } else if (fixedPrecisionAssets[quoteID]) {
            priceText = this.format_number(price, fixedPrecisionAssets[quoteID]);
        } else {
            priceText = this.format_number(price, Math.min(maxDecimals, Math.max(quotePrecision + basePrecision, 2)));
        }
        return priceText;
    },

    price_to_text: function price_to_text(price, base, quote) {
        var forcePrecision = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;

        if (typeof price !== "number" || !base || !quote) {
            return;
        }

        if (price === Infinity) {
            price = 0;
        }
        var priceText = void 0;

        if (forcePrecision) {
            priceText = this.format_number(price, forcePrecision);
        } else {
            priceText = this.price_text(price, base, quote);
        }
        var price_split = priceText.split(".");
        var int = price_split[0];
        var dec = price_split[1];
        var i = void 0;

        var zeros = 0;
        if (dec) {
            if (price > 1) {
                var l = dec.length;
                for (i = l - 1; i >= 0; i--) {
                    if (dec[i] !== "0") {
                        break;
                    }
                    zeros++;
                }
            } else {
                var _l = dec.length;
                for (i = 0; i < _l; i++) {
                    if (dec[i] !== "0") {
                        i--;
                        break;
                    }
                    zeros++;
                }
            }
        }

        var trailing = zeros ? dec.substr(Math.max(0, i + 1), dec.length) : null;

        if (trailing) {
            if (trailing.length === dec.length) {
                dec = null;
            } else if (trailing.length) {
                dec = dec.substr(0, i + 1);
            }
        }

        return {
            text: priceText,
            int: int,
            dec: dec,
            trailing: trailing,
            full: price
        };
    },

    check_market_stats: function check_market_stats() {
        var newStats = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : { close: {} };
        var oldStats = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : { close: {} };

        var statsChanged = newStats.volumeBase !== oldStats.volumeBase || !this.are_equal_shallow(newStats.close && newStats.close.base, oldStats.close && oldStats.close.base) || !this.are_equal_shallow(newStats.close && newStats.close.quote, oldStats.close && oldStats.close.quote);
        return statsChanged;
    },

    are_equal_shallow: function are_equal_shallow(a, b) {
        if (!a && b || a && !b) {
            return false;
        }
        if (Array.isArray(a) && Array.isArray(a)) {
            if (a.length > b.length) {
                return false;
            }
        }
        if (typeof a === "string" && typeof b === "string") {
            return a === b;
        } else if (typeof a === "string" && typeof b !== "string" || typeof a !== "string" && typeof b === "string") {
            return false;
        }

        if (a && a.toJS && b && b.toJS) return a === b;
        for (var key in a) {
            if (a.hasOwnProperty(key) && !(key in b) || a[key] !== b[key]) {
                return false;
            }
        }
        for (var key in b) {
            if (b.hasOwnProperty(key) && !(key in a) || a[key] !== b[key]) {
                return false;
            }
        }

        return true;
    },

    makeISODateString: function makeISODateString(date_str) {
        if (typeof date_str === "string" && !/Z$/.test(date_str)) {
            date_str += "Z";
        }
        return date_str;
    },


    format_date: function format_date(date_str) {
        date_str = this.makeISODateString(date_str);
        var date = new Date(date_str);
        return date.toLocaleDateString();
    },

    format_time: function format_time(time_str) {
        time_str = this.makeISODateString(time_str);
        var date = new Date(time_str);
        return date.toLocaleString();
    },

    limitByPrecision: function limitByPrecision(value, assetPrecision) {
        var valueString = value.toString();
        var splitString = valueString.split(".");
        if (splitString.length === 1 || splitString.length === 2 && splitString[1].length <= assetPrecision) {
            return valueString;
        } else {
            return splitString[0] + "." + splitString[1].substr(0, assetPrecision);
        }
    },

    convertPrice: function convertPrice(fromRate, toRate, fromID, toID) {
        if (!fromRate || !toRate) {
            return null;
        }
        // Handle case of input simply being a fromAsset and toAsset
        if (fromRate.toJS && this.is_object_type(fromRate.get("id"), "asset")) {
            fromID = fromRate.get("id");
            fromRate = fromRate.get("bitasset") ? asset_utils.extractRawFeedPrice(fromRate).toJS() : fromRate.getIn(["options", "core_exchange_rate"]).toJS();
        }

        if (toRate.toJS && this.is_object_type(toRate.get("id"), "asset")) {
            toID = toRate.get("id");
            toRate = toRate.get("bitasset") ? asset_utils.extractRawFeedPrice(toRate).toJS() : toRate.getIn(["options", "core_exchange_rate"]).toJS();
        }

        var fromRateQuoteID = fromRate.quote.asset_id;
        var toRateQuoteID = toRate.quote.asset_id;

        var fromRateQuoteAmount = void 0,
            fromRateBaseAmount = void 0;
        if (fromRateQuoteID === fromID) {
            fromRateQuoteAmount = fromRate.quote.amount;
            fromRateBaseAmount = fromRate.base.amount;
        } else {
            fromRateQuoteAmount = fromRate.base.amount;
            fromRateBaseAmount = fromRate.quote.amount;
        }

        var toRateQuoteAmount = void 0,
            toRateBaseAmount = void 0;
        if (toRateQuoteID === toID) {
            toRateQuoteAmount = toRate.quote.amount;
            toRateBaseAmount = toRate.base.amount;
        } else {
            toRateQuoteAmount = toRate.base.amount;
            toRateBaseAmount = toRate.quote.amount;
        }

        var baseRatio = void 0,
            finalQuoteAmount = void 0,
            finalBaseAmount = void 0;
        if (toRateBaseAmount > fromRateBaseAmount) {
            baseRatio = toRateBaseAmount / fromRateBaseAmount;
            finalQuoteAmount = fromRateQuoteAmount * baseRatio;
            finalBaseAmount = toRateQuoteAmount;
        } else {
            baseRatio = fromRateBaseAmount / toRateBaseAmount;
            finalQuoteAmount = fromRateQuoteAmount;
            finalBaseAmount = toRateQuoteAmount * baseRatio;
        }

        return {
            quote: {
                amount: finalQuoteAmount,
                asset_id: toID
            },
            base: {
                amount: finalBaseAmount,
                asset_id: fromID
            }
        };
    },

    convertValue: function convertValue(priceObject, amount, fromAsset, toAsset) {
        priceObject = priceObject.toJS ? priceObject.toJS() : priceObject;
        var quotePrecision = this.get_asset_precision(fromAsset.get("precision"));
        var basePrecision = this.get_asset_precision(toAsset.get("precision"));

        var assetPrice = this.get_asset_price(priceObject.quote.amount, fromAsset, priceObject.base.amount, toAsset);

        var eqValue = fromAsset.get("id") !== toAsset.get("id") ? basePrecision * (amount / quotePrecision) / assetPrice : amount;

        if (isNaN(eqValue) || !isFinite(eqValue)) {
            return null;
        }
        return eqValue;
    },

    sortText: function sortText(a, b) {
        var inverse = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

        if (a > b) {
            return inverse ? 1 : -1;
        } else if (a < b) {
            return inverse ? -1 : 1;
        } else {
            return 0;
        }
    },
    sortID: function sortID(a, b) {
        var inverse = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

        // inverse = false => low to high
        var intA = parseInt(a.split(".")[2], 10);
        var intB = parseInt(b.split(".")[2], 10);

        return inverse ? intB - intA : intA - intB;
    },
    calc_block_time: function calc_block_time(block_number, globalObject, dynGlobalObject) {
        var estimate = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;

        var block_interval = null;
        var head_block = null;
        var head_block_time = null;
        if (!estimate && (!globalObject || !dynGlobalObject)) {
            return null;
        }
        // estimate what is unknown, i.e. fix a block and assume interval and constant production with equal parameters
        if (!globalObject) {
            block_interval = 3;
        } else {
            block_interval = globalObject.get("parameters").get("block_interval");
        }
        if (!dynGlobalObject) {
            // mainnet estimation
            head_block = 37025190;
            head_block_time = new Date("2019-04-30T07:55:24Z");
        } else {
            head_block = dynGlobalObject.get("head_block_number");
            head_block_time = new Date(dynGlobalObject.get("time") + "Z");
        }
        var seconds_below = (head_block - block_number) * block_interval;
        return new Date(head_block_time - seconds_below * 1000);
    },
    get_translation_parts: function get_translation_parts(str) {
        var re = /{(.*?)}/g;
        return str.split(re);
    },
    get_percentage: function get_percentage(a, b) {
        return Math.round(a / b * 100) + "%";
    },
    replaceName: function replaceName(asset) {
        if (!asset) return { name: "", prefix: null, isBitAsset: false };
        var name = asset.get("symbol");
        var isBitAsset = asset.get("bitasset") && !asset.getIn(["bitasset", "is_prediction_market"]) && asset.get("issuer") === "1.2.0";

        var toReplace = getAssetNamespaces();
        var suffix = "";
        var i = void 0;
        for (i = 0; i < toReplace.length; i++) {
            if (name.indexOf(toReplace[i]) !== -1) {
                name = name.replace(toReplace[i], "") + suffix;
                break;
            }
        }

        var namespace = isBitAsset ? "bit" : toReplace[i];
        var prefix = null;
        if (!getAssetHideNamespaces().find(function (a) {
            return a.indexOf(namespace) !== -1;
        })) {
            prefix = namespace ? namespace.toLowerCase() : null;
        }

        return {
            name: name,
            prefix: prefix,
            isBitAsset: !!isBitAsset
        };
    },
    sanitize: function sanitize(string) {
        // sanitize with package
        string = _sanitize(string, {
            whiteList: [], // empty, means filter out all tags
            stripIgnoreTag: true // remove all tags instead of escaping
        });
        string = string.replace(/%3A/gi, ":"); // resolve to : to not break links
        string = string.replace(/javascript:/gi, "");
        string = string.replace(/vbscript:/gi, "");
        string = string.replace(/data:/gi, "");
        string = string.replace(/tcl:/gi, "");
        return string;
    },
    timeStringToGrapheneDate: function timeStringToGrapheneDate(time_string) {
        if (!time_string) return new Date("1970-01-01T00:00:00.000Z");
        if (!/Z$/.test(time_string)) {
            //does not end in Z
            // https://github.com/cryptonomex/graphene/issues/368
            time_string = time_string + "Z";
        }
        return new Date(time_string);
    },
    toFixedString: function toFixedString(x) {
        if (Math.abs(x) < 1.0) {
            var e = parseInt(x.toString().split("e-")[1]);
            if (e) {
                x *= Math.pow(10, e - 1);
                x = "0." + new Array(e).join("0") + x.toString().substring(2);
                if (x === "0.00000007000000000000001") {
                    x = "0.00000007";
                }
                if (x[10] === "9") {
                    var ten = x.substr(2, 8) * 1 + 1;
                    x = x.substr(0, 8) + ten;
                }
            } else {
                x = x.toString();
            }

            /*
            if (x.length < 10 && x.length > 8) {
                while (x.length < 10) {
                    x = x + "0";
                }
            }
             */
        } else {
            var _e = parseInt(x.toString().split("+")[1]);
            if (_e > 20) {
                _e -= 20;
                x /= Math.pow(10, _e);
                x += new Array(_e + 1).join("0");
            }
        }

        return x;
    }
};

export default Utils;