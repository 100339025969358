var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from "react";
import { connect } from "alt-react";
import counterpart from "counterpart";
import AccountStore from "stores/AccountStore";
import { Link } from "react-router-dom";
import Translate from "react-translate-component";
import TranslateWithLinks from "./Utility/TranslateWithLinks";
import { isIncognito } from "feature_detect";
import SettingsActions from "actions/SettingsActions";
import WalletUnlockActions from "actions/WalletUnlockActions";
import SettingsStore from "stores/SettingsStore";
import IntlActions from "actions/IntlActions";
import CreateAccount from "./Account/CreateAccount";
import CreateAccountPassword from "./Account/CreateAccountPassword";
import { Route } from "react-router-dom";
import { getWalletName, getLogo } from "branding";
import { Select, Row, Col, Icon } from "bitshares-ui-style-guide";
var logo = getLogo();

var FlagImage = function FlagImage(_ref) {
    var flag = _ref.flag,
        _ref$width = _ref.width,
        width = _ref$width === undefined ? 50 : _ref$width,
        _ref$height = _ref.height,
        height = _ref$height === undefined ? 50 : _ref$height;

    return React.createElement("img", {
        height: height,
        width: width,
        src: __BASE_URL__ + "language-dropdown/" + flag.toUpperCase() + ".png"
    });
};

var LoginSelector = function (_React$Component) {
    _inherits(LoginSelector, _React$Component);

    function LoginSelector(props) {
        _classCallCheck(this, LoginSelector);

        var _this = _possibleConstructorReturn(this, (LoginSelector.__proto__ || Object.getPrototypeOf(LoginSelector)).call(this, props));

        _this.state = {
            step: 1,
            locales: SettingsStore.getState().defaults.locale,
            currentLocale: SettingsStore.getState().settings.get("locale")
        };
        _this.unmounted = false;

        _this.handleLanguageSelect = _this.handleLanguageSelect.bind(_this);
        return _this;
    }

    // componentDidUpdate() {
    // const myAccounts = AccountStore.getMyAccounts();

    // use ChildCount to make sure user is on /create-account page except /create-account/*
    // to prevent redirect when user just registered and need to make backup of wallet or password
    // const childCount = React.Children.count(this.props.children);

    // do redirect to portfolio if user already logged in
    // if (
    //     this.props.history &&
    //     Array.isArray(myAccounts) &&
    //     myAccounts.length !== 0 &&
    //     childCount === 0
    // )
    //     this.props.history.push("/account/" + this.props.currentAccount);
    // }

    _createClass(LoginSelector, [{
        key: "componentWillMount",
        value: function componentWillMount() {
            var _this2 = this;

            isIncognito(function (incognito) {
                if (!_this2.unmounted) {
                    _this2.setState({ incognito: incognito });
                }
            });
        }
    }, {
        key: "componentWillUnmount",
        value: function componentWillUnmount() {
            this.unmounted = true;
        }
    }, {
        key: "onSelect",
        value: function onSelect(route) {
            this.props.history.push("/create-account/" + route);
        }
    }, {
        key: "handleLanguageSelect",
        value: function handleLanguageSelect(locale) {
            IntlActions.switchLocale(locale);
            this.setState({
                currentLocale: locale
            });
        }
    }, {
        key: "languagesFilter",
        value: function languagesFilter(input, option) {
            return option.props.language.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }
    }, {
        key: "render",
        value: function render() {
            var translator = require("counterpart");

            var flagDropdown = React.createElement(
                Select,
                {
                    showSearch: true,
                    filterOption: this.languagesFilter,
                    value: this.state.currentLocale,
                    onChange: this.handleLanguageSelect,
                    style: { width: "123px", marginBottom: "16px" }
                },
                this.state.locales.map(function (locale) {
                    return React.createElement(
                        Select.Option,
                        {
                            key: locale,
                            language: counterpart.translate("languages." + locale)
                        },
                        counterpart.translate("languages." + locale)
                    );
                })
            );

            return React.createElement(
                "div",
                { className: "grid-block align-center", id: "accountForm" },
                React.createElement(
                    "div",
                    { className: "grid-block shrink vertical" },
                    React.createElement(
                        "div",
                        { className: "grid-content shrink text-center account-creation" },
                        React.createElement(
                            "div",
                            null,
                            React.createElement("img", { src: logo })
                        ),
                        React.createElement(
                            "div",
                            null,
                            React.createElement(Translate, {
                                content: "header.create_account",
                                component: "h4"
                            })
                        ),
                        React.createElement(
                            "div",
                            null,
                            React.createElement(Translate, {
                                content: "account.intro_text_title",
                                component: "h4",
                                wallet_name: getWalletName()
                            }),
                            React.createElement(Translate, {
                                unsafe: true,
                                content: "account.intro_text_1",
                                component: "p"
                            }),
                            React.createElement(
                                "div",
                                { className: "shrink text-center" },
                                React.createElement(
                                    "div",
                                    { className: "grp-menu-item overflow-visible account-drop-down" },
                                    React.createElement(
                                        "div",
                                        {
                                            className: "grp-menu-item overflow-visible login-selector--language-select",
                                            style: { margin: "0 auto" },
                                            "data-intro": translator.translate("walkthrough.language_flag")
                                        },
                                        React.createElement(
                                            Row,
                                            { className: "login-selector--language-select--wrapper" },
                                            React.createElement(
                                                Col,
                                                { span: 4 },
                                                React.createElement(Icon, {
                                                    type: "global",
                                                    className: "login-selector--language-select--icon"
                                                })
                                            ),
                                            React.createElement(
                                                Col,
                                                { span: 20 },
                                                flagDropdown
                                            )
                                        )
                                    )
                                )
                            )
                        ),
                        React.createElement(
                            "div",
                            { className: "grid-block account-login-options" },
                            React.createElement(
                                Link,
                                {
                                    id: "account_login_button",
                                    to: "/create-account/password",
                                    className: "button primary",
                                    "data-intro": translator.translate("walkthrough.create_cloud_wallet")
                                },
                                React.createElement(Translate, { content: "header.create_account" })
                            ),
                            React.createElement(
                                "span",
                                {
                                    className: "button hollow primary",
                                    onClick: function onClick() {
                                        SettingsActions.changeSetting.defer({
                                            setting: "passwordLogin",
                                            value: true
                                        });
                                        WalletUnlockActions.unlock().catch(function () {});
                                    }
                                },
                                React.createElement(Translate, { content: "header.unlock_short" })
                            )
                        ),
                        React.createElement(
                            "div",
                            { className: "additional-account-options" },
                            React.createElement(
                                "h5",
                                { style: { textAlign: "center" } },
                                React.createElement(TranslateWithLinks, {
                                    string: "account.optional.formatter",
                                    keys: [{
                                        type: "link",
                                        value: "/wallet/backup/restore",
                                        translation: "account.optional.restore_link",
                                        dataIntro: translator.translate("walkthrough.restore_account"),
                                        arg: "restore_link"
                                    }, {
                                        type: "link",
                                        value: "/create-account/wallet",
                                        translation: "account.optional.restore_form",
                                        dataIntro: translator.translate("walkthrough.create_local_wallet"),
                                        arg: "restore_form"
                                    }]
                                })
                            )
                        ),
                        React.createElement(Route, {
                            path: "/create-account/wallet",
                            exact: true,
                            component: CreateAccount
                        }),
                        React.createElement(Route, {
                            path: "/create-account/password",
                            exact: true,
                            component: CreateAccountPassword
                        })
                    )
                )
            );
        }
    }]);

    return LoginSelector;
}(React.Component);

export default connect(LoginSelector, {
    listenTo: function listenTo() {
        return [AccountStore];
    },
    getProps: function getProps() {
        return {
            currentAccount: AccountStore.getState().currentAccount || AccountStore.getState().passwordAccount
        };
    }
});