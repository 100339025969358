var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import BaseStore from "./BaseStore";
import Immutable from "immutable";
import alt from "alt-instance";
import AccountActions from "actions/AccountActions";
import SettingsActions from "actions/SettingsActions";
import WalletActions from "actions/WalletActions";
import iDB from "idb-instance";
import PrivateKeyStore from "./PrivateKeyStore";
import { ChainStore, ChainValidation, FetchChain } from "bitsharesjs";
import { Apis } from "bitsharesjs-ws";
import AccountRefsStore from "stores/AccountRefsStore";
import AddressIndex from "stores/AddressIndex";
import ls from "common/localStorage";

var ss = new ls("__graphene__");

/**
 *  This Store holds information about accounts in this wallet
 *
 */

var AccountStore = function (_BaseStore) {
    _inherits(AccountStore, _BaseStore);

    function AccountStore() {
        _classCallCheck(this, AccountStore);

        var _this = _possibleConstructorReturn(this, (AccountStore.__proto__ || Object.getPrototypeOf(AccountStore)).call(this));

        _this.bindListeners({
            onSetCurrentAccount: AccountActions.setCurrentAccount,
            onCreateAccount: AccountActions.createAccount,
            onAccountSearch: AccountActions.accountSearch,
            tryToSetCurrentAccount: AccountActions.tryToSetCurrentAccount,
            onSetPasswordAccount: AccountActions.setPasswordAccount,
            onChangeSetting: SettingsActions.changeSetting,
            onSetWallet: WalletActions.setWallet,
            onAddStarAccount: AccountActions.addStarAccount,
            onRemoveStarAccount: AccountActions.removeStarAccount,
            onAddAccountContact: AccountActions.addAccountContact,
            onRemoveAccountContact: AccountActions.removeAccountContact,
            onToggleHideAccount: AccountActions.toggleHideAccount
            // onNewPrivateKeys: [ PrivateKeyActions.loadDbData, PrivateKeyActions.addKey ]
        });

        _this._export("loadDbData", "tryToSetCurrentAccount", "onCreateAccount", "getMyAccounts", "isMyAccount", "getMyAuthorityForAccount", "isMyKey", "reset", "setWallet");
        // can't use settings store due to possible initialization race conditions
        var storedSettings = ss.get("settings_v4", {});
        if (storedSettings.passwordLogin === undefined) {
            storedSettings.passwordLogin = true;
        }
        var referralAccount = _this._checkReferrer();
        _this.state = {
            subbed: false,
            myActiveAccounts: Immutable.Set(), // accounts for which the user controls the keys and are visible
            myHiddenAccounts: Immutable.Set(), // accounts for which the user controls the keys that have been 'hidden' in the settings
            currentAccount: null, // the currently selected account, subset of starredAccounts
            passwordAccount: null, // passwordAccount is the account used when logging in with cloud mode
            starredAccounts: Immutable.Map(), // starred accounts are 'active' accounts that can be selected using the right menu dropdown for trading/transfers etc
            searchAccounts: Immutable.Map(),
            accountContacts: Immutable.Set(),
            linkedAccounts: Immutable.Set(), // linkedAccounts are accounts for which the user controls the private keys, which are stored in a db with the wallet and automatically loaded every time the app starts
            referralAccount: referralAccount,
            passwordLogin: storedSettings.passwordLogin
        };

        _this.getMyAccounts = _this.getMyAccounts.bind(_this);
        _this.chainStoreUpdate = _this.chainStoreUpdate.bind(_this);
        _this._getStorageKey = _this._getStorageKey.bind(_this);
        _this.setWallet = _this.setWallet.bind(_this);
        return _this;
    }

    _createClass(AccountStore, [{
        key: "_migrateUnfollowedAccounts",
        value: function _migrateUnfollowedAccounts(state) {
            try {
                var unfollowed_accounts = ss.get("unfollowed_accounts", []);
                var hiddenAccounts = ss.get(this._getStorageKey("hiddenAccounts", state), []);
                if (unfollowed_accounts.length && !hiddenAccounts.length) {
                    ss.set(this._getStorageKey("hiddenAccounts", state), unfollowed_accounts);
                    ss.remove("unfollowed_accounts");
                    this.setState({
                        myHiddenAccounts: Immutable.Set(unfollowed_accounts)
                    });
                }
            } catch (err) {
                console.error(err);
            }
        }
    }, {
        key: "_checkReferrer",
        value: function _checkReferrer() {
            var referralAccount = "";
            if (window) {
                var getQueryParam = function getQueryParam(param) {
                    var result = window.location.search.match(new RegExp("(\\?|&)" + param + "(\\[\\])?=([^&]*)"));

                    return result ? decodeURIComponent(result[3]) : false;
                };

                var validQueries = ["r", "ref", "referrer", "referral"];
                for (var i = 0; i < validQueries.length; i++) {
                    referralAccount = getQueryParam(validQueries[i]);
                    if (referralAccount) break;
                }
            }

            if (!referralAccount) {
                referralAccount = "xbtsx";
            }

            if (referralAccount) {
                ss.set("referralAccount", referralAccount); // Reset to empty string when the user returns with no ref code
            } else {
                ss.remove("referralAccount");
            }
            //console.log(referralAccount);
            return referralAccount;
        }
    }, {
        key: "reset",
        value: function reset() {
            if (this.state.subbed) ChainStore.unsubscribe(this.chainStoreUpdate);
            this.setState(this._getInitialState());
        }
    }, {
        key: "onSetWallet",
        value: function onSetWallet(_ref) {
            var wallet_name = _ref.wallet_name;

            this.setWallet(wallet_name);
        }
    }, {
        key: "setWallet",
        value: function setWallet(wallet_name) {
            if (wallet_name !== this.state.wallet_name) {
                this.setState({
                    wallet_name: wallet_name,
                    passwordAccount: ss.get(this._getStorageKey("passwordAccount", { wallet_name: wallet_name }), null),
                    starredAccounts: Immutable.Map(ss.get(this._getStorageKey("starredAccounts", { wallet_name: wallet_name }))),
                    myActiveAccounts: Immutable.Set(),
                    accountContacts: Immutable.Set(ss.get(this._getStorageKey("accountContacts", { wallet_name: wallet_name }), [])),
                    myHiddenAccounts: Immutable.Set(ss.get(this._getStorageKey("hiddenAccounts", { wallet_name: wallet_name }), []))
                });
                this.tryToSetCurrentAccount();

                this._migrateUnfollowedAccounts({ wallet_name: wallet_name });
            }
        }
    }, {
        key: "_getInitialState",
        value: function _getInitialState() {
            this.account_refs = null;
            this.initial_account_refs_load = true; // true until all undefined accounts are found

            var wallet_name = this.state.wallet_name || "";
            var starredAccounts = Immutable.Map(ss.get(this._getStorageKey("starredAccounts", { wallet_name: wallet_name })));

            var accountContacts = Immutable.Set(ss.get(this._getStorageKey("accountContacts", { wallet_name: wallet_name }), []));

            return {
                neverShowBrowsingModeNotice: false,
                update: false,
                subbed: false,
                accountsLoaded: false,
                refsLoaded: false,
                currentAccount: null,
                referralAccount: ss.get("referralAccount", ""),
                passwordAccount: ss.get(this._getStorageKey("passwordAccount", { wallet_name: wallet_name }), ""),
                myActiveAccounts: Immutable.Set(),
                myHiddenAccounts: Immutable.Set(ss.get(this._getStorageKey("hiddenAccounts", { wallet_name: wallet_name }), [])),
                searchAccounts: Immutable.Map(),
                searchTerm: "",
                wallet_name: wallet_name,
                starredAccounts: starredAccounts,
                accountContacts: accountContacts
            };
        }
    }, {
        key: "onAddStarAccount",
        value: function onAddStarAccount(account) {
            if (!this.state.starredAccounts.has(account)) {
                var starredAccounts = this.state.starredAccounts.set(account, {
                    name: account
                });
                this.setState({ starredAccounts: starredAccounts });

                ss.set(this._getStorageKey("starredAccounts"), starredAccounts.toJS());
            } else {
                return false;
            }
        }
    }, {
        key: "onRemoveStarAccount",
        value: function onRemoveStarAccount(account) {
            var starredAccounts = this.state.starredAccounts.delete(account);
            this.setState({ starredAccounts: starredAccounts });
            ss.set(this._getStorageKey("starredAccounts"), starredAccounts.toJS());
        }
    }, {
        key: "onSetPasswordAccount",
        value: function onSetPasswordAccount(account) {
            var key = this._getStorageKey("passwordAccount");
            if (!account) {
                ss.remove(key);
            } else {
                ss.set(key, account);
            }
            if (this.state.passwordAccount !== account) {
                this.setState({
                    passwordAccount: account
                });
            }
        }
    }, {
        key: "onToggleHideAccount",
        value: function onToggleHideAccount(_ref2) {
            var account = _ref2.account,
                hide = _ref2.hide;
            var _state = this.state,
                myHiddenAccounts = _state.myHiddenAccounts,
                myActiveAccounts = _state.myActiveAccounts;

            if (hide && !myHiddenAccounts.has(account)) {
                myHiddenAccounts = myHiddenAccounts.add(account);
                myActiveAccounts = myActiveAccounts.delete(account);
            } else if (myHiddenAccounts.has(account)) {
                myHiddenAccounts = myHiddenAccounts.delete(account);
                myActiveAccounts = myActiveAccounts.add(account);
            }
            this.setState({ myHiddenAccounts: myHiddenAccounts, myActiveAccounts: myActiveAccounts });
        }
    }, {
        key: "loadDbData",
        value: function loadDbData() {
            var _this2 = this;

            var myActiveAccounts = Immutable.Set().asMutable();
            var chainId = Apis.instance().chain_id;
            return new Promise(function (resolve, reject) {
                iDB.load_data("linked_accounts").then(function (data) {
                    _this2.state.linkedAccounts = Immutable.fromJS(data || []).toSet();

                    /*
                    * If we're in cloud wallet mode, only fetch the currently
                    * used cloud mode account, if in wallet mode fetch all the
                    * accounts of that wallet for the current chain
                    */

                    var accountPromises = !!_this2.state.passwordLogin && !!_this2.state.passwordAccount ? [FetchChain("getAccount", _this2.state.passwordAccount)] : !!_this2.state.passwordLogin ? [] : data.filter(function (a) {
                        if (a.chainId) {
                            return a.chainId === chainId;
                        } else {
                            return true;
                        }
                    }).map(function (a) {
                        return FetchChain("getAccount", a.name);
                    });

                    Promise.all(accountPromises).then(function (accounts) {
                        accounts.forEach(function (a) {
                            if (!!a && _this2.isMyAccount(a) && !_this2.state.myHiddenAccounts.has(a.get("name"))) {
                                myActiveAccounts.add(a.get("name"));
                            } else if (!!a && !_this2.isMyAccount(a)) {
                                // Remove accounts not owned by the user from the linked_accounts db
                                _this2._unlinkAccount(a.get("name"));
                            }
                        });
                        var immutableAccounts = myActiveAccounts.asImmutable();
                        if (_this2.state.myActiveAccounts !== immutableAccounts) {
                            _this2.setState({
                                myActiveAccounts: myActiveAccounts.asImmutable()
                            });
                        }

                        if (_this2.state.accountsLoaded === false) {
                            _this2.setState({ accountsLoaded: true });
                        }

                        if (!_this2.state.subbed) ChainStore.subscribe(_this2.chainStoreUpdate);
                        _this2.state.subbed = true;
                        _this2.emitChange();
                        _this2.chainStoreUpdate();
                        resolve();
                    }).catch(function (err) {
                        if (!_this2.state.subbed) ChainStore.subscribe(_this2.chainStoreUpdate);
                        _this2.state.subbed = true;
                        _this2.emitChange();
                        _this2.chainStoreUpdate();
                        reject(err);
                    });
                }).catch(function (err) {
                    reject(err);
                });
            });
        }
    }, {
        key: "chainStoreUpdate",
        value: function chainStoreUpdate() {
            this.addAccountRefs();
        }
    }, {
        key: "addAccountRefs",
        value: function addAccountRefs() {
            var _this3 = this;

            //  Simply add them to the myActiveAccounts list (no need to persist them)
            var account_refs = AccountRefsStore.getAccountRefs();
            if (!this.initial_account_refs_load && this.account_refs === account_refs) {
                if (this.state.refsLoaded === false) {
                    this.setState({ refsLoaded: true });
                }
                return;
            }
            this.account_refs = account_refs;
            var pending = false;

            if (this.addAccountRefsInProgress) return;
            this.addAccountRefsInProgress = true;
            var myActiveAccounts = this.state.myActiveAccounts.withMutations(function (accounts) {
                account_refs.forEach(function (id) {
                    var account = ChainStore.getAccount(id);
                    if (account === undefined) {
                        pending = true;
                        return;
                    }

                    var linkedEntry = {
                        name: account.get("name"),
                        chainId: Apis.instance().chain_id
                    };
                    var isAlreadyLinked = _this3.state.linkedAccounts.find(function (a) {
                        return a.get("name") === linkedEntry.name && a.get("chainId") === linkedEntry.chainId;
                    });

                    /*
                    * Some wallets contain deprecated entries with no chain
                    * ids, remove these then write new entries with chain ids
                    */
                    var nameOnlyEntry = _this3.state.linkedAccounts.findKey(function (a) {
                        return a.get("name") === linkedEntry.name && !a.has("chainId");
                    });
                    if (!!nameOnlyEntry) {
                        _this3.state.linkedAccounts = _this3.state.linkedAccounts.delete(nameOnlyEntry);
                        _this3._unlinkAccount(account.get("name"));
                        isAlreadyLinked = false;
                    }
                    if (account && _this3.isMyAccount(account) && !isAlreadyLinked) {
                        _this3._linkAccount(account.get("name"));
                    }
                    if (account && !accounts.includes(account.get("name")) && !_this3.state.myHiddenAccounts.has(account.get("name"))) {
                        accounts.add(account.get("name"));
                    }
                });
            });

            /*
            * If we're in cloud wallet mode, simply set myActiveAccounts to the current
            * cloud wallet account
            */
            if (!!this.state.passwordLogin) {
                myActiveAccounts = Immutable.Set(!!this.state.passwordAccount ? [this.state.passwordAccount] : []);
            }
            if (myActiveAccounts !== this.state.myActiveAccounts) {
                this.setState({ myActiveAccounts: myActiveAccounts });
            }
            this.initial_account_refs_load = pending;
            this.tryToSetCurrentAccount();
            this.addAccountRefsInProgress = false;
        }
    }, {
        key: "getMyAccounts",
        value: function getMyAccounts() {
            if (!this.state.subbed) {
                return [];
            }

            var accounts = [];
            var _iteratorNormalCompletion = true;
            var _didIteratorError = false;
            var _iteratorError = undefined;

            try {
                for (var _iterator = this.state.myActiveAccounts[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                    var account_name = _step.value;

                    var account = ChainStore.getAccount(account_name);
                    if (account === undefined) {
                        // console.log(account_name, "account undefined");
                        continue;
                    }
                    if (account == null) {
                        console.log("WARN: non-chain account name in myActiveAccounts", account_name);
                        continue;
                    }
                    var auth = this.getMyAuthorityForAccount(account);

                    if (auth === undefined) {
                        // console.log(account_name, "auth undefined");
                        continue;
                    }

                    if (auth === "full" || auth === "partial") {
                        accounts.push(account_name);
                    }
                }

                /*
                * If we're in cloud wallet mode, simply return the current
                * cloud wallet account
                */
            } catch (err) {
                _didIteratorError = true;
                _iteratorError = err;
            } finally {
                try {
                    if (!_iteratorNormalCompletion && _iterator.return) {
                        _iterator.return();
                    }
                } finally {
                    if (_didIteratorError) {
                        throw _iteratorError;
                    }
                }
            }

            if (this.state.passwordLogin) return !!this.state.passwordAccount ? [this.state.passwordAccount] : [];

            /* In wallet mode, return a sorted list of all the active accounts */
            return accounts.sort();
        }

        /**
            @todo "partial"
            @return string "none", "full", "partial" or undefined (pending a chain store lookup)
        */

    }, {
        key: "getMyAuthorityForAccount",
        value: function getMyAuthorityForAccount(account) {
            var recursion_count = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;

            if (!account) return undefined;

            var owner_authority = account.get("owner");
            var active_authority = account.get("active");

            var owner_pubkey_threshold = pubkeyThreshold(owner_authority);
            if (owner_pubkey_threshold == "full") return "full";
            var active_pubkey_threshold = pubkeyThreshold(active_authority);
            if (active_pubkey_threshold == "full") return "full";

            var owner_address_threshold = addressThreshold(owner_authority);
            if (owner_address_threshold == "full") return "full";
            var active_address_threshold = addressThreshold(active_authority);
            if (active_address_threshold == "full") return "full";

            var owner_account_threshold = void 0,
                active_account_threshold = void 0;

            // if (account.get("name") === "secured-x") {
            //     debugger;
            // }
            if (recursion_count < 3) {
                owner_account_threshold = this._accountThreshold(owner_authority, recursion_count);
                if (owner_account_threshold === undefined) return undefined;
                if (owner_account_threshold == "full") return "full";

                active_account_threshold = this._accountThreshold(active_authority, recursion_count);
                if (active_account_threshold === undefined) return undefined;
                if (active_account_threshold == "full") return "full";
            }

            if (owner_pubkey_threshold === "partial" || active_pubkey_threshold === "partial" || owner_address_threshold === "partial" || active_address_threshold === "partial" || owner_account_threshold === "partial" || active_account_threshold === "partial") return "partial";
            return "none";
        }
    }, {
        key: "_accountThreshold",
        value: function _accountThreshold(authority, recursion_count) {
            var _this4 = this;

            var account_auths = authority.get("account_auths");
            if (!account_auths.size) return "none";

            var auths = account_auths.map(function (auth) {
                var account = ChainStore.getAccount(auth.get(0), false);
                if (account === undefined) return undefined;
                return _this4.getMyAuthorityForAccount(account, ++recursion_count);
            });

            var final = auths.reduce(function (map, auth) {
                return map.set(auth, true);
            }, Immutable.Map());

            return final.get("full") && final.size === 1 ? "full" : final.get("partial") && final.size === 1 ? "partial" : final.get("none") && final.size === 1 ? "none" : final.get("full") || final.get("partial") ? "partial" : undefined;
        }
    }, {
        key: "isMyAccount",
        value: function isMyAccount(account) {
            var authority = this.getMyAuthorityForAccount(account);
            if (authority === undefined) return undefined;
            return authority === "partial" || authority === "full";
        }
    }, {
        key: "onAccountSearch",
        value: function onAccountSearch(payload) {
            var _this5 = this;

            this.state.searchTerm = payload.searchTerm;
            this.state.searchAccounts = this.state.searchAccounts.clear();
            payload.accounts.forEach(function (account) {
                _this5.state.searchAccounts = _this5.state.searchAccounts.withMutations(function (map) {
                    map.set(account[1], account[0]);
                });
            });
        }
    }, {
        key: "_getStorageKey",
        value: function _getStorageKey() {
            var key = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "currentAccount";
            var state = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this.state;

            var wallet = state.wallet_name;
            var chainId = Apis.instance().chain_id;
            return key + (chainId ? "_" + chainId.substr(0, 8) : "") + (wallet ? "_" + wallet : "");
        }
    }, {
        key: "tryToSetCurrentAccount",
        value: function tryToSetCurrentAccount() {
            var passwordAccountKey = this._getStorageKey("passwordAccount");
            var currentAccountKey = this._getStorageKey("currentAccount");
            if (ss.has(passwordAccountKey)) {
                var acc = ss.get(passwordAccountKey, null);
                if (this.state.passwordAccount !== acc) {
                    this.setState({ passwordAccount: acc });
                }
                return this.setCurrentAccount(acc);
            } else if (ss.has(currentAccountKey)) {
                return this.setCurrentAccount(ss.get(currentAccountKey, null));
            }

            var starredAccounts = this.state.starredAccounts;

            if (starredAccounts.size) {
                return this.setCurrentAccount(starredAccounts.first().name);
            }
            if (this.state.myActiveAccounts.size) {
                return this.setCurrentAccount(this.state.myActiveAccounts.first());
            }
        }
    }, {
        key: "setCurrentAccount",
        value: function setCurrentAccount(name) {
            if (this.state.passwordAccount) name = this.state.passwordAccount;
            var key = this._getStorageKey();
            if (!name) {
                name = null;
            }

            if (this.state.currentAccount !== name) {
                this.setState({ currentAccount: name });
            }

            ss.set(key, name || null);
        }
    }, {
        key: "onSetCurrentAccount",
        value: function onSetCurrentAccount(name) {
            this.setCurrentAccount(name);
        }
    }, {
        key: "onCreateAccount",
        value: function onCreateAccount(name_or_account) {
            var _this6 = this;

            var account = name_or_account;
            if (typeof account === "string") {
                account = {
                    name: account
                };
            }

            if (account["toJS"]) account = account.toJS();

            if (account.name == "" || this.state.myActiveAccounts.get(account.name)) return Promise.resolve();

            if (!ChainValidation.is_account_name(account.name)) throw new Error("Invalid account name: " + account.name);

            return iDB.add_to_store("linked_accounts", {
                name: account.name,
                chainId: Apis.instance().chain_id
            }).then(function () {
                console.log("[AccountStore.js] ----- Added account to store: ----->", account.name);
                _this6.state.myActiveAccounts = _this6.state.myActiveAccounts.add(account.name);
                if (_this6.state.myActiveAccounts.size === 1) {
                    _this6.setCurrentAccount(account.name);
                }
            });
        }
    }, {
        key: "onAddAccountContact",
        value: function onAddAccountContact(name) {
            if (!ChainValidation.is_account_name(name, true)) throw new Error("Invalid account name: " + name);

            if (!this.state.accountContacts.has(name)) {
                var accountContacts = this.state.accountContacts.add(name);
                ss.set(this._getStorageKey("accountContacts"), accountContacts.toArray());
                this.setState({
                    accountContacts: accountContacts
                });
            }
        }
    }, {
        key: "onRemoveAccountContact",
        value: function onRemoveAccountContact(name) {
            if (!ChainValidation.is_account_name(name, true)) throw new Error("Invalid account name: " + name);

            if (this.state.accountContacts.has(name)) {
                var accountContacts = this.state.accountContacts.remove(name);

                ss.set(this._getStorageKey("accountContacts"), accountContacts);

                this.setState({
                    accountContacts: accountContacts
                });
            }
        }
    }, {
        key: "_linkAccount",
        value: function _linkAccount(name) {
            if (!ChainValidation.is_account_name(name, true)) throw new Error("Invalid account name: " + name);

            // Link
            var linkedEntry = {
                name: name,
                chainId: Apis.instance().chain_id
            };
            try {
                iDB.add_to_store("linked_accounts", linkedEntry);
                this.state.linkedAccounts = this.state.linkedAccounts.add(Immutable.fromJS(linkedEntry)); // Keep the local linkedAccounts in sync with the db
                if (!this.state.myHiddenAccounts.has(name)) this.state.myActiveAccounts = this.state.myActiveAccounts.add(name);

                // Update current account if only one account is linked
                if (this.state.myActiveAccounts.size === 1) {
                    this.setCurrentAccount(name);
                }
            } catch (err) {
                console.error(err);
            }
        }
    }, {
        key: "_unlinkAccount",
        value: function _unlinkAccount(name) {
            if (!ChainValidation.is_account_name(name, true)) throw new Error("Invalid account name: " + name);

            // Unlink
            iDB.remove_from_store("linked_accounts", name);
            // this.state.myActiveAccounts = this.state.myActiveAccounts.delete(name);

            // Update current account if no accounts are linked
            // if (this.state.myActiveAccounts.size === 0) {
            //     this.setCurrentAccount(null);
            // }
        }
    }, {
        key: "isMyKey",
        value: function isMyKey(key) {
            return PrivateKeyStore.hasKey(key);
        }
    }, {
        key: "onChangeSetting",
        value: function onChangeSetting(payload) {
            if (payload.setting === "passwordLogin") {
                if (payload.value === false) {
                    this.onSetPasswordAccount(null);
                    ss.remove(this._getStorageKey());
                    this.loadDbData();
                } else {
                    this.setState({ myActiveAccounts: Immutable.Set() });
                }
                this.setState({ passwordLogin: payload.value });
            }
        }
    }]);

    return AccountStore;
}(BaseStore);

export default alt.createStore(AccountStore, "AccountStore");

// @return 3 full, 2 partial, 0 none
function pubkeyThreshold(authority) {
    var available = 0;
    var required = authority.get("weight_threshold");
    var key_auths = authority.get("key_auths");
    var _iteratorNormalCompletion2 = true;
    var _didIteratorError2 = false;
    var _iteratorError2 = undefined;

    try {
        for (var _iterator2 = key_auths[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
            var k = _step2.value;

            if (PrivateKeyStore.hasKey(k.get(0))) {
                available += k.get(1);
            }
            if (available >= required) break;
        }
    } catch (err) {
        _didIteratorError2 = true;
        _iteratorError2 = err;
    } finally {
        try {
            if (!_iteratorNormalCompletion2 && _iterator2.return) {
                _iterator2.return();
            }
        } finally {
            if (_didIteratorError2) {
                throw _iteratorError2;
            }
        }
    }

    return available >= required ? "full" : available > 0 ? "partial" : "none";
}

// @return 3 full, 2 partial, 0 none
function addressThreshold(authority) {
    var available = 0;
    var required = authority.get("weight_threshold");
    var address_auths = authority.get("address_auths");
    if (!address_auths.size) return "none";
    var addresses = AddressIndex.getState().addresses;
    var _iteratorNormalCompletion3 = true;
    var _didIteratorError3 = false;
    var _iteratorError3 = undefined;

    try {
        for (var _iterator3 = address_auths[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
            var k = _step3.value;

            var address = k.get(0);
            var pubkey = addresses.get(address);
            if (PrivateKeyStore.hasKey(pubkey)) {
                available += k.get(1);
            }
            if (available >= required) break;
        }
    } catch (err) {
        _didIteratorError3 = true;
        _iteratorError3 = err;
    } finally {
        try {
            if (!_iteratorNormalCompletion3 && _iterator3.return) {
                _iterator3.return();
            }
        } finally {
            if (_didIteratorError3) {
                throw _iteratorError3;
            }
        }
    }

    return available >= required ? "full" : available > 0 ? "partial" : "none";
}