var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from "react";
import Translate from "react-translate-component";
import LoadingIndicator from "../LoadingIndicator";
import LogsActions from "actions/LogsActions";
import CopyButton from "../Utility/CopyButton";
import html2canvas from "html2canvas";
import { Modal, Button, Tooltip } from "bitshares-ui-style-guide";
import counterpart from "counterpart";
import Icon from "../Icon/Icon";

var ReportModal = function (_React$Component) {
    _inherits(ReportModal, _React$Component);

    function ReportModal(props) {
        _classCallCheck(this, ReportModal);

        var _this = _possibleConstructorReturn(this, (ReportModal.__proto__ || Object.getPrototypeOf(ReportModal)).call(this, props));

        _this.showScreenshot = function () {
            // Take screenshot
            _this.setState({
                showScreen: !_this.state.showScreen
            });
        };

        _this.getLogs = function () {
            LogsActions.getLogs().then(function (data) {
                if (__DEV__) {
                    data.unshift("Running in DEV mode, persistant capturing of logs deactivated!");
                }
                _this.setState({
                    logEntries: data.join("\n")
                });
            });
        };

        _this.state = _this.getInitialState(props);
        _this.showLog = _this.showLog.bind(_this);
        return _this;
    }

    _createClass(ReportModal, [{
        key: "getInitialState",
        value: function getInitialState() {
            return {
                loadingImage: false,
                logEntries: [],
                logsCopySuccess: false,
                showLog: false,
                imageURI: null,
                showScreen: false
            };
        }
    }, {
        key: "shouldComponentUpdate",
        value: function shouldComponentUpdate(nextProps, nextState) {
            var _this2 = this;

            var should = this.props.visible !== nextProps.visible || this.state.imageURI !== nextState.imageURI || this.state.showLog !== nextState.showLog || this.state.showScreen !== nextState.showScreen;
            if (nextProps.visible && this.props.visible !== nextProps.visible) {
                this.getLogs();
                html2canvas(document.getElementById("content")).then(function (canvas) {
                    return canvas.toDataURL("image/png");
                }).then(function (uri) {
                    return _this2.setState({ imageURI: uri });
                }, function (error) {
                    console.error("Screenshot could not be captured", error);
                    _this2.setState({
                        imageURI: "Screenshot could not be captured"
                    });
                });
            }
            return should;
        }
    }, {
        key: "onLogEntryChanged",
        value: function onLogEntryChanged(e) {
            this.setState({ logEntries: [e.target.value] });
        }
    }, {
        key: "showLog",
        value: function showLog() {
            this.setState({
                showLog: !this.state.showLog
            });
        }
    }, {
        key: "render",
        value: function render() {
            var _this3 = this;

            var _state = this.state,
                logEntries = _state.logEntries,
                loadingImage = _state.loadingImage,
                logsCopySuccess = _state.logsCopySuccess,
                showLog = _state.showLog,
                showScreen = _state.showScreen;


            var decriptionArea = function decriptionArea() {
                if (true) {
                    // !showLog && !showScreen
                    return React.createElement(
                        "p",
                        null,
                        React.createElement(Translate, { content: "modal.report.explanatory_text_2" }),
                        React.createElement("br", null),
                        "\xA0\xA0",
                        React.createElement(
                            "a",
                            {
                                href: "https://github.com/bitshares/bitshares-ui/issues",
                                target: "_blank",
                                style: { textAlign: "center", width: "100%" },
                                className: "external-link"
                            },
                            "https://github.com/bitshares/bitshares-ui/issues"
                        ),
                        React.createElement("br", null),
                        React.createElement(Translate, { content: "modal.report.explanatory_text_3" }),
                        React.createElement("br", null),
                        React.createElement("br", null),
                        React.createElement(Translate, { content: "modal.report.explanatory_text_4" }),
                        React.createElement("br", null),
                        "\xA0\xA0",
                        React.createElement(
                            "a",
                            {
                                href: "https://hackthedex.io",
                                target: "_blank",
                                style: { textAlign: "center", width: "100%" },
                                className: "external-link"
                            },
                            "https://hackthedex.io"
                        )
                    );
                }
            };

            var logsArea = function logsArea() {
                if (showLog) {
                    return React.createElement("textarea", {
                        id: "logsText",
                        style: {},
                        rows: "20",
                        value: logEntries,
                        onChange: _this3.onLogEntryChanged.bind(_this3)
                    });
                }
            };

            var screenshotArea = function screenshotArea() {
                if (_this3.state.imageURI != null) {
                    if (showScreen) {
                        if (_this3.state.imageURI.length > 100) {
                            return React.createElement("img", { src: _this3.state.imageURI });
                        } else {
                            return React.createElement(
                                "text",
                                null,
                                "this.state.imageURI"
                            );
                        }
                    }
                }
            };

            return React.createElement(
                Modal,
                {
                    title: counterpart.translate("modal.report.title"),
                    visible: this.props.visible,
                    onCancel: this.props.hideModal,
                    footer: [React.createElement(
                        Button,
                        { key: "submit", onClick: this.props.hideModal },
                        counterpart.translate("modal.ok")
                    )]
                },
                React.createElement(
                    "div",
                    { className: "grid-block vertical no-overflow" },
                    React.createElement(
                        "p",
                        null,
                        React.createElement(Translate, { content: "modal.report.explanatory_text_1" })
                    ),
                    React.createElement(
                        "span",
                        {
                            className: "raw",
                            style: {
                                border: "1px solid darkgray",
                                marginBottom: "1em"
                            }
                        },
                        React.createElement(
                            "div",
                            {
                                className: "right-label",
                                style: { paddingBottom: "0em" }
                            },
                            React.createElement(CopyButton, { text: this.state.logEntries })
                        ),
                        React.createElement(
                            Tooltip,
                            {
                                title: this.state.showLog ? counterpart.translate("modal.report.hideLog") : counterpart.translate("modal.report.showLog")
                            },
                            React.createElement(
                                "div",
                                {
                                    onClick: this.showLog,
                                    style: { cursor: "pointer" }
                                },
                                React.createElement(
                                    "label",
                                    {
                                        className: "left-label",
                                        style: {
                                            paddingTop: "1em",
                                            paddingLeft: "0.5em",
                                            cursor: "pointer"
                                        }
                                    },
                                    this.state.showLog ? "-" : "+",
                                    "\xA0",
                                    React.createElement(Translate, { content: "modal.report.lastLogEntries" })
                                )
                            )
                        ),
                        logsArea()
                    ),
                    React.createElement(
                        "span",
                        {
                            className: "raw",
                            style: {
                                border: "1px solid darkgray",
                                marginBottom: "1em"
                            }
                        },
                        React.createElement(
                            "div",
                            {
                                className: "right-label",
                                style: { paddingBottom: "0em" }
                            },
                            this.state.imageURI != null ? React.createElement("img", {
                                style: {
                                    height: "2.8em",
                                    marginTop: "0em",
                                    marginRight: "0em"
                                },
                                src: this.state.imageURI
                            }) : "Failed"
                        ),
                        React.createElement(
                            "div",
                            {
                                className: "right-label",
                                style: {
                                    paddingBottom: "0em",
                                    paddingTop: "1em",
                                    paddingRight: "0.5em"
                                }
                            },
                            React.createElement(Translate, { content: "modal.report.copyScreenshot" })
                        ),
                        React.createElement(
                            Tooltip,
                            {
                                title: this.state.showScreen ? counterpart.translate("modal.report.hideScreenshot") : counterpart.translate("modal.report.takeScreenshot")
                            },
                            React.createElement(
                                "div",
                                {
                                    onClick: this.showScreenshot,
                                    style: { cursor: "pointer" }
                                },
                                React.createElement(
                                    "label",
                                    {
                                        className: "left-label",
                                        style: {
                                            paddingTop: "1em",
                                            paddingLeft: "0.5em",
                                            cursor: "pointer"
                                        }
                                    },
                                    this.state.showScreen ? "-" : "+",
                                    "\xA0",
                                    React.createElement(Translate, { content: "modal.report.screenshot" })
                                )
                            )
                        ),
                        screenshotArea()
                    ),
                    React.createElement("br", null),
                    decriptionArea(),
                    loadingImage && React.createElement(
                        "div",
                        { style: { textAlign: "center" } },
                        React.createElement(LoadingIndicator, { type: "three-bounce" })
                    ),
                    logsCopySuccess && React.createElement(
                        "p",
                        null,
                        React.createElement(Translate, { content: "modal.report.copySuccess" })
                    )
                )
            );
        }
    }]);

    return ReportModal;
}(React.Component);

export default ReportModal;