var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from "react";
import { Link } from "react-router-dom";
import { connect } from "alt-react";
import AccountActions from "actions/AccountActions";
import AccountStore from "stores/AccountStore";
import SettingsStore from "stores/SettingsStore";
import SettingsActions from "actions/SettingsActions";
import ZfApi from "react-foundation-apps/src/utils/foundation-api";
import SendModal from "../Modal/SendModal";
import DepositModal from "../Modal/DepositModal";
import GatewayStore from "stores/GatewayStore";
import Icon from "../Icon/Icon";
import Translate from "react-translate-component";
import counterpart from "counterpart";
import WalletDb from "stores/WalletDb";
import WalletUnlockStore from "stores/WalletUnlockStore";
import WalletUnlockActions from "actions/WalletUnlockActions";
import WalletManagerStore from "stores/WalletManagerStore";
import cnames from "classnames";
import TotalBalanceValue from "../Utility/TotalBalanceValue";
import ReactTooltip from "react-tooltip";
import { Apis } from "bitsharesjs-ws";
import AccountImage from "../Account/AccountImage";
import { ChainStore } from "bitsharesjs";
import WithdrawModal from "../Modal/WithdrawModalNew";
import { List } from "immutable";
import DropDownMenu from "./HeaderDropdown";
import { withRouter } from "react-router-dom";
import { Notification } from "bitshares-ui-style-guide";
import AccountBrowsingMode from "../Account/AccountBrowsingMode";
import { setLocalStorageType, isPersistantType } from "lib/common/localStorage";

import { getLogo } from "branding";
import { getLogoSmall } from "branding";

var logo = getLogo();
var logoSmall = getLogoSmall();

// const FlagImage = ({flag, width = 20, height = 20}) => {
//     return <img height={height} width={width} src={`${__BASE_URL__}language-dropdown/${flag.toUpperCase()}.png`} />;
// };

var SUBMENUS = {
    SETTINGS: "SETTINGS"
};

var Header = function (_React$Component) {
    _inherits(Header, _React$Component);

    function Header(props) {
        _classCallCheck(this, Header);

        var _this = _possibleConstructorReturn(this, (Header.__proto__ || Object.getPrototypeOf(Header)).call(this));

        _this.state = {
            active: props.location.pathname,
            accountsListDropdownActive: false,
            dropdownActive: false,
            dropdownSubmenuActive: false,
            isDepositModalVisible: false,
            hasDepositModalBeenShown: false,
            isWithdrawModalVisible: false,
            hasWithdrawalModalBeenShown: false
        };

        _this.unlisten = null;
        _this._toggleAccountDropdownMenu = _this._toggleAccountDropdownMenu.bind(_this);
        _this._toggleDropdownMenu = _this._toggleDropdownMenu.bind(_this);
        _this._closeDropdown = _this._closeDropdown.bind(_this);
        _this._closeDropdownSubmenu = _this._closeDropdownSubmenu.bind(_this);
        _this._toggleDropdownSubmenu = _this._toggleDropdownSubmenu.bind(_this);
        _this._closeMenuDropdown = _this._closeMenuDropdown.bind(_this);
        _this._closeAccountsListDropdown = _this._closeAccountsListDropdown.bind(_this);

        _this.showDepositModal = _this.showDepositModal.bind(_this);
        _this.hideDepositModal = _this.hideDepositModal.bind(_this);

        _this.showWithdrawModal = _this.showWithdrawModal.bind(_this);
        _this.hideWithdrawModal = _this.hideWithdrawModal.bind(_this);

        _this.onBodyClick = _this.onBodyClick.bind(_this);
        return _this;
    }

    _createClass(Header, [{
        key: "showDepositModal",
        value: function showDepositModal() {
            this.setState({
                isDepositModalVisible: true,
                hasDepositModalBeenShown: true
            });
        }
    }, {
        key: "hideDepositModal",
        value: function hideDepositModal() {
            this.setState({
                isDepositModalVisible: false
            });
        }
    }, {
        key: "showWithdrawModal",
        value: function showWithdrawModal() {
            this.setState({
                isWithdrawModalVisible: true,
                hasWithdrawalModalBeenShown: true
            });
        }
    }, {
        key: "hideWithdrawModal",
        value: function hideWithdrawModal() {
            this.setState({
                isWithdrawModalVisible: false
            });
        }
    }, {
        key: "componentWillMount",
        value: function componentWillMount() {
            var _this2 = this;

            this.unlisten = this.props.history.listen(function (newState) {
                if (_this2.unlisten && _this2.state.active !== newState.pathname) {
                    _this2.setState({
                        active: newState.pathname
                    });
                }
            });
        }
    }, {
        key: "componentDidMount",
        value: function componentDidMount() {
            setTimeout(function () {
                ReactTooltip.rebuild();
            }, 1250);

            document.body.addEventListener("click", this.onBodyClick, {
                capture: false,
                passive: true
            });
        }
    }, {
        key: "componentWillUnmount",
        value: function componentWillUnmount() {
            if (this.unlisten) {
                this.unlisten();
                this.unlisten = null;
            }

            document.body.removeEventListener("click", this.onBodyClick);
        }
    }, {
        key: "shouldComponentUpdate",
        value: function shouldComponentUpdate(nextProps, nextState) {
            return nextProps.myActiveAccounts !== this.props.myActiveAccounts || nextProps.currentAccount !== this.props.currentAccount || nextProps.passwordLogin !== this.props.passwordLogin || nextProps.locked !== this.props.locked || nextProps.current_wallet !== this.props.current_wallet || nextProps.lastMarket !== this.props.lastMarket || nextProps.starredAccounts !== this.props.starredAccounts || nextProps.currentLocale !== this.props.currentLocale || nextState.active !== this.state.active || nextState.hiddenAssets !== this.props.hiddenAssets || nextState.dropdownActive !== this.state.dropdownActive || nextState.dropdownSubmenuActive !== this.state.dropdownSubmenuActive || nextState.accountsListDropdownActive !== this.state.accountsListDropdownActive || nextProps.height !== this.props.height || nextProps.location.pathname !== this.props.location.pathname;
        }
    }, {
        key: "_showSend",
        value: function _showSend(e) {
            e.preventDefault();
            if (this.send_modal) this.send_modal.show();
            this._closeDropdown();
        }
    }, {
        key: "_showDeposit",
        value: function _showDeposit(e) {
            e.preventDefault();
            this.showDepositModal();
            this._closeDropdown();
        }
    }, {
        key: "_showWithdraw",
        value: function _showWithdraw(e) {
            e.preventDefault();
            this._closeDropdown();
            this.showWithdrawModal();
        }
    }, {
        key: "_triggerMenu",
        value: function _triggerMenu(e) {
            e.preventDefault();
            ZfApi.publish("mobile-menu", "toggle");
        }
    }, {
        key: "_toggleLock",
        value: function _toggleLock(e) {
            e.preventDefault();
            if (WalletDb.isLocked()) {
                WalletUnlockActions.unlock().then(function () {
                    AccountActions.tryToSetCurrentAccount();
                }).catch(function () {});
            } else {
                WalletUnlockActions.lock();
                if (!WalletUnlockStore.getState().rememberMe) {
                    if (!isPersistantType()) {
                        setLocalStorageType("persistant");
                    }
                    AccountActions.setPasswordAccount(null);
                    AccountStore.tryToSetCurrentAccount();
                }
            }
            this._closeDropdown();
        }
    }, {
        key: "_onNavigate",
        value: function _onNavigate(route, e) {
            e.preventDefault();

            // Set Accounts Tab as active tab
            if (route == "/accounts") {
                SettingsActions.changeViewSetting({
                    dashboardEntry: "accounts"
                });
            }

            this.props.history.push(route);
            this._closeDropdown();
        }
    }, {
        key: "_closeAccountsListDropdown",
        value: function _closeAccountsListDropdown() {
            if (this.state.accountsListDropdownActive) {
                this.setState({
                    accountsListDropdownActive: false
                });
            }
        }
    }, {
        key: "_closeMenuDropdown",
        value: function _closeMenuDropdown() {
            if (this.state.dropdownActive) {
                this.setState({
                    dropdownActive: false
                });
            }
        }
    }, {
        key: "_closeDropdownSubmenu",
        value: function _closeDropdownSubmenu() {
            if (this.state.dropdownSubmenuActive) {
                this.setState({
                    dropdownSubmenuActive: false
                });
            }
        }
    }, {
        key: "_closeDropdown",
        value: function _closeDropdown() {
            this._closeMenuDropdown();
            this._closeAccountsListDropdown();
            this._closeDropdownSubmenu();
        }
    }, {
        key: "_onGoBack",
        value: function _onGoBack(e) {
            e.preventDefault();
            window.history.back();
        }
    }, {
        key: "_onGoForward",
        value: function _onGoForward(e) {
            e.preventDefault();
            window.history.forward();
        }
    }, {
        key: "_accountClickHandler",
        value: function _accountClickHandler(account_name, e) {
            e.preventDefault();
            ZfApi.publish("account_drop_down", "close");
            if (account_name !== this.props.currentAccount) {
                AccountActions.setCurrentAccount.defer(account_name);
                Notification.success({
                    message: counterpart.translate("header.account_notify", {
                        account: account_name
                    })
                });
                this._closeDropdown();
            }
        }
    }, {
        key: "_toggleAccountDropdownMenu",
        value: function _toggleAccountDropdownMenu() {
            // prevent state toggling if user cannot have multiple accounts

            var hasLocalWallet = !!WalletDb.getWallet();

            if (!hasLocalWallet) return false;

            this.setState({
                accountsListDropdownActive: !this.state.accountsListDropdownActive
            });
        }
    }, {
        key: "_toggleDropdownSubmenu",
        value: function _toggleDropdownSubmenu() {
            var item = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.state.dropdownSubmenuActiveItem;
            var e = arguments[1];

            if (e) e.stopPropagation();

            this.setState({
                dropdownSubmenuActive: !this.state.dropdownSubmenuActive,
                dropdownSubmenuActiveItem: item
            });
        }
    }, {
        key: "_toggleDropdownMenu",
        value: function _toggleDropdownMenu() {
            this.setState({
                dropdownActive: !this.state.dropdownActive
            });
        }
    }, {
        key: "onBodyClick",
        value: function onBodyClick(e) {
            var el = e.target;
            var insideMenuDropdown = false;
            var insideAccountDropdown = false;

            do {
                if (el.classList && el.classList.contains("account-dropdown-wrapper")) {
                    insideAccountDropdown = true;
                    break;
                }

                if (el.classList && el.classList.contains("menu-dropdown-wrapper")) {
                    insideMenuDropdown = true;
                    break;
                }
            } while (el = el.parentNode);

            if (!insideAccountDropdown) this._closeAccountsListDropdown();
            if (!insideMenuDropdown) {
                this._closeMenuDropdown();
                this._closeDropdownSubmenu();
            }
        }
    }, {
        key: "render",
        value: function render() {
            var _this3 = this;

            var active = this.state.active;
            var _props = this.props,
                currentAccount = _props.currentAccount,
                starredAccounts = _props.starredAccounts,
                passwordLogin = _props.passwordLogin,
                passwordAccount = _props.passwordAccount,
                height = _props.height;


            var tradingAccounts = AccountStore.getMyAccounts();
            var maxHeight = Math.max(40, height - 67 - 36) + "px";

            var a = ChainStore.getAccount(currentAccount);
            var showAccountLinks = !!a;
            var isMyAccount = !a ? false : AccountStore.isMyAccount(a) || passwordLogin && currentAccount === passwordAccount;
            var enableDepositWithdraw = !!a && Apis.instance() && Apis.instance().chain_id && Apis.instance().chain_id.substr(0, 8) === "4018d784";

            if (starredAccounts.size) {
                for (var i = tradingAccounts.length - 1; i >= 0; i--) {
                    if (!starredAccounts.has(tradingAccounts[i])) {
                        tradingAccounts.splice(i, 1);
                    }
                }
                starredAccounts.forEach(function (account) {
                    if (tradingAccounts.indexOf(account.name) === -1) {
                        tradingAccounts.push(account.name);
                    }
                });
            }

            var myAccounts = AccountStore.getMyAccounts();
            var myAccountCount = myAccounts.length;

            var walletBalance = myAccounts.length && this.props.currentAccount ? React.createElement(
                "div",
                {
                    className: "total-value",
                    onClick: this._toggleAccountDropdownMenu
                },
                React.createElement(TotalBalanceValue.AccountWrapper, {
                    hiddenAssets: this.props.hiddenAssets,
                    accounts: List([this.props.currentAccount]),
                    noTip: true,
                    style: { minHeight: 15 }
                })
            ) : null;

            var dashboard = React.createElement(
                "a",
                { className: "logo", target: "_blank", href: "https://xbts.io" },
                React.createElement("img", {
                    style: { margin: 0, height: 40 },
                    className: "column-hide-small",
                    src: logo
                }),
                React.createElement("img", {
                    style: { margin: 0, height: 30 },
                    className: "column-show-small",
                    src: logoSmall
                })
            );

            if (currentAccount) {
                dashboard = React.createElement(
                    "a",
                    {
                        className: cnames("logo", {
                            active: active === "/" || active.indexOf("dashboard") !== -1 && active.indexOf("account") === -1
                        }),
                        onClick: this._onNavigate.bind(this, "/")
                    },
                    React.createElement("img", {
                        style: { margin: 0, height: 40 },
                        className: "column-hide-small",
                        src: logo
                    }),
                    React.createElement("img", {
                        style: { margin: 0, height: 30 },
                        className: "column-show-small",
                        src: logoSmall
                    })
                );
            }

            var createAccountLink = myAccountCount === 0 ? true : null;

            // let lock_unlock = ((!!this.props.current_wallet) || passwordLogin) ? (
            //     <div className="grp-menu-item" >
            //     { this.props.locked ?
            //         <a style={{padding: "1rem"}} href onClick={this._toggleLock.bind(this)} data-class="unlock-tooltip" data-offset="{'left': 50}" data-tip={locked_tip} data-place="bottom" data-html><Icon className="icon-14px" name="locked" title="icons.locked.common" /></a>
            //         : <a style={{padding: "1rem"}} href onClick={this._toggleLock.bind(this)} data-class="unlock-tooltip" data-offset="{'left': 50}" data-tip={unlocked_tip} data-place="bottom" data-html><Icon className="icon-14px" name="unlocked" title="icons.unlocked.common" /></a> }
            //     </div>
            // ) : null;

            var tradeUrl = this.props.lastMarket ? "/market/" + this.props.lastMarket : "/market/XBTSX.STH_BTS";

            // Account selector: Only active inside the exchange
            var account_display_name = void 0,
                accountsList = void 0;
            if (currentAccount) {
                account_display_name = currentAccount.length > 20 ? currentAccount.slice(0, 20) + ".." : currentAccount;
                if (tradingAccounts.indexOf(currentAccount) < 0 && isMyAccount) {
                    tradingAccounts.push(currentAccount);
                }
                if (tradingAccounts.length >= 1) {
                    accountsList = tradingAccounts.sort().filter(function (name) {
                        return name !== currentAccount;
                    }).map(function (name) {
                        return React.createElement(
                            "li",
                            {
                                key: name,
                                className: cnames({
                                    active: active.replace("/account/", "").indexOf(name) === 0
                                }),
                                onClick: _this3._accountClickHandler.bind(_this3, name)
                            },
                            React.createElement(
                                "div",
                                {
                                    style: { paddingTop: 0 },
                                    className: "table-cell"
                                },
                                React.createElement(AccountImage, {
                                    style: { position: "relative", top: 4 },
                                    size: { height: 20, width: 20 },
                                    account: name
                                })
                            ),
                            React.createElement(
                                "div",
                                {
                                    className: "table-cell",
                                    style: { paddingLeft: 10 }
                                },
                                React.createElement(
                                    "a",
                                    {
                                        className: "text lower-case" + (name === account_display_name ? " current-account" : "")
                                    },
                                    name
                                )
                            )
                        );
                    });
                }
            }

            var hamburger = this.state.dropdownActive ? React.createElement(Icon, {
                className: "icon-18px",
                name: "hamburger-x",
                title: "icons.hamburger_x"
            }) : React.createElement(Icon, {
                className: "icon-18px",
                name: "hamburger",
                title: "icons.hamburger"
            });
            var hasLocalWallet = !!WalletDb.getWallet();

            /* Dynamic Menu Item */
            var dynamicMenuItem = void 0;
            if (active.indexOf("transfer") !== -1) {
                dynamicMenuItem = React.createElement(
                    "a",
                    { style: { flexFlow: "row" }, className: cnames({ active: true }) },
                    React.createElement(Icon, {
                        size: "1_5x",
                        style: { position: "relative", top: 0, left: -8 },
                        name: "transfer",
                        title: "icons.transfer"
                    }),
                    React.createElement(Translate, {
                        className: "column-hide-small",
                        component: "span",
                        content: "header.payments"
                    })
                );
            }
            if (active.indexOf("spotlight") !== -1) {
                dynamicMenuItem = React.createElement(
                    "a",
                    { style: { flexFlow: "row" }, className: cnames({ active: true }) },
                    React.createElement(Icon, {
                        size: "1_5x",
                        style: { position: "relative", top: 0, left: -8 },
                        name: "showcases",
                        title: "icons.showcases"
                    }),
                    React.createElement(Translate, {
                        className: "column-hide-small",
                        component: "span",
                        content: "header.showcases"
                    })
                );
            }
            if (active.indexOf("settings") !== -1) {
                dynamicMenuItem = React.createElement(
                    "a",
                    {
                        style: { flexFlow: "row" },
                        className: cnames({
                            active: active.indexOf("settings") !== -1
                        })
                    },
                    React.createElement(Icon, {
                        size: "1_5x",
                        style: { position: "relative", top: 0, left: -8 },
                        name: "cogs",
                        title: "icons.cogs"
                    }),
                    React.createElement(Translate, {
                        className: "column-hide-small",
                        component: "span",
                        content: "header.settings"
                    })
                );
            }
            if (active.indexOf("deposit-withdraw") !== -1) {
                dynamicMenuItem = React.createElement(
                    "a",
                    {
                        style: { flexFlow: "row" },
                        className: cnames({
                            active: active.indexOf("deposit-withdraw") !== -1
                        })
                    },
                    React.createElement(Icon, {
                        size: "1_5x",
                        style: { position: "relative", top: 0, left: -8 },
                        name: "deposit",
                        title: "icons.deposit.deposit_withdraw"
                    }),
                    React.createElement(Translate, {
                        className: "column-hide-small",
                        component: "span",
                        content: "header.deposit-withdraw"
                    })
                );
            }
            if (active.indexOf("news") !== -1) {
                dynamicMenuItem = React.createElement(
                    "a",
                    {
                        style: { flexFlow: "row" },
                        className: cnames({ active: active.indexOf("news") !== -1 })
                    },
                    React.createElement(Icon, {
                        size: "1_5x",
                        style: { position: "relative", top: 0, left: -8 },
                        name: "news",
                        title: "icons.news"
                    }),
                    React.createElement(Translate, {
                        className: "column-hide-small",
                        component: "span",
                        content: "news.news"
                    })
                );
            }
            if (active.indexOf("help") !== -1) {
                dynamicMenuItem = React.createElement(
                    "a",
                    {
                        style: { flexFlow: "row" },
                        className: cnames({ active: active.indexOf("help") !== -1 })
                    },
                    React.createElement(Icon, {
                        size: "1_5x",
                        style: { position: "relative", top: 0, left: -8 },
                        name: "question-circle",
                        title: "icons.question_circle"
                    }),
                    React.createElement(Translate, {
                        className: "column-hide-small",
                        component: "span",
                        content: "header.help"
                    })
                );
            }
            if (active.indexOf("/voting") !== -1) {
                dynamicMenuItem = React.createElement(
                    "a",
                    {
                        style: { flexFlow: "row" },
                        className: cnames({
                            active: active.indexOf("/voting") !== -1
                        })
                    },
                    React.createElement(Icon, {
                        size: "1_5x",
                        style: { position: "relative", top: 0, left: -8 },
                        name: "thumbs-up",
                        title: "icons.thumbs_up"
                    }),
                    React.createElement(Translate, {
                        className: "column-hide-small",
                        component: "span",
                        content: "account.voting"
                    })
                );
            }
            if (active.indexOf("/assets") !== -1 && active.indexOf("explorer") === -1) {
                dynamicMenuItem = React.createElement(
                    "a",
                    {
                        style: { flexFlow: "row" },
                        className: cnames({
                            active: active.indexOf("/assets") !== -1
                        })
                    },
                    React.createElement(Icon, {
                        size: "1_5x",
                        style: { position: "relative", top: 0, left: -8 },
                        name: "assets",
                        title: "icons.assets"
                    }),
                    React.createElement(Translate, {
                        className: "column-hide-small",
                        component: "span",
                        content: "explorer.assets.title"
                    })
                );
            }
            if (active.indexOf("/signedmessages") !== -1) {
                dynamicMenuItem = React.createElement(
                    "a",
                    {
                        style: { flexFlow: "row" },
                        className: cnames({
                            active: active.indexOf("/signedmessages") !== -1
                        })
                    },
                    React.createElement(Icon, {
                        size: "1_5x",
                        style: { position: "relative", top: 0, left: -8 },
                        name: "text",
                        title: "icons.text.signed_messages"
                    }),
                    React.createElement(Translate, {
                        className: "column-hide-small",
                        component: "span",
                        content: "account.signedmessages.menuitem"
                    })
                );
            }
            if (active.indexOf("/member-stats") !== -1) {
                dynamicMenuItem = React.createElement(
                    "a",
                    {
                        style: { flexFlow: "row" },
                        className: cnames({
                            active: active.indexOf("/member-stats") !== -1
                        })
                    },
                    React.createElement(Icon, {
                        size: "1_5x",
                        style: { position: "relative", top: 0, left: -8 },
                        name: "text",
                        title: "icons.text.membership_stats"
                    }),
                    React.createElement(Translate, {
                        className: "column-hide-small",
                        component: "span",
                        content: "account.member.stats"
                    })
                );
            }
            if (active.indexOf("/vesting") !== -1) {
                dynamicMenuItem = React.createElement(
                    "a",
                    {
                        style: { flexFlow: "row" },
                        className: cnames({
                            active: active.indexOf("/vesting") !== -1
                        })
                    },
                    React.createElement(Icon, {
                        size: "1_5x",
                        style: { position: "relative", top: 0, left: -8 },
                        name: "hourglass",
                        title: "icons.hourglass"
                    }),
                    React.createElement(Translate, {
                        className: "column-hide-small",
                        component: "span",
                        content: "account.vesting.title"
                    })
                );
            }
            if (active.indexOf("/whitelist") !== -1) {
                dynamicMenuItem = React.createElement(
                    "a",
                    {
                        style: { flexFlow: "row" },
                        className: cnames({
                            active: active.indexOf("/whitelist") !== -1
                        })
                    },
                    React.createElement(Icon, {
                        size: "1_5x",
                        style: { position: "relative", top: 0, left: -8 },
                        name: "list",
                        title: "icons.list"
                    }),
                    React.createElement(Translate, {
                        className: "column-hide-small",
                        component: "span",
                        content: "account.whitelist.title"
                    })
                );
            }
            if (active.indexOf("/permissions") !== -1) {
                dynamicMenuItem = React.createElement(
                    "a",
                    {
                        style: { flexFlow: "row" },
                        className: cnames({
                            active: active.indexOf("/permissions") !== -1
                        })
                    },
                    React.createElement(Icon, {
                        size: "1_5x",
                        style: { position: "relative", top: 0, left: -8 },
                        name: "warning",
                        title: "icons.warning"
                    }),
                    React.createElement(Translate, {
                        className: "column-hide-small",
                        component: "span",
                        content: "account.permissions"
                    })
                );
            }

            if (active.indexOf("/borrow") !== -1) {
                dynamicMenuItem = React.createElement(
                    "a",
                    {
                        style: { flexFlow: "row" },
                        className: cnames({
                            active: active.indexOf("/borrow") !== -1
                        })
                    },
                    React.createElement(Icon, {
                        size: "1_5x",
                        style: { position: "relative", top: 0, left: -8 },
                        name: "borrow",
                        title: "icons.borrow"
                    }),
                    React.createElement(Translate, {
                        className: "column-hide-small",
                        component: "span",
                        content: "showcases.borrow.title"
                    })
                );
            }

            if (active.indexOf("/barter") !== -1) {
                dynamicMenuItem = React.createElement(
                    "a",
                    {
                        style: { flexFlow: "row" },
                        className: cnames({
                            active: active.indexOf("/barter") !== -1
                        })
                    },
                    React.createElement(Icon, {
                        size: "1_5x",
                        style: { position: "relative", top: 0, left: -8 },
                        name: "barter",
                        title: "icons.barter"
                    }),
                    React.createElement(Translate, {
                        className: "column-hide-small",
                        component: "span",
                        content: "showcases.barter.title"
                    })
                );
            }

            if (active.indexOf("/direct-debit") !== -1) {
                dynamicMenuItem = React.createElement(
                    "a",
                    {
                        style: { flexFlow: "row" },
                        className: cnames({
                            active: active.indexOf("/direct-debit") !== -1
                        })
                    },
                    React.createElement(Icon, {
                        size: "1_5x",
                        style: { position: "relative", top: 0, left: -8 },
                        name: "direct_debit",
                        title: "icons.direct_debit"
                    }),
                    React.createElement(Translate, {
                        className: "column-hide-small",
                        component: "span",
                        content: "showcases.direct_debit.title"
                    })
                );
            }

            if (active.indexOf("/htlc") !== -1) {
                dynamicMenuItem = React.createElement(
                    "a",
                    {
                        style: { flexFlow: "row" },
                        className: cnames({
                            active: active.indexOf("/htlc") !== -1
                        })
                    },
                    React.createElement(Icon, {
                        size: "1_5x",
                        style: { position: "relative", top: 0, left: -8 },
                        name: "htlc",
                        title: "icons.htlc"
                    }),
                    React.createElement(Translate, {
                        className: "column-hide-small",
                        component: "span",
                        content: "showcases.htlc.title_short"
                    })
                );
            }

            var submenus = _defineProperty({}, SUBMENUS.SETTINGS, React.createElement(
                "ul",
                {
                    className: "dropdown header-menu header-submenu",
                    style: {
                        left: -200,
                        top: 64,
                        maxHeight: !this.state.dropdownActive ? 0 : maxHeight,
                        overflowY: "auto"
                    }
                },
                React.createElement(
                    "li",
                    {
                        className: "divider parent-item",
                        onClick: this._toggleDropdownSubmenu.bind(this, undefined)
                    },
                    React.createElement(
                        "div",
                        { className: "table-cell" },
                        React.createElement(
                            "span",
                            { className: "parent-item-icon" },
                            "<"
                        ),
                        React.createElement(Translate, {
                            content: "header.settings",
                            component: "span",
                            className: "parent-item-name"
                        })
                    )
                ),
                React.createElement(
                    "li",
                    {
                        onClick: this._onNavigate.bind(this, "/settings/general")
                    },
                    React.createElement(Translate, {
                        content: "settings.general",
                        component: "div",
                        className: "table-cell"
                    })
                ),
                !this.props.settings.get("passwordLogin") && React.createElement(
                    "li",
                    {
                        onClick: this._onNavigate.bind(this, "/settings/wallet")
                    },
                    React.createElement(Translate, {
                        content: "settings.wallet",
                        component: "div",
                        className: "table-cell"
                    })
                ),
                React.createElement(
                    "li",
                    {
                        onClick: this._onNavigate.bind(this, "/settings/accounts")
                    },
                    React.createElement(Translate, {
                        content: "settings.accounts",
                        component: "div",
                        className: "table-cell"
                    })
                ),
                !this.props.settings.get("passwordLogin") && [React.createElement(
                    "li",
                    {
                        key: "settings.password",
                        onClick: this._onNavigate.bind(this, "/settings/password")
                    },
                    React.createElement(Translate, {
                        content: "settings.password",
                        component: "div",
                        className: "table-cell"
                    })
                ), React.createElement(
                    "li",
                    {
                        key: "settings.backup",
                        onClick: this._onNavigate.bind(this, "/settings/backup")
                    },
                    React.createElement(Translate, {
                        content: "settings.backup",
                        component: "div",
                        className: "table-cell"
                    })
                )],
                React.createElement(
                    "li",
                    {
                        onClick: this._onNavigate.bind(this, "/settings/restore")
                    },
                    React.createElement(Translate, {
                        content: "settings.restore",
                        component: "div",
                        className: "table-cell"
                    })
                ),
                React.createElement(
                    "li",
                    {
                        onClick: this._onNavigate.bind(this, "/settings/access")
                    },
                    React.createElement(Translate, {
                        content: "settings.access",
                        component: "div",
                        className: "table-cell"
                    })
                ),
                React.createElement(
                    "li",
                    {
                        onClick: this._onNavigate.bind(this, "/settings/faucet_address")
                    },
                    React.createElement(Translate, {
                        content: "settings.faucet_address",
                        component: "div",
                        className: "table-cell"
                    })
                ),
                React.createElement(
                    "li",
                    {
                        onClick: this._onNavigate.bind(this, "/settings/reset")
                    },
                    React.createElement(Translate, {
                        content: "settings.reset",
                        component: "div",
                        className: "table-cell"
                    })
                )
            ));

            return React.createElement(
                "div",
                { className: "header-container", style: { minHeight: "64px" } },
                React.createElement(
                    "div",
                    null,
                    React.createElement(
                        "div",
                        {
                            className: "header menu-group primary",
                            style: { flexWrap: "nowrap", justifyContent: "none" }
                        },
                        __ELECTRON__ ? React.createElement(
                            "div",
                            { className: "grid-block show-for-medium shrink electron-navigation" },
                            React.createElement(
                                "ul",
                                { className: "menu-bar" },
                                React.createElement(
                                    "li",
                                    null,
                                    React.createElement(
                                        "div",
                                        {
                                            style: {
                                                marginLeft: "1rem",
                                                height: "3rem"
                                            }
                                        },
                                        React.createElement(
                                            "div",
                                            {
                                                style: { marginTop: "0.5rem" },
                                                onClick: this._onGoBack.bind(this),
                                                className: "button outline small"
                                            },
                                            "<"
                                        )
                                    )
                                ),
                                React.createElement(
                                    "li",
                                    null,
                                    React.createElement(
                                        "div",
                                        {
                                            style: {
                                                height: "3rem",
                                                marginLeft: "0.5rem",
                                                marginRight: "0.75rem"
                                            }
                                        },
                                        React.createElement(
                                            "div",
                                            {
                                                style: { marginTop: "0.5rem" },
                                                onClick: this._onGoForward.bind(this),
                                                className: "button outline small"
                                            },
                                            ">"
                                        )
                                    )
                                )
                            )
                        ) : null,
                        React.createElement(
                            "ul",
                            { className: "menu-bar" },
                            React.createElement(
                                "li",
                                null,
                                dashboard
                            ),
                            !currentAccount || !!createAccountLink ? null : React.createElement(
                                "li",
                                null,
                                React.createElement(
                                    Link,
                                    {
                                        style: { flexFlow: "row" },
                                        to: "/account/" + currentAccount,
                                        className: cnames({
                                            active: active.indexOf("account/") !== -1 && active.indexOf("/account/") !== -1 && active.indexOf("/assets") === -1 && active.indexOf("/voting") === -1 && active.indexOf("/signedmessages") === -1 && active.indexOf("/member-stats") === -1 && active.indexOf("/vesting") === -1 && active.indexOf("/whitelist") === -1 && active.indexOf("/permissions") === -1
                                        })
                                    },
                                    React.createElement(Icon, {
                                        size: "2x",
                                        style: {
                                            position: "relative",
                                            top: -2,
                                            left: -8
                                        },
                                        name: "dashboard",
                                        title: "icons.dashboard"
                                    }),
                                    React.createElement(Translate, {
                                        className: "column-hide-small",
                                        content: "header.dashboard"
                                    })
                                )
                            ),
                            React.createElement(
                                "li",
                                null,
                                React.createElement(
                                    "a",
                                    {
                                        style: { flexFlow: "row" },
                                        className: cnames(active.indexOf("market/") !== -1 ? null : "column-hide-xxs", {
                                            active: active.indexOf("market/") !== -1
                                        }),
                                        onClick: this._onNavigate.bind(this, tradeUrl)
                                    },
                                    React.createElement(Icon, {
                                        size: "1_5x",
                                        style: {
                                            position: "relative",
                                            top: -2,
                                            left: -8
                                        },
                                        name: "trade",
                                        title: "icons.trade.exchange"
                                    }),
                                    React.createElement(Translate, {
                                        className: "column-hide-small",
                                        component: "span",
                                        content: "header.exchange"
                                    })
                                )
                            ),
                            React.createElement(
                                "li",
                                null,
                                React.createElement(
                                    "a",
                                    {
                                        style: { flexFlow: "row" },
                                        className: cnames(active.indexOf("explorer") !== -1 ? null : "column-hide-xs", {
                                            active: active.indexOf("explorer") !== -1
                                        }),
                                        onClick: this._onNavigate.bind(this, "/explorer/blocks")
                                    },
                                    React.createElement(Icon, {
                                        size: "2x",
                                        style: {
                                            position: "relative",
                                            top: -2,
                                            left: -8
                                        },
                                        name: "server",
                                        title: "icons.server"
                                    }),
                                    React.createElement(Translate, {
                                        className: "column-hide-small",
                                        component: "span",
                                        content: "header.explorer"
                                    })
                                )
                            ),
                            !this.props.locked ? React.createElement(
                                "li",
                                { className: "walletdd" },
                                React.createElement(
                                    "span",
                                    {
                                        style: { flexFlow: "row" },
                                        className: "walletdd"
                                    },
                                    React.createElement(Icon, {
                                        size: "1_5x",
                                        style: {
                                            position: "relative",
                                            top: 8,
                                            left: -8
                                        },
                                        name: "wallet",
                                        title: "icons.wallet"
                                    }),
                                    React.createElement(Translate, {
                                        className: "column-hide-small",
                                        component: "span",
                                        content: "header.wallet"
                                    })
                                ),
                                React.createElement(
                                    "span",
                                    { className: "wallet-dd" },
                                    React.createElement(
                                        "ul",
                                        null,
                                        React.createElement(
                                            "li",
                                            { className: "column-hide-small" },
                                            React.createElement(
                                                Link,
                                                {
                                                    to: "/account/" + currentAccount
                                                },
                                                React.createElement(Icon, {
                                                    size: "1_5x",
                                                    name: "balance"
                                                }),
                                                React.createElement(Translate, {
                                                    component: "span",
                                                    content: "account.portfolio"
                                                })
                                            )
                                        ),
                                        React.createElement(
                                            "li",
                                            null,
                                            React.createElement(
                                                Link,
                                                { to: "/deposit-withdraw" },
                                                React.createElement(Icon, {
                                                    size: "1_5x",
                                                    name: "deposit-withdraw"
                                                }),
                                                React.createElement(Translate, {
                                                    className: "",
                                                    component: "span",
                                                    content: "account.deposit_withdraw"
                                                })
                                            )
                                        ),
                                        React.createElement(
                                            "li",
                                            {
                                                onClick: this._showSend.bind(this)
                                            },
                                            React.createElement(Icon, {
                                                size: "1_5x",
                                                name: "transfer"
                                            }),
                                            React.createElement(Translate, {
                                                className: "",
                                                component: "span",
                                                content: "header.payments"
                                            })
                                        )
                                    )
                                )
                            ) : null,
                            React.createElement(
                                "li",
                                null,
                                dynamicMenuItem
                            )
                        )
                    )
                ),
                React.createElement(
                    "div",
                    {
                        className: "truncated active-account",
                        style: { cursor: "pointer" }
                    },
                    React.createElement(AccountBrowsingMode, { location: this.props.location }),
                    React.createElement(
                        "div",
                        null,
                        React.createElement(
                            "div",
                            { title: "Use it name to receive and send assets in blockchain. Never send assets to other users if you don't understand how it works.", className: "text account-name" },
                            React.createElement(
                                "span",
                                { onClick: this._toggleAccountDropdownMenu },
                                currentAccount
                            ),
                            React.createElement(AccountBrowsingMode, {
                                location: this.props.location,
                                usernameViewIcon: true
                            })
                        ),
                        walletBalance
                    ),
                    hasLocalWallet && React.createElement(
                        "ul",
                        {
                            className: "dropdown header-menu local-wallet-menu",
                            style: {
                                right: 0,
                                maxHeight: !this.state.accountsListDropdownActive ? 0 : maxHeight,
                                overflowY: "auto",
                                position: "absolute",
                                width: "20em"
                            }
                        },
                        React.createElement(
                            "li",
                            {
                                className: cnames({
                                    active: active.indexOf("/accounts") !== -1
                                }, "divider"),
                                onClick: this._onNavigate.bind(this, "/accounts")
                            },
                            React.createElement(
                                "div",
                                { className: "table-cell" },
                                React.createElement(Icon, {
                                    size: "2x",
                                    name: "people",
                                    title: "icons.manage_accounts"
                                })
                            ),
                            React.createElement(
                                "div",
                                { className: "table-cell" },
                                React.createElement(Translate, { content: "header.accounts_manage" })
                            )
                        ),
                        accountsList
                    )
                ),
                React.createElement(
                    "div",
                    null,
                    this.props.currentAccount == null ? null : React.createElement(
                        "span",
                        {
                            onClick: this._toggleLock.bind(this),
                            style: { cursor: "pointer" }
                        },
                        React.createElement(Icon, {
                            className: "lock-unlock",
                            size: "2x",
                            name: this.props.locked ? "locked" : "unlocked",
                            title: this.props.locked ? "icons.locked.common" : "icons.unlocked.common"
                        })
                    )
                ),
                React.createElement(
                    "div",
                    { className: "app-menu" },
                    React.createElement(
                        "div",
                        {
                            onClick: this._toggleDropdownMenu,
                            className: cnames("menu-dropdown-wrapper dropdown-wrapper", { active: this.state.dropdownActive })
                        },
                        React.createElement(
                            "div",
                            { className: "hamburger" },
                            hamburger
                        ),
                        this.state.dropdownSubmenuActive && submenus[this.state.dropdownSubmenuActiveItem] && submenus[this.state.dropdownSubmenuActiveItem] || React.createElement(DropDownMenu, {
                            dropdownActive: this.state.dropdownActive,
                            toggleLock: this._toggleLock.bind(this),
                            maxHeight: maxHeight,
                            locked: this.props.locked,
                            active: active,
                            passwordLogin: passwordLogin,
                            onNavigate: this._onNavigate.bind(this),
                            isMyAccount: isMyAccount,
                            contacts: this.props.contacts,
                            showAccountLinks: showAccountLinks,
                            tradeUrl: tradeUrl,
                            currentAccount: currentAccount,
                            enableDepositWithdraw: enableDepositWithdraw,
                            showDeposit: this._showDeposit.bind(this),
                            showWithdraw: this._showWithdraw.bind(this),
                            showSend: this._showSend.bind(this),
                            toggleDropdownSubmenu: this._toggleDropdownSubmenu.bind(this, SUBMENUS.SETTINGS)
                        })
                    )
                ),
                React.createElement(SendModal, {
                    id: "send_modal_header",
                    refCallback: function refCallback(e) {
                        if (e) _this3.send_modal = e;
                    },
                    from_name: currentAccount
                }),
                this.state.hasDepositModalBeenShown && React.createElement(DepositModal, {
                    visible: this.state.isDepositModalVisible,
                    hideModal: this.hideDepositModal,
                    showModal: this.showDepositModal,
                    ref: "deposit_modal_new",
                    modalId: "deposit_modal_new",
                    account: currentAccount,
                    backedCoins: this.props.backedCoins
                }),
                this.state.hasWithdrawalModalBeenShown && React.createElement(WithdrawModal, {
                    visible: this.state.isWithdrawModalVisible,
                    hideModal: this.hideWithdrawModal,
                    showModal: this.showWithdrawModal,
                    ref: "withdraw_modal_new",
                    modalId: "withdraw_modal_new",
                    backedCoins: this.props.backedCoins
                })
            );
        }
    }]);

    return Header;
}(React.Component);

Header = connect(Header, {
    listenTo: function listenTo() {
        return [AccountStore, WalletUnlockStore, WalletManagerStore, SettingsStore, GatewayStore];
    },
    getProps: function getProps() {
        var chainID = Apis.instance().chain_id;
        return {
            backedCoins: GatewayStore.getState().backedCoins,
            myActiveAccounts: AccountStore.getState().myActiveAccounts,
            currentAccount: AccountStore.getState().currentAccount || AccountStore.getState().passwordAccount,
            passwordAccount: AccountStore.getState().passwordAccount,
            locked: WalletUnlockStore.getState().locked,
            current_wallet: WalletManagerStore.getState().current_wallet,
            lastMarket: SettingsStore.getState().viewSettings.get("lastMarket" + (chainID ? "_" + chainID.substr(0, 8) : "")),
            starredAccounts: AccountStore.getState().starredAccounts,
            passwordLogin: SettingsStore.getState().settings.get("passwordLogin"),
            currentLocale: SettingsStore.getState().settings.get("locale"),
            hiddenAssets: SettingsStore.getState().hiddenAssets,
            settings: SettingsStore.getState().settings,
            locales: SettingsStore.getState().defaults.locale,
            contacts: AccountStore.getState().accountContacts
        };
    }
});

export default withRouter(Header);