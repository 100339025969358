var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from "react";
import LoadingIndicator from "../LoadingIndicator";
import counterpart from "counterpart";
import { findDOMNode } from "react-dom";
import PropTypes from "prop-types";
import { Button } from "bitshares-ui-style-guide";

/** This component gives a convenient way to indicate loading.
 *
 *  Use props as follows:
 *          id|style                 optional, html standard for container element
 *          className                optional, html standard for container element, default=button
 *          type                     optional, html standard for container element, default=button
 *          onClick                  function handle  onClick handler for the underlying button
 *          caption                  str  text within the button
 *          loadingType              str  style of the loading animation, default inside-feedback
 *                                      inside-feedback
 *                                          Displays a small loading indicator and the loadingMessage instead of the caption
 *                                          while loading. Button width is fixated even if the feedback text is too long
 *                                      inside
 *                                          Displays only a loading indicator while loading
 *                                      right-feedback
 *                                          Displays a loading indicator right next to the button and the loadingMessage
 *                                          next to it. Button caption remains unchanged.
 *          loadingMessage           str  default loadingMessage, can be overwritten by providing a string in the feedback call
 *          isLoading                boolean  indicates if the button should show loading or normal state
 *
 *  Example usage:
 *
 *  _loadButton(event, feedback) {
 *      event.preventDefault();
 *      try {
 *          feedback("Loading ...");  // this can also be a translation string
 *          // do someting that can cause an exception
 *          self.someInSyncStuff();
 *
 *          self.getSomeAsyncPromise().then(res => {
 *              // react on it, setState, whatnot
 *          }).catch(err => {
 *              // error handling
 *          }).finally(()=>{
 *              // tell button to stop loading, no argument
 *              feedback()
 *          });
 *      } catch (err) {
 *          // some other error handling
 *          // tell button to stop loading, since the promise was never entered
 *          feedback();
 *      }
 *  }
 *
 *  render() {
 *      return (
 *           <LoadingButton
 *               style={{
 *                   float: "right"
 *               }}
 *               id="load"
 *               caption="Load it"  // this can also be a translation string
 *               onClick={this._loadButton.bind(this)}
 *           />
 *
 *    @author Stefan Schiessl <stefan.schiessl@blockchainprojectsbv.com>
 */

var LoadingButton = function (_React$Component) {
    _inherits(LoadingButton, _React$Component);

    function LoadingButton(props) {
        _classCallCheck(this, LoadingButton);

        // initialize state (do not use setState method!)
        var _this = _possibleConstructorReturn(this, (LoadingButton.__proto__ || Object.getPrototypeOf(LoadingButton)).call(this, props));

        _this.state = {
            loading: _this.props.isLoading == null ? false : _this.props.isLoading,
            overrideMessage: null,
            loadingButtonWidth: null
        };
        _this.processingOnClick = false;
        return _this;
    }

    _createClass(LoadingButton, [{
        key: "shouldComponentUpdate",
        value: function shouldComponentUpdate(nextProps, nextState) {
            return nextProps.isLoading !== this.props.isLoading || nextState.loading !== this.state.loading;
        }
    }, {
        key: "_feedback",
        value: function _feedback() {
            var done = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
            var message = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

            if (done == null) {
                this.setState({
                    overrideMessage: null,
                    loading: false
                });
                this.processingOnClick = false;
            } else if (typeof done === "string") {
                this.setState({
                    overrideMessage: done
                });
            } else if (typeof done === "boolean") {
                if (!done) {
                    this.setState({
                        overrideMessage: message
                    });
                } else {
                    this.setState({
                        loading: false
                    });
                    this.processingOnClick = false;
                }
            }
        }
    }, {
        key: "_onClick",
        value: function _onClick(event) {
            this.processingOnClick = true;
            if (this.state.loading) {
                return true;
            }
            if (this.props.onClick != null) {
                // persist button width
                this.setState({
                    loadingButtonWidth: findDOMNode(this.loadingButton).getBoundingClientRect().width,
                    loading: true
                });
                event.persist();
                this.props.onClick(event, this._feedback.bind(this));
                return true;
            }
        }
    }, {
        key: "_isLoading",
        value: function _isLoading() {
            return this.processingOnClick ? this.state.loading : this.props.isLoading == null ? false : this.props.isLoading;
        }
    }, {
        key: "render",
        value: function render() {
            var _this2 = this;

            var caption = this.props.caption || this.props.text || null;
            if (typeof caption === "string" && caption.indexOf(".") > 0) {
                caption = counterpart.translate(caption);
            }
            if (caption != null && caption.trim() == "") {
                caption = null;
            }

            var loadingMessage = this.props.loadingMessage || null;
            if (this.state.overrideMessage != null && this.state.overrideMessage.trim() != "") {
                loadingMessage = this.state.overrideMessage;
            }
            if (typeof loadingMessage === "string" && loadingMessage.indexOf(".") > 0) {
                loadingMessage = counterpart.translate(loadingMessage);
            }
            if (loadingMessage != null && loadingMessage.trim() == "") {
                loadingMessage = null;
            }
            var leftElement = null;
            var rightElement = null;
            var fixButtonWidth = false;
            var buttonInner = React.createElement(
                "span",
                null,
                caption
            );

            var loadingState = this._isLoading();

            switch (this.props.loadingType) {
                case "inside":
                    if (loadingState) {
                        fixButtonWidth = true;
                        buttonInner = React.createElement(
                            "span",
                            { style: { margin: "auto", display: "inline-block" } },
                            React.createElement(LoadingIndicator, { type: "circle-small" })
                        );
                    }
                    break;
                case "inside-feedback":
                    if (loadingState) {
                        fixButtonWidth = true;
                        buttonInner = React.createElement(
                            "span",
                            { style: { float: "left" } },
                            React.createElement(
                                "span",
                                {
                                    style: {
                                        position: "absolute",
                                        whiteSpace: "nowrap",
                                        marginLeft: "12px"
                                    }
                                },
                                loadingMessage
                            ),
                            React.createElement(
                                "span",
                                null,
                                React.createElement(LoadingIndicator, { type: "circle-small" })
                            )
                        );
                    }
                    break;
                case "overlay":
                    if (loadingState) {
                        fixButtonWidth = true;
                        rightElement = React.createElement(LoadingIndicator, { type: "loading-overlay" });
                    }
                    break;
                case "overlay-feedback":
                    if (loadingState) {
                        fixButtonWidth = true;
                        rightElement = React.createElement(LoadingIndicator, {
                            loadingText: loadingMessage,
                            type: "loading-overlay"
                        });
                    }
                    break;
                case "inside-feedback-resize":
                    if (loadingState) {
                        buttonInner = React.createElement(
                            "span",
                            null,
                            React.createElement(
                                "span",
                                null,
                                loadingMessage
                            ),
                            React.createElement(
                                "span",
                                { style: { float: "left" } },
                                React.createElement(LoadingIndicator, { type: "circle-small" })
                            )
                        );
                    }
                    break;
                case "right-feedback":
                    if (loadingState) {
                        rightElement = React.createElement(
                            "div",
                            {
                                style: {
                                    float: "left",
                                    marginLeft: "-9px",
                                    position: "relative"
                                },
                                className: "disabled"
                            },
                            React.createElement(
                                "span",
                                null,
                                React.createElement(
                                    "span",
                                    {
                                        style: {
                                            float: "left",
                                            marginTop: "7px"
                                        }
                                    },
                                    React.createElement(LoadingIndicator, { type: "circle" })
                                ),
                                React.createElement(
                                    "span",
                                    {
                                        style: {
                                            float: "left",
                                            marginLeft: "6px",
                                            marginTop: "11px"
                                        }
                                    },
                                    loadingMessage
                                )
                            )
                        );
                    }
                    break;
                case "left-feedback":
                    if (loadingState) {
                        leftElement = React.createElement(
                            "div",
                            {
                                style: {
                                    float: "left",
                                    marginRight: "6px",
                                    position: "relative"
                                },
                                className: "disabled"
                            },
                            React.createElement(
                                "span",
                                null,
                                React.createElement(
                                    "span",
                                    {
                                        style: {
                                            float: "right",
                                            marginTop: "7px"
                                        }
                                    },
                                    React.createElement(LoadingIndicator, { type: "circle" })
                                ),
                                React.createElement(
                                    "span",
                                    {
                                        style: {
                                            float: "right",
                                            marginRight: "6px",
                                            marginTop: "11px"
                                        }
                                    },
                                    loadingMessage
                                )
                            )
                        );
                    }
                    break;
            }

            var buttonStyle = {
                overflow: "hidden",
                position: "relative"
            };
            if (fixButtonWidth && this.state.loadingButtonWidth != null) {
                buttonStyle.width = this.state.loadingButtonWidth;
            }
            return React.createElement(
                "div",
                { style: this.props.style },
                leftElement != null && leftElement,
                React.createElement(
                    "span",
                    { style: { float: "left" } },
                    React.createElement(
                        Button,
                        {
                            ref: function ref(instance) {
                                _this2.loadingButton = instance;
                            },
                            disabled: loadingState,
                            type: this.props.type,
                            className: this.props.className,
                            id: this.props.id,
                            onClick: this._onClick.bind(this),
                            style: buttonStyle
                        },
                        buttonInner
                    )
                ),
                rightElement != null && rightElement,
                React.createElement("div", { style: { clear: "both" } })
            );
        }
    }]);

    return LoadingButton;
}(React.Component);

LoadingButton.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    type: PropTypes.string,
    style: PropTypes.object,
    //
    caption: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    //
    loadingType: PropTypes.string,
    loadingMessage: PropTypes.string,
    //
    isLoading: PropTypes.bool
};
LoadingButton.defaultProps = {
    style: {},
    isLoading: null,
    className: "button",
    type: "button",
    loadingType: "inside-feedback",
    loadingMessage: null
};


export default LoadingButton;