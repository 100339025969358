var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from "react";
import FormattedAsset from "../Utility/FormattedAsset";
import { Link } from "react-router-dom";
import Translate from "react-translate-component";
import counterpart from "counterpart";
import utils from "common/utils";
import LinkToAccountById from "../Utility/LinkToAccountById";
import LinkToAssetById from "../Utility/LinkToAssetById";
import { ChainStore, ChainTypes as grapheneChainTypes } from "bitsharesjs";
// import account_constants from "chain/account_constants";
var operations = grapheneChainTypes.operations;

import PropTypes from "prop-types";
import opComponents from "./operations";
import TranslateWithLinks from "../Utility/TranslateWithLinks";

require("./operations.scss");

var ops = Object.keys(operations);
// let listings = account_constants.account_listing;

export var TransactionIDAndExpiry = function TransactionIDAndExpiry(_ref) {
    var id = _ref.id,
        expiration = _ref.expiration,
        style = _ref.style;

    var endDate = counterpart.localize(new Date(expiration), {
        format: "short"
    });
    return React.createElement(
        "b",
        { style: style },
        React.createElement(
            "span",
            null,
            id,
            " | "
        ),
        React.createElement(
            "span",
            null,
            React.createElement(Translate, { content: "proposal.expires" }),
            ": ",
            endDate
        )
    );
};

var Row = function (_React$Component) {
    _inherits(Row, _React$Component);

    function Row(props) {
        _classCallCheck(this, Row);

        var _this = _possibleConstructorReturn(this, (Row.__proto__ || Object.getPrototypeOf(Row)).call(this, props));

        _this.showDetails = _this.showDetails.bind(_this);
        return _this;
    }

    _createClass(Row, [{
        key: "showDetails",
        value: function showDetails(e) {
            e.preventDefault();
            this.props.history.push("/block/" + this.props.block);
        }
    }, {
        key: "render",
        value: function render() {
            var _props = this.props,
                id = _props.id,
                fee = _props.fee,
                hideFee = _props.hideFee,
                hideExpiration = _props.hideExpiration,
                expiration = _props.expiration;


            fee.amount = parseInt(fee.amount, 10);

            return React.createElement(
                "div",
                { style: { padding: "5px 0", textAlign: "left" } },
                React.createElement(
                    "span",
                    null,
                    this.props.info,
                    "\xA0",
                    hideFee ? null : React.createElement(
                        "span",
                        { className: "facolor-fee" },
                        "(",
                        React.createElement(FormattedAsset, {
                            amount: fee.amount,
                            asset: fee.asset_id
                        }),
                        " ",
                        "fee)"
                    )
                ),
                !hideExpiration && this.props.expiration && React.createElement(TransactionIDAndExpiry, {
                    id: id,
                    expiration: expiration,
                    style: {
                        paddingTop: 5,
                        fontSize: "0.85rem",
                        paddingBottom: "0.5rem",
                        display: "block"
                    }
                })
            );
        }
    }]);

    return Row;
}(React.Component);

var ProposedOperation = function (_React$Component2) {
    _inherits(ProposedOperation, _React$Component2);

    function ProposedOperation() {
        var _ref2;

        var _temp, _this2, _ret;

        _classCallCheck(this, ProposedOperation);

        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
        }

        return _ret = (_temp = (_this2 = _possibleConstructorReturn(this, (_ref2 = ProposedOperation.__proto__ || Object.getPrototypeOf(ProposedOperation)).call.apply(_ref2, [this].concat(args))), _this2), _this2.state = {
            label_color: "info"
        }, _this2.changeColor = function (newColor) {
            var label_color = _this2.state.label_color;

            if (label_color !== newColor) {
                _this2.setState({ label_color: newColor });
            }
        }, _temp), _possibleConstructorReturn(_this2, _ret);
    }

    _createClass(ProposedOperation, [{
        key: "linkToAccount",
        value: function linkToAccount(name_or_id) {
            if (!name_or_id) return React.createElement(
                "span",
                null,
                "-"
            );
            return utils.is_object_id(name_or_id) ? React.createElement(LinkToAccountById, { account: name_or_id }) : React.createElement(
                Link,
                { to: "/account/" + name_or_id + "/overview" },
                name_or_id
            );
        }
    }, {
        key: "linkToAsset",
        value: function linkToAsset(symbol_or_id) {
            if (!symbol_or_id) return React.createElement(
                "span",
                null,
                "-"
            );
            return utils.is_object_id(symbol_or_id) ? React.createElement(LinkToAssetById, { asset: symbol_or_id }) : React.createElement(
                Link,
                { to: "/asset/" + symbol_or_id },
                symbol_or_id
            );
        }
    }, {
        key: "render",


        // TODO: add scu

        value: function render() {
            var _props2 = this.props,
                op = _props2.op,
                proposer = _props2.proposer,
                block = _props2.block,
                hideExpiration = _props2.hideExpiration,
                index = _props2.index,
                csvExportMode = _props2.csvExportMode;
            var label_color = this.state.label_color;

            var line = null,
                column = null;

            column = opComponents(ops[op[0]], this.props, {
                fromComponent: "proposed_operation",
                linkToAccount: this.linkToAccount,
                linkToAsset: this.linkToAsset,
                changeColor: this.changeColor
            });

            if (!!proposer && index == 0) {
                column = React.createElement(
                    "div",
                    { className: "inline-block" },
                    React.createElement(
                        "div",
                        { style: { paddingBottom: "0.5rem" } },
                        React.createElement(TranslateWithLinks, {
                            string: "operation.proposal_create",
                            keys: [{
                                type: "account",
                                value: proposer,
                                arg: "account"
                            }]
                        }),
                        ":"
                    ),
                    React.createElement(
                        "div",
                        { style: { marginLeft: "0.5rem" } },
                        column
                    )
                );
            }

            if (csvExportMode) {
                var globalObject = ChainStore.getObject("2.0.0");
                var dynGlobalObject = ChainStore.getObject("2.1.0");
                var block_time = utils.calc_block_time(block, globalObject, dynGlobalObject);
                return React.createElement(
                    "div",
                    { key: this.props.key },
                    React.createElement(
                        "div",
                        null,
                        block_time ? block_time.toLocaleString() : ""
                    ),
                    React.createElement(
                        "div",
                        null,
                        ops[op[0]]
                    ),
                    React.createElement(
                        "div",
                        null,
                        column
                    ),
                    React.createElement(
                        "div",
                        null,
                        React.createElement(FormattedAsset, {
                            amount: parseInt(op[1].fee.amount, 10),
                            asset: op[1].fee.asset_id
                        })
                    )
                );
            }

            line = column ? React.createElement(Row, {
                index: index,
                id: this.props.id,
                block: block,
                type: op[0],
                color: label_color,
                fee: op[1].fee,
                hideDate: this.props.hideDate,
                hideFee: this.props.hideFee,
                hideOpLabel: this.props.hideOpLabel,
                info: column,
                expiration: this.props.expiration,
                hideExpiration: hideExpiration
            }) : null;

            return line ? line : React.createElement("div", null);
        }
    }]);

    return ProposedOperation;
}(React.Component);

ProposedOperation.defaultProps = {
    op: [],
    current: "",
    block: null,
    hideDate: false,
    hideFee: false,
    hideOpLabel: false,
    csvExportMode: false,
    collapsed: true
};
ProposedOperation.propTypes = {
    op: PropTypes.array.isRequired,
    current: PropTypes.string,
    block: PropTypes.number,
    hideDate: PropTypes.bool,
    hideFee: PropTypes.bool,
    csvExportMode: PropTypes.bool,
    collapsed: PropTypes.bool
};


export default ProposedOperation;