var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import alt from "alt-instance";
import iDB from "idb-instance";
import { key } from "bitsharesjs";
import { ChainConfig } from "bitsharesjs-ws";
import Immutable from "immutable";
import BaseStore from "stores/BaseStore";

var AddressIndexWorker = void 0;
if (__ELECTRON__) {
    AddressIndexWorker = require("worker-loader?inline!workers/AddressIndexWorker");
}

var AddressIndex = function (_BaseStore) {
    _inherits(AddressIndex, _BaseStore);

    function AddressIndex() {
        _classCallCheck(this, AddressIndex);

        var _this = _possibleConstructorReturn(this, (AddressIndex.__proto__ || Object.getPrototypeOf(AddressIndex)).call(this));

        _this.state = {
            addresses: Immutable.Map(),
            saving: false
        };
        _this.pubkeys = new Set();
        // loadAddyMap is for debugging, this.add will load this on startup
        _this._export("add", "addAll", "loadAddyMap");
        return _this;
    }

    _createClass(AddressIndex, [{
        key: "saving",
        value: function saving() {
            if (this.state.saving) return;
            this.state.saving = true;
            this.setState({ saving: true });
        }

        /** Add public key string (if not already added).  Reasonably efficient
            for less than 10K keys.
        */

    }, {
        key: "add",
        value: function add(pubkey) {
            var _this2 = this;

            this.loadAddyMap().then(function () {
                var dirty = false;
                if (_this2.pubkeys.has(pubkey)) return;
                _this2.pubkeys.add(pubkey);
                _this2.saving();
                // Gather all 5 legacy address formats (see key.addresses)
                var address_strings = key.addresses(pubkey);
                var _iteratorNormalCompletion = true;
                var _didIteratorError = false;
                var _iteratorError = undefined;

                try {
                    for (var _iterator = address_strings[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                        var address = _step.value;

                        _this2.state.addresses = _this2.state.addresses.set(address, pubkey);
                        dirty = true;
                    }
                } catch (err) {
                    _didIteratorError = true;
                    _iteratorError = err;
                } finally {
                    try {
                        if (!_iteratorNormalCompletion && _iterator.return) {
                            _iterator.return();
                        }
                    } finally {
                        if (_didIteratorError) {
                            throw _iteratorError;
                        }
                    }
                }

                if (dirty) {
                    _this2.setState({ addresses: _this2.state.addresses });
                    _this2.saveAddyMap();
                } else {
                    _this2.setState({ saving: false });
                }
            }).catch(function (e) {
                throw e;
            });
        }

        /** Worker thread implementation (for more than 10K keys) */

    }, {
        key: "addAll",
        value: function addAll(pubkeys) {
            var _this3 = this;

            return new Promise(function (resolve, reject) {
                _this3.saving();
                _this3.loadAddyMap().then(function () {
                    if (!__ELECTRON__) {
                        AddressIndexWorker = require("worker-loader!workers/AddressIndexWorker");
                    }
                    var worker = new AddressIndexWorker();
                    worker.postMessage({
                        pubkeys: pubkeys,
                        address_prefix: ChainConfig.address_prefix
                    });
                    // let _this = this
                    worker.onmessage = function (event) {
                        try {
                            var key_addresses = event.data;
                            var dirty = false;
                            var addresses = _this3.state.addresses.withMutations(function (addresses) {
                                for (var i = 0; i < pubkeys.length; i++) {
                                    var pubkey = pubkeys[i];
                                    if (_this3.pubkeys.has(pubkey)) continue;
                                    _this3.pubkeys.add(pubkey);
                                    // Gather all 5 legacy address formats (see key.addresses)
                                    var address_strings = key_addresses[i];
                                    var _iteratorNormalCompletion2 = true;
                                    var _didIteratorError2 = false;
                                    var _iteratorError2 = undefined;

                                    try {
                                        for (var _iterator2 = address_strings[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
                                            var address = _step2.value;

                                            addresses.set(address, pubkey);
                                            dirty = true;
                                        }
                                    } catch (err) {
                                        _didIteratorError2 = true;
                                        _iteratorError2 = err;
                                    } finally {
                                        try {
                                            if (!_iteratorNormalCompletion2 && _iterator2.return) {
                                                _iterator2.return();
                                            }
                                        } finally {
                                            if (_didIteratorError2) {
                                                throw _iteratorError2;
                                            }
                                        }
                                    }
                                }
                            });
                            if (dirty) {
                                _this3.setState({ addresses: addresses });
                                _this3.saveAddyMap();
                            } else {
                                _this3.setState({ saving: false });
                            }
                            resolve();
                        } catch (e) {
                            console.error("AddressIndex.addAll", e);
                            reject(e);
                        }
                    };
                }).catch(function (e) {
                    throw e;
                });
            });
        }
    }, {
        key: "loadAddyMap",
        value: function loadAddyMap() {
            var _this4 = this;

            if (this.loadAddyMapPromise) return this.loadAddyMapPromise;
            this.loadAddyMapPromise = iDB.root.getProperty("AddressIndex").then(function (map) {
                _this4.state.addresses = map ? Immutable.Map(map) : Immutable.Map();
                // console.log("AddressIndex load", this.state.addresses.size);
                _this4.state.addresses.valueSeq().forEach(function (pubkey) {
                    return _this4.pubkeys.add(pubkey);
                });
                _this4.setState({ addresses: _this4.state.addresses });
            });
            return this.loadAddyMapPromise;
        }
    }, {
        key: "saveAddyMap",
        value: function saveAddyMap() {
            var _this5 = this;

            clearTimeout(this.saveAddyMapTimeout);
            this.saveAddyMapTimeout = setTimeout(function () {
                // console.log("AddressIndex save", this.state.addresses.size);
                _this5.setState({ saving: false });
                // If indexedDB fails to save, it will re-try via PrivateKeyStore calling this.add
                return iDB.root.setProperty("AddressIndex", _this5.state.addresses.toObject());
            }, 100);
        }
    }]);

    return AddressIndex;
}(BaseStore);
// console.log("post msg a");
// worker.postMessage("a")


export default alt.createStore(AddressIndex, "AddressIndex");