var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from "react";
import { Input, Form, Select } from "bitshares-ui-style-guide";
import counterpart from "counterpart";
import ChainStore from "bitsharesjs/es/chain/src/ChainStore";
import accountUtils from "../../lib/common/account_utils";

var AccountInputStyleGuide = function (_Component) {
    _inherits(AccountInputStyleGuide, _Component);

    function AccountInputStyleGuide(props) {
        _classCallCheck(this, AccountInputStyleGuide);

        var _this = _possibleConstructorReturn(this, (AccountInputStyleGuide.__proto__ || Object.getPrototypeOf(AccountInputStyleGuide)).call(this, props));

        _this.state = {
            // is user cursor on input or not
            isInputActive: false
        };

        _this.handleBlur = _this.handleBlur.bind(_this);
        _this.handleFocus = _this.handleFocus.bind(_this);
        _this.handleInputChange = _this.handleInputChange.bind(_this);
        return _this;
    }

    _createClass(AccountInputStyleGuide, [{
        key: "componentDidUpdate",
        value: function componentDidUpdate() {
            if (this.props.focus) {
                this.refs.input.focus();
            }
        }
    }, {
        key: "handleBlur",
        value: function handleBlur() {
            this.setState({
                isInputActive: false
            });
        }
    }, {
        key: "handleFocus",
        value: function handleFocus() {
            this.setState({
                isInputActive: true
            });
        }
    }, {
        key: "handleInputChange",
        value: function handleInputChange(e) {
            this.props.onChange(e.target.value);
        }
    }, {
        key: "isAccountScammer",
        value: function isAccountScammer() {
            var account = ChainStore.getAccount(this.props.value);

            if (account && account.get) {
                return accountUtils.isKnownScammer(account.get("name"));
            }

            return false;
        }
    }, {
        key: "getAccountStatus",
        value: function getAccountStatus() {
            var account = ChainStore.getAccount(this.props.value);

            if (account && account.get) {
                // is scammer
                var isKnownScammer = this.isAccountScammer();

                if (isKnownScammer) {
                    return counterpart.translate("account.member.suspected_scammer");
                }

                // get status (basic or lifetime member)
                var accountStatus = ChainStore.getAccountMemberStatus(account);

                return counterpart.translate("account.member." + accountStatus) + " #" + account.get("id").substring(4);
            }

            return null;
        }
    }, {
        key: "isAccountFound",
        value: function isAccountFound() {
            var account = ChainStore.getAccount(this.props.value);

            return !!account;
        }
    }, {
        key: "getValidateStatus",
        value: function getValidateStatus() {
            if (this.state.isInputActive || !this.props.value) return "";

            if (this.isAccountFound()) return "success";

            return "error";
        }
    }, {
        key: "getHelp",
        value: function getHelp() {
            if (this.state.isInputActive || !this.props.value) return "";

            if (!this.isAccountFound()) return counterpart.translate("account.errors.unknown");

            return "";
        }
    }, {
        key: "getPlaceholder",
        value: function getPlaceholder() {
            if (this.props.placeholder) return counterpart.translate(this.props.placeholder);

            return "";
        }
    }, {
        key: "input",
        value: function input() {
            var value = this.props.value;
            var onChange = this.handleInputChange;

            return React.createElement(Input, {
                ref: "input",
                placeholder: this.getPlaceholder(),
                value: value,
                onChange: onChange,
                onBlur: this.handleBlur,
                onFocus: this.handleFocus
            });
        }
    }, {
        key: "labelComponent",
        value: function labelComponent() {
            var _this2 = this;

            var accountStatus = this.getAccountStatus();

            var getStatus = function getStatus() {
                if (_this2.isAccountScammer()) return "account-input-style-guide--account-status--scammer";
            };

            var getLabel = function getLabel() {
                return React.createElement(
                    "span",
                    { className: "account-input-style-guide--label" },
                    counterpart.translate(_this2.props.label),
                    React.createElement(
                        "span",
                        {
                            className: "account-input-style-guide--account-status " + getStatus()
                        },
                        accountStatus
                    )
                );
            };

            return React.createElement(
                Form.Item,
                {
                    style: { textAlign: "left" },
                    label: getLabel(),
                    help: this.getHelp(),
                    validateStatus: this.getValidateStatus()
                },
                this.input()
            );
        }
    }, {
        key: "simpleComponent",
        value: function simpleComponent() {
            this.input();
        }
    }, {
        key: "render",
        value: function render() {
            return React.createElement(
                "div",
                { className: "account-input-style-guide" },
                this.props.label ? this.labelComponent() : this.simpleComponent()
            );
        }
    }]);

    return AccountInputStyleGuide;
}(Component);

export default AccountInputStyleGuide;