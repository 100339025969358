import React from "react";
import TranslateWithLinks from "../../Utility/TranslateWithLinks";

export var HtlcExtend = function HtlcExtend(_ref) {
    var op = _ref.op;

    return React.createElement(
        "span",
        { className: "right-td" },
        React.createElement(TranslateWithLinks, {
            string: "operation.htlc_extend",
            keys: [{
                type: "account",
                value: op[1].update_issuer,
                arg: "update_issuer"
            }, {
                type: "timespan",
                arg: "seconds_to_add",
                value: op[1].seconds_to_add
            }, {
                value: op[1].htlc_id,
                arg: "htlc_id"
            }]
        })
    );
};