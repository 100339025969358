var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

import WalletDb from "stores/WalletDb";
import WalletUnlockActions from "actions/WalletUnlockActions";
import CachedPropertyActions from "actions/CachedPropertyActions";
import ApplicationApi from "api/ApplicationApi";
import { TransactionBuilder, FetchChain } from "bitsharesjs";
import { Apis } from "bitsharesjs-ws";
import alt from "alt-instance";
import SettingsStore from "stores/SettingsStore";

var WalletActions = function () {
    function WalletActions() {
        _classCallCheck(this, WalletActions);
    }

    _createClass(WalletActions, [{
        key: "restore",

        /** Restore and make active a new wallet_object. */
        value: function restore() {
            var wallet_name = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "default";
            var wallet_object = arguments[1];

            wallet_name = wallet_name.toLowerCase();
            return { wallet_name: wallet_name, wallet_object: wallet_object };
        }

        /** Make an existing wallet active or create a wallet (and make it active).
            If <b>wallet_name</b> does not exist, provide a <b>create_wallet_password</b>.
        */

    }, {
        key: "setWallet",
        value: function setWallet(wallet_name, create_wallet_password, brnkey) {
            WalletUnlockActions.lock();
            if (!wallet_name) wallet_name = "default";
            return function (dispatch) {
                return new Promise(function (resolve) {
                    dispatch({
                        wallet_name: wallet_name,
                        create_wallet_password: create_wallet_password,
                        brnkey: brnkey,
                        resolve: resolve
                    });
                });
            };
        }
    }, {
        key: "setBackupDate",
        value: function setBackupDate() {
            CachedPropertyActions.set("backup_recommended", false);
            return true;
        }
    }, {
        key: "setBrainkeyBackupDate",
        value: function setBrainkeyBackupDate() {
            return true;
        }
    }, {
        key: "deleteWallet",
        value: function deleteWallet(name) {
            return name;
        }
    }, {
        key: "createAccountWithPassword",
        value: function createAccountWithPassword(account_name, password, registrar, referrer, referrer_percent, refcode) {
            var _WalletDb$generateKey = WalletDb.generateKeyFromPassword(account_name, "owner", password),
                owner_private = _WalletDb$generateKey.privKey;

            var _WalletDb$generateKey2 = WalletDb.generateKeyFromPassword(account_name, "active", password),
                active_private = _WalletDb$generateKey2.privKey;

            var _WalletDb$generateKey3 = WalletDb.generateKeyFromPassword(account_name, "memo", password),
                memo_private = _WalletDb$generateKey3.privKey;

            console.log("create account:", account_name);
            console.log("new active pubkey", active_private.toPublicKey().toPublicKeyString());
            console.log("new owner pubkey", owner_private.toPublicKey().toPublicKeyString());
            console.log("new memo pubkey", memo_private.toPublicKey().toPublicKeyString());

            return new Promise(function (resolve, reject) {
                var create_account = function create_account() {
                    return ApplicationApi.create_account(owner_private.toPublicKey().toPublicKeyString(), active_private.toPublicKey().toPublicKeyString(), memo_private.toPublicKey().toPublicKeyString(), account_name, registrar, //registrar_id,
                    referrer, //referrer_id,
                    referrer_percent, //referrer_percent,
                    true //broadcast
                    ).then(resolve).catch(reject);
                };

                if (registrar) {
                    // using another user's account as registrar
                    return create_account();
                } else {
                    // using faucet

                    var faucetAddress = SettingsStore.getSetting("faucet_address");
                    if (window && window.location && window.location.protocol === "https:") {
                        faucetAddress = faucetAddress.replace(/http:\/\//, "https://");
                    }

                    var create_account_promise = fetch(faucetAddress + "/api/v1/accounts", {
                        method: "post",
                        mode: "cors",
                        headers: {
                            Accept: "application/json",
                            "Content-type": "application/json"
                        },
                        body: JSON.stringify({
                            account: {
                                name: account_name,
                                owner_key: owner_private.toPublicKey().toPublicKeyString(),
                                active_key: active_private.toPublicKey().toPublicKeyString(),
                                memo_key: memo_private.toPublicKey().toPublicKeyString(),
                                refcode: refcode,
                                referrer: referrer
                            }
                        })
                    }).then(function (r) {
                        return r.json().then(function (res) {
                            if (!res || res && res.error) {
                                reject(res.error);
                            } else {
                                resolve(res);
                            }
                        });
                    }).catch(reject);

                    return create_account_promise.then(function (result) {
                        if (result && result.error) {
                            reject(result.error);
                        } else {
                            resolve(result);
                        }
                    }).catch(function (error) {
                        reject(error);
                    });
                }
            });
        }
    }, {
        key: "createAccount",
        value: function createAccount(account_name, registrar, referrer, referrer_percent, refcode) {
            if (WalletDb.isLocked()) {
                var error = "wallet locked";
                //this.actions.brainKeyAccountCreateError( error )
                return Promise.reject(error);
            }
            var owner_private = WalletDb.generateNextKey();
            var active_private = WalletDb.generateNextKey();
            var memo_private = WalletDb.generateNextKey();

            var updateWallet = function updateWallet() {
                var transaction = WalletDb.transaction_update_keys();
                var p = WalletDb.saveKeys([owner_private, active_private, memo_private], transaction);
                return p.catch(function () {
                    return transaction.abort();
                });
            };

            var create_account = function create_account() {
                return ApplicationApi.create_account(owner_private.private_key.toPublicKey().toPublicKeyString(), active_private.private_key.toPublicKey().toPublicKeyString(), memo_private.private_key.toPublicKey().toPublicKeyString(), account_name, registrar, //registrar_id,
                referrer, //referrer_id,
                referrer_percent, //referrer_percent,
                true //broadcast
                ).then(function () {
                    return updateWallet();
                });
            };

            if (registrar) {
                // using another user's account as registrar
                return create_account();
            } else {
                // using faucet

                var faucetAddress = SettingsStore.getSetting("faucet_address");
                if (window && window.location && window.location.protocol === "https:") {
                    faucetAddress = faucetAddress.replace(/http:\/\//, "https://");
                }

                var create_account_promise = fetch(faucetAddress + "/api/v1/accounts", {
                    method: "post",
                    mode: "cors",
                    headers: {
                        Accept: "application/json",
                        "Content-type": "application/json"
                    },
                    body: JSON.stringify({
                        account: {
                            name: account_name,
                            owner_key: owner_private.private_key.toPublicKey().toPublicKeyString(),
                            active_key: active_private.private_key.toPublicKey().toPublicKeyString(),
                            memo_key: active_private.private_key.toPublicKey().toPublicKeyString(),
                            //"memo_key": memo_private.private_key.toPublicKey().toPublicKeyString(),
                            refcode: refcode,
                            referrer: referrer
                        }
                    })
                }).then(function (r) {
                    return r.json();
                });

                return create_account_promise.then(function (result) {
                    if (result.error) {
                        throw result.error;
                    }
                    return updateWallet();
                }).catch(function (error) {
                    /*
                    * Since the account creation failed, we need to decrement the
                    * sequence used to generate private keys from the brainkey. Three
                    * keys were generated, so we decrement three times.
                    */
                    WalletDb.decrementBrainKeySequence();
                    WalletDb.decrementBrainKeySequence();
                    WalletDb.decrementBrainKeySequence();
                    throw error;
                });
            }
        }
    }, {
        key: "claimVestingBalance",
        value: function claimVestingBalance(account, vb) {
            var forceAll = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

            var tr = new TransactionBuilder();

            var balance = void 0;
            var available_percentage = void 0;

            if (vb) {
                balance = vb.balance.amount;

                // Vesting is 100% available if:
                // - policy[0] is set to 2
                // - vesting_seconds is 0
                // - foreAll is set to true
                available_percentage = vb.policy[0] === 2 || vb.policy[1].vesting_seconds === 0 || forceAll ? 1 : 0;

                // Vesting percentage needs to be checked further
                if (!available_percentage && vb.policy && vb.policy[0] !== 2) {
                    var start = Math.floor(new Date(vb.policy[1].start_claim + "Z").getTime() / 1000);
                    var now = Math.floor(new Date().getTime() / 1000);

                    if (start > 0) {
                        // Vesting has a specific start date.
                        // Vesting with locked value required to mautre fully before claiming
                        // Full vesting period must pass before it can be claimed.
                        // Calculate days left before a claim is possible
                        // Example asset is BRIDGE.BCO - 1.3.1564

                        var seconds_earned = now - start;
                        var seconds_period = vb.policy[1].vesting_seconds;

                        if (seconds_earned >= seconds_period) {
                            available_percentage = 1;
                        }
                    } else {
                        // Vesting has no start time.
                        // Vesting balances has a vesting with maturing value
                        // If period is 0 we expect a 100% claimable balance
                        // otherwise we expect to be allowed to claim the matured percentage.

                        // Core is lazy calculating the vesting balance object, so we
                        // need to account for the time passed since it was last updated
                        var seconds_last_updated = Math.floor(new Date(vb.policy[1].coin_seconds_earned_last_update + "Z").getTime() / 1000);
                        var _seconds_earned = parseFloat(vb.policy[1].coin_seconds_earned) + balance * (now - seconds_last_updated);
                        var _seconds_period = vb.policy[1].vesting_seconds;

                        available_percentage = _seconds_earned / (_seconds_period * balance);

                        // Make sure we don't go over 1
                        available_percentage = available_percentage > 1 ? 1 : available_percentage;
                    }
                }
            }

            tr.add_type_operation("vesting_balance_withdraw", {
                fee: { amount: "0", asset_id: "1.3.0" },
                owner: account,
                vesting_balance: vb.id,
                amount: {
                    amount: Math.floor(balance * available_percentage),
                    asset_id: vb.balance.asset_id
                }
            });

            return WalletDb.process_transaction(tr, null, true).then(function (result) {}).catch(function (err) {
                console.log("vesting_balance_withdraw err:", err);
            });
        }

        /** @parm balances is an array of balance objects with two
            additional values: {vested_balance, public_key_string}
        */

    }, {
        key: "importBalance",
        value: function importBalance(account_name_or_id, balances, broadcast) {
            return function (dispatch) {
                return new Promise(function (resolve, reject) {
                    var db = Apis.instance().db_api();
                    var address_publickey_map = {};

                    var account_lookup = FetchChain("getAccount", account_name_or_id);
                    var unlock = WalletUnlockActions.unlock();

                    var p = Promise.all([unlock, account_lookup]).then(function (results) {
                        var account = results[1];
                        //DEBUG console.log('... account',account)
                        if (account == void 0) return Promise.reject("Unknown account " + account_name_or_id);

                        var balance_claims = [];
                        var signer_pubkeys = {};
                        var _iteratorNormalCompletion = true;
                        var _didIteratorError = false;
                        var _iteratorError = undefined;

                        try {
                            for (var _iterator = balances[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                                var balance = _step.value;
                                var vested_balance = balance.vested_balance,
                                    public_key_string = balance.public_key_string;

                                //DEBUG console.log('... balance',b)

                                var total_claimed = void 0;
                                if (vested_balance) {
                                    if (vested_balance.amount == 0)
                                        // recently claimed
                                        continue;

                                    total_claimed = vested_balance.amount;
                                } else total_claimed = balance.balance.amount;

                                //assert
                                if (vested_balance && vested_balance.asset_id != balance.balance.asset_id) throw new Error("Vested balance record and balance record asset_id missmatch", vested_balance.asset_id, balance.balance.asset_id);

                                signer_pubkeys[public_key_string] = true;
                                balance_claims.push({
                                    fee: { amount: "0", asset_id: "1.3.0" },
                                    deposit_to_account: account.get("id"),
                                    balance_to_claim: balance.id,
                                    balance_owner_key: public_key_string,
                                    total_claimed: {
                                        amount: total_claimed,
                                        asset_id: balance.balance.asset_id
                                    }
                                });
                            }
                            //  if( ! balance_claims.length) {
                            //      throw new Error("No balances to claim");
                            //  }

                            //DEBUG console.log('... balance_claims',balance_claims)
                        } catch (err) {
                            _didIteratorError = true;
                            _iteratorError = err;
                        } finally {
                            try {
                                if (!_iteratorNormalCompletion && _iterator.return) {
                                    _iterator.return();
                                }
                            } finally {
                                if (_didIteratorError) {
                                    throw _iteratorError;
                                }
                            }
                        }

                        var tr = new TransactionBuilder();

                        var _iteratorNormalCompletion2 = true;
                        var _didIteratorError2 = false;
                        var _iteratorError2 = undefined;

                        try {
                            for (var _iterator2 = balance_claims[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
                                var balance_claim = _step2.value;

                                tr.add_type_operation("balance_claim", balance_claim);
                            }
                            // With a lot of balance claims the signing can take so Long
                            // the transaction will expire.  This will increase the timeout...
                        } catch (err) {
                            _didIteratorError2 = true;
                            _iteratorError2 = err;
                        } finally {
                            try {
                                if (!_iteratorNormalCompletion2 && _iterator2.return) {
                                    _iterator2.return();
                                }
                            } finally {
                                if (_didIteratorError2) {
                                    throw _iteratorError2;
                                }
                            }
                        }

                        tr.set_expire_seconds(15 * 60 + balance_claims.length);
                        return WalletDb.process_transaction(tr, Object.keys(signer_pubkeys), broadcast).then(function (result) {
                            dispatch(true);
                            return result;
                        });
                    }).catch(function () {});
                    resolve(p);
                });
            };
        }
    }]);

    return WalletActions;
}();

export default alt.createActions(WalletActions);