var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from "react";
import PropTypes from "prop-types";
import counterpart from "counterpart";
import { Modal, Button } from "bitshares-ui-style-guide";

var ChoiceModal = function (_React$Component) {
    _inherits(ChoiceModal, _React$Component);

    function ChoiceModal() {
        _classCallCheck(this, ChoiceModal);

        return _possibleConstructorReturn(this, (ChoiceModal.__proto__ || Object.getPrototypeOf(ChoiceModal)).apply(this, arguments));
    }

    _createClass(ChoiceModal, [{
        key: "confirmClicked",
        value: function confirmClicked(callback, e) {
            var _this2 = this;

            e.preventDefault();
            setTimeout(function () {
                _this2.props.hideModal();
            }, 500);
            callback();
        }
    }, {
        key: "render",
        value: function render() {
            var _this3 = this;

            var footer = [];

            this.props.choices.map(function (child, key) {
                footer.push(React.createElement(
                    Button,
                    {
                        type: "primary",
                        key: key,
                        onClick: _this3.confirmClicked.bind(_this3, child.callback)
                    },
                    counterpart.translate(child.translationKey)
                ));
            });

            footer.push(React.createElement(
                Button,
                { key: "cancel", onClick: this.props.hideModal },
                counterpart.translate("modal.cancel")
            ));

            return React.createElement(
                Modal,
                {
                    width: 600,
                    title: counterpart.translate("connection.title_out_of_sync"),
                    visible: this.props.visible,
                    onCancel: this.props.hideModal,
                    footer: footer,
                    id: this.props.modalId,
                    overlay: true
                },
                React.createElement(
                    "div",
                    { className: "grid-block vertical" },
                    this.props.content,
                    React.Children.map(this.props.children, function (child, i) {
                        var props = {};
                        props["key"] = i + "";
                        return React.cloneElement(child, props);
                    })
                )
            );
        }
    }]);

    return ChoiceModal;
}(React.Component);

ChoiceModal.propTypes = {
    choices: PropTypes.array.isRequired,
    content: PropTypes.object
};
ChoiceModal.defaultProps = {
    content: null
};


export default ChoiceModal;