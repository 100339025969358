var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import AccountActions from "actions/AccountActions";
import AccountStore from "stores/AccountStore";
import { ChainValidation } from "bitsharesjs";
import counterpart from "counterpart";
import AltContainer from "alt-container";
import ReactTooltip from "react-tooltip";
import { Form, Input } from "bitshares-ui-style-guide";

var AccountNameInput = function (_React$Component) {
    _inherits(AccountNameInput, _React$Component);

    function AccountNameInput() {
        _classCallCheck(this, AccountNameInput);

        var _this = _possibleConstructorReturn(this, (AccountNameInput.__proto__ || Object.getPrototypeOf(AccountNameInput)).call(this));

        _this.state = {
            value: null,
            error: null,
            existing_account: false
        };

        _this.handleChange = _this.handleChange.bind(_this);
        _this.onKeyDown = _this.onKeyDown.bind(_this);
        return _this;
    }

    _createClass(AccountNameInput, [{
        key: "shouldComponentUpdate",
        value: function shouldComponentUpdate(nextProps, nextState) {
            return nextState.value !== this.state.value || nextState.error !== this.state.error || nextState.account_name !== this.state.account_name || nextState.existing_account !== this.state.existing_account || nextProps.searchAccounts !== this.props.searchAccounts;
        }
    }, {
        key: "componentDidUpdate",
        value: function componentDidUpdate() {
            ReactTooltip.rebuild();

            if (this.props.onChange) this.props.onChange({ valid: !this.getError() });
        }
    }, {
        key: "getValue",
        value: function getValue() {
            return this.state.value;
        }
    }, {
        key: "setValue",
        value: function setValue(value) {
            this.setState({ value: value });
        }
    }, {
        key: "clear",
        value: function clear() {
            this.setState({ account_name: null, error: null, warning: null });
        }
    }, {
        key: "focus",
        value: function focus() {
            this.refs.input.focus();
        }
    }, {
        key: "valid",
        value: function valid() {
            return !this.getError();
        }
    }, {
        key: "getError",
        value: function getError() {
            var _this2 = this;

            if (this.state.value === null) return null;
            var error = null;
            if (this.state.error) {
                error = this.state.error;
            } else if (this.props.accountShouldExist || this.props.accountShouldNotExist) {
                var account = this.props.searchAccounts.find(function (a) {
                    return a === _this2.state.value;
                });
                if (this.props.accountShouldNotExist && account) {
                    error = counterpart.translate("account.name_input.name_is_taken");
                }
                if (this.props.accountShouldExist && !account) {
                    error = counterpart.translate("account.name_input.not_found");
                }
            }
            return error;
        }
    }, {
        key: "validateAccountName",
        value: function validateAccountName(value) {
            this.state.error = value === "" ? "Please enter valid account name" : ChainValidation.is_account_name_error(value);

            this.state.warning = null;
            if (this.props.cheapNameOnly) {
                if (!this.state.error && !ChainValidation.is_cheap_name(value)) this.state.error = counterpart.translate("account.name_input.premium_name_faucet");
            } else {
                if (!this.state.error && !ChainValidation.is_cheap_name(value)) this.state.warning = counterpart.translate("account.name_input.premium_name_warning");
            }
            this.setState({
                value: value,
                error: this.state.error,
                warning: this.state.warning
            });
            if (this.props.onChange) this.props.onChange({ value: value, valid: !this.getError() });
            if (this.props.accountShouldExist || this.props.accountShouldNotExist) AccountActions.accountSearch(value);
        }
    }, {
        key: "handleChange",
        value: function handleChange(e) {
            e.preventDefault();
            e.stopPropagation();
            // Simplify the rules (prevent typing of invalid characters)
            var account_name = e.target.value.toLowerCase();
            account_name = account_name.match(/[a-z0-9\.-]+/);
            account_name = account_name ? account_name[0] : "";
            this.setState({ account_name: account_name });
            this.validateAccountName(account_name);
        }
    }, {
        key: "onKeyDown",
        value: function onKeyDown(e) {
            if (this.props.onEnter && event.keyCode === 13) this.props.onEnter(e);
        }
    }, {
        key: "render",
        value: function render() {
            var error = this.getError() || "";
            var class_name = classNames("form-group", "account-name", {
                "has-error": false
            });
            var warning = this.state.warning;
            // let {noLabel} = this.props;

            var getHelp = function getHelp() {
                return error ? error : warning ? warning : "";
            };

            var getValidateStatus = function getValidateStatus() {
                return error ? "error" : warning ? "warning" : "";
            };

            return React.createElement(
                Form.Item,
                {
                    label: this.props.label,
                    help: getHelp(),
                    validateStatus: getValidateStatus()
                },
                React.createElement(Input, {
                    name: "username",
                    id: "username",
                    type: "text",
                    ref: "input",
                    autoComplete: "username",
                    placeholder: this.props.placeholder,
                    onChange: this.handleChange,
                    onKeyDown: this.onKeyDown,
                    value: this.state.account_name || this.props.initial_value
                })
            );

            return React.createElement(
                "div",
                { className: class_name },
                React.createElement(
                    "section",
                    null,
                    React.createElement(
                        "label",
                        { className: "left-label" },
                        this.props.placeholder
                    ),
                    React.createElement("input", {
                        name: "username",
                        id: "username",
                        type: "text",
                        ref: "input",
                        autoComplete: "username",
                        placeholder: null,
                        onChange: this.handleChange,
                        onKeyDown: this.onKeyDown,
                        value: this.state.account_name || this.props.initial_value
                    })
                ),
                React.createElement(
                    "div",
                    { style: { textAlign: "left" }, className: "facolor-error" },
                    error
                ),
                React.createElement(
                    "div",
                    { style: { textAlign: "left" }, className: "facolor-warning" },
                    error ? null : warning
                )
            );
        }
    }]);

    return AccountNameInput;
}(React.Component);

AccountNameInput.propTypes = {
    id: PropTypes.string,
    placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    initial_value: PropTypes.string,
    onChange: PropTypes.func,
    onEnter: PropTypes.func,
    accountShouldExist: PropTypes.bool,
    accountShouldNotExist: PropTypes.bool,
    cheapNameOnly: PropTypes.bool,
    noLabel: PropTypes.bool
};
AccountNameInput.defaultProps = {
    noLabel: false
};

var StoreWrapper = function (_React$Component2) {
    _inherits(StoreWrapper, _React$Component2);

    function StoreWrapper() {
        _classCallCheck(this, StoreWrapper);

        return _possibleConstructorReturn(this, (StoreWrapper.__proto__ || Object.getPrototypeOf(StoreWrapper)).apply(this, arguments));
    }

    _createClass(StoreWrapper, [{
        key: "render",
        value: function render() {
            return React.createElement(
                AltContainer,
                {
                    stores: [AccountStore],
                    inject: {
                        searchAccounts: function searchAccounts() {
                            return AccountStore.getState().searchAccounts;
                        }
                    }
                },
                React.createElement(AccountNameInput, _extends({ ref: "nameInput" }, this.props))
            );
        }
    }]);

    return StoreWrapper;
}(React.Component);

export default StoreWrapper;