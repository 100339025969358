var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from "react";
import { connect } from "alt-react";
import BindToChainState from "../Utility/BindToChainState";
import GatewayStore from "stores/GatewayStore";
import counterpart from "counterpart";
import { Select } from "bitshares-ui-style-guide";

var DepositWithdrawAssetSelector = function (_React$Component) {
    _inherits(DepositWithdrawAssetSelector, _React$Component);

    function DepositWithdrawAssetSelector(props) {
        _classCallCheck(this, DepositWithdrawAssetSelector);

        var _this = _possibleConstructorReturn(this, (DepositWithdrawAssetSelector.__proto__ || Object.getPrototypeOf(DepositWithdrawAssetSelector)).call(this, props));

        _this.state = {
            assets: []
        };
        return _this;
    }

    _createClass(DepositWithdrawAssetSelector, [{
        key: "componentDidMount",
        value: function componentDidMount() {
            this.getAssets();
        }
    }, {
        key: "getAssets",
        value: function getAssets() {
            var _props = this.props,
                backedCoins = _props.backedCoins,
                include = _props.include,
                includeBTS = _props.includeBTS;
            var assets = this.state.assets;


            var idMap = {};

            backedCoins.forEach(function (coin) {
                assets = assets.concat(coin.map(function (item) {
                    /* Gateway Specific Settings */
                    var _item$symbol$split = item.symbol.split("."),
                        _item$symbol$split2 = _slicedToArray(_item$symbol$split, 2),
                        gateway = _item$symbol$split2[0],
                        backedCoin = _item$symbol$split2[1];

                    // Return null if backedCoin is already stored


                    if (!idMap[backedCoin] && backedCoin && (gateway === "XBTSX" || gateway === "BNB" || gateway === "WAVES" || gateway === "ETH" || gateway === "HECO" || gateway === "BSC")) {
                        idMap[backedCoin] = true;

                        return {
                            id: backedCoin,
                            label: backedCoin,
                            gateway: gateway,
                            gateFee: item.gateFee,
                            issuer: item.issuerId || "1.2.1003283" //Fall back to xbtsx
                        };
                    } else {
                        return null;
                    }
                })).filter(function (item) {
                    return item;
                }).filter(function (item) {
                    if (item.id === "BTS") {
                        return true;
                    }
                    if (include) {
                        return include.includes(item.id);
                    }
                    return true;
                });
            });

            if (!(includeBTS === false)) {
                assets.push({ id: "BTS", label: "BTS", gateway: "" });
            }

            this.setState({
                assets: assets
            });
        }
    }, {
        key: "getSelectedAssetArray",
        value: function getSelectedAssetArray(selectedAsset) {
            var assets = this.state.assets;


            var asset = void 0;

            assets.map(function (a) {
                if (a.id === selectedAsset) {
                    asset = a;
                }
            });

            return asset;
        }
    }, {
        key: "_onSelect",
        value: function _onSelect(selectedAsset) {
            var onSelect = this.props.onSelect;

            var asset = this.getSelectedAssetArray(selectedAsset);

            if (onSelect) {
                onSelect(asset);
            }
        }
    }, {
        key: "_onInputChanged",
        value: function _onInputChanged(selectedAsset) {
            //console.log('selectedAsset', selectedAsset);
            if (selectedAsset.length < 2) {
                return;
            }
            var onChange = this.props.onChange;

            var asset = this.getSelectedAssetArray(selectedAsset);

            if (onChange && asset) {
                onChange(asset.id);
            } else {
                return;
            }
        }
    }, {
        key: "render",
        value: function render() {
            var usageContext = this.props.usageContext;


            var coinItems = this.state.assets.sort(function (a, b) {
                if (a.id && b.id) return a.id.localeCompare(b.id);
            });

            return React.createElement(
                Select,
                {
                    onSelect: this._onSelect.bind(this),
                    onChange: this._onInputChanged.bind(this),
                    onSearch: this._onInputChanged.bind(this),
                    placeholder: counterpart.translate(usageContext === "withdraw" ? "gateway.asset_search_withdraw" : "gateway.asset_search_deposit"),
                    value: this.props.defaultValue,
                    optionLabelProp: "value",
                    showSearch: true,
                    style: { width: "100%" }
                },
                coinItems.length > 0 ? coinItems.map(function (coin) {
                    return React.createElement(
                        Select.Option,
                        { key: coin.id, value: coin.label },
                        coin.label
                    );
                }) : React.createElement(
                    Select.Option,
                    { disabled: true, key: 0, value: 0 },
                    counterpart.translate(usageContext === "withdraw" ? "modal.withdraw.no_assets" : "modal.deposit.no_assets")
                )
            );
        }
    }]);

    return DepositWithdrawAssetSelector;
}(React.Component);

DepositWithdrawAssetSelector = BindToChainState(DepositWithdrawAssetSelector);

export default connect(DepositWithdrawAssetSelector, {
    listenTo: function listenTo() {
        return [GatewayStore];
    },
    getProps: function getProps() {
        return {
            backedCoins: GatewayStore.getState().backedCoins
        };
    }
});