var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import utils from "common/utils";
import React from "react";
import Immutable from "immutable";
import counterpart from "counterpart";
import AssetWrapper from "./AssetWrapper";
import PropTypes from "prop-types";
import { DecimalChecker } from "./DecimalChecker";
import { Form, Input, Select } from "bitshares-ui-style-guide";
import AssetSelect from "./AssetSelect";

var AmountSelector = function (_DecimalChecker) {
    _inherits(AmountSelector, _DecimalChecker);

    function AmountSelector() {
        _classCallCheck(this, AmountSelector);

        return _possibleConstructorReturn(this, (AmountSelector.__proto__ || Object.getPrototypeOf(AmountSelector)).apply(this, arguments));
    }

    _createClass(AmountSelector, [{
        key: "componentDidMount",
        value: function componentDidMount() {
            this.onAssetChange(this.props.asset);
        }
    }, {
        key: "formatAmount",
        value: function formatAmount(v) {
            /*// TODO: use asset's precision to format the number*/
            if (!v) v = "";
            if (typeof v === "number") v = v.toString();
            var value = v.trim().replace(/,/g, "");

            return value;
        }
    }, {
        key: "_onChange",
        value: function _onChange(e) {
            if (this.props.onChange) this.props.onChange({
                amount: this.getNumericEventValue(e),
                asset: this.props.asset
            });
        }
    }, {
        key: "onAssetChange",
        value: function onAssetChange(selected_asset) {
            if (this.props.onChange) this.props.onChange({
                amount: this.props.amount,
                asset: selected_asset
            });
        }
    }, {
        key: "render",
        value: function render() {
            var value = this.props.error ? counterpart.translate(this.props.error) : this.formatAmount(this.props.amount);

            var label = this.props.label ? React.createElement(
                "div",
                { className: "amount-selector-field--label" },
                counterpart.translate(this.props.label),
                React.createElement(
                    "div",
                    { className: "amount-selector-field--balance" },
                    this.props.display_balance
                )
            ) : null;

            var addonAfter = null;

            if (this.props.isPrice) {
                addonAfter = React.createElement(
                    "div",
                    null,
                    this.props.asset.get("symbol"),
                    "/",
                    this.props.base
                );
            }

            return React.createElement(
                Form.Item,
                {
                    label: label,
                    style: this.props.style,
                    className: "amount-selector-field"
                },
                React.createElement(
                    Input.Group,
                    { compact: true },
                    React.createElement(Input, {
                        style: {
                            width: this.props.isPrice ? "100%" : "calc(100% - 130px)"
                        },
                        disabled: this.props.disabled,
                        value: value || "",
                        placeholder: this.props.placeholder,
                        onChange: this._onChange.bind(this),
                        tabIndex: this.props.tabIndex,
                        onPaste: this.props.onPaste || this.onPaste.bind(this),
                        onKeyPress: this.onKeyPress.bind(this),
                        addonAfter: addonAfter
                    }),
                    !this.props.isPrice ? React.createElement(AssetSelect, {
                        style: { width: "130px" },
                        selectStyle: { width: "100%" },
                        value: this.props.asset.get("symbol"),
                        assets: Immutable.List(this.props.assets),
                        onChange: this.onAssetChange.bind(this),
                        disabled: this.props.selectDisabled ? true : undefined
                    }) : null
                )
            );
        }
    }]);

    return AmountSelector;
}(DecimalChecker);

AmountSelector.propTypes = {
    label: PropTypes.string, // a translation key for the label
    assets: PropTypes.array,
    amount: PropTypes.any,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    tabIndex: PropTypes.number,
    error: PropTypes.string,
    scroll_length: PropTypes.number,
    selectDisabled: PropTypes.bool
};
AmountSelector.defaultProps = {
    disabled: false,
    tabIndex: 0,
    selectDisabled: false
};

AmountSelector = AssetWrapper(AmountSelector);

export default AmountSelector;