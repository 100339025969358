var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from "react";
import FormattedAsset from "../Utility/FormattedAsset";
import { Link } from "react-router-dom";
import classNames from "classnames";
import Translate from "react-translate-component";
import counterpart from "counterpart";
import utils from "common/utils";
import BlockTime from "./BlockTime";
import LinkToAccountById from "../Utility/LinkToAccountById";
import LinkToAssetById from "../Utility/LinkToAssetById";
import BindToChainState from "../Utility/BindToChainState";
import ChainTypes from "../Utility/ChainTypes";
import { ChainStore, ChainTypes as grapheneChainTypes } from "bitsharesjs";
import { connect } from "alt-react";
import SettingsStore from "stores/SettingsStore";
import PropTypes from "prop-types";
import { Tooltip } from "bitshares-ui-style-guide";

var operations = grapheneChainTypes.operations;

import opComponents from "./operations";
require("./operations.scss");

var ops = Object.keys(operations);
// let listings = account_constants.account_listing;

var TransactionLabel = function (_React$Component) {
    _inherits(TransactionLabel, _React$Component);

    function TransactionLabel() {
        _classCallCheck(this, TransactionLabel);

        return _possibleConstructorReturn(this, (TransactionLabel.__proto__ || Object.getPrototypeOf(TransactionLabel)).apply(this, arguments));
    }

    _createClass(TransactionLabel, [{
        key: "shouldComponentUpdate",
        value: function shouldComponentUpdate(nextProps) {
            return nextProps.color !== this.props.color || nextProps.type !== this.props.type;
        }
    }, {
        key: "render",
        value: function render() {
            var trxTypes = counterpart.translate("transaction.trxTypes");
            var labelClass = classNames("label", this.props.color || "info");
            return React.createElement(
                "span",
                { className: labelClass },
                trxTypes[ops[this.props.type]]
            );
        }
    }]);

    return TransactionLabel;
}(React.Component);

var Row = function (_React$Component2) {
    _inherits(Row, _React$Component2);

    function Row(props) {
        _classCallCheck(this, Row);

        return _possibleConstructorReturn(this, (Row.__proto__ || Object.getPrototypeOf(Row)).call(this, props));
    }

    _createClass(Row, [{
        key: "shouldComponentUpdate",
        value: function shouldComponentUpdate(nextProps) {
            var _props = this.props,
                block = _props.block,
                dynGlobalObject = _props.dynGlobalObject;

            var last_irreversible_block_num = dynGlobalObject.get("last_irreversible_block_num");

            return nextProps.dynGlobalObject !== this.props.dynGlobalObject || block > last_irreversible_block_num || this.props.color !== nextProps.color;
        }
    }, {
        key: "render",
        value: function render() {
            var _props2 = this.props,
                block = _props2.block,
                fee = _props2.fee,
                color = _props2.color,
                type = _props2.type,
                hideOpLabel = _props2.hideOpLabel,
                hidePending = _props2.hidePending;


            var last_irreversible_block_num = this.props.dynGlobalObject.get("last_irreversible_block_num");
            var pending = null;
            if (!hidePending && block > last_irreversible_block_num) {
                pending = React.createElement(
                    "span",
                    null,
                    "(",
                    React.createElement(Translate, {
                        content: "operation.pending",
                        blocks: block - last_irreversible_block_num
                    }),
                    ")"
                );
            }

            fee.amount = parseInt(fee.amount, 10);

            return React.createElement(
                "tr",
                null,
                this.props.includeOperationId ? React.createElement(
                    "td",
                    { style: { textAlign: "left" } },
                    this.props.operationId
                ) : null,
                hideOpLabel ? null : React.createElement(
                    "td",
                    {
                        style: { textAlign: "left" },
                        className: "left-td column-hide-tiny"
                    },
                    React.createElement(
                        Tooltip,
                        {
                            placement: "bottom",
                            title: counterpart.translate("tooltip.show_block", {
                                block: utils.format_number(this.props.block, 0)
                            })
                        },
                        React.createElement(
                            Link,
                            {
                                className: "inline-block",
                                to: "/block/" + this.props.block + "/" + this.props.txIndex
                            },
                            React.createElement(TransactionLabel, { color: color, type: type })
                        )
                    )
                ),
                React.createElement(
                    "td",
                    { style: { padding: "8px 5px", textAlign: "left" } },
                    React.createElement(
                        "div",
                        null,
                        React.createElement(
                            "span",
                            null,
                            this.props.info
                        )
                    ),
                    React.createElement(
                        "div",
                        { style: { fontSize: 14, paddingTop: 5 } },
                        pending ? React.createElement(
                            "span",
                            null,
                            " - ",
                            pending
                        ) : null
                    )
                ),
                !this.props.hideFee && React.createElement(
                    "td",
                    { style: { textAlign: "left" } },
                    React.createElement(FormattedAsset, {
                        amount: fee.amount,
                        asset: fee.asset_id
                    })
                ),
                React.createElement(
                    "td",
                    null,
                    !this.props.hideDate ? React.createElement(BlockTime, {
                        block_number: block,
                        fullDate: this.props.fullDate
                    }) : null
                )
            );
        }
    }]);

    return Row;
}(React.Component);

Row.propTypes = {
    dynGlobalObject: ChainTypes.ChainObject.isRequired
};
Row.defaultProps = {
    dynGlobalObject: "2.1.0",
    tempComponent: "tr"
};


Row = BindToChainState(Row);

var Operation = function (_React$Component3) {
    _inherits(Operation, _React$Component3);

    function Operation() {
        var _ref;

        var _temp, _this3, _ret;

        _classCallCheck(this, Operation);

        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
        }

        return _ret = (_temp = (_this3 = _possibleConstructorReturn(this, (_ref = Operation.__proto__ || Object.getPrototypeOf(Operation)).call.apply(_ref, [this].concat(args))), _this3), _this3.state = {
            labelColor: "info"
        }, _this3.changeColor = function (newColor) {
            var labelColor = _this3.state.labelColor;

            if (labelColor !== newColor) {
                _this3.setState({ labelColor: newColor });
            }
        }, _temp), _possibleConstructorReturn(_this3, _ret);
    }

    _createClass(Operation, [{
        key: "componentWillReceiveProps",
        value: function componentWillReceiveProps(np) {
            if (np.marketDirections !== this.props.marketDirections) {
                this.forceUpdate();
            }
        }
    }, {
        key: "shouldComponentUpdate",
        value: function shouldComponentUpdate(nextProps, nextState) {
            if (!this.props.op || !nextProps.op) {
                return false;
            }
            return !utils.are_equal_shallow(nextProps.op[1], this.props.op[1]) || nextProps.marketDirections !== this.props.marketDirections || this.state.labelColor !== nextState.labelColor;
        }
    }, {
        key: "linkToAccount",
        value: function linkToAccount(name_or_id) {
            if (!name_or_id) return React.createElement(
                "span",
                null,
                "-"
            );
            return utils.is_object_id(name_or_id) ? React.createElement(LinkToAccountById, { account: name_or_id }) : React.createElement(
                Link,
                { to: "/account/" + name_or_id },
                name_or_id
            );
        }
    }, {
        key: "linkToAsset",
        value: function linkToAsset(symbol_or_id) {
            if (!symbol_or_id) return React.createElement(
                "span",
                null,
                "-"
            );
            return utils.is_object_id(symbol_or_id) ? React.createElement(LinkToAssetById, { asset: symbol_or_id }) : React.createElement(
                Link,
                { to: "/asset/" + symbol_or_id },
                symbol_or_id
            );
        }
    }, {
        key: "render",
        value: function render() {
            var _props3 = this.props,
                op = _props3.op,
                block = _props3.block;

            var line = null,
                column = null;

            column = opComponents(ops[op[0]], this.props, {
                fromComponent: "operation",
                linkToAccount: this.linkToAccount,
                linkToAsset: this.linkToAsset,
                changeColor: this.changeColor
            });

            if (this.props.csvExportMode) {
                var globalObject = ChainStore.getObject("2.0.0");
                var dynGlobalObject = ChainStore.getObject("2.1.0");
                var block_time = utils.calc_block_time(block, globalObject, dynGlobalObject);
                return React.createElement(
                    "div",
                    null,
                    React.createElement(
                        "div",
                        null,
                        block_time ? block_time.toLocaleString() : ""
                    ),
                    React.createElement(
                        "div",
                        null,
                        ops[op[0]]
                    ),
                    React.createElement(
                        "div",
                        null,
                        column
                    ),
                    React.createElement(
                        "div",
                        null,
                        React.createElement(FormattedAsset, {
                            amount: parseInt(op[1].fee.amount, 10),
                            asset: op[1].fee.asset_id
                        })
                    )
                );
            }

            line = column ? React.createElement(Row, {
                operationId: this.props.operationId,
                txIndex: this.props.txIndex,
                includeOperationId: this.props.includeOperationId,
                block: block,
                type: op[0],
                color: this.state.labelColor,
                fee: op[1].fee,
                hideOpLabel: this.props.hideOpLabel,
                hideDate: this.props.hideDate,
                info: column,
                hideFee: this.props.hideFee,
                hidePending: this.props.hidePending,
                fullDate: this.props.fullDate
            }) : null;

            return line ? line : React.createElement("tr", null);
        }
    }]);

    return Operation;
}(React.Component);

Operation.defaultProps = {
    op: [],
    current: "",
    block: null,
    hideOpLabel: false,
    csvExportMode: false
};
Operation.propTypes = {
    op: PropTypes.array.isRequired,
    current: PropTypes.string,
    block: PropTypes.number,
    csvExportMode: PropTypes.bool
};


Operation = connect(Operation, {
    listenTo: function listenTo() {
        return [SettingsStore];
    },
    getProps: function getProps() {
        return {
            marketDirections: SettingsStore.getState().marketDirections
        };
    }
});

export default Operation;