import React from "react";
import Translate from "react-translate-component";
import BindToChainState from "../../Utility/BindToChainState";
import utils from "common/utils";
import TranslateWithLinks from "../../Utility/TranslateWithLinks";

export var BalanceClaim = function BalanceClaim(_ref) {
    var op = _ref.op,
        changeColor = _ref.changeColor,
        linkToAccount = _ref.linkToAccount,
        fromComponent = _ref.fromComponent;

    changeColor("success");
    op[1].total_claimed.amount = parseInt(op[1].total_claimed.amount, 10);

    if (fromComponent === "proposed_operation") {
        return React.createElement(
            "span",
            null,
            linkToAccount(op[1].deposit_to_account),
            "\xA0",
            React.createElement(
                BindToChainState.Wrapper,
                { asset: op[1].total_claimed.asset_id },
                function (_ref2) {
                    var asset = _ref2.asset;
                    return React.createElement(Translate, {
                        component: "span",
                        content: "proposal.balance_claim",
                        balance_amount: utils.format_asset(op[1].total_claimed.amount, asset),
                        balance_id: op[1].balance_to_claim.substring(5)
                    });
                }
            )
        );
    } else {
        return React.createElement(
            "span",
            null,
            React.createElement(TranslateWithLinks, {
                string: "operation.balance_claim",
                keys: [{
                    type: "account",
                    value: op[1].deposit_to_account,
                    arg: "account"
                }, {
                    type: "amount",
                    value: op[1].total_claimed,
                    arg: "amount"
                }]
            })
        );
    }
};