var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import Translate from "react-translate-component";
import BrainkeyInput from "components/Wallet/BrainkeyInputStyleGuide";
import PasswordConfirm from "components/Wallet/PasswordConfirmStyleGuide";
import WalletDb from "stores/WalletDb";
import WalletManagerStore from "stores/WalletManagerStore";
import WalletActions from "actions/WalletActions";
import { connect } from "alt-react";
import SettingsActions from "actions/SettingsActions";
import PropTypes from "prop-types";
import { getWalletName } from "branding";
import { Button, Form, Input } from "bitshares-ui-style-guide";
import counterpart from "counterpart";

var CreateNewWallet = function (_Component) {
    _inherits(CreateNewWallet, _Component);

    function CreateNewWallet(props) {
        _classCallCheck(this, CreateNewWallet);

        var _this = _possibleConstructorReturn(this, (CreateNewWallet.__proto__ || Object.getPrototypeOf(CreateNewWallet)).call(this));

        _this.onSubmit = function (e) {
            e.preventDefault();

            var _this$state = _this.state,
                wallet_public_name = _this$state.wallet_public_name,
                valid_password = _this$state.valid_password,
                custom_brainkey = _this$state.custom_brainkey,
                errors = _this$state.errors;

            if (!valid_password || errors.wallet_public_name || custom_brainkey && !errors.validBrainkey) {
                return;
            }

            WalletActions.setWallet(wallet_public_name, valid_password, _this.state.brnkey);
            SettingsActions.changeSetting({
                setting: "passwordLogin",
                value: false
            });
            _this.setState({ create_submitted: true });
        };

        _this.formChange = function (event) {
            var key_id = event.target.id;
            var value = event.target.value;
            if (key_id === "wallet_public_name") {
                //case in-sensitive
                value = value.toLowerCase();
                // Allow only valid file name characters
                if (/[^a-z0-9_-]/.test(value)) return;
            }

            // Set state is updated directly because validate is going to
            // require a merge of new and old state
            _this.state[key_id] = value;
            _this.setState(_this.state);
            _this.validate();
        };

        _this.state = {
            wallet_public_name: "default",
            valid_password: null,
            errors: {
                validBrainkey: false
            },
            isValid: false,
            create_submitted: false,
            custom_brainkey: props.restoreBrainkey || false,
            brnkey: null
        };

        _this.validate = _this.validate.bind(_this);
        return _this;
    }

    _createClass(CreateNewWallet, [{
        key: "onBack",
        value: function onBack(e) {
            e.preventDefault();
            window.history.back();
        }
    }, {
        key: "onPassword",
        value: function onPassword(valid_password) {
            if (valid_password !== this.state.valid_password) this.setState({ valid_password: valid_password }, this.validate);
        }
    }, {
        key: "onCustomBrainkey",
        value: function onCustomBrainkey() {
            this.setState({ custom_brainkey: true });
        }
    }, {
        key: "onBrainkey",
        value: function onBrainkey(brnkey) {
            this.setState({ brnkey: brnkey }, this.validate);
        }
    }, {
        key: "validate",
        value: function validate() {
            var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.state;

            var errors = state.errors;
            var wallet_names = this.props.wallet_names;

            errors.wallet_public_name = !wallet_names.has(state.wallet_public_name) ? null : "Wallet " + state.wallet_public_name.toUpperCase() + " exists, please change the name";

            var isValid = errors.wallet_public_name === null && state.valid_password !== null;
            if (state.custom_brainkey && isValid) isValid = state.brnkey !== null;
            this.setState({ isValid: isValid, errors: errors });
        }
    }, {
        key: "render",
        value: function render() {
            var _this2 = this;

            var state = this.state;
            var errors = state.errors;
            var has_wallet = !!this.props.current_wallet;

            if (this.state.create_submitted && this.state.wallet_public_name === this.props.current_wallet) {
                return React.createElement(
                    "div",
                    null,
                    React.createElement(
                        "h4",
                        null,
                        React.createElement(Translate, { content: "wallet.wallet_created" })
                    ),
                    React.createElement(
                        Link,
                        { to: "/" },
                        React.createElement(
                            "div",
                            { className: "button success" },
                            React.createElement(Translate, { content: "wallet.done" })
                        )
                    )
                );
            }

            return React.createElement(
                "div",
                { className: "wallet-create" },
                React.createElement(
                    Form,
                    {
                        style: { maxWidth: "40rem" },
                        onSubmit: this.onSubmit,
                        onChange: this.formChange,
                        noValidate: true
                    },
                    React.createElement(
                        "div",
                        {
                            className: "grid-content",
                            style: {
                                textAlign: "left"
                            }
                        },
                        !this.props.restoreBrainkey ? React.createElement(
                            Fragment,
                            null,
                            React.createElement(Translate, {
                                component: "p",
                                content: "wallet.create_importkeys_text"
                            }),
                            React.createElement(Translate, {
                                component: "p",
                                content: "wallet.create_text",
                                wallet_name: getWalletName()
                            })
                        ) : null
                    ),
                    React.createElement(PasswordConfirm, { onValid: this.onPassword.bind(this) }),
                    has_wallet ? React.createElement(
                        Form.Item,
                        { label: counterpart.translate("wallet.name") },
                        React.createElement(
                            "div",
                            { className: "no-overflow" },
                            React.createElement(
                                "section",
                                null,
                                React.createElement(Input, {
                                    tabIndex: 3,
                                    type: "text",
                                    id: "wallet_public_name",
                                    defaultValue: this.state.wallet_public_name
                                }),
                                React.createElement(
                                    "div",
                                    { className: "has-error" },
                                    errors.wallet_public_name
                                )
                            )
                        )
                    ) : null,
                    React.createElement(
                        "div",
                        { className: "no-overflow" },
                        this.state.custom_brainkey ? React.createElement(
                            "div",
                            null,
                            React.createElement(
                                Form.Item,
                                {
                                    label: counterpart.translate("wallet.brainkey")
                                },
                                React.createElement(BrainkeyInput, {
                                    tabIndex: 4,
                                    onChange: this.onBrainkey.bind(this),
                                    errorCallback: function errorCallback(warn) {
                                        var errors = _this2.state.errors;

                                        errors.validBrainkey = warn;
                                        _this2.setState({
                                            errors: errors
                                        });
                                    }
                                })
                            )
                        ) : null,
                        React.createElement(
                            Button,
                            {
                                type: "primary",
                                htmlType: "submit",
                                disabled: !this.state.isValid
                            },
                            React.createElement(Translate, { content: "wallet.create_wallet" })
                        ),
                        React.createElement(
                            Button,
                            { onClick: this.onBack.bind(this) },
                            React.createElement(Translate, { content: "wallet.cancel" })
                        )
                    ),
                    !this.state.custom_brainkey ? React.createElement(
                        "div",
                        { style: { paddingTop: 20 } },
                        React.createElement(
                            "label",
                            null,
                            React.createElement(
                                "a",
                                { onClick: this.onCustomBrainkey.bind(this) },
                                React.createElement(Translate, { content: "wallet.custom_brainkey" })
                            )
                        )
                    ) : null
                )
            );
        }
    }]);

    return CreateNewWallet;
}(Component);

CreateNewWallet.propTypes = {
    hideTitle: PropTypes.bool
};


CreateNewWallet = connect(CreateNewWallet, {
    listenTo: function listenTo() {
        return [WalletManagerStore];
    },
    getProps: function getProps() {
        return WalletManagerStore.getState();
    }
});

var WalletCreate = function (_Component2) {
    _inherits(WalletCreate, _Component2);

    function WalletCreate() {
        _classCallCheck(this, WalletCreate);

        return _possibleConstructorReturn(this, (WalletCreate.__proto__ || Object.getPrototypeOf(WalletCreate)).apply(this, arguments));
    }

    _createClass(WalletCreate, [{
        key: "render",
        value: function render() {
            if (WalletDb.getWallet() && this.props.children) return React.createElement(
                "div",
                null,
                this.props.children
            );

            return React.createElement(CreateNewWallet, this.props);
        }
    }]);

    return WalletCreate;
}(Component);

var CreateWalletFromBrainkey = function CreateWalletFromBrainkey(props) {
    var wallet_types = React.createElement(
        Link,
        { to: "/help/introduction/wallets" },
        counterpart.translate("wallet.wallet_types")
    );
    var backup_types = React.createElement(
        Link,
        { to: "/help/introduction/backups" },
        counterpart.translate("wallet.backup_types")
    );

    if (!props.nested) {
        return React.createElement(
            "div",
            { className: "grid-container", style: { paddingTop: 30 } },
            React.createElement(Translate, { content: "settings.backup_brainkey", component: "h3" }),
            React.createElement(Translate, {
                content: "settings.restore_brainkey_text",
                component: "p",
                style: { maxWidth: "40rem" }
            }),
            React.createElement(Translate, {
                component: "p",
                style: { paddingBottom: 10 },
                wallet: wallet_types,
                backup: backup_types,
                content: "wallet.read_more"
            }),
            React.createElement(WalletCreate, _extends({ restoreBrainkey: true }, props))
        );
    }
    return React.createElement(WalletCreate, _extends({ restoreBrainkey: true }, props));
};

export { WalletCreate, CreateWalletFromBrainkey };