var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import alt from "alt-instance";
import Immutable from "immutable";
import iDB from "idb-instance";
import BaseStore from "stores/BaseStore";
import CachedPropertyActions from "actions/CachedPropertyActions";

var CachedPropertyStore = function (_BaseStore) {
    _inherits(CachedPropertyStore, _BaseStore);

    function CachedPropertyStore() {
        _classCallCheck(this, CachedPropertyStore);

        var _this = _possibleConstructorReturn(this, (CachedPropertyStore.__proto__ || Object.getPrototypeOf(CachedPropertyStore)).call(this));

        _this.state = _this._getInitialState();
        _this.bindListeners({
            onSet: CachedPropertyActions.set,
            onGet: CachedPropertyActions.get
        });
        _this._export("get", "reset");
        return _this;
    }

    _createClass(CachedPropertyStore, [{
        key: "_getInitialState",
        value: function _getInitialState() {
            return {
                props: Immutable.Map()
            };
        }
    }, {
        key: "get",
        value: function get(name) {
            return this.onGet({ name: name });
        }
    }, {
        key: "onSet",
        value: function onSet(_ref) {
            var _this2 = this;

            var name = _ref.name,
                value = _ref.value;

            if (this.state.props.get(name) === value) return;
            var props = this.state.props.set(name, value);
            this.state.props = props;
            iDB.setCachedProperty(name, value).then(function () {
                return _this2.setState({ props: props });
            });
        }
    }, {
        key: "onGet",
        value: function onGet(_ref2) {
            var _this3 = this;

            var name = _ref2.name;

            var value = this.state.props.get(name);
            if (value !== undefined) return value;
            try {
                iDB.getCachedProperty(name, null).then(function (value) {
                    var props = _this3.state.props.set(name, value);
                    _this3.state.props = props;
                    _this3.setState({ props: props });
                });
            } catch (err) {
                console.error("getCachedProperty error:", err);
            }
        }
    }, {
        key: "reset",
        value: function reset() {
            this.state = this._getInitialState();
            this.setState(this.state);
        }
    }]);

    return CachedPropertyStore;
}(BaseStore);

export var CachedPropertyStoreWrapped = alt.createStore(CachedPropertyStore, "CachedPropertyStore");
export default CachedPropertyStoreWrapped;