var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from "react";
import { findDOMNode } from "react-dom";
import cnames from "classnames";

var Pulsate = function (_React$Component) {
    _inherits(Pulsate, _React$Component);

    function Pulsate() {
        _classCallCheck(this, Pulsate);

        var _this = _possibleConstructorReturn(this, (Pulsate.__proto__ || Object.getPrototypeOf(Pulsate)).call(this));

        _this.state = {
            value: null,
            pulse: ""
        };
        return _this;
    }

    _createClass(Pulsate, [{
        key: "componentWillReceiveProps",
        value: function componentWillReceiveProps(nextProps) {
            this.update(nextProps);
        }
    }, {
        key: "componentWillMount",
        value: function componentWillMount() {
            this.update(this.props);
        }
    }, {
        key: "compare",
        value: function compare(value, nextValue) {
            if (value === nextValue) {
                return null; // stay unchanged
            } else {
                return nextValue > value ? "green" : "red";
            }
        }
    }, {
        key: "update",
        value: function update(props) {
            var _this2 = this;

            var value = this.state.value;
            var nextValue = props.value;
            var compareFunction = props.compareFunction || this.compare;

            if (value === null || nextValue === null) {
                this.setState({ value: nextValue, pulse: "" });
                return;
            }

            var pulse = compareFunction(value, nextValue);
            if (pulse === null) {
                this.setState({ value: nextValue });
            } else {
                this.setState({ value: nextValue, pulse: "" }, function () {
                    findDOMNode(_this2).offsetHeight;
                    _this2.setState({ pulse: pulse });
                });
            }
        }
    }, {
        key: "render",
        value: function render() {
            var _state = this.state,
                pulse = _state.pulse,
                value = _state.value;
            var _props = this.props,
                children = _props.children,
                reverse = _props.reverse,
                fill = _props.fill;


            if (!children) {
                children = value;
            }

            if (!pulse) {
                return React.createElement(
                    "span",
                    null,
                    children
                );
            }

            fill = fill || "none";
            return React.createElement(
                "span",
                {
                    className: cnames("pulsate", pulse, { reverse: reverse }),
                    style: { animationFillMode: fill }
                },
                children
            );
        }
    }]);

    return Pulsate;
}(React.Component);

export default Pulsate;