var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import BaseStore from "./BaseStore";
import Immutable from "immutable";
import alt from "alt-instance";
import AssetActions from "actions/AssetActions";

var AssetStore = function (_BaseStore) {
    _inherits(AssetStore, _BaseStore);

    function AssetStore() {
        _classCallCheck(this, AssetStore);

        var _this = _possibleConstructorReturn(this, (AssetStore.__proto__ || Object.getPrototypeOf(AssetStore)).call(this));

        _this.assets = Immutable.Map();
        _this.asset_symbol_to_id = {};
        _this.searchTerms = {};
        _this.lookupResults = [];
        _this.assetsLoading = false;

        _this.bindListeners({
            onGetAssetList: AssetActions.getAssetList,
            onLookupAsset: AssetActions.lookupAsset
        });
        return _this;
    }

    _createClass(AssetStore, [{
        key: "onGetAssetList",
        value: function onGetAssetList(payload) {
            var _this2 = this;

            if (!payload) {
                return false;
            }
            this.assetsLoading = payload.loading;

            if (payload.assets) {
                payload.assets.forEach(function (asset) {
                    for (var i = 0; i < payload.dynamic.length; i++) {
                        if (payload.dynamic[i].id === asset.dynamic_asset_data_id) {
                            asset.dynamic = payload.dynamic[i];
                            break;
                        }
                    }

                    if (asset.bitasset_data_id) {
                        asset.market_asset = true;

                        for (var i = 0; i < payload.bitasset_data.length; i++) {
                            if (payload.bitasset_data[i].id === asset.bitasset_data_id) {
                                asset.bitasset_data = payload.bitasset_data[i];
                                break;
                            }
                        }
                    } else {
                        asset.market_asset = false;
                    }

                    _this2.assets = _this2.assets.set(asset.id, asset);

                    _this2.asset_symbol_to_id[asset.symbol] = asset.id;
                });
            }
        }
    }, {
        key: "onLookupAsset",
        value: function onLookupAsset(payload) {
            this.searchTerms[payload.searchID] = payload.symbol;
            this.lookupResults = payload.assets;
        }
    }]);

    return AssetStore;
}(BaseStore);

export default alt.createStore(AssetStore, "AssetStore");