import React from "react";
import TranslateWithLinks from "../../Utility/TranslateWithLinks";

export var AssetSettleCancel = function AssetSettleCancel(_ref) {
    var op = _ref.op;

    return React.createElement(TranslateWithLinks, {
        string: "operation.asset_settle_cancel",
        keys: [{
            type: "account",
            value: op[1].account,
            arg: "account"
        }, { type: "amount", value: op[1].amount, arg: "amount" }]
    });
};