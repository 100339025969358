var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from "react";
import ChainTypes from "../Utility/ChainTypes";
import BindToChainState from "../Utility/BindToChainState";
import { ChainTypes as GraphChainTypes, ChainStore } from "bitsharesjs";
import counterpart from "counterpart";
import utils from "common/utils";
import Notify from "notifyjs";
var operations = GraphChainTypes.operations;

import PropTypes from "prop-types";

var OPERATIONS = Object.keys(operations);

var BrowserNotifications = function (_React$Component) {
    _inherits(BrowserNotifications, _React$Component);

    function BrowserNotifications() {
        _classCallCheck(this, BrowserNotifications);

        return _possibleConstructorReturn(this, (BrowserNotifications.__proto__ || Object.getPrototypeOf(BrowserNotifications)).apply(this, arguments));
    }

    _createClass(BrowserNotifications, [{
        key: "componentWillMount",
        value: function componentWillMount() {
            if (Notify.needsPermission) {
                Notify.requestPermission();
            }
        }
    }, {
        key: "componentWillReceiveProps",
        value: function componentWillReceiveProps(nextProps) {
            // if browser notifications disabled on settings we can skip all checks
            if (!nextProps.settings.get("browser_notifications").allow) {
                console.log("browser notifications disabled by settings");
                return false;
            }

            // if app not permitted to send notifications skip all checks
            if (Notify.needsPermission) {
                console.log("browser notifications disabled by Browser Permissions");
                return false;
            }

            if (nextProps.account && this.props.account && nextProps.account.size && this.props.account.get("history") && nextProps.account.get("history")) {
                var lastOperationOld = this.props.account.get("history").first();
                var lastOperationNew = nextProps.account.get("history").first();
                if (!lastOperationNew || !lastOperationOld) return false;

                // if operations not updated do not notify user
                if (lastOperationNew.get("id") === lastOperationOld.get("id")) {
                    return false;
                }

                if (this._isOperationTransfer(lastOperationNew) && this._isTransferToMyAccount(lastOperationNew) && nextProps.settings.get("browser_notifications").additional.transferToMe) {
                    this._notifyUserAboutTransferToHisAccount(lastOperationNew);
                }
            }
        }
    }, {
        key: "_getOperationName",
        value: function _getOperationName(operation) {
            if (operation.getIn(["op", 0]) !== undefined) return OPERATIONS[operation.getIn(["op", 0])];
            return null;
        }
    }, {
        key: "_isOperationTransfer",
        value: function _isOperationTransfer(operation) {
            return this._getOperationName(operation) === "transfer";
        }
    }, {
        key: "_isTransferToMyAccount",
        value: function _isTransferToMyAccount(operation) {
            if (!this._isOperationTransfer(operation)) throw Error("Operation is not transfer");

            return operation.getIn(["op", 1, "to"]) === this.props.account.get("id");
        }
    }, {
        key: "_notifyUserAboutTransferToHisAccount",
        value: function _notifyUserAboutTransferToHisAccount(operation) {
            var assetId = operation.getIn(["op", 1, "amount", "asset_id"]);
            var from = operation.getIn(["op", 1, "from"]);

            var amount = operation.getIn(["op", 1, "amount", "amount"]);

            if (!assetId || !from || !amount) throw Error("Operation has wrong format");

            var title = counterpart.translate("browser_notification_messages.money_received_title", {
                from: this._getAccountNameById(from)
            });

            var realAmount = this._getRealAmountByAssetId(amount, assetId);
            var symbol = this._getAssetSymbolByAssetId(assetId);
            if (realAmount === null || symbol === null) return;

            var body = counterpart.translate("browser_notification_messages.money_received_body", {
                amount: realAmount,
                symbol: symbol
            });

            this.notifyUsingBrowserNotification({
                title: title,
                body: body,
                closeOnClick: true
            });
        }
    }, {
        key: "notifyUsingBrowserNotification",
        value: function notifyUsingBrowserNotification() {
            var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

            /*
            * params.title (string) - title of notification
            * params.body (string) - body of notification
            * params.showTimeout (number) - number of seconds to show the notification
            * params.closeOnClick (boolean) - close the notification when clicked. Useful in chrome where the notification remains open until the timeout or the x is clicked.
            * params.onNotifyShow (function) - callback when notification is shown
            * params.onNotifyClose (function) - callback when notification is closed
            * params.onNotifyClick (function) - callback when notification is clicked
            * params.onNotifyError (function) - callback when notification throws an error
            * */

            if (!params.title && !params.body) return null;

            var notifyParams = {
                body: params.body
            };

            if (typeof params.onNotifyShow === "function") notifyParams.notifyShow = params.onNotifyShow;

            if (typeof params.onNotifyClose === "function") notifyParams.notifyClose = params.onNotifyShow;

            if (typeof params.onNotifyClick === "function") notifyParams.notifyClick = params.onNotifyShow;

            if (typeof params.onNotifyError === "function") notifyParams.notifyError = params.onNotifyShow;

            var notify = new Notify(params.title, notifyParams);

            notify.show();
        }
    }, {
        key: "_getRealAmountByAssetId",
        value: function _getRealAmountByAssetId(amount, assetId) {
            var asset = ChainStore.getAsset(assetId);
            if (!asset) return null;
            return utils.get_asset_amount(amount, asset);
        }
    }, {
        key: "_getAssetSymbolByAssetId",
        value: function _getAssetSymbolByAssetId(assetId) {
            var asset = ChainStore.getAsset(assetId);
            if (!asset) return null;
            return asset.get("symbol");
        }
    }, {
        key: "_getAccountNameById",
        value: function _getAccountNameById(accountId) {
            var account = ChainStore.getAccount(accountId);
            if (!account) return "";
            return account.get("name");
        }
    }, {
        key: "render",
        value: function render() {
            return null;
        }
    }]);

    return BrowserNotifications;
}(React.Component);

BrowserNotifications.propTypes = {
    account: ChainTypes.ChainAccount.isRequired,
    settings: PropTypes.object
};


BrowserNotifications = BindToChainState(BrowserNotifications);

export default BrowserNotifications;