var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from "react";
import { Component } from "react";
import PropTypes from "prop-types";
import sha256 from "js-sha256";
import jdenticon from "jdenticon";

var canvas_id_count = 0;

var Identicon = function (_Component) {
    _inherits(Identicon, _Component);

    function Identicon(props) {
        _classCallCheck(this, Identicon);

        var _this = _possibleConstructorReturn(this, (Identicon.__proto__ || Object.getPrototypeOf(Identicon)).call(this, props));

        _this.canvas_id = "identicon_" + (_this.props.account || "") + ++canvas_id_count;
        return _this;
    }

    _createClass(Identicon, [{
        key: "shouldComponentUpdate",
        value: function shouldComponentUpdate(nextProps) {
            return nextProps.size.height !== this.props.size.height || nextProps.size.width !== this.props.size.width || nextProps.account !== this.props.account;
        }
    }, {
        key: "render",
        value: function render() {
            var account = this.props.account;
            var _props$size = this.props.size,
                height = _props$size.height,
                width = _props$size.width;

            var hash = account ? sha256(account) : null;
            return React.createElement("canvas", {
                id: this.canvas_id,
                ref: "canvas",
                style: { height: height, width: width },
                width: width * 2,
                height: height * 2,
                "data-jdenticon-hash": hash
            });
        }
    }, {
        key: "repaint",
        value: function repaint() {
            if (this.props.account) jdenticon.updateById(this.canvas_id);else {
                var ctx = this.refs.canvas.getContext("2d");
                ctx.fillStyle = "rgba(100, 100, 100, 0.5)";
                var size = ctx.canvas.width;
                ctx.clearRect(0, 0, size, size);
                ctx.fillRect(0, 0, size, size);
                ctx.clearRect(0 + 1, 0 + 1, size - 2, size - 2);
                ctx.font = size + "px sans-serif";
                ctx.fillText("?", size / 4, size - size / 6);
            }
        }
    }, {
        key: "componentDidMount",
        value: function componentDidMount() {
            this.repaint();
        }
    }, {
        key: "componentDidUpdate",
        value: function componentDidUpdate() {
            this.repaint();
        }
    }]);

    return Identicon;
}(Component);

Identicon.propTypes = {
    size: PropTypes.object.isRequired,
    account: PropTypes.string
};

export default Identicon;