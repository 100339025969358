/* eslint-disable react/display-name */
import React from "react";
import Translate from "react-translate-component";
import FormattedAsset from "../../Utility/FormattedAsset";
import FormattedPrice from "../../Utility/FormattedPrice";
import BindToChainState from "../../Utility/BindToChainState";
import TranslateWithLinks from "../../Utility/TranslateWithLinks";
import marketUtils from "common/market_utils";

export var FillOrder = function FillOrder(_ref) {
    var changeColor = _ref.changeColor,
        op = _ref.op,
        linkToAccount = _ref.linkToAccount,
        marketDirections = _ref.marketDirections,
        fromComponent = _ref.fromComponent;

    changeColor("success");
    var o = op[1];
    if (fromComponent === "proposed_operation") {
        return React.createElement(
            "span",
            null,
            linkToAccount(op.account_id),
            "\xA0",
            React.createElement(Translate, { component: "span", content: "proposal.paid" }),
            "\xA0",
            React.createElement(FormattedAsset, {
                style: { fontWeight: "bold" },
                amount: op.pays.amount,
                asset: op.pays.asset_id
            }),
            "\xA0",
            React.createElement(Translate, { component: "span", content: "proposal.obtain" }),
            "\xA0",
            React.createElement(FormattedAsset, {
                style: { fontWeight: "bold" },
                amount: op.receives.amount,
                asset: op.receives.asset_id
            }),
            "\xA0",
            React.createElement(Translate, { component: "span", content: "proposal.at" }),
            "\xA0",
            React.createElement(FormattedPrice, {
                base_asset: o.pays.asset_id,
                base_amount: o.pays.amount,
                quote_asset: o.receives.asset_id,
                quote_amount: o.receives.amount
            })
        );
    } else {
        return React.createElement(
            "span",
            null,
            React.createElement(
                BindToChainState.Wrapper,
                {
                    base: o.receives.asset_id,
                    quote: o.pays.asset_id
                },
                function (_ref2) {
                    var base = _ref2.base,
                        quote = _ref2.quote;

                    var _marketUtils$getMarke = marketUtils.getMarketName(base, quote),
                        marketName = _marketUtils$getMarke.marketName,
                        first = _marketUtils$getMarke.first,
                        second = _marketUtils$getMarke.second;

                    var inverted = marketDirections.get(marketName);
                    var isBid = o.pays.asset_id === (inverted ? first.get("id") : second.get("id"));

                    var priceBase = isBid ? o.receives : o.pays;
                    var priceQuote = isBid ? o.pays : o.receives;
                    var amount = isBid ? o.receives : o.pays;
                    var receivedAmount = o.fee.asset_id === amount.asset_id ? amount.amount - o.fee.amount : amount.amount;

                    return React.createElement(TranslateWithLinks, {
                        string: "operation.fill_order_" + (isBid ? "buy" : "sell"),
                        keys: [{
                            type: "account",
                            value: op[1].account_id,
                            arg: "account"
                        }, {
                            type: "amount",
                            value: {
                                amount: receivedAmount,
                                asset_id: amount.asset_id
                            },
                            arg: "amount"
                        }, {
                            type: "price",
                            value: {
                                base: priceBase,
                                quote: priceQuote
                            },
                            arg: "price"
                        }],
                        params: {
                            order: o.order_id.substring(4)
                        }
                    });
                }
            )
        );
    }
};