import React from "react";
import Translate from "react-translate-component";

export var BondCancelOffer = function BondCancelOffer(_ref) {
    var op = _ref.op;

    return React.createElement(
        "span",
        null,
        React.createElement(Translate, { component: "span", content: "proposal.bond_cancel_offer" }),
        "\xA0",
        op[1].offer_id
    );
};