var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import Immutable from "immutable";
import alt from "alt-instance";
import BaseStore from "./BaseStore";
import idb_helper from "idb-helper";
import WalletDb from "./WalletDb";

import { PrivateKeyTcomb } from "./tcomb_structs";
import PrivateKeyActions from "actions/PrivateKeyActions";
import CachedPropertyActions from "actions/CachedPropertyActions";
import AddressIndex from "stores/AddressIndex";
import { PublicKey, ChainStore, Aes } from "bitsharesjs";

/** No need to wait on the promises returned by this store as long as
    this.state.privateKeyStorage_error == false and
    this.state.pending_operation_count == 0 before performing any important
    operations.
*/

var PrivateKeyStore = function (_BaseStore) {
    _inherits(PrivateKeyStore, _BaseStore);

    function PrivateKeyStore() {
        _classCallCheck(this, PrivateKeyStore);

        var _this = _possibleConstructorReturn(this, (PrivateKeyStore.__proto__ || Object.getPrototypeOf(PrivateKeyStore)).call(this));

        _this.state = _this._getInitialState();
        _this.pending_operation_count = 0;
        _this.bindListeners({
            onLoadDbData: PrivateKeyActions.loadDbData,
            onAddKey: PrivateKeyActions.addKey
        });
        _this._export("hasKey", "getPubkeys", "getTcomb_byPubkey", "getPubkeys_having_PrivateKey", "addPrivateKeys_noindex", "decodeMemo", "setPasswordLoginKey");
        return _this;
    }

    _createClass(PrivateKeyStore, [{
        key: "_getInitialState",
        value: function _getInitialState() {
            return {
                keys: Immutable.Map(),
                privateKeyStorage_error: false,
                pending_operation_count: 0,
                privateKeyStorage_error_add_key: null,
                privateKeyStorage_error_loading: null
            };
        }
    }, {
        key: "setPasswordLoginKey",
        value: function setPasswordLoginKey(key) {
            var keys = this.state.keys.set(key.pubkey, key);
            this.setState({
                keys: keys
            });
        }

        /** This method may be called again should the main database change */

    }, {
        key: "onLoadDbData",
        value: function onLoadDbData(resolve) {
            var _this2 = this;

            //resolve is deprecated
            this.pendingOperation();
            this.setState(this._getInitialState());
            var keys = Immutable.Map().asMutable();
            var p = idb_helper.cursor("private_keys", function (cursor) {
                if (!cursor) {
                    _this2.setState({ keys: keys.asImmutable() });
                    return;
                }
                var private_key_tcomb = PrivateKeyTcomb(cursor.value);
                keys.set(private_key_tcomb.pubkey, private_key_tcomb);
                AddressIndex.add(private_key_tcomb.pubkey);
                cursor.continue();
            }).then(function () {
                _this2.pendingOperationDone();
            }).catch(function (error) {
                _this2.setState(_this2._getInitialState());
                _this2.privateKeyStorageError("loading", error);
                throw error;
            });
            resolve(p);
        }
    }, {
        key: "hasKey",
        value: function hasKey(pubkey) {
            return this.state.keys.has(pubkey);
        }
    }, {
        key: "getPubkeys",
        value: function getPubkeys() {
            return this.state.keys.keySeq().toArray();
        }
    }, {
        key: "getPubkeys_having_PrivateKey",
        value: function getPubkeys_having_PrivateKey(pubkeys) {
            var addys = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

            var return_pubkeys = [];
            if (pubkeys) {
                var _iteratorNormalCompletion = true;
                var _didIteratorError = false;
                var _iteratorError = undefined;

                try {
                    for (var _iterator = pubkeys[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                        var pubkey = _step.value;

                        if (this.hasKey(pubkey)) {
                            return_pubkeys.push(pubkey);
                        }
                    }
                } catch (err) {
                    _didIteratorError = true;
                    _iteratorError = err;
                } finally {
                    try {
                        if (!_iteratorNormalCompletion && _iterator.return) {
                            _iterator.return();
                        }
                    } finally {
                        if (_didIteratorError) {
                            throw _iteratorError;
                        }
                    }
                }
            }
            if (addys) {
                var addresses = AddressIndex.getState().addresses;
                var _iteratorNormalCompletion2 = true;
                var _didIteratorError2 = false;
                var _iteratorError2 = undefined;

                try {
                    for (var _iterator2 = addys[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
                        var addy = _step2.value;

                        var _pubkey = addresses.get(addy);
                        return_pubkeys.push(_pubkey);
                    }
                } catch (err) {
                    _didIteratorError2 = true;
                    _iteratorError2 = err;
                } finally {
                    try {
                        if (!_iteratorNormalCompletion2 && _iterator2.return) {
                            _iterator2.return();
                        }
                    } finally {
                        if (_didIteratorError2) {
                            throw _iteratorError2;
                        }
                    }
                }
            }
            return return_pubkeys;
        }
    }, {
        key: "getTcomb_byPubkey",
        value: function getTcomb_byPubkey(public_key) {
            if (!public_key) return null;
            if (public_key.Q) public_key = public_key.toPublicKeyString();
            return this.state.keys.get(public_key);
        }
    }, {
        key: "onAddKey",
        value: function onAddKey(_ref) {
            var _this3 = this;

            var private_key_object = _ref.private_key_object,
                transaction = _ref.transaction,
                resolve = _ref.resolve;

            // resolve is deprecated
            if (this.state.keys.has(private_key_object.pubkey)) {
                resolve({ result: "duplicate", id: null });
                return;
            }

            this.pendingOperation();
            //console.log("... onAddKey private_key_object.pubkey", private_key_object.pubkey)

            this.state.keys = this.state.keys.set(private_key_object.pubkey, PrivateKeyTcomb(private_key_object));
            this.setState({ keys: this.state.keys });
            AddressIndex.add(private_key_object.pubkey);
            var p = new Promise(function (resolve, reject) {
                PrivateKeyTcomb(private_key_object);
                var duplicate = false;
                var p = idb_helper.add(transaction.objectStore("private_keys"), private_key_object);

                p.catch(function (event) {
                    // ignore_duplicates
                    var error = event.target.error;
                    console.log("... error", error, event);
                    if (error.name !== "ConstraintError" || error.message.indexOf("by_encrypted_key") === -1) {
                        _this3.privateKeyStorageError("add_key", error);
                        throw event;
                    }
                    duplicate = true;
                    event.preventDefault();
                }).then(function () {
                    _this3.pendingOperationDone();
                    if (duplicate) return { result: "duplicate", id: null };
                    if (private_key_object.brainkey_sequence == null) _this3.binaryBackupRecommended(); // non-deterministic
                    idb_helper.on_transaction_end(transaction).then(function () {
                        _this3.setState({ keys: _this3.state.keys });
                    });
                    return {
                        result: "added",
                        id: private_key_object.id
                    };
                });
                resolve(p);
            });
            resolve(p);
        }

        /** WARN: does not update AddressIndex.  This is designed for bulk importing.
            @return duplicate_count
        */

    }, {
        key: "addPrivateKeys_noindex",
        value: function addPrivateKeys_noindex(private_key_objects, transaction) {
            var _this4 = this;

            var store = transaction.objectStore("private_keys");
            var duplicate_count = 0;
            var keys = this.state.keys.withMutations(function (keys) {
                var _iteratorNormalCompletion3 = true;
                var _didIteratorError3 = false;
                var _iteratorError3 = undefined;

                try {
                    for (var _iterator3 = private_key_objects[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
                        var private_key_object = _step3.value;

                        if (_this4.state.keys.has(private_key_object.pubkey)) {
                            duplicate_count++;
                            continue;
                        }
                        var private_tcomb = PrivateKeyTcomb(private_key_object);
                        store.add(private_key_object);
                        keys.set(private_key_object.pubkey, private_tcomb);
                        ChainStore.getAccountRefsOfKey(private_key_object.pubkey);
                    }
                } catch (err) {
                    _didIteratorError3 = true;
                    _iteratorError3 = err;
                } finally {
                    try {
                        if (!_iteratorNormalCompletion3 && _iterator3.return) {
                            _iterator3.return();
                        }
                    } finally {
                        if (_didIteratorError3) {
                            throw _iteratorError3;
                        }
                    }
                }
            });
            this.setState({ keys: keys });
            this.binaryBackupRecommended();
            return duplicate_count;
        }
    }, {
        key: "binaryBackupRecommended",
        value: function binaryBackupRecommended() {
            CachedPropertyActions.set("backup_recommended", true);
        }
    }, {
        key: "pendingOperation",
        value: function pendingOperation() {
            this.pending_operation_count++;
            this.setState({ pending_operation_count: this.pending_operation_count });
        }
    }, {
        key: "pendingOperationDone",
        value: function pendingOperationDone() {
            if (this.pending_operation_count === 0) throw new Error("Pending operation done called too many times");
            this.pending_operation_count--;
            this.setState({ pending_operation_count: this.pending_operation_count });
        }
    }, {
        key: "privateKeyStorageError",
        value: function privateKeyStorageError(property, error) {
            this.pendingOperationDone();
            var state = { privateKeyStorage_error: true };
            state["privateKeyStorage_error_" + property] = error;
            console.error("privateKeyStorage_error_" + property, error);
            this.setState(state);
        }
    }, {
        key: "decodeMemo",
        value: function decodeMemo(memo) {
            var lockedWallet = false;
            var memo_text = void 0,
                isMine = false;
            var from_private_key = this.state.keys.get(memo.from);
            var to_private_key = this.state.keys.get(memo.to);
            var private_key = from_private_key ? from_private_key : to_private_key;
            var public_key = from_private_key ? memo.to : memo.from;
            public_key = PublicKey.fromPublicKeyString(public_key);

            try {
                private_key = WalletDb.decryptTcomb_PrivateKey(private_key);
            } catch (e) {
                // Failed because wallet is locked
                lockedWallet = true;
                private_key = null;
                isMine = true;
            }

            if (private_key) {
                var tryLegacy = false;
                try {
                    memo_text = private_key ? Aes.decrypt_with_checksum(private_key, public_key, memo.nonce, memo.message).toString("utf-8") : null;

                    if (private_key && !memo_text) {
                        // debugger
                    }
                } catch (e) {
                    console.log("transfer memo exception ...", e);
                    memo_text = "*";
                    tryLegacy = true;
                }

                // Apply legacy method if new, correct method fails to decode
                if (private_key && tryLegacy) {
                    // debugger;
                    try {
                        memo_text = Aes.decrypt_with_checksum(private_key, public_key, memo.nonce, memo.message, true).toString("utf-8");
                    } catch (e) {
                        console.log("transfer memo exception ...", e);
                        memo_text = "**";
                    }
                }
            }

            return {
                text: memo_text,
                isMine: isMine
            };
        }
    }]);

    return PrivateKeyStore;
}(BaseStore);

export default alt.createStore(PrivateKeyStore, "PrivateKeyStore");