var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from "react";
// import PropTypes from "prop-types";
// import ZfApi from "react-foundation-apps/src/utils/foundation-api";
// import Modal from "react-foundation-apps/src/modal";
// import Trigger from "react-foundation-apps/src/trigger";
// import Translate from "react-translate-component";

// import {getLogo} from "branding";
// var logo = getLogo();

var BaseModal = function (_React$Component) {
    _inherits(BaseModal, _React$Component);

    function BaseModal() {
        _classCallCheck(this, BaseModal);

        return _possibleConstructorReturn(this, (BaseModal.__proto__ || Object.getPrototypeOf(BaseModal)).apply(this, arguments));
    }

    _createClass(BaseModal, [{
        key: "render",
        value: function render() {
            return React.createElement(
                "div",
                null,
                "Base Modal was removed by task following below:",
                React.createElement("br", null),
                "https://github.com/bitshares/bitshares-ui/issues/1942"
            );
        }
    }]);

    return BaseModal;
}(React.Component);

// BaseModal.defaultProps = {
//     overlay: false
// };
//
// BaseModal.propTypes = {
//     id: PropTypes.string.isRequired,
//     onClose: PropTypes.func,
//     className: PropTypes.string,
//     overlay: PropTypes.bool,
//     overlayClose: PropTypes.bool,
//     noCloseBtn: PropTypes.bool
// };

export default BaseModal;