var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

import { Apis } from "bitsharesjs-ws";

var Api = function () {
    function Api() {
        _classCallCheck(this, Api);
    }

    _createClass(Api, [{
        key: "lookupAccounts",
        value: function lookupAccounts(startChar, limit) {
            return Apis.instance().db_api().exec("lookup_accounts", [startChar, limit]);
        }
    }]);

    return Api;
}();

export default new Api();