var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from "react";
import Translate from "react-translate-component";
import FormattedAsset from "./FormattedAsset";
import FloatingDropdown from "./FloatingDropdown";
import Immutable from "immutable";
import counterpart from "counterpart";
import AssetWrapper from "./AssetWrapper";
import utils from "common/utils";
import PropTypes from "prop-types";
import { DecimalChecker } from "./DecimalChecker";

var AssetSelector = function (_React$Component) {
    _inherits(AssetSelector, _React$Component);

    function AssetSelector() {
        _classCallCheck(this, AssetSelector);

        return _possibleConstructorReturn(this, (AssetSelector.__proto__ || Object.getPrototypeOf(AssetSelector)).apply(this, arguments));
    }

    _createClass(AssetSelector, [{
        key: "shouldComponentUpdate",
        value: function shouldComponentUpdate(np) {
            return !utils.are_equal_shallow(np.assets, this.props.assets) || np.value !== this.props.value || np.scroll_length !== this.props.scroll_length;
        }
    }, {
        key: "render",
        value: function render() {
            if (!this.props.assets.length) return null;

            return React.createElement(FloatingDropdown, {
                entries: this.props.assets.map(function (a) {
                    return a && a.get("symbol");
                }).filter(function (a) {
                    return !!a;
                }),
                values: this.props.assets.reduce(function (map, a) {
                    if (a && a.get("symbol")) map[a.get("symbol")] = a;
                    return map;
                }, {}),
                singleEntry: this.props.assets[0] ? React.createElement(FormattedAsset, {
                    asset: this.props.assets[0].get("id"),
                    amount: 0,
                    hide_amount: true
                }) : null,
                value: this.props.value,
                onChange: this.props.onChange,
                scroll_length: this.props.scroll_length
            });
        }
    }]);

    return AssetSelector;
}(React.Component);

AssetSelector.propTypes = {
    value: PropTypes.string, // asset id
    onChange: PropTypes.func,
    scroll_length: PropTypes.number
};


AssetSelector = AssetWrapper(AssetSelector, { asList: true });

var AmountSelector = function (_DecimalChecker) {
    _inherits(AmountSelector, _DecimalChecker);

    function AmountSelector() {
        _classCallCheck(this, AmountSelector);

        return _possibleConstructorReturn(this, (AmountSelector.__proto__ || Object.getPrototypeOf(AmountSelector)).apply(this, arguments));
    }

    _createClass(AmountSelector, [{
        key: "componentDidMount",
        value: function componentDidMount() {
            this.onAssetChange(this.props.asset);
        }
    }, {
        key: "formatAmount",
        value: function formatAmount(v) {
            /*// TODO: use asset's precision to format the number*/
            if (!v) v = "";
            if (typeof v === "number") v = v.toString();
            var value = v.trim().replace(/,/g, "");

            return value;
        }
    }, {
        key: "_onChange",
        value: function _onChange(e) {
            if (this.props.onChange) this.props.onChange({
                amount: this.getNumericEventValue(e),
                asset: this.props.asset
            });
        }
    }, {
        key: "onAssetChange",
        value: function onAssetChange(selected_asset) {
            if (this.props.onChange) this.props.onChange({
                amount: this.props.amount,
                asset: selected_asset
            });
        }
    }, {
        key: "render",
        value: function render() {
            //console.log("Calling AmountSelector: " + this.props.label + this.props.asset + this.props.assets + this.props.amount + this.props.placeholder + this.props.error);
            var value = this.props.error ? counterpart.translate(this.props.error) : this.formatAmount(this.props.amount);

            return React.createElement(
                "div",
                { className: "amount-selector", style: this.props.style },
                React.createElement(
                    "label",
                    { className: "right-label" },
                    this.props.display_balance
                ),
                React.createElement(Translate, {
                    className: "left-label",
                    component: "label",
                    content: this.props.label
                }),
                React.createElement(
                    "div",
                    { className: "inline-label input-wrapper" },
                    React.createElement("input", {
                        disabled: this.props.disabled,
                        type: "text",
                        value: value || "",
                        placeholder: this.props.placeholder,
                        onChange: this._onChange.bind(this),
                        tabIndex: this.props.tabIndex,
                        onPaste: this.props.onPaste || this.onPaste.bind(this),
                        onKeyPress: this.onKeyPress.bind(this)
                    }),
                    React.createElement(
                        "div",
                        { className: "form-label select floating-dropdown" },
                        this.props.isPrice ? React.createElement(
                            "div",
                            { className: "dropdown-wrapper inactive" },
                            React.createElement(
                                "div",
                                null,
                                this.props.asset.get("symbol"),
                                "/",
                                this.props.base
                            )
                        ) : React.createElement(AssetSelector, {
                            ref: this.props.refCallback,
                            value: this.props.asset.get("symbol"),
                            assets: Immutable.List(this.props.assets),
                            onChange: this.onAssetChange.bind(this),
                            scroll_length: this.props.scroll_length
                        })
                    )
                )
            );
        }
    }]);

    return AmountSelector;
}(DecimalChecker);

AmountSelector.propTypes = {
    label: PropTypes.string, // a translation key for the label
    assets: PropTypes.array,
    amount: PropTypes.any,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    tabIndex: PropTypes.number,
    error: PropTypes.string,
    scroll_length: PropTypes.number
};
AmountSelector.defaultProps = {
    disabled: false,
    tabIndex: 0
};

AmountSelector = AssetWrapper(AmountSelector);

export default AmountSelector;