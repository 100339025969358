var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

import alt from "alt-instance";

var SettingsActions = function () {
    function SettingsActions() {
        _classCallCheck(this, SettingsActions);
    }

    _createClass(SettingsActions, [{
        key: "changeSetting",
        value: function changeSetting(value) {
            return value;
        }
    }, {
        key: "changeViewSetting",
        value: function changeViewSetting(value) {
            return value;
        }
    }, {
        key: "changeMarketDirection",
        value: function changeMarketDirection(value) {
            return value;
        }
    }, {
        key: "addStarMarket",
        value: function addStarMarket(quote, base) {
            return { quote: quote, base: base };
        }
    }, {
        key: "removeStarMarket",
        value: function removeStarMarket(quote, base) {
            return { quote: quote, base: base };
        }
    }, {
        key: "clearStarredMarkets",
        value: function clearStarredMarkets() {
            return true;
        }
    }, {
        key: "setUserMarket",
        value: function setUserMarket(quote, base, value) {
            return { quote: quote, base: base, value: value };
        }
    }, {
        key: "addWS",
        value: function addWS(ws) {
            return ws;
        }
    }, {
        key: "removeWS",
        value: function removeWS(index) {
            return index;
        }
    }, {
        key: "hideWS",
        value: function hideWS(url) {
            return url;
        }
    }, {
        key: "showWS",
        value: function showWS(url) {
            return url;
        }
    }, {
        key: "hideAsset",
        value: function hideAsset(id, status) {
            return { id: id, status: status };
        }
    }, {
        key: "hideMarket",
        value: function hideMarket(id, status) {
            return { id: id, status: status };
        }
    }, {
        key: "clearSettings",
        value: function clearSettings() {
            return function (dispatch) {
                return new Promise(function (resolve) {
                    dispatch(resolve);
                });
            };
        }
    }, {
        key: "updateLatencies",
        value: function updateLatencies(latencies) {
            return latencies;
        }
    }, {
        key: "setExchangeLastExpiration",
        value: function setExchangeLastExpiration(value) {
            return value;
        }
    }, {
        key: "setExchangeTutorialShown",
        value: function setExchangeTutorialShown(value) {
            return value;
        }
    }, {
        key: "modifyPreferedBases",
        value: function modifyPreferedBases(payload) {
            return payload;
        }
    }, {
        key: "updateUnits",
        value: function updateUnits() {
            return true;
        }
    }, {
        key: "setPriceAlert",
        value: function setPriceAlert(value) {
            return value;
        }
    }, {
        key: "hideNewsHeadline",
        value: function hideNewsHeadline(value) {
            return value;
        }
    }]);

    return SettingsActions;
}();

export default alt.createActions(SettingsActions);