var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from "react";
import Immutable from "immutable";
import cname from "classnames";
import PropTypes from "prop-types";
import counterpart from "counterpart";
import { Form, Input } from "bitshares-ui-style-guide";

var FormItem = Form.Item;

var PasswordConfirm = function (_Component) {
    _inherits(PasswordConfirm, _Component);

    function PasswordConfirm() {
        _classCallCheck(this, PasswordConfirm);

        var _this = _possibleConstructorReturn(this, (PasswordConfirm.__proto__ || Object.getPrototypeOf(PasswordConfirm)).call(this));

        _this.getInputNode = function (node) {
            _this.firstPassword = node;
        };

        _this.state = {
            password: "",
            confirm: "",
            errors: Immutable.Map(),
            // An empty form has no errors but is still invaid
            valid: false
        };
        return _this;
    }

    _createClass(PasswordConfirm, [{
        key: "componentDidMount",
        value: function componentDidMount() {
            if (this.firstPassword) {
                this.firstPassword.focus();
            }
        }
    }, {
        key: "formChange",
        value: function formChange(event) {
            var key = event.target.id === "current-password" ? "password" : "confirm";
            var state = {};
            state[key] = event.target.value;
            this.setState(state, this.validate);
        }
    }, {
        key: "validate",
        value: function validate() {
            var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.state;
            var password = state.password,
                confirm = state.confirm;

            confirm = confirm.trim();
            password = password.trim();

            var errors = Immutable.Map();
            // Don't report until typing begins
            if (password.length !== 0 && password.length < 8) errors = errors.set("password_length", "Password must be 8 characters or more");

            // Don't report it until the confirm is populated
            if (password !== "" && confirm !== "" && password !== confirm) errors = errors.set("password_match", "Passwords do not match");

            var valid = password.length >= 8 && password === confirm;
            this.setState({ errors: errors, valid: valid });
            this.props.onValid(valid ? password : null);
        }
    }, {
        key: "render",
        value: function render() {
            var _state = this.state,
                password = _state.password,
                confirm = _state.confirm,
                errors = _state.errors;
            var newPassword = this.props.newPassword;

            var tabIndex = 1;

            return React.createElement(
                "div",
                { className: cname({ "has-error": errors.size }) },
                React.createElement(
                    FormItem,
                    {
                        label: counterpart.translate(newPassword ? "wallet.new_password" : "wallet.password")
                    },
                    React.createElement(
                        "section",
                        null,
                        React.createElement(Input, {
                            type: "password",
                            id: "current-password",
                            autoComplete: "current-password",
                            ref: this.getInputNode(),
                            onChange: this.formChange.bind(this),
                            value: password,
                            tabIndex: tabIndex++
                        })
                    )
                ),
                React.createElement(
                    FormItem,
                    {
                        label: counterpart.translate(newPassword ? "wallet.new_confirm" : "wallet.confirm")
                    },
                    React.createElement(
                        "section",
                        { className: cname({ "has-error": errors.size }) },
                        React.createElement(Input, {
                            type: "password",
                            id: "new-password",
                            autoComplete: "new-password",
                            onChange: this.formChange.bind(this),
                            value: confirm,
                            tabIndex: tabIndex++
                        }),
                        React.createElement(
                            "div",
                            null,
                            errors.get("password_match") || errors.get("password_length")
                        )
                    )
                ),
                this.props.children,
                React.createElement("br", null)
            );
        }
    }]);

    return PasswordConfirm;
}(Component);

PasswordConfirm.propTypes = {
    // Called everytime a valid password is provided and matches a confirmed password
    onValid: PropTypes.func.isRequired
};
export default PasswordConfirm;