var icons = ["balance", "coinpaprika", "statistics", "listing", "chat", "explorer", "deposit-withdraw", "molecule", "growth", "computer", "network", "pie-chart", "privacy", "photo-camera", "adjust", "alarm", "assets", "autolock", "barter", "borrow", "checkmark-circle", "chevron-down", "clippy", "clock", "cog", "cogs", "coming_soon", "connected", "connect", "cross-circle", "dashboard", "deposit", "disconnected", "direct_debit", "dollar-green", "dollar", "download", "excel", "eye", "eye-striked", "fi-star", "folder", "grouping", "hamburger-x", "hamburger", "htlc", "hourglass", "key", "list", "locked", "minus-circle", "news", "plus-circle", "power", "question-circle", "server", "settle", "share", "showcases", "shuffle", "text", "thumb-tack", "thumb-untack", "thumbs-up", "times", "trade", "transfer", "unlocked", "user", "voting", "warning", "withdraw", "filter", "info-circle-o", "zoom", "people", "fire", "question-in-circle", "attention", "checkmark", "paperclip", "wallet", "prediction", "prediction-large"];

var iconsMap = {};
var _iteratorNormalCompletion = true;
var _didIteratorError = false;
var _iteratorError = undefined;

try {
    for (var _iterator = icons[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var i = _step.value;
        iconsMap[i] = require("./" + i + ".svg");
    }
} catch (err) {
    _didIteratorError = true;
    _iteratorError = err;
} finally {
    try {
        if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
        }
    } finally {
        if (_didIteratorError) {
            throw _iteratorError;
        }
    }
}

export default iconsMap;