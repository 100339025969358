var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import alt from "alt-instance";
import BackupActions from "actions/BackupActions";
import BaseStore from "stores/BaseStore";
import { hash, PublicKey } from "bitsharesjs";

var BackupStore = function (_BaseStore) {
    _inherits(BackupStore, _BaseStore);

    function BackupStore() {
        _classCallCheck(this, BackupStore);

        var _this = _possibleConstructorReturn(this, (BackupStore.__proto__ || Object.getPrototypeOf(BackupStore)).call(this));

        _this.state = _this._getInitialState();
        _this.bindListeners({
            onIncommingFile: BackupActions.incommingWebFile,
            onIncommingBuffer: BackupActions.incommingBuffer,
            onReset: BackupActions.reset
        });
        _this._export("setWalletObjct");
        return _this;
    }

    _createClass(BackupStore, [{
        key: "_getInitialState",
        value: function _getInitialState() {
            return {
                name: null,
                contents: null,
                sha1: null,
                size: null,
                last_modified: null,
                public_key: null,
                wallet_object: null
            };
        }
    }, {
        key: "setWalletObjct",
        value: function setWalletObjct(wallet_object) {
            this.setState({ wallet_object: wallet_object });
        }
    }, {
        key: "onReset",
        value: function onReset() {
            this.setState(this._getInitialState());
        }
    }, {
        key: "onIncommingFile",
        value: function onIncommingFile(_ref) {
            var name = _ref.name,
                contents = _ref.contents,
                last_modified = _ref.last_modified;

            var sha1 = hash.sha1(contents).toString("hex");
            var size = contents.length;
            var public_key = getBackupPublicKey(contents);
            this.setState({ name: name, contents: contents, sha1: sha1, size: size, last_modified: last_modified, public_key: public_key });
        }
    }, {
        key: "onIncommingBuffer",
        value: function onIncommingBuffer(_ref2) {
            var name = _ref2.name,
                contents = _ref2.contents,
                public_key = _ref2.public_key;

            this.onReset();
            var sha1 = hash.sha1(contents).toString("hex");
            var size = contents.length;
            if (!public_key) public_key = getBackupPublicKey(contents);
            this.setState({ name: name, contents: contents, sha1: sha1, size: size, public_key: public_key });
        }
    }]);

    return BackupStore;
}(BaseStore);

export var BackupStoreWrapped = alt.createStore(BackupStore, "BackupStore");
export default BackupStoreWrapped;

function getBackupPublicKey(contents) {
    try {
        return PublicKey.fromBuffer(contents.slice(0, 33));
    } catch (e) {
        console.error(e, e.stack);
    }
}