var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from "react";
import BaseModal from "../Modal/BaseModal";
import ZfApi from "react-foundation-apps/src/utils/foundation-api";
import PasswordInput from "../Forms/PasswordInput";
import AltContainer from "alt-container";
import WalletDb from "stores/WalletDb";
import WalletUnlockStore from "stores/WalletUnlockStore";
import WalletManagerStore from "stores/WalletManagerStore";
import BackupStore from "stores/BackupStore";
import AccountStore from "stores/AccountStore";
import SettingsStore from "stores/SettingsStore";
import WalletUnlockActions from "actions/WalletUnlockActions";
import WalletActions from "actions/WalletActions";
import BackupActions, { restore, backup } from "actions/BackupActions";
import AccountActions from "actions/AccountActions";
import SettingsActions from "actions/SettingsActions";
import { Apis } from "bitsharesjs-ws";
import { Modal, Button, Form, Input, Switch, InputNumber, Tooltip, Notification } from "bitshares-ui-style-guide";
import utils from "common/utils";
import AccountSelector from "../Account/AccountSelectorAnt";
import { PrivateKey } from "bitsharesjs";
import { saveAs } from "file-saver";
import LoginTypeSelector from "./LoginTypeSelector";
import counterpart from "counterpart";
import { WalletSelector, CreateLocalWalletLink, WalletDisplay, BackupWarning, BackupFileSelector, DisableChromeAutocomplete, KeyFileLabel } from "./WalletUnlockModalLib";
import { backupName } from "common/backupUtils";
import { withRouter } from "react-router-dom";
import { setLocalStorageType, isPersistantType } from "lib/common/localStorage";
import Translate from "react-translate-component";
import Icon from "../Icon/Icon";

var WalletUnlockModal = function (_React$Component) {
    _inherits(WalletUnlockModal, _React$Component);

    function WalletUnlockModal(props) {
        _classCallCheck(this, WalletUnlockModal);

        var _this = _possibleConstructorReturn(this, (WalletUnlockModal.__proto__ || Object.getPrototypeOf(WalletUnlockModal)).call(this, props));

        _initialiseProps.call(_this);

        _this.state = _this.initialState(props);
        _this.account_input = React.createRef();

        _this.handlePasswordChange = _this.handlePasswordChange.bind(_this);
        return _this;
    }

    _createClass(WalletUnlockModal, [{
        key: "componentWillReceiveProps",
        value: function componentWillReceiveProps(np) {
            var _state = this.state,
                walletSelected = _state.walletSelected,
                restoringBackup = _state.restoringBackup,
                accountName = _state.accountName;
            var newCurrentWallet = np.currentWallet,
                newPasswordAccount = np.passwordAccount;


            var newState = {};
            // Updating the accountname through the listener breaks UX (#2335)
            if (walletSelected && !restoringBackup && !newCurrentWallet) newState.walletSelected = false;
            if (this.props.passwordLogin != np.passwordLogin) {
                newState.passwordError = false;
                newState.customError = null;
            }

            this.setState(newState);
        }
    }, {
        key: "shouldComponentUpdate",
        value: function shouldComponentUpdate(np, ns) {
            if (this.state.isOpen && !ns.isOpen) return false;
            return !utils.are_equal_shallow(np, this.props) || !utils.are_equal_shallow(ns, this.state);
        }
    }, {
        key: "handlePasswordChange",
        value: function handlePasswordChange(event) {
            this.setState({
                password: event.target.value
            });
        }
    }, {
        key: "componentDidMount",
        value: function componentDidMount() {
            var _this2 = this;

            var modalId = this.props.modalId;

            ZfApi.subscribe(modalId, function (name, msg) {
                var isOpen = _this2.state.isOpen;


                if (name !== modalId) return;
                if (msg === "close" && isOpen) {
                    _this2.handleModalClose();
                } else if (msg === "open" && !isOpen) {
                    _this2.handleModalOpen();
                }
            });
        }
    }, {
        key: "componentDidUpdate",
        value: function componentDidUpdate() {
            var _props = this.props,
                resolve = _props.resolve,
                isLocked = _props.isLocked,
                passwordLogin = _props.passwordLogin;
            var _state2 = this.state,
                isModalVisible = _state2.isModalVisible,
                accountName = _state2.accountName,
                focusedOnce = _state2.focusedOnce;


            if (!focusedOnce && isModalVisible && passwordLogin) {
                var account_input = this.account_input && this.account_input.current;
                var password_input = this.password_input;

                if (!account_input || !password_input) {
                    this.forceUpdate();
                }
                if (accountName && password_input) {
                    password_input.input.focus();
                    this.setState({ focusedOnce: true });
                } else if (account_input && account_input.input && typeof account_input.focus === "function") {
                    account_input.focus();
                    this.setState({ focusedOnce: true });
                }
            } else if (!focusedOnce && isModalVisible && !passwordLogin) {
                var _password_input = this.password_input2;
                if (!_password_input) {
                    this.forceUpdate();
                }
                if (_password_input) {
                    _password_input.input.focus();
                    this.setState({ focusedOnce: true });
                }
            }

            if (resolve) {
                if (isLocked) {
                    this.setState({
                        isModalVisible: true
                    });
                } else {
                    resolve();
                }
            } else {
                this.setState({
                    isModalVisible: false,
                    password: ""
                });
            }
        }
    }, {
        key: "render",
        value: function render() {
            var _this3 = this;

            var _props2 = this.props,
                backup = _props2.backup,
                passwordLogin = _props2.passwordLogin,
                modalId = _props2.modalId,
                currentWallet = _props2.currentWallet,
                walletNames = _props2.walletNames,
                walletLockTimeout = _props2.walletLockTimeout;
            var _state3 = this.state,
                walletSelected = _state3.walletSelected,
                restoringBackup = _state3.restoringBackup,
                passwordError = _state3.passwordError,
                customError = _state3.customError,
                accountName = _state3.accountName,
                stopAskingForBackup = _state3.stopAskingForBackup,
                isOpen = _state3.isOpen;


            var noWalletNames = !(walletNames.size > 0);
            var noLocalWallet = noWalletNames && !walletSelected;
            var walletDisplayName = backup.name || currentWallet;
            var errorMessage = passwordError ? counterpart.translate("wallet.pass_incorrect") : customError;
            // Modal overlayClose must be false pending a fix that allows us to detect
            // this event and clear the password (via this.refs.password_input.clear())
            // https://github.com/akiran/react-foundation-apps/issues/34

            var footer = [];
            if (passwordLogin) {
                footer.push(React.createElement(
                    Tooltip,
                    {
                        key: "wallet.remember_me_explanation",
                        title: counterpart.translate("wallet.remember_me_explanation")
                    },
                    React.createElement(
                        "div",
                        {
                            style: {
                                float: "left",
                                cursor: "pointer",
                                marginTop: "6px"
                            },
                            onClick: this.handleRememberMe.bind(this)
                        },
                        React.createElement(Translate, { content: "wallet.remember_me" }),
                        React.createElement(Switch, {
                            checked: this.state.rememberMe,
                            onChange: this.handleRememberMe.bind(this)
                        })
                    )
                ));
                footer.push(React.createElement(
                    "div",
                    {
                        style: {
                            float: "left"
                        },
                        key: "settings.walletLockTimeoutTooltip"
                    },
                    React.createElement(
                        "span",
                        null,
                        React.createElement(
                            Tooltip,
                            {
                                title: counterpart.translate("settings.walletLockTimeoutTooltip")
                            },
                            React.createElement(
                                "span",
                                null,
                                React.createElement(Icon, {
                                    onClick: function onClick() {
                                        _this3.setState({
                                            isAutoLockVisible: !_this3.state.isAutoLockVisible
                                        });
                                    },
                                    name: "autolock",
                                    size: "1_5x",
                                    style: {
                                        cursor: "pointer",
                                        top: "5px",
                                        position: "relative",
                                        marginLeft: "12px"
                                    }
                                })
                            )
                        ),
                        this.state.isAutoLockVisible && React.createElement(
                            Tooltip,
                            {
                                title: counterpart.translate("settings.walletLockTimeout")
                            },
                            React.createElement(InputNumber, {
                                value: walletLockTimeout,
                                onChange: this.handleWalletAutoLock,
                                placeholder: "Auto-lock after...",
                                style: {
                                    marginLeft: "7px",
                                    width: "65px"
                                }
                            })
                        )
                    )
                ));
            }
            footer.push(React.createElement(
                "span",
                { className: "auto-lock-wrapper", key: "wallet.backup_login" },
                React.createElement(
                    Button,
                    { onClick: this.handleLogin, key: "login-btn" },
                    counterpart.translate(this.shouldUseBackupLogin() ? "wallet.backup_login" : "header.unlock_short")
                )
            ));

            return (
                // U N L O C K
                React.createElement(
                    Modal,
                    {
                        title: "Login",
                        visible: this.state.isModalVisible,
                        wrapClassName: "unlock_wallet_modal2",
                        id: modalId,
                        closeable: false,
                        ref: "modal",
                        overlay: true,
                        overlayClose: false,
                        modalHeader: "header.unlock_short",
                        onCancel: this.handleModalClose,
                        leftHeader: true,
                        footer: footer,
                        zIndex: 1001 // always on top
                    },
                    React.createElement(
                        Form,
                        { className: "full-width", layout: "vertical" },
                        React.createElement(LoginTypeSelector, null),
                        passwordLogin ? React.createElement(
                            "div",
                            null,
                            React.createElement(DisableChromeAutocomplete, null),
                            React.createElement(AccountSelector, {
                                label: "account.name",
                                inputRef: this.account_input // needed for ref forwarding to Input
                                , accountName: accountName,
                                account: accountName,
                                onChange: this.handleAccountNameChange,
                                onAccountChanged: function onAccountChanged() {},
                                size: 60,
                                hideImage: true,
                                placeholder: " ",
                                useHR: true,
                                labelClass: "login-label",
                                reserveErrorSpace: true
                            }),
                            React.createElement(
                                Form.Item,
                                {
                                    label: counterpart.translate("settings.password"),
                                    validateStatus: passwordError ? "error" : "",
                                    help: passwordError || ""
                                },
                                React.createElement(Input, {
                                    type: "password",
                                    value: this.state.password,
                                    onChange: this.handlePasswordChange,
                                    onPressEnter: this.handleLogin,
                                    ref: function ref(input) {
                                        _this3.password_input = input;
                                    }
                                })
                            )
                        ) : React.createElement(
                            "div",
                            null,
                            React.createElement(
                                "div",
                                {
                                    className: "key-file-selector " + (restoringBackup && !walletSelected ? "restoring" : "")
                                },
                                React.createElement(KeyFileLabel, {
                                    showUseOtherWalletLink: restoringBackup && !backup.name,
                                    onUseOtherWallet: this.handleUseOtherWallet
                                }),
                                React.createElement("hr", null),
                                walletSelected ? React.createElement(WalletDisplay, {
                                    name: walletDisplayName,
                                    onUseOtherWallet: this.handleUseOtherWallet
                                }) : React.createElement(
                                    "div",
                                    null,
                                    restoringBackup || noWalletNames ? React.createElement(BackupFileSelector, {
                                        onFileChosen: this.loadBackup,
                                        onRestoreOther: this.handleRestoreOther
                                    }) : React.createElement(WalletSelector, {
                                        onFileChosen: this.loadBackup,
                                        restoringBackup: restoringBackup,
                                        walletNames: walletNames,
                                        onWalletChange: this.handleSelectedWalletChange
                                    }),
                                    noLocalWallet && React.createElement(CreateLocalWalletLink, {
                                        onCreate: this.handleCreateWallet
                                    })
                                )
                            ),
                            React.createElement(
                                Form.Item,
                                {
                                    label: counterpart.translate("wallet.enter_password"),
                                    validateStatus: errorMessage ? "error" : "success",
                                    help: errorMessage
                                },
                                React.createElement(Input, {
                                    type: "password",
                                    value: this.state.password,
                                    placeholder: counterpart.translate("wallet.enter_password"),
                                    onChange: this.handlePasswordChange,
                                    onPressEnter: this.handleLogin,
                                    ref: function ref(input) {
                                        _this3.password_input2 = input;
                                    }
                                })
                            )
                        ),
                        this.shouldShowBackupWarning() && React.createElement(BackupWarning, {
                            onChange: this.handleAskForBackupChange,
                            checked: stopAskingForBackup
                        })
                    )
                )
            );
        }
    }]);

    return WalletUnlockModal;
}(React.Component);

var _initialiseProps = function _initialiseProps() {
    var _this5 = this;

    this.initialState = function () {
        var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : _this5.props;
        var passwordAccount = props.passwordAccount,
            currentWallet = props.currentWallet;

        return {
            isModalVisible: false,
            passwordError: null,
            accountName: passwordAccount,
            walletSelected: !!currentWallet,
            customError: null,
            isOpen: false,
            restoringBackup: false,
            stopAskingForBackup: false,
            rememberMe: WalletUnlockStore.getState().rememberMe,
            focusedOnce: false,
            isAutoLockVisible: false
        };
    };

    this.handleModalClose = function () {
        WalletUnlockActions.cancel();
        BackupActions.reset();
        _this5.setState(_this5.initialState());
    };

    this.handleModalOpen = function () {
        BackupActions.reset();
        _this5.setState({ isOpen: true }, function () {
            var passwordLogin = _this5.props.passwordLogin;

            if (!passwordLogin) {
                var password_input = _this5.refs.password_input;

                if (password_input) {
                    password_input.clear();
                    password_input.focus();
                }

                var dbWallet = _this5.props.dbWallet;

                if (dbWallet && Apis.instance().chain_id !== dbWallet.chain_id) {
                    Notification.error({
                        message: counterpart.translate("notifications.wallet_unlock_different_block_chain", {
                            expectedWalletId: dbWallet.chain_id.substring(0, 4).toUpperCase(),
                            actualWalletId: Apis.instance().chain_id.substring(0, 4).toUpperCase()
                        })
                    });
                    WalletUnlockActions.cancel();
                }
            }
        });
    };

    this.validate = function (password, account) {
        var _props3 = _this5.props,
            passwordLogin = _props3.passwordLogin,
            resolve = _props3.resolve;
        var stopAskingForBackup = _this5.state.stopAskingForBackup;

        var _WalletDb$validatePas = WalletDb.validatePassword(password || "", true, //unlock
        account),
            cloudMode = _WalletDb$validatePas.cloudMode;

        if (WalletDb.isLocked()) {
            _this5.setState({ passwordError: true });
        } else {
            if (!passwordLogin) {
                _this5.setState({
                    password: ""
                });
            } else {
                _this5.setState({
                    password: ""
                });
                if (cloudMode) AccountActions.setPasswordAccount(account);
            }
            WalletUnlockActions.change();
            if (stopAskingForBackup) WalletActions.setBackupDate();else if (_this5.shouldUseBackupLogin()) _this5.backup();
            resolve();
            WalletUnlockActions.cancel();
        }
    };

    this.passwordInput = function () {
        return _this5.refs.password_input || _this5.refs.custom_password_input.refs.password_input;
    };

    this.restoreBackup = function (password, callback) {
        var backup = _this5.props.backup;

        var privateKey = PrivateKey.fromSeed(password || "");
        var walletName = backup.name.split(".")[0];
        restore(privateKey.toWif(), backup.contents, walletName).then(function () {
            return WalletActions.setWallet(walletName).then(function () {
                BackupActions.reset();
                callback();
            }).catch(function (e) {
                return _this5.setState({ customError: e.message });
            });
        }).catch(function (e) {
            var message = typeof e === "string" ? e : e.message;
            var invalidBackupPassword = message === "invalid_decryption_key";
            _this5.setState({
                customError: invalidBackupPassword ? null : message,
                passwordError: invalidBackupPassword
            });
        });
    };

    this.handleLogin = function (e) {
        if (e) e.preventDefault();

        var _props4 = _this5.props,
            passwordLogin = _props4.passwordLogin,
            backup = _props4.backup;
        var _state4 = _this5.state,
            walletSelected = _state4.walletSelected,
            accountName = _state4.accountName;


        if (!passwordLogin && !walletSelected) {
            _this5.setState({
                customError: counterpart.translate("wallet.ask_to_select_wallet")
            });
        } else {
            _this5.setState({ passwordError: null }, function () {
                var password = _this5.state.password;
                if (!passwordLogin && backup.name) {
                    _this5.restoreBackup(password, function () {
                        return _this5.validate(password);
                    });
                } else {
                    if (!_this5.state.rememberMe) {
                        if (isPersistantType()) {
                            setLocalStorageType("inram");
                        }
                    } else {
                        if (!isPersistantType()) {
                            setLocalStorageType("persistant");
                        }
                    }
                    var account = passwordLogin ? accountName : null;
                    _this5.validate(password, account);
                }
            });
        }
    };

    this.closeRedirect = function (path) {
        WalletUnlockActions.cancel();
        _this5.props.history.push(path);
    };

    this.handleCreateWallet = function () {
        return _this5.closeRedirect("/create-account/wallet");
    };

    this.handleRestoreOther = function () {
        return _this5.closeRedirect("/settings/restore");
    };

    this.loadBackup = function (e) {
        var fullPath = e.target.value;
        var file = e.target.files[0];

        _this5.setState({ restoringBackup: true }, function () {
            var startIndex = fullPath.indexOf("\\") >= 0 ? fullPath.lastIndexOf("\\") : fullPath.lastIndexOf("/");
            var filename = fullPath.substring(startIndex);
            if (filename.indexOf("\\") === 0 || filename.indexOf("/") === 0) {
                filename = filename.substring(1);
            }
            BackupActions.incommingWebFile(file);
            _this5.setState({
                walletSelected: true
            });
        });
    };

    this.handleSelectedWalletChange = function (e) {
        var value = e.target.value;

        var selectionType = value.split(".")[0];
        var walletName = value.substring(value.indexOf(".") + 1);

        BackupActions.reset();
        if (selectionType === "upload") _this5.setState({
            restoringBackup: true,
            customError: null
        });else WalletActions.setWallet(walletName).then(function () {
            return _this5.setState({
                walletSelected: true,
                customError: null,
                restoringBackup: false
            });
        });
    };

    this.backup = function () {
        return backup(_this5.props.dbWallet.password_pubkey).then(function (contents) {
            var currentWallet = _this5.props.currentWallet;

            var name = backupName(currentWallet);
            BackupActions.incommingBuffer({ name: name, contents: contents });

            var backup = _this5.props.backup;

            var blob = new Blob([backup.contents], {
                type: "application/octet-stream; charset=us-ascii"
            });
            if (blob.size !== backup.size) throw new Error("Invalid backup to download conversion");
            saveAs(blob, name);
            WalletActions.setBackupDate();
            BackupActions.reset();
        });
    };

    this.handleAskForBackupChange = function (e) {
        return _this5.setState({ stopAskingForBackup: e.target.checked });
    };

    this.handleUseOtherWallet = function () {
        _this5.setState({
            walletSelected: false,
            restoringBackup: false,
            passwordError: null,
            customError: null
        });
    };

    this.handleAccountNameChange = function (accountName) {
        return _this5.setState({ accountName: accountName, error: null });
    };

    this.shouldShowBackupWarning = function () {
        return !_this5.props.passwordLogin && _this5.state.walletSelected && !_this5.state.restoringBackup && !(!!_this5.props.dbWallet && !!_this5.props.dbWallet.backup_date);
    };

    this.shouldUseBackupLogin = function () {
        return _this5.shouldShowBackupWarning() && !_this5.state.stopAskingForBackup;
    };

    this.handleRememberMe = function () {
        var newRememberMe = !_this5.state.rememberMe;
        _this5.setState({ rememberMe: newRememberMe });
        SettingsActions.changeSetting({
            setting: "rememberMe",
            value: newRememberMe
        });
    };

    this.handleWalletAutoLock = function (val) {
        var newValue = parseInt(val, 10);
        if (isNaN(newValue)) newValue = 0;
        if (!isNaN(newValue) && typeof newValue === "number") {
            SettingsActions.changeSetting({
                setting: "walletLockTimeout",
                value: newValue
            });
        }
    };
};

WalletUnlockModal.defaultProps = {
    modalId: "unlock_wallet_modal2"
};

WalletUnlockModal = withRouter(WalletUnlockModal);

var WalletUnlockModalContainer = function (_React$Component2) {
    _inherits(WalletUnlockModalContainer, _React$Component2);

    function WalletUnlockModalContainer() {
        _classCallCheck(this, WalletUnlockModalContainer);

        return _possibleConstructorReturn(this, (WalletUnlockModalContainer.__proto__ || Object.getPrototypeOf(WalletUnlockModalContainer)).apply(this, arguments));
    }

    _createClass(WalletUnlockModalContainer, [{
        key: "render",
        value: function render() {
            return React.createElement(
                AltContainer,
                {
                    stores: [WalletUnlockStore, AccountStore, WalletManagerStore, WalletDb, BackupStore, SettingsStore],
                    inject: {
                        currentWallet: function currentWallet() {
                            return WalletManagerStore.getState().current_wallet;
                        },
                        walletNames: function walletNames() {
                            return WalletManagerStore.getState().wallet_names;
                        },
                        dbWallet: function dbWallet() {
                            return WalletDb.getWallet();
                        },
                        isLocked: function isLocked() {
                            return WalletDb.isLocked();
                        },
                        backup: function backup() {
                            return BackupStore.getState();
                        },
                        resolve: function resolve() {
                            return WalletUnlockStore.getState().resolve;
                        },
                        reject: function reject() {
                            return WalletUnlockStore.getState().reject;
                        },
                        locked: function locked() {
                            return WalletUnlockStore.getState().locked;
                        },
                        passwordLogin: function passwordLogin() {
                            return WalletUnlockStore.getState().passwordLogin;
                        },
                        passwordAccount: function passwordAccount() {
                            return AccountStore.getState().passwordAccount || "";
                        },
                        walletLockTimeout: function walletLockTimeout() {
                            return SettingsStore.getState().settings.get("walletLockTimeout");
                        }
                    }
                },
                React.createElement(WalletUnlockModal, this.props)
            );
        }
    }]);

    return WalletUnlockModalContainer;
}(React.Component);

export default WalletUnlockModalContainer;