var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from "react";
import counterpart from "counterpart";
import utils from "common/utils";
import LinkToAccountById from "../Utility/LinkToAccountById";
import LinkToAssetById from "../Utility/LinkToAssetById";
import { Link } from "react-router-dom";
import FormattedAsset from "../Utility/FormattedAsset";
import FormattedPrice from "../Utility/FormattedPrice";
import AssetName from "../Utility/AssetName";
import Translate from "react-translate-component";
import Icon from "../Icon/Icon";

/**
 *  Given a string and a list of interpolation parameters, this component
 *  will translate that string and replace the following:
 *
 *  account ids/names with links to accounts
 *  asset ids/names with links to assets
 *  amounts with fully formatted amounts with asset symbol
 *  prices with fully formatted prices with symbols
 *
 *  Expected Properties:
 *     string:  translation string key. Objects to interpolate should be wrapped in curly brackets: {amount}
 *     keys: array of objects to interpolate in the string.
 *         lookup goes by arg, which should match the name given inside the curly brackets in the translation string
 *         example:
 *         [{
 *             type: "account"|"amount"|"asset"|"price",
 *             value: "1.2.1"|{amount: 10, asset_id: "1.3.0"}|"1.3.1"|{base: {amount: 1, asset_id: "1.3.0"}, quote: {amount: 100, asset_id: "1.3.20"}}},
 *             arg: "account"|"amount"|"asset"|"price",
 *             decimalOffset: 1 (optional, only used for amounts)
 *         }
 *         ]
 *     params: object contaning simple strings to be interpolated using standard counterpart syntax: %(string)s
 *
 */

var TranslateWithLinks = function (_React$Component) {
    _inherits(TranslateWithLinks, _React$Component);

    function TranslateWithLinks() {
        _classCallCheck(this, TranslateWithLinks);

        return _possibleConstructorReturn(this, (TranslateWithLinks.__proto__ || Object.getPrototypeOf(TranslateWithLinks)).apply(this, arguments));
    }

    _createClass(TranslateWithLinks, [{
        key: "shouldComponentUpdate",
        value: function shouldComponentUpdate(nextProps) {
            return !utils.are_equal_shallow(nextProps.keys, this.props.keys);
        }
    }, {
        key: "linkToAccount",
        value: function linkToAccount(name_or_id) {
            var noLink = this.props.noLink;

            if (!name_or_id) return React.createElement(
                "span",
                null,
                "-"
            );
            return utils.is_object_id(name_or_id) ? React.createElement(LinkToAccountById, { account: name_or_id, noLink: noLink }) : noLink ? React.createElement(
                "span",
                null,
                name_or_id
            ) : React.createElement(
                Link,
                { to: "/account/" + name_or_id + "/overview" },
                name_or_id
            );
        }
    }, {
        key: "linkToAsset",
        value: function linkToAsset(symbol_or_id) {
            var _props = this.props,
                noLink = _props.noLink,
                noTip = _props.noTip;

            if (!symbol_or_id) return React.createElement(
                "span",
                null,
                "-"
            );
            return utils.is_object_id(symbol_or_id) ? React.createElement(LinkToAssetById, { asset: symbol_or_id, noLink: noLink }) : noLink ? React.createElement(AssetName, { name: symbol_or_id, dataPlace: "top", noTip: noTip }) : React.createElement(
                Link,
                { to: "/asset/" + symbol_or_id },
                React.createElement(AssetName, { name: symbol_or_id, dataPlace: "top", noTip: noTip })
            );
        }
    }, {
        key: "render",
        value: function render() {
            var _this2 = this;

            var _props2 = this.props,
                string = _props2.string,
                params = _props2.params,
                keys = _props2.keys;


            var text = counterpart.translate(string, params);
            var splitText = utils.get_translation_parts(text);

            keys.forEach(function (key) {
                if (splitText.indexOf(key.arg)) {
                    var value = void 0;
                    switch (key.type) {
                        case "account":
                            value = _this2.linkToAccount(key.value);
                            break;

                        case "amount":
                            value = React.createElement(
                                "span",
                                null,
                                React.createElement(FormattedAsset, {
                                    amount: key.value.amount,
                                    asset: key.value.asset_id,
                                    decimalOffset: key.decimalOffset,
                                    hide_asset: true
                                }),
                                "\xA0",
                                _this2.linkToAsset(key.value.asset_id)
                            );

                            break;

                        case "price":
                            value = React.createElement(FormattedPrice, {
                                base_asset: key.value.base.asset_id,
                                base_amount: key.value.base.amount,
                                quote_asset: key.value.quote.asset_id,
                                quote_amount: key.value.quote.amount
                            });
                            break;

                        case "asset":
                            value = _this2.linkToAsset(key.value);
                            break;

                        case "translate":
                            value = React.createElement(Translate, { content: key.value });
                            break;

                        case "link":
                            value = React.createElement(
                                Link,
                                {
                                    to: key.value,
                                    "data-intro": key.dataIntro ? key.dataIntro : null
                                },
                                React.createElement(Translate, { content: key.translation })
                            );
                            break;

                        case "icon":
                            var title = name.replace("-", "_");
                            value = React.createElement(Icon, {
                                className: key.className,
                                name: key.value,
                                title: title
                            });
                            break;

                        case "change":
                            if (key.value && Object.keys(key.value).length > 0) {
                                var _key$value = key.value,
                                    votes = _key$value.votes,
                                    active = _key$value.active,
                                    owner = _key$value.owner,
                                    memo = _key$value.memo;

                                var voteDiv = votes && React.createElement(
                                    "div",
                                    null,
                                    React.createElement(Translate, { content: "proposal.votes" }),
                                    votes.minus.length ? React.createElement(
                                        "div",
                                        null,
                                        "- " + counterpart.translate("proposal.remove") + " ",
                                        " ",
                                        votes.minus.join(", ")
                                    ) : null,
                                    votes.plus.length ? React.createElement(
                                        "div",
                                        null,
                                        "- " + counterpart.translate("proposal.add") + " ",
                                        " ",
                                        votes.plus.join(", ")
                                    ) : null
                                );
                                var warning = (active || owner || memo) && React.createElement(
                                    "div",
                                    null,
                                    React.createElement(Translate, {
                                        content: "proposal.permission_changes"
                                    }),
                                    ", ",
                                    React.createElement(Translate, {
                                        style: { color: "red" },
                                        content: "proposal.danger_operation"
                                    }),
                                    "!"
                                );
                                var activeDiv = active && React.createElement(
                                    React.Fragment,
                                    null,
                                    React.createElement(Translate, {
                                        content: "proposal.changes_to_active"
                                    }),
                                    React.createElement(
                                        "div",
                                        { style: { marginLeft: "0.5rem" } },
                                        active.keys.plus.length > 0 || active.accounts.plus.length > 0 && React.createElement(
                                            "div",
                                            null,
                                            "- " + counterpart.translate("proposal.add") + " ",
                                            active.keys.plus.join(", "),
                                            " ",
                                            active.accounts.plus.map(function (_tmp) {
                                                return React.createElement(
                                                    "span",
                                                    { key: _tmp },
                                                    _this2.linkToAccount(_tmp)
                                                );
                                            })
                                        ),
                                        active.keys.minus.length > 0 || active.accounts.minus.length > 0 && React.createElement(
                                            "div",
                                            null,
                                            "- " + counterpart.translate("proposal.remove") + " ",
                                            active.keys.minus.join(", "),
                                            " ",
                                            active.accounts.minus.map(function (_tmp) {
                                                return React.createElement(
                                                    "span",
                                                    { key: _tmp },
                                                    _this2.linkToAccount(_tmp)
                                                );
                                            })
                                        ),
                                        active.weight_threshold && React.createElement(
                                            "div",
                                            null,
                                            "- " + counterpart.translate("proposal.set_threshold", {
                                                threshold: active.weight_threshold
                                            })
                                        )
                                    )
                                );
                                var ownerDiv = owner && React.createElement(
                                    React.Fragment,
                                    null,
                                    React.createElement(Translate, {
                                        content: "proposal.changes_to_owner"
                                    }),
                                    React.createElement(
                                        "div",
                                        { style: { marginLeft: "0.5rem" } },
                                        owner.keys.plus.length > 0 || owner.accounts.plus.length > 0 && React.createElement(
                                            "div",
                                            null,
                                            "- " + counterpart.translate("proposal.add") + " ",
                                            owner.keys.plus.join(", "),
                                            " ",
                                            owner.accounts.plus.map(function (_tmp) {
                                                return React.createElement(
                                                    "span",
                                                    { key: _tmp },
                                                    _this2.linkToAccount(_tmp)
                                                );
                                            })
                                        ),
                                        owner.keys.minus.length > 0 || owner.accounts.minus.length > 0 && React.createElement(
                                            "div",
                                            null,
                                            "- " + counterpart.translate("proposal.remove") + " ",
                                            owner.keys.minus.join(", "),
                                            " ",
                                            owner.accounts.minus.map(function (_tmp) {
                                                return React.createElement(
                                                    "span",
                                                    { key: _tmp },
                                                    _this2.linkToAccount(_tmp)
                                                );
                                            })
                                        ),
                                        owner.weight_threshold && React.createElement(
                                            "div",
                                            null,
                                            "- " + counterpart.translate("proposal.set_threshold", {
                                                threshold: owner.weight_threshold
                                            })
                                        )
                                    )
                                );
                                var memoDiv = memo && (memo.keys.plus.length > 0 || memo.keys.minus.length > 0) && React.createElement(
                                    "div",
                                    null,
                                    React.createElement(Translate, {
                                        content: "proposal.changes_to_memo"
                                    }),
                                    memo.keys.plus.length > 0 && React.createElement(
                                        "div",
                                        null,
                                        " ",
                                        "+ ",
                                        memo.keys.plus.join(", ")
                                    ),
                                    memo.keys.minus.length > 0 && React.createElement(
                                        "div",
                                        null,
                                        " ",
                                        "- ",
                                        memo.keys.minus.join(", ")
                                    )
                                );
                                value = React.createElement(
                                    "div",
                                    {
                                        style: {
                                            marginLeft: "0.5rem",
                                            marginTop: "0.5rem"
                                        }
                                    },
                                    warning,
                                    voteDiv,
                                    activeDiv,
                                    ownerDiv,
                                    memoDiv
                                );
                            } else {
                                value = "";
                            }
                            break;
                        case "date":
                            if (key.value === null) {
                                value = "-";
                            } else {
                                value = counterpart.localize(key.value, {
                                    type: "date",
                                    format: "full"
                                });
                            }
                            break;

                        default:
                            value = key.value;
                            break;
                    }

                    splitText[splitText.indexOf(key.arg)] = value;
                }
            });

            var finalText = splitText.map(function (text, index) {
                return React.createElement(
                    "span",
                    { key: index },
                    text
                );
            });

            return React.createElement(
                "span",
                null,
                finalText
            );
        }
    }]);

    return TranslateWithLinks;
}(React.Component);

export default TranslateWithLinks;