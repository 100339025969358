var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from "react";
import PropTypes from "prop-types";
import Translate from "react-translate-component";
import WalletBlockSelection from "./WalletBlockSelection";
import WalletHeaderSelection from "./WalletHeaderSelection";
import AccountBlockSelection from "./AccountBlockSelection";
import AccountHeaderSelection from "./AccountHeaderSelection";

var RegistrationSelector = function (_React$Component) {
    _inherits(RegistrationSelector, _React$Component);

    function RegistrationSelector() {
        _classCallCheck(this, RegistrationSelector);

        var _this = _possibleConstructorReturn(this, (RegistrationSelector.__proto__ || Object.getPrototypeOf(RegistrationSelector)).call(this));

        _this.state = {
            activeWalletModel: true
        };
        return _this;
    }

    _createClass(RegistrationSelector, [{
        key: "onSelect",
        value: function onSelect(route) {
            this.props.history.push("/registration/" + route);
        }
    }, {
        key: "changeActiveModel",
        value: function changeActiveModel(isActiveWallet) {
            this.setState({ activeWalletModel: isActiveWallet });
        }
    }, {
        key: "renderHeader",
        value: function renderHeader(isWalletSection) {
            var _this2 = this;

            var activeWalletModel = this.state.activeWalletModel;

            return React.createElement(
                "div",
                { className: "small-horizontal small-only-block" },
                React.createElement(WalletHeaderSelection, {
                    active: activeWalletModel,
                    onChangeActive: function onChangeActive() {
                        return _this2.changeActiveModel(true);
                    },
                    forSmall: !isWalletSection
                }),
                React.createElement(AccountHeaderSelection, {
                    active: !activeWalletModel,
                    onChangeActive: function onChangeActive() {
                        return _this2.changeActiveModel(false);
                    },
                    forSmall: isWalletSection
                })
            );
        }
    }, {
        key: "render",
        value: function render() {
            var _this3 = this;

            if (this.props.children) {
                return this.props.children;
            }

            var activeWalletModel = this.state.activeWalletModel;

            return React.createElement(
                "div",
                { className: "grid-block align-center registration-layout" },
                React.createElement(
                    "div",
                    { className: "grid-block shrink vertical text-center registration-selector" },
                    React.createElement(Translate, {
                        content: "registration.title",
                        component: "p",
                        className: "registration-title"
                    }),
                    React.createElement(
                        "div",
                        { className: "registration-container" },
                        React.createElement(
                            "div",
                            { className: "v-align" },
                            React.createElement(
                                "div",
                                {
                                    className: (!activeWalletModel ? "inactive-model-block" : "") + " selection-block align-center plate"
                                },
                                this.renderHeader(true),
                                React.createElement(WalletBlockSelection, {
                                    onSelect: function onSelect() {
                                        return _this3.onSelect("local");
                                    },
                                    active: activeWalletModel,
                                    onChangeActive: function onChangeActive() {
                                        return _this3.changeActiveModel(true);
                                    }
                                })
                            ),
                            React.createElement(
                                "div",
                                {
                                    className: (activeWalletModel ? "inactive-model-block" : "") + " selection-block align-center plate"
                                },
                                this.renderHeader(false),
                                React.createElement(AccountBlockSelection, {
                                    onSelect: function onSelect() {
                                        return _this3.onSelect("cloud");
                                    },
                                    active: !activeWalletModel,
                                    onChangeActive: function onChangeActive() {
                                        return _this3.changeActiveModel(false);
                                    }
                                })
                            )
                        )
                    )
                )
            );
        }
    }]);

    return RegistrationSelector;
}(React.Component);

RegistrationSelector.propTypes = {
    children: PropTypes.element
};
RegistrationSelector.contextTypes = {
    router: PropTypes.object.isRequired
};
RegistrationSelector.defaultProps = {
    children: null
};
export default RegistrationSelector;