import React from "react";
import TranslateWithLinks from "../../Utility/TranslateWithLinks";

export var AssetCreate = function AssetCreate(_ref) {
    var op = _ref.op,
        changeColor = _ref.changeColor,
        fromComponent = _ref.fromComponent;

    changeColor("warning");
    if (fromComponent === "proposed_operation") {
        return React.createElement(TranslateWithLinks, {
            string: "proposal.asset_create",
            keys: [{
                type: "account",
                value: op[1].issuer,
                arg: "account"
            }],
            params: {
                asset: op[1].symbol
            }
        });
    } else {
        return React.createElement(
            "span",
            null,
            React.createElement(TranslateWithLinks, {
                string: "operation.asset_create",
                keys: [{
                    type: "account",
                    value: op[1].issuer,
                    arg: "account"
                }, {
                    type: "asset",
                    value: op[1].symbol,
                    arg: "asset"
                }]
            })
        );
    }
};