import React from "react";
import TranslateWithLinks from "../../Utility/TranslateWithLinks";

export var AssetUpdateIssuer = function AssetUpdateIssuer(_ref) {
    var op = _ref.op;

    return React.createElement(TranslateWithLinks, {
        string: "operation.asset_update_issuer",
        keys: [{
            type: "account",
            value: op[1].issuer,
            arg: "from_account"
        }, {
            type: "account",
            value: op[1].new_issuer,
            arg: "to_account"
        }, {
            type: "asset",
            value: op[1].asset_to_update,
            arg: "asset"
        }]
    });
};