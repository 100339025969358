import React from "react";
import Translate from "react-translate-component";

export var ShortOrderCancel = function ShortOrderCancel(_ref) {
    var op = _ref.op,
        changeColor = _ref.changeColor;

    changeColor("cancel");

    return React.createElement(
        "span",
        null,
        React.createElement(Translate, { component: "span", content: "proposal.short_order_cancel" }),
        "\xA0",
        op[1].order
    );
};