var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from "react";
import Translate from "react-translate-component";
import counterpart from "counterpart";
import { getWalletName } from "branding";
import { Alert, Checkbox, Tooltip } from "bitshares-ui-style-guide";

/* Dummy input to trick Chrome into disabling auto-complete */
export var DisableChromeAutocomplete = function DisableChromeAutocomplete() {
    return React.createElement("input", {
        type: "text",
        className: "no-padding no-margin",
        style: { visibility: "hidden", height: 0 }
    });
};

var stopPropagation = function stopPropagation(e) {
    return e.stopPropagation();
};

export var KeyFileLabel = function KeyFileLabel(_ref) {
    var showUseOtherWalletLink = _ref.showUseOtherWalletLink,
        onUseOtherWallet = _ref.onUseOtherWallet;
    return React.createElement(
        "div",
        { className: "label-container" },
        React.createElement(
            "label",
            { className: "left-label login-label" },
            React.createElement(Translate, { content: "wallet.key_file_bin" }),
            " "
        ),
        " ",
        showUseOtherWalletLink && React.createElement(
            "a",
            { onClick: onUseOtherWallet },
            "(",
            React.createElement(Translate, { content: "wallet.use_different" }),
            ")"
        )
    );
};

export var StyledUpload = function (_React$Component) {
    _inherits(StyledUpload, _React$Component);

    function StyledUpload() {
        _classCallCheck(this, StyledUpload);

        return _possibleConstructorReturn(this, (StyledUpload.__proto__ || Object.getPrototypeOf(StyledUpload)).apply(this, arguments));
    }

    _createClass(StyledUpload, [{
        key: "render",
        value: function render() {
            return React.createElement(
                "label",
                { className: "upload-button themed-input" },
                React.createElement(Translate, { content: "wallet.restore_key_file" }),
                React.createElement(UploadButtonLogo, null),
                React.createElement("input", {
                    type: "file",
                    onClick: stopPropagation,
                    onChange: this.props.onFileChosen,
                    accept: ".bin"
                })
            );
        }
    }]);

    return StyledUpload;
}(React.Component);

export var CustomError = function CustomError(_ref2) {
    var message = _ref2.message;
    return React.createElement(
        "div",
        { className: "has-error" },
        message || ""
    );
};

export var BackupFileSelector = function BackupFileSelector(_ref3) {
    var onFileChosen = _ref3.onFileChosen,
        onRestoreOther = _ref3.onRestoreOther;
    return React.createElement(
        "div",
        null,
        React.createElement(StyledUpload, { onFileChosen: onFileChosen }),
        React.createElement(
            "div",
            { className: "login-hint" },
            React.createElement(Translate, { content: "wallet.different_file_type" }),
            " ",
            React.createElement(
                "a",
                { onClick: onRestoreOther },
                React.createElement(Translate, { content: "wallet.restore_it_here" })
            )
        )
    );
};

export var RestoreBackupOnly = function RestoreBackupOnly(_ref4) {
    var onFileChosen = _ref4.onFileChosen,
        onRestoreOther = _ref4.onRestoreOther;
    return React.createElement(BackupFileSelector, {
        onFileChosen: onFileChosen,
        onRestoreOther: onRestoreOther
    });
};

export var BackupWarning = function BackupWarning(_ref5) {
    var onChange = _ref5.onChange,
        checked = _ref5.checked;
    return React.createElement(
        "div",
        { className: "backup-warning" },
        React.createElement(Alert, {
            type: "warning",
            message: counterpart.translate("alert.warning"),
            description: counterpart.translate("wallet.backup_warning")
        }),
        React.createElement(
            "div",
            { className: "checkbox" },
            React.createElement(
                Checkbox,
                {
                    key: "checkbox_" + checked // This is needed to prevent slow checkbox reaction
                    , onChange: onChange,
                    checked: checked
                },
                React.createElement(Translate, { content: "wallet.dont_ask_for_backup" })
            )
        )
    );
};

export var LoginButtons = function LoginButtons(_ref6) {
    var onLogin = _ref6.onLogin,
        backupLogin = _ref6.backupLogin;
    return React.createElement(
        Tooltip,
        {
            placement: "bottom",
            title: counterpart.translate("tooltip.login", {
                wallet_name: getWalletName()
            })
        },
        React.createElement(
            "button",
            { className: "button", onClick: onLogin },
            React.createElement(Translate, {
                content: backupLogin ? "wallet.backup_login" : "header.unlock_short"
            })
        )
    );
};

export var CustomPasswordInput = function (_React$Component2) {
    _inherits(CustomPasswordInput, _React$Component2);

    function CustomPasswordInput() {
        var _ref7;

        var _temp, _this2, _ret;

        _classCallCheck(this, CustomPasswordInput);

        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
        }

        return _ret = (_temp = (_this2 = _possibleConstructorReturn(this, (_ref7 = CustomPasswordInput.__proto__ || Object.getPrototypeOf(CustomPasswordInput)).call.apply(_ref7, [this].concat(args))), _this2), _this2.render = function () {
            return React.createElement(
                "div",
                { className: "content-block account-selector input-area" },
                React.createElement(
                    "label",
                    { className: "left-label login-label" },
                    React.createElement(Translate, { content: "settings.password" })
                ),
                React.createElement("input", {
                    ref: "password_input",
                    name: "password",
                    id: "password",
                    type: "password",
                    autoComplete: "current-password"
                })
            );
        }, _temp), _possibleConstructorReturn(_this2, _ret);
    }

    return CustomPasswordInput;
}(React.Component);

var UploadButtonLogo = function UploadButtonLogo() {
    return React.createElement(
        "svg",
        {
            viewBox: "0 0 6.349999 7.5313201",
            version: "1.1",
            className: "upload-button-logo"
        },
        React.createElement(
            "g",
            { transform: "translate(-86.783338,-137.44666)" },
            React.createElement("path", { d: "m 89.958337,144.97798 h -3.174999 v -1.18208 -1.18208 l 0.387288,-1.11098 0.387288,-1.11097 h 0.847434 0.847434 v 0.31163 0.31163 l -0.65212,0.17054 -0.652119,0.17053 -0.196798,0.75256 -0.196798,0.75255 h 2.40339 2.403391 l -0.196798,-0.75255 -0.196798,-0.75256 -0.652119,-0.17053 -0.65212,-0.17054 v -0.31163 -0.31163 h 0.847434 0.847434 l 0.387288,1.11097 0.387288,1.11098 v 1.18208 1.18208 z m 0,-3.175 H 89.60556 v -1.2017 -1.20169 l -0.705556,0.1845 -0.705555,0.18451 v -0.33243 -0.33243 l 0.881944,-0.82854 0.881944,-0.82854 0.881945,0.82854 0.881944,0.82854 v 0.33243 0.33243 l -0.705555,-0.18451 -0.705556,-0.1845 v 1.20169 1.2017 z" })
        )
    );
};

export var WalletDisplay = function WalletDisplay(_ref8) {
    var name = _ref8.name,
        onUseOtherWallet = _ref8.onUseOtherWallet;
    return React.createElement(
        "div",
        { className: "content-box" },
        React.createElement(
            "b",
            null,
            React.createElement(Translate, { content: "wallet.using" })
        ),
        " ",
        name,
        " ",
        React.createElement(
            "a",
            { onClick: onUseOtherWallet },
            "(",
            React.createElement(Translate, { content: "wallet.use_different" }),
            ")"
        )
    );
};

export var CreateLocalWalletLink = function CreateLocalWalletLink(_ref9) {
    var onCreate = _ref9.onCreate;
    return React.createElement(
        "div",
        { className: "login-hint" },
        React.createElement(Translate, { content: "wallet.no_wallet", component: "span" }),
        " ",
        React.createElement(
            "span",
            { className: "button", onClick: onCreate },
            React.createElement(Translate, { content: "wallet.create_wallet" })
        )
    );
};

export var WalletSelector = function WalletSelector(_ref10) {
    var restoringBackup = _ref10.restoringBackup,
        walletNames = _ref10.walletNames,
        onWalletChange = _ref10.onWalletChange;
    return React.createElement(
        "select",
        {
            value: restoringBackup ? "upload." : "",
            onChange: onWalletChange,
            className: "login-select"
        },
        React.createElement(
            "option",
            { value: "", hidden: true },
            React.createElement(Translate, { content: "wallet.select_wallet" })
        ),
        walletNames.map(function (walletName) {
            return React.createElement(
                "option",
                {
                    className: "login-option",
                    key: walletName,
                    value: "wallet." + walletName
                },
                walletName
            );
        }),
        React.createElement(
            "option",
            { value: "upload." },
            React.createElement(Translate, { content: "settings.backup_backup_short" })
        )
    );
};