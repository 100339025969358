var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from "react";
import FormattedAsset from "./FormattedAsset";
import ChainTypes from "./ChainTypes";
import BindToChainState from "./BindToChainState";
import utils from "common/utils";
import marketUtils from "common/market_utils";
import { ChainStore } from "bitsharesjs";
import { connect } from "alt-react";
import MarketsStore from "stores/MarketsStore";
import SettingsStore from "stores/SettingsStore";
import { List } from "immutable";
import Translate from "react-translate-component";
import counterpart from "counterpart";
import MarketStatsCheck from "./MarketStatsCheck";
import AssetWrapper from "./AssetWrapper";
import ReactTooltip from "react-tooltip";
import PropTypes from "prop-types";
import { Tooltip } from "bitshares-ui-style-guide";

/**
 *  Given an asset amount, displays the equivalent value in baseAsset if possible
 *
 *  Expects three properties
 *  -'toAsset' which should be a asset id
 *  -'fromAsset' which is the asset id of the original asset amount
 *  -'amount' which is the amount to convert
 *  -'fullPrecision' boolean to tell if the amount uses the full precision of the asset
 */

var TotalValue = function (_MarketStatsCheck) {
    _inherits(TotalValue, _MarketStatsCheck);

    function TotalValue() {
        _classCallCheck(this, TotalValue);

        return _possibleConstructorReturn(this, (TotalValue.__proto__ || Object.getPrototypeOf(TotalValue)).call(this));
    }

    _createClass(TotalValue, [{
        key: "shouldComponentUpdate",
        value: function shouldComponentUpdate(np) {
            return _get(TotalValue.prototype.__proto__ || Object.getPrototypeOf(TotalValue.prototype), "shouldComponentUpdate", this).call(this, np) || !utils.are_equal_shallow(np.fromAssets, this.props.fromAssets) || np.toAsset !== this.props.toAsset || !utils.are_equal_shallow(np.balances, this.props.balances) || !utils.are_equal_shallow(np.openOrders, this.props.openOrders) || !utils.are_equal_shallow(np.collateral, this.props.collateral) || !utils.are_equal_shallow(np.debt, this.props.debt);
        }
    }, {
        key: "componentDidUpdate",
        value: function componentDidUpdate() {
            if (this.props.inHeader) {
                ReactTooltip.rebuild();
            }
        }
    }, {
        key: "_convertValue",
        value: function _convertValue(amount, fromAsset, toAsset, allMarketStats, coreAsset) {
            if (!fromAsset || !toAsset) {
                return 0;
            }

            return marketUtils.convertValue(amount, toAsset, fromAsset, allMarketStats, coreAsset);
        }
    }, {
        key: "_assetValues",
        value: function _assetValues(totals, amount, asset) {
            if (!totals[asset]) {
                totals[asset] = amount;
            } else {
                totals[asset] += amount;
            }

            return totals;
        }
    }, {
        key: "render",
        value: function render() {
            var _this2 = this;

            var _props = this.props,
                fromAssets = _props.fromAssets,
                toAsset = _props.toAsset,
                balances = _props.balances,
                allMarketStats = _props.allMarketStats,
                collateral = _props.collateral,
                debt = _props.debt,
                openOrders = _props.openOrders,
                inHeader = _props.inHeader;

            var coreAsset = ChainStore.getAsset("1.3.0");

            if (!coreAsset || !toAsset) {
                return null;
            }

            var assets = {};
            fromAssets.forEach(function (asset) {
                if (asset) {
                    assets[asset.get("id")] = asset;
                }
            });

            var totalValue = 0;
            var assetValues = {};

            // Collateral value
            for (var asset in collateral) {
                var fromAsset = assets[asset];
                if (fromAsset) {
                    var collateralValue = this._convertValue(collateral[asset], fromAsset, toAsset, allMarketStats, coreAsset);
                    totalValue += collateralValue;
                    assetValues = this._assetValues(assetValues, collateralValue, fromAsset.get("id"));
                }
            }

            // Open orders value
            for (var _asset in openOrders) {
                var _fromAsset = assets[_asset];
                if (_fromAsset) {
                    var orderValue = this._convertValue(openOrders[_asset], _fromAsset, toAsset, allMarketStats, coreAsset);
                    totalValue += orderValue;
                    assetValues = this._assetValues(assetValues, orderValue, _fromAsset.get("id"));
                }
            }

            // Debt value
            for (var _asset2 in debt) {
                var _fromAsset2 = assets[_asset2];
                if (_fromAsset2) {
                    var debtValue = this._convertValue(debt[_asset2], _fromAsset2, toAsset, allMarketStats, coreAsset);
                    totalValue -= debtValue;
                    assetValues = this._assetValues(assetValues, -debtValue, _fromAsset2.get("id"));
                }
            }

            // Balance value
            balances.forEach(function (balance) {
                var fromAsset = assets[balance.asset_id];
                if (fromAsset) {
                    var eqValue = fromAsset !== toAsset ? _this2._convertValue(balance.amount, fromAsset, toAsset, allMarketStats, coreAsset) : balance.amount;
                    totalValue += eqValue;
                    assetValues = _this2._assetValues(assetValues, eqValue, fromAsset.get("id"));
                }
            });

            // Determine if higher precision should be displayed
            var hiPrec = false;
            for (var _asset3 in assetValues) {
                if (assets[_asset3] && assetValues[_asset3]) {
                    if (Math.abs(utils.get_asset_amount(assetValues[_asset3], toAsset)) < 100) {
                        hiPrec = true;
                        break;
                    }
                }
            }

            // Render each asset's balance, noting if there are any values missing
            var noDataSymbol = "**";
            var minValue = 1e-12;
            var missingData = false;
            var totalsTip = "<table><tbody>";
            for (var _asset4 in assetValues) {
                if (assets[_asset4] && assetValues[_asset4]) {
                    var symbol = assets[_asset4].get("symbol");
                    var amount = utils.get_asset_amount(assetValues[_asset4], toAsset);
                    if (amount) {
                        if (amount < minValue && amount > -minValue) {
                            // really close to zero, but not zero, probably a result of incomplete data
                            amount = noDataSymbol;
                            missingData = true;
                        } else if (hiPrec) {
                            if (amount >= 0 && amount < 0.01) amount = "<0.01";else if (amount < 0 && amount > -0.01) amount = "-0.01<";else amount = utils.format_number(amount, 2);
                        } else {
                            if (amount >= 0 && amount < 1) amount = "<1";else if (amount < 0 && amount > -0.01) amount = "-1<";else amount = utils.format_number(amount, 0);
                        }
                    } else {
                        amount = noDataSymbol;
                        missingData = true;
                    }
                    totalsTip += "<tr><td>" + symbol + ":&nbsp;</td><td style=\"text-align: right;\">" + amount + " " + toAsset.get("symbol") + "</td></tr>";
                }
            }

            // If any values are missing, let the user know.
            if (missingData) totalsTip += "<tr><td>&nbsp;</td><td style=\"text-align: right;\">" + noDataSymbol + " no data</td></tr>";

            totalsTip += '<tr><td colSpan="2">&nbsp;</td></tr>';
            totalsTip += "<tr><td colSpan=\"2\">" + counterpart.translate("account.total_estimate") + "</td></tr>";
            totalsTip += "</tbody></table>";

            if (!inHeader) {
                return React.createElement(
                    "span",
                    null,
                    !!this.props.label ? React.createElement(
                        "span",
                        { className: "font-secondary" },
                        React.createElement(Translate, { content: this.props.label }),
                        ":",
                        " "
                    ) : null,
                    React.createElement(FormattedAsset, {
                        noTip: this.props.noTip,
                        noPrefix: true,
                        hide_asset: this.props.hide_asset,
                        amount: totalValue,
                        asset: toAsset.get("id"),
                        decimalOffset: toAsset.get("symbol").indexOf("BTC") === -1 ? toAsset.get("precision") - 2 : 4
                    })
                );
            } else {
                return React.createElement(
                    Tooltip,
                    { placement: "bottom", title: totalsTip },
                    React.createElement(
                        "div",
                        { className: "tooltip inline-block" },
                        !!this.props.label ? React.createElement(
                            "span",
                            { className: "font-secondary" },
                            React.createElement(Translate, { content: this.props.label }),
                            ":",
                            " "
                        ) : null,
                        React.createElement(FormattedAsset, {
                            noTip: true,
                            noPrefix: true,
                            hide_asset: this.props.hide_asset,
                            amount: totalValue,
                            asset: toAsset.get("id"),
                            decimalOffset: toAsset.get("symbol").indexOf("BTC") === -1 ? toAsset.get("precision") - 2 : 4
                        })
                    )
                );
            }
        }
    }]);

    return TotalValue;
}(MarketStatsCheck);

TotalValue.propTypes = {
    toAsset: ChainTypes.ChainAsset.isRequired,
    coreAsset: ChainTypes.ChainAsset.isRequired,
    inHeader: PropTypes.bool,
    label: PropTypes.string
};
TotalValue.defaultProps = {
    inHeader: false,
    label: "",
    coreAsset: "1.3.0"
};

TotalValue = BindToChainState(TotalValue);
TotalValue = AssetWrapper(TotalValue, {
    propNames: ["fromAssets"],
    asList: true
});

var ValueStoreWrapper = function (_React$Component) {
    _inherits(ValueStoreWrapper, _React$Component);

    function ValueStoreWrapper() {
        _classCallCheck(this, ValueStoreWrapper);

        return _possibleConstructorReturn(this, (ValueStoreWrapper.__proto__ || Object.getPrototypeOf(ValueStoreWrapper)).apply(this, arguments));
    }

    _createClass(ValueStoreWrapper, [{
        key: "render",
        value: function render() {
            var preferredUnit = this.props.settings.get("unit") || "1.3.0";

            return React.createElement(TotalValue, _extends({}, this.props, { toAsset: preferredUnit }));
        }
    }]);

    return ValueStoreWrapper;
}(React.Component);

ValueStoreWrapper = connect(ValueStoreWrapper, {
    listenTo: function listenTo() {
        return [MarketsStore, SettingsStore];
    },
    getProps: function getProps() {
        return {
            allMarketStats: MarketsStore.getState().allMarketStats,
            settings: SettingsStore.getState().settings
        };
    }
});

var TotalBalanceValue = function (_React$Component2) {
    _inherits(TotalBalanceValue, _React$Component2);

    function TotalBalanceValue() {
        _classCallCheck(this, TotalBalanceValue);

        return _possibleConstructorReturn(this, (TotalBalanceValue.__proto__ || Object.getPrototypeOf(TotalBalanceValue)).apply(this, arguments));
    }

    _createClass(TotalBalanceValue, [{
        key: "render",
        value: function render() {
            var _props2 = this.props,
                balances = _props2.balances,
                collateral = _props2.collateral,
                debt = _props2.debt,
                openOrders = _props2.openOrders,
                inHeader = _props2.inHeader;

            var assets = List();
            var amounts = [];

            balances.forEach(function (balance) {
                if (balance) {
                    assets = assets.push(balance.get("asset_type"));
                    amounts.push({
                        asset_id: balance.get("asset_type"),
                        amount: parseInt(balance.get("balance"), 10)
                    });
                }
            });

            for (var asset in collateral) {
                if (!assets.includes(asset)) {
                    assets = assets.push(asset);
                }
            }

            for (var _asset5 in debt) {
                if (!assets.includes(_asset5)) {
                    assets = assets.push(_asset5);
                }
            }

            for (var _asset6 in openOrders) {
                if (!assets.includes(_asset6)) {
                    assets = assets.push(_asset6);
                }
            }

            return React.createElement(ValueStoreWrapper, {
                label: this.props.label,
                hide_asset: this.props.hide_asset,
                noTip: this.props.noTip,
                inHeader: inHeader,
                balances: amounts,
                openOrders: openOrders,
                debt: debt,
                collateral: collateral,
                fromAssets: assets
            });
        }
    }]);

    return TotalBalanceValue;
}(React.Component);

TotalBalanceValue.propTypes = {
    balances: ChainTypes.ChainObjectsList
};
TotalBalanceValue.defaultProps = {
    collateral: {},
    debt: {},
    openOrders: {}
};

TotalBalanceValue = BindToChainState(TotalBalanceValue);

var AccountWrapper = function (_React$Component3) {
    _inherits(AccountWrapper, _React$Component3);

    function AccountWrapper() {
        _classCallCheck(this, AccountWrapper);

        return _possibleConstructorReturn(this, (AccountWrapper.__proto__ || Object.getPrototypeOf(AccountWrapper)).apply(this, arguments));
    }

    _createClass(AccountWrapper, [{
        key: "shouldComponentUpdate",
        value: function shouldComponentUpdate(nextProps) {
            return !utils.are_equal_shallow(nextProps.accounts, this.props.accounts) || !utils.are_equal_shallow(nextProps.hiddenAssets.toJS(), this.props.hiddenAssets.toJS());
        }
    }, {
        key: "render",
        value: function render() {
            var _this6 = this;

            var balanceList = List(),
                collateral = {},
                debt = {},
                openOrders = {};

            this.props.accounts.forEach(function (account) {
                if (account) {
                    account.get("orders") && account.get("orders").forEach(function (orderID, key) {
                        var order = ChainStore.getObject(orderID);
                        if (order) {
                            var orderAsset = order.getIn(["sell_price", "base", "asset_id"]);
                            if (!openOrders[orderAsset]) {
                                openOrders[orderAsset] = parseInt(order.get("for_sale"), 10);
                            } else {
                                openOrders[orderAsset] += parseInt(order.get("for_sale"), 10);
                            }
                        }
                    });

                    account.get("call_orders") && account.get("call_orders").forEach(function (callID, key) {
                        var position = ChainStore.getObject(callID);
                        if (position) {
                            var collateralAsset = position.getIn(["call_price", "base", "asset_id"]);
                            if (!collateral[collateralAsset]) {
                                collateral[collateralAsset] = parseInt(position.get("collateral"), 10);
                            } else {
                                collateral[collateralAsset] += parseInt(position.get("collateral"), 10);
                            }
                            var debtAsset = position.getIn(["call_price", "quote", "asset_id"]);
                            if (!debt[debtAsset]) {
                                debt[debtAsset] = parseInt(position.get("debt"), 10);
                            } else {
                                debt[debtAsset] += parseInt(position.get("debt"), 10);
                            }
                        }
                    });

                    var account_balances = account.get("balances");
                    account_balances && account_balances.forEach(function (balance, asset_type) {
                        if (_this6.props.hiddenAssets.includes(asset_type)) {
                            return null;
                        }
                        var balanceAmount = ChainStore.getObject(balance);
                        if (!balanceAmount || !balanceAmount.get("balance")) {
                            return null;
                        }
                        balanceList = balanceList.push(balance);
                    });
                }
            });

            if (!balanceList.size && !Object.keys(openOrders).length && !Object.keys(debt).length) {
                return React.createElement(
                    "span",
                    null,
                    !!this.props.label ? React.createElement(
                        "span",
                        { className: "font-secondary" },
                        React.createElement(Translate, { content: this.props.label }),
                        ":",
                        " "
                    ) : null,
                    " ",
                    "0"
                );
            } else {
                return React.createElement(TotalBalanceValue, _extends({}, this.props, {
                    balances: balanceList,
                    openOrders: openOrders,
                    debt: debt,
                    collateral: collateral
                }));
            }
        }
    }]);

    return AccountWrapper;
}(React.Component);

AccountWrapper.propTypes = {
    accounts: ChainTypes.ChainAccountsList.isRequired
};

AccountWrapper = BindToChainState(AccountWrapper);

TotalBalanceValue.AccountWrapper = AccountWrapper;
export default TotalBalanceValue;