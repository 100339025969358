import React from "react";
import Translate from "react-translate-component";
import TranslateWithLinks from "../../Utility/TranslateWithLinks";

export var WitnessCreate = function WitnessCreate(_ref) {
    var op = _ref.op,
        linkToAccount = _ref.linkToAccount,
        fromComponent = _ref.fromComponent;

    if (fromComponent === "proposed_operation") {
        return React.createElement(
            "span",
            null,
            React.createElement(Translate, { component: "span", content: "proposal.witness_create" }),
            "\xA0",
            linkToAccount(op[1].witness_account)
        );
    } else {
        return React.createElement(
            "span",
            null,
            React.createElement(TranslateWithLinks, {
                string: "operation.witness_create",
                keys: [{
                    type: "account",
                    value: op[1].witness_account,
                    arg: "account"
                }]
            })
        );
    }
};