import React from "react";
import PropTypes from "prop-types";
import Translate from "react-translate-component";
import counterpart from "counterpart";
import Icon from "../Icon/Icon";
import { Tooltip } from "bitshares-ui-style-guide";

export default function WalletHeaderSelection(props) {
    return React.createElement(
        "div",
        {
            onClick: props.onChangeActive,
            className: (props.forSmall ? "hide-block-for-medium inactive-left-block" : "") + " small-horizontal small-only-block header-block"
        },
        !props.forSmall ? React.createElement(
            "div",
            null,
            props.active ? React.createElement("img", {
                className: "model-img",
                src: "model-type-images/flesh-active.svg",
                alt: "wallet"
            }) : React.createElement("img", {
                className: "model-img inactive-img",
                src: "model-type-images/flesh-inactive.svg",
                alt: "wallet"
            })
        ) : null,
        React.createElement(
            "div",
            { className: "small-only-text-left" },
            React.createElement(Translate, {
                content: "registration.walletModelTitle",
                component: "p",
                className: "selection-title " + (!props.active ? "inactive-title inactive-text" : "")
            }),
            React.createElement(Translate, {
                content: "wallet.wallet_model",
                className: "choice-model " + (!props.active ? "inactive-text" : "")
            }),
            !props.forSmall ? React.createElement(
                Tooltip,
                {
                    title: props.active ? counterpart.translate("tooltip.registration.walletModel") : ""
                },
                React.createElement(
                    "span",
                    null,
                    React.createElement(Icon, {
                        name: "question-in-circle",
                        className: "icon-14px question-icon"
                    })
                )
            ) : null,
            React.createElement(Translate, {
                content: "registration.recommended",
                component: "p",
                className: "recommended " + (!props.active && !props.forSmall ? "inactive-text" : "")
            })
        )
    );
}

WalletHeaderSelection.propTypes = {
    active: PropTypes.bool.isRequired,
    forSmall: PropTypes.bool,
    onChangeActive: PropTypes.func
};

WalletHeaderSelection.defaultProps = {
    forSmall: false,
    onChangeActive: null
};