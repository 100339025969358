require("file-loader?name=model-type-images/[name].svg!./model-type-images/account-active.svg");
require("file-loader?name=model-type-images/[name].svg!./model-type-images/account-inactive.svg");
require("file-loader?name=model-type-images/[name].svg!./model-type-images/flesh-active.svg");
require("file-loader?name=model-type-images/[name].svg!./model-type-images/flesh-inactive.svg");
require("file-loader?name=model-type-images/[name].svg!./model-type-images/flesh-drive.svg");
require("file-loader?name=model-type-images/[name].svg!./model-type-images/account.svg");
require("file-loader?name=bin-file/[name].svg!./bin-file/default.svg");
require("file-loader?name=bin-file/[name].svg!./bin-file/downloaded.svg");
require("file-loader?name=bin-file/[name].svg!./bin-file/error.svg");
require("file-loader?name=bin-file/[name].svg!./bin-file/hover.svg");
require("file-loader?name=bin-file/[name].svg!./bin-file/rounded-arrow.svg");