import React from "react";
import TranslateWithLinks from "../../Utility/TranslateWithLinks";
import { Tooltip } from "bitshares-ui-style-guide";
import counterpart from "counterpart";

export var HtlcRedeem = function HtlcRedeem(_ref) {
    var op = _ref.op,
        changeColor = _ref.changeColor;

    changeColor("success");
    return React.createElement(
        React.Fragment,
        null,
        React.createElement(
            "span",
            { className: "right-td" },
            React.createElement(TranslateWithLinks, {
                string: "operation.htlc_redeem",
                keys: [{
                    type: "account",
                    value: op[1].redeemer,
                    arg: "redeemer"
                }, {
                    value: op[1].htlc_id,
                    arg: "htlc_id"
                }]
            })
        ),
        React.createElement(
            "div",
            { className: "memo", style: { paddingTop: 5, cursor: "help" } },
            React.createElement(
                Tooltip,
                {
                    placement: "bottom",
                    title: counterpart.translate("htlc.preimage_explanation")
                },
                React.createElement(
                    "span",
                    { className: "inline-block" },
                    counterpart.translate("htlc.preimage") + ": " + op[1].preimage
                )
            )
        )
    );
};