var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from "react";
import PropTypes from "prop-types";
import Translate from "react-translate-component";
import { ChainStore, FetchChain } from "bitsharesjs/es";
import counterpart from "counterpart";
import AccountActions from "actions/AccountActions";
import WalletUnlockActions from "actions/WalletUnlockActions";
import WalletActions from "actions/WalletActions";
import AccountStore from "stores/AccountStore";
import WalletDb from "stores/WalletDb";
import TransactionConfirmStore from "stores/TransactionConfirmStore";
import utils from "common/utils";
import AccountSelect from "../Forms/AccountSelect";
import AccountNameInput from "./../Forms/AccountNameInputStyleGuide";
import PasswordInput from "./../Forms/PasswordInputStyleGuide";
import Icon from "../Icon/Icon";
import { Notification, Form, Input, Button, Select, Alert, Tooltip } from "bitshares-ui-style-guide";

var WalletRegistrationForm = function (_React$Component) {
    _inherits(WalletRegistrationForm, _React$Component);

    function WalletRegistrationForm() {
        _classCallCheck(this, WalletRegistrationForm);

        var _this = _possibleConstructorReturn(this, (WalletRegistrationForm.__proto__ || Object.getPrototypeOf(WalletRegistrationForm)).call(this));

        _this.state = {
            validAccountName: false,
            accountName: "",
            validPassword: false,
            registrarAccount: undefined,
            loading: false,
            showIdenticon: false,
            password: ""
        };
        _this.onFinishConfirm = _this.onFinishConfirm.bind(_this);
        _this.onRegistrarAccountChange = _this.onRegistrarAccountChange.bind(_this);
        _this.unmounted = false;

        _this.onSubmit = _this.onSubmit.bind(_this);
        _this.onPasswordChange = _this.onPasswordChange.bind(_this);
        _this.onPasswordValidationChange = _this.onPasswordValidationChange.bind(_this);
        return _this;
    }

    _createClass(WalletRegistrationForm, [{
        key: "shouldComponentUpdate",
        value: function shouldComponentUpdate(nextProps, nextState) {
            return !utils.are_equal_shallow(nextState, this.state);
        }
    }, {
        key: "componentWillUnmount",
        value: function componentWillUnmount() {
            this.unmounted = true;
        }
    }, {
        key: "onAccountNameChange",
        value: function onAccountNameChange(e) {
            var state = {};
            if (e.valid !== undefined) {
                state.validAccountName = e.valid;
            }
            if (e.value !== undefined) {
                state.accountName = e.value;
            }
            if (!this.state.showIdenticon) {
                state.showIdenticon = true;
            }
            this.setState(state);
        }
    }, {
        key: "onPasswordChange",
        value: function onPasswordChange(value) {
            this.setState({ password: value });
        }
    }, {
        key: "onPasswordValidationChange",
        value: function onPasswordValidationChange(validation) {
            this.setState({ validPassword: validation.valid });
        }
    }, {
        key: "onFinishConfirm",
        value: function onFinishConfirm(confirmStoreState) {
            var _this2 = this;

            if (confirmStoreState.included && confirmStoreState.broadcasted_transaction) {
                TransactionConfirmStore.unlisten(this.onFinishConfirm);
                TransactionConfirmStore.reset();

                FetchChain("getAccount", this.state.accountName, undefined, _defineProperty({}, this.state.accountName, true)).then(function () {
                    console.log("onFinishConfirm");
                    _this2.props.history.push("/wallet/backup/create?newAccount=true");
                });
            }
        }
    }, {
        key: "onRegistrarAccountChange",
        value: function onRegistrarAccountChange(registrarAccount) {
            this.setState({ registrarAccount: registrarAccount });
        }
    }, {
        key: "onSubmit",
        value: function onSubmit(e) {
            var _this3 = this;

            e.preventDefault();
            if (!this.isValid()) {
                return;
            }
            var accountName = this.state.accountName;

            if (WalletDb.getWallet()) {
                this.createAccount(accountName);
            } else {
                var password = this.state.password;
                this.createWallet(password).then(function () {
                    return _this3.createAccount(accountName);
                });
            }
        }
    }, {
        key: "createAccount",
        value: function createAccount(name) {
            var _this4 = this;

            var _AccountStore$getStat = AccountStore.getState(),
                referralAccount = _AccountStore$getStat.referralAccount;

            WalletUnlockActions.unlock().then(function () {
                _this4.setState({ loading: true });

                AccountActions.createAccount(name, _this4.state.registrarAccount, referralAccount || _this4.state.registrarAccount, 0).then(function () {
                    // User registering his own account
                    FetchChain("getAccount", name, undefined, _defineProperty({}, name, true)).then(function () {
                        _this4.props.continue();
                        if (_this4.unmounted) {
                            return;
                        }
                        _this4.setState({
                            loading: false
                        });
                    });
                    if (_this4.state.registrarAccount) {
                        TransactionConfirmStore.listen(_this4.onFinishConfirm);
                    }
                }).catch(function (error) {
                    _this4.setState({ loading: false });
                    console.log("ERROR AccountActions.createAccount", error);
                    var errorMsg = error.base && error.base.length && error.base.length > 0 ? error.base[0] : "unknown error";
                    if (error.remote_ip) {
                        ;

                        var _error$remote_ip = _slicedToArray(error.remote_ip, 1);

                        errorMsg = _error$remote_ip[0];
                    }Notification.error({
                        message: counterpart.translate("notifications.account_create_failure", {
                            account_name: name,
                            error_msg: errorMsg
                        })
                    });
                });
            });
        }
    }, {
        key: "createWallet",
        value: function createWallet(password) {
            var _this5 = this;

            this.setState({
                loading: true
            });
            return WalletActions.setWallet("default", password).then(function () {
                console.log("Congratulations, your wallet was successfully created.");
            }).catch(function (err) {
                _this5.setState({
                    loading: false
                });
                console.log("CreateWallet failed:", err);
                Notification.error({
                    message: counterpart.translate("notifications.account_wallet_create_failure", {
                        error_msg: err
                    })
                });
            });
        }
    }, {
        key: "isValid",
        value: function isValid() {
            var firstAccount = AccountStore.getMyAccounts().length === 0;
            var valid = this.state.validAccountName;
            if (!WalletDb.getWallet()) {
                valid = valid && this.state.validPassword;
            }
            if (!firstAccount) {
                valid = valid && this.state.registrarAccount;
            }
            return valid;
        }
    }, {
        key: "renderDropdown",
        value: function renderDropdown(myAccounts, isLTM) {
            var registrarAccount = this.state.registrarAccount;


            return React.createElement(
                Form.Item,
                { label: counterpart.translate("account.pay_from") },
                React.createElement(
                    Select,
                    {
                        placeholder: counterpart.translate("account.select_placeholder"),
                        style: { width: "100%" },
                        value: this.state.registrarAccount,
                        onChange: this.onRegistrarAccountChange
                    },
                    myAccounts.map(function (accountName) {
                        return React.createElement(
                            Select.Option,
                            { key: accountName, value: accountName },
                            accountName
                        );
                    })
                )
            );
        }
    }, {
        key: "renderPasswordInput",
        value: function renderPasswordInput() {
            return React.createElement(PasswordInput, {
                ref: "password",
                onChange: this.onPasswordChange,
                onValidationChange: this.onPasswordValidationChange,
                label: React.createElement(
                    "span",
                    null,
                    React.createElement(
                        "span",
                        { className: "vertical-middle" },
                        counterpart.translate("settings.password")
                    ),
                    "\xA0",
                    React.createElement(
                        Tooltip,
                        {
                            title: counterpart.translate("tooltip.registration.password")
                        },
                        React.createElement(
                            "span",
                            null,
                            React.createElement(Icon, {
                                name: "question-in-circle",
                                className: "icon-14px question-icon vertical-middle"
                            })
                        )
                    )
                )
            });
        }
    }, {
        key: "renderAccountCreateForm",
        value: function renderAccountCreateForm() {
            var _this6 = this;

            var registrarAccount = this.state.registrarAccount;


            var myAccounts = AccountStore.getMyAccounts();
            var firstAccount = myAccounts.length === 0;
            var hasWallet = WalletDb.getWallet();
            var valid = this.isValid();
            var isLTM = false;
            var registrar = registrarAccount ? ChainStore.getAccount(registrarAccount) : null;
            if (registrar) {
                if (registrar.get("lifetime_referrer") === registrar.get("id")) {
                    isLTM = true;
                }
            }

            var isButtonDisabled = function isButtonDisabled() {
                return !valid || registrarAccount && !isLTM;
            };

            return React.createElement(
                Form,
                { layout: "vertical", onSubmit: this.onSubmit },
                React.createElement(AccountNameInput, {
                    cheapNameOnly: !!firstAccount,
                    onChange: function onChange(e) {
                        return _this6.onAccountNameChange(e);
                    },
                    accountShouldNotExist: true,
                    placeholder: counterpart.translate("account.name"),
                    label: React.createElement(
                        "span",
                        null,
                        React.createElement(
                            "span",
                            { className: "vertical-middle" },
                            counterpart.translate("account.name")
                        ),
                        "\xA0",
                        React.createElement(
                            Tooltip,
                            {
                                title: counterpart.translate("tooltip.registration.accountName")
                            },
                            React.createElement(
                                "span",
                                null,
                                React.createElement(Icon, {
                                    name: "question-in-circle",
                                    className: "icon-14px question-icon vertical-middle"
                                })
                            )
                        )
                    ),
                    noLabel: true
                }),
                hasWallet ? null : this.renderPasswordInput(),
                firstAccount ? null : this.renderDropdown(myAccounts, isLTM),
                registrar && !isLTM ? React.createElement(
                    Form.Item,
                    null,
                    React.createElement(Alert, {
                        type: "error",
                        description: React.createElement(Translate, { content: "wallet.must_be_ltm" })
                    })
                ) : null,
                React.createElement(
                    Form.Item,
                    null,
                    React.createElement(
                        Button,
                        {
                            type: "primary",
                            disabled: this.state.loading || isButtonDisabled(),
                            htmlType: "submit",
                            loading: this.state.loading
                        },
                        counterpart.translate("registration.continue")
                    )
                )
            );
        }
    }, {
        key: "render",
        value: function render() {
            var hasWallet = WalletDb.getWallet();
            var firstAccount = AccountStore.getMyAccounts().length === 0;

            return React.createElement(
                "div",
                null,
                React.createElement(
                    "div",
                    { className: "text-left" },
                    firstAccount ? React.createElement(Translate, {
                        component: "h3",
                        content: "registration.createAccountTitle"
                    }) : React.createElement(Translate, { component: "h3", content: "wallet.create_a" }),
                    !hasWallet ? React.createElement(Translate, {
                        component: "p",
                        content: "registration.walletDescription",
                        className: "model-description"
                    }) : null
                ),
                this.renderAccountCreateForm()
            );
        }
    }]);

    return WalletRegistrationForm;
}(React.Component);

WalletRegistrationForm.propTypes = {
    continue: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired
};


export default WalletRegistrationForm;