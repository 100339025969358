var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "alt-react";
import Translate from "react-translate-component";
import ReactTooltip from "react-tooltip";
import BackupStore from "stores/BackupStore";
import BackupActions from "actions/BackupActions";
import DecryptBackup from "./DecryptBackup";
import Icon from "../Icon/Icon";

var WalletLogin = function (_Component) {
    _inherits(WalletLogin, _Component);

    function WalletLogin(props) {
        _classCallCheck(this, WalletLogin);

        var _this = _possibleConstructorReturn(this, (WalletLogin.__proto__ || Object.getPrototypeOf(WalletLogin)).call(this, props));

        _this.state = {
            isDrop: false
        };
        return _this;
    }

    _createClass(WalletLogin, [{
        key: "componentDidMount",
        value: function componentDidMount() {
            BackupActions.reset();
        }
    }, {
        key: "onFileUpload",
        value: function onFileUpload(evt, droppedFile) {
            var file = droppedFile || evt.target.files[0];
            BackupActions.incommingWebFile(file);
            this.forceUpdate();
        }
    }, {
        key: "onDropBinFile",
        value: function onDropBinFile(e) {
            e.preventDefault();
            this.onFileUpload(e, e.dataTransfer.files[0]);
        }
    }, {
        key: "onPlaceFile",
        value: function onPlaceFile(isOpen) {
            this.setState({
                isDrop: isOpen
            });
        }
    }, {
        key: "renderTooltip",
        value: function renderTooltip() {
            return React.createElement(
                ReactTooltip,
                {
                    id: "without-bin",
                    className: "custom-tooltip text-left",
                    globalEventOff: "click"
                },
                React.createElement(
                    "div",
                    {
                        className: "tooltip-text",
                        onClick: function onClick(e) {
                            return e.stopPropagation();
                        }
                    },
                    React.createElement(Translate, { content: "tooltip.login-tooltip.withoutBinFileBlock.begin" }),
                    React.createElement(
                        Link,
                        { to: "/create-wallet-brainkey" },
                        React.createElement(Translate, {
                            component: "u",
                            className: "active-upload-text cursor-pointer",
                            content: "tooltip.login-tooltip.withoutBinFileBlock.brainkey"
                        })
                    ),
                    React.createElement(Translate, { content: "tooltip.login-tooltip.withoutBinFileBlock.middle" }),
                    React.createElement(Translate, {
                        onClick: this.props.goToAccountModel,
                        className: "without-bin cursor-pointer",
                        content: "tooltip.login-tooltip.withoutBinFileBlock.model"
                    }),
                    React.createElement(Translate, { content: "tooltip.login-tooltip.withoutBinFileBlock.end" }),
                    React.createElement(
                        "span",
                        {
                            onClick: function onClick() {
                                return ReactTooltip.hide();
                            },
                            className: "close-button"
                        },
                        "\xD7"
                    )
                )
            );
        }
    }, {
        key: "renderUploadInputForSmall",
        value: function renderUploadInputForSmall() {
            var _this2 = this;

            var isDownloaded = this.props.backup.contents && this.props.backup.public_key;

            return React.createElement(
                "div",
                null,
                React.createElement(
                    "span",
                    { className: "text-left left-label show-for-small-only" },
                    React.createElement(Translate, {
                        content: this.props.backup.contents ? "login.selectDifferent" : "login.browseFileLabel"
                    })
                ),
                React.createElement(
                    "div",
                    {
                        onDragOver: function onDragOver(e) {
                            return e.preventDefault();
                        },
                        onDragEnter: function onDragEnter(e) {
                            return e.preventDefault();
                        },
                        onDrop: function onDrop(e) {
                            return _this2.onDropBinFile(e);
                        },
                        className: "small-container"
                    },
                    isDownloaded ? React.createElement(
                        "span",
                        { className: "bin-name" },
                        this.props.backup.name
                    ) : React.createElement(
                        "span",
                        null,
                        "\xA0"
                    ),
                    React.createElement(
                        "span",
                        { className: "upload-text" },
                        React.createElement(
                            "u",
                            { className: "active-upload-text" },
                            React.createElement("input", {
                                ref: "file_input",
                                accept: ".bin",
                                type: "file",
                                id: "backupFile",
                                className: "upload-bin-input",
                                onChange: function onChange(e) {
                                    return _this2.onFileUpload(e);
                                }
                            }),
                            React.createElement(Icon, { name: "paperclip", className: "attach-bin" })
                        )
                    )
                )
            );
        }
    }, {
        key: "renderUploadInput",
        value: function renderUploadInput() {
            var _this3 = this;

            var isDrop = this.state.isDrop;
            var backup = this.props.backup;

            var isInvalid = backup.contents && !backup.public_key;
            var isDownloaded = backup.contents && backup.public_key;

            return React.createElement(
                "label",
                {
                    onDragOver: function onDragOver() {
                        _this3.onPlaceFile(true);
                    },
                    onDragLeave: function onDragLeave() {
                        _this3.onPlaceFile(false);
                    },
                    className: "cursor-pointer",
                    htmlFor: "backupFile"
                },
                React.createElement(
                    "div",
                    {
                        onDragOver: function onDragOver(e) {
                            return e.preventDefault();
                        },
                        onDrop: function onDrop(e) {
                            return _this3.onDropBinFile(e);
                        },
                        className: "file-input-container " + (isInvalid ? "invalid" : "") + " " + (isDrop ? "dropHover" : "") + " " + (isDownloaded ? "downloaded" : "")
                    },
                    React.createElement("img", {
                        className: "rounded-arrow",
                        src: "bin-file/rounded-arrow.svg",
                        alt: "arrow"
                    }),
                    isDownloaded ? React.createElement("img", {
                        className: "bin-file",
                        src: "bin-file/downloaded.svg",
                        alt: "bin-file"
                    }) : isInvalid ? React.createElement("img", {
                        className: "bin-file",
                        src: "bin-file/error.svg",
                        alt: "bin-file"
                    }) : React.createElement(
                        "span",
                        null,
                        React.createElement("img", {
                            className: "bin-file initial-bin",
                            src: "bin-file/hover.svg",
                            alt: "bin-file"
                        })
                    ),
                    React.createElement(
                        "span",
                        { className: "upload-text text-left no-overflow" },
                        isDownloaded ? React.createElement(
                            "p",
                            { className: "bin-name" },
                            this.props.backup.name,
                            " (",
                            this.props.backup.size,
                            " bytes)"
                        ) : isInvalid ? React.createElement(Translate, {
                            className: "facolor-error",
                            content: "login.invalidFormat"
                        }) : React.createElement(Translate, { content: "login.dropFile" }),
                        React.createElement(
                            "u",
                            { className: "active-upload-text" },
                            React.createElement("input", {
                                ref: "file_input",
                                accept: ".bin",
                                type: "file",
                                id: "backupFile",
                                className: "upload-bin-input",
                                onChange: function onChange(e) {
                                    return _this3.onFileUpload(e);
                                }
                            }),
                            React.createElement(Translate, {
                                content: this.props.backup.contents ? "login.selectDifferent" : "login.browseFile"
                            })
                        )
                    )
                )
            );
        }
    }, {
        key: "render",
        value: function render() {
            return React.createElement(
                "div",
                { onClick: this.props.onChangeActive, className: "wallet-block" },
                React.createElement(
                    "div",
                    { className: "overflow-bg-block show-for-small-only" },
                    React.createElement("span", { className: "content" })
                ),
                new FileReader().readAsBinaryString || !this.props.active ? null : React.createElement(Translate, {
                    component: "p",
                    className: "error",
                    content: "login.supportWarning"
                }),
                React.createElement(
                    "div",
                    { className: !this.props.active ? "display-none" : "" },
                    this.renderUploadInput(),
                    this.renderUploadInputForSmall(),
                    React.createElement(Translate, {
                        component: "p",
                        className: "text-left without-bin cursor-pointer hide-for-small-only",
                        content: "login.withoutBinFile",
                        "data-for": "without-bin",
                        "data-tip": true,
                        "data-event": "click",
                        "data-place": "right",
                        "data-effect": "solid"
                    }),
                    React.createElement(Translate, {
                        component: "p",
                        className: "text-left without-bin cursor-pointer show-for-small-only",
                        content: "login.withoutBinFile",
                        "data-for": "without-bin",
                        "data-tip": true,
                        "data-event": "click",
                        "data-place": "bottom",
                        "data-effect": "solid"
                    }),
                    this.renderTooltip()
                ),
                React.createElement(DecryptBackup, {
                    active: this.props.active,
                    history: this.props.history
                })
            );
        }
    }]);

    return WalletLogin;
}(Component);

WalletLogin.propTypes = {
    active: PropTypes.bool,
    backup: PropTypes.object,
    onChangeActive: PropTypes.func.isRequired,
    goToAccountModel: PropTypes.func.isRequired
};
WalletLogin.defaultProps = {
    active: false,
    backup: {}
};


var connectObject = {
    listenTo: function listenTo() {
        return [BackupStore];
    },
    getProps: function getProps() {
        return {
            backup: BackupStore.getState()
        };
    }
};

export default connect(WalletLogin, connectObject);