var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from "react";
import { connect } from "alt-react";
import classNames from "classnames";
import AccountActions from "actions/AccountActions";
import AccountStore from "stores/AccountStore";
import AccountNameInput from "./../Forms/AccountNameInputStyleGuide";
import PasswordInput from "./../Forms/PasswordInputStyleGuide";
import WalletDb from "stores/WalletDb";
import { Link } from "react-router-dom";
import AccountSelect from "../Forms/AccountSelect";
import WalletUnlockActions from "actions/WalletUnlockActions";
import TransactionConfirmStore from "stores/TransactionConfirmStore";
import LoadingIndicator from "../LoadingIndicator";
import WalletActions from "actions/WalletActions";
import Translate from "react-translate-component";
import { ChainStore, FetchChain } from "bitsharesjs";
import { BackupCreate } from "../Wallet/Backup";
import ReactTooltip from "react-tooltip";
import utils from "common/utils";
import SettingsActions from "actions/SettingsActions";
import counterpart from "counterpart";
import { withRouter } from "react-router-dom";
import { scroller } from "react-scroll";
import { getWalletName } from "branding";
import { Notification } from "bitshares-ui-style-guide";

var CreateAccount = function (_React$Component) {
    _inherits(CreateAccount, _React$Component);

    function CreateAccount() {
        _classCallCheck(this, CreateAccount);

        var _this = _possibleConstructorReturn(this, (CreateAccount.__proto__ || Object.getPrototypeOf(CreateAccount)).call(this));

        _this._onBackupDownload = function () {
            _this.setState({
                step: 3
            });
        };

        _this.state = {
            validAccountName: false,
            accountName: "",
            validPassword: false,
            registrar_account: null,
            loading: false,
            hide_refcode: true,
            show_identicon: false,
            step: 1
        };
        _this.onFinishConfirm = _this.onFinishConfirm.bind(_this);

        _this.accountNameInput = null;

        _this.scrollToInput = _this.scrollToInput.bind(_this);
        return _this;
    }

    _createClass(CreateAccount, [{
        key: "componentWillMount",
        value: function componentWillMount() {
            SettingsActions.changeSetting({
                setting: "passwordLogin",
                value: false
            });
        }
    }, {
        key: "componentDidMount",
        value: function componentDidMount() {
            ReactTooltip.rebuild();
            this.scrollToInput();
        }
    }, {
        key: "shouldComponentUpdate",
        value: function shouldComponentUpdate(nextProps, nextState) {
            return !utils.are_equal_shallow(nextState, this.state);
        }
    }, {
        key: "isValid",
        value: function isValid() {
            var firstAccount = AccountStore.getMyAccounts().length === 0;
            var valid = this.state.validAccountName;
            if (!WalletDb.getWallet()) {
                valid = valid && this.state.validPassword;
            }
            if (!firstAccount) {
                valid = valid && this.state.registrar_account;
            }
            return valid;
        }
    }, {
        key: "onAccountNameChange",
        value: function onAccountNameChange(e) {
            var state = {};
            if (e.valid !== undefined) state.validAccountName = e.valid;
            if (e.value !== undefined) state.accountName = e.value;
            if (!this.state.show_identicon) state.show_identicon = true;
            this.setState(state);
        }
    }, {
        key: "onPasswordChange",
        value: function onPasswordChange(e) {
            this.setState({ validPassword: e.valid });
        }
    }, {
        key: "onFinishConfirm",
        value: function onFinishConfirm(confirm_store_state) {
            var _this2 = this;

            if (confirm_store_state.included && confirm_store_state.broadcasted_transaction) {
                TransactionConfirmStore.unlisten(this.onFinishConfirm);
                TransactionConfirmStore.reset();

                FetchChain("getAccount", this.state.accountName, undefined, _defineProperty({}, this.state.accountName, true)).then(function () {
                    console.log("onFinishConfirm");
                    _this2.props.history.push("/wallet/backup/create?newAccount=true");
                });
            }
        }
    }, {
        key: "scrollToInput",
        value: function scrollToInput() {
            scroller.scrollTo("scrollToInput", {
                duration: 1500,
                delay: 100,
                smooth: true,
                containerId: "accountForm"
            });
        }
    }, {
        key: "createAccount",
        value: function createAccount(name) {
            var _this3 = this;

            var refcode = this.refs.refcode ? this.refs.refcode.value() : null;
            var referralAccount = AccountStore.getState().referralAccount;
            WalletUnlockActions.unlock().then(function () {
                _this3.setState({ loading: true });

                AccountActions.createAccount(name, _this3.state.registrar_account, referralAccount || _this3.state.registrar_account, 0, refcode).then(function () {
                    // User registering his own account
                    if (_this3.state.registrar_account) {
                        FetchChain("getAccount", name, undefined, _defineProperty({}, name, true)).then(function () {
                            _this3.setState({
                                step: 2,
                                loading: false
                            });
                        });
                        TransactionConfirmStore.listen(_this3.onFinishConfirm);
                    } else {
                        // Account registered by the faucet
                        FetchChain("getAccount", name, undefined, _defineProperty({}, name, true)).then(function () {
                            _this3.setState({
                                step: 2,
                                loading: false
                            });
                        });
                    }
                }).catch(function (error) {
                    console.log("ERROR AccountActions.createAccount", error);
                    var error_msg = error.base && error.base.length && error.base.length > 0 ? error.base[0] : "unknown error";
                    if (error.remote_ip) error_msg = error.remote_ip[0];
                    Notification.error({
                        message: counterpart.translate("notifications.account_create_failure", {
                            account_name: name,
                            error_msg: error_msg
                        })
                    });
                    _this3.setState({ loading: false });
                });
            }).catch(function () {});
        }
    }, {
        key: "createWallet",
        value: function createWallet(password) {
            return WalletActions.setWallet("default", //wallet name
            password).then(function () {
                console.log("Congratulations, your wallet was successfully created.");
            }).catch(function (err) {
                console.log("CreateWallet failed:", err);
                Notification.error({
                    message: counterpart.translate("notifications.account_wallet_create_failure", {
                        error_msg: err
                    })
                });
            });
        }
    }, {
        key: "onSubmit",
        value: function onSubmit(e) {
            var _this4 = this;

            e.preventDefault();
            if (!this.isValid()) return;
            var account_name = this.accountNameInput.getValue();
            if (WalletDb.getWallet()) {
                this.createAccount(account_name);
            } else {
                var password = this.refs.password.value();
                this.createWallet(password).then(function () {
                    return _this4.createAccount(account_name);
                });
            }
        }
    }, {
        key: "onRegistrarAccountChange",
        value: function onRegistrarAccountChange(registrar_account) {
            this.setState({ registrar_account: registrar_account });
        }

        // showRefcodeInput(e) {
        //     e.preventDefault();
        //     this.setState({hide_refcode: false});
        // }

    }, {
        key: "_renderAccountCreateForm",
        value: function _renderAccountCreateForm() {
            var _this5 = this;

            var registrar_account = this.state.registrar_account;


            var my_accounts = AccountStore.getMyAccounts();
            var firstAccount = my_accounts.length === 0;
            var hasWallet = WalletDb.getWallet();
            var valid = this.isValid();
            var isLTM = false;
            var registrar = registrar_account ? ChainStore.getAccount(registrar_account) : null;
            if (registrar) {
                if (registrar.get("lifetime_referrer") == registrar.get("id")) {
                    isLTM = true;
                }
            }

            var buttonClass = classNames("submit-button button no-margin", {
                disabled: !valid || registrar_account && !isLTM
            });

            return React.createElement(
                "form",
                {
                    style: { maxWidth: "40rem" },
                    onSubmit: this.onSubmit.bind(this),
                    noValidate: true,
                    className: "create-account-wrapper"
                },
                React.createElement(
                    "p",
                    {
                        style: {
                            fontWeight: "normal",
                            fontFamily: "Roboto-Medium, arial, sans-serif",
                            fontStyle: "normal"
                        }
                    },
                    firstAccount ? React.createElement(Translate, { content: "wallet.create_w_a" }) : React.createElement(Translate, { content: "wallet.create_a" })
                ),
                React.createElement(AccountNameInput, {
                    ref: function ref(_ref) {
                        if (_ref) {
                            _this5.accountNameInput = _ref.refs.nameInput;
                        }
                    },
                    cheapNameOnly: !!firstAccount,
                    onChange: this.onAccountNameChange.bind(this),
                    accountShouldNotExist: true,
                    placeholder: counterpart.translate("wallet.account_public"),
                    noLabel: true
                }),
                hasWallet ? null : React.createElement(PasswordInput, {
                    ref: "password",
                    confirmation: true,
                    onChange: this.onPasswordChange.bind(this),
                    noLabel: true,
                    checkStrength: true
                }),
                firstAccount ? null : React.createElement(
                    "div",
                    { className: "full-width-content form-group no-overflow" },
                    React.createElement(
                        "label",
                        null,
                        React.createElement(Translate, { content: "account.pay_from" })
                    ),
                    React.createElement(AccountSelect, {
                        account_names: my_accounts,
                        onChange: this.onRegistrarAccountChange.bind(this)
                    }),
                    registrar_account && !isLTM ? React.createElement(
                        "div",
                        {
                            style: { textAlign: "left" },
                            className: "facolor-error"
                        },
                        React.createElement(Translate, { content: "wallet.must_be_ltm" })
                    ) : null
                ),
                React.createElement("div", { className: "divider" }),
                this.state.loading ? React.createElement(LoadingIndicator, { type: "three-bounce" }) : React.createElement(
                    "button",
                    { style: { width: "100%" }, className: buttonClass },
                    React.createElement(Translate, { content: "account.create_account" })
                ),
                React.createElement(
                    "div",
                    { style: { paddingTop: 40 } },
                    React.createElement(
                        "label",
                        null,
                        React.createElement(
                            Link,
                            { to: "/existing-account" },
                            React.createElement(Translate, { content: "wallet.restore" })
                        )
                    ),
                    React.createElement(
                        "label",
                        null,
                        React.createElement(
                            Link,
                            { to: "/create-wallet-brainkey" },
                            React.createElement(Translate, { content: "settings.backup_brainkey" })
                        )
                    )
                ),
                !hasWallet || firstAccount ? null : React.createElement(
                    "div",
                    { style: { paddingTop: 20 } },
                    React.createElement(
                        "label",
                        null,
                        React.createElement(
                            "a",
                            {
                                onClick: function onClick() {
                                    _this5.setState({ step: 3 });
                                }
                            },
                            React.createElement(Translate, { content: "wallet.go_get_started" })
                        )
                    )
                )
            );
        }
    }, {
        key: "_renderAccountCreateText",
        value: function _renderAccountCreateText() {
            var hasWallet = WalletDb.getWallet();
            var my_accounts = AccountStore.getMyAccounts();
            var firstAccount = my_accounts.length === 0;

            return React.createElement(
                "div",
                { className: "confirm-checks" },
                React.createElement(
                    "h4",
                    {
                        style: {
                            fontWeight: "normal",
                            fontFamily: "Roboto-Medium, arial, sans-serif",
                            fontStyle: "normal",
                            paddingBottom: 15,
                            marginTop: 0
                        }
                    },
                    React.createElement(Translate, { content: "wallet.wallet_browser" })
                ),
                React.createElement(
                    "p",
                    null,
                    !hasWallet ? React.createElement(Translate, {
                        content: "wallet.has_wallet",
                        wallet_name: getWalletName()
                    }) : null
                ),
                React.createElement(Translate, {
                    style: { textAlign: "left" },
                    component: "p",
                    content: "wallet.create_account_text"
                }),
                firstAccount ? React.createElement(Translate, {
                    style: { textAlign: "left" },
                    component: "p",
                    content: "wallet.first_account_paid"
                }) : React.createElement(Translate, {
                    style: { textAlign: "left" },
                    component: "p",
                    content: "wallet.not_first_account"
                })
            );
        }
    }, {
        key: "_renderBackup",
        value: function _renderBackup() {
            return React.createElement(
                "div",
                { className: "backup-submit" },
                React.createElement(
                    "p",
                    null,
                    React.createElement(Translate, { unsafe: true, content: "wallet.wallet_crucial" })
                ),
                React.createElement("div", { className: "divider" }),
                React.createElement(BackupCreate, { noText: true, downloadCb: this._onBackupDownload })
            );
        }
    }, {
        key: "_renderBackupText",
        value: function _renderBackupText() {
            return React.createElement(
                "div",
                null,
                React.createElement(
                    "p",
                    {
                        style: {
                            fontWeight: "normal",
                            fontFamily: "Roboto-Medium, arial, sans-serif",
                            fontStyle: "normal"
                        }
                    },
                    React.createElement(Translate, { content: "footer.backup" })
                ),
                React.createElement(
                    "p",
                    null,
                    React.createElement(Translate, { content: "wallet.wallet_move", unsafe: true })
                ),
                React.createElement(
                    "p",
                    { className: "txtlabel warning" },
                    React.createElement(Translate, { unsafe: true, content: "wallet.wallet_lose_warning" })
                )
            );
        }
    }, {
        key: "_renderGetStarted",
        value: function _renderGetStarted() {
            return React.createElement(
                "div",
                null,
                React.createElement(
                    "table",
                    { className: "table" },
                    React.createElement(
                        "tbody",
                        null,
                        React.createElement(
                            "tr",
                            null,
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, { content: "wallet.tips_dashboard" }),
                                ":"
                            ),
                            React.createElement(
                                "td",
                                null,
                                React.createElement(
                                    Link,
                                    { to: "/" },
                                    React.createElement(Translate, { content: "header.dashboard" })
                                )
                            )
                        ),
                        React.createElement(
                            "tr",
                            null,
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, { content: "wallet.tips_account" }),
                                ":"
                            ),
                            React.createElement(
                                "td",
                                null,
                                React.createElement(
                                    Link,
                                    {
                                        to: "/account/" + this.state.accountName + "/overview"
                                    },
                                    React.createElement(Translate, { content: "wallet.link_account" })
                                )
                            )
                        ),
                        React.createElement(
                            "tr",
                            null,
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, { content: "wallet.tips_deposit" }),
                                ":"
                            ),
                            React.createElement(
                                "td",
                                null,
                                React.createElement(
                                    Link,
                                    { to: "/deposit-withdraw" },
                                    React.createElement(Translate, { content: "wallet.link_deposit" })
                                )
                            )
                        ),
                        React.createElement(
                            "tr",
                            null,
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, { content: "wallet.tips_transfer" }),
                                ":"
                            ),
                            React.createElement(
                                "td",
                                null,
                                React.createElement(
                                    Link,
                                    { to: "/transfer" },
                                    React.createElement(Translate, { content: "wallet.link_transfer" })
                                )
                            )
                        ),
                        React.createElement(
                            "tr",
                            null,
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, { content: "wallet.tips_settings" }),
                                ":"
                            ),
                            React.createElement(
                                "td",
                                null,
                                React.createElement(
                                    Link,
                                    { to: "/settings" },
                                    React.createElement(Translate, { content: "header.settings" })
                                )
                            )
                        )
                    )
                )
            );
        }
    }, {
        key: "_renderGetStartedText",
        value: function _renderGetStartedText() {
            return React.createElement(
                "div",
                null,
                React.createElement(
                    "p",
                    {
                        style: {
                            fontWeight: "normal",
                            fontFamily: "Roboto-Medium, arial, sans-serif",
                            fontStyle: "normal"
                        }
                    },
                    React.createElement(Translate, { content: "wallet.congrat" })
                ),
                React.createElement(
                    "p",
                    null,
                    React.createElement(Translate, { content: "wallet.tips_explore" })
                ),
                React.createElement(
                    "p",
                    null,
                    React.createElement(Translate, { content: "wallet.tips_header" })
                ),
                React.createElement(
                    "p",
                    { className: "txtlabel warning" },
                    React.createElement(Translate, { content: "wallet.tips_login" })
                )
            );
        }
    }, {
        key: "render",
        value: function render() {
            var step = this.state.step;


            return React.createElement(
                "div",
                {
                    className: "sub-content",
                    id: "scrollToInput",
                    name: "scrollToInput"
                },
                React.createElement(
                    "div",
                    { style: { maxWidth: "95vw" } },
                    step !== 1 ? React.createElement(
                        "p",
                        {
                            style: {
                                fontWeight: "normal",
                                fontFamily: "Roboto-Medium, arial, sans-serif",
                                fontStyle: "normal"
                            }
                        },
                        React.createElement(Translate, { content: "wallet.step_" + step })
                    ) : null,
                    step === 1 ? this._renderAccountCreateForm() : step === 2 ? this._renderBackup() : this._renderGetStarted()
                ),
                React.createElement(
                    "div",
                    { style: { maxWidth: "95vw", paddingTop: "2rem" } },
                    step === 1 ? this._renderAccountCreateText() : step === 2 ? this._renderBackupText() : this._renderGetStartedText()
                ),
                React.createElement(
                    Link,
                    { to: "/" },
                    React.createElement(
                        "button",
                        { className: "button primary hollow" },
                        React.createElement(Translate, { content: "wallet.back" })
                    )
                )
            );
        }
    }]);

    return CreateAccount;
}(React.Component);

CreateAccount = withRouter(CreateAccount);

export default connect(CreateAccount, {
    listenTo: function listenTo() {
        return [AccountStore];
    },
    getProps: function getProps() {
        return {};
    }
});