import { SerializerValidation, TransactionBuilder, TransactionHelper } from "bitsharesjs";
import ApplicationApi from "./ApplicationApi";

var WalletApi = {
    new_transaction: function new_transaction() {
        return new TransactionBuilder();
    },
    sign_and_broadcast: function sign_and_broadcast(tr) {
        var broadcast = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

        SerializerValidation.required(tr, "transaction");
        return WalletDb.process_transaction(tr, null, //signer_private_key,
        broadcast);
    },


    /** Console print any transaction object with zero default values. */
    template: function template(transaction_object_name) {
        var object = TransactionHelper.template(transaction_object_name, {
            use_default: true,
            annotate: true
        });
        // visual
        console.error(JSON.stringify(object, null, 4));

        // usable
        object = TransactionHelper.template(transaction_object_name, {
            use_default: true,
            annotate: false
        });
        // visual
        console.error(JSON.stringify(object));
        return object;
    },
    transfer: function transfer(from_account_id, to_account_id, amount, asset, memo_message) {
        var broadcast = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : true;
        var encrypt_memo = arguments.length > 6 && arguments[6] !== undefined ? arguments[6] : true;
        var optional_nonce = arguments.length > 7 && arguments[7] !== undefined ? arguments[7] : null;

        console.error("deprecated, call application_api.transfer instead");
        return ApplicationApi.transfer({
            from_account_id: from_account_id,
            to_account_id: to_account_id,
            amount: amount,
            asset: asset,
            memo_message: memo_message,
            broadcast: broadcast,
            encrypt_memo: encrypt_memo,
            optional_nonce: optional_nonce
        });
    }
};

export default WalletApi;