var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

// look for more icons here https://linearicons.com/free or here http://hawcons.com/preview/

import React from "react";
import counterpart from "counterpart";
import PropTypes from "prop-types";
import iconsMap from "../../assets/icons/icons-loader.js";

require("./icon.scss");

var Icon = function (_React$Component) {
    _inherits(Icon, _React$Component);

    function Icon() {
        _classCallCheck(this, Icon);

        return _possibleConstructorReturn(this, (Icon.__proto__ || Object.getPrototypeOf(Icon)).apply(this, arguments));
    }

    _createClass(Icon, [{
        key: "shouldComponentUpdate",
        value: function shouldComponentUpdate(np) {
            return np.className !== this.props.className || np.name !== this.props.name || np.title !== this.props.title || np.size !== this.props.size;
        }
    }, {
        key: "render",
        value: function render() {
            var classes = "icon ";
            if (this.props.name !== "warning") {
                // fixme remove warning, otherwise color is being overwritten. should be handled by adjusting the CSS instead
                classes = classes + this.props.name;
            }
            if (this.props.size) {
                classes += " icon-" + this.props.size;
            }
            if (this.props.className) {
                classes += " " + this.props.className;
            }
            if (this.props.title != null) {
                var title = this.props.title;
                if (typeof title === "string" && title.indexOf(".") > 0) {
                    title = counterpart.translate(title);
                }
                return React.createElement("span", {
                    title: title,
                    className: classes,
                    style: this.props.style || {},
                    dangerouslySetInnerHTML: {
                        __html: iconsMap[this.props.name]
                    },
                    onClick: this.props.onClick
                });
            } else {
                return React.createElement("span", {
                    className: classes,
                    style: this.props.style || {},
                    dangerouslySetInnerHTML: {
                        __html: iconsMap[this.props.name]
                    },
                    onClick: this.props.onClick
                });
            }
        }
    }]);

    return Icon;
}(React.Component);

Icon.propTypes = {
    name: PropTypes.string.isRequired,
    title: PropTypes.string,
    size: PropTypes.oneOf(["1x", "1_5x", "2x", "3x", "4x", "5x", "10x"]),
    inverse: PropTypes.bool,
    className: PropTypes.string
};

Icon.defaultProps = {
    title: null
};

export default Icon;