var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

import alt from "alt-instance";

import localeCodes from "assets/locales";

var locales = {};
if (__ELECTRON__) {
    localeCodes.forEach(function (locale) {
        locales[locale] = require("assets/locales/locale-" + locale + ".json");
    });
}

var IntlActions = function () {
    function IntlActions() {
        _classCallCheck(this, IntlActions);
    }

    _createClass(IntlActions, [{
        key: "switchLocale",
        value: function switchLocale(locale) {
            if (locale === "en") {
                return { locale: locale };
            }
            if (__ELECTRON__) {
                return {
                    locale: locale,
                    localeData: locales[locale]
                };
            } else {
                return function (dispatch) {
                    fetch(__BASE_URL__ + "locale-" + locale + ".json").then(function (reply) {
                        return reply.json().then(function (result) {
                            dispatch({
                                locale: locale,
                                localeData: result
                            });
                        });
                    }).catch(function (err) {
                        console.log("fetch locale error:", err);
                        return function (dispatch) {
                            dispatch({ locale: "en" });
                        };
                    });
                };
            }
        }
    }, {
        key: "getLocale",
        value: function getLocale(locale) {
            return locale;
        }
    }]);

    return IntlActions;
}();

export default alt.createActions(IntlActions);