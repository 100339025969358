var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

import alt from "alt-instance";

var WalletUnlockActions = function () {
    function WalletUnlockActions() {
        _classCallCheck(this, WalletUnlockActions);
    }

    _createClass(WalletUnlockActions, [{
        key: "unlock",

        /** If you get resolved then the wallet is or was just unlocked.  If you get
            rejected then the wallet is still locked.
              @return nothing .. Just test for resolve() or reject()
        */
        value: function unlock() {
            return function (dispatch) {
                return new Promise(function (resolve, reject) {
                    dispatch({ resolve: resolve, reject: reject });
                }).then(function (was_unlocked) {
                    //DEBUG  console.log('... WalletUnlockStore\tmodal unlock')
                    if (was_unlocked) WrappedWalletUnlockActions.change();
                }).catch(function (params) {
                    throw params;
                });
            };
        }
    }, {
        key: "lock",
        value: function lock() {
            return function (dispatch) {
                return new Promise(function (resolve) {
                    dispatch({ resolve: resolve });
                }).then(function (was_unlocked) {
                    if (was_unlocked) WrappedWalletUnlockActions.change();
                });
            };
        }
    }, {
        key: "cancel",
        value: function cancel() {
            return true;
        }
    }, {
        key: "change",
        value: function change() {
            return true;
        }
    }, {
        key: "checkLock",
        value: function checkLock() {
            return true;
        }
    }]);

    return WalletUnlockActions;
}();

var WrappedWalletUnlockActions = alt.createActions(WalletUnlockActions);
export default WrappedWalletUnlockActions;