import React from "react";
import Translate from "react-translate-component";

export var Custom = function Custom(_ref) {
    var fromComponent = _ref.fromComponent;

    return React.createElement(
        "span",
        null,
        React.createElement(Translate, {
            component: "span",
            content: fromComponent === "proposed_operation" ? "proposal.custom" : "transaction.custom"
        })
    );
};