var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

import React from "react";
import { Transfer } from "./Transfer";
import { LimitOrderCreate } from "./LimitOrderCreate";
import { LimitOrderCancel } from "./LimitOrderCancel";
import { ShortOrderCancel } from "./ShortOrderCancel";
import { CallOrderUpdate } from "./CallOrderUpdate";
import { KeyCreate } from "./KeyCreate";
import { AccountCreate } from "./AccountCreate";
import { AccountUpdate } from "./AccountUpdate";
import { AccountWhitelist } from "./AccountWhitelist";
import { AccountUpgrade } from "./AccountUpgrade";
import { AccountTransfer } from "./AccountTransfer";
import { AssetCreate } from "./AssetCreate";
import { AssetUpdate } from "./AssetUpdate";
import { AssetUpdateFeedProducers } from "./AssetUpdateFeedProducers";
import { AssetIssue } from "./AssetIssue";
import { AssetReserve } from "./AssetReserve";
import { AssetFundFeePool } from "./AssetFundFeePool";
import { AssetSettle } from "./AssetSettle";
import { AssetSettleCancel } from "./AssetSettleCancel";
import { AssetGlobalSettle } from "./AssetGlobalSettle";
import { AssetPublishFeed } from "./AssetPublishFeed";
import { AssetClaimPool } from "./AssetClaimPool";
import { AssetUpdateIssuer } from "./AssetUpdateIssuer";
import { WitnessCreate } from "./WitnessCreate";
import { WitnessUpdate } from "./WitnessUpdate";
import { WitnessWithdrawPay } from "./WitnessWithdrawPay";
import { ProposalCreate } from "./ProposalCreate";
import { ProposalUpdate } from "./ProposalUpdate";
import { ProposalDelete } from "./ProposalDelete";
import { WithdrawPermissionCreate } from "./WithdrawPermissionCreate";
import { WithdrawPermissionUpdate } from "./WithdrawPermissionUpdate";
import { WithdrawPermissionClaim } from "./WithdrawPermissionClaim";
import { WithdrawPermissionDelete } from "./WithdrawPermissionDelete";
import { FillOrder } from "./FillOrder";
import { GlobalParametersUpdate } from "./GlobalParametersUpdate";
import { FileWrite } from "./FileWrite";
import { VestingBalanceCreate } from "./VestingBalanceCreate";
import { VestingBalanceWithdraw } from "./VestingBalanceWithdraw";
import { BondCreateOffer } from "./BondCreateOffer";
import { BondCancelOffer } from "./BondCancelOffer";
import { BondAcceptOffer } from "./BondAcceptOffer";
import { BondClaimCollaterial } from "./BondClaimCollaterial";
import { WorkerCreate } from "./WorkerCreate";
import { BalanceClaim } from "./BalanceClaim";
import { CommittyMemberCreate } from "./CommittyMemberCreate";
import { TransferToBlind } from "./TransferToBlind";
import { TransferFromBlind } from "./TransferFromBlind";
import { AssetClaimFees } from "./AssetClaimFees";
import { CommitteeMemberUpdateGlobalParams } from "./CommitteeMemberUpdateGlobalParams";
import { Custom } from "./Custom";
import { OverrideTransfer } from "./OverrideTransfer";
import { DefaultOperation } from "./DefaultOperation";
import { BidCollateral } from "./BidCollateral";
import { HtlcCreate } from "./HtlcCreate";
import { HtlcExtend } from "./HtlcExtend";
import { HtlcRedeem } from "./HtlcRedeem";
import { HtlcRedeemed } from "./HtlcRedeemed";
import { HtlcRefund } from "./HtlcRefund";

export default function opComponents(opType, props, opts) {
    switch (opType) {
        case "transfer":
            return React.createElement(Transfer, _extends({}, props, opts));
        case "limit_order_create":
            return React.createElement(LimitOrderCreate, _extends({}, props, opts));
        case "limit_order_cancel":
            return React.createElement(LimitOrderCancel, _extends({}, props, opts));

        case "short_order_cancel":
            return React.createElement(ShortOrderCancel, _extends({}, props, opts));

        case "call_order_update":
            return React.createElement(CallOrderUpdate, _extends({}, props, opts));

        case "key_create":
            return React.createElement(KeyCreate, null);

        case "account_create":
            return React.createElement(AccountCreate, _extends({}, props, opts));

        case "account_update":
            return React.createElement(AccountUpdate, _extends({}, props, opts));

        case "account_whitelist":
            return React.createElement(AccountWhitelist, _extends({}, props, opts));

        case "account_upgrade":
            return React.createElement(AccountUpgrade, _extends({}, props, opts));

        case "account_transfer":
            return React.createElement(AccountTransfer, _extends({}, props, opts));

        case "asset_create":
            return React.createElement(AssetCreate, _extends({}, props, opts));

        case "asset_update":
        case "asset_update_bitasset":
            return React.createElement(AssetUpdate, _extends({}, props, opts));
        /* -------------------------------------------------------------------------------------------- */
        case "asset_update_feed_producers":
            return React.createElement(AssetUpdateFeedProducers, _extends({}, props, opts));

        case "asset_issue":
            return React.createElement(AssetIssue, _extends({}, props, opts));

        case "asset_reserve":
            return React.createElement(AssetReserve, _extends({}, props, opts));

        case "asset_fund_fee_pool":
            return React.createElement(AssetFundFeePool, _extends({}, props, opts));

        case "asset_settle":
            return React.createElement(AssetSettle, _extends({}, props, opts));

        case "asset_settle_cancel":
            return React.createElement(AssetSettleCancel, props);

        case "asset_global_settle":
            return React.createElement(AssetGlobalSettle, _extends({}, props, opts));

        case "asset_publish_feed":
            return React.createElement(AssetPublishFeed, _extends({}, props, opts));

        case "asset_claim_pool":
            return React.createElement(AssetClaimPool, _extends({}, props, opts));

        case "asset_update_issuer":
            return React.createElement(AssetUpdateIssuer, props);

        case "witness_create":
            return React.createElement(WitnessCreate, _extends({}, props, opts));

        case "witness_update":
            return React.createElement(WitnessUpdate, _extends({}, props, opts));

        case "witness_withdraw_pay":
            return React.createElement(WitnessWithdrawPay, _extends({}, props, opts));

        case "proposal_create":
            return React.createElement(ProposalCreate, _extends({}, props, opts));

        case "proposal_update":
            return React.createElement(ProposalUpdate, _extends({}, props, opts));

        case "proposal_delete":
            return React.createElement(ProposalDelete, _extends({}, props, opts));

        case "withdraw_permission_create":
            return React.createElement(WithdrawPermissionCreate, _extends({}, props, opts));

        case "withdraw_permission_update":
            return React.createElement(WithdrawPermissionUpdate, _extends({}, props, opts));

        case "withdraw_permission_claim":
            return React.createElement(WithdrawPermissionClaim, _extends({}, props, opts));

        case "withdraw_permission_delete":
            return React.createElement(WithdrawPermissionDelete, _extends({}, props, opts));

        case "fill_order":
            return React.createElement(FillOrder, _extends({}, props, opts));

        case "global_parameters_update":
            return React.createElement(GlobalParametersUpdate, { fromComponent: opts.fromComponent });

        case "file_write":
            return React.createElement(FileWrite, null);

        case "vesting_balance_create":
            return React.createElement(VestingBalanceCreate, _extends({}, props, opts));

        case "vesting_balance_withdraw":
            return React.createElement(VestingBalanceWithdraw, _extends({}, props, opts));

        case "bond_create_offer":
            return React.createElement(BondCreateOffer, props);

        case "bond_cancel_offer":
            return React.createElement(BondCancelOffer, props);

        case "bond_accept_offer":
            return React.createElement(BondAcceptOffer, _extends({}, props, opts));

        case "bond_claim_collateral":
            return React.createElement(BondClaimCollaterial, _extends({}, props, opts));

        case "worker_create":
            return React.createElement(WorkerCreate, _extends({}, props, opts));

        case "balance_claim":
            return React.createElement(BalanceClaim, _extends({}, props, opts));

        case "committee_member_create":
            return React.createElement(CommittyMemberCreate, _extends({}, props, opts));

        case "transfer_to_blind":
            return React.createElement(TransferToBlind, _extends({}, props, opts));

        case "transfer_from_blind":
            return React.createElement(TransferFromBlind, _extends({}, props, opts));

        case "asset_claim_fees":
            return React.createElement(AssetClaimFees, _extends({}, props, opts));

        case "committee_member_update_global_parameters":
            return React.createElement(CommitteeMemberUpdateGlobalParams, {
                fromComponent: opts.fromComponent
            });

        case "custom":
            return React.createElement(Custom, { fromComponent: opts.fromComponent });

        case "override_transfer":
            return React.createElement(OverrideTransfer, _extends({}, props, opts));

        case "bid_collateral":
            return React.createElement(BidCollateral, props);

        case "htlc_create":
            return React.createElement(HtlcCreate, _extends({}, props, opts));

        case "htlc_redeem":
            return React.createElement(HtlcRedeem, _extends({}, props, opts));

        case "htlc_redeemed":
            return React.createElement(HtlcRedeemed, _extends({}, props, opts));

        case "htlc_extend":
            return React.createElement(HtlcExtend, _extends({}, props, opts));

        case "htlc_refund":
            return React.createElement(HtlcRefund, _extends({}, props, opts));

        default:
            return React.createElement(DefaultOperation, props);
    }
}