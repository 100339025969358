import { getFaucet } from "../branding";

export var xbtsxAPIs = {
    //BASE: "http://localhost:3080/api/v2", //for tests local
    BASE: "https://apis.xbts.io/api/v2", // new !!!
    COINS_LIST: "/coin"
};

export var wavesAPIs = {
    BASE: "https://apis.xbts.io/waves",
    COINS_LIST: "/coin"
};

export var bnbAPIs = {
    BASE: "https://apis.xbts.io/bnb",
    //BASE: "http://localhost:3080/bnb", //for tests local
    COINS_LIST: "/coin"
};

export var bscAPIs = {
    BASE: "https://apis.xbts.io/bsc",
    COINS_LIST: "/coin"
};

export var ethAPIs = {
    BASE: "https://apis.xbts.io/eth",
    //BASE: "http://localhost:3080/eth", //for tests local
    COINS_LIST: "/coin"
};

export var hecoAPIs = {
    BASE: "https://apis.xbts.io/heco",
    COINS_LIST: "/coin"
};

export var eosAPIs = {
    BASE: "https://apis.xbts.io/eos",
    COINS_LIST: "/coin"
};

export var blockTradesAPIs = {
    BASE: "https://api.blocktrades.us/v2",
    COINS_LIST: "/coins",
    ACTIVE_WALLETS: "/active-wallets",
    TRADING_PAIRS: "/trading-pairs",
    DEPOSIT_LIMIT: "/deposit-limits",
    ESTIMATE_OUTPUT: "/estimate-output-amount",
    ESTIMATE_INPUT: "/estimate-input-amount"
};

export var openledgerAPIs = {
    BASE: "https://ol-api1.openledger.info/api/v0/ol/support",
    COINS_LIST: "/coins",
    ACTIVE_WALLETS: "/active-wallets",
    TRADING_PAIRS: "/trading-pairs",
    DEPOSIT_LIMIT: "/deposit-limits",
    ESTIMATE_OUTPUT: "/estimate-output-amount",
    ESTIMATE_INPUT: "/estimate-input-amount",
    RPC_URL: "https://openledger.info/api/"
};

export var rudexAPIs = {
    BASE: "https://gateway.rudex.org/api/v3_0",
    COINS_LIST: "/coins",
    NEW_DEPOSIT_ADDRESS: "/simple-api/initiate-trade"
};

export var citadelAPIs = {
    BASE: "https://citadel.li/trade",
    COINS_LIST: "/coins",
    ACTIVE_WALLETS: "/active-wallets",
    TRADING_PAIRS: "/trading-pairs",
    DEPOSIT_LIMIT: "/deposit-limits",
    ESTIMATE_OUTPUT: "/estimate-output-amount",
    ESTIMATE_INPUT: "/estimate-input-amount"
};

export var gdex2APIs = {
    BASE: "https://api.gdex.io/adjust",
    COINS_LIST: "/coins",
    ACTIVE_WALLETS: "/active-wallets",
    TRADING_PAIRS: "/trading-pairs"
};

// Legacy Deposit/Withdraw
export var gdexAPIs = {
    BASE: "https://api.gdex.io",
    ASSET_LIST: "/gateway/asset/assetList",
    ASSET_DETAIL: "/gateway/asset/assetDetail",
    GET_DEPOSIT_ADDRESS: "/gateway/address/getAddress",
    CHECK_WITHDRAY_ADDRESS: "/gateway/address/checkAddress",
    DEPOSIT_RECORD_LIST: "/gateway/deposit/recordList",
    DEPOSIT_RECORD_DETAIL: "/gateway/deposit/recordDetail",
    WITHDRAW_RECORD_LIST: "/gateway/withdraw/recordList",
    WITHDRAW_RECORD_DETAIL: "/gateway/withdraw/recordDetail",
    GET_USER_INFO: "/gateway/user/getUserInfo",
    USER_AGREEMENT: "/gateway/user/isAgree",
    WITHDRAW_RULE: "/gateway/withdraw/rule"
};

export var nodeRegions = [
// region of the node follows roughly https://en.wikipedia.org/wiki/Subregion#/media/File:United_Nations_geographical_subregions.png
"Northern Europe", "Western Europe", "Southern Europe", "Eastern Europe", "Northern Asia", "Western Asia", "Southern Asia", "Eastern Asia", "Central Asia", "Southeastern Asia", "Australia", "New Zealand", "Melanesia", "Polynesia", "Micronesia", "Northern Africa", "Western Africa", "Middle Africa", "Eastern Africa", "Southern Africa", "Northern America", "Central America", "Caribbean", "South America"];

export var settingsAPIs = {
    // If you want a location to be translated, add the translation to settings in locale-xx.js
    // and use an object {translate: key} in WS_NODE_LIST
    DEFAULT_WS_NODE: "wss://fake.automatic-selection.com",
    WS_NODE_LIST: [{
        url: "wss://fake.automatic-selection.com",
        location: { translate: "settings.api_closest" }
    }, {
        url: "ws://127.0.0.1:8090",
        location: "Locally hosted"
    }, {
        url: "wss://dex.iobanker.com/ws",
        region: "Western Europe",
        country: "Germany",
        location: "Frankfurt",
        operator: "Witness: iobanker-core",
        contact: "email:admin@iobanker.com"
    }, {
        url: "wss://bit.btsabc.org/ws",
        region: "Eastern Asia",
        country: "China",
        location: "Hong Kong",
        operator: "Witness: abc123",
        contact: "QQ:58291;email:58291@qq.com"
    }, {
        url: "wss://ws.gdex.top/ws",
        region: "Eastern Asia",
        country: "China",
        location: "Shanghai",
        operator: "Witness: gdex-witness",
        contact: "telegram:BrianZhang"
    }, {
        url: "wss://api.weaccount.cn",
        region: "Eastern Asia",
        country: "China",
        location: "Hangzhou",
        operator: "Witness: btspp-witness",
        contact: "telegram:btsplusplus"
    }, {
        url: "wss://nexus01.co.uk/ws",
        region: "Northern Europe",
        country: "England",
        location: "Gloucester",
        operator: "Witness: nexus01",
        contact: "telegram: nexus01"
    }, {
        url: "wss://blockzms.xyz/ws",
        region: "North America",
        country: "U.S.A.",
        location: "New Jersey",
        operator: "Witness: delegate-zhaomu",
        contact: "telegram:lzmlam;wechat:lzmlam"
    }, {
        url: "wss://eu.nodes.bitshares.ws",
        region: "Western Europe",
        country: "Germany",
        operator: "Infrastructure Worker",
        contact: "email:info@blockchainprojectsbv.com"
    }, {
        url: "wss://us.nodes.bitshares.ws",
        region: "North America",
        country: "U.S.A.",
        operator: "Infrastructure Worker",
        contact: "email:info@blockchainprojectsbv.com"
    }, {
        url: "wss://hk.nodes.bitshares.ws",
        region: "Eastern Asia",
        country: "Hong Kong",
        operator: "Infrastructure Worker",
        contact: "email:info@blockchainprojectsbv.com"
    }, {
        url: "wss://api.bts.mobi/ws",
        region: "Northern America",
        country: "U.S.A.",
        location: "Virginia",
        operator: "Witness: in.abit",
        contact: "telegram:abitmore"
    }, {
        url: "wss://btsws.roelandp.nl/ws",
        region: "Northern Europe",
        country: "Finland",
        location: "Helsinki",
        operator: "Witness: roelandp",
        contact: "telegram:roelandp"
    }, {
        url: "wss://api.bitshares.bhuz.info/ws",
        region: "Northern America",
        country: "Canada",
        operator: "Witness: bhuz",
        contact: "telegram:bhuzor"
    }, {
        url: "wss://api.btsgo.net/ws",
        region: "Asia",
        location: "Singapore",
        operator: "Witness: xn-delegate",
        contact: "wechat:Necklace"
    }, {
        url: "wss://bts.open.icowallet.net/ws",
        region: "Eastern Asia",
        country: "China",
        location: "Hangzhou",
        operator: "Witness: magicwallet.witness",
        contact: "telegram:plus_wave"
    }, {
        url: "wss://freedom.bts123.cc:15138/",
        region: "South China",
        country: "China",
        location: "Changsha",
        operator: "Witness: delegate.freedom",
        contact: "telegram:eggplant"
    }, {
        url: "wss://bitshares.bts123.cc:15138/",
        region: "North China",
        country: "China",
        location: "Hangzhou",
        operator: "Witness: delegate.freedom",
        contact: "telegram:eggplant"
    }, {
        url: "wss://api.bts.ai",
        region: "Eastern Asia",
        country: "China",
        location: "Beijing",
        operator: "Witness: witness.hiblockchain",
        contact: "telegram:vianull;wechat:strugglingl"
    }, {
        url: "wss://api.61bts.com",
        region: "Eastern Asia",
        country: "China",
        location: "Shandong",
        operator: "Witness: liuye",
        contact: "email:work@domyself.me"
    }, {
        url: "wss://btsfullnode.bangzi.info/ws",
        region: "Western Europe",
        country: "Germany",
        location: "Munich",
        operator: "Witness: Bangzi",
        contact: "telegram:Bangzi"
    }, {
        url: "wss://api.dex.trading/",
        region: "Western Europe",
        country: "France",
        location: "Paris",
        operator: "Witness: zapata42-witness",
        contact: "telegram:Zapata_42"
    }, {
        url: "wss://singapore.asia.api.bitshares.org/ws",
        region: "Asia",
        country: "Singapore",
        location: "Singapore",
        operator: "'Exotic flavors' infra worker",
        contact: "telegram:murda_ra;email:hostmaster@bitshares.org"
    }, {
        url: "wss://public.xbts.io/ws",
        region: "Europe",
        country: "Germany",
        location: "Nürnberg",
        operator: "Witness: xbtsio-wallet",
        contact: "telegram: xbtsio"
    }, {
        url: "wss://cloud.xbts.io/ws",
        region: "Northern America",
        country: "U.S.A.",
        location: "VG, Ashburn",
        operator: "Witness: xbtsio-wallet",
        contact: "telegram: xbtsio"
    }, {
        url: "wss://node.xbts.io/ws",
        region: "Europe",
        country: "Germany",
        location: "Falkenstein",
        operator: "Witness: xbtsio-wallet",
        contact: "telegram: xbtsio"
    },

    // Testnet
    {
        url: "wss://node.testnet.bitshares.eu",
        region: "TESTNET - Western Europe",
        country: "Germany",
        location: "Frankfurt",
        operator: "BitShares Europe",
        contact: "telegram:xeroc"
    }, {
        url: "wss://testnet.nodes.bitshares.ws",
        region: "TESTNET - Western Europe",
        country: "Germany",
        location: "Nuremberg",
        operator: "Infrastructure Worker",
        contact: "email:info@blockchainprojectsbv.com"
    }, {
        url: "wss://testnet.dex.trading/",
        region: "TESTNET - Western Europe",
        country: "France",
        location: "Paris",
        operator: "Witness: zapata42-witness",
        contact: "telegram:Zapata_42"
    }, {
        url: "wss://testnet.xbts.io/ws",
        region: "TESTNET - Europe",
        country: "Germany",
        location: "Nürnberg",
        operator: "Witness: xbtsio-wallet",
        contact: "telegram: xbtsio"
    }],
    ES_WRAPPER_LIST: [{
        url: "https://wrapper.elasticsearch.bitshares.ws",
        region: "Western Europe",
        country: "Germany",
        operator: "Infrastructure Worker",
        contact: "email:info@blockchainprojectsbv.com"
    }],
    DEFAULT_FAUCET: getFaucet().url,
    TESTNET_FAUCET: "https://faucet.testnet.bitshares.eu"
};