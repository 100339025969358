var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from "react";
import PropTypes from "prop-types";
import QRCode from "qrcode.react";

/**
 *  Given an asset name, amount and label generates uri for its refilling
 *
 */

var CryptoLinkFormatter = function (_React$Component) {
    _inherits(CryptoLinkFormatter, _React$Component);

    function CryptoLinkFormatter(params) {
        _classCallCheck(this, CryptoLinkFormatter);

        var _this = _possibleConstructorReturn(this, (CryptoLinkFormatter.__proto__ || Object.getPrototypeOf(CryptoLinkFormatter)).call(this, params));

        _this.assetTemplates = {
            BTS: {
                template: "{address}",
                params: []
            },
            BTC: {
                template: "bitcoin:{address}", // template of the link with optional variables - {address} or other component's properties names in curly braces
                params: [
                // list of parameters appended to link above in the manner of HTTP GET query parameters : ?name=value&name2=value2
                {
                    // parameters now supports two props: optional `name` - parameter's name (if its not equal to bound property name) and `bind` - actual property of the component
                    // name: "value"
                    bind: "amount" // components property name, value of which would be assigned to this parameter. In this particullary case we would get &amount=<component's `amount` property value>
                }, {
                    // message=<message>
                    bind: "message"
                }]
            },
            LTC: {
                template: "litecoin:{address}",
                params: [{
                    // &amount=<amount>
                    bind: "amount"
                }, {
                    // &message=<message>
                    bind: "message"
                }]
            },
            ETH: {
                template: "ethereum:{address}",
                params: [{
                    // &value=<amount>
                    name: "value", // name of the parameter. if not provided - bind param name would be set as name
                    bind: "amount" // actual param value got from components props
                }, {
                    // &message=<message>
                    bind: "message"
                }]
            },
            BCH: {
                template: "bitcoincash:{address}",
                params: [{
                    bind: "amount"
                }, {
                    bind: "message"
                }]
            }
        };
        return _this;
    }

    _createClass(CryptoLinkFormatter, [{
        key: "render",
        value: function render() {
            var _props = this.props,
                size = _props.size,
                asset = _props.asset;

            var conf = this.props;
            var assetTemplate = this.assetTemplates[asset];

            var error = false;
            if (typeof assetTemplate != "undefined") {
                // template handling
                var link = assetTemplate.template.replace(/{([a-zA-Z0-9]+)}/g, function (match, tokenName) {
                    if (tokenName in conf) {
                        return conf[tokenName];
                    } else {
                        // some variable required by template was not found  - can't proceed next
                        error = true;
                        return true;
                    }
                });

                // if error encountered - its better not to show any broken qr
                if (error) {
                    return "";
                }

                // query param handling
                if (assetTemplate.params.length > 0) {
                    var parameters = [];

                    assetTemplate.params.forEach(function (parameter) {
                        var name = "";

                        if (typeof parameter["name"] != "undefined") {
                            name = parameter["name"];
                        }

                        if (name == "") {
                            name = parameter["bind"];
                        }

                        if (typeof conf[parameter["bind"]] !== "undefined") {
                            parameters.push(name + "=" + conf[parameter["bind"]]);
                        }
                    });

                    if (parameters.length > 0) {
                        link += "?" + parameters.join("&");
                    }
                }

                return React.createElement(
                    "div",
                    { className: "QR" },
                    React.createElement(QRCode, { size: size, value: link })
                );
            } else {
                return "";
            }
        }
    }]);

    return CryptoLinkFormatter;
}(React.Component);

CryptoLinkFormatter.assetTemplates = {};
CryptoLinkFormatter.propTypes = {
    asset: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    amount: PropTypes.number,
    message: PropTypes.string,
    size: PropTypes.number
};
CryptoLinkFormatter.defaultProps = {
    size: 140
};


export default CryptoLinkFormatter;