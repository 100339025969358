var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from "react";
import { Link } from "react-router-dom";
import { connect } from "alt-react";
import SettingsStore from "stores/SettingsStore";
import Translate from "react-translate-component";

var light = require("assets/logo-404-light.png");
var dark = require("assets/logo-404-dark.png");
var midnight = require("assets/logo-404-midnight.png");

var Page404 = function (_React$Component) {
    _inherits(Page404, _React$Component);

    function Page404() {
        _classCallCheck(this, Page404);

        return _possibleConstructorReturn(this, (Page404.__proto__ || Object.getPrototypeOf(Page404)).apply(this, arguments));
    }

    _createClass(Page404, [{
        key: "render",
        value: function render() {
            var logo = void 0;

            if (this.props.theme === "lightTheme") {
                logo = light;
            }

            if (this.props.theme === "darkTheme") {
                logo = dark;
            }

            if (this.props.theme === "midnightTheme") {
                logo = midnight;
            }

            return React.createElement(
                "div",
                { className: "page-404" },
                React.createElement(
                    "div",
                    { className: "page-404-container" },
                    React.createElement(
                        "div",
                        { className: "page-404-logo" },
                        React.createElement("img", { src: logo, alt: "Logo" })
                    ),
                    React.createElement(
                        "div",
                        { className: "page-404-title" },
                        React.createElement(Translate, { content: "page404.page_not_found_title" })
                    ),
                    React.createElement(
                        "div",
                        { className: "page-404-subtitle" },
                        React.createElement(Translate, { content: "page404." + this.props.subtitle })
                    ),
                    React.createElement(
                        "div",
                        { className: "page-404-button-back" },
                        React.createElement(
                            Link,
                            { to: "/" },
                            React.createElement(Translate, {
                                component: "button",
                                className: "button",
                                content: "page404.home"
                            })
                        )
                    )
                )
            );
        }
    }]);

    return Page404;
}(React.Component);

Page404.defaultProps = {
    subtitle: "page_not_found_subtitle"
};


export default Page404 = connect(Page404, {
    listenTo: function listenTo() {
        return [SettingsStore];
    },
    getProps: function getProps() {
        return {
            theme: SettingsStore.getState().settings.get("themes")
        };
    }
});