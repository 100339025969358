var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from "react";
import PropTypes from "prop-types";
import Translate from "react-translate-component";
import AltContainer from "alt-container";
import ReactTooltip from "react-tooltip";
import WalletDb from "stores/WalletDb";
import AccountStore from "stores/AccountStore";
import WalletUnlockActions from "actions/WalletUnlockActions";
import AccountActions from "actions/AccountActions";
import SettingsActions from "actions/SettingsActions";
import utils from "common/utils";
import ChainStore from "bitsharesjs/es/chain/src/ChainStore";
import AccountInputStyleGuide from "../Account/AccountInputStyleGuide";
import { Button, Input, Form } from "bitshares-ui-style-guide";
import counterpart from "counterpart";

var AccountLogin = function (_React$Component) {
    _inherits(AccountLogin, _React$Component);

    function AccountLogin(props) {
        _classCallCheck(this, AccountLogin);

        var _this = _possibleConstructorReturn(this, (AccountLogin.__proto__ || Object.getPrototypeOf(AccountLogin)).call(this));

        _this.state = _this.getInitialState(props);
        _this.handlePasswordChange = _this.handlePasswordChange.bind(_this);
        _this.onPasswordEnter = _this.onPasswordEnter.bind(_this);
        _this.accountChanged = _this.accountChanged.bind(_this);
        _this.onAccountChanged = _this.onAccountChanged.bind(_this);
        return _this;
    }

    _createClass(AccountLogin, [{
        key: "getInitialState",
        value: function getInitialState() {
            var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.props;

            return {
                password: "",
                passwordError: null,
                accountName: props.passwordAccount,
                account: null,
                passwordVisible: false
            };
        }
    }, {
        key: "componentDidUpdate",
        value: function componentDidUpdate(previousProps) {
            ReactTooltip.rebuild();

            if (!previousProps.active && this.props.active && this.state.accountName) {
                this.refs.password.focus();
            }
        }
    }, {
        key: "componentWillReceiveProps",
        value: function componentWillReceiveProps(np) {
            if (np.passwordAccount && !this.state.accountName) {
                this.setState({
                    accountName: np.passwordAccount
                });
            }
        }
    }, {
        key: "shouldComponentUpdate",
        value: function shouldComponentUpdate(np, ns) {
            return !utils.are_equal_shallow(np, this.props) || !utils.are_equal_shallow(ns, this.state);
        }
    }, {
        key: "handlePasswordChange",
        value: function handlePasswordChange(e) {
            this.setState({
                password: e.target.value
            });
        }
    }, {
        key: "onPasswordEnter",
        value: function onPasswordEnter(e) {
            var _this2 = this;

            e && e.preventDefault();
            var password = this.state.password;
            var account = this.state.accountName;
            this.setState({ passwordError: null });

            WalletDb.validatePassword(password, true, // unlock
            account);

            setTimeout(function () {
                WalletDb.validatePassword(password, true, // unlock
                account);

                if (WalletDb.isLocked()) {
                    _this2.setState({ passwordError: true });
                    return false;
                }
                _this2.setState({
                    password: ""
                });

                AccountActions.setPasswordAccount(account);
                SettingsActions.changeSetting({
                    setting: "passwordLogin",
                    value: true
                });
                _this2.props.history.push("/");
                WalletUnlockActions.change();
            }, 550);

            return false;
        }
    }, {
        key: "onAccountChanged",
        value: function onAccountChanged(account) {
            this.setState({ account: account, error: null });
        }
    }, {
        key: "accountChanged",
        value: function accountChanged(accountName) {
            if (!accountName) {
                this.setState({ account: null, accountName: null });
            } else {
                var account = ChainStore.getAccount(accountName);

                this.setState({
                    accountName: accountName,
                    error: null,
                    account: account
                });
            }
        }
    }, {
        key: "reset",
        value: function reset() {
            this.setState(this.getInitialState());
        }
    }, {
        key: "hideTooltip",
        value: function hideTooltip() {
            document.getElementById("password-error").classList.remove("custom-tooltip");
            ReactTooltip.hide();
        }
    }, {
        key: "renderButtons",
        value: function renderButtons() {
            return React.createElement(
                Form.Item,
                { style: { textAlign: "center" } },
                this.props.active ? React.createElement(
                    Button,
                    { onClick: this.onPasswordEnter, type: "primary" },
                    counterpart.translate("login.loginButton")
                ) : React.createElement(
                    Button,
                    null,
                    counterpart.translate("registration.select")
                )
            );
        }
    }, {
        key: "renderTooltip",
        value: function renderTooltip() {
            var _this3 = this;

            return React.createElement(
                ReactTooltip,
                {
                    id: "password-error",
                    className: "custom-tooltip text-left"
                },
                React.createElement(
                    "div",
                    { className: "tooltip-text" },
                    React.createElement(Translate, { content: "tooltip.login-tooltip.incorrectPassword.begin" }),
                    React.createElement(Translate, {
                        onClick: this.props.goToWalletModel,
                        className: "active-upload-text without-bin cursor-pointer",
                        content: "tooltip.login-tooltip.incorrectPassword.model"
                    }),
                    React.createElement(Translate, { content: "tooltip.login-tooltip.incorrectPassword.end" }),
                    React.createElement(
                        "span",
                        {
                            onClick: function onClick() {
                                return _this3.hideTooltip();
                            },
                            className: "close-button"
                        },
                        "\xD7"
                    )
                )
            );
        }
    }, {
        key: "renderNameInput",
        value: function renderNameInput() {
            var accountName = this.state.accountName;
            var active = this.props.active;


            return React.createElement(AccountInputStyleGuide, {
                label: "account.name",
                value: accountName,
                onChange: this.accountChanged,
                placeholder: "account.name",
                size: 60,
                hideImage: true,
                focus: active && !this.state.accountName
            });
        }
    }, {
        key: "renderPasswordInput",
        value: function renderPasswordInput() {
            var _state = this.state,
                passwordError = _state.passwordError,
                passwordVisible = _state.passwordVisible;


            var getValidateStatus = function getValidateStatus() {
                return passwordError !== null ? "error" : "";
            };

            var getHelp = function getHelp() {
                return passwordError !== null ? React.createElement(Translate, {
                    "data-for": "password-error",
                    "data-tip": true,
                    "data-place": "bottom",
                    "data-effect": "solid",
                    "data-delay-hide": 500,
                    content: "wallet.pass_incorrect"
                }) : null;
            };

            return React.createElement(
                Form.Item,
                {
                    label: "Password",
                    help: getHelp(),
                    validateStatus: getValidateStatus()
                },
                React.createElement(Input, {
                    ref: "password",
                    placeholder: counterpart.translate("wallet.enter_password"),
                    style: { width: "100%" },
                    value: this.state.password,
                    onChange: this.handlePasswordChange,
                    type: !passwordVisible ? "password" : "text",
                    className: (passwordError ? "input-warning" : "") + " input create-account-input"
                })
            );
        }
    }, {
        key: "render",
        value: function render() {
            return React.createElement(
                "div",
                { onClick: this.props.onChangeActive, className: "account-block" },
                React.createElement(
                    "div",
                    { className: "overflow-bg-block show-for-small-only" },
                    React.createElement("span", { className: "content" })
                ),
                React.createElement(
                    Form,
                    {
                        layout: "vertical",
                        className: !this.props.active ? "display-none" : "",
                        style: { textAlign: "left" }
                    },
                    this.renderNameInput(),
                    this.renderPasswordInput(),
                    this.renderButtons()
                ),
                this.renderTooltip()
            );
        }
    }]);

    return AccountLogin;
}(React.Component);

AccountLogin.propTypes = {
    active: PropTypes.bool.isRequired,
    onChangeActive: PropTypes.func.isRequired,
    goToWalletModel: PropTypes.func.isRequired
};


function AccountLoginContainer(props) {
    return React.createElement(
        AltContainer,
        {
            stores: [AccountStore],
            inject: {
                passwordAccount: function passwordAccount() {
                    return AccountStore.getState().passwordAccount || "";
                }
            }
        },
        React.createElement(AccountLogin, props)
    );
}

export default AccountLoginContainer;