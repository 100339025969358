import React from "react";
import TranslateWithLinks from "../../Utility/TranslateWithLinks";

export var AssetUpdateFeedProducers = function AssetUpdateFeedProducers(_ref) {
    var op = _ref.op,
        changeColor = _ref.changeColor,
        fromComponent = _ref.fromComponent;

    changeColor("warning");

    if (fromComponent === "proposed_operation") {
        return React.createElement(TranslateWithLinks, {
            string: "proposal.feed_producer",
            keys: [{
                type: "account",
                value: op[1].issuer,
                arg: "account"
            }, {
                type: "asset",
                value: op[1].asset_to_update,
                arg: "asset"
            }]
        });
    } else {
        return React.createElement(
            "span",
            null,
            React.createElement(TranslateWithLinks, {
                string: "operation.asset_update_feed_producers",
                keys: [{
                    type: "account",
                    value: op[1].issuer,
                    arg: "account"
                }, {
                    type: "asset",
                    value: op[1].asset_to_update,
                    arg: "asset"
                }]
            })
        );
    }
};