import React from "react";
import BindToChainState from "../../Utility/BindToChainState";
import TranslateWithLinks from "../../Utility/TranslateWithLinks";

export var AssetClaimFees = function AssetClaimFees(_ref) {
    var op = _ref.op,
        changeColor = _ref.changeColor,
        linkToAccount = _ref.linkToAccount;

    changeColor("success");
    op[1].amount_to_claim.amount = parseInt(op[1].amount_to_claim.amount, 10);

    return React.createElement(
        "span",
        null,
        linkToAccount(op[1].issuer),
        "\xA0",
        React.createElement(
            BindToChainState.Wrapper,
            { asset: op[1].amount_to_claim.asset_id },
            function (_ref2) {
                var asset = _ref2.asset;
                return React.createElement(TranslateWithLinks, {
                    string: "transaction.asset_claim_fees",
                    keys: [{
                        type: "amount",
                        value: op[1].amount_to_claim,
                        arg: "balance_amount"
                    }, {
                        type: "asset",
                        value: asset.get("id"),
                        arg: "asset"
                    }]
                });
            }
        )
    );
};