var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from "react";
import Translate from "react-translate-component";
import AccountLogin from "./AccountLogin";
import WalletLogin from "./WalletLogin";
import WalletHeaderSelection from "../Registration/WalletHeaderSelection";
import AccountHeaderSelection from "../Registration/AccountHeaderSelection";

var Login = function (_React$Component) {
    _inherits(Login, _React$Component);

    function Login() {
        _classCallCheck(this, Login);

        var _this = _possibleConstructorReturn(this, (Login.__proto__ || Object.getPrototypeOf(Login)).call(this));

        _this.state = {
            activeWalletModel: true
        };
        return _this;
    }

    _createClass(Login, [{
        key: "changeActiveModel",
        value: function changeActiveModel(isActiveWallet) {
            this.setState({ activeWalletModel: isActiveWallet });
        }
    }, {
        key: "render",
        value: function render() {
            var _this2 = this;

            var activeWalletModel = this.state.activeWalletModel;

            return React.createElement(
                "div",
                { className: "grid-block align-center registration-layout" },
                React.createElement(
                    "div",
                    { className: "grid-block shrink vertical text-center registration-selector" },
                    React.createElement(Translate, {
                        content: "login.title",
                        component: "p",
                        className: "registration-title"
                    }),
                    React.createElement(
                        "div",
                        null,
                        React.createElement(
                            "div",
                            { className: "v-align login-page-selector" },
                            React.createElement(
                                "div",
                                {
                                    className: (!activeWalletModel ? "inactive-model-block" : "") + " selection-block align-center plate"
                                },
                                React.createElement(
                                    "div",
                                    { className: "small-horizontal small-only-block" },
                                    React.createElement(WalletHeaderSelection, {
                                        active: activeWalletModel,
                                        onChangeActive: function onChangeActive() {
                                            return _this2.changeActiveModel(true);
                                        },
                                        loginPage: true
                                    }),
                                    React.createElement(AccountHeaderSelection, {
                                        active: !activeWalletModel,
                                        onChangeActive: function onChangeActive() {
                                            return _this2.changeActiveModel(false);
                                        },
                                        forSmall: true,
                                        loginPage: true
                                    })
                                ),
                                React.createElement(WalletLogin, {
                                    active: activeWalletModel,
                                    onChangeActive: function onChangeActive() {
                                        return !activeWalletModel ? _this2.changeActiveModel(true) : null;
                                    },
                                    goToAccountModel: function goToAccountModel() {
                                        return _this2.changeActiveModel(false);
                                    },
                                    history: this.props.history
                                })
                            ),
                            React.createElement(
                                "div",
                                {
                                    className: (activeWalletModel ? "inactive-model-block" : "") + " selection-block align-center plate"
                                },
                                React.createElement(
                                    "div",
                                    { className: "small-horizontal small-only-block" },
                                    React.createElement(WalletHeaderSelection, {
                                        active: activeWalletModel,
                                        onChangeActive: function onChangeActive() {
                                            return _this2.changeActiveModel(true);
                                        },
                                        forSmall: true,
                                        loginPage: true
                                    }),
                                    React.createElement(AccountHeaderSelection, {
                                        active: !activeWalletModel,
                                        onChangeActive: function onChangeActive() {
                                            return _this2.changeActiveModel(false);
                                        },
                                        loginPage: true
                                    })
                                ),
                                React.createElement(AccountLogin, {
                                    active: !activeWalletModel,
                                    history: this.props.history,
                                    onChangeActive: function onChangeActive() {
                                        return activeWalletModel ? _this2.changeActiveModel(false) : null;
                                    },
                                    goToWalletModel: function goToWalletModel() {
                                        return _this2.changeActiveModel(true);
                                    }
                                })
                            )
                        )
                    )
                )
            );
        }
    }]);

    return Login;
}(React.Component);

export default Login;