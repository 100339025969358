var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from "react";
import { connect } from "alt-react";
import BlockchainStore from "stores/BlockchainStore";
import SettingsStore from "stores/SettingsStore";
import Translate from "react-translate-component";
import WebsocketAddModal from "./Settings/WebsocketAddModal";
import SettingsActions from "actions/SettingsActions";
import { Apis } from "bitsharesjs-ws";
import { Form, Select, Button, Input } from "bitshares-ui-style-guide";
import counterpart from "counterpart";

var optionalApis = { enableCrypto: true, enableOrders: true };

var InitError = function (_React$Component) {
    _inherits(InitError, _React$Component);

    function InitError(props) {
        _classCallCheck(this, InitError);

        var _this = _possibleConstructorReturn(this, (InitError.__proto__ || Object.getPrototypeOf(InitError)).call(this, props));

        _this.state = {
            isModalVisible: false
        };

        _this.handleModalClose = _this.handleModalClose.bind(_this);
        return _this;
    }

    _createClass(InitError, [{
        key: "componentWillReceiveProps",
        value: function componentWillReceiveProps(nextProps) {
            if (nextProps.rpc_connection_status === "open" && nextProps.apiServer !== this.props.apiServer) {
                SettingsActions.showWS(nextProps.apiServer);
            }
        }
    }, {
        key: "handleModalClose",
        value: function handleModalClose() {
            this.setState({
                isModalVisible: false
            });
        }
    }, {
        key: "triggerModal",
        value: function triggerModal(e) {
            this.setState({
                isModalVisible: true
            });
        }
    }, {
        key: "onChangeWS",
        value: function onChangeWS(value) {
            SettingsActions.changeSetting({
                setting: "apiServer",
                value: value
            });
            Apis.reset(value, true, 4000, optionalApis);
        }
    }, {
        key: "onReloadClick",
        value: function onReloadClick(e) {
            if (e) {
                e.preventDefault();
            }
            if (window.electron) {
                window.location.hash = "";
                window.remote.getCurrentWindow().reload();
            } else window.location.href = __BASE_URL__;
        }
    }, {
        key: "onReset",
        value: function onReset() {
            SettingsActions.changeSetting({
                setting: "apiServer",
                value: this.props.defaultConnection
            });
            SettingsActions.clearSettings();
        }
    }, {
        key: "render",
        value: function render() {
            var uniqueNodes = this.props.apis.reduce(function (a, node) {
                // node is the minimum requirement of filled data to connect
                if (!!node && !!node.url) {
                    var exists = a.findIndex(function (n) {
                        return n.url === node.url;
                    }) !== -1;
                    if (!exists) a.push(node);
                }
                return a;
            }, []);

            var selectOptions = uniqueNodes.map(function (entry) {
                var onlyDescription = entry.url.indexOf("fake.automatic-selection") !== -1;
                var location = entry.location;

                if (!!location && (typeof location === "undefined" ? "undefined" : _typeof(location)) === "object" && "translate" in location) location = counterpart.translate(location.translate);

                return React.createElement(
                    Select.Option,
                    { key: entry.url, value: entry.url },
                    location || entry.url,
                    " ",
                    !onlyDescription && location ? "(" + entry.url + ")" : null
                );
            });

            return React.createElement(
                "div",
                { className: "grid-block" },
                React.createElement(
                    "div",
                    { className: "grid-container" },
                    React.createElement(
                        "div",
                        { className: "grid-content no-overflow" },
                        React.createElement("br", null),
                        React.createElement(Translate, { component: "h3", content: "app_init.title" }),
                        React.createElement(
                            Form,
                            { layout: "vertical" },
                            React.createElement(
                                Form.Item,
                                {
                                    label: counterpart.translate("settings.apiServer")
                                },
                                React.createElement(
                                    Input.Group,
                                    { compact: true },
                                    React.createElement(
                                        Select,
                                        {
                                            style: { width: "calc(100% - 175px)" },
                                            onChange: this.onChangeWS.bind(this),
                                            value: this.props.apiServer
                                        },
                                        selectOptions
                                    ),
                                    React.createElement(
                                        Button,
                                        {
                                            id: "add",
                                            style: { width: "175px" },
                                            onClick: this.triggerModal.bind(this),
                                            icon: "plus"
                                        },
                                        counterpart.translate("settings.add_api")
                                    )
                                )
                            ),
                            React.createElement(
                                Form.Item,
                                {
                                    label: counterpart.translate("app_init.ws_status")
                                },
                                this.props.rpc_connection_status === "open" ? React.createElement(
                                    "span",
                                    { className: "txtlabel success" },
                                    React.createElement(Translate, {
                                        content: "app_init.connected"
                                    })
                                ) : React.createElement(
                                    "span",
                                    { className: "txtlabel warning" },
                                    React.createElement(Translate, {
                                        content: "app_init.not_connected"
                                    })
                                )
                            ),
                            React.createElement(
                                Button,
                                {
                                    type: "primary",
                                    onClick: this.onReloadClick
                                },
                                counterpart.translate("app_init.retry")
                            ),
                            React.createElement(
                                Button,
                                {
                                    style: { marginLeft: "16px" },
                                    onClick: this.onReset.bind(this)
                                },
                                counterpart.translate("settings.reset")
                            )
                        ),
                        React.createElement(WebsocketAddModal, {
                            ref: "ws_modal",
                            isAddNodeModalVisible: this.state.isModalVisible,
                            onAddNodeClose: this.handleModalClose,
                            apis: this.props.apis
                        })
                    )
                )
            );
        }
    }]);

    return InitError;
}(React.Component);

export default connect(InitError, {
    listenTo: function listenTo() {
        return [BlockchainStore, SettingsStore];
    },
    getProps: function getProps() {
        return {
            rpc_connection_status: BlockchainStore.getState().rpc_connection_status,
            apis: SettingsStore.getState().defaults.apiServer,
            apiServer: SettingsStore.getState().settings.get("apiServer"),
            defaultConnection: SettingsStore.getState().defaultSettings.get("apiServer")
        };
    }
});