import React from "react";
import Translate from "react-translate-component";

export var CommittyMemberCreate = function CommittyMemberCreate(_ref) {
    var op = _ref.op,
        linkToAccount = _ref.linkToAccount,
        fromComponent = _ref.fromComponent;

    return React.createElement(
        "span",
        null,
        React.createElement(Translate, {
            component: "span",
            content: fromComponent === "proposed_operation" ? "proposal.committee_member_create" : "transaction.committee_member_create"
        }),
        "\xA0",
        linkToAccount(op[1].committee_member_account)
    );
};