var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from "react";
import PrivateKeyStore from "stores/PrivateKeyStore";
import WalletUnlockActions from "actions/WalletUnlockActions";
import counterpart from "counterpart";
import Icon from "../Icon/Icon";
import { connect } from "alt-react";
import WalletUnlockStore from "stores/WalletUnlockStore";
import utils from "common/utils";
import ReactTooltip from "react-tooltip";
import { Tooltip } from "bitshares-ui-style-guide";
import sanitize from "sanitize";

var MemoText = function (_React$Component) {
    _inherits(MemoText, _React$Component);

    function MemoText() {
        _classCallCheck(this, MemoText);

        return _possibleConstructorReturn(this, (MemoText.__proto__ || Object.getPrototypeOf(MemoText)).apply(this, arguments));
    }

    _createClass(MemoText, [{
        key: "shouldComponentUpdate",
        value: function shouldComponentUpdate(nextProps) {
            return !utils.are_equal_shallow(nextProps.memo, this.props.memo) || nextProps.wallet_locked !== this.props.wallet_locked;
        }
    }, {
        key: "componentDidMount",
        value: function componentDidMount() {
            ReactTooltip.rebuild();
        }
    }, {
        key: "_toggleLock",
        value: function _toggleLock(e) {
            e.preventDefault();
            WalletUnlockActions.unlock().then(function () {
                ReactTooltip.rebuild();
            }).catch(function () {});
        }
    }, {
        key: "render",
        value: function render() {
            var _props = this.props,
                memo = _props.memo,
                fullLength = _props.fullLength;

            if (!memo) {
                return null;
            }

            var _PrivateKeyStore$deco = PrivateKeyStore.decodeMemo(memo),
                text = _PrivateKeyStore$deco.text,
                isMine = _PrivateKeyStore$deco.isMine;

            if (!text && isMine) {
                return React.createElement(
                    "div",
                    { className: "memo" },
                    React.createElement(
                        "span",
                        null,
                        counterpart.translate("transfer.memo_unlock"),
                        " "
                    ),
                    React.createElement(
                        "a",
                        { onClick: this._toggleLock.bind(this) },
                        React.createElement(Icon, { name: "locked", title: "icons.locked.action" })
                    )
                );
            }

            text = utils.sanitize(text);

            var full_memo = text;
            if (text && !fullLength && text.length > 35) {
                text = text.substr(0, 35) + "...";
            }

            if (text) {
                return React.createElement(
                    "div",
                    { className: "memo", style: { paddingTop: 5, cursor: "help" } },
                    React.createElement(
                        Tooltip,
                        {
                            placement: "bottom",
                            title: full_memo !== text ? full_memo : null
                        },
                        React.createElement(
                            "span",
                            {
                                className: "inline-block",
                                "data-class": "memo-tip",
                                "data-offset": "{'bottom': 10}"
                            },
                            text
                        )
                    )
                );
            } else {
                return null;
            }
        }
    }]);

    return MemoText;
}(React.Component);

MemoText.defaultProps = {
    fullLength: false
};

var MemoTextStoreWrapper = function (_React$Component2) {
    _inherits(MemoTextStoreWrapper, _React$Component2);

    function MemoTextStoreWrapper() {
        _classCallCheck(this, MemoTextStoreWrapper);

        return _possibleConstructorReturn(this, (MemoTextStoreWrapper.__proto__ || Object.getPrototypeOf(MemoTextStoreWrapper)).apply(this, arguments));
    }

    _createClass(MemoTextStoreWrapper, [{
        key: "render",
        value: function render() {
            return React.createElement(MemoText, this.props);
        }
    }]);

    return MemoTextStoreWrapper;
}(React.Component);

export default connect(MemoTextStoreWrapper, {
    listenTo: function listenTo() {
        return [WalletUnlockStore];
    },
    getProps: function getProps() {
        return {
            wallet_locked: WalletUnlockStore.getState().locked
        };
    }
});