var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from "react";
import MarketsActions from "actions/MarketsActions";
import marketUtils from "common/market_utils";
import utils from "common/utils";

var MarketStatsCheck = function (_React$Component) {
    _inherits(MarketStatsCheck, _React$Component);

    function MarketStatsCheck() {
        _classCallCheck(this, MarketStatsCheck);

        var _this = _possibleConstructorReturn(this, (MarketStatsCheck.__proto__ || Object.getPrototypeOf(MarketStatsCheck)).call(this));

        _this.fromStatsIntervals = {};
        _this.directStatsIntervals = {};
        _this.toStatsInterval = null;
        return _this;
    }

    _createClass(MarketStatsCheck, [{
        key: "_statsChanged",
        value: function _statsChanged() {
            var newStats = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
            var oldStats = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

            if (!newStats.price) return false;else if (!oldStats.price) return true;
            return newStats.volumeBase !== oldStats.volumeBase || !newStats.price.equals(oldStats.price);
        }
    }, {
        key: "_useDirectMarket",
        value: function _useDirectMarket(props) {
            var fromAsset = props.fromAsset,
                toAsset = props.toAsset,
                allMarketStats = props.allMarketStats;

            if (!fromAsset) return false;

            var _marketUtils$getMarke = marketUtils.getMarketName(toAsset, fromAsset),
                directMarket = _marketUtils$getMarke.marketName;

            var directStats = allMarketStats.get(directMarket);

            if (directStats && directStats.volumeBase === 0) return false;

            return true;
        }
    }, {
        key: "_checkDirectMarkets",
        value: function _checkDirectMarkets(props) {
            var _this2 = this;

            var fromAssets = props.fromAssets,
                fromAsset = props.fromAsset,
                toAsset = props.toAsset,
                allMarketStats = props.allMarketStats;

            if (!fromAssets && fromAsset) fromAssets = [fromAsset];

            return fromAssets.filter(function (a) {
                return !!a;
            }).map(function (asset) {
                return _this2._useDirectMarket({
                    fromAsset: asset,
                    toAsset: toAsset,
                    allMarketStats: allMarketStats
                }) ? asset.get("symbol") : null;
            }).filter(function (a) {
                return !!a;
            });
        }
    }, {
        key: "componentWillMount",
        value: function componentWillMount() {
            this._startUpdates(this.props);
        }
    }, {
        key: "componentWillReceiveProps",
        value: function componentWillReceiveProps(np) {
            var currentDirectMarkets = this._checkDirectMarkets(this.props);
            var newDirectMarkets = this._checkDirectMarkets(np);
            if (!utils.are_equal_shallow(currentDirectMarkets, newDirectMarkets)) {
                this._startUpdates(np);
            }

            if (np.toAsset && this.props.asset && this.props.toAsset.get("symbol") !== np.asset.get("symbol")) {
                this._startUpdates(np);
            }
        }
    }, {
        key: "_startUpdates",
        value: function _startUpdates(props) {
            var _this3 = this;

            /* Only run this every x seconds */
            if (!!this.updatesTimer) return;
            this.updatesTimer = setTimeout(function () {
                _this3.updatesTimer = null;
            }, 10 * 1000);
            var coreAsset = props.coreAsset,
                fromAssets = props.fromAssets,
                fromAsset = props.fromAsset,
                toAsset = props.toAsset;

            if (!fromAssets && fromAsset) fromAssets = [fromAsset];

            var directMarkets = fromAssets.map(function (asset) {
                var _marketUtils$getMarke2 = marketUtils.getMarketName(props.toAsset, asset),
                    directMarket = _marketUtils$getMarke2.marketName;

                var useDirectMarket = _this3._useDirectMarket({
                    toAsset: toAsset,
                    fromAsset: asset,
                    allMarketStats: props.allMarketStats
                });

                if (useDirectMarket && toAsset.get("id") !== asset.get("id")) {
                    if (!_this3.directStatsIntervals[directMarket]) {
                        setTimeout(function () {
                            _this3.directStatsIntervals[directMarket] = MarketsActions.getMarketStatsInterval(5 * 60 * 1000, asset, toAsset);
                        }, 50);
                    }
                }
                // else if (this.directStatsIntervals[directMarket]) {
                //     console.log(directMarket, "directStatsIntervals exists, clearing");
                //     this.directStatsIntervals[directMarket]();
                // }

                return useDirectMarket ? directMarket : null;
            }).filter(function (a) {
                return !!a;
            });

            var indirectAssets = fromAssets.filter(function (f) {
                var _marketUtils$getMarke3 = marketUtils.getMarketName(props.toAsset, f),
                    directMarket = _marketUtils$getMarke3.marketName;

                return directMarkets.indexOf(directMarket) === -1;
            });

            if (coreAsset && indirectAssets.length) {
                // From assets
                indirectAssets.forEach(function (asset) {
                    if (asset && asset.get("id") !== coreAsset.get("id")) {
                        var _marketUtils$getMarke4 = marketUtils.getMarketName(coreAsset, asset),
                            marketName = _marketUtils$getMarke4.marketName;

                        if (!_this3.fromStatsIntervals[marketName]) {
                            setTimeout(function () {
                                _this3.fromStatsIntervals[marketName] = MarketsActions.getMarketStatsInterval(5 * 60 * 1000, coreAsset, asset);
                            }, 50);
                        }
                    }
                });

                // To asset
                if (props.toAsset.get("id") !== coreAsset.get("id")) {
                    // wrap this in a timeout to prevent dispatch in the middle of a dispatch
                    this.toStatsInterval = MarketsActions.getMarketStatsInterval(5 * 60 * 1000, coreAsset, props.toAsset);
                }
            }
        }
    }, {
        key: "_stopUpdates",
        value: function _stopUpdates() {
            for (var key in this.fromStatsIntervals) {
                this.fromStatsIntervals[key]();
                delete this.fromStatsIntervals[key];
            }
            for (var _key in this.directStatsIntervals) {
                this.directStatsIntervals[_key]();
                delete this.directStatsIntervals[_key];
            }
            if (this.toStatsInterval) this.toStatsInterval();
            this.toStatsInterval = null;
        }
    }, {
        key: "componentWillUnmount",
        value: function componentWillUnmount() {
            this._stopUpdates();
        }
    }, {
        key: "shouldComponentUpdate",
        value: function shouldComponentUpdate(np) {
            var _this4 = this;

            var _props = this.props,
                fromAsset = _props.fromAsset,
                fromAssets = _props.fromAssets;

            var _marketUtils$getMarke5 = marketUtils.getMarketName(np.toAsset, np.coreAsset),
                toMarket = _marketUtils$getMarke5.marketName;

            if (!fromAssets && fromAsset) fromAssets = [fromAsset];
            function getMarketNames(assets, toAsset) {
                return assets.map(function (asset) {
                    if (!asset) return null;

                    var _marketUtils$getMarke6 = marketUtils.getMarketName(asset, toAsset),
                        marketName = _marketUtils$getMarke6.marketName;

                    return marketName;
                }).filter(function (a) {
                    return !!a;
                });
            }

            var directMarkets = getMarketNames(fromAssets, np.toAsset);
            var indirectMarkets = getMarketNames(fromAssets, np.coreAsset);

            var indirectCheck = indirectMarkets.reduce(function (a, b) {
                return a || _this4._statsChanged(np.allMarketStats.get(b), _this4.props.allMarketStats.get(b));
            }, false);

            var directCheck = directMarkets.reduce(function (a, b) {
                return a || _this4._statsChanged(np.allMarketStats.get(b), _this4.props.allMarketStats.get(b));
            }, false);

            return this._statsChanged(np.allMarketStats.get(toMarket), this.props.allMarketStats.get(toMarket)) || indirectCheck || directCheck;
        }
    }]);

    return MarketStatsCheck;
}(React.Component);

export default MarketStatsCheck;