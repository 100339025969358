var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from "react";
import { ChainStore } from "bitsharesjs";
import AccountStore from "stores/AccountStore";
import NotificationStore from "stores/NotificationStore";
import { withRouter } from "react-router-dom";
import SyncError from "./components/SyncError";
import LoadingIndicator from "./components/LoadingIndicator";
import BrowserNotifications from "./components/BrowserNotifications/BrowserNotificationsContainer";
import Header from "components/Layout/Header";
import ReactTooltip from "react-tooltip";
import NotificationSystem from "react-notification-system";
import TransactionConfirm from "./components/Blockchain/TransactionConfirm";
import WalletUnlockModal from "./components/Wallet/WalletUnlockModal";
import BrowserSupportModal from "./components/Modal/BrowserSupportModal";
import Footer from "./components/Layout/Footer";
import Deprecate from "./Deprecate";
import Incognito from "./components/Layout/Incognito";
import { isIncognito } from "feature_detect";
import { updateGatewayBackers } from "common/gatewayUtils";
import titleUtils from "common/titleUtils";
import { BodyClassName, Notification } from "bitshares-ui-style-guide";
import { DEFAULT_NOTIFICATION_DURATION } from "services/Notification";
import Loadable from "react-loadable";
// import NewsHeadline from "components/Layout/NewsHeadline";

import { Route, Switch, Redirect } from "react-router-dom";

// Nested route components
import Page404 from "./components/Page404/Page404";

var Exchange = Loadable({
    loader: function loader() {
        return import( /* webpackChunkName: "exchange" */"./components/Exchange/ExchangeContainer");
    },
    loading: LoadingIndicator
});

var Explorer = Loadable({
    loader: function loader() {
        return import( /* webpackChunkName: "explorer" */"./components/Explorer/Explorer");
    },
    loading: LoadingIndicator
});

var AccountPage = Loadable({
    loader: function loader() {
        return import( /* webpackChunkName: "account" */"./components/Account/AccountPage");
    },
    loading: LoadingIndicator
});

var Transfer = Loadable({
    loader: function loader() {
        return import( /* webpackChunkName: "transfer" */"./components/Transfer/Transfer");
    },
    loading: LoadingIndicator
});

var AccountDepositWithdraw = Loadable({
    loader: function loader() {
        return import( /* webpackChunkName: "deposit-withdraw" */"./components/Account/AccountDepositWithdraw");
    },
    loading: LoadingIndicator
});

var News = Loadable({
    loader: function loader() {
        return import( /* webpackChunkName: "news" */"./components/News");
    },
    loading: LoadingIndicator
});

var Settings = Loadable({
    loader: function loader() {
        return import( /* webpackChunkName: "settings" */"./components/Settings/SettingsContainer");
    },
    loading: LoadingIndicator
});

var Help = Loadable({
    loader: function loader() {
        return import( /* webpackChunkName: "help" */"./components/Help");
    },
    loading: LoadingIndicator
});

var Asset = Loadable({
    loader: function loader() {
        return import( /* webpackChunkName: "asset" */"./components/Blockchain/Asset");
    },
    loading: LoadingIndicator
});

var Block = Loadable({
    loader: function loader() {
        return import( /* webpackChunkName: "block" */"./components/Blockchain/BlockContainer");
    },
    loading: LoadingIndicator
});

var DashboardAccountsOnly = Loadable({
    loader: function loader() {
        return import( /* webpackChunkName: "dashboard-accounts" */"./components/Dashboard/DashboardAccountsOnly");
    },
    loading: LoadingIndicator
});

var DashboardPage = Loadable({
    loader: function loader() {
        return import( /* webpackChunkName: "dashboard" */"./components/Dashboard/DashboardPage");
    },
    loading: LoadingIndicator
});

var WalletManager = Loadable({
    loader: function loader() {
        return import( /* webpackChunkName: "wallet" */"./components/Wallet/WalletManager");
    },
    loading: LoadingIndicator
});

var ExistingAccount = Loadable({
    loader: function loader() {
        return import( /* webpackChunkName: "existing-account" */"./components/Wallet/ExistingAccount");
    },
    loading: LoadingIndicator
});

var CreateWorker = Loadable({
    loader: function loader() {
        return import( /* webpackChunkName: "create-worker" */"./components/Account/CreateWorker");
    },
    loading: LoadingIndicator
});

var Barter = Loadable({
    loader: function loader() {
        return import( /* webpackChunkName: "settings" */"./components/Showcases/Barter");
    },
    loading: LoadingIndicator
});

var Borrow = Loadable({
    loader: function loader() {
        return import( /* webpackChunkName: "settings" */"./components/Showcases/Borrow");
    },
    loading: LoadingIndicator
});

var Htlc = Loadable({
    loader: function loader() {
        return import( /* webpackChunkName: "settings" */"./components/Showcases/Htlc");
    },
    loading: LoadingIndicator
});

var DirectDebit = Loadable({
    loader: function loader() {
        return import( /* webpackChunkName: "settings" */"./components/Showcases/DirectDebit");
    },
    loading: LoadingIndicator
});

import LoginSelector from "./components/LoginSelector";
import Login from "./components/Login/Login";
import RegistrationSelector from "./components/Registration/RegistrationSelector";
import WalletRegistration from "./components/Registration/WalletRegistration";
import AccountRegistration from "./components/Registration/AccountRegistration";
import { CreateWalletFromBrainkey } from "./components/Wallet/WalletCreate";
import ShowcaseGrid from "./components/Showcases/ShowcaseGrid";
import PriceAlertNotifications from "./components/PriceAlertNotifications";

var App = function (_React$Component) {
    _inherits(App, _React$Component);

    function App() {
        _classCallCheck(this, App);

        var _this = _possibleConstructorReturn(this, (App.__proto__ || Object.getPrototypeOf(App)).call(this));

        var syncFail = ChainStore.subError && ChainStore.subError.message === "ChainStore sync error, please check your system clock" ? true : false;
        _this.state = {
            isBrowserSupportModalVisible: false,
            loading: false,
            synced: _this._syncStatus(),
            syncFail: syncFail,
            incognito: false,
            incognitoWarningDismissed: false,
            height: window && window.innerHeight
        };

        _this._rebuildTooltips = _this._rebuildTooltips.bind(_this);
        _this._chainStoreSub = _this._chainStoreSub.bind(_this);
        _this._syncStatus = _this._syncStatus.bind(_this);
        _this._getWindowHeight = _this._getWindowHeight.bind(_this);

        _this.showBrowserSupportModal = _this.showBrowserSupportModal.bind(_this);
        _this.hideBrowserSupportModal = _this.hideBrowserSupportModal.bind(_this);

        Notification.config({
            duration: DEFAULT_NOTIFICATION_DURATION,
            top: 90
        });
        return _this;
    }

    _createClass(App, [{
        key: "componentWillUnmount",
        value: function componentWillUnmount() {
            window.removeEventListener("resize", this._getWindowHeight);
            NotificationStore.unlisten(this._onNotificationChange);
            ChainStore.unsubscribe(this._chainStoreSub);
            clearInterval(this.syncCheckInterval);
        }

        /**
         * Returns the current blocktime, or exception if not yet available
         * @returns {Date}
         */

    }, {
        key: "getBlockTime",
        value: function getBlockTime() {
            var dynGlobalObject = ChainStore.getObject("2.1.0");
            if (dynGlobalObject) {
                var block_time = dynGlobalObject.get("time");
                if (!/Z$/.test(block_time)) {
                    block_time += "Z";
                }
                return new Date(block_time);
            } else {
                throw new Error("Blocktime not available right now");
            }
        }

        /**
         * Returns the delta between the current time and the block time in seconds, or -1 if block time not available yet
         *
         * Note: Could be integrating properly with BlockchainStore to send out updates, but not necessary atp
         */

    }, {
        key: "getBlockTimeDelta",
        value: function getBlockTimeDelta() {
            try {
                var bt = (this.getBlockTime().getTime() + ChainStore.getEstimatedChainTimeOffset()) / 1000;
                var now = new Date().getTime() / 1000;
                return Math.abs(now - bt);
            } catch (err) {
                return -1;
            }
        }
    }, {
        key: "hideBrowserSupportModal",
        value: function hideBrowserSupportModal() {
            this.setState({
                isBrowserSupportModalVisible: false
            });
        }
    }, {
        key: "showBrowserSupportModal",
        value: function showBrowserSupportModal() {
            this.setState({
                isBrowserSupportModalVisible: true
            });
        }
    }, {
        key: "_syncStatus",
        value: function _syncStatus() {
            var setState = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

            var synced = this.getBlockTimeDelta() < 5;
            if (setState && synced !== this.state.synced) {
                this.setState({ synced: synced });
            }
            return synced;
        }
    }, {
        key: "_setListeners",
        value: function _setListeners() {
            try {
                window.addEventListener("resize", this._getWindowHeight, {
                    capture: false,
                    passive: true
                });
                NotificationStore.listen(this._onNotificationChange.bind(this));
                ChainStore.subscribe(this._chainStoreSub);
                AccountStore.tryToSetCurrentAccount();
            } catch (e) {
                console.error("e:", e);
            }
        }
    }, {
        key: "componentDidMount",
        value: function componentDidMount() {
            this._setListeners();
            this.syncCheckInterval = setInterval(this._syncStatus.bind(this, true), 5000);
            var user_agent = navigator.userAgent.toLowerCase();
            if (!(window.electron || user_agent.indexOf("firefox") > -1 || user_agent.indexOf("chrome") > -1 || user_agent.indexOf("edge") > -1)) {
                this.showBrowserSupportModal();
            }

            this.props.history.listen(this._rebuildTooltips);

            this._rebuildTooltips();

            isIncognito(function (incognito) {
                this.setState({ incognito: incognito });
            }.bind(this));
            updateGatewayBackers();
        }
    }, {
        key: "componentDidUpdate",
        value: function componentDidUpdate(prevProps) {
            if (this.props.location !== prevProps.location) {
                this.onRouteChanged();
            }
        }
    }, {
        key: "onRouteChanged",
        value: function onRouteChanged() {
            document.title = titleUtils.GetTitleByPath(this.props.location.pathname);
        }
    }, {
        key: "_onIgnoreIncognitoWarning",
        value: function _onIgnoreIncognitoWarning() {
            this.setState({ incognitoWarningDismissed: true });
        }
    }, {
        key: "_rebuildTooltips",
        value: function _rebuildTooltips() {
            var _this2 = this;

            if (this.rebuildTimeout) return;
            ReactTooltip.hide();

            this.rebuildTimeout = setTimeout(function () {
                if (_this2.refs.tooltip) {
                    _this2.refs.tooltip.globalRebuild();
                }
                _this2.rebuildTimeout = null;
            }, 1500);
        }
    }, {
        key: "_chainStoreSub",
        value: function _chainStoreSub() {
            var synced = this._syncStatus();
            if (synced !== this.state.synced) {
                this.setState({ synced: synced });
            }
            if (ChainStore.subscribed !== this.state.synced || ChainStore.subError) {
                var syncFail = ChainStore.subError && ChainStore.subError.message === "ChainStore sync error, please check your system clock" ? true : false;
                this.setState({
                    syncFail: syncFail
                });
            }
        }

        /** Usage: NotificationActions.[success,error,warning,info] */

    }, {
        key: "_onNotificationChange",
        value: function _onNotificationChange() {
            var notification = NotificationStore.getState().notification;
            if (notification.autoDismiss === void 0) {
                notification.autoDismiss = 10;
            }
            if (this.refs.notificationSystem) this.refs.notificationSystem.addNotification(notification);
        }
    }, {
        key: "_getWindowHeight",
        value: function _getWindowHeight() {
            this.setState({ height: window && window.innerHeight });
        }

        // /** Non-static, used by passing notificationSystem via react Component refs */
        // _addNotification(params) {
        //     console.log("add notification:", this.refs, params);
        //     this.refs.notificationSystem.addNotification(params);
        // }

    }, {
        key: "render",
        value: function render() {
            var _state = this.state,
                incognito = _state.incognito,
                incognitoWarningDismissed = _state.incognitoWarningDismissed;

            var _props = this.props,
                walletMode = _props.walletMode,
                theme = _props.theme,
                location = _props.location,
                match = _props.match,
                others = _objectWithoutProperties(_props, ["walletMode", "theme", "location", "match"]);

            var content = null;

            if (this.state.syncFail) {
                content = React.createElement(SyncError, null);
            } else if (this.state.loading) {
                content = React.createElement(
                    "div",
                    { className: "grid-frame vertical" },
                    React.createElement(LoadingIndicator, {
                        loadingText: "Connecting to APIs and starting app"
                    })
                );
            } else if (__DEPRECATED__) {
                content = React.createElement(Deprecate, this.props);
            } else {
                var accountName = AccountStore.getState().currentAccount || AccountStore.getState().passwordAccount;
                accountName = accountName && accountName !== "null" ? accountName : "committee-account";
                content = React.createElement(
                    "div",
                    { className: "grid-frame vertical" },
                    React.createElement(Header, _extends({ height: this.state.height }, others)),
                    React.createElement(
                        "div",
                        { id: "mainContainer", className: "grid-block" },
                        React.createElement(
                            "div",
                            { className: "grid-block vertical" },
                            React.createElement(
                                Switch,
                                null,
                                React.createElement(Route, {
                                    path: "/",
                                    exact: true,
                                    component: DashboardPage
                                }),
                                React.createElement(Route, {
                                    path: "/account/:account_name",
                                    component: AccountPage
                                }),
                                React.createElement(Route, {
                                    path: "/accounts",
                                    component: DashboardAccountsOnly
                                }),
                                React.createElement(Route, {
                                    path: "/market/:marketID",
                                    component: Exchange
                                }),
                                React.createElement(Route, {
                                    path: "/settings/:tab",
                                    component: Settings
                                }),
                                React.createElement(Route, { path: "/settings", component: Settings }),
                                React.createElement(Route, {
                                    path: "/transfer",
                                    exact: true,
                                    component: Transfer
                                }),
                                React.createElement(Route, {
                                    path: "/deposit-withdraw",
                                    exact: true,
                                    component: AccountDepositWithdraw
                                }),
                                React.createElement(Route, {
                                    path: "/create-account",
                                    component: LoginSelector
                                }),
                                React.createElement(Route, { path: "/login", component: Login }),
                                React.createElement(Route, {
                                    path: "/registration",
                                    exact: true,
                                    component: RegistrationSelector
                                }),
                                React.createElement(Route, {
                                    path: "/registration/local",
                                    exact: true,
                                    component: WalletRegistration
                                }),
                                React.createElement(Route, {
                                    path: "/registration/cloud",
                                    exact: true,
                                    component: AccountRegistration
                                }),
                                React.createElement(Route, { path: "/news", exact: true, component: News }),
                                React.createElement(Redirect, {
                                    path: "/voting",
                                    to: {
                                        pathname: "/account/" + accountName + "/voting"
                                    }
                                }),
                                React.createElement(Route, {
                                    path: "/explorer/:tab",
                                    component: Explorer
                                }),
                                React.createElement(Route, { path: "/explorer", component: Explorer }),
                                React.createElement(Route, {
                                    path: "/asset/:symbol",
                                    component: Asset
                                }),
                                React.createElement(Route, {
                                    exact: true,
                                    path: "/block/:height",
                                    component: Block
                                }),
                                React.createElement(Route, {
                                    exact: true,
                                    path: "/block/:height/:txIndex",
                                    component: Block
                                }),
                                React.createElement(Route, { path: "/borrow", component: Borrow }),
                                React.createElement(Route, { path: "/barter", component: Barter }),
                                React.createElement(Route, {
                                    path: "/direct-debit",
                                    component: DirectDebit
                                }),
                                React.createElement(Route, {
                                    path: "/spotlight",
                                    component: ShowcaseGrid
                                }),
                                React.createElement(Route, {
                                    path: "/wallet",
                                    component: WalletManager
                                }),
                                React.createElement(Route, {
                                    path: "/create-wallet-brainkey",
                                    component: CreateWalletFromBrainkey
                                }),
                                React.createElement(Route, {
                                    path: "/existing-account",
                                    component: ExistingAccount
                                }),
                                React.createElement(Route, {
                                    path: "/create-worker",
                                    component: CreateWorker
                                }),
                                React.createElement(Route, { exact: true, path: "/help", component: Help }),
                                React.createElement(Route, {
                                    exact: true,
                                    path: "/help/:path1",
                                    component: Help
                                }),
                                React.createElement(Route, {
                                    exact: true,
                                    path: "/help/:path1/:path2",
                                    component: Help
                                }),
                                React.createElement(Route, {
                                    exact: true,
                                    path: "/help/:path1/:path2/:path3",
                                    component: Help
                                }),
                                React.createElement(Route, { path: "/htlc", component: Htlc }),
                                React.createElement(Route, { path: "*", component: Page404 })
                            )
                        )
                    ),
                    React.createElement(Footer, {
                        synced: this.state.synced,
                        history: this.props.history
                    }),
                    React.createElement(ReactTooltip, {
                        ref: "tooltip",
                        place: "top",
                        type: theme === "lightTheme" ? "dark" : "light",
                        effect: "solid"
                    })
                );
            }

            return React.createElement(
                "div",
                {
                    style: { backgroundColor: !theme ? "#2a2a2a" : null },
                    className: theme
                },
                React.createElement(
                    BodyClassName,
                    { className: theme },
                    walletMode && incognito && !incognitoWarningDismissed ? React.createElement(Incognito, {
                        onClickIgnore: this._onIgnoreIncognitoWarning.bind(this)
                    }) : null,
                    React.createElement(
                        "div",
                        { id: "content-wrapper" },
                        content,
                        React.createElement(NotificationSystem, {
                            ref: "notificationSystem",
                            allowHTML: true,
                            style: {
                                Containers: {
                                    DefaultStyle: {
                                        width: "425px"
                                    }
                                }
                            }
                        }),
                        React.createElement(TransactionConfirm, null),
                        React.createElement(BrowserNotifications, null),
                        React.createElement(PriceAlertNotifications, null),
                        React.createElement(WalletUnlockModal, null),
                        React.createElement(BrowserSupportModal, {
                            visible: this.state.isBrowserSupportModalVisible,
                            hideModal: this.hideBrowserSupportModal,
                            showModal: this.showBrowserSupportModal
                        })
                    )
                )
            );
        }
    }]);

    return App;
}(React.Component);

export default withRouter(App);