var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "alt-react";
import { PrivateKey } from "bitsharesjs/es";
import WalletManagerStore from "stores/WalletManagerStore";
import BackupStore from "stores/BackupStore";
import AccountStore from "stores/AccountStore";
import WalletActions from "actions/WalletActions";
import WalletDb from "stores/WalletDb";
import WalletUnlockActions from "actions/WalletUnlockActions";
import BackupActions, { restore } from "actions/BackupActions";
import SettingsActions from "actions/SettingsActions";
import Icon from "../Icon/Icon";
import { Button, Form, Input, Notification } from "bitshares-ui-style-guide";
import counterpart from "counterpart";

var DecryptBackup = function (_Component) {
    _inherits(DecryptBackup, _Component);

    function DecryptBackup() {
        _classCallCheck(this, DecryptBackup);

        var _this = _possibleConstructorReturn(this, (DecryptBackup.__proto__ || Object.getPrototypeOf(DecryptBackup)).call(this));

        _this.state = {
            backupPassword: "",
            formError: ""
        };

        _this.onPassword = _this.onPassword.bind(_this);
        _this.formChange = _this.formChange.bind(_this);
        return _this;
    }

    _createClass(DecryptBackup, [{
        key: "componentDidUpdate",
        value: function componentDidUpdate(prevProps) {
            if (this.props.active) {
                if (this.refs.passwordInput && this.refs.passwordInput.focus) {
                    this.refs.passwordInput.focus();
                }
            }
            if (!prevProps.currentAccount && this.props.currentAccount) {
                this.props.history.push("/");
            }
        }
    }, {
        key: "onRestore",
        value: function onRestore() {
            var backupPassword = this.state.backupPassword;

            WalletDb.validatePassword(backupPassword || "", true);
            WalletUnlockActions.change();
            SettingsActions.changeSetting({
                setting: "passwordLogin",
                value: false
            });
            BackupActions.reset();
        }
    }, {
        key: "onPassword",
        value: function onPassword(e) {
            var _this2 = this;

            if (e) e.preventDefault();
            var privateKey = PrivateKey.fromSeed(this.state.backupPassword || "");
            var _props$backup = this.props.backup,
                contents = _props$backup.contents,
                name = _props$backup.name;

            var walletName = name.split(".")[0];
            restore(privateKey.toWif(), contents, walletName).then(function () {
                return WalletActions.setWallet(walletName).then(function () {
                    _this2.onRestore(walletName);
                });
            }).catch(function (error) {
                console.error("Error verifying wallet " + _this2.props.backup.name, error, error.stack);
                if (error === "invalid_decryption_key") {
                    _this2.setState({
                        formError: counterpart.translate("notifications.invalid_password")
                    });
                } else {
                    _this2.setState({
                        formError: error
                    });
                }
                _this2.setState({ passwordError: true });
            });
        }
    }, {
        key: "formChange",
        value: function formChange(event) {
            var state = {};
            state[event.target.id] = event.target.value;
            this.setState(_extends({}, state, {
                formError: ""
            }));
        }
    }, {
        key: "renderButtons",
        value: function renderButtons() {
            return React.createElement(
                "div",
                { className: "button-group" },
                this.props.active ? React.createElement(
                    Button,
                    { onClick: this.onPassword, type: "primary" },
                    counterpart.translate("login.loginButton")
                ) : React.createElement(
                    Button,
                    null,
                    counterpart.translate("registration.select")
                )
            );
        }
    }, {
        key: "render",
        value: function render() {
            var _this3 = this;

            var getPasswordInputValidateStatus = function getPasswordInputValidateStatus() {
                return _this3.state.formError ? "error" : "";
            };

            var getPasswordInputHelp = function getPasswordInputHelp() {
                return _this3.state.formError ? _this3.state.formError : "";
            };

            return React.createElement(
                "div",
                null,
                React.createElement(
                    "div",
                    {
                        className: (!this.props.active ? "display-none" : "") + " password-block"
                    },
                    React.createElement(
                        Form,
                        {
                            layout: "vertical",
                            style: { textAlign: "left" },
                            onSubmit: this.onPassword
                        },
                        React.createElement(
                            Form.Item,
                            {
                                label: counterpart.translate("settings.password"),
                                validateStatus: getPasswordInputValidateStatus(),
                                help: getPasswordInputHelp()
                            },
                            React.createElement(Input, {
                                className: (this.state.passwordError ? "input-warning" : this.state.backupPassword ? "input-success" : "") + " input create-account-input",
                                type: !this.state.passwordVisible ? "password" : "text",
                                placeholder: counterpart.translate("wallet.enter_password"),
                                id: "backupPassword",
                                onChange: this.formChange,
                                value: this.state.backupPassword,
                                ref: "passwordInput",
                                autoFocus: true
                            })
                        )
                    ),
                    !this.state.passwordVisible ? React.createElement(
                        "span",
                        {
                            className: "no-width eye-block",
                            onClick: function onClick() {
                                return _this3.setState({ passwordVisible: true });
                            }
                        },
                        React.createElement(Icon, {
                            name: "eye-visible",
                            className: "eye-icon icon-opacity"
                        })
                    ) : React.createElement(
                        "span",
                        {
                            className: "no-width eye-block",
                            onClick: function onClick() {
                                return _this3.setState({ passwordVisible: false });
                            }
                        },
                        React.createElement(Icon, {
                            name: "eye-invisible",
                            className: "eye-icon icon-opacity"
                        })
                    )
                ),
                this.renderButtons()
            );
        }
    }]);

    return DecryptBackup;
}(Component);

DecryptBackup.propTypes = {
    active: PropTypes.bool,
    currentAccount: PropTypes.string,
    backup: PropTypes.object,
    wallet: PropTypes.object,
    history: PropTypes.object.isRequired
};
DecryptBackup.defaultProps = {
    active: false,
    currentAccount: "",
    backup: {},
    wallet: {}
};


var connectObject = {
    listenTo: function listenTo() {
        return [WalletManagerStore, BackupStore, AccountStore];
    },
    getProps: function getProps() {
        return {
            wallet: WalletManagerStore.getState(),
            backup: BackupStore.getState(),
            currentAccount: AccountStore.getState().currentAccount || AccountStore.getState().passwordAccount
        };
    }
};

export default connect(DecryptBackup, connectObject);