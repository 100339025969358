import React from "react";
import Translate from "react-translate-component";
import TranslateWithLinks from "../../Utility/TranslateWithLinks";

var ShortObjectId = function ShortObjectId(_ref) {
    var objectId = _ref.objectId;

    if (typeof objectId === "string") {
        var parts = objectId.split(".");
        var length = parts.length;

        if (length > 0) return "#" + parts[length - 1];
    }
    return objectId;
};

export var ProposalDelete = function ProposalDelete(_ref2) {
    var op = _ref2.op,
        fromComponent = _ref2.fromComponent;

    if (fromComponent === "proposed_operation") {
        return React.createElement(
            "span",
            null,
            React.createElement(Translate, {
                component: "span",
                content: "proposal.proposal_update"
            })
        );
    } else {
        return React.createElement(
            "span",
            null,
            React.createElement(TranslateWithLinks, {
                string: "operation.proposal_delete",
                keys: [{
                    type: "account",
                    value: op[1].fee_paying_account,
                    arg: "account"
                }, {
                    value: React.createElement(ShortObjectId, { objectId: op[1].proposal }),
                    arg: "proposal"
                }]
            })
        );
    }
};