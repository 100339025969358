var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

import alt from "alt-instance";
import { ChainConfig } from "bitsharesjs-ws";
import counterpart from "counterpart";
import ZfApi from "react-foundation-apps/src/utils/foundation-api";

var TransactionConfirmActions = function () {
    function TransactionConfirmActions() {
        _classCallCheck(this, TransactionConfirmActions);
    }

    _createClass(TransactionConfirmActions, [{
        key: "confirm",
        value: function confirm(transaction, resolve, reject) {
            return { transaction: transaction, resolve: resolve, reject: reject };
        }
    }, {
        key: "broadcast",
        value: function broadcast(transaction, resolve, reject) {
            return function (dispatch) {
                dispatch({ broadcasting: true, closed: true });

                var broadcast_timeout = setTimeout(function () {
                    dispatch({
                        broadcast: false,
                        broadcasting: false,
                        error: counterpart.translate("trx_error.expire"),
                        closed: false
                    });
                    if (reject) reject();
                }, ChainConfig.expire_in_secs * 2000);

                transaction.broadcast(function () {
                    dispatch({ broadcasting: false, broadcast: true });
                }).then(function (res) {
                    clearTimeout(broadcast_timeout);
                    dispatch({
                        error: null,
                        broadcasting: false,
                        broadcast: true,
                        included: true,
                        trx_id: res[0].id,
                        trx_block_num: res[0].block_num,
                        broadcasted_transaction: true
                    });
                    if (resolve) resolve();
                }).catch(function (error) {
                    console.error(error);
                    clearTimeout(broadcast_timeout);
                    // messages of length 1 are local exceptions (use the 1st line)
                    // longer messages are remote API exceptions (use the 1st line)
                    var splitError = error.message.split("\n");
                    var message = splitError[0];
                    dispatch({
                        broadcast: false,
                        broadcasting: false,
                        error: message,
                        closed: false
                    });
                    if (reject) reject();
                });
            };
        }
    }, {
        key: "wasBroadcast",
        value: function wasBroadcast(res) {
            return res;
        }
    }, {
        key: "wasIncluded",
        value: function wasIncluded(res) {
            return res;
        }
    }, {
        key: "close",
        value: function close() {
            ZfApi.publish("transaction_confirm_actions", "close");
            return true;
        }
    }, {
        key: "error",
        value: function error(msg) {
            return { error: msg };
        }
    }, {
        key: "togglePropose",
        value: function togglePropose() {
            return true;
        }
    }, {
        key: "proposeFeePayingAccount",
        value: function proposeFeePayingAccount(fee_paying_account) {
            return fee_paying_account;
        }
    }]);

    return TransactionConfirmActions;
}();

export default alt.createActions(TransactionConfirmActions);