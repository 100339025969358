var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from "react";
import Showcase from "./Showcase";
import { connect } from "alt-react";
import { ChainStore } from "bitsharesjs";
import AccountStore from "../../stores/AccountStore";
import { createPaperWalletAsPDF } from "common/paperWallet";

var ShowcaseGrid = function (_Component) {
    _inherits(ShowcaseGrid, _Component);

    function ShowcaseGrid() {
        _classCallCheck(this, ShowcaseGrid);

        var _this = _possibleConstructorReturn(this, (ShowcaseGrid.__proto__ || Object.getPrototypeOf(ShowcaseGrid)).call(this));

        _this.state = { currentAccount: null };
        return _this;
    }

    _createClass(ShowcaseGrid, [{
        key: "componentWillMount",
        value: function componentWillMount() {
            this.setState({
                currentAccount: ChainStore.getAccount(this.props.currentAccount)
            });
        }
    }, {
        key: "componentWillReceiveProps",
        value: function componentWillReceiveProps(np) {
            if (np.currentAccount !== this.props.currentAccount) {
                this.setState({
                    currentAccount: ChainStore.getAccount(np.currentAccount)
                });
            }
        }
    }, {
        key: "render",
        value: function render() {
            var _this2 = this;

            var hasAccount = this.state.currentAccount !== null;

            var thiz = this;
            var tiles = [{
                title: "showcases.paper_wallet.title",
                target: function target() {
                    if (hasAccount) {
                        createPaperWalletAsPDF(_this2.state.currentAccount);
                    }
                },
                description: "showcases.paper_wallet.description",
                icon: "wallet", // see Icons app/compoentns/Icon/Icon
                disabled: hasAccount ? false : "Please login to use this functionality"
            }, {
                title: "showcases.voting.title",
                target: function target(event) {
                    if (hasAccount) {
                        thiz.props.history.push("/account/" + _this2.state.currentAccount.get("name") + "/voting");
                    }
                },
                description: "showcases.voting.description",
                icon: "voting",
                disabled: hasAccount ? false : "Please login to use this functionality"
            }, {
                title: "showcases.barter.title",
                target: function target(event) {
                    thiz.props.history.push("/barter");
                },
                description: "showcases.barter.description",
                icon: "barter",
                disabled: hasAccount ? false : "Please login to use this functionality"
            }, {
                title: "showcases.borrow.title",
                target: function target() {
                    if (hasAccount) {
                        thiz.props.history.push("/borrow");
                    }
                },
                description: "showcases.borrow.description",
                icon: "borrow",
                disabled: hasAccount ? false : "Please login to use this functionality"
            }, {
                title: "showcases.direct_debit.title",
                target: function target(event) {
                    thiz.props.history.push("/direct-debit");
                },
                description: "showcases.direct_debit.description",
                icon: "direct_debit",
                disabled: hasAccount ? false : "Please login to use this functionality"
            }, {
                title: "showcases.htlc.title",
                target: function target(event) {
                    thiz.props.history.push("/htlc");
                },
                description: "showcases.htlc.description",
                icon: "htlc",
                disabled: hasAccount ? false : "Please login to use this functionality"
            }, {
                title: "showcases.prediction_market.title",
                target: function target(event) {
                    thiz.props.history.push("/prediction");
                },
                description: "showcases.prediction_market.description",
                icon: "prediction",
                disabled: "Visit bitshares-ui issue #2717 to check progress",
                comingSoon: true
            }, {
                title: "showcases.timed_transfer.title",
                target: function target() {},
                description: "showcases.timed_transfer.description",
                icon: "alarm",
                disabled: true,
                comingSoon: true
                // .... even more tiles in this list
            }];
            return React.createElement(
                "div",
                {
                    className: "overflow-visible showcases-grid",
                    style: {
                        align: "center"
                    }
                },
                React.createElement(
                    "div",
                    { className: "showcases-grid--wrapper" },
                    tiles.map(function (tile) {
                        return React.createElement(
                            "div",
                            {
                                key: tile.title,
                                className: "showcases-grid--wrapper--item"
                            },
                            !!tile.disabled ? React.createElement(Showcase, {
                                target: tile.target,
                                title: tile.title,
                                description: tile.description,
                                icon: tile.icon,
                                disabled: tile.disabled,
                                comingSoon: tile.comingSoon || false
                            }) : React.createElement(Showcase, {
                                target: tile.target,
                                title: tile.title,
                                description: tile.description,
                                icon: tile.icon
                            })
                        );
                    })
                )
            );
        }
    }]);

    return ShowcaseGrid;
}(Component);

ShowcaseGrid = connect(ShowcaseGrid, {
    listenTo: function listenTo() {
        return [AccountStore];
    },
    getProps: function getProps() {
        return {
            currentAccount: AccountStore.getState().currentAccount || AccountStore.getState().passwordAccount
        };
    }
});

export default ShowcaseGrid;