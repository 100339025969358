var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from "react";
import Icon from "../Icon/Icon";
import Translate from "react-translate-component";
import cnames from "classnames";
import AccountActions from "actions/AccountActions";

var DropDownMenu = function (_React$Component) {
    _inherits(DropDownMenu, _React$Component);

    function DropDownMenu() {
        _classCallCheck(this, DropDownMenu);

        return _possibleConstructorReturn(this, (DropDownMenu.__proto__ || Object.getPrototypeOf(DropDownMenu)).apply(this, arguments));
    }

    _createClass(DropDownMenu, [{
        key: "shouldComponentUpdate",
        value: function shouldComponentUpdate(np) {
            var shouldUpdate = false;
            for (var key in np) {
                if (typeof np[key] === "function") continue;
                shouldUpdate = shouldUpdate || np[key] !== this.props[key];
            }
            return shouldUpdate;
        }
    }, {
        key: "_onAddContact",
        value: function _onAddContact() {
            AccountActions.addAccountContact(this.props.currentAccount);
        }
    }, {
        key: "_onRemoveContact",
        value: function _onRemoveContact() {
            AccountActions.removeAccountContact(this.props.currentAccount);
        }
    }, {
        key: "render",
        value: function render() {
            var _this2 = this;

            var _props = this.props,
                dropdownActive = _props.dropdownActive,
                toggleLock = _props.toggleLock,
                maxHeight = _props.maxHeight,
                locked = _props.locked,
                active = _props.active,
                passwordLogin = _props.passwordLogin,
                isMyAccount = _props.isMyAccount,
                showAccountLinks = _props.showAccountLinks,
                tradeUrl = _props.tradeUrl,
                enableDepositWithdraw = _props.enableDepositWithdraw,
                currentAccount = _props.currentAccount,
                contacts = _props.contacts;


            var isContact = contacts.has(currentAccount);

            return React.createElement(
                "ul",
                {
                    className: "dropdown header-menu",
                    style: {
                        left: -200,
                        top: 64,
                        maxHeight: !dropdownActive ? 0 : maxHeight,
                        overflowY: "auto"
                    }
                },
                React.createElement(
                    "li",
                    { className: "divider", onClick: toggleLock },
                    React.createElement(
                        "div",
                        { className: "table-cell" },
                        React.createElement(Icon, { size: "2x", name: "power", title: "icons.power" })
                    ),
                    React.createElement(
                        "div",
                        { className: "table-cell" },
                        React.createElement(Translate, {
                            content: "header." + (this.props.locked ? "unlock_short" : "lock_short")
                        })
                    )
                ),
                locked ? React.createElement(
                    "li",
                    {
                        className: cnames({
                            active: active.indexOf("/create-account/" + (!passwordLogin ? "wallet" : "password")) !== -1
                        }),
                        onClick: this.props.onNavigate.bind(this, "/create-account/" + (!passwordLogin ? "wallet" : "password"))
                    },
                    React.createElement(
                        "div",
                        { className: "table-cell" },
                        React.createElement(Icon, {
                            size: "2x",
                            name: "user",
                            title: "icons.user.create_account"
                        })
                    ),
                    React.createElement(
                        "div",
                        { className: "table-cell" },
                        React.createElement(Translate, { content: "header.create_account" })
                    )
                ) : null,
                !this.props.locked ? React.createElement(
                    "li",
                    {
                        className: cnames({
                            active: active.indexOf("/account") !== -1
                        }),
                        onClick: this.props.onNavigate.bind(this, "/account/" + currentAccount)
                    },
                    React.createElement(
                        "div",
                        { className: "table-cell" },
                        React.createElement(Icon, {
                            size: "2x",
                            name: "dashboard",
                            title: "icons.dasboard"
                        })
                    ),
                    React.createElement(
                        "div",
                        { className: "table-cell" },
                        React.createElement(Translate, { content: "header.dashboard" })
                    )
                ) : null,
                !isMyAccount && showAccountLinks ? React.createElement(
                    "li",
                    {
                        className: "divider",
                        onClick: this[isContact ? "_onRemoveContact" : "_onAddContact"].bind(this)
                    },
                    React.createElement(
                        "div",
                        { className: "table-cell" },
                        React.createElement(Icon, {
                            size: "2x",
                            name: (isContact ? "minus" : "plus") + "-circle",
                            title: isContact ? "icons.minus_circle.remove_contact" : "icons.plus_circle.add_contact"
                        })
                    ),
                    React.createElement(
                        "div",
                        { className: "table-cell" },
                        React.createElement(Translate, {
                            content: "account." + (isContact ? "unfollow" : "follow")
                        })
                    )
                ) : null,
                React.createElement(
                    "li",
                    {
                        className: cnames({
                            active: active.indexOf("/market/") !== -1
                        }, "column-show-small"),
                        onClick: this.props.onNavigate.bind(this, tradeUrl)
                    },
                    React.createElement(
                        "div",
                        { className: "table-cell" },
                        React.createElement(Icon, {
                            size: "2x",
                            name: "trade",
                            title: "icons.trade.exchange"
                        })
                    ),
                    React.createElement(
                        "div",
                        { className: "table-cell" },
                        React.createElement(Translate, { content: "header.exchange" })
                    )
                ),
                React.createElement(
                    "li",
                    {
                        className: cnames({
                            active: active.indexOf("/explorer") !== -1
                        }, "column-show-small"),
                        onClick: this.props.onNavigate.bind(this, "/explorer/blocks")
                    },
                    React.createElement(
                        "div",
                        { className: "table-cell" },
                        React.createElement(Icon, { size: "2x", name: "server", title: "icons.server" })
                    ),
                    React.createElement(
                        "div",
                        { className: "table-cell" },
                        React.createElement(Translate, { content: "header.explorer" })
                    )
                ),
                React.createElement(
                    "li",
                    {
                        className: cnames({
                            active: active.indexOf("/deposit-withdraw") !== -1
                        }, { disabled: !enableDepositWithdraw }),
                        onClick: !enableDepositWithdraw ? function () {} : this.props.onNavigate.bind(this, "/deposit-withdraw")
                    },
                    React.createElement(
                        "div",
                        { className: "table-cell" },
                        React.createElement(Icon, {
                            size: "2x",
                            name: "deposit-withdraw",
                            title: "icons.deposit.deposit_withdraw"
                        })
                    ),
                    React.createElement(
                        "div",
                        { className: "table-cell" },
                        React.createElement(Translate, { content: "account.deposit_withdraw" })
                    )
                ),
                [{
                    icon: {
                        name: "transfer",
                        title: "icons.transfer"
                    },
                    disabled: !showAccountLinks,
                    mainText: "header.payments",
                    mainCallback: this.props.showSend
                    //subText: "header.payments_legacy",
                    //subURL: "/transfer"

                    /*
                    {
                        icon: {
                            name: "deposit",
                            title: "icons.deposit.deposit"
                        },
                        disabled: !enableDepositWithdraw,
                        mainText: "modal.deposit.submit",
                        mainCallback: this.props.showDeposit,
                        //subText: "header.deposit_legacy",
                        //subURL: "/deposit-withdraw"
                    },
                    {
                        icon: {
                            name: "withdraw",
                            title: "icons.withdraw"
                        },
                        disabled: !enableDepositWithdraw,
                        mainText: "modal.withdraw.submit",
                        mainCallback: this.props.showWithdraw,
                        //subText: "header.withdraw_legacy",
                        //subURL: "/deposit-withdraw"
                    }
                    */
                }].map(function (_ref, index) {
                    var icon = _ref.icon,
                        subURL = _ref.subURL,
                        disabled = _ref.disabled,
                        mainText = _ref.mainText,
                        subText = _ref.subText,
                        mainCallback = _ref.mainCallback;
                    return React.createElement(
                        "li",
                        {
                            key: index,
                            className: cnames({
                                active: active.indexOf(subURL) !== -1,
                                disabled: disabled
                            }),
                            onClick: disabled ? function (event) {
                                event.stopPropagation();
                            } : mainCallback
                        },
                        React.createElement(
                            "div",
                            { className: "table-cell" },
                            React.createElement(Icon, _extends({ size: "2x" }, icon))
                        ),
                        React.createElement(
                            "div",
                            { className: "table-cell" },
                            React.createElement(Translate, { content: mainText }),
                            " ",
                            React.createElement(
                                "span",
                                {
                                    onClick: disabled ? function () {} : function (event) {
                                        event.stopPropagation();
                                        _this2.props.onNavigate.bind(_this2, subURL)(event);
                                    },
                                    className: cnames("header-dropdown-sub-link", { enabled: !disabled })
                                },
                                React.createElement(Translate, { content: subText })
                            )
                        )
                    );
                }),
                React.createElement(
                    "li",
                    {
                        className: cnames({
                            active: active.indexOf("/settings") !== -1
                        }, "divider", "desktop-only"),
                        onClick: this.props.onNavigate.bind(this, "/settings")
                    },
                    React.createElement(
                        "div",
                        { className: "table-cell" },
                        React.createElement(Icon, { size: "2x", name: "cogs", title: "icons.cogs" })
                    ),
                    React.createElement(
                        "div",
                        { className: "table-cell" },
                        React.createElement(Translate, { content: "header.settings" })
                    )
                ),
                React.createElement(
                    "li",
                    {
                        className: cnames({
                            active: active.indexOf("/spotlight") !== -1
                        }, "divider"),
                        onClick: this.props.onNavigate.bind(this, "/spotlight")
                    },
                    React.createElement(
                        "div",
                        { className: "table-cell" },
                        React.createElement(Icon, {
                            size: "2x",
                            name: "showcases",
                            title: "icons.showcases"
                        })
                    ),
                    React.createElement(
                        "div",
                        { className: "table-cell" },
                        React.createElement(Translate, { content: "header.showcases" })
                    )
                ),
                React.createElement(
                    "li",
                    {
                        className: cnames({
                            active: active.indexOf("/settings") !== -1
                        }, "divider", "mobile-only", "has-submenu"),
                        onClick: this.props.toggleDropdownSubmenu
                    },
                    React.createElement(
                        "div",
                        { className: "table-cell" },
                        React.createElement(Icon, { size: "2x", name: "cogs", title: "icons.cogs" })
                    ),
                    React.createElement(
                        "div",
                        { className: "table-cell" },
                        React.createElement(Translate, { content: "header.settings" }),
                        " "
                    )
                ),
                React.createElement(
                    "li",
                    {
                        className: cnames({
                            active: active.indexOf("/news") !== -1
                        }),
                        onClick: this.props.onNavigate.bind(this, "/news")
                    },
                    React.createElement(
                        "div",
                        { className: "table-cell" },
                        React.createElement(Icon, { size: "2x", name: "news", title: "icons.news" })
                    ),
                    React.createElement(
                        "div",
                        { className: "table-cell" },
                        React.createElement(Translate, { content: "news.news" })
                    )
                ),
                React.createElement(
                    "li",
                    {
                        className: cnames({
                            active: active.indexOf("/voting") !== -1,
                            disabled: !showAccountLinks
                        }),
                        onClick: this.props.onNavigate.bind(this, "/account/" + currentAccount + "/voting")
                    },
                    React.createElement(
                        "div",
                        { className: "table-cell" },
                        React.createElement(Icon, {
                            size: "2x",
                            name: "thumbs-up",
                            title: "icons.thumbs_up"
                        })
                    ),
                    React.createElement(
                        "div",
                        { className: "table-cell" },
                        React.createElement(Translate, { content: "account.voting" })
                    )
                ),
                React.createElement(
                    "li",
                    {
                        className: cnames({
                            active: active.indexOf("/assets") !== -1 && active.indexOf("/account/") !== -1,
                            disabled: !showAccountLinks
                        }),
                        onClick: this.props.onNavigate.bind(this, "/account/" + currentAccount + "/assets")
                    },
                    React.createElement(
                        "div",
                        { className: "table-cell" },
                        React.createElement(Icon, { size: "2x", name: "assets", title: "icons.assets" })
                    ),
                    React.createElement(
                        "div",
                        { className: "table-cell" },
                        React.createElement(Translate, { content: "explorer.assets.title" })
                    )
                ),
                React.createElement(
                    "li",
                    {
                        className: cnames({
                            active: active.indexOf("/signedmessages") !== -1,
                            disabled: !showAccountLinks
                        }),
                        onClick: this.props.onNavigate.bind(this, "/account/" + currentAccount + "/signedmessages")
                    },
                    React.createElement(
                        "div",
                        { className: "table-cell" },
                        React.createElement(Icon, {
                            size: "2x",
                            name: "text",
                            title: "icons.text.signed_messages"
                        })
                    ),
                    React.createElement(
                        "div",
                        { className: "table-cell" },
                        React.createElement(Translate, { content: "account.signedmessages.menuitem" })
                    )
                ),
                React.createElement(
                    "li",
                    {
                        className: cnames({
                            active: active.indexOf("/member-stats") !== -1,
                            disabled: !showAccountLinks
                        }),
                        onClick: this.props.onNavigate.bind(this, "/account/" + currentAccount + "/member-stats")
                    },
                    React.createElement(
                        "div",
                        { className: "table-cell" },
                        React.createElement(Icon, {
                            size: "2x",
                            name: "text",
                            title: "icons.text.membership_stats"
                        })
                    ),
                    React.createElement(
                        "div",
                        { className: "table-cell" },
                        React.createElement(Translate, { content: "account.member.stats" })
                    )
                ),
                isMyAccount ? React.createElement(
                    "li",
                    {
                        className: cnames({
                            active: active.indexOf("/vesting") !== -1
                        }),
                        onClick: this.props.onNavigate.bind(this, "/account/" + currentAccount + "/vesting")
                    },
                    React.createElement(
                        "div",
                        { className: "table-cell" },
                        React.createElement(Icon, {
                            size: "2x",
                            name: "hourglass",
                            title: "icons.hourglass"
                        })
                    ),
                    React.createElement(
                        "div",
                        { className: "table-cell" },
                        React.createElement(Translate, { content: "account.vesting.title" })
                    )
                ) : null,
                React.createElement(
                    "li",
                    {
                        className: cnames({
                            active: active.indexOf("/whitelist") !== -1,
                            disabled: !showAccountLinks
                        }),
                        onClick: this.props.onNavigate.bind(this, "/account/" + currentAccount + "/whitelist")
                    },
                    React.createElement(
                        "div",
                        { className: "table-cell" },
                        React.createElement(Icon, { size: "2x", name: "list", title: "icons.list" })
                    ),
                    React.createElement(
                        "div",
                        { className: "table-cell" },
                        React.createElement(Translate, { content: "account.whitelist.title" })
                    )
                ),
                React.createElement(
                    "li",
                    {
                        className: cnames("divider", {
                            active: active.indexOf("/permissions") !== -1,
                            disabled: !showAccountLinks
                        }),
                        onClick: this.props.onNavigate.bind(this, "/account/" + currentAccount + "/permissions")
                    },
                    React.createElement(
                        "div",
                        { className: "table-cell" },
                        React.createElement(Icon, { size: "2x", name: "warning", title: "icons.warning" })
                    ),
                    React.createElement(
                        "div",
                        { className: "table-cell" },
                        React.createElement(Translate, { content: "account.permissions" })
                    )
                ),
                showAccountLinks ? React.createElement(
                    "li",
                    {
                        className: cnames({
                            active: active.indexOf("/accounts") !== -1
                        }, "divider"),
                        onClick: this.props.onNavigate.bind(this, "/accounts")
                    },
                    React.createElement(
                        "div",
                        { className: "table-cell" },
                        React.createElement(Icon, {
                            size: "2x",
                            name: "folder",
                            title: "icons.folder"
                        })
                    ),
                    React.createElement(
                        "div",
                        { className: "table-cell" },
                        React.createElement(Translate, { content: "explorer.accounts.title" })
                    )
                ) : null
            );
        }
    }]);

    return DropDownMenu;
}(React.Component);

export default DropDownMenu;