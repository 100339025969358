import React from "react";
import Translate from "react-translate-component";

export var GlobalParametersUpdate = function GlobalParametersUpdate(_ref) {
    var fromComponent = _ref.fromComponent;

    return React.createElement(
        "span",
        null,
        React.createElement(Translate, {
            component: "span",
            content: fromComponent === "proposed_operation" ? "proposal.global_parameters_update" : "transaction.global_parameters_update"
        })
    );
};