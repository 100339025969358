var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from "react";
import TimeAgo from "../Utility/TimeAgo";
import counterpart from "counterpart";
import PropTypes from "prop-types";
import BlockchainActions from "actions/BlockchainActions";
import BlockchainStore from "stores/BlockchainStore";
import { connect } from "alt-react";

/**
 * @brief displays block's date and time based on block number
 *
 * properties: block_number - number
 * Note, it fetches block header data only
 **/

var BlockTime = function (_React$Component) {
    _inherits(BlockTime, _React$Component);

    function BlockTime(props) {
        _classCallCheck(this, BlockTime);

        var _this = _possibleConstructorReturn(this, (BlockTime.__proto__ || Object.getPrototypeOf(BlockTime)).call(this, props));

        _this.state = { time: null };
        return _this;
    }

    _createClass(BlockTime, [{
        key: "componentWillMount",
        value: function componentWillMount() {
            if (!this.props.blockHeader) {
                BlockchainActions.getHeader.defer(this.props.block_number);
            }
        }
    }, {
        key: "shouldComponentUpdate",
        value: function shouldComponentUpdate(next_props) {
            return next_props.block_number !== this.props.block_number || next_props.blockHeader !== this.props.blockHeader;
        }
    }, {
        key: "render",
        value: function render() {
            return React.createElement(
                "span",
                { className: "time", key: this.props.block_number },
                this.props.blockHeader ? this.props.fullDate ? counterpart.localize(this.props.blockHeader.timestamp, {
                    type: "date",
                    format: "full"
                }) : React.createElement(TimeAgo, { time: this.props.blockHeader.timestamp }) : null
            );
        }
    }]);

    return BlockTime;
}(React.Component);

BlockTime.propTypes = {
    block_number: PropTypes.number.isRequired
};


BlockTime = connect(BlockTime, {
    listenTo: function listenTo() {
        return [BlockchainStore];
    },
    getProps: function getProps(props) {
        return {
            blockHeader: BlockchainStore.getState().blockHeaders.get(props.block_number)
        };
    }
});

export default BlockTime;