var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from "react";
import { connect } from "alt-react";
import classNames from "classnames";
import AccountActions from "actions/AccountActions";
import AccountStore from "stores/AccountStore";
import AccountNameInput from "./../Forms/AccountNameInput";
import WalletDb from "stores/WalletDb";
import { Link } from "react-router-dom";
import AccountSelect from "../Forms/AccountSelect";
import TransactionConfirmStore from "stores/TransactionConfirmStore";
import LoadingIndicator from "../LoadingIndicator";
import Translate from "react-translate-component";
import counterpart from "counterpart";
import { ChainStore, FetchChain, key } from "bitsharesjs";
import ReactTooltip from "react-tooltip";
import utils from "common/utils";
import SettingsActions from "actions/SettingsActions";
import WalletUnlockActions from "actions/WalletUnlockActions";
import Icon from "../Icon/Icon";
import CopyButton from "../Utility/CopyButton";
import { withRouter } from "react-router-dom";
import { scroller } from "react-scroll";
import { Notification, Tooltip } from "bitshares-ui-style-guide";

var CreateAccountPassword = function (_React$Component) {
    _inherits(CreateAccountPassword, _React$Component);

    function CreateAccountPassword() {
        _classCallCheck(this, CreateAccountPassword);

        var _this = _possibleConstructorReturn(this, (CreateAccountPassword.__proto__ || Object.getPrototypeOf(CreateAccountPassword)).call(this));

        _this.state = {
            validAccountName: false,
            accountName: "",
            validPassword: false,
            registrar_account: null,
            loading: false,
            hide_refcode: true,
            show_identicon: false,
            step: 1,
            showPass: false,
            generatedPassword: ("P" + key.get_random_key().toWif()).substr(0, 45),
            confirm_password: "",
            understand_1: false,
            understand_2: false,
            understand_3: false
        };
        _this.onFinishConfirm = _this.onFinishConfirm.bind(_this);

        _this.accountNameInput = null;

        _this.scrollToInput = _this.scrollToInput.bind(_this);
        return _this;
    }

    _createClass(CreateAccountPassword, [{
        key: "componentWillMount",
        value: function componentWillMount() {
            if (!WalletDb.getWallet()) {
                SettingsActions.changeSetting({
                    setting: "passwordLogin",
                    value: true
                });
            }
        }
    }, {
        key: "componentDidMount",
        value: function componentDidMount() {
            ReactTooltip.rebuild();
            this.scrollToInput();
        }
    }, {
        key: "shouldComponentUpdate",
        value: function shouldComponentUpdate(nextProps, nextState) {
            return !utils.are_equal_shallow(nextState, this.state);
        }
    }, {
        key: "scrollToInput",
        value: function scrollToInput() {
            scroller.scrollTo("scrollToInput", {
                duration: 1500,
                delay: 100,
                smooth: true,
                containerId: "accountForm"
            });
        }
    }, {
        key: "isValid",
        value: function isValid() {
            var firstAccount = AccountStore.getMyAccounts().length === 0;
            var valid = this.state.validAccountName;
            if (!WalletDb.getWallet()) {
                valid = valid && this.state.validPassword;
            }
            if (!firstAccount) {
                valid = valid && this.state.registrar_account;
            }
            return valid && this.state.understand_1 && this.state.understand_2;
        }
    }, {
        key: "onAccountNameChange",
        value: function onAccountNameChange(e) {
            var state = {};
            if (e.valid !== undefined) state.validAccountName = e.valid;
            if (e.value !== undefined) state.accountName = e.value;
            if (!this.state.show_identicon) state.show_identicon = true;
            this.setState(state);
        }
    }, {
        key: "onFinishConfirm",
        value: function onFinishConfirm(confirm_store_state) {
            var _this2 = this;

            if (confirm_store_state.included && confirm_store_state.broadcasted_transaction) {
                TransactionConfirmStore.unlisten(this.onFinishConfirm);
                TransactionConfirmStore.reset();

                FetchChain("getAccount", this.state.accountName, undefined, _defineProperty({}, this.state.accountName, true)).then(function () {
                    _this2.props.history.push("/wallet/backup/create?newAccount=true");
                });
            }
        }
    }, {
        key: "_unlockAccount",
        value: function _unlockAccount(name, password) {
            SettingsActions.changeSetting({
                setting: "passwordLogin",
                value: true
            });

            WalletDb.validatePassword(password, true, name);
            WalletUnlockActions.checkLock.defer();
        }
    }, {
        key: "createAccount",
        value: function createAccount(name, password) {
            var _this3 = this;

            var refcode = this.refs.refcode ? this.refs.refcode.value() : null;
            var referralAccount = AccountStore.getState().referralAccount;
            this.setState({ loading: true });

            AccountActions.createAccountWithPassword(name, password, this.state.registrar_account, referralAccount || this.state.registrar_account, 0, refcode).then(function () {
                AccountActions.setPasswordAccount(name);
                // User registering his own account
                if (_this3.state.registrar_account) {
                    FetchChain("getAccount", name, undefined, _defineProperty({}, name, true)).then(function () {
                        _this3.setState({
                            step: 2,
                            loading: false
                        });
                        _this3._unlockAccount(name, password);
                    });
                    TransactionConfirmStore.listen(_this3.onFinishConfirm);
                } else {
                    // Account registered by the faucet
                    FetchChain("getAccount", name, undefined, _defineProperty({}, name, true)).then(function () {
                        _this3.setState({
                            step: 2
                        });
                        _this3._unlockAccount(name, password);
                    });
                }
            }).catch(function (error) {
                console.log("ERROR AccountActions.createAccount", error);
                var error_msg = error.base && error.base.length && error.base.length > 0 ? error.base[0] : "unknown error";
                if (error.remote_ip) error_msg = error.remote_ip[0];

                Notification.error({
                    message: counterpart.translate("notifications.account_create_failure", {
                        account_name: name,
                        error_msg: error_msg
                    })
                });

                _this3.setState({ loading: false });
            });
        }
    }, {
        key: "onSubmit",
        value: function onSubmit(e) {
            e.preventDefault();
            if (!this.isValid()) return;
            var account_name = this.accountNameInput.getValue();
            // if (WalletDb.getWallet()) {
            //     this.createAccount(account_name);
            // } else {
            var password = this.state.generatedPassword;
            this.createAccount(account_name, password);
        }
    }, {
        key: "onRegistrarAccountChange",
        value: function onRegistrarAccountChange(registrar_account) {
            this.setState({ registrar_account: registrar_account });
        }

        // showRefcodeInput(e) {
        //     e.preventDefault();
        //     this.setState({hide_refcode: false});
        // }

    }, {
        key: "_onInput",
        value: function _onInput(value, e) {
            var _setState;

            this.setState((_setState = {}, _defineProperty(_setState, value, value === "confirm_password" ? e.target.value : !this.state[value]), _defineProperty(_setState, "validPassword", value === "confirm_password" ? e.target.value === this.state.generatedPassword : this.state.validPassword), _setState));
        }
    }, {
        key: "_renderAccountCreateForm",
        value: function _renderAccountCreateForm() {
            var _this4 = this;

            var registrar_account = this.state.registrar_account;


            var my_accounts = AccountStore.getMyAccounts();
            var firstAccount = my_accounts.length === 0;
            var valid = this.isValid();
            var isLTM = false;
            var registrar = registrar_account ? ChainStore.getAccount(registrar_account) : null;
            if (registrar) {
                if (registrar.get("lifetime_referrer") == registrar.get("id")) {
                    isLTM = true;
                }
            }

            var buttonClass = classNames("submit-button button no-margin", {
                disabled: !valid || registrar_account && !isLTM
            });

            return React.createElement(
                "div",
                { style: { textAlign: "left" } },
                React.createElement(
                    "form",
                    {
                        style: { maxWidth: "60rem" },
                        onSubmit: this.onSubmit.bind(this),
                        noValidate: true
                    },
                    React.createElement(AccountNameInput, {
                        ref: function ref(_ref) {
                            if (_ref) {
                                _this4.accountNameInput = _ref.refs.nameInput;
                            }
                        },
                        cheapNameOnly: !!firstAccount,
                        onChange: this.onAccountNameChange.bind(this),
                        accountShouldNotExist: true,
                        placeholder: counterpart.translate("wallet.account_public"),
                        noLabel: true
                    }),
                    React.createElement(
                        "section",
                        { className: "form-group" },
                        React.createElement(
                            "label",
                            { className: "left-label" },
                            React.createElement(Translate, { content: "wallet.generated" }),
                            "\xA0\xA0",
                            React.createElement(
                                Tooltip,
                                {
                                    title: counterpart.translate("tooltip.generate")
                                },
                                React.createElement(
                                    "span",
                                    { className: "tooltip" },
                                    React.createElement(Icon, {
                                        name: "question-circle",
                                        title: "icons.question_circle"
                                    })
                                )
                            )
                        ),
                        React.createElement(
                            "div",
                            { style: { paddingBottom: "0.5rem" } },
                            React.createElement(
                                "span",
                                { className: "inline-label" },
                                React.createElement(
                                    "textarea",
                                    {
                                        style: {
                                            padding: "0px",
                                            marginBottom: "0px"
                                        },
                                        rows: "3",
                                        readOnly: true,
                                        disabled: true
                                    },
                                    this.state.generatedPassword
                                ),
                                React.createElement(CopyButton, {
                                    text: this.state.generatedPassword,
                                    tip: "tooltip.copy_password",
                                    dataPlace: "top"
                                })
                            )
                        )
                    ),
                    React.createElement(
                        "section",
                        null,
                        React.createElement(
                            "label",
                            { className: "left-label" },
                            React.createElement(Translate, { content: "wallet.confirm_password" })
                        ),
                        React.createElement("input", {
                            type: "password",
                            name: "password",
                            id: "password",
                            value: this.state.confirm_password,
                            onChange: this._onInput.bind(this, "confirm_password")
                        }),
                        this.state.confirm_password && this.state.confirm_password !== this.state.generatedPassword ? React.createElement(
                            "div",
                            { className: "has-error" },
                            React.createElement(Translate, { content: "wallet.confirm_error" })
                        ) : null
                    ),
                    React.createElement("br", null),
                    React.createElement(
                        "div",
                        {
                            className: "confirm-checks",
                            style: { paddingBottom: "1.5rem" },
                            onClick: this._onInput.bind(this, "understand_2")
                        },
                        React.createElement(
                            "label",
                            {
                                htmlFor: "checkbox-3",
                                style: { position: "relative" }
                            },
                            React.createElement("input", {
                                type: "checkbox",
                                id: "checkbox-4",
                                onChange: function onChange() {},
                                checked: this.state.understand_2,
                                style: {
                                    position: "absolute",
                                    top: "-5px",
                                    left: "0"
                                }
                            }),
                            React.createElement(
                                "div",
                                { style: { paddingLeft: "30px" } },
                                React.createElement(
                                    "span",
                                    { className: "caption-text orange" },
                                    this.state.accountName
                                ),
                                " ",
                                React.createElement(Translate, { content: "account.my_bts" }),
                                " ",
                                React.createElement(
                                    "span",
                                    { className: "caption-text orange" },
                                    "DO NOT add a suffix (E.G. -bts-1, -bts66, -wallet, -deposit or etc)"
                                )
                            )
                        ),
                        React.createElement(
                            "small",
                            null,
                            React.createElement(
                                "label",
                                null,
                                React.createElement(Translate, { content: "account.your_scam" })
                            )
                        )
                    ),
                    React.createElement(
                        "div",
                        {
                            className: "confirm-checks",
                            onClick: this._onInput.bind(this, "understand_3")
                        },
                        React.createElement(
                            "label",
                            {
                                htmlFor: "checkbox-1",
                                style: { position: "relative" }
                            },
                            React.createElement("input", {
                                type: "checkbox",
                                id: "checkbox-1",
                                onChange: function onChange() {},
                                checked: this.state.understand_3,
                                style: {
                                    position: "absolute",
                                    top: "-5px",
                                    left: "0"
                                }
                            }),
                            React.createElement(
                                "div",
                                { style: { paddingLeft: "30px" } },
                                React.createElement(Translate, { content: "wallet.understand_3" })
                            )
                        )
                    ),
                    React.createElement("br", null),
                    React.createElement(
                        "div",
                        {
                            className: "confirm-checks",
                            onClick: this._onInput.bind(this, "understand_1")
                        },
                        React.createElement(
                            "label",
                            {
                                htmlFor: "checkbox-2",
                                style: { position: "relative" }
                            },
                            React.createElement("input", {
                                type: "checkbox",
                                id: "checkbox-2",
                                onChange: function onChange() {},
                                checked: this.state.understand_1,
                                style: {
                                    position: "absolute",
                                    top: "-5px",
                                    left: "0"
                                }
                            }),
                            React.createElement(
                                "div",
                                { style: { paddingLeft: "30px" } },
                                React.createElement(Translate, { content: "wallet.understand_1" })
                            )
                        )
                    ),
                    React.createElement("br", null),
                    React.createElement(
                        "div",
                        {
                            className: "confirm-checks",
                            style: { paddingBottom: "1.5rem" },
                            onClick: this._onInput.bind(this, "understand_2")
                        },
                        React.createElement(
                            "label",
                            {
                                htmlFor: "checkbox-3",
                                style: { position: "relative" }
                            },
                            React.createElement("input", {
                                type: "checkbox",
                                id: "checkbox-3",
                                onChange: function onChange() {},
                                checked: this.state.understand_2,
                                style: {
                                    position: "absolute",
                                    top: "-5px",
                                    left: "0"
                                }
                            }),
                            React.createElement(
                                "div",
                                { style: { paddingLeft: "30px" } },
                                React.createElement(Translate, { content: "wallet.understand_2" })
                            )
                        ),
                        React.createElement(
                            "p",
                            null,
                            React.createElement(
                                "span",
                                { className: "caption-text text-info" },
                                "Official XBTS group ",
                                React.createElement(
                                    "a",
                                    { target: "_blank", href: "https://t.me/xbtsio" },
                                    "https://t.me/xbtsio"
                                )
                            )
                        )
                    ),
                    firstAccount ? null : React.createElement(
                        "div",
                        {
                            className: "full-width-content form-group no-overflow",
                            style: { paddingTop: 30 }
                        },
                        React.createElement(
                            "label",
                            null,
                            React.createElement(Translate, { content: "account.pay_from" })
                        ),
                        React.createElement(AccountSelect, {
                            account_names: my_accounts,
                            onChange: this.onRegistrarAccountChange.bind(this)
                        }),
                        registrar_account && !isLTM ? React.createElement(
                            "div",
                            {
                                style: { textAlign: "left" },
                                className: "facolor-error"
                            },
                            React.createElement(Translate, { content: "wallet.must_be_ltm" })
                        ) : null
                    ),
                    this.state.loading ? React.createElement(LoadingIndicator, { type: "three-bounce" }) : React.createElement(
                        "button",
                        { style: { width: "100%" }, className: buttonClass },
                        React.createElement(Translate, { content: "account.create_account" })
                    )
                )
            );
        }
    }, {
        key: "_renderAccountCreateText",
        value: function _renderAccountCreateText() {
            var my_accounts = AccountStore.getMyAccounts();
            var firstAccount = my_accounts.length === 0;

            return React.createElement(
                "div",
                null,
                React.createElement(
                    "h4",
                    {
                        style: {
                            fontWeight: "normal",
                            fontFamily: "Roboto-Medium, arial, sans-serif",
                            fontStyle: "normal",
                            paddingBottom: 15
                        }
                    },
                    React.createElement(Translate, { content: "wallet.wallet_password" })
                ),
                React.createElement(Translate, {
                    style: { textAlign: "left" },
                    unsafe: true,
                    component: "p",
                    content: "wallet.create_account_password_text"
                }),
                React.createElement(Translate, {
                    style: { textAlign: "left" },
                    component: "p",
                    content: "wallet.create_account_text"
                }),
                firstAccount ? null : React.createElement(Translate, {
                    style: { textAlign: "left" },
                    component: "p",
                    content: "wallet.not_first_account"
                })
            );
        }
    }, {
        key: "_renderBackup",
        value: function _renderBackup() {
            var _this5 = this;

            return React.createElement(
                "div",
                { className: "backup-submit" },
                React.createElement(
                    "p",
                    null,
                    React.createElement(Translate, { unsafe: true, content: "wallet.password_crucial" })
                ),
                React.createElement(
                    "div",
                    null,
                    !this.state.showPass ? React.createElement(
                        "div",
                        {
                            onClick: function onClick() {
                                _this5.setState({ showPass: true });
                            },
                            className: "button"
                        },
                        React.createElement(Translate, { content: "wallet.password_show" })
                    ) : React.createElement(
                        "div",
                        null,
                        React.createElement(
                            "h5",
                            null,
                            React.createElement(Translate, { content: "settings.password" }),
                            ":"
                        ),
                        React.createElement(
                            "p",
                            {
                                style: {
                                    fontWeight: "normal",
                                    fontFamily: "Roboto-Medium, arial, sans-serif",
                                    fontStyle: "normal",
                                    textAlign: "center"
                                }
                            },
                            this.state.generatedPassword
                        )
                    )
                ),
                React.createElement("div", { className: "divider" }),
                React.createElement(
                    "p",
                    { className: "txtlabel warning" },
                    React.createElement(Translate, { unsafe: true, content: "wallet.password_lose_warning" })
                ),
                React.createElement(
                    "div",
                    {
                        style: { width: "100%" },
                        onClick: function onClick() {
                            _this5.props.history.push("/");
                        },
                        className: "button"
                    },
                    React.createElement(Translate, { content: "wallet.ok_done" })
                )
            );
        }
    }, {
        key: "_renderGetStarted",
        value: function _renderGetStarted() {
            return React.createElement(
                "div",
                null,
                React.createElement(
                    "table",
                    { className: "table" },
                    React.createElement(
                        "tbody",
                        null,
                        React.createElement(
                            "tr",
                            null,
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, { content: "wallet.tips_dashboard" }),
                                ":"
                            ),
                            React.createElement(
                                "td",
                                null,
                                React.createElement(
                                    Link,
                                    { to: "/" },
                                    React.createElement(Translate, { content: "header.dashboard" })
                                )
                            )
                        ),
                        React.createElement(
                            "tr",
                            null,
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, { content: "wallet.tips_account" }),
                                ":"
                            ),
                            React.createElement(
                                "td",
                                null,
                                React.createElement(
                                    Link,
                                    {
                                        to: "/account/" + this.state.accountName + "/overview"
                                    },
                                    React.createElement(Translate, { content: "wallet.link_account" })
                                )
                            )
                        ),
                        React.createElement(
                            "tr",
                            null,
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, { content: "wallet.tips_deposit" }),
                                ":"
                            ),
                            React.createElement(
                                "td",
                                null,
                                React.createElement(
                                    Link,
                                    { to: "/deposit-withdraw" },
                                    React.createElement(Translate, { content: "wallet.link_deposit" })
                                )
                            )
                        ),
                        React.createElement(
                            "tr",
                            null,
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, { content: "wallet.tips_transfer" }),
                                ":"
                            ),
                            React.createElement(
                                "td",
                                null,
                                React.createElement(
                                    Link,
                                    { to: "/transfer" },
                                    React.createElement(Translate, { content: "wallet.link_transfer" })
                                )
                            )
                        ),
                        React.createElement(
                            "tr",
                            null,
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, { content: "wallet.tips_settings" }),
                                ":"
                            ),
                            React.createElement(
                                "td",
                                null,
                                React.createElement(
                                    Link,
                                    { to: "/settings" },
                                    React.createElement(Translate, { content: "header.settings" })
                                )
                            )
                        )
                    )
                )
            );
        }
    }, {
        key: "_renderGetStartedText",
        value: function _renderGetStartedText() {
            return React.createElement(
                "div",
                null,
                React.createElement(
                    "p",
                    {
                        style: {
                            fontWeight: "normal",
                            fontFamily: "Roboto-Medium, arial, sans-serif",
                            fontStyle: "normal"
                        }
                    },
                    React.createElement(Translate, { content: "wallet.congrat" })
                ),
                React.createElement(
                    "p",
                    null,
                    React.createElement(Translate, { content: "wallet.tips_explore_pass" })
                ),
                React.createElement(
                    "p",
                    null,
                    React.createElement(Translate, { content: "wallet.tips_header" })
                ),
                React.createElement(
                    "p",
                    { className: "txtlabel warning" },
                    React.createElement(Translate, { content: "wallet.tips_login" })
                )
            );
        }
    }, {
        key: "render",
        value: function render() {
            var step = this.state.step;
            // let my_accounts = AccountStore.getMyAccounts();
            // let firstAccount = my_accounts.length === 0;

            return React.createElement(
                "div",
                {
                    className: "sub-content",
                    id: "scrollToInput",
                    name: "scrollToInput"
                },
                React.createElement(
                    "div",
                    null,
                    step === 2 ? React.createElement(
                        "p",
                        {
                            style: {
                                fontWeight: "normal",
                                fontFamily: "Roboto-Medium, arial, sans-serif",
                                fontStyle: "normal"
                            }
                        },
                        React.createElement(Translate, { content: "wallet.step_" + step })
                    ) : null,
                    step === 3 ? this._renderGetStartedText() : null,
                    step === 1 ? React.createElement(
                        "div",
                        null,
                        this._renderAccountCreateForm()
                    ) : step === 2 ? this._renderBackup() : this._renderGetStarted()
                )
            );
        }
    }]);

    return CreateAccountPassword;
}(React.Component);

CreateAccountPassword = withRouter(CreateAccountPassword);

export default connect(CreateAccountPassword, {
    listenTo: function listenTo() {
        return [AccountStore];
    },
    getProps: function getProps() {
        return {};
    }
});