var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from "react";
import PropTypes from "prop-types";
import { connect } from "alt-react";
import AccountActions from "actions/AccountActions";
import AccountStore from "stores/AccountStore";
import WalletDb from "stores/WalletDb";
import counterpart from "counterpart";
import TransactionConfirmStore from "stores/TransactionConfirmStore";
import Translate from "react-translate-component";
import { FetchChain } from "bitsharesjs/es";
import WalletUnlockActions from "actions/WalletUnlockActions";
import Icon from "components/Icon/Icon";
import { Notification, Button, Input, Checkbox, Form, Alert } from "bitshares-ui-style-guide";
import CopyButton from "../Utility/CopyButton";

var AccountRegistrationConfirm = function (_React$Component) {
    _inherits(AccountRegistrationConfirm, _React$Component);

    function AccountRegistrationConfirm() {
        _classCallCheck(this, AccountRegistrationConfirm);

        var _this = _possibleConstructorReturn(this, (AccountRegistrationConfirm.__proto__ || Object.getPrototypeOf(AccountRegistrationConfirm)).call(this));

        _this.state = {
            confirmed: false
        };
        _this.onFinishConfirm = _this.onFinishConfirm.bind(_this);
        _this.toggleConfirmed = _this.toggleConfirmed.bind(_this);
        _this.createAccount = _this.createAccount.bind(_this);
        _this.onCreateAccount = _this.onCreateAccount.bind(_this);
        return _this;
    }

    _createClass(AccountRegistrationConfirm, [{
        key: "shouldComponentUpdate",
        value: function shouldComponentUpdate(nextProps, nextState) {
            return nextState.confirmed !== this.state.confirmed;
        }
    }, {
        key: "onFinishConfirm",
        value: function onFinishConfirm(confirmStoreState) {
            var _this2 = this;

            if (confirmStoreState.included && confirmStoreState.broadcasted_transaction) {
                TransactionConfirmStore.unlisten(this.onFinishConfirm);
                TransactionConfirmStore.reset();

                FetchChain("getAccount", this.state.accountName).then(function () {
                    _this2.props.history.push("/wallet/backup/create?newAccount=true");
                });
            }
        }
    }, {
        key: "onCreateAccount",
        value: function onCreateAccount(e) {
            e.preventDefault();
            this.createAccount(this.props.accountName, this.props.password);
        }
    }, {
        key: "createAccount",
        value: function createAccount(name, password) {
            var _this3 = this;

            var _AccountStore$getStat = AccountStore.getState(),
                referralAccount = _AccountStore$getStat.referralAccount;

            AccountActions.createAccountWithPassword(name, password, this.state.registrarAccount, referralAccount || this.state.registrarAccount, 0).then(function () {
                AccountActions.setPasswordAccount(name);
                if (_this3.state.registrarAccount) {
                    FetchChain("getAccount", name).then(function () {
                        _this3.unlockAccount(name, password);
                    });
                    TransactionConfirmStore.listen(_this3.onFinishConfirm);
                } else {
                    FetchChain("getAccount", name).then(function () {});
                    _this3.unlockAccount(name, password);
                    _this3.props.history.push("/");
                }
            }).catch(function (error) {
                console.log("ERROR AccountActions.createAccount", error);
                var errorMsg = error.base && error.base.length && error.base.length > 0 ? error.base[0] : "unknown error";
                if (error.remote_ip) {
                    var _error$remote_ip = _slicedToArray(error.remote_ip, 1);

                    errorMsg = _error$remote_ip[0];
                }
                Notification.error({
                    message: counterpart.translate("account_create_failure", {
                        account_name: name,
                        error_msg: errorMsg
                    })
                });
            });
        }
    }, {
        key: "unlockAccount",
        value: function unlockAccount(name, password) {
            WalletDb.validatePassword(password, true, name);
            WalletUnlockActions.checkLock.defer();
        }
    }, {
        key: "toggleConfirmed",
        value: function toggleConfirmed(e) {
            this.setState({
                confirmed: e.target.checked
            });
        }
    }, {
        key: "render",
        value: function render() {
            return React.createElement(
                Form,
                { layout: "vertical" },
                React.createElement(
                    Form.Item,
                    {
                        label: counterpart.translate("registration.copyPassword")
                    },
                    React.createElement(Input.TextArea, {
                        disabled: true,
                        rows: 2,
                        id: "password",
                        value: this.props.password
                    }),
                    React.createElement(CopyButton, {
                        text: this.state.generatedPassword,
                        tip: "tooltip.copy_password",
                        dataPlace: "top",
                        className: "button registration-layout--copy-password-btn"
                    })
                ),
                React.createElement(
                    Form.Item,
                    null,
                    React.createElement(Alert, {
                        showIcon: true,
                        type: "warning",
                        message: "",
                        description: counterpart.translate("registration.accountNote")
                    })
                ),
                React.createElement(
                    Form.Item,
                    null,
                    React.createElement(
                        Checkbox,
                        {
                            checked: this.state.confirmed,
                            onChange: this.toggleConfirmed
                        },
                        React.createElement(Translate, {
                            content: "registration.accountConfirmation",
                            className: "checkbox-text"
                        })
                    )
                ),
                React.createElement(
                    Form.Item,
                    null,
                    React.createElement(
                        Button,
                        {
                            type: "primary",
                            disabled: !this.state.confirmed,
                            onClick: this.onCreateAccount
                        },
                        React.createElement(Translate, { content: "account.create_account" })
                    )
                )
            );
        }
    }]);

    return AccountRegistrationConfirm;
}(React.Component);

AccountRegistrationConfirm.propTypes = {
    accountName: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    toggleConfirmed: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired
};


export default connect(AccountRegistrationConfirm, {
    listenTo: function listenTo() {
        return [AccountStore];
    },
    getProps: function getProps() {
        return {};
    }
});