import _debounce from "lodash-es/debounce";
import _extend from "lodash-es/extend";

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from "react";
import BindToChainState from "components/Utility/BindToChainState";
import DepositWithdrawAssetSelector from "../DepositWithdraw/DepositWithdrawAssetSelector";
import Translate from "react-translate-component";
import ExchangeInput from "components/Exchange/ExchangeInput";
import AssetName from "../Utility/AssetName";

import GatewayStore from "stores/GatewayStore";
import AssetStore from "stores/AssetStore";
import MarketsStore from "stores/MarketsStore";
import MarketsActions from "actions/MarketsActions";
import { connect } from "alt-react";
import SettingsStore from "stores/SettingsStore";
import Immutable from "immutable";
import { Asset, Price } from "common/MarketClasses";
import utils from "common/utils";
import MarketUtils from "common/market_utils";
import BalanceWrapper from "../Account/BalanceWrapper";
import AccountActions from "actions/AccountActions";
import AccountStore from "stores/AccountStore";
import ChainTypes from "../Utility/ChainTypes";
import FormattedAsset from "../Utility/FormattedAsset";
import BalanceComponent from "../Utility/BalanceComponent";
import QRScanner from "../QRAddressScanner";
import { Modal, Button, Select, Input } from "bitshares-ui-style-guide";
import counterpart from "counterpart";
import { gatewaySelector, _getNumberAvailableGateways, _onAssetSelected, _getCoinToGatewayMapping } from "lib/common/assetGatewayMixin";
import { getGatewayStatusByAsset } from "common/gatewayUtils";
import { availableGateways } from "common/gateways";
import { validateAddress as blocktradesValidateAddress, WithdrawAddresses } from "lib/common/gatewayMethods";
import AmountSelector from "components/Utility/AmountSelectorStyleGuide";
import { checkFeeStatusAsync, checkBalance } from "common/trxHelper";
import AccountSelector from "components/Account/AccountSelector";
import { ChainStore } from "bitsharesjs";
var gatewayBoolCheck = "withdrawalAllowed";

import { getAssetAndGateway, getIntermediateAccount } from "common/gatewayUtils";
import { isObject } from "util";
import PropTypes from "prop-types";

var bep8 = null;

var WithdrawModalNew = function (_React$Component) {
    _inherits(WithdrawModalNew, _React$Component);

    function WithdrawModalNew(props) {
        _classCallCheck(this, WithdrawModalNew);

        var _this = _possibleConstructorReturn(this, (WithdrawModalNew.__proto__ || Object.getPrototypeOf(WithdrawModalNew)).call(this, props));

        _this.state = {
            selectedAsset: "",
            selectedAssetId: "",
            selectedGateway: "XBTSX",
            fee: 0,
            feeAmount: new Asset({ amount: 0 }),
            feeStatus: {},
            hasBalance: null,
            hasPoolBalance: null,
            feeError: null,
            fee_asset_id: "1.3.0",
            gateFee: 0,
            quantity: 0,
            address: "",
            memo: "",
            userEstimate: null,
            addressError: false,
            gatewayStatus: availableGateways,
            withdrawalCurrencyId: "",
            withdrawalCurrencyBalance: null,
            withdrawalCurrencyBalanceId: "",
            withdrawalCurrencyPrecision: "",
            preferredCurrencyPrecision: "",
            precisionDifference: "",
            coreAsset: "",
            convertedBalance: "",
            estimatedValue: "",
            options_is_valid: false,
            btsAccountName: "",
            btsAccount: ""
        };

        _this.handleQrScanSuccess = _this.handleQrScanSuccess.bind(_this);
        _this._checkFeeStatus = _debounce(_this._checkFeeStatus.bind(_this), 250);
        _this._updateFee = _debounce(_this._updateFee.bind(_this), 250);
        return _this;
    }

    _createClass(WithdrawModalNew, [{
        key: "componentWillMount",
        value: function componentWillMount() {
            this._updateFee(this.state);
            this._checkFeeStatus();
            var initialState = {};

            var coinToGatewayMapping = _getCoinToGatewayMapping.call(this, gatewayBoolCheck);
            initialState.coinToGatewayMapping = coinToGatewayMapping;

            if (this.props.initialSymbol) {
                initialState = _extend(initialState, this._getAssetAndGatewayFromInitialSymbol(this.props.initialSymbol));

                initialState.gatewayStatus = getGatewayStatusByAsset.call(this, initialState.selectedAsset, gatewayBoolCheck);
            }

            this.setState(initialState);
            this.setState(this._getAssetPairVariables(this.props, initialState));
        }
    }, {
        key: "_getAssetAndGatewayFromInitialSymbol",
        value: function _getAssetAndGatewayFromInitialSymbol(initialSymbol) {
            var _getAssetAndGateway = getAssetAndGateway(initialSymbol),
                selectedAsset = _getAssetAndGateway.selectedAsset,
                selectedGateway = _getAssetAndGateway.selectedGateway;

            var gateFee = 0;

            var gatePrefix = null;

            if (selectedGateway === "WAVES" || selectedGateway === "BNB" || selectedGateway === "ETH" || selectedGateway === "HECO") {
                gatePrefix = "XBTSX";
            }

            if (selectedGateway) {
                this.props.backedCoins.get(selectedGateway).forEach(function (item) {
                    if (item.symbol === [gatePrefix, selectedAsset].join(".") || item.backingCoinType === selectedAsset) {
                        gateFee = item.gateFee;
                    }
                });
            }

            return { selectedAsset: selectedAsset, selectedGateway: selectedGateway, gateFee: gateFee };
        }
    }, {
        key: "componentWillReceiveProps",
        value: function componentWillReceiveProps(np) {
            this.setState(this._getAssetPairVariables(np));

            if (np.account !== this.props.account) {
                this._checkFeeStatus();
                this._updateFee();
            }

            if (this.state.address != "") {
                this.onAddressSelected(this.state.address);
            }

            if (np.initialSymbol !== this.props.initialSymbol) {
                var newState = this._getAssetAndGatewayFromInitialSymbol(np.initialSymbol);
                newState.gatewayStatus = getGatewayStatusByAsset.call(this, newState.selectedAsset, gatewayBoolCheck);
                newState.address = "";
                newState.quantity = 0;
                this.setState(newState);
            }
        }
    }, {
        key: "componentWillUpdate",
        value: function componentWillUpdate(nextProps, nextState) {
            var preferredCurrency = nextProps.preferredCurrency,
                assets = nextProps.assets;
            var selectedAsset = nextState.selectedAsset,
                quantity = nextState.quantity,
                selectedGateway = nextState.selectedGateway;


            if (preferredCurrency && selectedAsset && quantity) {
                if (preferredCurrency === this.props.preferredCurrency && selectedAsset === this.state.selectedAsset && quantity === this.state.quantity) return;
                var toAsset = null;
                var fromAsset = null;
                var gatePrefix = null;

                if (selectedGateway === "WAVES" || selectedGateway === "BNB" || selectedGateway === "ETH" || selectedGateway === "HECO") {
                    gatePrefix = "XBTSX";
                }

                var fullFromAssetSymbol = gatePrefix + "." + selectedAsset;

                assets.forEach(function (item) {
                    item = item.get ? item : Immutable.fromJS(item);
                    if (item.get("symbol") === preferredCurrency) toAsset = item;
                    if (item.get("symbol") === fullFromAssetSymbol) fromAsset = item;
                });

                if (fromAsset && toAsset) {
                    // todo: when was this used and what is it good for?
                    // if (toAsset.get("precision") !== fromAsset.get("precision"))
                    //     toAsset = toAsset.set(
                    //         "precision",
                    //         fromAsset.get("precision")
                    //     );

                    MarketsActions.getMarketStats(toAsset, fromAsset, true);
                }
            }
        }
    }, {
        key: "_getAssetPairVariables",
        value: function _getAssetPairVariables() {
            var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.props;
            var state = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this.state;
            var assets = props.assets,
                marketStats = props.marketStats,
                balances = props.balances,
                preferredCurrency = props.preferredCurrency;
            var selectedAsset = state.selectedAsset,
                quantity = state.quantity,
                selectedGateway = state.selectedGateway,
                gateFee = state.gateFee;

            if (isNaN(gateFee)) gateFee = 0;
            quantity = Number(quantity);
            if (isNaN(quantity)) quantity = 0;
            gateFee = Number(gateFee);

            var prefix = selectedGateway;
            if (prefix === "BNB" || prefix === "WAVES" || prefix === "GDEX" || prefix === "ETH" || prefix === "HECO") {
                prefix = "XBTSX";
            }

            var fullSymbol = selectedGateway ? prefix + "." + selectedAsset : selectedAsset;

            /*
            if (selectedGateway === "RUDEX" && selectedAsset === "PPY") {
                fullSymbol = "PPY";
            }
             */

            var withdrawalCurrencyBalance = 0;
            var withdrawalCurrencyBalanceId = null;
            var withdrawalCurrencyPrecision = null;
            var preferredCurrencyPrecision = null;
            var precisionDifference = 0;
            var coreAsset = null;
            var convertedBalance = null;
            var estimatedValue = 0;

            var withdrawalCurrency = assets.find(function (item) {
                return item.symbol === fullSymbol;
            });

            var withdrawBalance = void 0,
                fromAsset = void 0;

            if (balances) {
                balances.forEach(function (balance) {
                    if (balance && balance.toJS) {
                        if (withdrawalCurrency && balance.get("asset_type") === withdrawalCurrency.id) {
                            withdrawBalance = balance;
                            withdrawalCurrencyBalanceId = balance.get("id");
                            withdrawalCurrencyBalance = balance.get("balance");
                        }
                    }
                });
            }

            if (!withdrawalCurrencyBalance) {
                //In case does not exist in balances
                withdrawalCurrencyBalance = 0;
            }

            if (preferredCurrency && selectedAsset) {
                var toAsset = null;

                assets.forEach(function (item) {
                    item = item.get ? item : Immutable.fromJS(item);
                    if (item.get("id") === "1.3.0") coreAsset = item;
                    if (item.get("symbol") === preferredCurrency) {
                        toAsset = item;
                        preferredCurrencyPrecision = item.get("precision");
                    }
                    var gatePrefix = null;
                    if (selectedGateway === "WAVES" || selectedGateway === "BNB" || selectedGateway === "ETH" || selectedGateway === "HECO") {
                        gatePrefix = "XBTSX";
                    }
                    if (item.get("symbol") === gatePrefix + "." + selectedAsset) {
                        fromAsset = item;
                        withdrawalCurrencyPrecision = item.get("precision");
                    }
                    if (item.get("symbol") === selectedAsset) {
                        fromAsset = item;
                        withdrawalCurrencyPrecision = item.get("precision");
                    }
                });

                if (preferredCurrencyPrecision && withdrawalCurrencyPrecision) {
                    precisionDifference = withdrawalCurrencyPrecision - preferredCurrencyPrecision;
                }

                if (quantity && fromAsset && toAsset) {
                    estimatedValue = quantity * MarketUtils.getFinalPrice(coreAsset, fromAsset, toAsset, marketStats, true, true);
                    if (precisionDifference > 0) {
                        //Need to compensate for different precisions between currencies
                        estimatedValue = estimatedValue * Math.pow(10, precisionDifference);
                    } //No need to compensate for precision difference < 0
                }
            }

            //console.log("withdrawalCurrencyBalance", withdrawalCurrencyBalance, withdrawalCurrencyPrecision)
            if (Number.isFinite(withdrawalCurrencyBalance) && withdrawalCurrencyPrecision) {
                var withdrawalCurrencyBalanceString = String(withdrawalCurrencyBalance);
                var l = withdrawalCurrencyBalanceString.length;

                while (l < withdrawalCurrencyPrecision) {
                    //Zero pad
                    withdrawalCurrencyBalanceString = "0" + withdrawalCurrencyBalanceString;
                    ++l;
                }

                var decimalPart = withdrawalCurrencyBalanceString.substr(0, l - withdrawalCurrencyPrecision);
                var mantissa = withdrawalCurrencyBalanceString.substr(l - withdrawalCurrencyPrecision);

                if (!decimalPart) {
                    decimalPart = "0";
                    mantissa = withdrawalCurrencyBalanceString;
                }

                convertedBalance = Number(decimalPart + "." + mantissa);
            }

            var nAvailableGateways = _getNumberAvailableGateways.call(this);
            var assetAndGateway = selectedAsset && selectedGateway;

            var isBTS = false;
            if (coreAsset) {
                if (selectedAsset === coreAsset.get("symbol")) isBTS = true;
            } else if (selectedAsset === "BTS") {
                isBTS = true;
            }

            var canCoverWithdrawal = quantity === 0 ? true : checkBalance(quantity, fromAsset, this.state.feeAmount, withdrawBalance);

            var _getAvailableAssets2 = this._getAvailableAssets(),
                fee_asset_types = _getAvailableAssets2.fee_asset_types;

            return {
                withdrawalCurrency: withdrawalCurrency,
                withdrawalCurrencyId: withdrawalCurrency ? withdrawalCurrency.id : null,
                withdrawalCurrencyBalance: withdrawalCurrencyBalance,
                withdrawalCurrencyBalanceId: withdrawalCurrencyBalanceId,
                withdrawalCurrencyPrecision: withdrawalCurrencyPrecision,
                preferredCurrencyPrecision: preferredCurrencyPrecision,
                precisionDifference: precisionDifference,
                coreAsset: coreAsset,
                convertedBalance: convertedBalance,
                estimatedValue: estimatedValue,
                nAvailableGateways: nAvailableGateways,
                assetAndGateway: assetAndGateway,
                isBTS: isBTS,
                canCoverWithdrawal: canCoverWithdrawal,
                fee_asset_types: fee_asset_types
            };
        }
    }, {
        key: "_getAvailableAssets",
        value: function _getAvailableAssets() {
            var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.state;

            var btsAccount = this.props.account;
            var feeStatus = state.feeStatus;

            function hasFeePoolBalance(id) {
                if (feeStatus[id] === undefined) return true;
                return feeStatus[id] && feeStatus[id].hasPoolBalance;
            }

            function hasBalance(id) {
                if (feeStatus[id] === undefined) return true;
                return feeStatus[id] && feeStatus[id].hasBalance;
            }

            var fee_asset_types = [];
            if (!(btsAccount && btsAccount.get("balances"))) {
                return { fee_asset_types: fee_asset_types };
            }
            var account_balances = btsAccount.get("balances").toJS();
            fee_asset_types = Object.keys(account_balances).sort(utils.sortID);
            for (var key in account_balances) {
                var asset = ChainStore.getObject(key);
                var balanceObject = ChainStore.getObject(account_balances[key]);
                if (balanceObject && balanceObject.get("balance") === 0) {
                    if (fee_asset_types.indexOf(key) !== -1) {
                        fee_asset_types.splice(fee_asset_types.indexOf(key), 1);
                    }
                }

                if (asset) {
                    // Remove any assets that do not have valid core exchange rates
                    var priceIsValid = false,
                        p = void 0;
                    try {
                        p = new Price({
                            base: new Asset(asset.getIn(["options", "core_exchange_rate", "base"]).toJS()),
                            quote: new Asset(asset.getIn(["options", "core_exchange_rate", "quote"]).toJS())
                        });
                        priceIsValid = p.isValid();
                    } catch (err) {
                        priceIsValid = false;
                    }

                    if (asset.get("id") !== "1.3.0" && !priceIsValid) {
                        fee_asset_types.splice(fee_asset_types.indexOf(key), 1);
                    }
                }
            }

            fee_asset_types = fee_asset_types.filter(function (a) {
                return hasFeePoolBalance(a) && hasBalance(a);
            });

            return { fee_asset_types: fee_asset_types };
        }
    }, {
        key: "_checkFeeStatus",
        value: function _checkFeeStatus() {
            var _this2 = this;

            var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.state;

            var account = this.props.account;
            if (!account) return;

            var _getAvailableAssets3 = this._getAvailableAssets(state),
                assets = _getAvailableAssets3.fee_asset_types;
            // const assets = ["1.3.0", this.props.asset.get("id")];


            var feeStatus = {};
            var p = [];
            var memoContent = state.selectedAsset.toLowerCase() + ":" + state.address + (state.memo ? ":" + state.memo : "");
            assets.forEach(function (a) {
                p.push(checkFeeStatusAsync({
                    accountID: account.get("id"),
                    feeID: a,
                    options: ["price_per_kbyte"],
                    data: {
                        type: "memo",
                        content: memoContent
                    }
                }));
            });
            Promise.all(p).then(function (status) {
                assets.forEach(function (a, idx) {
                    feeStatus[a] = status[idx];
                });
                if (!utils.are_equal_shallow(state.feeStatus, feeStatus)) {
                    _this2.setState({
                        feeStatus: feeStatus
                    });
                }
            }).catch(function (err) {
                console.error(err);
            });
        }
    }, {
        key: "_updateFee",
        value: function _updateFee() {
            var _this3 = this;

            var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.state;

            var btsAccount = this.props.account;
            var fee_asset_id = state.fee_asset_id;

            var _getAvailableAssets4 = this._getAvailableAssets(state),
                fee_asset_types = _getAvailableAssets4.fee_asset_types;

            if (fee_asset_types.length === 1 && fee_asset_types[0] !== fee_asset_id) {
                fee_asset_id = fee_asset_types[0];
            }

            if (!btsAccount) return null;
            var memoContent = state.selectedAsset.toLowerCase() + ":" + state.address + (state.memo ? ":" + state.memo : "");

            checkFeeStatusAsync({
                accountID: btsAccount.get("id"),
                feeID: fee_asset_id,
                options: ["price_per_kbyte"],
                data: {
                    type: "memo",
                    content: memoContent
                }
            }).then(function (_ref) {
                var fee = _ref.fee,
                    hasBalance = _ref.hasBalance,
                    hasPoolBalance = _ref.hasPoolBalance;

                if (_this3.unMounted) return;

                _this3.setState({
                    feeAmount: fee,
                    hasBalance: hasBalance,
                    hasPoolBalance: hasPoolBalance,
                    feeError: !hasBalance || !hasPoolBalance
                });
            });
        }
    }, {
        key: "_getBindingHelpers",
        value: function _getBindingHelpers() {
            var onFocus = this.onFocusAmount.bind(this);
            var onBlur = this.onBlurAmount.bind(this);

            return { onFocus: onFocus, onBlur: onBlur };
        }
    }, {
        key: "onFeeChanged",
        value: function onFeeChanged(_ref2) {
            var asset = _ref2.asset;

            // If asset is an object, just extract asset ID
            if (isObject(asset)) {
                asset = asset.get("id");
            }

            this.setState({
                fee_asset_id: asset
            }, this._updateFee);
        }
    }, {
        key: "onAssetSelected",
        value: function onAssetSelected(asset) {
            var _this4 = this;

            var _onAssetSelected$call = _onAssetSelected.call(this, asset.id, gatewayBoolCheck),
                selectedAsset = _onAssetSelected$call.selectedAsset,
                selectedGateway = _onAssetSelected$call.selectedGateway;

            var address = WithdrawAddresses.getLast(asset.id.toLowerCase());
            this.setState({
                selectedAsset: selectedAsset,
                selectedGateway: selectedGateway,
                gateFee: asset.gateFee,
                address: address,
                isBTS: false
            }, function () {
                _this4.setState(_this4._getAssetPairVariables(), _this4.updateFee);
            });
        }
    }, {
        key: "onAssetChanged",
        value: function onAssetChanged(value) {
            value = value.toUpperCase();

            var stateObj = {};

            if (value == "BTS") {
                stateObj = { isBTS: true };
            }

            if (!value) {
                stateObj = {
                    selectedAsset: "",
                    selectedGateway: "",
                    addressError: false,
                    fee: 0,
                    isBTS: false
                };
            }

            stateObj.estimatedValue = 0;
            stateObj.memo = "";
            stateObj.address = "";

            this.setState(stateObj);
        }
    }, {
        key: "onGatewayChanged",
        value: function onGatewayChanged(selectedGateway) {
            var _this5 = this;

            this.setState({ selectedGateway: selectedGateway }, function () {
                _this5.setState(_this5._getAssetPairVariables());
                _this5.updateGatewayFee();
            });
        }
    }, {
        key: "onQuantityChanged",
        value: function onQuantityChanged(e) {
            var input = null;
            if (parseFloat(e.target.value) == e.target.value) {
                input = e.target.value.trim();
            } else {
                var pasteValue = e.target.value.trim().replace(/[^\d.,-]/g, "");
                var decimal = pasteValue.match(/(\,\d{1,2})$/g);
                var decimalCount = decimal ? decimal.length : 0;
                if (decimal && decimalCount) {
                    pasteValue = pasteValue.replace(",", ".");
                }
                input = parseFloat(pasteValue.replace(",", "")) || 0;
            }
            this.setState({ quantity: input });
        }
    }, {
        key: "onEstimateChanged",
        value: function onEstimateChanged(e) {
            this.setState({ userEstimate: e.target.value });
        }
    }, {
        key: "onFocusAmount",
        value: function onFocusAmount(e) {
            var value = e.target.value;


            if (String(value) == "0") {
                e.target.value = "";
            }
        }
    }, {
        key: "onBlurAmount",
        value: function onBlurAmount(e) {
            var value = e.target.value;


            if (value === "") {
                e.target.value = 0;
            }
        }

        // Don't validate address on change.
        // Validation is done when address is selected

    }, {
        key: "onAddressChanged",
        value: function onAddressChanged(inputAddress) {
            this.setState({ address: inputAddress });
        }
    }, {
        key: "onAddressSelected",
        value: function onAddressSelected(inputAddress) {
            this.validateAddress(inputAddress);
            this.setState({ address: inputAddress }, this._updateFee);
        }
    }, {
        key: "_getBackingAssetProps",
        value: function _getBackingAssetProps() {
            var _state = this.state,
                selectedGateway = _state.selectedGateway,
                selectedAsset = _state.selectedAsset;

            return this.props.backedCoins.get(selectedGateway.toUpperCase(), []).find(function (c) {
                var backingCoin = c.backingCoinType || c.backingCoin;

                // Gateway has EOS.* asset names
                if (backingCoin.toUpperCase().indexOf("EOS.") !== -1) {
                    var _backingCoin$split = backingCoin.split("."),
                        _backingCoin$split2 = _slicedToArray(_backingCoin$split, 2),
                        _network = _backingCoin$split2[0],
                        _coin = _backingCoin$split2[1];

                    backingCoin = _coin;
                }

                return backingCoin === selectedAsset;
            });
        }
    }, {
        key: "updateGatewayFee",
        value: function updateGatewayFee() {
            var _state2 = this.state,
                selectedGateway = _state2.selectedGateway,
                selectedAsset = _state2.selectedAsset;

            var gateFee = 0;

            if (selectedGateway && selectedAsset) {
                this.props.backedCoins.get(selectedGateway).forEach(function (item) {
                    if (item.symbol === [selectedGateway, selectedAsset].join(".") || item.backingCoinType === selectedAsset) {
                        gateFee = item.gateFee || 0;
                    }
                });
            }

            this.setState({ gateFee: gateFee });
        }
    }, {
        key: "validateAddress",
        value: function validateAddress(address) {
            var _this6 = this;

            var _state3 = this.state,
                selectedGateway = _state3.selectedGateway,
                gatewayStatus = _state3.gatewayStatus;

            // Get Backing Asset Details for Gateway

            var backingAsset = this._getBackingAssetProps();

            blocktradesValidateAddress({
                url: gatewayStatus[selectedGateway].baseAPI.BASE,
                walletType: backingAsset.walletType,
                newAddress: address,
                output_coin_type: gatewayStatus[selectedGateway].addressValidatorAsset ? this.state.selectedGateway.toLowerCase() + "." + this.state.selectedAsset.toLowerCase() : null,
                method: gatewayStatus[selectedGateway].addressValidatorMethod || null
            }).then(function (isValid) {
                _this6.setState({ addressError: isValid ? false : true });
            });
        }
    }, {
        key: "onSelectedAddressChanged",
        value: function onSelectedAddressChanged(address) {
            var state = this.state;
            var selectedAsset = state.selectedAsset;

            var walletType = selectedAsset.toLowerCase();
            WithdrawAddresses.setLast({ wallet: walletType, address: address });

            this.validateAddress(address);
            this.setState({ address: address }, this._updateFee);
        }
    }, {
        key: "onMemoChanged",
        value: function onMemoChanged(e) {
            this.setState({ memo: e.target.value }, this._updateFee);
        }
    }, {
        key: "onClickAvailableBalance",
        value: function onClickAvailableBalance(available) {
            console.log("available", available);
            this.setState({ quantity: available });
        }
    }, {
        key: "onDropDownList",
        value: function onDropDownList() {
            var hasAsset = WithdrawAddresses.has(this.state.selectedAsset.toLowerCase());
            if (hasAsset) {
                if (this.state.options_is_valid === false) {
                    this.setState({ options_is_valid: true });
                }

                if (this.state.options_is_valid === true) {
                    this.setState({ options_is_valid: false });
                }
            }
        }
    }, {
        key: "onSubmit",
        value: function onSubmit() {
            var _this7 = this;

            var _state4 = this.state,
                withdrawalCurrencyId = _state4.withdrawalCurrencyId,
                withdrawalCurrencyBalance = _state4.withdrawalCurrencyBalance,
                withdrawalCurrencyPrecision = _state4.withdrawalCurrencyPrecision,
                quantity = _state4.quantity,
                withdrawalCurrency = _state4.withdrawalCurrency,
                selectedGateway = _state4.selectedGateway,
                selectedAsset = _state4.selectedAsset,
                address = _state4.address,
                isBTS = _state4.isBTS,
                gateFee = _state4.gateFee,
                memo = _state4.memo,
                btsAccount = _state4.btsAccount,
                feeAmount = _state4.feeAmount;


            var gatewayStatus = this.state.gatewayStatus[selectedGateway];
            var assetName = !!gatewayStatus.assetWithdrawlAlias ? gatewayStatus.assetWithdrawlAlias[selectedAsset.toLowerCase()] || selectedAsset.toLowerCase() : selectedAsset.toLowerCase();

            var intermediateAccountNameOrId = getIntermediateAccount(withdrawalCurrency.symbol, this.props.backedCoins);
            var intermediateAccount = this.props.intermediateAccounts.find(function (a) {
                return a && (a.get("id") === intermediateAccountNameOrId || a.get("name") === intermediateAccountNameOrId);
            });
            if (!intermediateAccount) throw new Error("Unable to find intermediateAccount");
            if (!WithdrawAddresses.has(assetName)) {
                var withdrawals = [];
                withdrawals.push(address);
                WithdrawAddresses.set({ wallet: assetName, addresses: withdrawals });
            } else {
                var _withdrawals = WithdrawAddresses.get(assetName);
                if (_withdrawals.indexOf(address) === -1) {
                    _withdrawals.push(address);
                    WithdrawAddresses.set({
                        wallet: assetName,
                        addresses: _withdrawals
                    });
                }
            }
            WithdrawAddresses.setLast({ wallet: assetName, address: address });

            var sendAmount = new Asset({
                asset_id: withdrawalCurrencyId,
                precision: withdrawalCurrencyPrecision,
                real: quantity
            });

            var balanceAmount = new Asset({
                asset_id: withdrawalCurrencyId,
                precision: withdrawalCurrencyPrecision,
                real: 0
            });

            if (withdrawalCurrencyBalance != null) {
                balanceAmount = sendAmount.clone(withdrawalCurrencyBalance);
            }

            var gateFeeAmount = new Asset({
                asset_id: withdrawalCurrencyId,
                precision: withdrawalCurrencyPrecision,
                real: gateFee
            });

            sendAmount.plus(gateFeeAmount);

            /* Insufficient balance */
            if (balanceAmount.lt(sendAmount)) {
                sendAmount = balanceAmount;
            }

            var descriptor = "";
            var to = "";

            if (isBTS) {
                descriptor = memo ? new Buffer(memo, "utf-8") : "";
                to = btsAccount.get("id");
            } else {
                assetName = gatewayStatus.useFullAssetName ? selectedGateway.toLowerCase() + "." + assetName : assetName;
                descriptor = assetName + ":" + address + (memo ? ":" + new Buffer(memo, "utf-8") : "");
                to = intermediateAccount.get("id");
            }

            var args = [this.props.account.get("id"), to, sendAmount.getAmount(), withdrawalCurrencyId, descriptor, null, feeAmount ? feeAmount.asset_id : "1.3.0"];

            AccountActions.transfer.apply(AccountActions, args).then(function () {
                _this7.props.hideModal();
            });
        }
    }, {
        key: "onBTSAccountNameChanged",
        value: function onBTSAccountNameChanged(btsAccountName) {
            if (!btsAccountName) this.setState({ btsAccount: null });
            this.setState({ btsAccountName: btsAccountName, btsAccountError: null });
        }
    }, {
        key: "onBTSAccountChanged",
        value: function onBTSAccountChanged(btsAccount) {
            this.setState({ btsAccount: btsAccount, btsAccountError: null });
        }
    }, {
        key: "_renderStoredAddresses",
        value: function _renderStoredAddresses() {
            var state = this.state;
            var selectedAsset = state.selectedAsset,
                address = state.address;

            var storedAddresses = WithdrawAddresses.get(selectedAsset.toLowerCase());

            if (storedAddresses.length > 1 && state.options_is_valid) {
                return React.createElement(
                    "div",
                    {
                        className: !storedAddresses.length ? "blocktrades-disabled-options" : "blocktrades-options"
                    },
                    storedAddresses.filter(function (item) {
                        return item != address;
                    }).map(function (name, index) {
                        return React.createElement(
                            "a",
                            {
                                key: index,
                                onClick: this.onSelectedAddressChanged.bind(this, name)
                            },
                            name
                        );
                    }, this)
                );
            }
        }
    }, {
        key: "handleQrScanSuccess",
        value: function handleQrScanSuccess(data) {
            // if user don't put quantity on field by himself
            // use amount detected on QR code
            if (!this.state.quantity) {
                this.setState({
                    address: data.address,
                    quantity: data.amount
                });
            } else {
                this.setState({
                    address: data.address
                });
            }

            this.onAddressSelected(data.address);
        }
    }, {
        key: "render",
        value: function render() {
            var state = this.state,
                props = this.props;
            var preferredCurrency = props.preferredCurrency,
                assets = props.assets,
                balances = props.balances;
            var _state5 = this.state,
                selectedAsset = _state5.selectedAsset,
                selectedGateway = _state5.selectedGateway,
                gatewayStatus = _state5.gatewayStatus,
                addressError = _state5.addressError,
                gateFee = _state5.gateFee,
                withdrawalCurrencyBalanceId = _state5.withdrawalCurrencyBalanceId,
                convertedBalance = _state5.convertedBalance,
                nAvailableGateways = _state5.nAvailableGateways,
                assetAndGateway = _state5.assetAndGateway,
                isBTS = _state5.isBTS,
                canCoverWithdrawal = _state5.canCoverWithdrawal,
                fee_asset_types = _state5.fee_asset_types,
                quantity = _state5.quantity,
                address = _state5.address,
                btsAccount = _state5.btsAccount,
                coinToGatewayMapping = _state5.coinToGatewayMapping;

            var symbolsToInclude = [];

            // Get Backing Asset for Gateway
            var backingAsset = this._getBackingAssetProps();

            var minWithdraw = null;
            var maxWithdraw = null;

            if (backingAsset && backingAsset.minAmount) {
                minWithdraw = !!backingAsset.precision ? utils.format_number(backingAsset.minAmount / utils.get_asset_precision(backingAsset.precision), backingAsset.precision, false) : backingAsset.minAmount;
            } else if (backingAsset) {
                minWithdraw = "gateFee" in backingAsset ? backingAsset.gateFee * 2 || 0 + backingAsset.transactionFee || 0 : 0;
            }

            if (backingAsset && backingAsset.maxAmount) {
                maxWithdraw = backingAsset.maxAmount;
            }

            balances.forEach(function (item) {
                var id = item.get("asset_type");
                var asset = assets.get(id);

                if (asset && item.get("balance") > 0) {
                    var _asset$symbol$split = asset.symbol.split("."),
                        _asset$symbol$split2 = _slicedToArray(_asset$symbol$split, 2),
                        _gateway = _asset$symbol$split2[0],
                        _asset = _asset$symbol$split2[1];

                    var find = !!_asset ? _asset : _gateway;
                    symbolsToInclude.push(find);
                }
            });

            var _getBindingHelpers2 = this._getBindingHelpers(),
                onFocus = _getBindingHelpers2.onFocus,
                onBlur = _getBindingHelpers2.onBlur;

            var shouldDisable = isBTS ? !quantity || !btsAccount : !assetAndGateway || !quantity || !address || !canCoverWithdrawal || addressError || quantity < minWithdraw;

            var storedAddresses = WithdrawAddresses.get(selectedAsset.toLowerCase());

            //console.log("this.state",this.state);

            var maxAvailable = convertedBalance && this.state.withdrawalCurrency ? new Asset({
                real: convertedBalance,
                asset_id: this.state.withdrawalCurrency.id,
                precision: this.state.withdrawalCurrency.precision
            }) : new Asset({
                amount: 0,
                asset_id: this.state.withdrawalCurrency ? this.state.withdrawalCurrency.id : undefined
            });

            if (this.state.feeAmount.asset_id === maxAvailable.asset_id) {
                maxAvailable.minus(this.state.feeAmount);
            }

            var prefix = selectedGateway;
            if (prefix === "BNB" || prefix === "WAVES" || prefix === "GDEX" || prefix === "ETH" || prefix === "HECO") {
                prefix = "XBTSX";
            }

            return React.createElement(
                Modal,
                {
                    title: counterpart.translate("modal.withdraw.header"),
                    visible: this.props.visible,
                    closeable: false,
                    wrapClassName: this.props.modalId,
                    onCancel: this.props.hideModal,
                    id: this.props.modalId,
                    footer: [React.createElement(
                        Button,
                        {
                            key: "submit",
                            onClick: this.onSubmit.bind(this),
                            disabled: shouldDisable
                        },
                        counterpart.translate("modal.withdraw.withdraw")
                    ), React.createElement(
                        Button,
                        { key: "cancel", onClick: this.props.close },
                        counterpart.translate("modal.withdraw.cancel")
                    )]
                },
                React.createElement(
                    "div",
                    { className: "grid-block vertical no-overflow" },
                    React.createElement(
                        "div",
                        { className: "modal__body", style: { paddingTop: 0 } },
                        React.createElement(
                            "div",
                            { style: { marginBottom: "1em" } },
                            React.createElement(DepositWithdrawAssetSelector, {
                                onSelect: this.onAssetSelected.bind(this),
                                onChange: this.onAssetChanged.bind(this),
                                include: symbolsToInclude,
                                selectOnBlur: true,
                                defaultValue: selectedAsset,
                                includeBTS: false,
                                usageContext: "withdraw"
                            })
                        ),
                        !isBTS && selectedAsset && !selectedGateway ? React.createElement(Translate, { content: "modal.withdraw.no_gateways" }) : null,
                        React.createElement(
                            "div",
                            { style: { marginBottom: "1em" } },
                            selectedGateway ? gatewaySelector.call(this, {
                                selectedGateway: selectedGateway,
                                gatewayStatus: gatewayStatus,
                                nAvailableGateways: nAvailableGateways,
                                availableGateways: coinToGatewayMapping[selectedAsset],
                                error: false,
                                onGatewayChanged: this.onGatewayChanged.bind(this),
                                selectedAsset: selectedAsset,
                                balances: balances,
                                assets: assets
                            }) : null
                        ),
                        assetAndGateway || isBTS ? React.createElement(
                            "div",
                            { style: { marginBottom: "1em" } },
                            preferredCurrency ? React.createElement(
                                "div",
                                {
                                    style: {
                                        fontSize: "0.8em",
                                        float: "right"
                                    }
                                },
                                React.createElement(Translate, { content: "modal.withdraw.available" }),
                                React.createElement(
                                    "span",
                                    {
                                        style: {
                                            color: canCoverWithdrawal ? null : "red",
                                            cursor: "pointer",
                                            textDecoration: "underline"
                                        },
                                        onClick: this.onClickAvailableBalance.bind(this, maxAvailable.getAmount({
                                            real: true
                                        }))
                                    },
                                    withdrawalCurrencyBalanceId ? React.createElement(BalanceComponent, {
                                        balance: withdrawalCurrencyBalanceId
                                    }) : React.createElement(
                                        "span",
                                        null,
                                        "0.00",
                                        " ",
                                        React.createElement(FormattedAsset, {
                                            hide_amount: true,
                                            amount: 0,
                                            asset: maxAvailable.asset_id
                                        })
                                    )
                                )
                            ) : null,
                            React.createElement(
                                "label",
                                { className: "left-label" },
                                React.createElement(Translate, { content: "modal.withdraw.quantity" })
                            ),
                            React.createElement(ExchangeInput, {
                                value: quantity ? quantity : "",
                                onChange: this.onQuantityChanged.bind(this),
                                onFocus: onFocus,
                                onBlur: onBlur,
                                allowNaN: true,
                                placeholder: counterpart.translate("gateway.limit_withdraw_asset", {
                                    min: !minWithdraw ? 0 : minWithdraw,
                                    max: !maxWithdraw ? counterpart.translate("gateway.limit_withdraw_asset_none") : maxWithdraw
                                })
                            }),
                            canCoverWithdrawal && minWithdraw && quantity && quantity < minWithdraw ? React.createElement(Translate, {
                                component: "div",
                                className: "error-msg",
                                style: {
                                    position: "absolute",
                                    right: 0,
                                    textTransform: "uppercase",
                                    fontSize: 13
                                },
                                content: "gateway.limit_withdraw_asset_min",
                                min: minWithdraw,
                                coin: prefix + "." + selectedAsset
                            }) : null,
                            canCoverWithdrawal && maxWithdraw && quantity && quantity > maxWithdraw ? React.createElement(Translate, {
                                component: "div",
                                className: "error-msg",
                                style: {
                                    position: "absolute",
                                    right: 0,
                                    textTransform: "uppercase",
                                    fontSize: 13
                                },
                                content: "gateway.limit_withdraw_asset_max",
                                max: maxWithdraw,
                                coin: prefix + "." + selectedAsset
                            }) : null,
                            (assetAndGateway || isBTS) && !canCoverWithdrawal ? React.createElement(Translate, {
                                content: "modal.withdraw.cannot_cover",
                                component: "div",
                                className: "error-msg",
                                style: {
                                    position: "absolute",
                                    right: 0,
                                    textTransform: "uppercase",
                                    fontSize: 13
                                }
                            }) : null
                        ) : null,
                        assetAndGateway && !isBTS ? React.createElement(
                            "div",
                            { style: { marginBottom: "1em" } },
                            React.createElement(
                                "label",
                                { className: "left-label" },
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "modal.withdraw.address"
                                })
                            ),
                            addressError ? React.createElement(
                                "div",
                                {
                                    className: "has-error",
                                    style: {
                                        position: "absolute",
                                        right: "1em",
                                        marginTop: "-30px"
                                    }
                                },
                                React.createElement(Translate, { content: "modal.withdraw.address_not_valid" })
                            ) : null,
                            React.createElement(
                                "div",
                                null,
                                React.createElement(
                                    "div",
                                    { className: "inline-label" },
                                    React.createElement(
                                        Select,
                                        {
                                            showSearch: true,
                                            style: { width: "100%" },
                                            value: address,
                                            onSearch: this.onAddressChanged.bind(this),
                                            onSelect: this.onAddressSelected.bind(this)
                                        },
                                        address && storedAddresses.indexOf(address) == -1 ? React.createElement(
                                            Select.Option,
                                            { value: address },
                                            address
                                        ) : null,
                                        storedAddresses.map(function (address) {
                                            return React.createElement(
                                                Select.Option,
                                                { value: address },
                                                address
                                            );
                                        })
                                    ),
                                    React.createElement(
                                        "span",
                                        null,
                                        React.createElement(QRScanner, {
                                            label: "Scan",
                                            onSuccess: this.handleQrScanSuccess
                                        })
                                    )
                                )
                            )
                        ) : null,
                        isBTS ? React.createElement(
                            "div",
                            { style: { marginBottom: "1em" } },
                            React.createElement(AccountSelector, {
                                label: "transfer.to",
                                accountName: state.btsAccountName,
                                onChange: this.onBTSAccountNameChanged.bind(this),
                                onAccountChanged: this.onBTSAccountChanged.bind(this),
                                account: state.btsAccountName,
                                size: 60,
                                error: state.btsAccountError
                            })
                        ) : null,
                        isBTS || backingAsset && backingAsset.memoSupport ? React.createElement(
                            "div",
                            { style: { marginBottom: "1em" } },
                            React.createElement(
                                "label",
                                { className: "left-label" },
                                React.createElement(Translate, { content: "modal.withdraw.memo" })
                            ),
                            React.createElement(Input.TextArea, {
                                value: state.memo,
                                onChange: this.onMemoChanged.bind(this)
                            })
                        ) : null,
                        !isBTS && backingAsset && backingAsset.memoSupport && backingAsset.backingCoin === "STH" ? React.createElement(
                            "div",
                            { style: { marginBottom: "1em" } },
                            React.createElement(
                                "label",
                                { className: "left-label" },
                                "Transfer on Binance DEX (100 STH \u2192 1 STHM BEP8)"
                            ),
                            React.createElement(
                                "label",
                                { className: "left-label" },
                                "Transfer from Binance DEX (1 STHM \u2192 100 STH)"
                            )
                        ) : null,
                        assetAndGateway || isBTS ? React.createElement(
                            "div",
                            { className: "grid-block no-overflow wrap shrink" },
                            React.createElement(
                                "div",
                                {
                                    className: "small-12 medium-6",
                                    style: { paddingRight: 5 }
                                },
                                React.createElement(
                                    "label",
                                    { className: "left-label" },
                                    React.createElement(Translate, { content: "transfer.fee" })
                                ),
                                React.createElement(AmountSelector, {
                                    asset: this.state.fee_asset_id,
                                    assets: fee_asset_types,
                                    amount: this.state.feeAmount.getAmount({
                                        real: true
                                    }),
                                    onChange: this.onFeeChanged.bind(this)
                                })
                            ),
                            React.createElement(
                                "div",
                                { className: "small-12 medium-6" },
                                React.createElement(
                                    "label",
                                    { className: "left-label" },
                                    React.createElement(Translate, { content: "gateway.fee" })
                                ),
                                React.createElement(
                                    "div",
                                    { className: "grid-block no-overflow wrap shrink" },
                                    React.createElement(ExchangeInput, {
                                        placeholder: "0.0",
                                        id: "baseMarketFee",
                                        value: !!backingAsset && "gateFee" in backingAsset ? backingAsset.gateFee : 0,
                                        disabled: true,
                                        addonAfter: React.createElement(
                                            "span",
                                            null,
                                            React.createElement(AssetName, {
                                                noTip: true,
                                                name: backingAsset.symbol
                                            })
                                        )
                                    })
                                )
                            )
                        ) : null
                    )
                )
            );
        }
    }]);

    return WithdrawModalNew;
}(React.Component);

var ConnectedWithdrawModal = connect(WithdrawModalNew, {
    listenTo: function listenTo() {
        return [GatewayStore, AssetStore, SettingsStore, MarketsStore];
    },
    getProps: function getProps() {
        return {
            backedCoins: GatewayStore.getState().backedCoins,
            preferredCurrency: SettingsStore.getSetting("unit"),
            marketStats: MarketsStore.getState().allMarketStats
        };
    }
});

var WithdrawModalWrapper = function (_React$Component2) {
    _inherits(WithdrawModalWrapper, _React$Component2);

    function WithdrawModalWrapper() {
        _classCallCheck(this, WithdrawModalWrapper);

        return _possibleConstructorReturn(this, (WithdrawModalWrapper.__proto__ || Object.getPrototypeOf(WithdrawModalWrapper)).apply(this, arguments));
    }

    _createClass(WithdrawModalWrapper, [{
        key: "render",
        value: function render() {
            var props = this.props;


            if (!props.account) return null;

            var balances = props.account.get("balances");
            var assets = Immutable.fromJS({});
            balances.forEach(function (item, id) {
                try {
                    var asset = ChainStore.getAsset(id).toJS();
                    assets = assets.set(id, asset);
                } catch (e) {}
            });

            props.backedCoins.forEach(function (gateway) {
                gateway.forEach(function (coin) {
                    if (coin.withdrawalAllowed) {
                        try {
                            var asset = ChainStore.getAsset(coin.symbol).toJS();
                            if (!assets.has(asset.id)) assets = assets.set(asset.id, asset);
                        } catch (e) {}
                    }
                });
            });

            return React.createElement(BalanceWrapper, _extends({
                wrap: ConnectedWithdrawModal
            }, props, {
                balances: props.account.get("balances"),
                assets: assets
            }));
        }
    }]);

    return WithdrawModalWrapper;
}(React.Component);

WithdrawModalWrapper.propTypes = {
    account: ChainTypes.ChainAccount.isRequired,
    withdrawAssets: ChainTypes.ChainAssetsList,
    intermediateAccounts: ChainTypes.ChainAccountsList
};
WithdrawModalWrapper.defaultProps = {
    account: "",
    withdrawAssets: Immutable.List(),
    intermediateAccounts: Immutable.List()
};


var ConnectedWrapper = connect(BindToChainState(WithdrawModalWrapper), {
    listenTo: function listenTo() {
        return [AccountStore];
    },
    getProps: function getProps() {
        return {
            account: AccountStore.getState().currentAccount
        };
    }
});

var WithdrawModal = function (_React$Component3) {
    _inherits(WithdrawModal, _React$Component3);

    function WithdrawModal() {
        _classCallCheck(this, WithdrawModal);

        return _possibleConstructorReturn(this, (WithdrawModal.__proto__ || Object.getPrototypeOf(WithdrawModal)).apply(this, arguments));
    }

    _createClass(WithdrawModal, [{
        key: "shouldComponentUpdate",
        value: function shouldComponentUpdate(np, ns) {
            if (!this.props.visible && !np.visible) return false;
            return true;
        }
    }, {
        key: "render",
        value: function render() {
            var _this10 = this;

            var withdrawAssets = Immutable.List();
            var intermediateAccounts = Immutable.List();
            this.props.backedCoins.forEach(function (gateway) {
                gateway.forEach(function (coin) {
                    if (coin.withdrawalAllowed) {
                        withdrawAssets.push(coin.symbol);
                        var withdrawAccount = getIntermediateAccount(coin.symbol, _this10.props.backedCoins);
                        if (withdrawAccount && !intermediateAccounts.includes(withdrawAccount)) intermediateAccounts = intermediateAccounts.push(withdrawAccount);
                    }
                });
            });

            return React.createElement(ConnectedWrapper, _extends({}, this.props, {
                id: this.props.modalId,
                close: this.props.hideModal,
                withdrawAssets: withdrawAssets,
                intermediateAccounts: intermediateAccounts
            }));
        }
    }]);

    return WithdrawModal;
}(React.Component);

export default WithdrawModal;