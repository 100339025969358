import ls from "./localStorage";
import { blockTradesAPIs, openledgerAPIs } from "api/apiConfig";
import { availableGateways } from "common/gateways";
var blockTradesStorage = new ls("");

var fetchInProgess = {};
var fetchCache = {};
var clearIntervals = {};
var fetchCacheTTL = 30000;
function setCacheClearTimer(key) {
    clearIntervals[key] = setTimeout(function () {
        delete fetchCache[key];
        delete clearIntervals[key];
    }, fetchCacheTTL);
}

export function fetchCoins() {
    var url = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : openledgerAPIs.BASE + openledgerAPIs.COINS_LIST;

    var key = "fetchCoins_" + url;
    var currentPromise = fetchInProgess[key];
    if (fetchCache[key]) {
        return Promise.resolve(fetchCache[key]);
    } else if (!currentPromise) {
        fetchInProgess[key] = currentPromise = fetch(url).then(function (reply) {
            return reply.json().then(function (result) {
                // throw new Error("Test");
                return result;
            });
        }).catch(function (err) {
            console.log("fetchCoins error from " + url + ": " + err);
            throw err;
        });
    }
    return new Promise(function (res, rej) {
        currentPromise.then(function (result) {
            fetchCache[key] = result;
            res(result);
            delete fetchInProgess[key];
            if (!clearIntervals[key]) setCacheClearTimer(key);
        }).catch(rej);
    });
}

export function fetchCoinsSimple() {
    var url = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : openledgerAPIs.BASE + openledgerAPIs.COINS_LIST;

    return fetch(url).then(function (reply) {
        return reply.json().then(function (result) {
            return result;
        });
    }).catch(function (err) {
        console.log("fetchCoinsSimple error from " + url + ": " + err);
        throw err;
    });
}

export function fetchTradingPairs() {
    var url = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : blockTradesAPIs.BASE + blockTradesAPIs.TRADING_PAIRS;

    var key = "fetchTradingPairs_" + url;
    var currentPromise = fetchInProgess[key];
    if (fetchCache[key]) {
        return Promise.resolve(fetchCache[key]);
    } else if (!currentPromise) {
        fetchInProgess[key] = currentPromise = fetch(url, {
            method: "get",
            headers: new Headers({ Accept: "application/json" })
        }).then(function (reply) {
            return reply.json().then(function (result) {
                return result;
            });
        }).catch(function (err) {
            console.log("fetchTradingPairs error from " + url + ": " + err);
            throw err;
        });
    }
    return new Promise(function (res, rej) {
        currentPromise.then(function (result) {
            fetchCache[key] = result;
            res(result);
            delete fetchInProgess[key];
            if (!clearIntervals[key]) setCacheClearTimer(key);
        }).catch(rej);
    });
}

export function getDepositLimit(inputCoin, outputCoin) {
    var url = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : blockTradesAPIs.BASE + blockTradesAPIs.DEPOSIT_LIMIT;

    return fetch(url + "?inputCoinType=" + encodeURIComponent(inputCoin) + "&outputCoinType=" + encodeURIComponent(outputCoin), { method: "get", headers: new Headers({ Accept: "application/json" }) }).then(function (reply) {
        return reply.json().then(function (result) {
            return result;
        });
    }).catch(function (err) {
        console.log("error fetching deposit limit of", inputCoin, outputCoin, err);
    });
}

export function estimateOutput(inputAmount, inputCoin, outputCoin) {
    var url = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : blockTradesAPIs.BASE + blockTradesAPIs.ESTIMATE_OUTPUT;

    return fetch(url + "?inputAmount=" + encodeURIComponent(inputAmount) + "&inputCoinType=" + encodeURIComponent(inputCoin) + "&outputCoinType=" + encodeURIComponent(outputCoin), { method: "get", headers: new Headers({ Accept: "application/json" }) }).then(function (reply) {
        return reply.json().then(function (result) {
            return result;
        });
    }).catch(function (err) {
        console.log("error fetching deposit limit of", inputCoin, outputCoin, err);
    });
}

export function estimateInput(outputAmount, inputCoin, outputCoin) {
    var url = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : blockTradesAPIs.BASE + blockTradesAPIs.ESTIMATE_INPUT;

    return fetch(url + "?outputAmount=" + encodeURIComponent(outputAmount) + "&inputCoinType=" + encodeURIComponent(inputCoin) + "&outputCoinType=" + encodeURIComponent(outputCoin), {
        method: "get",
        headers: new Headers({ Accept: "application/json" })
    }).then(function (reply) {
        return reply.json().then(function (result) {
            return result;
        });
    }).catch(function (err) {
        console.log("error fetching deposit limit of", inputCoin, outputCoin, err);
    });
}

export function getActiveWallets() {
    var url = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : openledgerAPIs.BASE + openledgerAPIs.ACTIVE_WALLETS;

    var key = "getActiveWallets_" + url;
    var currentPromise = fetchInProgess[key];

    if (fetchCache[key]) {
        return Promise.resolve(fetchCache[key]);
    } else if (!currentPromise) {
        fetchInProgess[key] = currentPromise = fetch(url).then(function (reply) {
            return reply.json().then(function (result) {
                return result;
            });
        }).catch(function (err) {
            console.log("error fetching blocktrades active wallets", err, url);
        });
    }

    return new Promise(function (res) {
        currentPromise.then(function (result) {
            fetchCache[key] = result;
            res(result);
            delete fetchInProgess[key];
            if (!clearIntervals[key]) setCacheClearTimer(key);
        });
    });
}

export function getDepositAddress(_ref) {
    var coin = _ref.coin,
        account = _ref.account,
        stateCallback = _ref.stateCallback;

    var body = {
        coin: coin,
        account: account
    };

    var body_string = JSON.stringify(body);

    fetch(openledgerAPIs.BASE + "/simple-api/get-last-address", {
        method: "POST",
        headers: new Headers({
            Accept: "application/json",
            "Content-Type": "application/json"
        }),
        body: body_string
    }).then(function (data) {
        data.json().then(function (json) {
            var address = {
                address: json.address,
                memo: json.memo || null,
                error: json.error || null,
                loading: false
            };
            if (stateCallback) stateCallback(address);
        }, function (error) {
            console.log("error: ", error);
            if (stateCallback) stateCallback({ address: error.message, memo: null });
        });
    }, function (error) {
        console.log("error: ", error);
        if (stateCallback) stateCallback({ address: error.message, memo: null });
    }).catch(function (err) {
        console.log("fetch error:", err);
    });
}

var depositRequests = {};
export function requestDepositAddress(_ref2) {
    var inputCoinType = _ref2.inputCoinType,
        outputCoinType = _ref2.outputCoinType,
        outputAddress = _ref2.outputAddress,
        _ref2$url = _ref2.url,
        url = _ref2$url === undefined ? openledgerAPIs.BASE : _ref2$url,
        stateCallback = _ref2.stateCallback,
        selectedGateway = _ref2.selectedGateway;

    var gatewayStatus = availableGateways[selectedGateway];
    inputCoinType = !!gatewayStatus && !!gatewayStatus.assetWithdrawlAlias ? gatewayStatus.assetWithdrawlAlias[inputCoinType.toLowerCase()] || inputCoinType.toLowerCase() : inputCoinType;

    var body = {
        inputCoinType: inputCoinType,
        outputCoinType: outputCoinType,
        outputAddress: outputAddress
    };

    var body_string = JSON.stringify(body);
    if (depositRequests[body_string]) return;
    depositRequests[body_string] = true;
    fetch(url + "/simple-api/initiate-trade", {
        method: "post",
        headers: new Headers({
            Accept: "application/json",
            "Content-Type": "application/json"
        }),
        body: body_string
    }).then(function (reply) {
        reply.json().then(function (json) {
            delete depositRequests[body_string];
            // console.log( "reply: ", json );
            var address = {
                address: json.inputAddress || "unknown",
                memo: json.inputMemo,
                error: json.error || null
            };
            if (stateCallback) stateCallback(address);
        }, function (error) {
            console.log("error: ", error);
            delete depositRequests[body_string];
            if (stateCallback) stateCallback(null);
        });
    }, function (error) {
        console.log("error: ", error);
        delete depositRequests[body_string];
        if (stateCallback) stateCallback(null);
    }).catch(function (err) {
        console.log("fetch error:", err);
        delete depositRequests[body_string];
    });
}

export function getBackedCoins(_ref3) {
    var allCoins = _ref3.allCoins,
        tradingPairs = _ref3.tradingPairs,
        backer = _ref3.backer;

    var gatewayStatus = availableGateways[backer];
    var coins_by_type = {};

    // Backer has no coinType == backingCoinType but uses single wallet style
    if (!!gatewayStatus.singleWallet) {
        allCoins.forEach(function (coin_type) {
            return coins_by_type[coin_type.backingCoinType] = coin_type;
        });
    }

    allCoins.forEach(function (coin_type) {
        return coins_by_type[coin_type.coinType] = coin_type;
    });

    var allowed_outputs_by_input = {};
    tradingPairs.forEach(function (pair) {
        if (!allowed_outputs_by_input[pair.inputCoinType]) allowed_outputs_by_input[pair.inputCoinType] = {};
        allowed_outputs_by_input[pair.inputCoinType][pair.outputCoinType] = true;
    });

    var backedCoins = [];
    allCoins.forEach(function (inputCoin) {
        var outputCoin = coins_by_type[inputCoin.backingCoinType];
        if (inputCoin.walletSymbol.startsWith(backer + ".") && inputCoin.backingCoinType && outputCoin) {
            var isDepositAllowed = allowed_outputs_by_input[inputCoin.backingCoinType] && allowed_outputs_by_input[inputCoin.backingCoinType][inputCoin.coinType];
            var isWithdrawalAllowed = allowed_outputs_by_input[inputCoin.coinType] && allowed_outputs_by_input[inputCoin.coinType][inputCoin.backingCoinType];

            backedCoins.push({
                name: outputCoin.name,
                intermediateAccount: !!gatewayStatus.intermediateAccount ? gatewayStatus.intermediateAccount : outputCoin.intermediateAccount,
                gateFee: outputCoin.gateFee || outputCoin.transactionFee,
                walletType: outputCoin.walletType,
                backingCoinType: !!gatewayStatus.singleWallet ? inputCoin.backingCoinType.toUpperCase() : outputCoin.walletSymbol,
                minAmount: outputCoin.minAmount || 0,
                maxAmount: outputCoin.maxAmount || 999999999,
                symbol: inputCoin.walletSymbol,
                supportsMemos: outputCoin.supportsOutputMemos,
                depositAllowed: isDepositAllowed,
                withdrawalAllowed: isWithdrawalAllowed
            });
        }
    });
    return backedCoins;
}

export function validateAddress(_ref4) {
    var _ref4$url = _ref4.url,
        url = _ref4$url === undefined ? blockTradesAPIs.BASE : _ref4$url,
        walletType = _ref4.walletType,
        newAddress = _ref4.newAddress,
        _ref4$output_coin_typ = _ref4.output_coin_type,
        output_coin_type = _ref4$output_coin_typ === undefined ? null : _ref4$output_coin_typ,
        _ref4$method = _ref4.method,
        method = _ref4$method === undefined ? null : _ref4$method;

    if (!newAddress) return new Promise(function (res) {
        return res();
    });

    if (!method || method == "GET") {
        url += "/wallets/" + walletType + "/address-validator?address=" + encodeURIComponent(newAddress);
        if (output_coin_type) {
            url += "&outputCoinType=" + output_coin_type;
        }
        return fetch(url, {
            method: "get",
            headers: new Headers({
                Accept: "application/json",
                "Content-Type": "application/json"
            })
        }).then(function (reply) {
            return reply.json().then(function (json) {
                return json.isValid;
            });
        }).catch(function (err) {
            console.log("validate error:", err);
        });
    } else if (method == "POST") {
        return fetch(url + "/wallets/" + walletType + "/check-address", {
            method: "post",
            headers: new Headers({
                Accept: "application/json",
                "Content-Type": "application/json"
            }),
            body: JSON.stringify({ address: newAddress })
        }).then(function (reply) {
            return reply.json().then(function (json) {
                return json.isValid;
            });
        }).catch(function (err) {
            console.log("validate error:", err);
        });
    }
}

var _conversionCache = {};
export function getConversionJson(inputs) {
    var input_coin_type = inputs.input_coin_type,
        output_coin_type = inputs.output_coin_type,
        url = inputs.url,
        account_name = inputs.account_name;

    if (!input_coin_type || !output_coin_type) return Promise.reject();
    var body = JSON.stringify({
        inputCoinType: input_coin_type,
        outputCoinType: output_coin_type,
        outputAddress: account_name,
        inputMemo: "blocktrades conversion: " + input_coin_type + "to" + output_coin_type
    });

    var _cacheString = url + input_coin_type + output_coin_type + account_name;
    return new Promise(function (resolve, reject) {
        if (_conversionCache[_cacheString]) return resolve(_conversionCache[_cacheString]);
        fetch(url + "/simple-api/initiate-trade", {
            method: "post",
            headers: new Headers({
                Accept: "application/json",
                "Content-Type": "application/json"
            }),
            body: body
        }).then(function (reply) {
            reply.json().then(function (json) {
                _conversionCache[_cacheString] = json;
                resolve(json);
            }, reject).catch(reject);
        }).catch(reject);
    });
}

function hasWithdrawalAddress(wallet) {
    return blockTradesStorage.has("history_address_" + wallet);
}

function setWithdrawalAddresses(_ref5) {
    var wallet = _ref5.wallet,
        addresses = _ref5.addresses;

    blockTradesStorage.set("history_address_" + wallet, addresses);
}

function getWithdrawalAddresses(wallet) {
    return blockTradesStorage.get("history_address_" + wallet, []);
}

function setLastWithdrawalAddress(_ref6) {
    var wallet = _ref6.wallet,
        address = _ref6.address;

    blockTradesStorage.set("history_address_last_" + wallet, address);
}

function getLastWithdrawalAddress(wallet) {
    return blockTradesStorage.get("history_address_last_" + wallet, "");
}

export var WithdrawAddresses = {
    has: hasWithdrawalAddress,
    set: setWithdrawalAddresses,
    get: getWithdrawalAddresses,
    setLast: setLastWithdrawalAddress,
    getLast: getLastWithdrawalAddress
};