import React from "react";
import Translate from "react-translate-component";

export var WithdrawPermissionCreate = function WithdrawPermissionCreate(_ref) {
    var op = _ref.op,
        linkToAccount = _ref.linkToAccount,
        fromComponent = _ref.fromComponent;

    if (fromComponent === "proposed_operation") {
        return React.createElement(
            "span",
            null,
            React.createElement(Translate, {
                component: "span",
                content: "proposal.withdraw_permission_create"
            }),
            "\xA0",
            linkToAccount(op[1].withdraw_from_account),
            React.createElement(Translate, { component: "span", content: "proposal.to" }),
            "\xA0",
            linkToAccount(op[1].authorized_account)
        );
    } else {
        return React.createElement(
            "span",
            null,
            React.createElement(Translate, {
                component: "span",
                content: "transaction.withdraw_permission_create"
            }),
            "\xA0",
            linkToAccount(op[1].withdraw_from_account),
            React.createElement(Translate, { component: "span", content: "transaction.to" }),
            "\xA0",
            linkToAccount(op[1].authorized_account)
        );
    }
};