import React from "react";
import Translate from "react-translate-component";
import BindToChainState from "../../Utility/BindToChainState";
import TranslateWithLinks from "../../Utility/TranslateWithLinks";

export var AccountWhitelist = function AccountWhitelist(_ref) {
    var op = _ref.op,
        fromComponent = _ref.fromComponent;

    var label = op[1].new_listing === listings.no_listing ? "unlisted_by" : op[1].new_listing === listings.white_listed ? "whitelisted_by" : "blacklisted_by";
    if (fromComponent === "proposed_operation") {
        return React.createElement(
            "span",
            null,
            React.createElement(
                BindToChainState.Wrapper,
                {
                    lister: op[1].authorizing_account,
                    listee: op[1].account_to_list
                },
                function (_ref2) {
                    var lister = _ref2.lister,
                        listee = _ref2.listee;
                    return React.createElement(Translate, {
                        component: "span",
                        content: "transaction." + label,
                        lister: lister.get("name"),
                        listee: listee.get("name")
                    });
                }
            )
        );
    } else {
        return React.createElement(
            "span",
            null,
            React.createElement(TranslateWithLinks, {
                string: "operation." + label,
                keys: [{
                    type: "account",
                    value: op[1].authorizing_account,
                    arg: "lister"
                }, {
                    type: "account",
                    value: op[1].account_to_list,
                    arg: "listee"
                }]
            })
        );
    }
};