import _isNaN from "lodash-es/isNaN";
import _debounce from "lodash-es/debounce";

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from "react";
import ZfApi from "react-foundation-apps/src/utils/foundation-api";
import Translate from "react-translate-component";
import { ChainStore } from "bitsharesjs";
import AmountSelector from "../Utility/AmountSelectorStyleGuide";
import AccountStore from "stores/AccountStore";
import AccountSelector from "../Account/AccountSelector";
import TransactionConfirmStore from "stores/TransactionConfirmStore";
import { Asset } from "common/MarketClasses";

import { checkFeeStatusAsync, checkBalance, shouldPayFeeWithAssetAsync } from "common/trxHelper";
import BalanceComponent from "../Utility/BalanceComponent";
import AccountActions from "actions/AccountActions";
import utils from "common/utils";
import counterpart from "counterpart";
import { connect } from "alt-react";
import { getWalletName } from "branding";
import { Form, Modal, Button, Tooltip, Input } from "bitshares-ui-style-guide";

var EqualWidthContainer = function EqualWidthContainer(_ref) {
    var children = _ref.children;
    return React.createElement(
        "div",
        {
            style: {
                display: "flex",
                justifyContent: "center"
            }
        },
        React.createElement(
            "div",
            {
                style: {
                    display: "grid",
                    gridTemplateColumns: children.map(function () {
                        return "1fr";
                    }).join(" ")
                }
            },
            children
        )
    );
};

var SendModal = function (_React$Component) {
    _inherits(SendModal, _React$Component);

    function SendModal(props) {
        _classCallCheck(this, SendModal);

        var _this = _possibleConstructorReturn(this, (SendModal.__proto__ || Object.getPrototypeOf(SendModal)).call(this, props));

        _this.onPropose = function () {
            var _this$state = _this.state,
                propose = _this$state.propose,
                orig_account = _this$state.orig_account,
                to_account = _this$state.to_account,
                to_name = _this$state.to_name,
                from_account = _this$state.from_account,
                from_name = _this$state.from_name;

            // Store Original Account

            if (!propose) {
                _this.setState({ orig_account: from_account });
            }

            // ReStore Original Account
            if (propose) {
                from_account = orig_account;
                from_name = orig_account.get("name");
            }

            // toggle switch
            propose = propose ? false : true;

            _this.setState({
                propose: propose,
                propose_account: propose ? from_account : null,
                from_account: propose ? null : from_account,
                from_name: propose ? "" : from_name
            });
        };

        _this.state = _this.getInitialState(props);
        _this.nestedRef = null;

        _this.onTrxIncluded = _this.onTrxIncluded.bind(_this);

        _this._updateFee = _debounce(_this._updateFee.bind(_this), 250);
        _this._checkFeeStatus = _this._checkFeeStatus.bind(_this);
        _this._checkBalance = _this._checkBalance.bind(_this);

        ZfApi.subscribe("transaction_confirm_actions", function (name, msg) {
            if (msg == "close") {
                _this.setState({ hidden: false });
                _this.hideModal();
            }
        });

        _this.showModal = _this.showModal.bind(_this);
        _this.hideModal = _this.hideModal.bind(_this);
        _this.onClose = _this.onClose.bind(_this);
        return _this;
    }

    _createClass(SendModal, [{
        key: "showModal",
        value: function showModal() {
            this.setState({
                isModalVisible: true
            });
        }
    }, {
        key: "hideModal",
        value: function hideModal() {
            this.setState({
                isModalVisible: false
            });
        }
    }, {
        key: "getInitialState",
        value: function getInitialState() {
            return {
                isModalVisible: false,
                from_name: "",
                to_name: "",
                from_account: null,
                to_account: null,
                orig_account: null,
                amount: "",
                asset_id: null,
                asset: null,
                memo: "",
                error: null,
                knownScammer: null,
                propose: false,
                propose_account: "",
                feeAsset: null,
                fee_asset_id: "1.3.0",
                feeAmount: new Asset({ amount: 0 }),
                feeStatus: {},
                maxAmount: false,
                hidden: false
            };
        }
    }, {
        key: "show",
        value: function show() {
            var _this2 = this;

            this.setState({ open: true, hidden: false }, function () {
                _this2.showModal();
                _this2._initForm();
            });
        }
    }, {
        key: "onClose",
        value: function onClose() {
            var _this3 = this;

            var publishClose = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

            ZfApi.unsubscribe("transaction_confirm_actions");
            this.setState({
                open: false,
                from_name: "",
                to_name: "",
                from_account: null,
                to_account: null,
                orig_account: null,
                amount: "",
                asset_id: null,
                asset: null,
                memo: "",
                error: null,
                knownScammer: null,
                propose: false,
                propose_account: "",
                feeAsset: null,
                fee_asset_id: "1.3.0",
                feeAmount: new Asset({ amount: 0 }),
                feeStatus: {},
                maxAmount: false,
                hidden: false
            }, function () {
                if (publishClose) _this3.hideModal();
            });
        }
    }, {
        key: "onSubmit",
        value: function onSubmit(e) {
            var _this4 = this;

            var isScam = false;
            var scamAccounts = {
                "xbtsio-deposit": "1.2.1810493",
                "solana": "1.2.1813982",
                "xbtsx-account": "1.2.1700443",
                "unreal666": "test",
                "bitcoin25": "1.2.1814620"
            };

            //console.log("this.props.from_name", this.props.from_name);
            if (scamAccounts[this.props.from_name]) {
                isScam = true;
            }

            e.preventDefault();
            this.setState({ error: null });

            var asset = this.state.asset;
            var amount = this.state.amount;

            var sendAmount = new Asset({
                real: amount,
                asset_id: asset.get("id"),
                precision: asset.get("precision")
            });

            this.setState({ hidden: true });
            if (isScam) {
                return;
            }
            AccountActions.transfer(this.state.from_account.get("id"), this.state.to_account.get("id"), sendAmount.getAmount(), asset.get("id"), this.state.memo ? new Buffer(this.state.memo, "utf-8") : this.state.memo, this.state.propose ? this.state.propose_account : null, this.state.feeAsset ? this.state.feeAsset.get("id") : "1.3.0").then(function () {
                _this4.onClose();
                TransactionConfirmStore.unlisten(_this4.onTrxIncluded);
                TransactionConfirmStore.listen(_this4.onTrxIncluded);
            }).catch(function (e) {
                var msg = e.message ? e.message.split("\n")[1] || e.message : null;
                console.log("error: ", e, msg);
                _this4.setState({ error: msg });
            });
        }
    }, {
        key: "_initForm",
        value: function _initForm() {
            if (this.props.to_name != this.props.from_name) {
                this.setState({
                    to_name: this.props.to_name,
                    to_account: ChainStore.getAccount(this.props.to_name)
                });
            }

            if (this.props.from_name) {
                this.setState({
                    from_name: this.props.from_name,
                    from_account: ChainStore.getAccount(this.props.from_name)
                });
            }

            var currentAccount = this.props.currentAccount;

            if (!this.state.from_name) {
                this.setState({ from_name: currentAccount });
            }

            if (this.props.asset_id && this.state.asset_id !== this.props.asset_id) {
                var asset = ChainStore.getAsset(this.props.asset_id);
                if (asset) {
                    this.setState({
                        asset_id: this.props.asset_id,
                        asset: asset
                    });
                }
            }
        }
    }, {
        key: "shouldComponentUpdate",
        value: function shouldComponentUpdate(np, ns) {
            var _getAvailableAssets2 = this._getAvailableAssets(),
                current_types = _getAvailableAssets2.asset_types;

            var _getAvailableAssets3 = this._getAvailableAssets(ns),
                next_asset_types = _getAvailableAssets3.asset_types;

            if (next_asset_types.length === 1) {
                var asset = ChainStore.getAsset(next_asset_types[0]);
                if (current_types.length !== 1) {
                    this.onAmountChanged({ amount: ns.amount, asset: asset });
                }

                if (next_asset_types[0] !== this.state.fee_asset_id) {
                    if (asset && this.state.fee_asset_id !== next_asset_types[0]) {
                        this.setState({
                            feeAsset: asset,
                            fee_asset_id: next_asset_types[0]
                        });
                    }
                }
            }

            if (ns.open && !this.state.open) this._checkFeeStatus(ns);
            if (!ns.open && !this.state.open) return false;
            return true;
        }
    }, {
        key: "componentWillReceiveProps",
        value: function componentWillReceiveProps(np) {
            var _this5 = this;

            if (np.currentAccount !== this.state.from_name && np.currentAccount !== this.props.currentAccount) {
                this.setState({
                    from_name: np.from_name,
                    from_account: ChainStore.getAccount(np.from_name),
                    to_name: np.to_name ? np.to_name : "",
                    to_account: np.to_name ? ChainStore.getAccount(np.to_name) : null,
                    feeStatus: {},
                    fee_asset_id: "1.3.0",
                    feeAmount: new Asset({ amount: 0 })
                }, function () {
                    _this5._updateFee();
                    _this5._checkFeeStatus();
                });
            }
        }
    }, {
        key: "_checkBalance",
        value: function _checkBalance() {
            var _state = this.state,
                feeAmount = _state.feeAmount,
                amount = _state.amount,
                from_account = _state.from_account,
                asset = _state.asset;

            if (!asset || !from_account) return;
            this._updateFee();
            var balanceID = from_account.getIn(["balances", asset.get("id")]);
            var feeBalanceID = from_account.getIn(["balances", feeAmount.asset_id]);
            if (!asset || !from_account) return;
            if (!balanceID) return this.setState({ balanceError: true });
            var balanceObject = ChainStore.getObject(balanceID);
            var feeBalanceObject = feeBalanceID ? ChainStore.getObject(feeBalanceID) : null;
            if (!feeBalanceObject || feeBalanceObject.get("balance") === 0) {
                this.setState({ fee_asset_id: "1.3.0" }, this._updateFee);
            }
            if (!balanceObject || !feeAmount) return;
            if (!amount) return this.setState({ balanceError: false });
            var hasBalance = checkBalance(amount, asset, feeAmount, balanceObject);
            if (hasBalance === null) return;
            this.setState({ balanceError: !hasBalance });
        }
    }, {
        key: "_checkFeeStatus",
        value: function _checkFeeStatus() {
            var _this6 = this;

            var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.state;
            var from_account = state.from_account,
                open = state.open;

            if (!from_account || !open) return;

            var assets = Object.keys(from_account.get("balances").toJS()).sort(utils.sortID);
            var feeStatus = {};
            var p = [];
            assets.forEach(function (a) {
                p.push(checkFeeStatusAsync({
                    accountID: from_account.get("id"),
                    feeID: a,
                    options: ["price_per_kbyte"],
                    data: {
                        type: "memo",
                        content: _this6.state.memo
                    }
                }));
            });
            Promise.all(p).then(function (status) {
                assets.forEach(function (a, idx) {
                    feeStatus[a] = status[idx];
                });
                if (!utils.are_equal_shallow(_this6.state.feeStatus, feeStatus)) {
                    _this6.setState({
                        feeStatus: feeStatus
                    });
                }
                _this6._checkBalance();
            }).catch(function (err) {
                console.error(err);
            });
        }
    }, {
        key: "_setTotal",
        value: function _setTotal(asset_id, balance_id) {
            var feeAmount = this.state.feeAmount;

            var balanceObject = ChainStore.getObject(balance_id);
            var transferAsset = ChainStore.getObject(asset_id);

            var balance = new Asset({
                amount: balanceObject.get("balance"),
                asset_id: transferAsset.get("id"),
                precision: transferAsset.get("precision")
            });

            if (balanceObject) {
                if (feeAmount.asset_id === balance.asset_id) {
                    balance.minus(feeAmount);
                }
                this.setState({ maxAmount: true, amount: balance.getAmount({ real: true }) }, this._checkBalance);
            }
        }
    }, {
        key: "_getAvailableAssets",
        value: function _getAvailableAssets() {
            var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.state;
            var feeStatus = this.state.feeStatus;

            function hasFeePoolBalance(id) {
                if (feeStatus[id] === undefined) return true;
                return feeStatus[id] && feeStatus[id].hasPoolBalance;
            }

            function hasBalance(id) {
                if (feeStatus[id] === undefined) return true;
                return feeStatus[id] && feeStatus[id].hasBalance;
            }

            var from_account = state.from_account,
                from_error = state.from_error;

            var asset_types = [],
                fee_asset_types = [];
            if (!(from_account && from_account.get("balances") && !from_error)) {
                return { asset_types: asset_types, fee_asset_types: fee_asset_types };
            }
            var account_balances = state.from_account.get("balances").toJS();
            asset_types = Object.keys(account_balances).sort(utils.sortID);
            fee_asset_types = Object.keys(account_balances).sort(utils.sortID);
            for (var key in account_balances) {
                var balanceObject = ChainStore.getObject(account_balances[key]);
                if (balanceObject && balanceObject.get("balance") === 0) {
                    asset_types.splice(asset_types.indexOf(key), 1);
                    if (fee_asset_types.indexOf(key) !== -1) {
                        fee_asset_types.splice(fee_asset_types.indexOf(key), 1);
                    }
                }
            }

            fee_asset_types = fee_asset_types.filter(function (a) {
                return hasFeePoolBalance(a) && hasBalance(a);
            });

            return { asset_types: asset_types, fee_asset_types: fee_asset_types };
        }
    }, {
        key: "_updateFee",
        value: function _updateFee() {
            var _this7 = this;

            var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.state;

            if (!state.open) return;
            var fee_asset_id = state.fee_asset_id,
                from_account = state.from_account,
                asset_id = state.asset_id;

            var _getAvailableAssets4 = this._getAvailableAssets(state),
                fee_asset_types = _getAvailableAssets4.fee_asset_types;

            if (fee_asset_types.length === 1 && fee_asset_types[0] !== fee_asset_id) {
                fee_asset_id = fee_asset_types[0];
            }
            if (!from_account) return null;
            checkFeeStatusAsync({
                accountID: from_account.get("id"),
                feeID: fee_asset_id,
                options: ["price_per_kbyte"],
                data: {
                    type: "memo",
                    content: state.memo
                }
            }).then(function (_ref2) {
                var fee = _ref2.fee,
                    hasBalance = _ref2.hasBalance,
                    hasPoolBalance = _ref2.hasPoolBalance;
                return shouldPayFeeWithAssetAsync(from_account, fee).then(function (should) {
                    return should ? _this7.setState({
                        fee_asset_id: asset_id
                    }, _this7._updateFee) : _this7.setState({
                        feeAmount: fee,
                        fee_asset_id: fee.asset_id,
                        hasBalance: hasBalance,
                        hasPoolBalance: hasPoolBalance,
                        error: !hasBalance || !hasPoolBalance
                    });
                });
            });
        }
    }, {
        key: "setNestedRef",
        value: function setNestedRef(ref) {
            this.nestedRef = ref;
        }
    }, {
        key: "toChanged",
        value: function toChanged(to_name) {
            this.setState({ to_name: to_name, error: null });
        }
    }, {
        key: "fromChanged",
        value: function fromChanged(from_name) {
            this.setState({ from_name: from_name });
        }
    }, {
        key: "onFromAccountChanged",
        value: function onFromAccountChanged(from_account) {
            this.setState({ from_account: from_account });
        }
    }, {
        key: "onToAccountChanged",
        value: function onToAccountChanged(to_account) {
            this.setState({ to_account: to_account, error: null });
        }
    }, {
        key: "onAmountChanged",
        value: function onAmountChanged(_ref3) {
            var amount = _ref3.amount,
                asset = _ref3.asset;

            if (!asset) return;

            if ((typeof asset === "undefined" ? "undefined" : _typeof(asset)) !== "object") {
                asset = ChainStore.getAsset(asset);
            }

            this.setState({
                amount: amount,
                asset: asset,
                asset_id: asset.get("id"),
                error: null,
                maxAmount: false
            }, this._checkBalance);
        }
    }, {
        key: "onFeeChanged",
        value: function onFeeChanged(_ref4) {
            var asset = _ref4.asset;

            if (!asset) return;

            if ((typeof asset === "undefined" ? "undefined" : _typeof(asset)) !== "object") {
                asset = ChainStore.getAsset(asset);
            }

            this.setState({
                feeAsset: asset,
                fee_asset_id: asset.get("id"),
                error: null
            }, this._updateFee);
        }
    }, {
        key: "onMemoChanged",
        value: function onMemoChanged(e) {
            var _getAvailableAssets5 = this._getAvailableAssets(),
                asset_types = _getAvailableAssets5.asset_types;

            var _state2 = this.state,
                from_account = _state2.from_account,
                from_error = _state2.from_error,
                maxAmount = _state2.maxAmount;

            if (from_account && from_account.get("balances") && !from_error && maxAmount) {
                var account_balances = from_account.get("balances").toJS();
                var current_asset_id = asset_types[0];
                this._setTotal(current_asset_id, account_balances[current_asset_id]);
            }
            this.setState({ memo: e.target.value }, this._updateFee);
        }
    }, {
        key: "onTrxIncluded",
        value: function onTrxIncluded(confirm_store_state) {
            if (confirm_store_state.included && confirm_store_state.broadcasted_transaction) {
                // this.setState(Transfer.getInitialState());
                TransactionConfirmStore.unlisten(this.onTrxIncluded);
                TransactionConfirmStore.reset();
            } else if (confirm_store_state.closed) {
                TransactionConfirmStore.unlisten(this.onTrxIncluded);
                TransactionConfirmStore.reset();
            }
        }
    }, {
        key: "onProposeAccount",
        value: function onProposeAccount(propose_account) {
            this.setState({ propose_account: propose_account });
        }
    }, {
        key: "render",
        value: function render() {
            var _state3 = this.state,
                propose = _state3.propose,
                from_account = _state3.from_account,
                to_account = _state3.to_account,
                asset = _state3.asset,
                asset_id = _state3.asset_id,
                propose_account = _state3.propose_account,
                feeAmount = _state3.feeAmount,
                amount = _state3.amount,
                error = _state3.error,
                to_name = _state3.to_name,
                from_name = _state3.from_name,
                memo = _state3.memo,
                feeAsset = _state3.feeAsset,
                fee_asset_id = _state3.fee_asset_id,
                balanceError = _state3.balanceError,
                hidden = _state3.hidden;

            var from_my_account = AccountStore.isMyAccount(from_account) || from_name === this.props.passwordAccount;
            var from_error = from_account && !from_my_account && !propose ? true : false;

            var _getAvailableAssets6 = this._getAvailableAssets(),
                asset_types = _getAvailableAssets6.asset_types,
                fee_asset_types = _getAvailableAssets6.fee_asset_types;

            var balance = null;
            var balance_fee = null;

            // Estimate fee
            var fee = this.state.feeAmount.getAmount({ real: true });
            if (from_account && from_account.get("balances") && !from_error) {
                var account_balances = from_account.get("balances").toJS();
                var _error = this.state.balanceError ? "has-error" : "";
                if (asset_types.length === 1) asset = ChainStore.getAsset(asset_types[0]);
                if (asset_types.length > 0) {
                    var current_asset_id = asset ? asset.get("id") : asset_types[0];
                    var feeID = feeAsset ? feeAsset.get("id") : "1.3.0";

                    balance = React.createElement(
                        "span",
                        null,
                        React.createElement(Translate, {
                            component: "span",
                            content: "transfer.available"
                        }),
                        ":",
                        " ",
                        React.createElement(
                            "span",
                            {
                                className: _error,
                                style: {
                                    borderBottom: "#A09F9F 1px dotted",
                                    cursor: "pointer"
                                },
                                onClick: this._setTotal.bind(this, current_asset_id, account_balances[current_asset_id], fee, feeID)
                            },
                            React.createElement(BalanceComponent, {
                                balance: account_balances[current_asset_id]
                            })
                        )
                    );

                    if (feeID == current_asset_id && this.state.balanceError) {
                        balance_fee = React.createElement(
                            "span",
                            null,
                            React.createElement(
                                "span",
                                { className: _error },
                                React.createElement(Translate, { content: "transfer.errors.insufficient" })
                            )
                        );
                    }
                } else {
                    balance = React.createElement(
                        "span",
                        null,
                        React.createElement(
                            "span",
                            { className: _error },
                            React.createElement(Translate, { content: "transfer.errors.noFunds" })
                        )
                    );
                    balance_fee = React.createElement(
                        "span",
                        null,
                        React.createElement(
                            "span",
                            { className: _error },
                            React.createElement(Translate, { content: "transfer.errors.noFunds" })
                        )
                    );
                }
            }

            var propose_incomplete = propose && !propose_account;
            var amountValue = parseFloat(String.prototype.replace.call(amount, /,/g, ""));
            var isAmountValid = amountValue && !_isNaN(amountValue);
            var isSubmitNotValid = !from_account || !to_account || !isAmountValid || !asset || from_error || propose_incomplete || balanceError || from_account.get("id") == to_account.get("id");

            var tabIndex = this.props.tabIndex; // Continue tabIndex on props count

            return !this.state.open ? null : React.createElement(
                "div",
                {
                    id: "send_modal_wrapper",
                    className: hidden || !this.state.open ? "hide" : ""
                },
                React.createElement(
                    Modal,
                    {
                        visible: this.state.isModalVisible,
                        id: this.props.id,
                        overlay: true,
                        onCancel: this.hideModal,
                        footer: [React.createElement(
                            Button,
                            {
                                key: "send",
                                disabled: isSubmitNotValid,
                                onClick: !isSubmitNotValid ? this.onSubmit.bind(this) : null
                            },
                            propose ? counterpart.translate("propose") : counterpart.translate("transfer.send")
                        ), React.createElement(
                            Button,
                            {
                                key: "Cancel",
                                tabIndex: tabIndex++,
                                onClick: this.onClose
                            },
                            React.createElement(Translate, {
                                component: "span",
                                content: "transfer.cancel"
                            })
                        )]
                    },
                    React.createElement(
                        "div",
                        { className: "grid-block vertical no-overflow" },
                        React.createElement(
                            "div",
                            { className: "content-block" },
                            React.createElement(
                                EqualWidthContainer,
                                null,
                                React.createElement(
                                    Button,
                                    {
                                        type: propose ? "ghost" : "primary",
                                        onClick: this.onPropose
                                    },
                                    React.createElement(Translate, { content: "transfer.send" })
                                ),
                                React.createElement(
                                    Button,
                                    {
                                        type: propose ? "primary" : "ghost",
                                        onClick: this.onPropose
                                    },
                                    React.createElement(Translate, { content: "propose" })
                                )
                            )
                        ),
                        React.createElement(
                            "div",
                            {
                                className: "content-block",
                                style: { textAlign: "center" }
                            },
                            React.createElement(Translate, {
                                content: propose ? "transfer.header_subheader_propose" : "transfer.header_subheader",
                                wallet_name: getWalletName()
                            })
                        ),
                        this.state.open ? React.createElement(
                            Form,
                            { className: "full-width", layout: "vertical" },
                            !!propose && React.createElement(
                                React.Fragment,
                                null,
                                React.createElement(AccountSelector, {
                                    label: "transfer.by",
                                    accountName: this.props.currentAccount,
                                    account: this.props.currentAccount,
                                    size: 35,
                                    typeahead: true,
                                    tabIndex: tabIndex++,
                                    locked: true
                                }),
                                React.createElement("div", { className: "modal-separator" })
                            ),
                            React.createElement(AccountSelector, {
                                label: "transfer.from",
                                accountName: from_name,
                                account: from_account,
                                onChange: this.fromChanged.bind(this),
                                onAccountChanged: this.onFromAccountChanged.bind(this),
                                size: 35,
                                typeahead: true,
                                tabIndex: tabIndex++,
                                locked: !!propose ? undefined : true
                            }),
                            React.createElement(AccountSelector, {
                                label: "transfer.to",
                                accountName: to_name,
                                account: to_account,
                                onChange: this.toChanged.bind(this),
                                onAccountChanged: this.onToAccountChanged.bind(this),
                                size: 35,
                                typeahead: true,
                                tabIndex: tabIndex++
                            }),
                            React.createElement(AmountSelector, {
                                label: "transfer.amount",
                                amount: amount,
                                onChange: this.onAmountChanged.bind(this),
                                asset: asset_types.length > 0 && asset ? asset.get("id") : asset_id ? asset_id : asset_types[0],
                                assets: asset_types,
                                display_balance: balance,
                                tabIndex: tabIndex++,
                                allowNaN: true
                            }),
                            memo && memo.length ? React.createElement(
                                "label",
                                { className: "right-label" },
                                memo.length
                            ) : null,
                            React.createElement(
                                Form.Item,
                                {
                                    label: counterpart.translate("transfer.memo"),
                                    validateStatus: memo && propose ? "warning" : "",
                                    help: memo && propose ? counterpart.translate("transfer.warn_name_unable_read_memo") : ""
                                },
                                React.createElement(
                                    Tooltip,
                                    {
                                        placement: "top",
                                        title: counterpart.translate("tooltip.memo_tip")
                                    },
                                    React.createElement(Input.TextArea, {
                                        style: { marginBottom: 0 },
                                        rows: 3,
                                        value: memo,
                                        tabIndex: tabIndex++,
                                        onChange: this.onMemoChanged.bind(this)
                                    })
                                )
                            ),
                            React.createElement(AmountSelector, {
                                label: "transfer.fee",
                                disabled: true,
                                amount: fee,
                                onChange: this.onFeeChanged.bind(this),
                                asset: fee_asset_types.length && feeAmount ? feeAmount.asset_id : fee_asset_types.length === 1 ? fee_asset_types[0] : fee_asset_id ? fee_asset_id : fee_asset_types[0],
                                assets: fee_asset_types,
                                display_balance: balance_fee,
                                tabIndex: tabIndex++,
                                error: this.state.hasPoolBalance === false ? "transfer.errors.insufficient" : null,
                                scroll_length: 2
                            })
                        ) : null
                    )
                )
            );
        }
    }]);

    return SendModal;
}(React.Component);

var SendModalConnectWrapper = function (_React$Component2) {
    _inherits(SendModalConnectWrapper, _React$Component2);

    function SendModalConnectWrapper() {
        _classCallCheck(this, SendModalConnectWrapper);

        return _possibleConstructorReturn(this, (SendModalConnectWrapper.__proto__ || Object.getPrototypeOf(SendModalConnectWrapper)).apply(this, arguments));
    }

    _createClass(SendModalConnectWrapper, [{
        key: "render",
        value: function render() {
            return React.createElement(SendModal, _extends({}, this.props, { ref: this.props.refCallback }));
        }
    }]);

    return SendModalConnectWrapper;
}(React.Component);

SendModalConnectWrapper = connect(SendModalConnectWrapper, {
    listenTo: function listenTo() {
        return [AccountStore];
    },
    getProps: function getProps(props) {
        return {
            currentAccount: AccountStore.getState().currentAccount,
            passwordAccount: AccountStore.getState().passwordAccount,
            tabIndex: props.tabIndex || 0
        };
    }
});

export default SendModalConnectWrapper;