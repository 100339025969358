import React from "react";
import TranslateWithLinks from "../../Utility/TranslateWithLinks";
import { ChainStore } from "bitsharesjs";

var compareKeys = function compareKeys(prev, next) {
    var minus = prev.filter(function (x) {
        return !next.includes(x);
    });
    var plus = next.filter(function (x) {
        return !prev.includes(x);
    });

    return { minus: minus, plus: plus };
};

var getVotesName = function getVotesName(item) {
    if (item.id.startsWith("1.14")) {
        // worker
        var worker_account = ChainStore.getAccountName(item.worker_account);
        return "Worker " + item.name + (worker_account ? " of " + worker_account : " account " + item.worker_account);
    } else if (item.id.startsWith("1.6.")) {
        var witness_account = ChainStore.getAccountName(item.witness_account);
        return "Witness " + (witness_account ? witness_account : " account " + item.witness_account);
    } else {
        var committee_member_account = ChainStore.getAccountName(item.committee_member_account);
        return "Committee " + (committee_member_account ? committee_member_account : " account " + item.committee_member_account);
    }
};

export var AccountUpdate = function AccountUpdate(_ref) {
    var op = _ref.op,
        fromComponent = _ref.fromComponent,
        collapsed = _ref.collapsed;

    var account = op[1].account;

    var votes = op[1].new_options ? op[1].new_options.votes : undefined;
    var memo_key = op[1].new_options ? op[1].new_options.memo_key : undefined;

    var owner = op[1].owner ? op[1].owner : undefined;
    var active = op[1].active ? op[1].active : undefined;

    var change = {};
    var votesPlusNames = [];
    var votesMinusNames = [];

    if (collapsed == undefined) {
        collapsed = true;
    }

    if (fromComponent === "proposed_operation" && !collapsed) {
        var _account = ChainStore.getAccount(account, false);
        var _votes = _account.get("options").get("votes").toArray();

        if (votes) {
            var votesIds = compareKeys(_votes, votes);
            var votesPlusData = ChainStore.getObjectsByVoteIds(votesIds.plus);
            var votesMinusData = ChainStore.getObjectsByVoteIds(votesIds.minus);

            if (votesPlusData && votesMinusData) {
                votesPlusData.forEach(function (item) {
                    if (item) {
                        var name = getVotesName(item.toJS());
                        if (name) votesPlusNames.push(name);
                    }
                });
                votesMinusData.forEach(function (item) {
                    if (item) {
                        var name = getVotesName(item.toJS());
                        if (name) votesMinusNames.push(name);
                    }
                });
                change.votes = { minus: votesMinusNames, plus: votesPlusNames };
            }
        }

        if (owner) {
            change.owner = {};
            var _owner_keys = _account.get("owner").get("key_auths").map(function (a) {
                return a.get(0);
            }).toArray();
            change.owner.keys = compareKeys(_owner_keys, owner.key_auths.map(function (x) {
                return x[0];
            }));
            var _owner_accounts = _account.get("owner").get("account_auths").map(function (a) {
                return a.get(0);
            }).toArray();
            change.owner.accounts = compareKeys(_owner_accounts, owner.account_auths.map(function (x) {
                return x[0];
            }));
            if (_account.get("owner").get("weight_threshold") !== owner.weight_threshold) {
                change.owner.weight_threshold = owner.weight_threshold;
            }
        }
        if (active) {
            change.active = {};
            var _active_keys = _account.get("active").get("key_auths").map(function (a) {
                return a.get(0);
            }).toArray();
            change.active.keys = compareKeys(_active_keys, active.key_auths.map(function (x) {
                return x[0];
            }));
            var _active_accounts = _account.get("active").get("account_auths").map(function (a) {
                return a.get(0);
            }).toArray();
            change.active.accounts = compareKeys(_active_accounts, active.account_auths.map(function (x) {
                return x[0];
            }));
            if (_account.get("active").get("weight_threshold") !== active.weight_threshold) {
                change.active.weight_threshold = active.weight_threshold;
            }
        }

        if (memo_key) {
            change.memo = {};
            var _memo = _account.get("options").get("memo_key");
            change.memo.keys = compareKeys([_memo], [memo_key]);
            if (change.memo.keys.minus.length == 0 && change.memo.keys.plus.length == 0) {
                change.memo = undefined;
            }
        }
    }

    return React.createElement(
        "span",
        null,
        React.createElement(TranslateWithLinks, {
            string: fromComponent === "proposed_operation" ? "proposal.update_account" : "operation.update_account",
            keys: [{
                type: "account",
                value: op[1].account,
                arg: "account"
            }, {
                type: "change",
                value: change,
                arg: "change"
            }]
        })
    );
};