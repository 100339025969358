import React from "react";
import Translate from "react-translate-component";
import FormattedAsset from "../../Utility/FormattedAsset";
import TranslateWithLinks from "../../Utility/TranslateWithLinks";

export var WorkerCreate = function WorkerCreate(_ref) {
    var op = _ref.op,
        fromComponent = _ref.fromComponent;

    if (fromComponent === "proposed_operation") {
        return React.createElement(
            "span",
            null,
            React.createElement(Translate, { component: "span", content: "proposal.create_worker" }),
            "\xA0",
            React.createElement(FormattedAsset, {
                style: { fontWeight: "bold" },
                amount: op[1].daily_pay,
                asset: "1.3.0"
            })
        );
    } else {
        return React.createElement(
            "span",
            null,
            React.createElement(TranslateWithLinks, {
                string: "operation.worker_create",
                keys: [{
                    type: "account",
                    value: op[1].owner,
                    arg: "account"
                }, {
                    type: "amount",
                    value: {
                        amount: op[1].daily_pay,
                        asset_id: "1.3.0"
                    },
                    arg: "pay"
                }],
                params: {
                    name: op[1].name
                }
            })
        );
    }
};