var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

import ls, { ls_key_exists } from "./localStorageImpl";

/**
 * Abstract interface for local storage. Provides get, set, has and remove. Actual implementation must override
 * _get, _set, _has and _remove.
 *
 * @author Stefan Schießl, Alexander Verevkin
 */

var AbstractLocalStorage = function () {
    function AbstractLocalStorage(prefix) {
        _classCallCheck(this, AbstractLocalStorage);

        this._storage_prefix = prefix;
    }

    /**
     * Add the prefix for the key
     *
     * @param key
     * @returns {*}
     * @private
     */


    _createClass(AbstractLocalStorage, [{
        key: "_translateKey",
        value: function _translateKey(key) {
            return this._storage_prefix + key;
        }

        /**
         * Checks if the underlying storage has the key, and if so and it has a valid value, returns it.
         * Otherwise default value is returned.
         *
         * @param key
         * @param defaultValue
         * @returns {*}
         */

    }, {
        key: "get",
        value: function get(key) {
            var defaultValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

            try {
                if (this.has(key)) {
                    key = this._translateKey(key);
                    var value = this._get(key);
                    if (value === undefined || value === null) {
                        return defaultValue;
                    } else {
                        return value;
                    }
                } else {
                    return defaultValue;
                }
            } catch (err) {
                return defaultValue;
            }
        }

        /**
         * Internal getter to access the actual implementation, only for derived classes
         *
         * @param key
         * @private
         */

    }, {
        key: "_get",
        value: function _get(key) {
            throw Error("Needs implementation");
        }

        /**
         * Stringifies the given value and stores it associated to the given key
         *
         * @param key
         * @param object
         */

    }, {
        key: "set",
        value: function set(key, object) {
            this._set(this._translateKey(key), object);
        }

        /**
         * Internal setter to access the actual implementation, only for derived classes
         *
         * @param key
         * @private
         */

    }, {
        key: "_set",
        value: function _set(key) {
            throw Error("Needs implementation");
        }

        /**
         * Removes the key from the storage
         *
         * @param key
         */

    }, {
        key: "remove",
        value: function remove(key) {
            this._remove(this._translateKey(key));
        }

        /**
         * Check if the key is present in the storage
         * @param key
         */

    }, {
        key: "has",
        value: function has(key) {
            return this._has(this._translateKey(key));
        }

        /**
         * Internal remove to access the actual implementation, only for derived classes
         *
         * @param key
         * @private
         */

    }, {
        key: "_remove",
        value: function _remove(key) {
            throw Error("Needs implementation");
        }

        /**
         * Internal has  to access the actual implementation, only for derived classes
         *
         * @param key
         * @private
         */

    }, {
        key: "_has",
        value: function _has(key) {
            throw Error("Needs implementation");
        }
    }]);

    return AbstractLocalStorage;
}();

/**
 * Persistant storage that access the typically known as localStorage implementation of modern browsers
 */


var PersistantLocalStorage = function (_AbstractLocalStorage) {
    _inherits(PersistantLocalStorage, _AbstractLocalStorage);

    function PersistantLocalStorage() {
        _classCallCheck(this, PersistantLocalStorage);

        return _possibleConstructorReturn(this, (PersistantLocalStorage.__proto__ || Object.getPrototypeOf(PersistantLocalStorage)).apply(this, arguments));
    }

    _createClass(PersistantLocalStorage, [{
        key: "_get",
        value: function _get(key) {
            var value = ls.getItem(key);
            if (value === "") {
                return value;
            } else {
                return JSON.parse(value);
            }
        }
    }, {
        key: "_set",
        value: function _set(key, object) {
            if (object === "") {
                ls.setItem(key, object);
            } else {
                ls.setItem(key, JSON.stringify(object));
            }
        }
    }, {
        key: "_remove",
        value: function _remove(key) {
            ls.removeItem(key);
        }
    }, {
        key: "_has",
        value: function _has(key) {
            return ls_key_exists(key, ls);
        }
    }, {
        key: "_getLocalStorage",
        value: function _getLocalStorage() {
            return ls;
        }
    }]);

    return PersistantLocalStorage;
}(AbstractLocalStorage);

/**
 * Storage that keeps everything in a local variable that is only kept in ram
 */


var InRamLocalStorage = function (_AbstractLocalStorage2) {
    _inherits(InRamLocalStorage, _AbstractLocalStorage2);

    function InRamLocalStorage(prefix) {
        _classCallCheck(this, InRamLocalStorage);

        var _this2 = _possibleConstructorReturn(this, (InRamLocalStorage.__proto__ || Object.getPrototypeOf(InRamLocalStorage)).call(this, prefix));

        _this2._dict = {};
        return _this2;
    }

    _createClass(InRamLocalStorage, [{
        key: "_get",
        value: function _get(key) {
            return this._dict.get(key);
        }
    }, {
        key: "_set",
        value: function _set(key, object) {
            // keep the stringify, to be consistent across storage impls
            this._dict[key] = JSON.stringify(object);
        }
    }, {
        key: "_remove",
        value: function _remove(key) {
            delete this._dict[key];
        }
    }, {
        key: "_has",
        value: function _has(key) {
            return key in this._dict;
        }
    }]);

    return InRamLocalStorage;
}(AbstractLocalStorage);

var enforceLocalStorageType = null;

/**
 * Allow the wallet to switch the storage type (e.g. for private sessions)
 * @param type
 */
export var setLocalStorageType = function setLocalStorageType(type) {
    if (type !== "inram" && type !== "persistant") {
        throw "Please choose inram or persistant storage type";
    }
    enforceLocalStorageType = type;
};

export var isPersistantType = function isPersistantType() {
    return enforceLocalStorageType == null || enforceLocalStorageType == "persistant";
};

/**
 * Storage that allows switching between persistant and inram implementation
 */

var DynamicLocalStorage = function (_AbstractLocalStorage3) {
    _inherits(DynamicLocalStorage, _AbstractLocalStorage3);

    function DynamicLocalStorage(prefix) {
        _classCallCheck(this, DynamicLocalStorage);

        // default is persistant storage, but if that is not available use inram
        var _this3 = _possibleConstructorReturn(this, (DynamicLocalStorage.__proto__ || Object.getPrototypeOf(DynamicLocalStorage)).call(this, prefix));

        if (null === ls) {
            _this3.useInRam();
        } else {
            _this3.usePersistant();
        }
        return _this3;
    }

    _createClass(DynamicLocalStorage, [{
        key: "isPersistant",
        value: function isPersistant() {
            return this._impl instanceof PersistantLocalStorage;
        }
    }, {
        key: "_switchIfNecessary",
        value: function _switchIfNecessary() {
            if (enforceLocalStorageType == null) {
                return;
            }
            if (enforceLocalStorageType == "inram") {
                this.useInRam();
            } else {
                this.usePersistant();
            }
        }
    }, {
        key: "_get",
        value: function _get(key) {
            this._switchIfNecessary();
            return this._impl._get(key);
        }
    }, {
        key: "_set",
        value: function _set(key, object) {
            this._switchIfNecessary();
            this._impl._set(key, object);
        }
    }, {
        key: "_remove",
        value: function _remove(key) {
            this._switchIfNecessary();
            this._impl._remove(key);
        }
    }, {
        key: "_has",
        value: function _has(key) {
            this._switchIfNecessary();
            return this._impl._has(key);
        }

        /**
         * When switching from persistant to inram, copy persistant as default
         */

    }, {
        key: "useInRam",
        value: function useInRam() {
            var copy = {};
            if (this.isPersistant()) {
                console.log("Switching to InRam storage for private session");
                for (var i = 0, len = this._impl._getLocalStorage().length; i < len; ++i) {
                    var key = this._impl._getLocalStorage().key(i);
                    copy[key] = this._impl._getLocalStorage().getItem(key);
                }
            }
            this._impl = new InRamLocalStorage(this._storage_prefix);
            for (var _key in copy) {
                this._impl._set(_key, copy[_key]);
            }
        }
    }, {
        key: "usePersistant",
        value: function usePersistant() {
            this._impl = new PersistantLocalStorage(this._storage_prefix);
        }
    }]);

    return DynamicLocalStorage;
}(AbstractLocalStorage);

// LocalStorage should be a singleton, wrap it


var _localStorageCache = {};
var localStorage = function localStorage(prefix) {
    if (!(prefix in _localStorageCache)) {
        _localStorageCache[prefix] = new DynamicLocalStorage(prefix);
    }
    return _localStorageCache[prefix];
};
export default localStorage;