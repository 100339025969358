var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from "react";
import ReactTooltip from "react-tooltip";
import SettingsActions from "actions/SettingsActions";
import utils from "common/utils";
import WalletRegistrationConfirm from "./WalletRegistrationConfirm";
import WalletRegistrationForm from "./WalletRegistrationForm";

var WalletRegistration = function (_React$Component) {
    _inherits(WalletRegistration, _React$Component);

    function WalletRegistration() {
        _classCallCheck(this, WalletRegistration);

        var _this = _possibleConstructorReturn(this, (WalletRegistration.__proto__ || Object.getPrototypeOf(WalletRegistration)).call(this));

        _this.state = {
            confirmationStep: false,
            checkboxRemember: false,
            checkboxUploaded: false,
            checkboxRecover: false
        };

        _this.toggleConfirmed = _this.toggleConfirmed.bind(_this);
        return _this;
    }

    _createClass(WalletRegistration, [{
        key: "componentWillMount",
        value: function componentWillMount() {
            SettingsActions.changeSetting({
                setting: "passwordLogin",
                value: false
            });
        }
    }, {
        key: "componentDidMount",
        value: function componentDidMount() {
            ReactTooltip.rebuild();
        }
    }, {
        key: "shouldComponentUpdate",
        value: function shouldComponentUpdate(nextProps, nextState) {
            return !utils.are_equal_shallow(nextState, this.state);
        }
    }, {
        key: "continue",
        value: function _continue() {
            this.setState({
                confirmationStep: true
            });
        }
    }, {
        key: "toggleConfirmed",
        value: function toggleConfirmed(checkbox) {
            this.setState(_defineProperty({}, checkbox, !this.state[checkbox]));
        }
    }, {
        key: "render",
        value: function render() {
            var _this2 = this;

            var _state = this.state,
                confirmationStep = _state.confirmationStep,
                checkboxRemember = _state.checkboxRemember,
                checkboxUploaded = _state.checkboxUploaded,
                checkboxRecover = _state.checkboxRecover;


            return React.createElement(
                "div",
                { className: "no-margin grid-block registration-layout registration" },
                React.createElement(
                    "div",
                    { className: "grid-block horizontal align-center text-center" },
                    React.createElement(
                        "div",
                        null,
                        React.createElement("img", {
                            className: (checkboxRemember && checkboxUploaded && checkboxRecover ? "confirmed" : "") + " model-img",
                            src: "/model-type-images/flesh-drive.svg",
                            alt: "wallet"
                        })
                    ),
                    React.createElement(
                        "div",
                        { className: "create-account-block" },
                        !confirmationStep ? React.createElement(WalletRegistrationForm, {
                            history: this.props.history,
                            "continue": function _continue() {
                                return _this2.continue();
                            }
                        }) : React.createElement(WalletRegistrationConfirm, {
                            history: this.props.history,
                            toggleConfirmed: this.toggleConfirmed,
                            checkboxRemember: checkboxRemember,
                            checkboxUploaded: checkboxUploaded,
                            checkboxRecover: checkboxRecover
                        })
                    )
                )
            );
        }
    }]);

    return WalletRegistration;
}(React.Component);

export default WalletRegistration;