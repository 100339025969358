import React from "react";
import PropTypes from "prop-types";
import Translate from "react-translate-component";
import { Button } from "bitshares-ui-style-guide";
import counterpart from "counterpart";

export default function WalletBlockSelection(props) {
    return React.createElement(
        "div",
        {
            className: "wallet-block-registration",
            onClick: props.onChangeActive
        },
        React.createElement(
            "div",
            { className: "overflow-bg-block show-for-small-only" },
            React.createElement("span", { className: "content" })
        ),
        React.createElement(Translate, {
            content: "registration.securityKey",
            component: "p",
            className: "model-option security-key " + (!props.active ? "inactive-text" : "")
        }),
        React.createElement(Translate, {
            content: "registration.securityWalletModel",
            component: "p",
            className: "model-option-value option-border " + (!props.active ? "inactive-text" : "")
        }),
        React.createElement(Translate, {
            content: "registration.loginByKey",
            component: "p",
            className: "model-option " + (!props.active ? "inactive-text" : "")
        }),
        React.createElement(Translate, {
            content: "registration.walletLoginByValue",
            component: "p",
            className: "model-option-value option-border " + (!props.active ? "inactive-text" : "")
        }),
        React.createElement(Translate, {
            content: "registration.backUpRestoreKey",
            component: "p",
            className: "model-option " + (!props.active ? "inactive-text" : "")
        }),
        React.createElement(Translate, {
            content: "settings.yes",
            component: "p",
            className: "model-option-value " + (!props.active ? "inactive-text" : "")
        }),
        props.active ? React.createElement(
            Button,
            { onClick: props.onSelect, type: "primary" },
            counterpart.translate("registration.continue")
        ) : React.createElement(
            Button,
            null,
            counterpart.translate("registration.select")
        )
    );
}

WalletBlockSelection.propTypes = {
    active: PropTypes.bool.isRequired,
    onSelect: PropTypes.func.isRequired,
    onChangeActive: PropTypes.func.isRequired
};