var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from "react";
import Translate from "react-translate-component";
import SettingsActions from "actions/SettingsActions";
import SettingsStore from "stores/SettingsStore";
//import {settingsAPIs} from "../../api/apiConfig";
import willTransitionTo, { routerTransitioner } from "../../routerTransition";
import { connect } from "alt-react";
import cnames from "classnames";
import Icon from "../Icon/Icon";
import LoadingButton from "../Utility/LoadingButton";
import { Switch, Button } from "bitshares-ui-style-guide";
import NodeSelector from "../Utility/NodeSelector";
import counterpart from "counterpart";
var autoSelectionUrl = "wss://fake.automatic-selection.com";

function isTestNet(url) {
    return !__TESTNET__ && url.indexOf("testnet") !== -1;
}

/**
 * This class renders the auto-selection node
 */

var AutoSelectionNode = function (_React$Component) {
    _inherits(AutoSelectionNode, _React$Component);

    function AutoSelectionNode(props) {
        _classCallCheck(this, AutoSelectionNode);

        return _possibleConstructorReturn(this, (AutoSelectionNode.__proto__ || Object.getPrototypeOf(AutoSelectionNode)).call(this, props));
    }

    /**
     * On activation routerTransitioner selects the best by itself. On deactivation the currently connected, or
     * last connected node is selected again.
     *
     * @param url
     */


    _createClass(AutoSelectionNode, [{
        key: "activate",
        value: function activate(url) {
            SettingsActions.changeSetting({
                setting: "apiServer",
                value: url
            });
            if (SettingsStore.getSetting("activeNode") != SettingsStore.getSetting("apiServer")) {
                setTimeout(function () {
                    willTransitionTo(false);
                }.bind(this), 50);
            }
        }
    }, {
        key: "render",
        value: function render() {
            var _props = this.props,
                isActive = _props.isActive,
                connectedNode = _props.connectedNode,
                totalNodes = _props.totalNodes,
                popup = _props.popup;


            if (popup) {
                return React.createElement(
                    "div",
                    null,
                    React.createElement(Switch, {
                        style: {
                            float: "right",
                            position: "relative",
                            top: "-15px"
                        },
                        checked: isActive,
                        onChange: connectedNode != null ? this.activate.bind(this, isActive ? connectedNode.url : autoSelectionUrl) : function () {}
                    }),
                    React.createElement(
                        "p",
                        { style: { fontSize: "80%" } },
                        React.createElement(Translate, { content: "settings.automatic_short" }),
                        ":"
                    )
                );
            } else {
                return React.createElement(
                    "div",
                    { className: "auto-node" },
                    React.createElement(
                        "div",
                        null,
                        React.createElement(Switch, {
                            checked: isActive,
                            onChange: connectedNode != null ? this.activate.bind(this, isActive ? connectedNode.url : autoSelectionUrl) : function () {}
                        }),
                        React.createElement(Translate, {
                            component: "div",
                            style: { paddingLeft: "1rem", paddingTop: "0.2rem" },
                            content: "settings.automatic",
                            totalNodes: totalNodes
                        })
                    ),
                    React.createElement(
                        "div",
                        { style: { float: "right", marginBottom: "0.5rem" } },
                        React.createElement(NodeSelector, null)
                    )
                );
            }
        }
    }]);

    return AutoSelectionNode;
}(React.Component);

/**
 * This class renders a a single node within the nodes list in the settings overview.
 *
 * This includes:
 *   - rendering the currently active node
 *   - render all other nodes with or without ping in the three sections:
 *      available, hidden, personal
 */


var ApiNode = function (_React$Component2) {
    _inherits(ApiNode, _React$Component2);

    function ApiNode(props) {
        _classCallCheck(this, ApiNode);

        return _possibleConstructorReturn(this, (ApiNode.__proto__ || Object.getPrototypeOf(ApiNode)).call(this, props));
    }

    /**
     * Nodes can only be activated, as switching only works by activating another
     * @param url
     */


    _createClass(ApiNode, [{
        key: "activate",
        value: function activate(url) {
            SettingsActions.changeSetting({
                setting: "apiServer",
                value: url
            });
            if (SettingsStore.getSetting("activeNode") != SettingsStore.getSetting("apiServer")) {
                setTimeout(function () {
                    willTransitionTo(false);
                }.bind(this), 50);
            }
        }
    }, {
        key: "remove",
        value: function remove(url, name) {
            this.props.showRemoveNodeModal(url, name);
        }
    }, {
        key: "show",
        value: function show(url) {
            SettingsActions.showWS(url);
        }
    }, {
        key: "hide",
        value: function hide(url) {
            SettingsActions.hideWS(url);
        }

        /**
         * Construct ping dict containing toString, color and rating.
         * @returns {*}
         * @private
         */

    }, {
        key: "_getPing",
        value: function _getPing() {
            if (isTestNet(this.props.node.url)) {
                return {
                    toString: null,
                    color: null,
                    rating: null
                };
            }
            if (!this.props.node.ping) {
                return {
                    toString: null,
                    color: "high",
                    rating: "node_down"
                };
            }
            if (this.props.node.ping == Infinity) {
                return {
                    toString: null,
                    color: "high",
                    rating: "node_down"
                };
            }
            if (this.props.node.ping == -1) {
                return {
                    toString: null,
                    color: "high",
                    rating: "skipped"
                };
            }
            var color = void 0,
                rating = void 0;
            var pingInMs = this.props.node.ping;
            if (pingInMs < 400) {
                color = "low";
                rating = "low_latency";
            } else if (pingInMs >= 400 && pingInMs < 800) {
                color = "medium";
                rating = "medium_latency";
            } else {
                color = "high";
                rating = "high_latency";
            }

            return {
                toString: pingInMs >= 1000 ? +(pingInMs / 1000).toFixed(2) + "s" : pingInMs + "ms",
                color: color,
                rating: rating
            };
        }
    }, {
        key: "render",
        value: function render() {
            var _props2 = this.props,
                node = _props2.node,
                isActive = _props2.isActive,
                popup = _props2.popup;


            var ping = this._getPing();

            var url = node.url;

            var canBeHidden = !isActive;
            var canBeRemoved = !node.default && !isActive;

            var hidden = !!node.hidden;

            var location = !!node.location && _typeof(node.location) === "object" && "translate" in node.location ? counterpart.translate(node.location.translate) : node.location;

            var title = !!location ? location : "";
            if (!!node.country) {
                title = node.country + (!!title ? " - " + title : "");
            }
            if (!!node.region) {
                title = node.region + (!!title ? " - " + title : "");
            }

            if (popup) {
                return React.createElement(
                    "div",
                    { className: "api-status" },
                    React.createElement(
                        "a",
                        null,
                        React.createElement(Icon, {
                            className: ping.color + " default-icon",
                            name: isActive ? "connected" : "disconnected",
                            title: isActive ? "icons.connected" : "icons.disconnected",
                            size: "1_5x",
                            onClick: this.activate.bind(this, url)
                        }),
                        React.createElement(Icon, {
                            className: ping.color + " hover-icon",
                            name: "connect",
                            title: "icons.connect",
                            size: "1_5x",
                            onClick: this.activate.bind(this, url)
                        })
                    ),
                    title
                );
            } else {
                return React.createElement(
                    "div",
                    { className: "api-node" },
                    React.createElement(
                        "div",
                        { className: "api-node-left" },
                        React.createElement(
                            "p",
                            { className: "api-node-title" },
                            title
                        ),
                        !!node.operator && React.createElement(
                            "p",
                            { className: "api-node-operator" },
                            node.operator,
                            "\xA0\xA0\xA0"
                        ),
                        React.createElement(
                            "p",
                            {
                                className: "api-node-url",
                                id: isActive ? "active_node" : null
                            },
                            url
                        )
                    ),
                    React.createElement(
                        "div",
                        null,
                        React.createElement(
                            "div",
                            { className: "api-status" },
                            React.createElement(
                                "span",
                                { className: ping.color },
                                !!ping.rating && React.createElement(Translate, {
                                    content: "settings." + ping.rating
                                }),
                                !!ping.toString && React.createElement(
                                    "p",
                                    null,
                                    ping.toString
                                )
                            )
                        )
                    ),
                    React.createElement(
                        "div",
                        { style: { marginTop: "-5px" } },
                        canBeHidden && React.createElement(
                            "a",
                            {
                                onClick: hidden ? this.show.bind(this, url) : this.hide.bind(this, url)
                            },
                            React.createElement(Icon, {
                                className: "shuffle",
                                name: hidden ? "eye-striked" : "eye",
                                title: hidden ? "icons.eye_striked" : "icons.eye",
                                size: "1_5x"
                            })
                        ),
                        canBeRemoved && React.createElement(
                            "a",
                            { onClick: this.remove.bind(this, url, title) },
                            React.createElement(Icon, {
                                name: "times",
                                title: "icons.times",
                                size: "1_5x"
                            })
                        ),
                        React.createElement(
                            "div",
                            { className: "api-status" },
                            !isActive ? React.createElement(
                                "a",
                                {
                                    id: url,
                                    onClick: this.activate.bind(this, url)
                                },
                                React.createElement(Icon, {
                                    className: ping.color + " default-icon",
                                    name: "disconnected",
                                    title: "icons.connect",
                                    size: "1_5x"
                                }),
                                React.createElement(Icon, {
                                    className: ping.color + " hover-icon",
                                    name: "connect",
                                    title: "icons.connect",
                                    size: "1_5x"
                                })
                            ) : React.createElement(Icon, {
                                className: ping.color,
                                name: "connected",
                                title: "icons.connected",
                                size: "2x"
                            })
                        )
                    )
                );
            }
        }
    }]);

    return ApiNode;
}(React.Component);

ApiNode.defaultProps = {
    node: {}
};

var AccessSettings = function (_React$Component3) {
    _inherits(AccessSettings, _React$Component3);

    function AccessSettings(props) {
        _classCallCheck(this, AccessSettings);

        var _this3 = _possibleConstructorReturn(this, (AccessSettings.__proto__ || Object.getPrototypeOf(AccessSettings)).call(this, props));

        _this3.state = {
            activeTab: "available_nodes"
        };
        return _this3;
    }

    /**
     * Copies all keys in the default apiServer and adds the ping
     *
     * @param node
     * @returns {{ping: *}}
     */


    _createClass(AccessSettings, [{
        key: "getNode",
        value: function getNode(node) {
            var props = this.props;

            var nodeWrapper = {
                ping: props.apiLatencies[node.url]
            };
            Object.keys(node).forEach(function (key) {
                nodeWrapper[key] = node[key];
            });
            return nodeWrapper;
        }
    }, {
        key: "_getConnectedNode",
        value: function _getConnectedNode() {
            var connectedURL = this.props.connectedNode || autoSelectionUrl;

            var connectedNode = this.props.nodes.find(function (node) {
                return node.url == connectedURL;
            });

            return connectedNode ? this.getNode(connectedNode) : null;
        }
    }, {
        key: "_connectedNodeIsPersonal",
        value: function _connectedNodeIsPersonal() {
            var _this4 = this;

            if (!this.props.connectedNode) {
                return false;
            }
            var cn = this.props.nodes.find(function (node) {
                return node.url == _this4.props.connectedNode;
            });
            return cn && this._nodeIsPersonal(cn);
        }
    }, {
        key: "_nodeIsPersonal",
        value: function _nodeIsPersonal(node) {
            return !node.default && !node.hidden && !isTestNet(node.url);
        }
    }, {
        key: "_getMainNetNodes",
        value: function _getMainNetNodes() {
            return this.props.nodes.filter(function (a) {
                return !isTestNet(a.url);
            });
        }

        /**
         * @param node either a string (and then au
         * @param connectedNode
         * @returns {XML}
         */

    }, {
        key: "renderNode",
        value: function renderNode(node, connectedNode) {
            var props = this.props;


            if (node == null) return null;
            return React.createElement(ApiNode, {
                node: node,
                key: node.url,
                showRemoveNodeModal: props.showRemoveNodeModal,
                isActive: connectedNode !== null && node.url == connectedNode.url,
                popup: props.popup
            });
        }
    }, {
        key: "renderAutoSelection",
        value: function renderAutoSelection(connectedNode) {
            var props = this.props;


            return React.createElement(AutoSelectionNode, {
                key: autoSelectionUrl,
                isActive: props.selectedNode === autoSelectionUrl,
                connectedNode: connectedNode,
                totalNodes: this._getMainNetNodes().length,
                popup: props.popup
            });
        }
    }, {
        key: "_changeTab",
        value: function _changeTab(tab) {
            this.setState({
                activeTab: tab
            });
        }
    }, {
        key: "_recalculateLatency",
        value: function _recalculateLatency(event, feedback) {
            var _this5 = this;

            routerTransitioner.doLatencyUpdate(true, false, 1).finally(function () {
                _this5.forceUpdate();
                feedback();
            });
        }
    }, {
        key: "render",
        value: function render() {
            var _this6 = this;

            var props = this.props;

            // placeholder to avoid this mismatch

            var getNode = this.getNode.bind(this);
            var renderNode = this.renderNode.bind(this);

            // currently selected and active node
            var connectedNode = this._getConnectedNode();

            var allNodesExceptConnected = props.nodes.map(function (node) {
                return getNode(node);
            }).filter(function (node) {
                return (connectedNode == null || node.url !== connectedNode.url) && node.url !== autoSelectionUrl;
            }).sort(function (a, b) {
                var isTestnet = isTestNet(a.url);
                if (!!a.ping && !!b.ping) {
                    return a.ping - b.ping;
                } else if (!a.ping && !b.ping) {
                    if (isTestnet) return -1;
                    return 1;
                } else if (!!a.ping && !b.ping) {
                    return -1;
                } else if (!!b.ping && !a.ping) {
                    return 1;
                }
                return 0;
            });

            var nodesToShow = null;
            var onlyPersonalNodeActive = false;
            if (this.state.activeTab === "my_nodes") {
                nodesToShow = allNodesExceptConnected.filter(function (node) {
                    return _this6._nodeIsPersonal(node);
                });
                onlyPersonalNodeActive = this._connectedNodeIsPersonal() && nodesToShow.length === 0;
            } else if (this.state.activeTab === "available_nodes") {
                nodesToShow = allNodesExceptConnected.filter(function (node) {
                    return node.default && !node.hidden && !isTestNet(node.url);
                });
            } else if (this.state.activeTab === "testnet_nodes") {
                nodesToShow = allNodesExceptConnected.filter(function (node) {
                    return isTestNet(node.url);
                });
            } else {
                nodesToShow = allNodesExceptConnected.filter(function (node) {
                    return node.hidden && !isTestNet(node.url);
                });
            }

            var popupCount = 0;

            var backgroundPinging = !!routerTransitioner && routerTransitioner.isBackgroundPingingInProgress();

            return this.props.popup ? React.createElement(
                "div",
                null,
                React.createElement(
                    "div",
                    { style: { fontWeight: "bold", height: 40 } },
                    React.createElement(Translate, { content: "settings.switch" }),
                    this.renderAutoSelection(connectedNode)
                ),
                React.createElement(
                    "div",
                    {
                        className: "nodes-list",
                        style: {
                            display: props.selectedNode === autoSelectionUrl ? "none" : ""
                        }
                    },
                    nodesToShow.map(function (node) {
                        popupCount++;
                        if (popupCount <= 5) {
                            return renderNode(node, connectedNode);
                        }
                    })
                )
            ) : React.createElement(
                "div",
                { style: { paddingTop: "1em" } },
                this.renderAutoSelection(connectedNode),
                React.createElement("div", { style: { clear: "both" } }),
                React.createElement(
                    "div",
                    { className: "active-node" },
                    React.createElement(LoadingButton, {
                        style: { float: "right" },
                        isLoading: backgroundPinging,
                        caption: "settings.ping",
                        loadingType: "inside-feedback-resize",
                        loadingMessage: "settings.pinging",
                        onClick: this._recalculateLatency.bind(this)
                    }),
                    React.createElement(Translate, {
                        component: "h4",
                        style: { marginLeft: "1rem" },
                        content: "settings.active_node"
                    }),
                    renderNode(connectedNode, connectedNode)
                ),
                React.createElement(
                    "div",
                    {
                        className: "nodes",
                        style: {
                            position: "relative",
                            marginBottom: "2em"
                        }
                    },
                    React.createElement(
                        "div",
                        { className: "grid-block shrink", style: { marginLeft: 0 } },
                        ["available_nodes", "my_nodes", "hidden_nodes", "testnet_nodes"].map(function (key) {
                            return React.createElement(
                                "div",
                                {
                                    key: key,
                                    className: cnames("nodes-header clickable", {
                                        inactive: _this6.state.activeTab !== key
                                    }),
                                    onClick: _this6._changeTab.bind(_this6, key)
                                },
                                React.createElement(Translate, { content: "settings." + key })
                            );
                        })
                    ),
                    this.state.activeTab === "my_nodes" && React.createElement(
                        "div",
                        {
                            style: { paddingLeft: "1rem", paddingBottom: "1rem" }
                        },
                        React.createElement(
                            Button,
                            {
                                type: "primary",
                                onClick: props.showAddNodeModal
                            },
                            React.createElement(Translate, {
                                id: "add",
                                component: "span",
                                content: "settings.add_api"
                            })
                        )
                    ),
                    this.state.activeTab === "testnet_nodes" && React.createElement(Translate, {
                        component: "p",
                        content: "settings.testnet_nodes_disclaimer"
                    }),
                    nodesToShow.map(function (node) {
                        return renderNode(node, connectedNode);
                    }),
                    onlyPersonalNodeActive ? React.createElement(
                        "div",
                        { className: "api-node" },
                        React.createElement(
                            "p",
                            {
                                className: "api-node-title",
                                style: { padding: "1rem" }
                            },
                            React.createElement(Translate, { content: "settings.personal_active" })
                        )
                    ) : null
                )
            );
        }
    }]);

    return AccessSettings;
}(React.Component);

AccessSettings = connect(AccessSettings, {
    listenTo: function listenTo() {
        return [SettingsStore];
    },
    getProps: function getProps() {
        return {
            // apiServer and activeNode are ambiguous definition when dealing with isActive, autoSelectionActive etc..
            // using distinct names
            selectedNode: SettingsStore.getState().settings.get("apiServer"),
            connectedNode: SettingsStore.getState().settings.get("activeNode"),
            apiLatencies: SettingsStore.getState().apiLatencies
        };
    }
});

export default AccessSettings;