var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from "react";
import FormattedAsset from "./FormattedAsset";
import ChainTypes from "./ChainTypes";
import BindToChainState from "./BindToChainState";
import PropTypes from "prop-types";

/**
 *  Given a balance_object, displays it in a pretty way
 *
 *  Expects one property, 'balance' which should be a balance_object id
 */

var BalanceComponent = function (_React$Component) {
    _inherits(BalanceComponent, _React$Component);

    function BalanceComponent() {
        _classCallCheck(this, BalanceComponent);

        return _possibleConstructorReturn(this, (BalanceComponent.__proto__ || Object.getPrototypeOf(BalanceComponent)).apply(this, arguments));
    }

    _createClass(BalanceComponent, [{
        key: "render",
        value: function render() {
            var amount = this.props.balance.get("balance");
            if (amount || amount === 0) {
                amount = Number(this.props.balance.get("balance"));
            } else {
                amount = null;
            }
            var type = this.props.balance.get("asset_type");
            return React.createElement(FormattedAsset, {
                amount: amount,
                asset: type,
                asPercentage: this.props.asPercentage,
                assetInfo: this.props.assetInfo,
                replace: this.props.replace,
                hide_asset: this.props.hide_asset
            });
        }
    }]);

    return BalanceComponent;
}(React.Component);

BalanceComponent.propTypes = {
    balance: ChainTypes.ChainObject.isRequired,
    assetInfo: PropTypes.node,
    hide_asset: PropTypes.bool
};
BalanceComponent.defaultProps = {
    hide_asset: false
};


export default BindToChainState(BalanceComponent);