import React from "react";
import Translate from "react-translate-component";
import TranslateWithLinks from "../../Utility/TranslateWithLinks";

export var LimitOrderCancel = function LimitOrderCancel(_ref) {
    var op = _ref.op,
        changeColor = _ref.changeColor,
        fromComponent = _ref.fromComponent,
        linkToAccount = _ref.linkToAccount;

    changeColor("cancel");

    if (fromComponent === "proposed_operation") {
        return React.createElement(
            "span",
            null,
            linkToAccount(op[1].fee_paying_account),
            "\xA0",
            React.createElement(Translate, {
                component: "span",
                content: "proposal.limit_order_cancel"
            }),
            "\xA0#",
            op[1].order.substring(4)
        );
    } else {
        return React.createElement(
            "span",
            null,
            React.createElement(TranslateWithLinks, {
                string: "operation.limit_order_cancel",
                keys: [{
                    type: "account",
                    value: op[1].fee_paying_account,
                    arg: "account"
                }],
                params: {
                    order: op[1].order.substring(4)
                }
            })
        );
    }
};