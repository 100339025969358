var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from "react";
import counterpart from "counterpart";
import PropTypes from "prop-types";

var AccountSelect = function (_React$Component) {
    _inherits(AccountSelect, _React$Component);

    function AccountSelect(props) {
        _classCallCheck(this, AccountSelect);

        var _this = _possibleConstructorReturn(this, (AccountSelect.__proto__ || Object.getPrototypeOf(AccountSelect)).call(this, props));

        _this.state = { selected: null };
        _this.default_placeholder = counterpart.translate("account.select_placeholder");
        return _this;
    }

    _createClass(AccountSelect, [{
        key: "componentDidMount",
        value: function componentDidMount() {
            // setTimeout(() => {
            //     var account_names = this.props.account_names;
            //     if (account_names.length === 1 && !!account_names[0] && account_names[0] !== "" && account_names[0] !== this.state.selected) {
            //         this._selectAccount(account_names[0]);
            //     }
            // }, 100);
        }
    }, {
        key: "shouldComponentUpdate",
        value: function shouldComponentUpdate(nextProps) {
            return nextProps.selected !== this.props.selected || nextProps.list_size !== this.props.list_size || nextProps.placeholder !== this.props.placeholder || nextProps.account_names !== this.props.account_names;
        }
    }, {
        key: "value",
        value: function value() {
            return this.state.selected;
        }
    }, {
        key: "reset",
        value: function reset() {
            this.setState({ selected: null });
        }
    }, {
        key: "render",
        value: function render() {
            var account_names = this.props.account_names;
            var selected_account = this.props.selected;
            var placeholder = this.props.placeholder || this.default_placeholder;
            if (this.props.list_size > 1) {
                placeholder = React.createElement(
                    "option",
                    { value: "", disabled: true },
                    placeholder
                );
            } else {
                //When disabled and list_size was 1, chrome was skipping the
                //placeholder and selecting the 1st item automatically (not shown)
                placeholder = React.createElement(
                    "option",
                    { value: "" },
                    placeholder
                );
            }
            var key = 0;
            return React.createElement(
                "select",
                {
                    ref: "account-selector",
                    key: selected_account,
                    defaultValue: selected_account,
                    className: "form-control account-select bts-select " + (this.props.className || ""),
                    onChange: this._onAccountChange.bind(this),
                    style: this.props.center ? { margin: "0 auto" } : null,
                    tabIndex: this.props.tabIndex
                },
                placeholder,
                account_names.sort().map(function (account_name) {
                    if (!account_name || account_name === "") {
                        return null;
                    }
                    return React.createElement(
                        "option",
                        { key: key++, value: account_name },
                        account_name
                    );
                })
            );
        }
    }, {
        key: "_onAccountChange",
        value: function _onAccountChange(e) {
            //DEBUG console.log('... _onAccountChange',e.target.value)
            e.preventDefault();
            var value = e.target.value;
            this._selectAccount(value);
        }
    }, {
        key: "_selectAccount",
        value: function _selectAccount(value) {
            var placeholder = this.props.placeholder || this.default_placeholder;
            if (value === placeholder) {
                value = null;
            }
            this.setState({
                selected: value
            });
            if (this.props.onChange) {
                this.props.onChange(value);
            }
        }
    }]);

    return AccountSelect;
}(React.Component);

AccountSelect.propTypes = {
    account_names: PropTypes.array,
    list_size: PropTypes.number,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    center: PropTypes.bool,
    tabIndex: PropTypes.number,
    className: PropTypes.string
    //defaultAccount: PropTypes.string
};
export default AccountSelect;