var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from "react";
import { Link } from "react-router-dom";
import ChainTypes from "./ChainTypes";
import BindToChainState from "./BindToChainState";
import PropTypes from "prop-types";

var LinkToAccountById = function (_React$Component) {
    _inherits(LinkToAccountById, _React$Component);

    function LinkToAccountById() {
        _classCallCheck(this, LinkToAccountById);

        return _possibleConstructorReturn(this, (LinkToAccountById.__proto__ || Object.getPrototypeOf(LinkToAccountById)).apply(this, arguments));
    }

    _createClass(LinkToAccountById, [{
        key: "shouldComponentUpdate",
        value: function shouldComponentUpdate(nextProps) {
            if (nextProps.account === this.props.account) {
                return false;
            }
            return true;
        }
    }, {
        key: "render",
        value: function render() {
            var account_name = this.props.account;
            if (!account_name) {
                return React.createElement(
                    "span",
                    null,
                    this.props.account.get("id")
                );
            }

            var maxDisplayAccountNameLength = this.props.maxDisplayAccountNameLength > 0 ? 20 : Infinity;

            return this.props.noLink ? React.createElement(
                "span",
                null,
                account_name.substr(0, maxDisplayAccountNameLength),
                account_name.length > maxDisplayAccountNameLength ? "..." : null
            ) : React.createElement(
                Link,
                {
                    onClick: this.props.onClick ? this.props.onClick : function () {},
                    to: "/account/" + account_name + "/" + this.props.subpage + "/"
                },
                account_name.substr(0, maxDisplayAccountNameLength),
                account_name.length > maxDisplayAccountNameLength ? "..." : null
            );
        }
    }]);

    return LinkToAccountById;
}(React.Component);

LinkToAccountById.propTypes = {
    account: ChainTypes.ChainAccountName.isRequired,
    subpage: PropTypes.string.isRequired,
    maxDisplayAccountNameLength: PropTypes.number
};
LinkToAccountById.defaultProps = {
    subpage: "overview",
    autosubscribe: false,
    maxDisplayAccountNameLength: 20
};


export default BindToChainState(LinkToAccountById, { autosubscribe: false });