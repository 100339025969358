var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import { hot } from "react-hot-loader";
import React from "react";
import App from "./App";
import IntlActions from "actions/IntlActions";
import WalletManagerStore from "stores/WalletManagerStore";
import SettingsStore from "stores/SettingsStore";
import IntlStore from "stores/IntlStore";
import intlData from "./components/Utility/intlData";
import alt from "alt-instance";
import { connect, supplyFluxContext } from "alt-react";
import { IntlProvider } from "react-intl";
import willTransitionTo from "./routerTransition";
import { BodyClassName } from "bitshares-ui-style-guide";
import LoadingIndicator from "./components/LoadingIndicator";
import InitError from "./components/InitError";
import SyncError from "./components/SyncError";
import counterpart from "counterpart";
import LogsActions from "actions/LogsActions";

/*
* Electron does not support browserHistory, so we need to use hashHistory.
* The same is true for servers without configuration options, such as Github Pages
*/
import { HashRouter, BrowserRouter } from "react-router-dom";
var Router = __HASH_HISTORY__ ? HashRouter : BrowserRouter;

var RootIntl = function (_React$Component) {
    _inherits(RootIntl, _React$Component);

    function RootIntl() {
        _classCallCheck(this, RootIntl);

        return _possibleConstructorReturn(this, (RootIntl.__proto__ || Object.getPrototypeOf(RootIntl)).apply(this, arguments));
    }

    _createClass(RootIntl, [{
        key: "componentWillMount",
        value: function componentWillMount() {
            IntlActions.switchLocale(this.props.locale);
        }
    }, {
        key: "render",
        value: function render() {
            return React.createElement(
                IntlProvider,
                {
                    locale: this.props.locale,
                    formats: intlData.formats,
                    initialNow: Date.now()
                },
                React.createElement(
                    Router,
                    null,
                    React.createElement(App, this.props)
                )
            );
        }
    }]);

    return RootIntl;
}(React.Component);

var AppInit = function (_React$Component2) {
    _inherits(AppInit, _React$Component2);

    function AppInit() {
        _classCallCheck(this, AppInit);

        var _this2 = _possibleConstructorReturn(this, (AppInit.__proto__ || Object.getPrototypeOf(AppInit)).call(this));

        _this2.state = {
            apiConnected: false,
            apiError: false,
            syncError: null,
            status: "",
            extendeLogText: [] // used to cache logs when not mounted
        };
        _this2.mounted = true;
        _this2.persistentLogEnabled = false;
        return _this2;
    }

    /**
     * Global error catching and forwarding to log handler
     * @param error
     */


    _createClass(AppInit, [{
        key: "componentDidCatch",
        value: function componentDidCatch(error) {
            this.saveExtendedLog("error", [error]);
        }
    }, {
        key: "componentDidUpdate",
        value: function componentDidUpdate(nextProps, nextState) {
            LogsActions.setLog(nextState.extendeLogText);
        }
    }, {
        key: "saveExtendedLog",
        value: function saveExtendedLog(type, logText) {
            var maxlogslength = 19;
            var logState = this.state.extendeLogText;
            var text = "";

            var _iteratorNormalCompletion = true;
            var _didIteratorError = false;
            var _iteratorError = undefined;

            try {
                for (var _iterator = logText[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                    var value = _step.value;

                    text += value;
                }
            } catch (err) {
                _didIteratorError = true;
                _iteratorError = err;
            } finally {
                try {
                    if (!_iteratorNormalCompletion && _iterator.return) {
                        _iterator.return();
                    }
                } finally {
                    if (_didIteratorError) {
                        throw _iteratorError;
                    }
                }
            }

            text = [type, ": ", text].join("");
            if (logState.length > maxlogslength) {
                logState.splice(0, 1);
            }
            if (text.indexOf(logState[logState.length - 1])) {
                logState.push(text);
                if (this.mounted) {
                    this.setState({ extendeLogText: logState });
                } else {
                    LogsActions.setLog(logState);
                }
            }
        }
    }, {
        key: "_enablePersistingLog",
        value: function _enablePersistingLog() {
            var _this3 = this;

            if (this.persistentLogEnabled) return;

            if (!this.state.extendeLogText.length) {
                LogsActions.getLogs().then(function (data) {
                    if (data) {
                        _this3.setState({ extendeLogText: data });
                    }
                });
            }

            var thiz = this;
            var saveLog = function saveLog(type, log) {
                if (log.length > 1 && typeof log[1] === "string" && log[1] === "html2canvas:") {
                    return;
                }
                thiz.saveExtendedLog(type, Array.from(log));
                if (thiz.mounted) {
                    console["str" + type].apply(console, log);
                }
            };

            // see https://www.sitepoint.com/javascript-decorators-what-they-are/ for decorator

            // see https://stackoverflow.com/questions/9559725/extending-console-log-without-affecting-log-line for line numbers

            console.strlog = console.log.bind(console);
            console.strerror = console.error.bind(console);
            console.strwarn = console.warn.bind(console);
            console.strinfo = console.info.bind(console);
            console.strtimeEnd = console.timeEnd.bind(console);
            console.strdebug = console.debug.bind(console);

            console.log = function () {
                saveLog("log", arguments);
            };
            console.warn = function () {
                saveLog("warn", arguments);
            };
            console.error = function () {
                saveLog("error", arguments);
            };
            console.info = function () {
                saveLog("info", arguments);
            };
            console.timeEnd = function () {
                saveLog("timeEnd", arguments);
            };
            console.debug = function () {
                saveLog("debug", arguments);
            };

            this.persistentLogEnabled = true;
        }
    }, {
        key: "componentWillMount",
        value: function componentWillMount() {
            var _this4 = this;

            if (!__DEV__) {
                this._enablePersistingLog();
            }

            willTransitionTo(true, this._statusCallback.bind(this)).then(function () {
                _this4.setState({
                    apiConnected: true,
                    apiError: false,
                    syncError: null
                });
            }).catch(function (err) {
                console.log("willTransitionTo err:", err);
                _this4.setState({
                    apiConnected: false,
                    apiError: true,
                    syncError: !err ? null : (err && err.message).indexOf("ChainStore sync error") !== -1
                });
            });
        }
    }, {
        key: "componentDidMount",
        value: function componentDidMount() {
            this.mounted = true;
            //Detect OS for platform specific fixes
            if (navigator.platform.indexOf("Win") > -1) {
                var main = document.getElementById("content");
                var windowsClass = "windows";
                if (main.className.indexOf("windows") === -1) {
                    main.className = main.className + (main.className.length ? " " : "") + windowsClass;
                }
            }
        }
    }, {
        key: "componentWillUnmount",
        value: function componentWillUnmount() {
            this.mounted = false;
        }
    }, {
        key: "_statusCallback",
        value: function _statusCallback(status) {
            this.setState({ status: status });
        }
    }, {
        key: "render",
        value: function render() {
            var _props = this.props,
                theme = _props.theme,
                apiServer = _props.apiServer;
            var _state = this.state,
                apiConnected = _state.apiConnected,
                apiError = _state.apiError,
                syncError = _state.syncError,
                status = _state.status;


            if (!apiConnected) {
                var server = apiServer;
                if (!!!server) {
                    server = "";
                }
                return React.createElement(
                    "div",
                    {
                        style: { backgroundColor: !theme ? "#2a2a2a" : null },
                        className: theme
                    },
                    React.createElement(
                        "div",
                        { id: "content-wrapper" },
                        React.createElement(
                            "div",
                            { className: "grid-frame vertical" },
                            !apiError ? React.createElement(LoadingIndicator, {
                                loadingText: status || counterpart.translate("app_init.connecting", { server: server })
                            }) : syncError ? React.createElement(SyncError, null) : React.createElement(
                                BodyClassName,
                                { className: theme },
                                React.createElement(InitError, null)
                            )
                        )
                    )
                );
            }
            return React.createElement(RootIntl, _extends({}, this.props, this.state));
        }
    }]);

    return AppInit;
}(React.Component);

AppInit = connect(AppInit, {
    listenTo: function listenTo() {
        return [IntlStore, WalletManagerStore, SettingsStore];
    },
    getProps: function getProps() {
        return {
            locale: IntlStore.getState().currentLocale,
            walletMode: !SettingsStore.getState().settings.get("passwordLogin") || !!WalletManagerStore.getState().current_wallet,
            theme: SettingsStore.getState().settings.get("themes"),
            apiServer: SettingsStore.getState().settings.get("activeNode", "")
        };
    }
});
AppInit = supplyFluxContext(alt)(AppInit);
export default hot(module)(AppInit);