import _pick from "lodash-es/pick";
import _get from "lodash-es/get";
import _omit from "lodash-es/omit";
import _toPairs from "lodash-es/toPairs";
import _clone from "lodash-es/clone";
import _reject from "lodash-es/reject";
import _flow from "lodash-es/flow";
import _curry from "lodash-es/curry";

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from "react";

import { ChainStore } from "bitsharesjs";
import ChainTypes from "./ChainTypes";
import utils from "common/utils";
import { getDisplayName } from "common/reactUtils";
import LoadingIndicator from "../LoadingIndicator";

/**
 * @brief provides automatic fetching and updating of chain data
 *
 * After applying this decorator to component any property of a type from ChainTypes
 * specified in component's propTypes will be automatically converted from object or account id
 * into a state object that is either undefined, null or an Immutable object.   The
 * Immutable object will automatically be updated anytime it changes on the
 * blockchain.
 *
 * Example:
 *
 * class Balance {
 *    static propTypes = {
 *        balance: ChainTypes.ChainObject.isRequired
 *    }
 *    render() {
 *        let amount = Number(this.props.balance.get('balance'));
 *        let type = this.props.balance.get('asset_type');
 *        return (<FormattedAsset amount={amount} asset={type}/>);
 *    }
 * }
 * Balance = BindToChainState(Balance);
 *
 * <Balance balance="1.5.3"/>
 */

var arrayElement = function arrayElement(element_number, array) {
    return array[element_number];
};
var firstEl = _curry(arrayElement)(0);
var secondEl = _curry(arrayElement)(1);
var checkChainType = _curry(function (chain_type, t) {
    return t === chain_type || t === chain_type.isRequired;
});
var isObjectType = checkChainType(ChainTypes.ChainObject);
var isAccountType = checkChainType(ChainTypes.ChainAccount);
var isKeyRefsType = checkChainType(ChainTypes.ChainKeyRefs);
var isAddressBalancesType = checkChainType(ChainTypes.ChainAddressBalances);
var isAssetType = checkChainType(ChainTypes.ChainAsset);
var isObjectsListType = checkChainType(ChainTypes.ChainObjectsList);
var isAccountsListType = checkChainType(ChainTypes.ChainAccountsList);
var isAssetsListType = checkChainType(ChainTypes.ChainAssetsList);
var isAccountNameType = checkChainType(ChainTypes.ChainAccountName);

function checkIfRequired(t) {
    for (var k in ChainTypes) {
        var v = ChainTypes[k];
        if (t === v.isRequired) return true;
    }
    return false;
}

function BindToChainState(Component) {
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    var Wrapper = function (_React$Component) {
        _inherits(Wrapper, _React$Component);

        function Wrapper(props) {
            _classCallCheck(this, Wrapper);

            var _this = _possibleConstructorReturn(this, (Wrapper.__proto__ || Object.getPrototypeOf(Wrapper)).call(this, props));

            _this.hasErrored = false;

            var prop_types_array = _toPairs(Component.propTypes);
            if (options && options.all_props) {
                _this.chain_objects = _reject(Object.keys(_this.props), function (e) {
                    return e === "children" || e === "show_loader";
                });
                _this.chain_accounts = [];
                _this.chain_account_names = [];
                _this.chain_key_refs = [];
                _this.chain_address_balances = [];
                _this.chain_assets = [];
                _this.chain_objects_list = [];
                _this.chain_accounts_list = [];
                _this.chain_assets_list = [];
                _this.required_props = [];
                _this.all_chain_props = _this.chain_objects;
            } else {
                _this.chain_objects = prop_types_array.filter(_flow(secondEl, isObjectType)).map(firstEl);
                _this.chain_accounts = prop_types_array.filter(_flow(secondEl, isAccountType)).map(firstEl);
                _this.chain_account_names = prop_types_array.filter(_flow(secondEl, isAccountNameType)).map(firstEl);
                _this.chain_key_refs = prop_types_array.filter(_flow(secondEl, isKeyRefsType)).map(firstEl);
                _this.chain_address_balances = prop_types_array.filter(_flow(secondEl, isAddressBalancesType)).map(firstEl);
                _this.chain_assets = prop_types_array.filter(_flow(secondEl, isAssetType)).map(firstEl);
                _this.chain_objects_list = prop_types_array.filter(_flow(secondEl, isObjectsListType)).map(firstEl);
                _this.chain_accounts_list = prop_types_array.filter(_flow(secondEl, isAccountsListType)).map(firstEl);
                _this.chain_assets_list = prop_types_array.filter(_flow(secondEl, isAssetsListType)).map(firstEl);
                _this.required_props = prop_types_array.filter(_flow(secondEl, checkIfRequired)).map(firstEl);
                _this.all_chain_props = [].concat(_toConsumableArray(_this.chain_objects), _toConsumableArray(_this.chain_accounts), _toConsumableArray(_this.chain_account_names), _toConsumableArray(_this.chain_key_refs), _toConsumableArray(_this.chain_address_balances), _toConsumableArray(_this.chain_assets), _toConsumableArray(_this.chain_objects_list));
            }
            if (options && options.require_all_props) {
                _this.required_props = _this.all_chain_props;
            }
            _this.dynamic_props = {};
            _this.default_props = _clone(Component.defaultProps) || {};
            for (var key in _this.default_props) {
                var value = _this.default_props[key];
                if (typeof value === "string" && value.indexOf("props.") === 0) {
                    _this.dynamic_props[key] = _get(_this, value);
                }
            }

            _this.tempComponent = Component.defaultProps ? Component.defaultProps.tempComponent || null : null;

            //console.log("----- Wrapper constructor ----->", this.all_chain_props);
            _this.update = _this.update.bind(_this);
            _this.state = { resolved: false };
            return _this;
        }

        _createClass(Wrapper, [{
            key: "shouldComponentUpdate",
            value: function shouldComponentUpdate(nextProps, nextState) {
                return !utils.are_equal_shallow(this.props, nextProps) || !utils.are_equal_shallow(this.state, nextState);
            }
        }, {
            key: "componentDidCatch",
            value: function componentDidCatch(error, errorInfo) {
                this._errored(error, errorInfo);
            }
        }, {
            key: "_errored",
            value: function _errored(error, errorInfo) {
                console.error("BindToChainState(" + getDisplayName(Component) + ")", error, errorInfo);
                this.setState({
                    hasErrored: true
                });
            }
        }, {
            key: "componentWillMount",
            value: function componentWillMount() {
                ChainStore.subscribe(this.update);
                this.update();
            }
        }, {
            key: "componentWillUnmount",
            value: function componentWillUnmount() {
                ChainStore.unsubscribe(this.update);
            }
        }, {
            key: "componentWillReceiveProps",
            value: function componentWillReceiveProps(next_props) {
                if (options && options.all_props) {
                    this.chain_objects = _reject(Object.keys(next_props), function (e) {
                        return e === "children" || e === "show_loader";
                    });
                    this.all_chain_props = this.chain_objects;

                    var newState = _pick(this.state, this.chain_objects);
                    if (!utils.are_equal_shallow(newState, this.state)) {
                        this.setState(newState);
                    }
                }
                var props_obj = null;
                for (var k in this.dynamic_props) {
                    var selector = this.default_props[k];
                    if (!props_obj) props_obj = { props: next_props };
                    var cur_value = _get(this, selector);
                    var next_value = _get(props_obj, selector);
                    if (next_value && next_value !== cur_value) {
                        this.dynamic_props[k] = _get(props_obj, selector);
                    }
                }
                this.update(next_props);
            }
        }, {
            key: "update",
            value: function update() {
                var next_props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

                try {
                    this._update(next_props);
                } catch (err) {
                    this._errored(err);
                }
            }
        }, {
            key: "_update",
            value: function _update() {
                var _this2 = this;

                var next_props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

                var props = next_props || this.props;
                var new_state = {};
                var all_objects_counter = 0;
                var resolved_objects_counter = 0;

                /* Resolve pure objects*/
                var _iteratorNormalCompletion = true;
                var _didIteratorError = false;
                var _iteratorError = undefined;

                try {
                    for (var _iterator = this.chain_objects[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                        var key = _step.value;

                        var prop = props[key] || this.dynamic_props[key] || this.default_props[key];
                        if (prop) {
                            var new_obj = ChainStore.getObject(prop, false, this.default_props["autosubscribe"]);
                            if (new_obj === undefined && this.required_props.indexOf(key) === -1 && new_obj !== this.state[key]) new_state[key] = new_obj;else if (new_obj && new_obj !== this.state[key]) new_state[key] = new_obj;
                            ++all_objects_counter;
                            if (new_obj !== undefined) ++resolved_objects_counter;
                        } else {
                            if (this.state[key]) new_state[key] = null;
                        }
                    }

                    /* Resolve accounts */
                } catch (err) {
                    _didIteratorError = true;
                    _iteratorError = err;
                } finally {
                    try {
                        if (!_iteratorNormalCompletion && _iterator.return) {
                            _iterator.return();
                        }
                    } finally {
                        if (_didIteratorError) {
                            throw _iteratorError;
                        }
                    }
                }

                var _iteratorNormalCompletion2 = true;
                var _didIteratorError2 = false;
                var _iteratorError2 = undefined;

                try {
                    for (var _iterator2 = this.chain_accounts[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
                        var _key = _step2.value;

                        var prop = props[_key] || this.dynamic_props[_key] || this.default_props[_key];
                        if (prop) {
                            if (prop[0] === "#" && Number.parseInt(prop.substring(1))) prop = "1.2." + prop.substring(1);
                            if (prop instanceof Map && !!prop.get("name") && prop.size == 1) {
                                prop = prop.get("name");
                            }
                            var _new_obj = ChainStore.getAccount(prop, this.default_props["autosubscribe"]);
                            if (_new_obj === undefined && this.required_props.indexOf(_key) === -1 && _new_obj !== this.state[_key]) new_state[_key] = _new_obj;else if (_new_obj && _new_obj !== this.state[_key]) new_state[_key] = _new_obj;else if (_new_obj === null) new_state[_key] = _new_obj;
                            ++all_objects_counter;
                            if (_new_obj !== undefined) ++resolved_objects_counter;
                            if (prop === "bitsharesblocksazdazdz") console.log("account:", prop, "new_obj", _new_obj, "all_objects_counter", all_objects_counter, "resolved_objects_counter", resolved_objects_counter);
                        } else {
                            if (this.state[_key]) new_state[_key] = null;
                        }
                    }

                    /* Resolve account names */
                } catch (err) {
                    _didIteratorError2 = true;
                    _iteratorError2 = err;
                } finally {
                    try {
                        if (!_iteratorNormalCompletion2 && _iterator2.return) {
                            _iterator2.return();
                        }
                    } finally {
                        if (_didIteratorError2) {
                            throw _iteratorError2;
                        }
                    }
                }

                var _iteratorNormalCompletion3 = true;
                var _didIteratorError3 = false;
                var _iteratorError3 = undefined;

                try {
                    for (var _iterator3 = this.chain_account_names[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
                        var _key2 = _step3.value;

                        var prop = props[_key2] || this.dynamic_props[_key2] || this.default_props[_key2];
                        if (prop) {
                            var name = ChainStore.getAccountName(prop);
                            if (name === undefined && this.required_props.indexOf(_key2) === -1 && name !== this.state[_key2]) new_state[_key2] = name;else if (name && name !== this.state[_key2]) new_state[_key2] = name;
                            ++all_objects_counter;
                            if (name !== undefined) ++resolved_objects_counter;
                        } else {
                            if (this.state[_key2]) new_state[_key2] = null;
                        }
                    }

                    /* Resolve account key references */
                } catch (err) {
                    _didIteratorError3 = true;
                    _iteratorError3 = err;
                } finally {
                    try {
                        if (!_iteratorNormalCompletion3 && _iterator3.return) {
                            _iterator3.return();
                        }
                    } finally {
                        if (_didIteratorError3) {
                            throw _iteratorError3;
                        }
                    }
                }

                var _iteratorNormalCompletion4 = true;
                var _didIteratorError4 = false;
                var _iteratorError4 = undefined;

                try {
                    for (var _iterator4 = this.chain_key_refs[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
                        var _key3 = _step4.value;

                        var prop = props[_key3] || this.dynamic_prop[_key3] || this.default_props[_key3];
                        if (prop) {
                            var _new_obj2 = ChainStore.getAccountRefsOfKey(prop);
                            if (_new_obj2 === undefined && this.required_props.indexOf(_key3) === -1 && _new_obj2 !== this.state[_key3]) new_state[_key3] = _new_obj2;else if (_new_obj2 && _new_obj2 !== this.state[_key3]) new_state[_key3] = _new_obj2;
                            ++all_objects_counter;
                            if (_new_obj2 !== undefined) ++resolved_objects_counter;
                        } else {
                            if (this.state[_key3]) new_state[_key3] = null;
                        }
                    }

                    /* Resolve balance objects */
                } catch (err) {
                    _didIteratorError4 = true;
                    _iteratorError4 = err;
                } finally {
                    try {
                        if (!_iteratorNormalCompletion4 && _iterator4.return) {
                            _iterator4.return();
                        }
                    } finally {
                        if (_didIteratorError4) {
                            throw _iteratorError4;
                        }
                    }
                }

                var _iteratorNormalCompletion5 = true;
                var _didIteratorError5 = false;
                var _iteratorError5 = undefined;

                try {
                    for (var _iterator5 = this.chain_address_balances[Symbol.iterator](), _step5; !(_iteratorNormalCompletion5 = (_step5 = _iterator5.next()).done); _iteratorNormalCompletion5 = true) {
                        var _key4 = _step5.value;

                        var prop = props[_key4] || this.dynamic_props[_key4] || this.default_props[_key4];

                        if (prop) {
                            var _new_obj3 = ChainStore.getBalanceObjects(prop);

                            if (_new_obj3 === undefined && this.required_props.indexOf(_key4) === -1 && _new_obj3 !== this.state[_key4]) new_state[_key4] = _new_obj3;else if (_new_obj3 && _new_obj3 !== this.state[_key4]) new_state[_key4] = _new_obj3;
                            ++all_objects_counter;
                            if (_new_obj3 !== undefined) ++resolved_objects_counter;
                        } else {
                            if (this.state[_key4]) new_state[_key4] = null;
                        }
                    }

                    /* Resolve assets */
                } catch (err) {
                    _didIteratorError5 = true;
                    _iteratorError5 = err;
                } finally {
                    try {
                        if (!_iteratorNormalCompletion5 && _iterator5.return) {
                            _iterator5.return();
                        }
                    } finally {
                        if (_didIteratorError5) {
                            throw _iteratorError5;
                        }
                    }
                }

                var _iteratorNormalCompletion6 = true;
                var _didIteratorError6 = false;
                var _iteratorError6 = undefined;

                try {
                    for (var _iterator6 = this.chain_assets[Symbol.iterator](), _step6; !(_iteratorNormalCompletion6 = (_step6 = _iterator6.next()).done); _iteratorNormalCompletion6 = true) {
                        var _key5 = _step6.value;

                        var prop = props[_key5] || this.dynamic_props[_key5] || this.default_props[_key5];
                        if (prop) {
                            var _new_obj4 = ChainStore.getAsset(prop);

                            if (_new_obj4 === undefined && this.required_props.indexOf(_key5) === -1 && _new_obj4 !== this.state[_key5]) new_state[_key5] = _new_obj4;else if (_new_obj4 && _new_obj4 !== this.state[_key5] || _new_obj4 === null) new_state[_key5] = _new_obj4;

                            ++all_objects_counter;
                            if (_new_obj4 !== undefined) ++resolved_objects_counter;
                        } else {
                            if (this.state[_key5]) new_state[_key5] = null;
                        }
                    }

                    /* Resolve lists of pure objects */
                } catch (err) {
                    _didIteratorError6 = true;
                    _iteratorError6 = err;
                } finally {
                    try {
                        if (!_iteratorNormalCompletion6 && _iterator6.return) {
                            _iterator6.return();
                        }
                    } finally {
                        if (_didIteratorError6) {
                            throw _iteratorError6;
                        }
                    }
                }

                var _iteratorNormalCompletion7 = true;
                var _didIteratorError7 = false;
                var _iteratorError7 = undefined;

                try {
                    for (var _iterator7 = this.chain_objects_list[Symbol.iterator](), _step7; !(_iteratorNormalCompletion7 = (_step7 = _iterator7.next()).done); _iteratorNormalCompletion7 = true) {
                        var _key6 = _step7.value;

                        //console.log("-- Wrapper.update -->", this.chain_objects_list);
                        var prop = props[_key6] || this.dynamic_props[_key6] || this.default_props[_key6];
                        if (prop) {
                            (function () {
                                var prop_prev_state = _this2.state[_key6];
                                var prop_new_state = [];
                                var changes = false;
                                if (!prop_prev_state || prop_prev_state.length !== prop.size) {
                                    prop_prev_state = [];
                                    changes = true;
                                }
                                var index = 0;
                                prop.forEach(function (obj_id) {
                                    ++index;
                                    if (obj_id) {
                                        var _new_obj5 = ChainStore.getObject(obj_id, false, _this2.default_props["autosubscribe"]);
                                        if (_new_obj5) ++resolved_objects_counter;
                                        if (prop_prev_state[index] !== _new_obj5) {
                                            changes = true;
                                            prop_new_state[index] = _new_obj5;
                                        } else {
                                            prop_new_state[index] = prop_prev_state[index];
                                        }
                                    }
                                    ++all_objects_counter;
                                });
                                //console.log("-- Wrapper.chain_objects_list: ", prop_new_state);
                                if (changes) new_state[_key6] = prop_new_state;
                            })();
                        } else {
                            if (this.state[_key6]) new_state[_key6] = null;
                        }
                    }

                    /* Resolve lists of accounts */
                } catch (err) {
                    _didIteratorError7 = true;
                    _iteratorError7 = err;
                } finally {
                    try {
                        if (!_iteratorNormalCompletion7 && _iterator7.return) {
                            _iterator7.return();
                        }
                    } finally {
                        if (_didIteratorError7) {
                            throw _iteratorError7;
                        }
                    }
                }

                var _iteratorNormalCompletion8 = true;
                var _didIteratorError8 = false;
                var _iteratorError8 = undefined;

                try {
                    for (var _iterator8 = this.chain_accounts_list[Symbol.iterator](), _step8; !(_iteratorNormalCompletion8 = (_step8 = _iterator8.next()).done); _iteratorNormalCompletion8 = true) {
                        var _key7 = _step8.value;

                        //console.log("-- Wrapper.update -->", this.chain_accounts_list);
                        var prop = props[_key7] || this.dynamic_props[_key7] || this.default_props[_key7];
                        if (prop) {
                            (function () {
                                var prop_prev_state = _this2.state[_key7];
                                var prop_new_state = [];
                                var changes = false;
                                if (!prop_prev_state || prop_prev_state.length !== prop.size) {
                                    prop_prev_state = [];
                                    changes = true;
                                }
                                var index = 0;
                                prop.forEach(function (obj_id) {
                                    //console.log("-- Wrapper.chain_accounts_list item -->", obj_id, index);
                                    if (obj_id) {
                                        var _new_obj6 = ChainStore.getAccount(obj_id, _this2.default_props["autosubscribe"]);
                                        if (_new_obj6) ++resolved_objects_counter;
                                        if (prop_prev_state[index] !== _new_obj6) {
                                            changes = true;
                                            prop_new_state[index] = _new_obj6;
                                        } else {
                                            prop_new_state[index] = prop_prev_state[index];
                                        }
                                    }
                                    ++index;
                                    ++all_objects_counter;
                                });
                                //console.log("-- Wrapper.chain_accounts_list: ", prop_new_state);
                                if (changes) new_state[_key7] = prop_new_state;
                            })();
                        } else {
                            if (this.state[_key7]) new_state[_key7] = null;
                        }
                    }

                    /* Resolve lists of assets */
                } catch (err) {
                    _didIteratorError8 = true;
                    _iteratorError8 = err;
                } finally {
                    try {
                        if (!_iteratorNormalCompletion8 && _iterator8.return) {
                            _iterator8.return();
                        }
                    } finally {
                        if (_didIteratorError8) {
                            throw _iteratorError8;
                        }
                    }
                }

                var _iteratorNormalCompletion9 = true;
                var _didIteratorError9 = false;
                var _iteratorError9 = undefined;

                try {
                    for (var _iterator9 = this.chain_assets_list[Symbol.iterator](), _step9; !(_iteratorNormalCompletion9 = (_step9 = _iterator9.next()).done); _iteratorNormalCompletion9 = true) {
                        var _key8 = _step9.value;

                        //console.log("-- Wrapper.update -->", this.chain_assets_list);
                        var prop = props[_key8] || this.dynamic_props[_key8] || this.default_props[_key8];
                        if (prop) {
                            (function () {
                                var prop_prev_state = _this2.state[_key8];
                                var prop_new_state = [];
                                var changes = false;
                                if (!prop_prev_state || prop_prev_state.length !== prop.size) {
                                    prop_prev_state = [];
                                    changes = true;
                                }
                                var index = 0;
                                prop.forEach(function (obj_id) {
                                    ++index;
                                    //console.log("-- Wrapper.chain_assets_list item -->", obj_id, index);
                                    if (obj_id) {
                                        var _new_obj7 = ChainStore.getAsset(obj_id);
                                        if (_new_obj7) ++resolved_objects_counter;
                                        if (prop_prev_state[index] !== _new_obj7) {
                                            changes = true;
                                            prop_new_state[index] = _new_obj7;
                                        } else {
                                            prop_new_state[index] = prop_prev_state[index];
                                        }
                                    }
                                    ++all_objects_counter;
                                });
                                //console.log("-- Wrapper.chain_assets_list: ", prop_new_state);
                                if (changes) new_state[_key8] = prop_new_state;
                            })();
                        } else {
                            if (this.state[_key8]) new_state[_key8] = null;
                        }
                    }

                    //console.log("----- Wrapper update ----->", this.all_chain_props, this.all_chain_props.length, all_objects_counter, resolved_objects_counter);
                } catch (err) {
                    _didIteratorError9 = true;
                    _iteratorError9 = err;
                } finally {
                    try {
                        if (!_iteratorNormalCompletion9 && _iterator9.return) {
                            _iterator9.return();
                        }
                    } finally {
                        if (_didIteratorError9) {
                            throw _iteratorError9;
                        }
                    }
                }

                if (all_objects_counter <= resolved_objects_counter) new_state.resolved = true;

                var stateChanged = false;

                /*
                 * are_equal_shallow won't correctly compare null to undefined, so
                 * we need to work around it by assigning a non-falsy value instead
                 * of null before making the comparison
                 */
                function replaceNull(state) {
                    var temp = {};
                    for (var _key9 in state) {
                        if (state[_key9] === null) temp[_key9] = "null";else temp[_key9] = state[_key9];
                    }
                    return temp;
                }
                var temp_state = replaceNull(this.state);
                var temp_new_state = replaceNull(new_state);
                for (var _key10 in temp_new_state) {
                    if (!utils.are_equal_shallow(temp_new_state[_key10], temp_state[_key10])) {
                        stateChanged = true;
                    } else {
                        delete new_state[_key10];
                    }
                }
                // console.time(Component.name + " setState");

                /* For some reason this stateChange is very expensive, so we need to limit it */
                if (stateChanged) this.setState(new_state);

                // let updateEnd = new Date().getTime();
                // if ((updateEnd - updateStart) > 15) {
                // console.log("slow update state change:", stateChanged, "new_state", new_state, "this state:", this.state);
                // console.timeEnd(Component.name + " setState");
                // console.timeEnd(Component.name + " before state");
                // console.timeEnd(Component.name + " after state");
                //     console.log("slow update", Component.name, updateEnd - updateStart, Object.keys(new_state));
                // }
            }
        }, {
            key: "render",
            value: function render() {
                var props = _omit(this.props, this.all_chain_props);
                var _iteratorNormalCompletion10 = true;
                var _didIteratorError10 = false;
                var _iteratorError10 = undefined;

                try {
                    for (var _iterator10 = this.required_props[Symbol.iterator](), _step10; !(_iteratorNormalCompletion10 = (_step10 = _iterator10.next()).done); _iteratorNormalCompletion10 = true) {
                        var prop = _step10.value;

                        if (this.state[prop] === undefined) {
                            if (typeof options !== "undefined" && options.show_loader) {
                                return React.createElement(LoadingIndicator, null);
                            } else if (this.hasErrored) {
                                return React.createElement(
                                    "span",
                                    null,
                                    "Error rendering component, please report"
                                );
                            } else {
                                // returning a temp component of the desired type prevents invariant violation errors, notably when rendering tr components
                                // to use, specicy a defaultProps field of tempComponent: "tr" (or "div", "td", etc as desired)
                                return this.tempComponent ? React.createElement(this.tempComponent) : React.createElement("span", null);
                            }
                        }
                    }
                    //return <span className={this.state.resolved ? "resolved":"notresolved"}><Component {...props} {...this.state}/></span>;
                } catch (err) {
                    _didIteratorError10 = true;
                    _iteratorError10 = err;
                } finally {
                    try {
                        if (!_iteratorNormalCompletion10 && _iterator10.return) {
                            _iterator10.return();
                        }
                    } finally {
                        if (_didIteratorError10) {
                            throw _iteratorError10;
                        }
                    }
                }

                return React.createElement(Component, _extends({ ref: "bound_component" }, props, this.state));
            }
        }]);

        return Wrapper;
    }(React.Component);

    Wrapper.displayName = "BindToChainState(" + getDisplayName(Component) + ")";
    return Wrapper;
}

var Wrapper = function (_React$Component2) {
    _inherits(Wrapper, _React$Component2);

    function Wrapper() {
        _classCallCheck(this, Wrapper);

        return _possibleConstructorReturn(this, (Wrapper.__proto__ || Object.getPrototypeOf(Wrapper)).apply(this, arguments));
    }

    _createClass(Wrapper, [{
        key: "render",
        value: function render() {
            return React.createElement(
                "span",
                { className: "wrapper" },
                this.props.children(this.props)
            );
        }
    }]);

    return Wrapper;
}(React.Component);

Wrapper = BindToChainState(Wrapper, { all_props: true, require_all_props: true });

BindToChainState.Wrapper = Wrapper;

export default BindToChainState;