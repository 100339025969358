var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from "react";
import AltContainer from "alt-container";
import Translate from "react-translate-component";
import BindToChainState from "../Utility/BindToChainState";
import ChainTypes from "../Utility/ChainTypes";
import CachedPropertyStore from "stores/CachedPropertyStore";
import BlockchainStore from "stores/BlockchainStore";
import WalletDb from "stores/WalletDb";
import SettingsStore from "stores/SettingsStore";
import SettingsActions from "actions/SettingsActions";
import AccessSettings from "../Settings/AccessSettings";
import Icon from "../Icon/Icon";
import "intro.js/introjs.css";
import guide from "intro.js";
import ReportModal from "../Modal/ReportModal";
import PropTypes from "prop-types";
import { routerTransitioner } from "../../routerTransition";
import LoadingIndicator from "../LoadingIndicator";
import counterpart from "counterpart";
import ChoiceModal from "../Modal/ChoiceModal";
import { ChainStore } from "bitsharesjs";
import ifvisible from "ifvisible";
import { getWalletName } from "branding";
import { Tooltip } from "bitshares-ui-style-guide";
import AccountStore from "../../stores/AccountStore";

// import {getLogoSmall} from "../../branding";
// import {getLogoDark} from "../../branding";

// const logoFooter = getLogoDark();

var Footer = function (_React$Component) {
    _inherits(Footer, _React$Component);

    function Footer(props) {
        _classCallCheck(this, Footer);

        var _this = _possibleConstructorReturn(this, (Footer.__proto__ || Object.getPrototypeOf(Footer)).call(this, props));

        _this.state = {
            hasOutOfSyncModalBeenShownOnce: false,
            isOutOfSyncModalVisible: false,
            isReportModalVisible: false,
            isAccessSettingsPopoverVisible: false,
            showConnectingPopup: false,
            showAccessSettingsTooltip: false
        };

        _this.getNode = _this.getNode.bind(_this);
        _this._showOutOfSyncModal = _this._showOutOfSyncModal.bind(_this);
        _this._hideOutOfSyncModal = _this._hideOutOfSyncModal.bind(_this);
        _this._showReportModal = _this._showReportModal.bind(_this);
        _this._hideReportModal = _this._hideReportModal.bind(_this);
        _this._showAccessSettingsTooltip = _this._showAccessSettingsTooltip.bind(_this);
        return _this;
    }

    _createClass(Footer, [{
        key: "_showOutOfSyncModal",
        value: function _showOutOfSyncModal() {
            this.setState({
                isOutOfSyncModalVisible: true
            });
        }
    }, {
        key: "_hideOutOfSyncModal",
        value: function _hideOutOfSyncModal() {
            this.setState({
                isOutOfSyncModalVisible: false
            });
        }
    }, {
        key: "_showReportModal",
        value: function _showReportModal() {
            this.setState({
                isReportModalVisible: true
            });
        }
    }, {
        key: "_hideReportModal",
        value: function _hideReportModal() {
            this.setState({
                isReportModalVisible: false
            });
        }
    }, {
        key: "componentDidMount",
        value: function componentDidMount() {
            this.checkNewVersionAvailable.call(this);

            this.downloadLink = "https://github.com/XBTS/xbts-ui/releases";

            var ensure = this._ensureConnectivity.bind(this);
            ifvisible.on("wakeup", function () {
                ensure();
            });
        }
    }, {
        key: "shouldComponentUpdate",
        value: function shouldComponentUpdate(nextProps, nextState) {
            return nextState.isOutOfSyncModalVisible !== this.state.isOutOfSyncModalVisible || nextState.isReportModalVisible !== this.state.isReportModalVisible || nextProps.dynGlobalObject !== this.props.dynGlobalObject || nextProps.backup_recommended !== this.props.backup_recommended || nextProps.rpc_connection_status !== this.props.rpc_connection_status || nextProps.synced !== this.props.synced || nextState.isAccessSettingsPopoverVisible !== this.state.isAccessSettingsPopoverVisible || nextState.showAccessSettingsTooltip !== this.state.showAccessSettingsTooltip;
        }
    }, {
        key: "checkNewVersionAvailable",
        value: function checkNewVersionAvailable() {
            if (__ELECTRON__) {
                fetch("https://github.com/XBTS/xbts-ui/releases/latest").then(function (res) {
                    return res.json();
                }).then(function (json) {
                    var oldVersion = String(json.tag_name);
                    var newVersion = String(APP_VERSION);
                    var isReleaseCandidate = APP_VERSION.indexOf("rc") !== -1;
                    if (!isReleaseCandidate && oldVersion !== newVersion) {
                        this.setState({ newVersion: newVersion });
                    }
                }.bind(this));
            }
        }
    }, {
        key: "downloadVersion",
        value: function downloadVersion() {
            var a = document.createElement("a");
            a.href = this.downloadLink;
            a.target = "_blank";
            a.rel = "noopener noreferrer";
            a.style = "display: none;";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
    }, {
        key: "launchIntroJS",
        value: function launchIntroJS() {
            var translator = require("counterpart");

            var hintData = document.querySelectorAll("[data-intro]");
            var theme = SettingsStore.getState().settings.get("themes");

            if (hintData.length == 0) {
                this.props.history.push("/help");
            } else {
                guide.introJs().setOptions({
                    tooltipClass: theme,
                    highlightClass: theme,
                    showBullets: false,
                    hideNext: true,
                    hidePrev: true,
                    nextLabel: translator.translate("walkthrough.next_label"),
                    prevLabel: translator.translate("walkthrough.prev_label"),
                    skipLabel: translator.translate("walkthrough.skip_label"),
                    doneLabel: translator.translate("walkthrough.done_label")
                }).start();
            }
        }
    }, {
        key: "getNodeIndexByURL",
        value: function getNodeIndexByURL(url) {
            var nodes = this.props.defaults.apiServer;
            var index = nodes.findIndex(function (node) {
                return !!node && !!node.url && node.url === url;
            });
            if (index === -1) {
                return null;
            }
            return index;
        }
    }, {
        key: "getCurrentNodeIndex",
        value: function getCurrentNodeIndex() {
            var props = this.props;

            var currentNode = this.getNodeIndexByURL.call(this, props.currentNode);

            return currentNode;
        }
    }, {
        key: "getNode",
        value: function getNode() {
            var node = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : { url: "", operator: "" };

            if (!node || !node.url) {
                throw "Node is undefined of has no url";
            }

            var props = this.props;


            var testNet = node.url.indexOf("testnet") !== -1;

            if (!!node.location && node.location.translate) node.location = counterpart.translate(node.location.translate);

            var title = node.operator + " " + !!node.location ? node.location : "";
            if ("country" in node) {
                title = node.country + (!!title ? " - " + title : "");
            }

            return {
                name: title,
                url: node.url,
                ping: node.url in props.apiLatencies ? props.apiLatencies[node.url] : -1,
                testNet: testNet
            };
        }

        /**
         * Returns the current blocktime, or exception if not yet available
         * @returns {Date}
         */

    }, {
        key: "getBlockTime",
        value: function getBlockTime() {
            var dynGlobalObject = ChainStore.getObject("2.1.0");
            if (dynGlobalObject) {
                var block_time = dynGlobalObject.get("time");
                if (!/Z$/.test(block_time)) {
                    block_time += "Z";
                }
                return new Date(block_time);
            } else {
                throw new Error("Blocktime not available right now");
            }
        }

        /**
         * Returns the delta between the current time and the block time in seconds, or -1 if block time not available yet
         *
         * Note: Could be integrating properly with BlockchainStore to send out updates, but not necessary atp
         */

    }, {
        key: "getBlockTimeDelta",
        value: function getBlockTimeDelta() {
            try {
                var bt = (this.getBlockTime().getTime() + ChainStore.getEstimatedChainTimeOffset()) / 1000;
                var now = new Date().getTime() / 1000;
                return Math.abs(now - bt);
            } catch (err) {
                console.log(err);
                return -1;
            }
        }

        /**
         * Closes the out of sync modal if closed
         *
         * @private
         */

    }, {
        key: "_closeOutOfSyncModal",
        value: function _closeOutOfSyncModal() {
            this._hideOutOfSyncModal();
        }

        /**
         * This method can be called whenever it is assumed that the connection is stale.
         * It will check synced/connected state and notify the user or do automatic reconnect.
         * In general the connection state can be "out of sync" and "disconnected".
         *
         * disconnected:
         *      - dependent on rpc_connection_status of BlockchainStore
         *
         * out of sync:
         *      - reported block time is more than X sec in the past, as reported in
         *        App -> _syncStatus
         *
         * @private
         */

    }, {
        key: "_ensureConnectivity",
        value: function _ensureConnectivity() {
            var _this2 = this;

            // user is not looking at the app, no reconnection effort necessary
            if (!ifvisible.now("active")) return;

            var connected = !(this.props.rpc_connection_status === "closed");

            if (!connected) {
                console.log("Your connection was lost");
                setTimeout(function () {
                    _this2._triggerReconnect();
                }, 50);
            } else if (!this.props.synced) {
                // If the blockchain is out of sync the footer will be rerendered one last time and then
                // not receive anymore blocks, meaning no rerender. Thus we need to trigger any and all
                // handling out of sync state within this one call

                var forceReconnectAfterSeconds = this._getForceReconnectAfterSeconds();
                var askToReconnectAfterSeconds = 10;

                // Trigger automatic reconnect after X seconds
                setTimeout(function () {
                    if (!_this2.props.synced) {
                        _this2._triggerReconnect();
                    }
                }, forceReconnectAfterSeconds * 1000);

                // Still out of sync?
                if (this.getBlockTimeDelta() > 3) {
                    console.log("Your node is out of sync since " + this.getBlockTimeDelta() + " seconds, waiting " + askToReconnectAfterSeconds + " seconds, then we notify you");
                    setTimeout(function () {
                        // Only ask the user once, and only continue if still out of sync
                        if (_this2.getBlockTimeDelta() > 3 && _this2.state.hasOutOfSyncModalBeenShownOnce === false) {
                            _this2.setState({
                                hasOutOfSyncModalBeenShownOnce: true
                            });
                            _this2._showOutOfSyncModal();
                        }
                    }, askToReconnectAfterSeconds * 1000);
                }
            } else {
                setTimeout(function () {
                    _this2._closeOutOfSyncModal();
                    _this2.setState({
                        hasOutOfSyncModalBeenShownOnce: false
                    });
                }, 50);
            }
        }
    }, {
        key: "_getForceReconnectAfterSeconds",
        value: function _getForceReconnectAfterSeconds() {
            return 60;
        }
    }, {
        key: "_triggerReconnect",
        value: function _triggerReconnect() {
            var _this3 = this;

            var honorManualSelection = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

            if (honorManualSelection && !routerTransitioner.isAutoSelection()) {
                return;
            }
            if (!routerTransitioner.isTransitionInProgress()) {
                this._closeOutOfSyncModal();
                console.log("Trying to reconnect ...");

                // reconnect to anythin
                var promise = routerTransitioner.willTransitionTo(false);
                if (!!promise) setTimeout(function () {
                    _this3.forceUpdate();
                }, 10);
                promise.then(function () {
                    console.log("... done trying to reconnect");
                });
            }
        }
    }, {
        key: "_showAccessSettingsTooltip",
        value: function _showAccessSettingsTooltip(showAccessNodeTooltip) {
            if (!this.state.isAccessSettingsPopoverVisible) {
                this.setState({ showAccessSettingsTooltip: showAccessNodeTooltip });
            } else {
                this.setState({ showAccessSettingsTooltip: false });
            }
        }
    }, {
        key: "_getAccountName",
        value: function _getAccountName() {
            return AccountStore.getState().currentAccount;
        }
    }, {
        key: "render",
        value: function render() {
            var _this4 = this;

            var autoSelectAPI = "wss://fake.automatic-selection.com";
            var state = this.state,
                props = this.props;
            var synced = props.synced;

            var connected = !(this.props.rpc_connection_status === "closed");

            // Current Node Details
            var nodes = this.props.defaults.apiServer;

            var currentNodeIndex = this.getCurrentNodeIndex.call(this);
            var activeNode = this.getNode(nodes[currentNodeIndex] || nodes[0]);
            if (activeNode.url == autoSelectAPI) {
                var nodeUrl = props.activeNode;
                currentNodeIndex = this.getNodeIndexByURL.call(this, nodeUrl);
                if (!!currentNodeIndex) {
                    activeNode = this.getNode(nodes[currentNodeIndex]);
                } else {
                    activeNode = this.getNode(nodes[0]);
                }
            }

            var block_height = this.props.dynGlobalObject.get("head_block_number");
            var version_match = APP_VERSION.match(/2\.0\.(\d\w+)/);
            var version = version_match ? "." + version_match[1] : " " + APP_VERSION;
            var rc_match = APP_VERSION.match(/-rc[0-9]$/);
            // if (rc_match) version += rc_match[0];
            var updateStyles = { display: "inline-block", verticalAlign: "top" };
            var logoProps = {};

            this._ensureConnectivity();

            return React.createElement(
                "div",
                null,
                !!routerTransitioner && routerTransitioner.isTransitionInProgress() && React.createElement(LoadingIndicator, {
                    loadingText: routerTransitioner.getTransitionTarget()
                }),
                React.createElement(
                    ChoiceModal,
                    {
                        showModal: this._showOutOfSyncModal,
                        hideModal: this._hideOutOfSyncModal,
                        visible: this.state.isOutOfSyncModalVisible,
                        choices: [{
                            translationKey: "connection.manual_reconnect",
                            callback: function callback() {
                                if (!_this4.props.synced) {
                                    _this4._triggerReconnect(false);
                                }
                            }
                        }, {
                            translationKey: "connection.manual_ping",
                            callback: function callback() {
                                if (!_this4.props.synced) {
                                    _this4.onAccess();
                                }
                            }
                        }]
                    },
                    React.createElement(
                        "div",
                        null,
                        React.createElement(Translate, {
                            content: "connection.out_of_sync",
                            out_of_sync_seconds: parseInt(this.getBlockTimeDelta())
                        }),
                        React.createElement("br", null),
                        React.createElement("br", null),
                        React.createElement(Translate, { content: "connection.want_to_reconnect" }),
                        routerTransitioner.isAutoSelection() && React.createElement(Translate, {
                            content: "connection.automatic_reconnect",
                            reconnect_in_seconds: parseInt(this._getForceReconnectAfterSeconds())
                        })
                    )
                ),
                React.createElement(
                    "div",
                    { className: "show-for-medium grid-block shrink footer" },
                    React.createElement(
                        "div",
                        { className: "align-justify grid-block" },
                        React.createElement(
                            "div",
                            { className: "grid-block" },
                            React.createElement(
                                "div",
                                _extends({
                                    className: "logo",
                                    style: {
                                        fontSize: state.newVersion ? "0.9em" : "1em",
                                        cursor: state.newVersion ? "pointer" : "normal",
                                        marginTop: state.newVersion ? "-5px" : "0px",
                                        overflow: "hidden"
                                    },
                                    onClick: state.newVersion ? this.downloadVersion.bind(this) : null
                                }, logoProps),
                                state.newVersion && React.createElement(Icon, {
                                    name: "download",
                                    title: counterpart.translate("icons.download", { wallet_name: getWalletName() }),
                                    style: {
                                        marginRight: "20px",
                                        marginTop: "10px",
                                        fontSize: "1.35em",
                                        display: "inline-block"
                                    }
                                }),
                                React.createElement(
                                    "span",
                                    { style: updateStyles },
                                    React.createElement(Translate, {
                                        content: "footer.title",
                                        wallet_name: getWalletName()
                                    }),
                                    __GIT_BRANCH__ === "release" ? React.createElement(
                                        "a",
                                        {
                                            href: "https://github.com/xbts/xbts-ui/commit/" + version.trim(),
                                            className: "version external-link",
                                            target: "_blank",
                                            rel: "noopener noreferrer"
                                        },
                                        version
                                    ) : React.createElement(
                                        "span",
                                        { className: "version" },
                                        version
                                    )
                                ),
                                state.newVersion && React.createElement(Translate, {
                                    content: "footer.update_available",
                                    style: {
                                        color: "#FCAB53",
                                        position: "absolute",
                                        top: "8px",
                                        left: "36px"
                                    }
                                })
                            )
                        ),
                        React.createElement(
                            "div",
                            { className: "footer-right" },
                            React.createElement(
                                "div",
                                { className: "footer-group-one text-nonecase overflow-x-auto small" },
                                React.createElement(
                                    "i",
                                    { className: "green text-nonecase" },
                                    this._getAccountName()
                                ),
                                "\xA0",
                                React.createElement(Translate, { content: "account.your_name", name: this._getAccountName() })
                            ),
                            React.createElement(
                                "div",
                                { className: "footer-group-one" },
                                React.createElement(
                                    "a",
                                    {
                                        href: "https://xbts.io",
                                        className: "footer-group-one-link",
                                        title: "XBTS Web"
                                    },
                                    React.createElement(
                                        "span",
                                        { className: "span-listing" },
                                        "WEB"
                                    )
                                )
                            ),
                            React.createElement(
                                "div",
                                { className: "footer-group-one" },
                                React.createElement(
                                    "a",
                                    {
                                        href: "https://app.xbts.io",
                                        className: "footer-group-one-link",
                                        title: "XBTS DeFi"
                                    },
                                    React.createElement(
                                        "span",
                                        { className: "span-listing" },
                                        "DeFi"
                                    )
                                )
                            ),
                            React.createElement(
                                "div",
                                { className: "footer-group-social" },
                                React.createElement(
                                    "a",
                                    {
                                        href: "https://coinpaprika.com/exchanges/xbts/",
                                        target: "_blank",
                                        className: "footer-group-social-link"
                                    },
                                    React.createElement(
                                        "span",
                                        { className: "icon" },
                                        React.createElement(Icon, {
                                            name: "coinpaprika",
                                            title: "Marketcaps"
                                        })
                                    )
                                ),
                                React.createElement(
                                    "a",
                                    {
                                        href: "https://github.com/XBTS/xbts-ui/releases",
                                        target: "_blank",
                                        className: "footer-group-social-link"
                                    },
                                    React.createElement(
                                        "span",
                                        { className: "icon" },
                                        React.createElement(Icon, {
                                            name: "download",
                                            title: "Download XBTS DEX App"
                                        })
                                    )
                                ),
                                React.createElement(
                                    "a",
                                    {
                                        href: "https://smartholdem.io/#/antibounty",
                                        target: "_blank",
                                        className: "footer-group-social-link"
                                    },
                                    React.createElement(
                                        "span",
                                        { className: "icon kran" },
                                        React.createElement(
                                            "svg",
                                            {
                                                viewBox: "0 0 14 18",
                                                xmlns: "http://www.w3.org/2000/svg"
                                            },
                                            React.createElement(
                                                "title",
                                                null,
                                                "SmartHoldem Faucet"
                                            ),
                                            React.createElement("path", {
                                                d: "M13.5 9.825c.291.101.5.372.5.691a.742.742 0 0 1-.75.734h-4a.742.742 0 0 1-.75-.734c0-.319.209-.59.5-.69v-1.51H7.596a3.254 3.254 0 0 1-2.346.978c-.9 0-1.735-.353-2.346-.979H.75A.742.742 0 0 1 0 7.582V4.647c0-.405.336-.734.75-.734h2.154A3.226 3.226 0 0 1 4.5 3.02V1.467h-.75A.742.742 0 0 1 3 .734C3 .329 3.336 0 3.75 0h3c.414 0 .75.328.75.734a.742.742 0 0 1-.75.733H6V3.02c.608.139 1.16.446 1.596.893h5.154c.414 0 .75.329.75.734v5.178zm-1.707 3.12c.4.41 1.707 1.83 1.707 2.854 0 1.214-1.01 2.201-2.25 2.201S9 17.013 9 15.799c0-1.025 1.307-2.444 1.707-2.854a.758.758 0 0 1 1.086 0z",
                                                fill: "#8C979B",
                                                fillRule: "evenodd"
                                            })
                                        )
                                    )
                                ),
                                React.createElement(
                                    "a",
                                    {
                                        href: "https://t.me/xbtsio",
                                        target: "_blank",
                                        className: "footer-group-social-link"
                                    },
                                    React.createElement(
                                        "span",
                                        { className: "icon telegram" },
                                        React.createElement(
                                            "svg",
                                            {
                                                viewBox: "0 0 18 16",
                                                xmlns: "http://www.w3.org/2000/svg"
                                            },
                                            React.createElement(
                                                "title",
                                                null,
                                                "telegram"
                                            ),
                                            React.createElement("path", {
                                                d: "M.318 7.616l4.147 1.568 1.606 5.232a.486.486 0 0 0 .775.237l2.312-1.91c.243-.2.588-.21.841-.024l4.17 3.068a.487.487 0 0 0 .766-.3L17.989.598a.491.491 0 0 0-.654-.563L.313 6.69a.498.498 0 0 0 .005.927zm5.494.733l8.106-5.06c.146-.09.296.11.17.227l-6.69 6.302a1.412 1.412 0 0 0-.429.841l-.228 1.712c-.03.228-.347.25-.409.03L5.456 9.28a.832.832 0 0 1 .356-.93z",
                                                fill: "#8C979B",
                                                fillRule: "nonzero"
                                            })
                                        )
                                    )
                                )
                            )
                        ),
                        !!routerTransitioner && routerTransitioner.isBackgroundPingingInProgress() && React.createElement(
                            "div",
                            {
                                onClick: function onClick() {
                                    _this4._showNodesPopover();
                                },
                                style: {
                                    cursor: "pointer"
                                },
                                className: "grid-block shrink txtlabel"
                            },
                            routerTransitioner.getBackgroundPingingTarget(),
                            React.createElement(
                                "div",
                                {
                                    style: {
                                        marginTop: "0.4rem",
                                        marginLeft: "0.5rem"
                                    }
                                },
                                React.createElement(LoadingIndicator, { type: "circle" })
                            ),
                            "\xA0 \xA0"
                        ),
                        synced ? null : React.createElement(
                            "div",
                            { className: "grid-block shrink txtlabel cancel" },
                            React.createElement(Translate, { content: "footer.nosync" }),
                            "\xA0 \xA0"
                        ),
                        !connected ? React.createElement(
                            "div",
                            { className: "grid-block shrink txtlabel error" },
                            React.createElement(Translate, { content: "footer.connection" }),
                            "\xA0 \xA0"
                        ) : null,
                        this.props.backup_recommended ? React.createElement(
                            "span",
                            null,
                            React.createElement(
                                "div",
                                { className: "grid-block" },
                                React.createElement(
                                    Tooltip,
                                    {
                                        overlay: React.createElement(
                                            "div",
                                            null,
                                            "Please understand that you are responsible for making your own backup\u2026"
                                        )
                                    },
                                    React.createElement(
                                        "a",
                                        {
                                            className: "shrink txtlabel facolor-alert",
                                            onClick: this.onBackup.bind(this)
                                        },
                                        React.createElement(Translate, { content: "footer.backup" })
                                    )
                                ),
                                "\xA0\xA0"
                            )
                        ) : null,
                        this.props.backup_brainkey_recommended ? React.createElement(
                            "span",
                            null,
                            React.createElement(
                                "div",
                                { className: "grid-block" },
                                React.createElement(
                                    "a",
                                    {
                                        className: "grid-block shrink txtlabel facolor-alert",
                                        onClick: this.onBackupBrainkey.bind(this)
                                    },
                                    React.createElement(Translate, { content: "footer.brainkey" })
                                ),
                                "\xA0\xA0"
                            )
                        ) : null,
                        block_height ? React.createElement(
                            "div",
                            { className: "grid-block shrink" },
                            React.createElement(
                                Tooltip,
                                {
                                    title: counterpart.translate("tooltip.nodes_popup"),
                                    mouseEnterDelay: 0.5,
                                    onVisibleChange: this._showAccessSettingsTooltip,
                                    visible: this.state.showAccessSettingsTooltip
                                },
                                React.createElement(
                                    "div",
                                    {
                                        onClick: function onClick() {
                                            _this4._showNodesPopover();
                                        },
                                        style: {
                                            position: "relative",
                                            cursor: "pointer"
                                        }
                                    },
                                    React.createElement(
                                        "div",
                                        { className: "footer-status" },
                                        connected && activeNode.testNet && React.createElement(
                                            "span",
                                            { className: "testnet" },
                                            React.createElement(Translate, { content: "settings.testnet_nodes" }),
                                            " "
                                        ),
                                        !connected ? React.createElement(
                                            "span",
                                            { className: "warning" },
                                            React.createElement(Translate, { content: "footer.disconnected" })
                                        ) : React.createElement(
                                            "span",
                                            { className: "success" },
                                            activeNode.name
                                        )
                                    ),
                                    React.createElement(
                                        "div",
                                        { className: "footer-block" },
                                        React.createElement(
                                            "span",
                                            null,
                                            React.createElement(
                                                "span",
                                                { className: "footer-block-title" },
                                                React.createElement(Translate, { content: "footer.latency" })
                                            ),
                                            "\xA0",
                                            !connected ? "-" : !activeNode.ping ? "-" : parseInt(activeNode.ping) + "ms",
                                            "\xA0/\xA0",
                                            React.createElement(
                                                "span",
                                                { className: "footer-block-title" },
                                                React.createElement(Translate, { content: "footer.block" })
                                            ),
                                            "\xA0#",
                                            block_height
                                        )
                                    )
                                )
                            ),
                            React.createElement(
                                "div",
                                { className: "grid-block" },
                                React.createElement(
                                    Tooltip,
                                    {
                                        title: counterpart.translate("tooltip.self_help"),
                                        placement: "topRight",
                                        mouseEnterDelay: 0.5
                                    },
                                    React.createElement(
                                        "div",
                                        {
                                            className: "introjs-launcher",
                                            onClick: function onClick() {
                                                _this4.launchIntroJS();
                                            }
                                        },
                                        React.createElement(Translate, { content: "global.help" })
                                    )
                                )
                            )
                        ) : React.createElement(
                            "div",
                            { className: "grid-block shrink" },
                            React.createElement(Translate, { content: "footer.loading" })
                        )
                    )
                ),
                React.createElement(
                    "div",
                    {
                        onMouseLeave: function onMouseLeave() {
                            _this4.setState({ isAccessSettingsPopoverVisible: false });
                        },
                        className: "node-access-popup",
                        style: {
                            display: this.state.isAccessSettingsPopoverVisible ? "" : "none"
                        }
                    },
                    React.createElement(AccessSettings, {
                        nodes: this.props.defaults.apiServer,
                        popup: true
                    }),
                    React.createElement(
                        "div",
                        { style: { paddingTop: 15 } },
                        React.createElement(
                            "a",
                            { onClick: this.onAccess.bind(this) },
                            React.createElement(Translate, { content: "footer.advanced_settings" })
                        )
                    )
                ),
                React.createElement(
                    "div",
                    {
                        className: "introjs-launcher show-for-small-only",
                        onClick: function onClick() {
                            _this4.launchIntroJS();
                        }
                    },
                    React.createElement(Translate, { content: "global.help" })
                ),
                React.createElement(ReportModal, {
                    showModal: this._showReportModal,
                    hideModal: this._hideReportModal,
                    visible: this.state.isReportModalVisible,
                    refCallback: function refCallback(e) {
                        if (e) _this4.reportModal = e;
                    }
                })
            );
        }
    }, {
        key: "_showNodesPopover",
        value: function _showNodesPopover() {
            if (this.state.showAccessSettingsTooltip && !this.state.isAccessSettingsPopoverVisible) {
                this.setState({
                    isAccessSettingsPopoverVisible: !this.state.isAccessSettingsPopoverVisible,
                    showAccessSettingsTooltip: false
                });
            } else {
                this.setState({
                    isAccessSettingsPopoverVisible: !this.state.isAccessSettingsPopoverVisible,
                    showAccessSettingsTooltip: false
                });
            }
        }
    }, {
        key: "onBackup",
        value: function onBackup() {
            this.props.history.push("/wallet/backup/create");
        }
    }, {
        key: "onBackupBrainkey",
        value: function onBackupBrainkey() {
            this.props.history.push("/wallet/backup/brainkey");
        }
    }, {
        key: "onPopup",
        value: function onPopup() {
            this.setState({
                isAccessSettingsPopoverVisible: !this.state.isAccessSettingsPopoverVisible
            });
        }
    }, {
        key: "onAccess",
        value: function onAccess() {
            SettingsActions.changeViewSetting({ activeSetting: 6 });
            this.props.history.push("/settings/access");
        }
    }]);

    return Footer;
}(React.Component);

Footer.propTypes = {
    dynGlobalObject: ChainTypes.ChainObject.isRequired,
    synced: PropTypes.bool.isRequired
};
Footer.defaultProps = {
    dynGlobalObject: "2.1.0"
};

Footer = BindToChainState(Footer);

var AltFooter = function (_Component) {
    _inherits(AltFooter, _Component);

    function AltFooter() {
        _classCallCheck(this, AltFooter);

        return _possibleConstructorReturn(this, (AltFooter.__proto__ || Object.getPrototypeOf(AltFooter)).apply(this, arguments));
    }

    _createClass(AltFooter, [{
        key: "render",
        value: function render() {
            var wallet = WalletDb.getWallet();
            return React.createElement(
                AltContainer,
                {
                    stores: [CachedPropertyStore, BlockchainStore, WalletDb, SettingsStore],
                    inject: {
                        defaults: function defaults() {
                            return SettingsStore.getState().defaults;
                        },
                        apiLatencies: function apiLatencies() {
                            return SettingsStore.getState().apiLatencies;
                        },
                        currentNode: function currentNode() {
                            return SettingsStore.getState().settings.get("apiServer");
                        },
                        activeNode: function activeNode() {
                            return SettingsStore.getState().settings.get("activeNode");
                        },
                        backup_recommended: function backup_recommended() {
                            return wallet && (!wallet.backup_date || CachedPropertyStore.get("backup_recommended"));
                        },
                        rpc_connection_status: function rpc_connection_status() {
                            return BlockchainStore.getState().rpc_connection_status;
                        }
                    }
                },
                React.createElement(Footer, this.props)
            );
        }
    }]);

    return AltFooter;
}(Component);

export default AltFooter;