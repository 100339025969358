var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

import alt from "alt-instance";
import { fetchCoins as _fetchCoins, fetchTradingPairs, fetchCoinsSimple as _fetchCoinsSimple, getBackedCoins, getActiveWallets } from "common/gatewayMethods";
import { blockTradesAPIs } from "api/apiConfig";

var inProgress = {};

var GATEWAY_TIMEOUT = 10000;

var onGatewayTimeout = function onGatewayTimeout(dispatch, gateway) {
    dispatch({ down: gateway });
};

var GatewayActions = function () {
    function GatewayActions() {
        _classCallCheck(this, GatewayActions);
    }

    _createClass(GatewayActions, [{
        key: "fetchCoins",
        value: function fetchCoins() {
            var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
                _ref$backer = _ref.backer,
                backer = _ref$backer === undefined ? "OPEN" : _ref$backer,
                _ref$url = _ref.url,
                url = _ref$url === undefined ? undefined : _ref$url,
                _ref$urlBridge = _ref.urlBridge,
                urlBridge = _ref$urlBridge === undefined ? undefined : _ref$urlBridge,
                _ref$urlWallets = _ref.urlWallets,
                urlWallets = _ref$urlWallets === undefined ? undefined : _ref$urlWallets;

            if (!inProgress["fetchCoins_" + backer]) {
                inProgress["fetchCoins_" + backer] = true;
                return function (dispatch) {
                    var fetchCoinsTimeout = setTimeout(onGatewayTimeout.bind(null, dispatch, backer), GATEWAY_TIMEOUT);
                    Promise.all([_fetchCoins(url), fetchTradingPairs(urlBridge), getActiveWallets(urlWallets)]).then(function (result) {
                        clearTimeout(fetchCoinsTimeout);
                        delete inProgress["fetchCoins_" + backer];

                        var _result = _slicedToArray(result, 3),
                            coins = _result[0],
                            tradingPairs = _result[1],
                            wallets = _result[2];

                        var backedCoins = getBackedCoins({
                            allCoins: coins,
                            tradingPairs: tradingPairs,
                            backer: backer
                        }).filter(function (a) {
                            return !!a.walletType;
                        });
                        backedCoins.forEach(function (a) {
                            a.isAvailable = wallets.indexOf(a.walletType) !== -1;
                        });
                        dispatch({
                            coins: coins,
                            backedCoins: backedCoins,
                            backer: backer
                        });
                    }).catch(function () {
                        clearTimeout(fetchCoinsTimeout);
                        delete inProgress["fetchCoins_" + backer];
                        dispatch({
                            coins: [],
                            backedCoins: [],
                            backer: backer
                        });
                    });
                };
            } else {
                return {};
            }
        }
    }, {
        key: "fetchCoinsSimple",
        value: function fetchCoinsSimple() {
            var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
                _ref2$backer = _ref2.backer,
                backer = _ref2$backer === undefined ? "XBTSX" : _ref2$backer,
                _ref2$url = _ref2.url,
                url = _ref2$url === undefined ? undefined : _ref2$url;

            if (!inProgress["fetchCoinsSimple_" + backer]) {
                inProgress["fetchCoinsSimple_" + backer] = true;
                return function (dispatch) {
                    var fetchCoinsTimeout = setTimeout(onGatewayTimeout.bind(null, dispatch, backer), GATEWAY_TIMEOUT);
                    _fetchCoinsSimple(url).then(function (coins) {
                        clearTimeout(fetchCoinsTimeout);
                        delete inProgress["fetchCoinsSimple_" + backer];
                        dispatch({
                            coins: coins,
                            backer: backer
                        });
                    }).catch(function () {
                        clearTimeout(fetchCoinsTimeout);
                        delete inProgress["fetchCoinsSimple_" + backer];

                        dispatch({
                            coins: [],
                            backer: backer
                        });
                    });
                };
            } else {
                return {};
            }
        }
    }, {
        key: "fetchPairs",
        value: function fetchPairs() {
            if (!inProgress["fetchTradingPairs"]) {
                inProgress["fetchTradingPairs"] = true;
                return function (dispatch) {
                    var fetchCoinsTimeout = setTimeout(onGatewayTimeout.bind(null, dispatch, "TRADE"), GATEWAY_TIMEOUT);
                    Promise.all([_fetchCoins(blockTradesAPIs.BASE + blockTradesAPIs.COINS_LIST), fetchTradingPairs(blockTradesAPIs.BASE + blockTradesAPIs.TRADING_PAIRS), getActiveWallets(blockTradesAPIs.BASE + blockTradesAPIs.ACTIVE_WALLETS)]).then(function (result) {
                        clearTimeout(fetchCoinsTimeout);
                        delete inProgress["fetchTradingPairs"];

                        var _result2 = _slicedToArray(result, 3),
                            coins = _result2[0],
                            bridgeCoins = _result2[1],
                            wallets = _result2[2];

                        dispatch({
                            coins: coins,
                            bridgeCoins: bridgeCoins,
                            wallets: wallets
                        });
                    }).catch(function () {
                        delete inProgress["fetchTradingPairs"];
                        dispatch({
                            coins: [],
                            bridgeCoins: [],
                            wallets: []
                        });
                    });
                };
            } else {
                return {};
            }
        }
    }]);

    return GatewayActions;
}();

export default alt.createActions(GatewayActions);