import React from "react";
import Translate from "react-translate-component";
import FormattedPrice from "../../Utility/FormattedPrice";
import TranslateWithLinks from "../../Utility/TranslateWithLinks";

export var AssetPublishFeed = function AssetPublishFeed(_ref) {
    var op = _ref.op,
        changeColor = _ref.changeColor,
        linkToAccount = _ref.linkToAccount,
        fromComponent = _ref.fromComponent;

    changeColor("warning");
    if (fromComponent === "proposed_operation") {
        return React.createElement(
            "span",
            null,
            linkToAccount(op[1].publisher),
            "\xA0",
            React.createElement(Translate, { component: "span", content: "proposal.publish_feed" }),
            "\xA0",
            React.createElement(FormattedPrice, {
                base_asset: op[1].feed.settlement_price.base.asset_id,
                quote_asset: op[1].feed.settlement_price.quote.asset_id,
                base_amount: op[1].feed.settlement_price.base.amount,
                quote_amount: op[1].feed.settlement_price.quote.amount
            })
        );
    } else {
        return React.createElement(
            "span",
            null,
            React.createElement(TranslateWithLinks, {
                string: "operation.publish_feed",
                keys: [{
                    type: "account",
                    value: op[1].publisher,
                    arg: "account"
                }, {
                    type: "price",
                    value: op[1].feed.settlement_price,
                    arg: "price"
                }]
            })
        );
    }
};