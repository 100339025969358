import React from "react";
import { Link } from "react-router-dom";

export var DefaultOperation = function DefaultOperation(_ref) {
    var op = _ref.op,
        block = _ref.block;

    console.log("unimplemented op:", op);
    return React.createElement(
        "span",
        null,
        React.createElement(
            Link,
            { to: "/block/" + block },
            "#",
            block
        )
    );
};