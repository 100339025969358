/* eslint-disable react/display-name */
import React from "react";
import TranslateWithLinks from "../../Utility/TranslateWithLinks";
import BindToChainState from "../../Utility/BindToChainState";
import marketUtils from "common/market_utils";
export var LimitOrderCreate = function LimitOrderCreate(_ref) {
    var op = _ref.op,
        changeColor = _ref.changeColor,
        fromComponent = _ref.fromComponent,
        marketDirections = _ref.marketDirections,
        result = _ref.result;

    changeColor("warning");

    if (fromComponent === "proposed_operation") {
        var isAsk = marketUtils.isAskOp(op[1]);

        return React.createElement(
            "span",
            null,
            React.createElement(TranslateWithLinks, {
                string: isAsk ? "proposal.limit_order_sell" : "proposal.limit_order_buy",
                keys: [{
                    type: "account",
                    value: op[1].seller,
                    arg: "account"
                }, {
                    type: "amount",
                    value: isAsk ? op[1].amount_to_sell : op[1].min_to_receive,
                    arg: "amount"
                }, {
                    type: "price",
                    value: {
                        base: isAsk ? op[1].min_to_receive : op[1].amount_to_sell,
                        quote: isAsk ? op[1].amount_to_sell : op[1].min_to_receive
                    },
                    arg: "price"
                }]
            })
        );
    } else {
        var o = op[1];
        return React.createElement(
            "span",
            null,
            React.createElement(
                BindToChainState.Wrapper,
                {
                    base: o.min_to_receive.asset_id,
                    quote: o.amount_to_sell.asset_id
                },
                function (_ref2) {
                    var base = _ref2.base,
                        quote = _ref2.quote;

                    var _marketUtils$getMarke = marketUtils.getMarketName(base, quote),
                        marketName = _marketUtils$getMarke.marketName,
                        first = _marketUtils$getMarke.first,
                        second = _marketUtils$getMarke.second;

                    var inverted = marketDirections.get(marketName);

                    var isBid = o.amount_to_sell.asset_id === (inverted ? first.get("id") : second.get("id"));

                    var priceBase = isBid ? o.amount_to_sell : o.min_to_receive;
                    var priceQuote = isBid ? o.min_to_receive : o.amount_to_sell;
                    var amount = isBid ? op[1].min_to_receive : op[1].amount_to_sell;
                    var orderId = result ? typeof result[1] == "string" ? "#" + result[1].substring(4) : "" : "";

                    return React.createElement(TranslateWithLinks, {
                        string: isBid ? "operation.limit_order_buy" : "operation.limit_order_sell",
                        keys: [{
                            type: "account",
                            value: op[1].seller,
                            arg: "account"
                        }, {
                            type: "amount",
                            value: amount,
                            arg: "amount"
                        }, {
                            type: "price",
                            value: {
                                base: priceBase,
                                quote: priceQuote
                            },
                            arg: "price"
                        }],
                        params: {
                            order: orderId
                        }
                    });
                }
            )
        );
    }
};