/**
 * Settings storage for all Gateway Services
 * General API Settings are stored in api/apiConfig and should be imported here
 */

import {
//rudexAPIs,
xbtsxAPIs, gdex2APIs, wavesAPIs, bnbAPIs, ethAPIs, hecoAPIs, bscAPIs, eosAPIs } from "api/apiConfig";
import { allowedGateway } from "branding";

export var availableGateways = {
    XBTSX: {
        id: "XBTSX",
        name: "XBTSX",
        baseAPI: xbtsxAPIs,
        isEnabled: allowedGateway("XBTSX"),
        isSimple: true,
        selected: false,
        simpleAssetGateway: false,
        addressValidatorMethod: "POST",
        options: {
            enabled: false,
            selected: false
        }
    },
    WAVES: {
        id: "WAVES",
        name: "WAVES",
        baseAPI: wavesAPIs,
        isEnabled: allowedGateway("WAVES"),
        isSimple: true,
        selected: false,
        simpleAssetGateway: false,
        addressValidatorMethod: "POST",
        options: {
            enabled: false,
            selected: false
        }
    },
    BNB: {
        id: "BNB",
        name: "BNB",
        baseAPI: bnbAPIs,
        isEnabled: allowedGateway("BNB"),
        isSimple: true,
        selected: false,
        simpleAssetGateway: false,
        addressValidatorMethod: "POST",
        options: {
            enabled: false,
            selected: false
        }
    },
    ETH: {
        id: "ETH",
        name: "ETH",
        baseAPI: ethAPIs,
        isEnabled: allowedGateway("ETH"),
        isSimple: true,
        selected: false,
        simpleAssetGateway: false,
        addressValidatorMethod: "POST",
        options: {
            enabled: false,
            selected: false
        }
    },
    HECO: {
        id: "HECO",
        name: "HECO",
        baseAPI: hecoAPIs,
        isEnabled: allowedGateway("HECO"),
        isSimple: true,
        selected: false,
        simpleAssetGateway: false,
        addressValidatorMethod: "POST",
        options: {
            enabled: false,
            selected: false
        }
    },

    BSC: {
        id: "BSC",
        name: "BSC",
        baseAPI: bscAPIs,
        isEnabled: allowedGateway("BSC"),
        isSimple: true,
        selected: false,
        simpleAssetGateway: false,
        addressValidatorMethod: "POST",
        options: {
            enabled: false,
            selected: false
        }
    },
    EOS: {
        id: "EOS",
        name: "EOS",
        baseAPI: eosAPIs,
        isEnabled: allowedGateway("EOS"),
        isSimple: true,
        selected: false,
        simpleAssetGateway: false,
        addressValidatorMethod: "POST",
        options: {
            enabled: false,
            selected: false
        }
    },

    GDEX: {
        id: "GDEX",
        name: "GDEX",
        baseAPI: gdex2APIs,
        isEnabled: allowedGateway("GDEX"),
        options: {
            enabled: false,
            selected: false
        }
    }
};

export var gatewayPrefixes = Object.keys(availableGateways);

export function getPossibleGatewayPrefixes(bases) {
    return gatewayPrefixes.reduce(function (assets, prefix) {
        bases.forEach(function (a) {
            assets.push(prefix + "." + a);
        });
        return assets;
    }, []);
}

export default availableGateways;