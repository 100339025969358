import React from "react";
import ReactDOM from "react-dom";
import AppInit from "./AppInit";
if (__PERFORMANCE_DEVTOOL__) {
    var _require = require("react-perf-devtool"),
        registerObserver = _require.registerObserver;

    registerObserver();
}

var rootEl = document.getElementById("content");
var render = function render() {
    ReactDOM.render(React.createElement(AppInit, null), rootEl);
};
render();