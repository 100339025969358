var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React from "react";
import PropTypes from "prop-types";
import FormattedAsset from "../Utility/FormattedAsset";
import { Link as RealLink } from "react-router-dom";
import Translate from "react-translate-component";
import counterpart from "counterpart";
import classNames from "classnames";
import { FormattedDate } from "react-intl";
import Inspector from "react-json-inspector";
import utils from "common/utils";
import LinkToAccountById from "../Utility/LinkToAccountById";
import LinkToAssetById from "../Utility/LinkToAssetById";
import FormattedPrice from "../Utility/FormattedPrice";
import account_constants from "chain/account_constants";
import Icon from "../Icon/Icon";
import PrivateKeyStore from "stores/PrivateKeyStore";
import WalletUnlockActions from "actions/WalletUnlockActions";
import ProposedOperation from "./ProposedOperation";
import { ChainTypes } from "bitsharesjs";
var operations = ChainTypes.operations;

import ReactTooltip from "react-tooltip";
import moment from "moment";
import { Link, DirectLink } from "react-scroll";
import { Tooltip } from "bitshares-ui-style-guide";
import asset_utils from "../../lib/common/asset_utils";
import sanitize from "sanitize";
import { AccountStakingInfo } from "../Account/AccountStakeCreateNew";

require("./operations.scss");
require("./json-inspector.scss");

var ops = Object.keys(operations);
var listings = Object.keys(account_constants.account_listing);

var TranslateBoolean = function TranslateBoolean(_ref) {
    var value = _ref.value,
        otherProps = _objectWithoutProperties(_ref, ["value"]);

    return React.createElement(Translate, _extends({
        content: "boolean." + (value ? "true" : "false")
    }, otherProps));
};

var OpType = function (_React$Component) {
    _inherits(OpType, _React$Component);

    function OpType() {
        _classCallCheck(this, OpType);

        return _possibleConstructorReturn(this, (OpType.__proto__ || Object.getPrototypeOf(OpType)).apply(this, arguments));
    }

    _createClass(OpType, [{
        key: "shouldComponentUpdate",
        value: function shouldComponentUpdate(nextProps) {
            return nextProps.type !== this.props.type;
        }
    }, {
        key: "render",
        value: function render() {
            var trxTypes = counterpart.translate("transaction.trxTypes");
            var labelClass = classNames("txtlabel", this.props.color || "info");

            return React.createElement(
                "tr",
                null,
                React.createElement(
                    "td",
                    null,
                    React.createElement(
                        "span",
                        { className: labelClass },
                        this.props.txIndex >= 0 ? React.createElement(
                            "span",
                            null,
                            "#",
                            this.props.txIndex + 1,
                            ":\xA0"
                        ) : "",
                        trxTypes[ops[this.props.type]]
                    )
                ),
                React.createElement("td", null)
            );
        }
    }]);

    return OpType;
}(React.Component);

var NoLinkDecorator = function (_React$Component2) {
    _inherits(NoLinkDecorator, _React$Component2);

    function NoLinkDecorator() {
        _classCallCheck(this, NoLinkDecorator);

        return _possibleConstructorReturn(this, (NoLinkDecorator.__proto__ || Object.getPrototypeOf(NoLinkDecorator)).apply(this, arguments));
    }

    _createClass(NoLinkDecorator, [{
        key: "render",
        value: function render() {
            return React.createElement(
                "span",
                null,
                this.props.children
            );
        }
    }]);

    return NoLinkDecorator;
}(React.Component);

var OperationTable = function (_React$Component3) {
    _inherits(OperationTable, _React$Component3);

    function OperationTable() {
        _classCallCheck(this, OperationTable);

        return _possibleConstructorReturn(this, (OperationTable.__proto__ || Object.getPrototypeOf(OperationTable)).apply(this, arguments));
    }

    _createClass(OperationTable, [{
        key: "render",
        value: function render() {
            var fee_row = React.createElement(
                "tr",
                null,
                React.createElement(
                    "td",
                    null,
                    React.createElement(Translate, { component: "span", content: "transfer.fee" })
                ),
                React.createElement(
                    "td",
                    null,
                    this.props.fee.amount > 0 ? React.createElement(FormattedAsset, {
                        color: "fee",
                        amount: this.props.fee.amount,
                        asset: this.props.fee.asset_id
                    }) : React.createElement(
                        "label",
                        null,
                        React.createElement(Translate, { content: "transfer.free" })
                    )
                )
            );

            return React.createElement(
                "div",
                null,
                React.createElement(
                    "table",
                    { style: { marginBottom: "1em" }, className: "table op-table" },
                    React.createElement("caption", null),
                    React.createElement(
                        "tbody",
                        null,
                        React.createElement(OpType, {
                            txIndex: this.props.txIndex,
                            type: this.props.type,
                            color: this.props.color
                        }),
                        this.props.children,
                        fee_row
                    )
                )
            );
        }
    }]);

    return OperationTable;
}(React.Component);

var Transaction = function (_React$Component4) {
    _inherits(Transaction, _React$Component4);

    function Transaction() {
        _classCallCheck(this, Transaction);

        return _possibleConstructorReturn(this, (Transaction.__proto__ || Object.getPrototypeOf(Transaction)).apply(this, arguments));
    }

    _createClass(Transaction, [{
        key: "componentDidMount",
        value: function componentDidMount() {
            ReactTooltip.rebuild();
        }
    }, {
        key: "linkToAccount",
        value: function linkToAccount(name_or_id) {
            if (!name_or_id) return React.createElement(
                "span",
                null,
                "-"
            );
            var Link = this.props.no_links ? NoLinkDecorator : RealLink;
            return utils.is_object_id(name_or_id) ? React.createElement(LinkToAccountById, { account: name_or_id }) : React.createElement(
                Link,
                { to: "/account/" + name_or_id + "/overview" },
                name_or_id
            );
        }
    }, {
        key: "linkToAsset",
        value: function linkToAsset(symbol_or_id) {
            if (!symbol_or_id) return React.createElement(
                "span",
                null,
                "-"
            );
            var Link = this.props.no_links ? NoLinkDecorator : RealLink;
            return utils.is_object_id(symbol_or_id) ? React.createElement(LinkToAssetById, { asset: symbol_or_id }) : React.createElement(
                Link,
                { to: "/asset/" + symbol_or_id },
                symbol_or_id
            );
        }
    }, {
        key: "_toggleLock",
        value: function _toggleLock(e) {
            var _this5 = this;

            e.preventDefault();
            WalletUnlockActions.unlock().then(function () {
                _this5.forceUpdate();
            }).catch(function () {});
        }
    }, {
        key: "render",
        value: function render() {
            var _this6 = this;

            var trx = this.props.trx;

            var info = null;
            info = [];

            var opCount = trx.operations.length;
            var memo = null;

            trx.operations.forEach(function (op, opIndex) {
                var rows = [];
                var key = 0;

                var color = "";
                switch (ops[op[0]] // For a list of trx types, see chain_types.coffee
                ) {case "transfer":
                        color = "success";

                        if (op[1].memo) {
                            var _PrivateKeyStore$deco = PrivateKeyStore.decodeMemo(op[1].memo),
                                text = _PrivateKeyStore$deco.text,
                                isMine = _PrivateKeyStore$deco.isMine;

                            memo = text ? React.createElement(
                                "td",
                                {
                                    className: "memo",
                                    style: { wordBreak: "break-all" }
                                },
                                text
                            ) : !text && isMine ? React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, { content: "transfer.memo_unlock" }),
                                "\xA0",
                                React.createElement(
                                    "a",
                                    { onClick: _this6._toggleLock.bind(_this6) },
                                    React.createElement(Icon, {
                                        name: "locked",
                                        title: "icons.locked.action"
                                    })
                                )
                            ) : null;
                        }

                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "transfer.from"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                _this6.linkToAccount(op[1].from)
                            )
                        ));
                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "transfer.to"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                _this6.linkToAccount(op[1].to)
                            )
                        ));
                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "transfer.amount"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                React.createElement(FormattedAsset, {
                                    amount: op[1].amount.amount,
                                    asset: op[1].amount.asset_id
                                })
                            )
                        ));

                        {
                            memo ? rows.push(React.createElement(
                                "tr",
                                { key: key++ },
                                React.createElement(
                                    "td",
                                    null,
                                    React.createElement(Translate, { content: "transfer.memo" })
                                ),
                                memo
                            )) : null;
                        }

                        break;

                    case "limit_order_create":
                        color = "warning";
                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "exchange.price"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                React.createElement(FormattedPrice, {
                                    base_asset: op[1].amount_to_sell.asset_id,
                                    quote_asset: op[1].min_to_receive.asset_id,
                                    base_amount: op[1].amount_to_sell.amount,
                                    quote_amount: op[1].min_to_receive.amount,
                                    noPopOver: true
                                })
                            )
                        ));

                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "exchange.sell"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                React.createElement(FormattedAsset, {
                                    amount: op[1].amount_to_sell.amount,
                                    asset: op[1].amount_to_sell.asset_id
                                })
                            )
                        ));

                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(
                                    Tooltip,
                                    {
                                        placement: "left",
                                        title: counterpart.translate("tooltip.buy_min")
                                    },
                                    React.createElement(Translate, {
                                        component: "span",
                                        content: "exchange.buy_min"
                                    })
                                )
                            ),
                            React.createElement(
                                "td",
                                null,
                                React.createElement(FormattedAsset, {
                                    amount: op[1].min_to_receive.amount,
                                    asset: op[1].min_to_receive.asset_id
                                })
                            )
                        ));

                        // rows.push(
                        //     <tr key="2">
                        //         <td><Translate component="span" content="transaction.min_receive" /></td>
                        //         <td>{!missingAssets[1] ? <FormattedAsset amount={op[1].min_to_receive.amount} asset={op[1].min_to_receive.asset_id} /> : null}</td>
                        //     </tr>
                        // );
                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "transaction.seller"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                _this6.linkToAccount(op[1].seller)
                            )
                        ));
                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "transaction.expiration"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                React.createElement(FormattedDate, {
                                    value: moment.utc(op[1].expiration),
                                    format: "full",
                                    timeZoneName: "short"
                                })
                            )
                        ));

                        break;

                    case "limit_order_cancel":
                        color = "cancel";
                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "transaction.order_id"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                op[1].order
                            )
                        ));
                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "explorer.block.fee_payer"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                _this6.linkToAccount(op[1].fee_paying_account)
                            )
                        ));

                        break;

                    case "short_order_cancel":
                        color = "cancel";
                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "transaction.order_id"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                op[1].order
                            )
                        ));
                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "explorer.block.fee_payer"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                _this6.linkToAccount(op[1].fee_paying_account)
                            )
                        ));

                        break;

                    case "call_order_update":
                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "transaction.funding_account"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                _this6.linkToAccount(op[1].funding_account)
                            )
                        ));
                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "transaction.delta_collateral"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                React.createElement(FormattedAsset, {
                                    amount: op[1].delta_collateral.amount,
                                    asset: op[1].delta_collateral.asset_id
                                })
                            )
                        ));
                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "transaction.delta_debt"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                React.createElement(FormattedAsset, {
                                    amount: op[1].delta_debt.amount,
                                    asset: op[1].delta_debt.asset_id
                                })
                            )
                        ));
                        if (!!op[1].extensions && !!op[1].extensions.target_collateral_ratio) {
                            rows.push(React.createElement(
                                "tr",
                                { key: key++ },
                                React.createElement(
                                    "td",
                                    null,
                                    React.createElement(Translate, {
                                        component: "span",
                                        content: "transaction.collateral_target"
                                    })
                                ),
                                React.createElement(
                                    "td",
                                    null,
                                    op[1].extensions.target_collateral_ratio / 1000
                                )
                            ));
                        }

                        break;

                    case "key_create":
                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "explorer.block.fee_payer"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                _this6.linkToAccount(op[1].fee_paying_account)
                            )
                        ));
                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "explorer.block.key"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                op[1].key_data[1]
                            )
                        ));

                        break;

                    case "account_create":
                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "account.name"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                _this6.linkToAccount(op[1].name)
                            )
                        ));
                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "account.member.registrar"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                _this6.linkToAccount(op[1].registrar)
                            )
                        ));
                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "account.member.lifetime_referrer"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                _this6.linkToAccount(op[1].referrer)
                            )
                        ));

                        break;

                    case "account_update":
                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "account.name"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                _this6.linkToAccount(op[1].account)
                            )
                        ));
                        // let voting_account = ChainStore.getAccount(op[1].new_options.voting_account)
                        // let updating_account = ChainStore.getAccount(op[1].account)
                        if (op[1].new_options) {
                            if (op[1].new_options.voting_account) {
                                // let proxy_account_name = voting_account.get('name')
                                rows.push(React.createElement(
                                    "tr",
                                    { key: key++ },
                                    React.createElement(
                                        "td",
                                        null,
                                        React.createElement(Translate, {
                                            component: "span",
                                            content: "account.votes.proxy"
                                        })
                                    ),
                                    React.createElement(
                                        "td",
                                        null,
                                        _this6.linkToAccount(op[1].new_options.voting_account)
                                    )
                                ));
                            } else {
                                console.log("num witnesses: ", op[1].new_options.num_witness);
                                console.log("===============> NEW: ", op[1].new_options);
                                rows.push(React.createElement(
                                    "tr",
                                    { key: key++ },
                                    React.createElement(
                                        "td",
                                        null,
                                        React.createElement(Translate, {
                                            component: "span",
                                            content: "account.votes.proxy"
                                        })
                                    ),
                                    React.createElement(
                                        "td",
                                        null,
                                        React.createElement(Translate, {
                                            component: "span",
                                            content: "account.votes.no_proxy"
                                        })
                                    )
                                ));
                                rows.push(React.createElement(
                                    "tr",
                                    { key: key++ },
                                    React.createElement(
                                        "td",
                                        null,
                                        React.createElement(Translate, {
                                            component: "span",
                                            content: "account.options.num_committee"
                                        })
                                    ),
                                    React.createElement(
                                        "td",
                                        null,
                                        op[1].new_options.num_committee
                                    )
                                ));
                                rows.push(React.createElement(
                                    "tr",
                                    { key: key++ },
                                    React.createElement(
                                        "td",
                                        null,
                                        React.createElement(Translate, {
                                            component: "span",
                                            content: "account.options.num_witnesses"
                                        })
                                    ),
                                    React.createElement(
                                        "td",
                                        null,
                                        op[1].new_options.num_witness
                                    )
                                ));
                                rows.push(React.createElement(
                                    "tr",
                                    { key: key++ },
                                    React.createElement(
                                        "td",
                                        null,
                                        React.createElement(Translate, {
                                            component: "span",
                                            content: "account.options.votes"
                                        })
                                    ),
                                    React.createElement(
                                        "td",
                                        null,
                                        JSON.stringify(op[1].new_options.votes)
                                    )
                                ));
                            }

                            rows.push(React.createElement(
                                "tr",
                                { key: key++ },
                                React.createElement(
                                    "td",
                                    null,
                                    React.createElement(Translate, {
                                        component: "span",
                                        content: "account.options.memo_key"
                                    })
                                ),
                                React.createElement(
                                    "td",
                                    null,
                                    op[1].new_options.memo_key.substring(0, 10) + "..."
                                )
                            ));
                        }

                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "explorer.block.common_options"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Inspector, { data: op[1], search: false })
                            )
                        ));

                        break;

                    case "account_whitelist":
                        var listing = void 0;
                        for (var i = 0; i < listings.length; i++) {
                            if (account_constants.account_listing[listings[i]] === op[1].new_listing) {
                                console.log("listings:", listings[i]);
                                listing = listings[i];
                            }
                        }

                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "explorer.block.authorizing_account"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                _this6.linkToAccount(op[1].authorizing_account)
                            )
                        ));
                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "explorer.block.listed_account"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                _this6.linkToAccount(op[1].account_to_list)
                            )
                        ));
                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "explorer.block.new_listing"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    content: "transaction.whitelist_states." + listing
                                })
                            )
                        ));

                        break;

                    case "account_upgrade":
                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "explorer.block.account_upgrade"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                _this6.linkToAccount(op[1].account_to_upgrade)
                            )
                        ));
                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "explorer.block.lifetime"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                op[1].upgrade_to_lifetime_member.toString()
                            )
                        ));
                        break;

                    case "account_transfer":
                        /* This case is uncomplete, needs filling out with proper fields */
                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "transfer.from"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                _this6.linkToAccount(op[1].account_id)
                            )
                        ));

                        break;

                    case "asset_create":
                        color = "warning";

                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "explorer.assets.issuer"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                _this6.linkToAccount(op[1].issuer)
                            )
                        ));
                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "explorer.assets.symbol"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                _this6.linkToAsset(op[1].symbol)
                            )
                        ));
                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "explorer.assets.precision"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                op[1].precision
                            )
                        ));
                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "account.user_issued_assets.max_supply"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                utils.format_asset(op[1].common_options.max_supply, op[1])
                            )
                        ));
                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "account.user_issued_assets.description"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                op[1].common_options.description
                            )
                        ));
                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "transaction.market_fee"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                op[1].common_options.market_fee_percent / 100,
                                "%"
                            )
                        ));
                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "transaction.max_market_fee"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                utils.format_asset(op[1].common_options.max_market_fee, op[1])
                            )
                        ));
                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "explorer.block.common_options"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Inspector, { data: op[1], search: false })
                            )
                        ));

                        break;

                    case "asset_update":
                    case "asset_update_bitasset":
                        console.log("op:", op);
                        color = "warning";

                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "explorer.block.asset_update"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                _this6.linkToAsset(op[1].asset_to_update)
                            )
                        ));
                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "explorer.assets.issuer"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                _this6.linkToAccount(op[1].issuer)
                            )
                        ));
                        if (op[1].new_issuer !== op[1].issuer) {
                            rows.push(React.createElement(
                                "tr",
                                { key: key++ },
                                React.createElement(
                                    "td",
                                    null,
                                    React.createElement(Translate, {
                                        component: "span",
                                        content: "account.user_issued_assets.new_issuer"
                                    })
                                ),
                                React.createElement(
                                    "td",
                                    null,
                                    _this6.linkToAccount(op[1].new_issuer)
                                )
                            ));
                        }
                        if (op[1].new_options.core_exchange_rate) {
                            rows.push(React.createElement(
                                "tr",
                                { key: key++ },
                                React.createElement(
                                    "td",
                                    null,
                                    React.createElement(Translate, {
                                        component: "span",
                                        content: "markets.core_rate"
                                    })
                                ),
                                React.createElement(
                                    "td",
                                    null,
                                    React.createElement(FormattedPrice, {
                                        base_asset: op[1].new_options.core_exchange_rate.base.asset_id,
                                        quote_asset: op[1].new_options.core_exchange_rate.quote.asset_id,
                                        base_amount: op[1].new_options.core_exchange_rate.base.amount,
                                        quote_amount: op[1].new_options.core_exchange_rate.quote.amount,
                                        noPopOver: true
                                    })
                                )
                            ));
                        }

                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "explorer.block.new_options"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Inspector, {
                                    data: op[1].new_options,
                                    search: false
                                })
                            )
                        ));

                        break;

                    case "asset_update_feed_producers":
                        color = "warning";
                        console.log("op:", op);
                        var producers = [];
                        op[1].new_feed_producers.forEach(function (producer) {
                            // let missingAsset = this.getAccounts([producer])[0];
                            producers.push(React.createElement(
                                "div",
                                null,
                                _this6.linkToAccount(producer),
                                React.createElement("br", null)
                            ));
                        });

                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "explorer.block.asset_update"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                _this6.linkToAsset(op[1].asset_to_update)
                            )
                        ));
                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "explorer.block.new_producers"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                producers
                            )
                        ));

                        break;

                    case "asset_issue":
                        color = "warning";

                        if (op[1].memo) {
                            var _PrivateKeyStore$deco2 = PrivateKeyStore.decodeMemo(op[1].memo),
                                _text = _PrivateKeyStore$deco2.text,
                                _isMine = _PrivateKeyStore$deco2.isMine;

                            memo = _text ? React.createElement(
                                "td",
                                null,
                                _text
                            ) : !_text && _isMine ? React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, { content: "transfer.memo_unlock" }),
                                "\xA0",
                                React.createElement(
                                    "a",
                                    { onClick: _this6._toggleLock.bind(_this6) },
                                    React.createElement(Icon, {
                                        name: "locked",
                                        title: "icons.locked.action"
                                    })
                                )
                            ) : null;
                        }

                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "explorer.assets.issuer"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                _this6.linkToAccount(op[1].issuer)
                            )
                        ));

                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "explorer.block.asset_issue"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                React.createElement(FormattedAsset, {
                                    style: { fontWeight: "bold" },
                                    amount: op[1].asset_to_issue.amount,
                                    asset: op[1].asset_to_issue.asset_id
                                })
                            )
                        ));

                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "transfer.to"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                _this6.linkToAccount(op[1].issue_to_account)
                            )
                        ));

                        {
                            memo ? rows.push(React.createElement(
                                "tr",
                                { key: key++ },
                                React.createElement(
                                    "td",
                                    null,
                                    React.createElement(Translate, { content: "transfer.memo" })
                                ),
                                memo
                            )) : null;
                        }

                        break;

                    case "asset_burn":
                        color = "cancel";

                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "explorer.account.title"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                _this6.linkToAccount(op[1].payer)
                            )
                        ));

                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "transfer.amount"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                React.createElement(FormattedAsset, {
                                    amount: op[1].amount_to_burn.amount,
                                    asset: op[1].amount_to_burn.asset_id
                                })
                            )
                        ));

                        break;

                    case "asset_fund_fee_pool":
                        color = "warning";
                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "explorer.account.title"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                _this6.linkToAccount(op[1].from_account)
                            )
                        ));

                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "explorer.asset.title"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                _this6.linkToAsset(op[1].asset_id)
                            )
                        ));

                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "transfer.amount"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                React.createElement(FormattedAsset, {
                                    amount: op[1].amount,
                                    asset: "1.3.0"
                                })
                            )
                        ));

                        break;

                    case "asset_settle":
                        color = "warning";

                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "explorer.account.title"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                _this6.linkToAccount(op[1].account)
                            )
                        ));

                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "explorer.asset.title"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                _this6.linkToAsset(op[1].amount.asset_id)
                            )
                        ));

                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "transfer.amount"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                React.createElement(FormattedAsset, {
                                    amount: op[1].amount.amount,
                                    asset: op[1].amount.asset_id
                                })
                            )
                        ));

                        break;

                    case "asset_publish_feed":
                        color = "warning";
                        var feed = op[1].feed;


                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "transaction.publisher"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                _this6.linkToAccount(op[1].publisher)
                            )
                        ));

                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "explorer.asset.title"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                _this6.linkToAsset(op[1].asset_id)
                            )
                        ));

                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "explorer.asset.price_feed.maximum_short_squeeze_ratio"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                (feed.maximum_short_squeeze_ratio / 1000).toFixed(2)
                            )
                        ));

                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "explorer.asset.price_feed.maintenance_collateral_ratio"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                (feed.maintenance_collateral_ratio / 1000).toFixed(2)
                            )
                        ));

                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "markets.core_rate"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                React.createElement(FormattedPrice, {
                                    base_asset: feed.core_exchange_rate.base.asset_id,
                                    quote_asset: feed.core_exchange_rate.quote.asset_id,
                                    base_amount: feed.core_exchange_rate.base.amount,
                                    quote_amount: feed.core_exchange_rate.quote.amount,
                                    noPopOver: true
                                })
                            )
                        ));

                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "explorer.block.feed_price"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                React.createElement(FormattedPrice, {
                                    base_asset: asset_utils.extractRawFeedPrice(feed).base.asset_id,
                                    quote_asset: asset_utils.extractRawFeedPrice(feed).quote.asset_id,
                                    base_amount: asset_utils.extractRawFeedPrice(feed).base.amount,
                                    quote_amount: asset_utils.extractRawFeedPrice(feed).quote.amount,
                                    noPopOver: true
                                })
                            )
                        ));

                        break;

                    case "committee_member_create":
                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "explorer.committee_member.title"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                _this6.linkToAccount(op[1].committee_member_account)
                            )
                        ));

                        break;

                    case "witness_create":
                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "explorer.block.witness"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                _this6.linkToAccount(op[1].witness_account)
                            )
                        ));

                        break;

                    case "witness_update":
                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "explorer.block.witness"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                _this6.linkToAccount(op[1].witness_account)
                            )
                        ));

                        if (op[1].new_url) {
                            rows.push(React.createElement(
                                "tr",
                                { key: key++ },
                                React.createElement(
                                    "td",
                                    null,
                                    React.createElement(Translate, {
                                        component: "span",
                                        content: "transaction.new_url"
                                    })
                                ),
                                React.createElement(
                                    "td",
                                    null,
                                    React.createElement(
                                        "a",
                                        {
                                            href: op[1].new_url,
                                            target: "_blank",
                                            rel: "noopener noreferrer"
                                        },
                                        op[1].new_url
                                    )
                                )
                            ));
                        }

                        break;

                    case "balance_claim":
                        color = "success";

                        var bal_id = op[1].balance_to_claim.substring(5);
                        // console.log( "bal_id: ", bal_id, op[1].balance_to_claim );

                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "transaction.claimed"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                React.createElement(FormattedAsset, {
                                    amount: op[1].total_claimed.amount,
                                    asset: op[1].total_claimed.asset_id
                                })
                            )
                        ));
                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "transaction.deposit_to"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                _this6.linkToAccount(op[1].deposit_to_account)
                            )
                        ));
                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "transaction.balance_id"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                "#",
                                bal_id
                            )
                        ));
                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "transaction.balance_owner"
                                })
                            ),
                            React.createElement(
                                "td",
                                { style: { fontSize: "80%" } },
                                op[1].balance_owner_key.substring(0, 10),
                                "..."
                            )
                        ));
                        break;

                    case "vesting_balance_create":
                        var stakingPeriod = AccountStakingInfo.getStakingPeriodByPeriodValue(op[1].policy[1].vesting_seconds) || {};
                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, { content: "xbtsx.account.amount_sth" })
                            ),
                            React.createElement(
                                "td",
                                null,
                                React.createElement(FormattedAsset, {
                                    amount: op[1].amount.amount,
                                    asset: op[1].amount.asset_id
                                })
                            )
                        ));

                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, { content: "xbtsx.account.length" })
                            ),
                            React.createElement(
                                "td",
                                null,
                                stakingPeriod.monthName
                            )
                        ));
                        break;

                    case "vesting_balance_withdraw":
                        color = "success";

                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "transfer.to"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                _this6.linkToAccount(op[1].owner)
                            )
                        ));
                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "transfer.amount"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                React.createElement(FormattedAsset, {
                                    amount: op[1].amount.amount,
                                    asset: op[1].amount.asset_id
                                })
                            )
                        ));

                        break;

                    case "transfer_to_blind":
                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "transfer.from"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                _this6.linkToAccount(op[1].from)
                            )
                        ));
                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "transfer.amount"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                React.createElement(FormattedAsset, {
                                    amount: op[1].amount.amount,
                                    asset: op[1].amount.asset_id
                                })
                            )
                        ));
                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "transaction.blinding_factor"
                                })
                            ),
                            React.createElement(
                                "td",
                                { style: { fontSize: "80%" } },
                                op[1].blinding_factor
                            )
                        ));
                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "transaction.outputs"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Inspector, {
                                    data: op[1].outputs[0],
                                    search: false
                                })
                            )
                        ));
                        break;

                    case "transfer_from_blind":
                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "transfer.to"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                _this6.linkToAccount(op[1].to)
                            )
                        ));
                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "transfer.amount"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                React.createElement(FormattedAsset, {
                                    amount: op[1].amount.amount,
                                    asset: op[1].amount.asset_id
                                })
                            )
                        ));
                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "transaction.blinding_factor"
                                })
                            ),
                            React.createElement(
                                "td",
                                { style: { fontSize: "80%" } },
                                op[1].blinding_factor
                            )
                        ));
                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "transaction.inputs"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Inspector, {
                                    data: op[1].inputs[0],
                                    search: false
                                })
                            )
                        ));
                        break;

                    case "blind_transfer":
                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "transaction.inputs"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Inspector, {
                                    data: op[1].inputs[0],
                                    search: false
                                })
                            )
                        ));
                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "transaction.outputs"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Inspector, {
                                    data: op[1].outputs[0],
                                    search: false
                                })
                            )
                        ));
                        break;

                    case "proposal_create":
                        var expiration_date = new Date(op[1].expiration_time + "Z");
                        var has_review_period = op[1].review_period_seconds !== undefined;
                        var review_begin_time = !has_review_period ? null : expiration_date.getTime() - op[1].review_period_seconds * 1000;
                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "proposal_create.review_period"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                has_review_period ? React.createElement(FormattedDate, {
                                    value: new Date(review_begin_time),
                                    format: "full"
                                }) : React.createElement(
                                    "span",
                                    null,
                                    "\u2014"
                                )
                            )
                        ));
                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "proposal_create.expiration_time"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                React.createElement(FormattedDate, {
                                    value: expiration_date,
                                    format: "full"
                                })
                            )
                        ));
                        var operations = [];
                        var _iteratorNormalCompletion = true;
                        var _didIteratorError = false;
                        var _iteratorError = undefined;

                        try {
                            for (var _iterator = op[1].proposed_ops[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                                var pop = _step.value;
                                operations.push(pop.op);
                            }
                        } catch (err) {
                            _didIteratorError = true;
                            _iteratorError = err;
                        } finally {
                            try {
                                if (!_iteratorNormalCompletion && _iterator.return) {
                                    _iterator.return();
                                }
                            } finally {
                                if (_didIteratorError) {
                                    throw _iteratorError;
                                }
                            }
                        }

                        var proposalsText = op[1].proposed_ops.map(function (o, index) {
                            return React.createElement(ProposedOperation, {
                                key: index,
                                index: index,
                                op: o.op,
                                inverted: false,
                                hideFee: true,
                                hideOpLabel: true,
                                hideDate: true,
                                proposal: true,
                                collapsed: true
                            });
                        });

                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "proposal_create.proposed_operations"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                proposalsText
                            )
                        ));
                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "proposal_create.fee_paying_account"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                _this6.linkToAccount(op[1].fee_paying_account)
                            )
                        ));
                        break;

                    case "proposal_update":
                        var fields = ["active_approvals_to_add", "active_approvals_to_remove", "owner_approvals_to_add", "owner_approvals_to_remove", "key_approvals_to_add", "key_approvals_to_remove"];

                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "proposal_create.fee_paying_account"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                _this6.linkToAccount(op[1].fee_paying_account)
                            )
                        ));

                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "proposal_create.id"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                op[1].proposal
                            )
                        ));

                        fields.forEach(function (field) {
                            if (op[1][field].length) {
                                rows.push(React.createElement(
                                    "tr",
                                    { key: key++ },
                                    React.createElement(
                                        "td",
                                        null,
                                        React.createElement(Translate, {
                                            content: "proposal.update." + field
                                        })
                                    ),
                                    React.createElement(
                                        "td",
                                        null,
                                        op[1][field].map(function (value) {
                                            return React.createElement(
                                                "div",
                                                { key: value },
                                                _this6.linkToAccount(value)
                                            );
                                        })
                                    )
                                ));
                            }
                        });

                        break;

                    case "proposal_delete":
                        color = "cancel";
                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "proposal_create.fee_paying_account"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                _this6.linkToAccount(op[1].fee_paying_account)
                            )
                        ));
                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "proposal_delete.using_owner_authority"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                React.createElement(TranslateBoolean, {
                                    value: op[1].using_owner_authority
                                })
                            )
                        ));
                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "proposal_create.id"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                op[1].proposal
                            )
                        ));
                        break;

                    case "asset_claim_fees":
                        color = "success";

                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "transaction.claimed"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                React.createElement(FormattedAsset, {
                                    amount: op[1].amount_to_claim.amount,
                                    asset: op[1].amount_to_claim.asset_id
                                })
                            )
                        ));

                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "transaction.deposit_to"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                _this6.linkToAccount(op[1].issuer)
                            )
                        ));

                        break;

                    case "asset_reserve":
                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "modal.reserve.from"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                _this6.linkToAccount(op[1].payer)
                            )
                        ));

                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "explorer.asset.title"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                _this6.linkToAsset(op[1].amount_to_reserve.asset_id)
                            )
                        ));

                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "transfer.amount"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                React.createElement(FormattedAsset, {
                                    amount: op[1].amount_to_reserve.amount,
                                    asset: op[1].amount_to_reserve.asset_id
                                })
                            )
                        ));
                        break;

                    case "worker_create":
                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "explorer.workers.title"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                op[1].name
                            )
                        ));

                        var startDate = counterpart.localize(new Date(op[1].work_begin_date), { type: "date" });
                        var endDate = counterpart.localize(new Date(op[1].work_end_date), { type: "date" });

                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "explorer.workers.period"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                startDate,
                                " - ",
                                endDate
                            )
                        ));

                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "explorer.workers.daily_pay"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                React.createElement(FormattedAsset, {
                                    amount: op[1].daily_pay,
                                    asset: "1.3.0"
                                })
                            )
                        ));

                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "explorer.workers.website"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                utils.sanitize(op[1].url)
                            )
                        ));

                        if (op[1].initializer[1]) {
                            rows.push(React.createElement(
                                "tr",
                                { key: key++ },
                                React.createElement(
                                    "td",
                                    null,
                                    React.createElement(Translate, {
                                        component: "span",
                                        content: "explorer.workers.vesting_pay"
                                    })
                                ),
                                React.createElement(
                                    "td",
                                    null,
                                    op[1].initializer[1].pay_vesting_period_days
                                )
                            ));
                        }

                        break;

                    case "asset_claim_pool":
                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "account.name"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                React.createElement(LinkToAccountById, { account: op[1].issuer })
                            )
                        ));
                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "explorer.asset.title"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                React.createElement(LinkToAssetById, { asset: op[1].asset_id })
                            )
                        ));

                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "transfer.amount"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                React.createElement(FormattedAsset, {
                                    amount: op[1].amount_to_claim.amount,
                                    asset: op[1].amount_to_claim.asset_id
                                })
                            )
                        ));
                        break;

                    case "asset_update_issuer":
                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "transfer.from"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                React.createElement(LinkToAccountById, { account: op[1].issuer })
                            )
                        ));

                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "transfer.to"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                React.createElement(LinkToAccountById, { account: op[1].new_issuer })
                            )
                        ));

                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "explorer.asset.title"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                React.createElement(LinkToAssetById, {
                                    asset: op[1].asset_to_update
                                })
                            )
                        ));

                        break;

                    case "bid_collateral":
                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "explorer.account.title"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                React.createElement(LinkToAccountById, { account: op[1].bidder })
                            )
                        ));

                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "explorer.asset.collateral_bid.collateral"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                React.createElement(FormattedAsset, {
                                    asset: op[1].additional_collateral.asset_id,
                                    amount: op[1].additional_collateral.amount
                                })
                            )
                        ));

                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "explorer.asset.collateral_bid.debt"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                React.createElement(FormattedAsset, {
                                    asset: op[1].debt_covered.asset_id,
                                    amount: op[1].debt_covered.amount
                                })
                            )
                        ));

                        break;
                    case "htlc_create":
                        // add claim period to block time
                        var block_time = _this6.props.block ? _this6.props.block.timestamp.getTime() : new Date().getTime();
                        var claim_due = new Date(block_time + op[1].claim_period_seconds * 1000);

                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "transfer.from"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                React.createElement(LinkToAccountById, { account: op[1].from })
                            )
                        ));
                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "transfer.to"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                React.createElement(LinkToAccountById, { account: op[1].to })
                            )
                        ));
                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "transfer.amount"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                React.createElement(FormattedAsset, {
                                    amount: op[1].amount.amount,
                                    asset: op[1].amount.asset_id
                                })
                            )
                        ));

                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "htlc.claim_period_due"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                React.createElement(FormattedDate, {
                                    value: claim_due,
                                    format: "full"
                                })
                            )
                        ));

                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "htlc.preimage_hash"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                React.createElement(
                                    Tooltip,
                                    {
                                        placement: "bottom",
                                        title: counterpart.translate("htlc.preimage_hash_explanation")
                                    },
                                    React.createElement(
                                        "span",
                                        null,
                                        "(" + op[1].preimage_size + ", " + op[1].preimage_hash[0] + "): " + op[1].preimage_hash[1]
                                    )
                                )
                            )
                        ));

                        break;
                    case "htlc_redeem":
                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, { component: "span", content: "htlc.id" })
                            ),
                            React.createElement(
                                "td",
                                null,
                                React.createElement(
                                    "span",
                                    null,
                                    op[1].htlc_id
                                )
                            )
                        ));

                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "htlc.redeemer"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                _this6.linkToAccount(op[1].redeemer)
                            )
                        ));

                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "htlc.preimage"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                _this6.linkToAccount(op[1].preimage)
                            )
                        ));

                        break;
                    case "htlc_extend":
                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, { component: "span", content: "htlc.id" })
                            ),
                            React.createElement(
                                "td",
                                null,
                                React.createElement(
                                    "span",
                                    null,
                                    op[1].htlc_id
                                )
                            )
                        ));

                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "htlc.update_issuer"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                _this6.linkToAccount(op[1].update_issuer)
                            )
                        ));

                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "htlc.seconds_to_add"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                React.createElement(
                                    "span",
                                    null,
                                    op[1].seconds_to_add
                                )
                            )
                        ));

                        break;
                    default:
                        console.log("unimplemented tx op:", op);

                        rows.push(React.createElement(
                            "tr",
                            { key: key++ },
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Translate, {
                                    component: "span",
                                    content: "explorer.block.op"
                                })
                            ),
                            React.createElement(
                                "td",
                                null,
                                React.createElement(Inspector, { data: op, search: false })
                            )
                        ));
                        break;
                }

                info.push(React.createElement(
                    OperationTable,
                    {
                        txIndex: _this6.props.index,
                        key: opIndex,
                        opCount: opCount,
                        index: opIndex,
                        color: color,
                        type: op[0],
                        fee: op[1].fee
                    },
                    rows
                ));
            });

            return React.createElement(
                "div",
                null,
                info
            );
        }
    }]);

    return Transaction;
}(React.Component);

Transaction.defaultProps = {
    no_links: false
};

Transaction.propTypes = {
    trx: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    no_links: PropTypes.bool
};

export default Transaction;