import React from "react";
import Translate from "react-translate-component";
import FormattedAsset from "../../Utility/FormattedAsset";

export var VestingBalanceCreate = function VestingBalanceCreate(_ref) {
    var op = _ref.op,
        linkToAccount = _ref.linkToAccount,
        fromComponent = _ref.fromComponent;

    if (fromComponent === "proposed_operation") {
        return React.createElement(
            "span",
            null,
            "\xA0",
            linkToAccount(op[1].creator),
            React.createElement(Translate, {
                component: "span",
                content: "proposal.vesting_balance_create"
            }),
            "\xA0",
            React.createElement(FormattedAsset, {
                style: { fontWeight: "bold" },
                amount: op[1].amount.amount,
                asset: op[1].amount.asset_id
            }),
            "\xA0",
            linkToAccount(op[1].owner)
        );
    } else {
        return React.createElement(
            "span",
            null,
            "\xA0",
            linkToAccount(op[1].creator),
            React.createElement(Translate, {
                component: "span",
                content: "transaction.vesting_balance_create"
            }),
            "\xA0",
            React.createElement(FormattedAsset, {
                amount: op[1].amount.amount,
                asset: op[1].amount.asset_id
            }),
            "\xA0",
            linkToAccount(op[1].owner)
        );
    }
};