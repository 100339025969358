var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from "react";
import PropTypes from "prop-types";
import Translate from "react-translate-component";
import ReactTooltip from "react-tooltip";
import Icon from "../Icon/Icon";
import { Download } from "../Wallet/Backup";

var WalletRegistrationConfirm = function (_React$Component) {
    _inherits(WalletRegistrationConfirm, _React$Component);

    function WalletRegistrationConfirm() {
        var _ref;

        var _temp, _this, _ret;

        _classCallCheck(this, WalletRegistrationConfirm);

        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
        }

        return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = WalletRegistrationConfirm.__proto__ || Object.getPrototypeOf(WalletRegistrationConfirm)).call.apply(_ref, [this].concat(args))), _this), _this.onBackupDownload = function () {
            _this.props.history.push("/");
        }, _temp), _possibleConstructorReturn(_this, _ret);
    }

    _createClass(WalletRegistrationConfirm, [{
        key: "renderTooltip",
        value: function renderTooltip() {
            return React.createElement(
                ReactTooltip,
                {
                    id: "wallet-confirm",
                    className: "custom-tooltip text-left",
                    globalEventOff: "click"
                },
                React.createElement(
                    "div",
                    {
                        className: "tooltip-text",
                        onClick: function onClick(e) {
                            return e.stopPropagation();
                        }
                    },
                    React.createElement(Translate, { content: "tooltip.registration.whyBinFile" }),
                    React.createElement(
                        "span",
                        {
                            onClick: function onClick() {
                                return ReactTooltip.hide();
                            },
                            className: "close-button cursor-pointer"
                        },
                        "\xD7"
                    )
                )
            );
        }
    }, {
        key: "render",
        value: function render() {
            var _this2 = this;

            return React.createElement(
                "div",
                { className: "text-left" },
                React.createElement(
                    "div",
                    { className: "confirm-checks" },
                    React.createElement(Translate, {
                        component: "h3",
                        content: "registration.createAccountTitle"
                    }),
                    WalletRegistrationConfirm.renderWarning()
                ),
                React.createElement(
                    "div",
                    {
                        className: "checkbox-block",
                        onClick: function onClick() {
                            return _this2.props.toggleConfirmed("checkboxRemember");
                        }
                    },
                    React.createElement(
                        "span",
                        null,
                        React.createElement(Icon, {
                            className: (this.props.checkboxRemember ? "checkbox-active" : "") + " checkbox",
                            name: "checkmark"
                        })
                    ),
                    React.createElement(Translate, {
                        className: "checkbox-text",
                        content: "registration.checkboxRemember"
                    })
                ),
                React.createElement(
                    "div",
                    {
                        className: "checkbox-block",
                        onClick: function onClick() {
                            return _this2.props.toggleConfirmed("checkboxUploaded");
                        }
                    },
                    React.createElement(
                        "span",
                        null,
                        React.createElement(Icon, {
                            className: (this.props.checkboxUploaded ? "checkbox-active" : "") + " checkbox",
                            name: "checkmark"
                        })
                    ),
                    React.createElement(Translate, {
                        className: "checkbox-text",
                        content: "registration.checkboxUploaded"
                    })
                ),
                React.createElement(
                    "div",
                    {
                        className: "checkbox-block",
                        onClick: function onClick() {
                            return _this2.props.toggleConfirmed("checkboxRecover");
                        }
                    },
                    React.createElement(
                        "span",
                        null,
                        React.createElement(Icon, {
                            className: (this.props.checkboxRecover ? "checkbox-active" : "") + " checkbox",
                            name: "checkmark"
                        })
                    ),
                    React.createElement(Translate, {
                        className: "checkbox-text",
                        content: "registration.checkboxRecover"
                    })
                ),
                React.createElement(Download, {
                    confirmation: true,
                    checkboxActive: this.props.checkboxUploaded && this.props.checkboxRemember && this.props.checkboxRecover,
                    downloadCb: this.onBackupDownload
                }),
                React.createElement(Translate, {
                    component: "p",
                    className: "cursor-pointer why-bin-file checkbox-text",
                    content: "registration.whyBinFile",
                    "data-for": "wallet-confirm",
                    "data-tip": true,
                    "data-event": "click",
                    "data-place": "bottom",
                    "data-effect": "solid"
                }),
                this.renderTooltip()
            );
        }
    }], [{
        key: "renderWarning",
        value: function renderWarning() {
            return React.createElement(
                "div",
                { className: "attention-note" },
                React.createElement(Icon, { name: "attention", size: "1x" }),
                React.createElement(Translate, {
                    content: "registration.attention",
                    className: "attention-text"
                }),
                React.createElement(Translate, { component: "p", content: "registration.walletNote" })
            );
        }
    }]);

    return WalletRegistrationConfirm;
}(React.Component);

WalletRegistrationConfirm.propTypes = {
    toggleConfirmed: PropTypes.func.isRequired,
    checkboxUploaded: PropTypes.bool.isRequired,
    checkboxRecover: PropTypes.bool.isRequired,
    checkboxRemember: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired
};


export default WalletRegistrationConfirm;