import React from "react";
import TranslateWithLinks from "../../Utility/TranslateWithLinks";

export var CallOrderUpdate = function CallOrderUpdate(_ref) {
    var op = _ref.op,
        changeColor = _ref.changeColor,
        fromComponent = _ref.fromComponent;

    changeColor("warning");

    return React.createElement(
        "span",
        null,
        React.createElement(TranslateWithLinks, {
            string: fromComponent === "proposed_operation" ? "proposal.call_order_update" : "operation.call_order_update",
            keys: [{
                type: "account",
                value: op[1].funding_account,
                arg: "account"
            }, {
                type: "asset",
                value: op[1].delta_debt.asset_id,
                arg: "debtSymbol"
            }, {
                type: "amount",
                value: op[1].delta_debt,
                arg: "debt"
            }, {
                type: "amount",
                value: op[1].delta_collateral,
                arg: "collateral"
            }]
        })
    );
};