import React from "react";
import Translate from "react-translate-component";
import FormattedAsset from "../../Utility/FormattedAsset";

export var BondCreateOffer = function BondCreateOffer(_ref) {
    var op = _ref.op;

    return React.createElement(
        "span",
        null,
        React.createElement(Translate, { component: "span", content: "proposal.bond_create_offer" }),
        "\xA0",
        React.createElement(FormattedAsset, {
            style: { fontWeight: "bold" },
            amount: op[1].amount.amount,
            asset: op[1].amount.asset_id
        })
    );
};