var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from "react";
import utils from "common/utils";
import { connect } from "alt-react";
import AccountImage from "../Account/AccountImage";
import AccountStore from "stores/AccountStore";
import AccountActions from "actions/AccountActions";
import Translate from "react-translate-component";
import { ChainStore, PublicKey, ChainValidation, FetchChain } from "bitsharesjs";
import ChainTypes from "../Utility/ChainTypes";
import BindToChainState from "../Utility/BindToChainState";
import counterpart from "counterpart";
import Icon from "../Icon/Icon";
import accountUtils from "common/account_utils";
import cnames from "classnames";
import PropTypes from "prop-types";
import { Tooltip, Button, Input, Icon as AntIcon, Select, Form } from "bitshares-ui-style-guide";

/*
 * @brief Allows the user to enter an account by name or #ID
 *
 * This component is designed to be stateless as possible.  It's primary responsbility is to
 * manage the layout of data and to filter the user input.
 *
 */

var AccountSelector = function (_React$Component) {
    _inherits(AccountSelector, _React$Component);

    function AccountSelector(props) {
        _classCallCheck(this, AccountSelector);

        var _this = _possibleConstructorReturn(this, (AccountSelector.__proto__ || Object.getPrototypeOf(AccountSelector)).call(this, props));

        _this.state = {
            inputChanged: false,
            locked: null
        };
        return _this;
    }

    _createClass(AccountSelector, [{
        key: "componentDidMount",
        value: function componentDidMount() {
            var _props = this.props,
                account = _props.account,
                accountName = _props.accountName;


            if (typeof account === "undefined") account = ChainStore.getAccount(accountName);

            if (this.props.onAccountChanged && account) this.props.onAccountChanged(account);

            if (!this.props.typeahead && accountName) this.onInputChanged(accountName);
        }
    }, {
        key: "componentDidUpdate",
        value: function componentDidUpdate() {
            if (this.props.focus && !!this.props.editable && !this.props.disabled) {
                this.refs.user_input.focus();
            }
        }
    }, {
        key: "componentWillReceiveProps",
        value: function componentWillReceiveProps(np) {
            if (np.account && np.account !== this.props.account) {
                if (this.props.onAccountChanged) {
                    this.props.onAccountChanged(np.account);
                }
            }
        }

        // can be used in parent component: this.refs.account_selector.getAccount()

    }, {
        key: "getAccount",
        value: function getAccount() {
            return this.props.account;
        }
    }, {
        key: "getError",
        value: function getError() {
            var _props2 = this.props,
                account = _props2.account,
                error = _props2.error;


            if (!error && account && !this.getInputType(account.get("name"))) error = counterpart.translate("account.errors.invalid");

            return error;
        }
    }, {
        key: "getInputType",
        value: function getInputType(value) {
            // OK
            if (!value) return null;
            if (value[0] === "#" && utils.is_object_id("1.2." + value.substring(1))) return "id";
            if (ChainValidation.is_account_name(value, true)) return "name";
            if (this.props.allowPubKey && PublicKey.fromPublicKeyString(value)) return "pubkey";
            return null;
        }
    }, {
        key: "getVerifiedAccountName",
        value: function getVerifiedAccountName(e) {
            var allowUppercase = this.props.allowUppercase;


            var value = null;
            if (typeof e === "string") {
                value = e;
            } else if (e && e.target) {
                value = e.target.value.trim();
            } else {
                value = "";
            }

            if (!allowUppercase) value = value.toLowerCase();

            // If regex matches ^.*#/account/account-name/.*$, parse out account-name
            var _value = value.replace("#", "").match(/(?:\/account\/)(.*)/);
            if (_value) value = _value[1];

            return value;
        }
    }, {
        key: "_notifyOnChange",
        value: function _notifyOnChange(selectedAccountName) {
            var props = this.props;


            var accountName = this.getVerifiedAccountName(selectedAccountName);

            // Synchronous onChange for input change
            if (!!props.onChange && (!!accountName || accountName === "")) {
                props.onChange(accountName);
            }

            // asynchronous onAccountChanged for checking on chain
            if (!!props.onAccountChanged) {
                FetchChain("getAccount", accountName, undefined, _defineProperty({}, accountName, false)).then(function (account) {
                    if (!!account) {
                        props.onAccountChanged(account);
                    }
                }).catch(function (err) {
                    console.log(err);
                });
            }
        }
    }, {
        key: "onSelect",
        value: function onSelect(selectedAccountName) {
            this._notifyOnChange(selectedAccountName);
        }
    }, {
        key: "onInputChanged",
        value: function onInputChanged(e) {
            this.setState({
                inputChanged: true
            });

            this._notifyOnChange(e);
        }
    }, {
        key: "onKeyDown",
        value: function onKeyDown(e) {
            if (e.keyCode === 13 || e.keyCode === 9) {
                this.onAction(e);
            }
        }
    }, {
        key: "_onAddContact",
        value: function _onAddContact() {
            AccountActions.addAccountContact(this.props.accountName);
        }
    }, {
        key: "_onRemoveContact",
        value: function _onRemoveContact() {
            AccountActions.removeAccountContact(this.props.accountName);
        }
    }, {
        key: "onAction",
        value: function onAction(e) {
            var _props3 = this.props,
                onAction = _props3.onAction,
                disableActionButton = _props3.disableActionButton,
                account = _props3.account,
                accountName = _props3.accountName;

            e.preventDefault();
            if (!this.getError() && onAction && !disableActionButton) {
                if (account) onAction(account);else if (this.getInputType(accountName) === "pubkey") onAction(accountName);
            }
        }
    }, {
        key: "render",
        value: function render() {
            var _this2 = this;

            var _props4 = this.props,
                accountName = _props4.accountName,
                account = _props4.account,
                allowPubKey = _props4.allowPubKey,
                typeahead = _props4.typeahead,
                disableActionButton = _props4.disableActionButton,
                contacts = _props4.contacts,
                myActiveAccounts = _props4.myActiveAccounts,
                noPlaceHolder = _props4.noPlaceHolder,
                useHR = _props4.useHR,
                labelClass = _props4.labelClass,
                reserveErrorSpace = _props4.reserveErrorSpace;


            var inputType = this.getInputType(accountName);

            var typeAheadAccounts = [];
            var error = this.getError();
            var linkedAccounts = myActiveAccounts;
            linkedAccounts = linkedAccounts.concat(contacts);

            // Selected Account
            var displayText = void 0;
            if (account) {
                account.isKnownScammer = accountUtils.isKnownScammer(account.get("name"));
                account.accountType = this.getInputType(account.get("name"));
                account.accountStatus = ChainStore.getAccountMemberStatus(account);
                account.statusText = !account.isKnownScammer ? counterpart.translate("account.member." + account.accountStatus) : counterpart.translate("account.member.suspected_scammer");
                displayText = account.accountType === "name" ? "#" + account.get("id").substring(4) : account.accountType === "id" ? account.get("name") : null;
            }

            // Without Typeahead Error Handling
            if (!typeahead) {
                if (!account && accountName && inputType !== "pubkey") {
                    error = counterpart.translate("account.errors.unknown");
                }
            } else {
                if (!(allowPubKey && inputType === "pubkey") && !error && accountName && !account) error = counterpart.translate("account.errors.unknown");
            }
            if (allowPubKey && inputType === "pubkey") displayText = "Public Key";

            if (account && linkedAccounts) account.isFavorite = myActiveAccounts.has(account.get("name")) || contacts.has(account.get("name"));

            if (typeahead && linkedAccounts) {
                linkedAccounts.map(function (accountName) {
                    if (_this2.props.excludeAccounts.indexOf(accountName) !== -1) return null;
                    var account = ChainStore.getAccount(accountName);
                    var account_status = ChainStore.getAccountMemberStatus(account);
                    var account_status_text = !accountUtils.isKnownScammer(accountName) ? "account.member." + account_status : "account.member.suspected_scammer";

                    typeAheadAccounts.push({
                        id: accountName,
                        label: accountName,
                        status: counterpart.translate(account_status_text),
                        isOwn: myActiveAccounts.has(accountName),
                        isFavorite: contacts.has(accountName),
                        isKnownScammer: accountUtils.isKnownScammer(accountName),
                        className: accountUtils.isKnownScammer(accountName) ? "negative" : "positive"
                    });
                }).filter(function (a) {
                    return !!a;
                });
            }

            var typeaheadHasAccount = !!accountName ? typeAheadAccounts.reduce(function (boolean, a) {
                return boolean || a.label === accountName;
            }, false) : false;

            if (!!accountName && !typeaheadHasAccount && this.state.inputChanged) {
                var _account = ChainStore.getAccount(accountName);
                var _account_status = _account ? ChainStore.getAccountMemberStatus(_account) : null;
                var _account_status_text = _account ? !accountUtils.isKnownScammer(_account.get("name")) ? counterpart.translate("account.member." + _account_status) : counterpart.translate("account.member.suspected_scammer") : counterpart.translate("account.errors.unknown");

                typeAheadAccounts.push({
                    id: this.props.accountName,
                    label: this.props.accountName,
                    status: _account_status_text,
                    isOwn: myActiveAccounts.has(accountName),
                    isFavorite: contacts.has(accountName),
                    isKnownScammer: accountUtils.isKnownScammer(accountName),
                    className: accountUtils.isKnownScammer(accountName) || !_account ? "negative" : null,
                    disabled: !_account ? true : false
                });
            }

            typeAheadAccounts.sort(function (a, b) {
                if (a.disabled && !b.disabled) {
                    if (a.label > b.label) return 1;else return -1;
                } else return -1;
            });

            var linked_status = void 0;

            if (!this.props.account) {
                linked_status = null;
            } else if (myActiveAccounts.has(account.get("name"))) {
                linked_status = React.createElement(
                    Tooltip,
                    {
                        placement: "top",
                        title: counterpart.translate("tooltip.own_account")
                    },
                    React.createElement(
                        "span",
                        { className: "tooltip green" },
                        React.createElement(AntIcon, { type: "user" })
                    )
                );
            } else if (accountUtils.isKnownScammer(account.get("name"))) {
                linked_status = React.createElement(
                    Tooltip,
                    {
                        placement: "top",
                        title: counterpart.translate("tooltip.scam_account")
                    },
                    React.createElement(
                        "span",
                        { className: "tooltip red" },
                        React.createElement(AntIcon, { type: "warning", theme: "filled" })
                    )
                );
            } else if (contacts.has(account.get("name"))) {
                linked_status = React.createElement(
                    Tooltip,
                    {
                        placement: "top",
                        title: counterpart.translate("tooltip.follow_user"),
                        onClick: this._onRemoveContact.bind(this)
                    },
                    React.createElement(
                        "span",
                        { className: "tooltip green" },
                        React.createElement(AntIcon, { type: "star", theme: "filled" })
                    )
                );
            } else {
                linked_status = React.createElement(
                    Tooltip,
                    {
                        placement: "top",
                        title: counterpart.translate("tooltip.follow_user_add"),
                        onClick: this._onAddContact.bind(this)
                    },
                    React.createElement(
                        "span",
                        { className: "tooltip" },
                        React.createElement(AntIcon, { type: "star" })
                    )
                );
            }

            var disabledAction = !(account || inputType === "pubkey") || error || disableActionButton;

            var lockedState = this.state.locked !== null ? this.state.locked : this.props.locked;

            var editableInput = !!lockedState ? false : this.props.editable != null ? this.props.editable : undefined;
            var disabledInput = !!lockedState ? true : this.props.disabled != null ? this.props.disabled : undefined;

            return React.createElement(
                Tooltip,
                {
                    className: "input-area",
                    title: this.props.tooltip,
                    mouseEnterDelay: 0.5
                },
                React.createElement(
                    Form,
                    {
                        className: "full-width",
                        layout: "vertical",
                        style: this.props.style
                    },
                    React.createElement(
                        Form.Item,
                        {
                            label: this.props.label ? counterpart.translate(this.props.label) : "",
                            validateStatus: error ? "error" : null,
                            help: error ? error : null
                        },
                        this.props.label ? React.createElement(
                            "div",
                            {
                                className: "header-area" + (this.props.hideImage ? " no-margin" : "")
                            },
                            React.createElement(
                                "label",
                                {
                                    className: cnames("right-label", account && (account.isFavorite || account.isOwn) ? "positive" : null, account && account.isKnownScammer ? "negative" : null),
                                    style: { marginTop: -30 }
                                },
                                React.createElement(
                                    "span",
                                    { style: { paddingRight: "0.5rem" } },
                                    account && account.statusText,
                                    "\xA0",
                                    !!displayText && displayText
                                ),
                                linked_status
                            )
                        ) : null,
                        useHR && React.createElement("hr", null),
                        React.createElement(
                            "div",
                            { className: "inline-label input-wrapper" },
                            account && account.accountType === "pubkey" ? React.createElement(
                                "div",
                                { className: "account-image" },
                                React.createElement(Icon, {
                                    name: "key",
                                    title: "icons.key",
                                    size: "4x"
                                })
                            ) : this.props.hideImage ? null : React.createElement(AccountImage, {
                                size: {
                                    height: this.props.size || 80,
                                    width: this.props.size || 80
                                },
                                account: account ? account.get("name") : null,
                                custom_image: null
                            }),
                            typeof this.props.typeahead !== "undefined" ? React.createElement(
                                Select,
                                {
                                    showSearch: true,
                                    optionLabelProp: "value",
                                    onSelect: this.onSelect.bind(this),
                                    onChange: this.onInputChanged.bind(this),
                                    onSearch: this.onInputChanged.bind(this),
                                    placeholder: counterpart.translate("account.search"),
                                    value: account ? accountName : null,
                                    disabled: !!disabledInput ? disabledInput : undefined
                                },
                                typeAheadAccounts.map(function (account) {
                                    return React.createElement(
                                        Select.Option,
                                        {
                                            key: account.id,
                                            value: account.label,
                                            disabled: account.disabled
                                        },
                                        account.isOwn ? React.createElement(AntIcon, { type: "user" }) : null,
                                        account.isFavorite ? React.createElement(AntIcon, { type: "star" }) : null,
                                        account.isKnownScammer ? React.createElement(AntIcon, { type: "warning" }) : null,
                                        "\xA0",
                                        account.label,
                                        React.createElement(
                                            "span",
                                            { style: { float: "right" } },
                                            account.status
                                        )
                                    );
                                })
                            ) : React.createElement(Input, _defineProperty({
                                style: {
                                    textTransform: this.getInputType(accountName) === "pubkey" ? null : "lowercase",
                                    fontVariant: "initial"
                                },
                                name: "username",
                                id: "username",
                                autoComplete: !!this.props.editable ? "username" : undefined,
                                type: "text",
                                value: this.props.accountName || "",
                                placeholder: this.props.placeholder || counterpart.translate("account.name"),
                                disabled: this.props.disabled,
                                ref: "user_input",
                                onChange: this.onInputChanged.bind(this),
                                onKeyDown: this.onKeyDown.bind(this),
                                tabIndex: !this.props.editable || !!this.props.disabled ? -1 : this.props.tabIndex,
                                editable: !!editableInput ? editableInput.toString() : undefined,
                                readOnly: !!editableInput ? (!editableInput).toString() : undefined
                            }, "disabled", !!disabledInput ? disabledInput.toString() : undefined)),
                            !!lockedState && React.createElement(
                                Tooltip,
                                {
                                    title: counterpart.translate("tooltip.unlock_account_name")
                                },
                                React.createElement(
                                    "div",
                                    {
                                        style: {
                                            lineHeight: "2rem",
                                            marginLeft: "10px",
                                            cursor: "pointer"
                                        },
                                        onClick: function onClick() {
                                            return _this2.setState({ locked: false });
                                        }
                                    },
                                    React.createElement(AntIcon, {
                                        style: { fontSize: "1rem" },
                                        type: "edit"
                                    })
                                )
                            ),
                            this.props.children,
                            this.props.onAction ? React.createElement(
                                Tooltip,
                                {
                                    title: counterpart.translate("tooltip.required_input", {
                                        type: counterpart.translate("global.field_type.account")
                                    })
                                },
                                React.createElement(
                                    Button,
                                    {
                                        type: "primary",
                                        disabled: disabledAction,
                                        onClick: this.onAction.bind(this)
                                    },
                                    React.createElement(Translate, {
                                        content: this.props.action_label
                                    })
                                )
                            ) : null
                        )
                    )
                )
            );
        }
    }]);

    return AccountSelector;
}(React.Component);

AccountSelector.propTypes = {
    label: PropTypes.string, // a translation key for the label
    error: PropTypes.element, // the error message override
    placeholder: PropTypes.string, // the placeholder text to be displayed when there is no user_input
    onChange: PropTypes.func, // a method to be called any time user input changes
    onAccountChanged: PropTypes.func, // a method to be called when existing account is selected
    onAction: PropTypes.func, // a method called when Add button is clicked
    accountName: PropTypes.string, // the current value of the account selector, the string the user enters
    account: ChainTypes.ChainAccount, // account object retrieved via BindToChainState decorator (not input)
    tabIndex: PropTypes.number, // tabindex property to be passed to input tag
    disableActionButton: PropTypes.bool, // use it if you need to disable action button,
    allowUppercase: PropTypes.bool, // use it if you need to allow uppercase letters
    typeahead: PropTypes.bool,
    excludeAccounts: PropTypes.array, // array of accounts to exclude from the typeahead
    focus: PropTypes.bool,
    disabled: PropTypes.bool,
    editable: PropTypes.bool,
    locked: PropTypes.bool
};
AccountSelector.defaultProps = {
    autosubscribe: false,
    excludeAccounts: [],
    disabled: null,
    editable: null,
    locked: false
};


AccountSelector = BindToChainState(AccountSelector);

AccountSelector = connect(AccountSelector, {
    listenTo: function listenTo() {
        return [AccountStore];
    },
    getProps: function getProps() {
        return {
            myActiveAccounts: AccountStore.getState().myActiveAccounts,
            contacts: AccountStore.getState().accountContacts
        };
    }
});

export default AccountSelector;