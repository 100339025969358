var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from "react";
import Transaction from "./Transaction";
import counterpart from "counterpart";
import Translate from "react-translate-component";
import TransactionConfirmActions from "actions/TransactionConfirmActions";
import TransactionConfirmStore from "stores/TransactionConfirmStore";
import { connect } from "alt-react";
import Icon from "../Icon/Icon";
import WalletDb from "stores/WalletDb";
import AccountStore from "stores/AccountStore";
import AccountSelect from "components/Forms/AccountSelect";
import { ChainStore } from "bitsharesjs";
import utils from "common/utils";
import Operation from "components/Blockchain/Operation";
import notify from "actions/NotificationActions";
import { Modal, Button, Icon as AIcon, Alert, Switch } from "bitshares-ui-style-guide";

var TransactionConfirm = function (_React$Component) {
    _inherits(TransactionConfirm, _React$Component);

    function TransactionConfirm(props) {
        _classCallCheck(this, TransactionConfirm);

        var _this = _possibleConstructorReturn(this, (TransactionConfirm.__proto__ || Object.getPrototypeOf(TransactionConfirm)).call(this, props));

        _this.state = {
            isModalVisible: false
        };

        _this.onCloseClick = _this.onCloseClick.bind(_this);

        _this.onConfirmClick = _this.onConfirmClick.bind(_this);

        _this.onKeyUp = _this.onKeyUp.bind(_this);
        return _this;
    }

    _createClass(TransactionConfirm, [{
        key: "shouldComponentUpdate",
        value: function shouldComponentUpdate(nextProps) {
            if (!nextProps.transaction) {
                return false;
            }

            return !utils.are_equal_shallow(nextProps, this.props);
        }
    }, {
        key: "componentDidUpdate",
        value: function componentDidUpdate() {
            if (!this.props.closed) {
                this.showModal();
            } else {
                this.hideModal();
            }
        }
    }, {
        key: "showModal",
        value: function showModal() {
            this.setState({
                isModalVisible: true
            });
        }
    }, {
        key: "hideModal",
        value: function hideModal() {
            this.setState({
                isModalVisible: false
            });
        }
    }, {
        key: "onKeyUp",
        value: function onKeyUp(e) {
            if (e.keyCode === 13) this.onConfirmClick(e);else e.preventDefault();
        }
    }, {
        key: "onConfirmClick",
        value: function onConfirmClick(e) {
            var _this2 = this;

            e.preventDefault();
            if (this.props.propose) {
                var propose_options = {
                    fee_paying_account: ChainStore.getAccount(this.props.fee_paying_account).get("id")
                };
                this.props.transaction.update_head_block().then(function () {
                    WalletDb.process_transaction(_this2.props.transaction.propose(propose_options), null, true);
                });
            } else {
                TransactionConfirmActions.broadcast(this.props.transaction, this.props.resolve, this.props.reject);
            }
        }
    }, {
        key: "onCloseClick",
        value: function onCloseClick(e) {
            e.preventDefault();
            TransactionConfirmActions.close();
        }
    }, {
        key: "onProposeClick",
        value: function onProposeClick() {
            TransactionConfirmActions.togglePropose();
        }
    }, {
        key: "onProposeAccount",
        value: function onProposeAccount(fee_paying_account) {
            ChainStore.getAccount(fee_paying_account);
            TransactionConfirmActions.proposeFeePayingAccount(fee_paying_account);
        }
    }, {
        key: "componentWillReceiveProps",
        value: function componentWillReceiveProps(np) {
            if (np.broadcast && np.included && !this.props.included && !np.error) {
                notify.addNotification.defer({
                    children: React.createElement(
                        "div",
                        null,
                        React.createElement(
                            "p",
                            null,
                            React.createElement(Translate, { content: "transaction.transaction_confirmed" }),
                            "\xA0\xA0",
                            React.createElement(
                                "span",
                                null,
                                React.createElement(Icon, {
                                    name: "checkmark-circle",
                                    title: "icons.checkmark_circle.operation_succeed",
                                    size: "1x",
                                    className: "success"
                                })
                            )
                        ),
                        React.createElement(
                            "table",
                            null,
                            React.createElement(Operation, {
                                op: this.props.transaction.serialize().operations[0],
                                block: 1,
                                current: "1.2.0",
                                hideFee: true,
                                inverted: false,
                                hideOpLabel: true,
                                hideDate: true
                            })
                        )
                    ),
                    level: "success",
                    autoDismiss: 3
                });
            }
        }
    }, {
        key: "render",
        value: function render() {
            var _props = this.props,
                broadcast = _props.broadcast,
                broadcasting = _props.broadcasting;

            if (!this.props.transaction || this.props.closed) {
                return null;
            }
            var button_group = void 0,
                footer = void 0,
                header = void 0,
                confirmButtonClass = "button";
            if (this.props.propose && !this.props.fee_paying_account) confirmButtonClass += " disabled";

            if (this.props.error || this.props.included) {
                header = this.props.error ? counterpart.translate("transaction.broadcast_fail", {
                    message: ""
                }) : counterpart.translate("transaction.transaction_confirmed");

                footer = [React.createElement(
                    Button,
                    { key: "cancel", onClick: this.onCloseClick },
                    counterpart.translate("transfer.close")
                )];
            } else if (broadcast) {
                header = counterpart.translate("transaction.broadcast_success") + ". " + counterpart.translate("transaction.waiting");

                footer = [React.createElement(
                    Button,
                    { key: "cancel", onClick: this.onCloseClick },
                    counterpart.translate("transfer.close")
                )];
            } else if (broadcasting) {
                header = React.createElement(
                    "div",
                    null,
                    counterpart.translate("transaction.broadcasting"),
                    React.createElement(AIcon, { type: "loading" })
                );
                footer = [];
            } else {
                header = counterpart.translate("transaction.confirm");

                footer = [React.createElement(
                    Button,
                    {
                        key: "confirm",
                        type: "primary",
                        onClick: this.onConfirmClick
                    },
                    this.props.propose ? counterpart.translate("propose") : counterpart.translate("transfer.confirm")
                ), React.createElement(
                    Button,
                    { key: "cancel", onClick: this.onCloseClick },
                    counterpart.translate("account.perm.cancel")
                )];
            }

            return React.createElement(
                "div",
                { ref: "transactionConfirm", onKeyUp: this.onKeyUp },
                React.createElement(
                    Modal,
                    {
                        wrapClassName: "modal--transaction-confirm",
                        title: header,
                        visible: !this.props.closed,
                        id: "transaction_confirm_modal",
                        ref: "modal",
                        footer: footer,
                        overlay: true,
                        onCancel: this.onCloseClick,
                        overlayClose: !broadcasting,
                        noCloseBtn: true
                    },
                    React.createElement(
                        "div",
                        { className: "grid-block vertical no-padding no-margin" },
                        this.props.error ? React.createElement(Alert, { type: "error", message: this.props.error }) : null,
                        this.props.included ? React.createElement(Alert, {
                            type: "success",
                            message: counterpart.translate("transaction.transaction_confirmed"),
                            description: "#" + this.props.trx_id + "@" + this.props.trx_block_num
                        }) : null,
                        React.createElement(
                            "div",
                            {
                                className: "shrink",
                                style: {
                                    maxHeight: "60vh",
                                    overflowY: "auto",
                                    overflowX: "hidden"
                                }
                            },
                            React.createElement(Transaction, {
                                key: Date.now(),
                                trx: this.props.transaction.serialize(),
                                index: 0,
                                no_links: true
                            })
                        ),
                        this.props.propose ? React.createElement(
                            "div",
                            { className: "full-width-content form-group" },
                            React.createElement(
                                "label",
                                null,
                                React.createElement(Translate, { content: "account.propose_from" })
                            ),
                            React.createElement(AccountSelect, {
                                className: "full-width",
                                account_names: AccountStore.getMyAccounts(),
                                onChange: this.onProposeAccount.bind(this)
                            })
                        ) : null,
                        React.createElement(
                            "div",
                            {
                                className: "grid-block shrink",
                                style: { paddingTop: "1rem" }
                            },
                            !this.props.transaction.has_proposed_operation() && !(broadcast || broadcasting || this.props.error) ? React.createElement(
                                "div",
                                { className: "align-right grid-block" },
                                React.createElement(
                                    "label",
                                    {
                                        style: {
                                            paddingRight: "0.5rem"
                                        }
                                    },
                                    React.createElement(Translate, { content: "propose" }),
                                    ":"
                                ),
                                React.createElement(Switch, {
                                    checked: this.props.propose,
                                    onChange: this.onProposeClick.bind(this)
                                })
                            ) : null
                        )
                    )
                )
            );
        }
    }]);

    return TransactionConfirm;
}(React.Component);

TransactionConfirm = connect(TransactionConfirm, {
    listenTo: function listenTo() {
        return [TransactionConfirmStore];
    },
    getProps: function getProps() {
        return TransactionConfirmStore.getState();
    }
});

export default TransactionConfirm;