var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

import alt from "alt-instance";
import WalletApi from "api/WalletApi";
import WalletDb from "stores/WalletDb";
import { ChainStore } from "bitsharesjs";
import { Apis } from "bitsharesjs-ws";
import marketUtils from "common/market_utils";
import accountUtils from "common/account_utils";
import Immutable from "immutable";

var subs = {};
var currentBucketSize = void 0;
var marketStats = {};
var statTTL = 60 * 1 * 1000; // 1 minute

var cancelBatchIDs = Immutable.List();
var dispatchCancelTimeout = null;
var cancelBatchTime = 500;

var subBatchResults = Immutable.List();
var dispatchSubTimeout = null;
var subBatchTime = 500;

var currentMarket = null;
var currentGroupLimit = "";

function clearBatchTimeouts() {
    clearTimeout(dispatchCancelTimeout);
    clearTimeout(dispatchSubTimeout);
    dispatchCancelTimeout = null;
    dispatchSubTimeout = null;
}

var marketStatsQueue = []; // Queue array holding get_ticker promises
var marketStatsQueueLength = 500; // Number of get_ticker calls per batch
var marketStatsQueueTimeout = 1.5; // Seconds before triggering a queue processing
var marketStatsQueueActive = false;

var currentGroupedOrderLimit = 0;

var MarketsActions = function () {
    function MarketsActions() {
        _classCallCheck(this, MarketsActions);
    }

    _createClass(MarketsActions, [{
        key: "changeBase",
        value: function changeBase(market) {
            clearBatchTimeouts();
            return market;
        }
    }, {
        key: "changeBucketSize",
        value: function changeBucketSize(size) {
            return size;
        }
    }, {
        key: "getMarketStats",
        value: function getMarketStats(base, quote) {
            var refresh = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
            var errorCallback = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;

            var _marketUtils$getMarke = marketUtils.getMarketName(base, quote),
                marketName = _marketUtils$getMarke.marketName,
                first = _marketUtils$getMarke.first,
                second = _marketUtils$getMarke.second;

            return function (dispatch) {
                if (base === quote) return;
                var now = new Date();

                if (marketStats[marketName] && !refresh) {
                    if (now - marketStats[marketName].lastFetched < statTTL) {
                        return false;
                    } else {
                        refresh = true;
                    }
                }

                if (!marketStats[marketName] || refresh) {
                    marketStats[marketName] = {
                        lastFetched: new Date()
                    };

                    if (Apis.instance().db_api()) {
                        marketStatsQueue.push({
                            promise: Apis.instance().db_api().exec("get_ticker", [second.get("id"), first.get("id")]),
                            market: marketName,
                            base: second,
                            quote: first
                        });
                    }

                    if (!marketStatsQueueActive) {
                        marketStatsQueueActive = true;

                        setTimeout(function () {
                            _processQueue();
                        }, 1000 * marketStatsQueueTimeout); // 2 seconds between
                    }

                    var _processQueue = function _processQueue() {
                        var currentBatch = marketStatsQueue.slice(0, marketStatsQueueLength);
                        return Promise.all(currentBatch.map(function (q) {
                            return q.promise;
                        })).then(function (results) {
                            dispatch({
                                tickers: results,
                                markets: currentBatch.map(function (q) {
                                    return q.market;
                                }),
                                bases: currentBatch.map(function (q) {
                                    return q.base;
                                }),
                                quotes: currentBatch.map(function (q) {
                                    return q.quote;
                                })
                            });
                            marketStatsQueue.splice(0, results.length);
                            if (marketStatsQueue.length === 0) {
                                marketStatsQueueActive = false;
                                return;
                            } else {
                                return _processQueue();
                            }
                        }).catch(function (err) {
                            console.log("getMarketStats error for " + marketName + ":", err);
                            if (errorCallback != null) {
                                errorCallback(err);
                            }
                        });
                    };
                }
            };
        }
    }, {
        key: "switchMarket",
        value: function switchMarket() {
            return true;
        }
    }, {
        key: "subscribeMarket",
        value: function subscribeMarket(base, quote, bucketSize, groupedOrderLimit) {
            var _this = this;

            /*
            * DataFeed will call subscribeMarket with undefined groupedOrderLimit,
            * so we keep track of the last value used and use that instead in that
            * case
            */
            if (typeof groupedOrderLimit === "undefined") groupedOrderLimit = currentGroupedOrderLimit;else currentGroupedOrderLimit = groupedOrderLimit;

            clearBatchTimeouts();
            var subID = quote.get("id") + "_" + base.get("id");
            currentMarket = base.get("id") + "_" + quote.get("id");

            var _marketUtils$isMarket = marketUtils.isMarketAsset(quote, base),
                isMarketAsset = _marketUtils$isMarket.isMarketAsset,
                marketAsset = _marketUtils$isMarket.marketAsset,
                inverted = _marketUtils$isMarket.inverted;

            var bucketCount = 200;
            // let lastLimitOrder = null;
            return function (dispatch) {
                var subscription = function subscription(marketId, subResult) {
                    /*
                    ** When switching markets rapidly we might receive sub notifications
                    ** from the previous markets, in that case disregard them
                    */
                    if (marketId !== currentMarket) {
                        return;
                    }
                    /* In the case of many market notifications arriving at the same time,
                    * we queue them in a batch here and dispatch them all at once at a frequency
                    * defined by "subBatchTime"
                    */
                    if (!dispatchSubTimeout) {
                        subBatchResults = subBatchResults.concat(subResult);

                        dispatchSubTimeout = setTimeout(function () {
                            var hasLimitOrder = false;
                            var onlyLimitOrder = true;
                            var hasFill = false;

                            // // We get two notifications for each limit order created, ignore the second one
                            // if (subResult.length === 1 && subResult[0].length === 1 && subResult[0][0] === lastLimitOrder) {
                            //     return;
                            // }

                            // Check whether the market had a fill order, and whether it only has a new limit order
                            subBatchResults.forEach(function (result) {
                                result.forEach(function (notification) {
                                    if (typeof notification === "string") {
                                        var split = notification.split(".");
                                        if (split.length >= 2 && split[1] === "7") {
                                            hasLimitOrder = true;
                                        } else {
                                            onlyLimitOrder = false;
                                        }
                                    } else {
                                        onlyLimitOrder = false;
                                        if (notification.length === 2 && notification[0] && notification[0][0] === 4) {
                                            hasFill = true;
                                        }
                                    }
                                });
                            });

                            var callPromise = null,
                                settlePromise = null;

                            // Only check for call and settle orders if either the base or quote is the CORE asset
                            if (isMarketAsset) {
                                callPromise = Apis.instance().db_api().exec("get_call_orders", [marketAsset.id, 300]);
                                settlePromise = Apis.instance().db_api().exec("get_settle_orders", [marketAsset.id, 300]);
                            }

                            var groupedOrdersBidsPromise = [];
                            var groupedOrdersAsksPromise = [];
                            if (currentGroupLimit !== 0) {
                                groupedOrdersBidsPromise = Apis.instance().orders_api().exec("get_grouped_limit_orders", [base.get("id"), quote.get("id"), currentGroupLimit, // group
                                null, // price start
                                100 // limit must not exceed 101
                                ]);
                                groupedOrdersAsksPromise = Apis.instance().orders_api().exec("get_grouped_limit_orders", [quote.get("id"), base.get("id"), currentGroupLimit, // group
                                null, // price start
                                100 // limit must not exceed 101
                                ]);
                            }

                            var startDate = new Date();
                            var startDate2 = new Date();
                            var startDate3 = new Date();
                            var endDate = new Date();
                            startDate = new Date(startDate.getTime() - bucketSize * bucketCount * 1000);
                            startDate2 = new Date(startDate2.getTime() - bucketSize * bucketCount * 2000);
                            startDate3 = new Date(startDate3.getTime() - bucketSize * bucketCount * 3000);
                            endDate.setDate(endDate.getDate() + 1);

                            subBatchResults = subBatchResults.clear();
                            dispatchSubTimeout = null;
                            // Selectively call the different market api calls depending on the type
                            // of operations received in the subscription update
                            Promise.all([Apis.instance().db_api().exec("get_limit_orders", [base.get("id"), quote.get("id"), 300]), onlyLimitOrder ? null : callPromise, onlyLimitOrder ? null : settlePromise, !hasFill ? null : Apis.instance().history_api().exec("get_market_history", [base.get("id"), quote.get("id"), bucketSize, startDate.toISOString().slice(0, -5), endDate.toISOString().slice(0, -5)]), !hasFill ? null : Apis.instance().history_api().exec("get_fill_order_history", [base.get("id"), quote.get("id"), 200]), !hasFill ? null : Apis.instance().history_api().exec("get_market_history", [base.get("id"), quote.get("id"), bucketSize, startDate2.toISOString().slice(0, -5), startDate.toISOString().slice(0, -5)]), !hasFill ? null : Apis.instance().history_api().exec("get_market_history", [base.get("id"), quote.get("id"), bucketSize, startDate3.toISOString().slice(0, -5), startDate2.toISOString().slice(0, -5)]), Apis.instance().db_api().exec("get_ticker", [base.get("id"), quote.get("id")]), groupedOrdersBidsPromise, groupedOrdersAsksPromise]).then(function (results) {
                                var data1 = results[5] || [];
                                var data2 = results[6] || [];
                                dispatch({
                                    limits: results[0],
                                    calls: !onlyLimitOrder && results[1],
                                    settles: !onlyLimitOrder && results[2],
                                    price: hasFill && data1.concat(data2.concat(results[3])),
                                    history: hasFill && results[4],
                                    market: subID,
                                    base: base,
                                    quote: quote,
                                    inverted: inverted,
                                    ticker: results[7],
                                    groupedOrdersBids: results[8],
                                    groupedOrdersAsks: results[9]
                                });
                            }).catch(function (error) {
                                console.log("Error in MarketsActions.subscribeMarket: ", error);
                            });
                        }, subBatchTime);
                    } else {
                        subBatchResults = subBatchResults.concat(subResult);
                    }
                };

                if (!subs[subID] || currentBucketSize !== bucketSize || currentGroupLimit !== groupedOrderLimit) {
                    dispatch({ switchMarket: true });
                    currentBucketSize = bucketSize;
                    currentGroupLimit = groupedOrderLimit;
                    var callPromise = null,
                        settlePromise = null;

                    if (isMarketAsset) {
                        callPromise = Apis.instance().db_api().exec("get_call_orders", [marketAsset.id, 300]);
                        settlePromise = Apis.instance().db_api().exec("get_settle_orders", [marketAsset.id, 300]);
                    }

                    var groupedOrdersBidsPromise = [];
                    var groupedOrdersAsksPromise = [];
                    if (currentGroupLimit !== 0) {
                        groupedOrdersBidsPromise = Apis.instance().orders_api().exec("get_grouped_limit_orders", [base.get("id"), quote.get("id"), currentGroupLimit, // group
                        null, // price start
                        100 // limit must not exceed 101
                        ]);
                        groupedOrdersAsksPromise = Apis.instance().orders_api().exec("get_grouped_limit_orders", [quote.get("id"), base.get("id"), currentGroupLimit, // group
                        null, // price start
                        100 // limit must not exceed 101
                        ]);
                    }

                    var startDate = new Date();
                    var startDate2 = new Date();
                    var startDate3 = new Date();
                    var endDate = new Date();
                    startDate = new Date(startDate.getTime() - bucketSize * bucketCount * 1000);
                    startDate2 = new Date(startDate2.getTime() - bucketSize * bucketCount * 2000);
                    startDate3 = new Date(startDate3.getTime() - bucketSize * bucketCount * 3000);
                    endDate.setDate(endDate.getDate() + 1);
                    if (__DEV__) console.time("Fetch market data");

                    return new Promise(function (resolve, reject) {
                        Promise.all([Apis.instance().db_api().exec("subscribe_to_market", [subscription.bind(_this, base.get("id") + "_" + quote.get("id")), base.get("id"), quote.get("id")]), Apis.instance().db_api().exec("get_limit_orders", [base.get("id"), quote.get("id"), 300]), callPromise, settlePromise, Apis.instance().history_api().exec("get_market_history", [base.get("id"), quote.get("id"), bucketSize, startDate.toISOString().slice(0, -5), endDate.toISOString().slice(0, -5)]), Apis.instance().history_api().exec("get_market_history_buckets", []), Apis.instance().history_api().exec("get_fill_order_history", [base.get("id"), quote.get("id"), 200]), Apis.instance().history_api().exec("get_market_history", [base.get("id"), quote.get("id"), bucketSize, startDate2.toISOString().slice(0, -5), startDate.toISOString().slice(0, -5)]), Apis.instance().history_api().exec("get_market_history", [base.get("id"), quote.get("id"), bucketSize, startDate3.toISOString().slice(0, -5), startDate2.toISOString().slice(0, -5)]), Apis.instance().db_api().exec("get_ticker", [base.get("id"), quote.get("id")]), groupedOrdersBidsPromise, groupedOrdersAsksPromise]).then(function (results) {
                            var data1 = results[8] || [];
                            var data2 = results[7] || [];
                            subs[subID] = subscription;
                            if (__DEV__) console.timeEnd("Fetch market data");
                            dispatch({
                                limits: results[1],
                                calls: results[2],
                                settles: results[3],
                                price: data1.concat(data2.concat(results[4])),
                                buckets: results[5],
                                history: results[6],
                                market: subID,
                                base: base,
                                quote: quote,
                                inverted: inverted,
                                ticker: results[9],
                                init: true,
                                resolve: resolve,
                                groupedOrdersBids: results[10],
                                groupedOrdersAsks: results[11]
                            });
                        }).catch(function (error) {
                            console.log("Error in MarketsActions.subscribeMarket: ", error);
                            reject(error);
                        });
                    });
                }
                return Promise.resolve(true);
            };
        }

        // clearMarket() {
        //     clearBatchTimeouts();
        //     return true;
        // }

    }, {
        key: "unSubscribeMarket",
        value: function unSubscribeMarket(quote, base) {
            var subID = quote + "_" + base;
            clearBatchTimeouts();
            return function (dispatch) {
                if (subs[subID]) {
                    return new Promise(function (resolve, reject) {
                        Apis.instance().db_api().exec("unsubscribe_from_market", [subs[subID], quote, base]).then(function () {
                            delete subs[subID];
                            dispatch({ unSub: true, resolve: resolve });
                        }).catch(function (error) {
                            subs[subID] = true;
                            console.log("Error in MarketsActions.unSubscribeMarket: ", error);
                            dispatch({ unSub: false, market: subID });
                            reject(error);
                        });
                    });
                }
                return Promise.resolve(true);
            };
        }
    }, {
        key: "createLimitOrder",
        value: function createLimitOrder(account, sellAmount, sellAsset, buyAmount, buyAsset, expiration, isFillOrKill, fee_asset_id) {
            var tr = WalletApi.new_transaction();

            var feeAsset = ChainStore.getAsset(fee_asset_id);
            if (feeAsset.getIn(["options", "core_exchange_rate", "base", "asset_id"]) === "1.3.0" && feeAsset.getIn(["options", "core_exchange_rate", "quote", "asset_id"]) === "1.3.0") {
                fee_asset_id = "1.3.0";
            }

            tr.add_type_operation("limit_order_create", {
                fee: {
                    amount: 0,
                    asset_id: fee_asset_id
                },
                seller: account,
                amount_to_sell: {
                    amount: sellAmount,
                    asset_id: sellAsset.get("id")
                },
                min_to_receive: {
                    amount: buyAmount,
                    asset_id: buyAsset.get("id")
                },
                expiration: expiration,
                fill_or_kill: isFillOrKill
            });

            return function (dispatch) {
                return WalletDb.process_transaction(tr, null, true).then(function (result) {
                    dispatch(true);
                    return true;
                }).catch(function (error) {
                    console.log("order error:", error);
                    dispatch({ error: error });
                    return { error: error };
                });
            };
        }
    }, {
        key: "createLimitOrder2",
        value: function createLimitOrder2(orderOrOrders) {
            var tr = WalletApi.new_transaction();

            var orders = [];

            // let feeAsset = ChainStore.getAsset(fee_asset_id);
            // if( feeAsset.getIn(["options", "core_exchange_rate", "base", "asset_id"]) === "1.3.0" && feeAsset.getIn(["options", "core_exchange_rate", "quote", "asset_id"]) === "1.3.0" ) {
            //     fee_asset_id = "1.3.0";
            // }

            if (Array.isArray(orderOrOrders)) {
                orders = orderOrOrders.map(function (order) {
                    return order.toObject();
                });
            } else {
                orders.push(orderOrOrders.toObject());
            }

            orders.forEach(function (order) {
                tr.add_type_operation("limit_order_create", order);
            });

            return WalletDb.process_transaction(tr, null, true).then(function (result) {
                return true;
            }).catch(function (error) {
                console.log("order error:", error);
                return { error: error };
            });
        }
    }, {
        key: "createPredictionShort",
        value: function createPredictionShort(order, collateral, account, sellAmount, sellAsset, buyAmount, collateralAmount, buyAsset, expiration, isFillOrKill) {
            var fee_asset_id = arguments.length > 10 && arguments[10] !== undefined ? arguments[10] : "1.3.0";

            var tr = WalletApi.new_transaction();

            // Set the fee asset to use
            fee_asset_id = accountUtils.getFinalFeeAsset(order.seller, "call_order_update", order.fee.asset_id);

            order.setExpiration();

            tr.add_type_operation("call_order_update", {
                fee: {
                    amount: 0,
                    asset_id: fee_asset_id
                },
                funding_account: order.seller,
                delta_collateral: collateral.toObject(),
                delta_debt: order.amount_for_sale.toObject(),
                expiration: order.getExpiration()
            });

            tr.add_type_operation("limit_order_create", order.toObject());

            return WalletDb.process_transaction(tr, null, true).then(function (result) {
                return true;
            }).catch(function (error) {
                console.log("order error:", error);
                return { error: error };
            });
        }
    }, {
        key: "cancelLimitOrder",
        value: function cancelLimitOrder(accountID, orderID) {
            // Set the fee asset to use
            var fee_asset_id = accountUtils.getFinalFeeAsset(accountID, "limit_order_cancel");

            var tr = WalletApi.new_transaction();
            tr.add_type_operation("limit_order_cancel", {
                fee: {
                    amount: 0,
                    asset_id: fee_asset_id
                },
                fee_paying_account: accountID,
                order: orderID
            });
            return WalletDb.process_transaction(tr, null, true).catch(function (error) {
                console.log("cancel error:", error);
            });
        }
    }, {
        key: "cancelLimitOrders",
        value: function cancelLimitOrders(accountID, orderIDs) {
            var fee_asset_id = accountUtils.getFinalFeeAsset(accountID, "limit_order_cancel");

            var tr = WalletApi.new_transaction();
            orderIDs.forEach(function (id) {
                tr.add_type_operation("limit_order_cancel", {
                    fee: {
                        amount: 0,
                        asset_id: fee_asset_id
                    },
                    fee_paying_account: accountID,
                    order: id
                });
            });

            return WalletDb.process_transaction(tr, null, true).catch(function (error) {
                console.log("cancel error:", error);
            });
        }
    }, {
        key: "cancelLimitOrderSuccess",
        value: function cancelLimitOrderSuccess(ids) {
            return function (dispatch) {
                /* In the case of many cancel orders being issued at the same time,
                * we batch them here and dispatch them all at once at a frequency
                * defined by "dispatchCancelTimeout"
                */
                if (!dispatchCancelTimeout) {
                    cancelBatchIDs = cancelBatchIDs.concat(ids);
                    dispatchCancelTimeout = setTimeout(function () {
                        dispatch(cancelBatchIDs.toJS());
                        dispatchCancelTimeout = null;
                        cancelBatchIDs = cancelBatchIDs.clear();
                    }, cancelBatchTime);
                } else {
                    cancelBatchIDs = cancelBatchIDs.concat(ids);
                }
            };
        }
    }, {
        key: "closeCallOrderSuccess",
        value: function closeCallOrderSuccess(orderID) {
            return orderID;
        }
    }, {
        key: "callOrderUpdate",
        value: function callOrderUpdate(order) {
            return order;
        }
    }, {
        key: "feedUpdate",
        value: function feedUpdate(asset) {
            return asset;
        }
    }, {
        key: "settleOrderUpdate",
        value: function settleOrderUpdate(asset) {
            return function (dispatch) {
                Apis.instance().db_api().exec("get_settle_orders", [asset, 100]).then(function (result) {
                    dispatch({
                        settles: result
                    });
                });
            };
        }
    }, {
        key: "toggleStars",
        value: function toggleStars() {
            return true;
        }
    }, {
        key: "getTrackedGroupsConfig",
        value: function getTrackedGroupsConfig() {
            return function (dispatch) {
                Apis.instance().orders_api().exec("get_tracked_groups", []).then(function (result) {
                    dispatch({
                        trackedGroupsConfig: result
                    });
                }).catch(function (err) {
                    console.log("current node api does not support grouped orders.");
                    dispatch({
                        trackedGroupsConfig: []
                    });
                });
            };
        }
    }, {
        key: "changeCurrentGroupLimit",
        value: function changeCurrentGroupLimit(groupLimit) {
            return groupLimit;
        }
    }]);

    return MarketsActions;
}();

var actions = alt.createActions(MarketsActions);

// helper method, not actually dispatching anything
var marketStatsIntervals = {};

actions.clearMarketStatsInInterval = function (key) {
    if (marketStatsIntervals[key]) {
        clearInterval(marketStatsIntervals[key]);
        delete marketStatsIntervals[key];
    }
};

actions.getMarketStatsInterval = function (intervalTime, base, quote) {
    var refresh = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;

    actions.getMarketStats(base, quote, refresh);

    var _marketUtils$getMarke2 = marketUtils.getMarketName(base, quote),
        marketName = _marketUtils$getMarke2.marketName;

    if (marketStatsIntervals[marketName]) {
        return actions.clearMarketStatsInInterval.bind(this, marketName);
    }
    marketStatsIntervals[marketName] = setInterval(function () {
        actions.getMarketStats(base, quote, refresh, function () {
            actions.clearMarketStatsInInterval(base, quote);
        });
    }, intervalTime);
    return actions.clearMarketStatsInInterval.bind(this, marketName);
};

export default actions;