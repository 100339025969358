import React from "react";
import Translate from "react-translate-component";
import counterpart from "counterpart";
import { getGatewayStatusByAsset } from "common/gatewayUtils";
import { Link } from "react-router-dom";
import { Select, Icon } from "bitshares-ui-style-guide";
import utils from "common/utils";

function _getCoinToGatewayMapping() {
    var boolCheck = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "depositAllowed";

    var coinToGatewayMapping = {};

    this.props.backedCoins.forEach(function (gateway, gatewayName) {
        gateway.forEach(function (coin) {
            // let symbol = coin.backingCoinType || coin.symbol;
            var symbolOnly = coin.symbol.split(".").pop();

            if (!coinToGatewayMapping[symbolOnly]) coinToGatewayMapping[symbolOnly] = [];

            if (coin[boolCheck] && (gateway === "XBTSX" ? coin.isAvailable : true)) coinToGatewayMapping[symbolOnly].push(gatewayName);
        });
    });

    return coinToGatewayMapping;
}

function _getNumberAvailableGateways() {
    var _state = this.state,
        gatewayStatus = _state.gatewayStatus,
        selectedAsset = _state.selectedAsset;


    var nAvailableGateways = 0;
    for (var g in gatewayStatus) {
        this.props.backedCoins.get(g.toUpperCase(), []).find(function (c) {
            if ((selectedAsset === c.backingCoinType || selectedAsset === c.backingCoin) && c.depositAllowed || c.isAvailable) {
                nAvailableGateways++;
            }
        });
    }

    return nAvailableGateways;
}

function _onAssetSelected(selectedAsset) {
    var boolCheck = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "depositAllowed";
    var selectGatewayFn = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;

    var _ref = this.props || {},
        balances = _ref.balances,
        assets = _ref.assets; //Function must be bound on calling component and these props must be passed to calling component


    var gatewayStatus = getGatewayStatusByAsset.call(this, selectedAsset, boolCheck);

    var selectedGateway = this.state.selectedGateway || null;
    var balancesByAssetAndGateway = {};

    if (balances && assets) {
        balances.forEach(function (balance) {
            if (!!balance && !!balance.toJS) {
                var asset = assets.get(balance.get("asset_type"));

                if (asset) {
                    var symbolSplit = asset.symbol.split(".");

                    if (symbolSplit.length == 2) {
                        var symbol = symbolSplit[1];
                        var _gateway = symbolSplit[0];

                        if (!balancesByAssetAndGateway[symbol]) balancesByAssetAndGateway[symbol] = {};
                        balancesByAssetAndGateway[symbol][_gateway] = balance.get("balance");
                    }
                }
            }
        });
    }

    var coinToGatewayMapping = this.state.coinToGatewayMapping;

    if (selectedAsset != this.state.selectedAsset && coinToGatewayMapping && coinToGatewayMapping[selectedAsset]) {
        var gateways = coinToGatewayMapping[selectedAsset];
        //let gateways = ["XBTSX"];

        console.log("gateways", gateways);
        if (gateways.length && !selectGatewayFn) {
            //Default gateway selection logic is to pick the gateway with the highest balance, or default to the first available
            if (balancesByAssetAndGateway[selectedAsset]) {
                var greatestBalance = null;
                var greatestBalanceGateway = null;
                for (var gateway in balancesByAssetAndGateway[selectedAsset]) {
                    var balance = balancesByAssetAndGateway[selectedAsset][gateway];

                    if (!greatestBalance) greatestBalance = balance;
                    if (!greatestBalanceGateway) greatestBalanceGateway = gateway;
                }

                selectedGateway = gateways[gateways.indexOf(greatestBalanceGateway)] || gateways[0];
            } else {
                selectedGateway = gateways[0];
            }
        } else if (gateways.length && selectGatewayFn) {
            selectedGateway = selectGatewayFn(coinToGatewayMapping[selectedAsset], balancesByAssetAndGateway[selectedAsset]);
        }
    }

    this.setState({
        selectedAsset: selectedAsset,
        selectedGateway: selectedGateway,
        gatewayStatus: gatewayStatus
    });

    return { selectedAsset: selectedAsset, selectedGateway: selectedGateway };
}

function gatewaySelector(args) {
    var selectedGateway = args.selectedGateway,
        gatewayStatus = args.gatewayStatus,
        nAvailableGateways = args.nAvailableGateways,
        error = args.error,
        onGatewayChanged = args.onGatewayChanged,
        _args$selectedAsset = args.selectedAsset,
        selectedAsset = _args$selectedAsset === undefined ? null : _args$selectedAsset,
        _args$balances = args.balances,
        balances = _args$balances === undefined ? null : _args$balances,
        _args$assets = args.assets,
        assets = _args$assets === undefined ? null : _args$assets;


    var balancesByAssetAndGateway = {};
    if (balances && assets) {
        balances.forEach(function (balance) {
            if (!!balance && !!balance.toJS) {
                var asset = assets.get(balance.get("asset_type"));

                if (asset) {
                    var symbolSplit = asset.symbol.split(".");

                    if (symbolSplit.length === 2) {
                        var symbol = symbolSplit[1];
                        var gateway = symbolSplit[0];

                        if (!balancesByAssetAndGateway[symbol]) balancesByAssetAndGateway[symbol] = {};
                        balancesByAssetAndGateway[symbol][gateway] = [balance.get("balance"), asset];
                    }
                }
            }
        });
    }

    var supportLink = !!selectedGateway && !!gatewayStatus[selectedGateway] ? "/help/gateways/" + gatewayStatus[selectedGateway].name.toLowerCase().replace("-", "") : null;

    var gateways = [];
    Object.keys(gatewayStatus).map(function (key) {
        gateways.push(gatewayStatus[key]);
        // Set to full name to work with <Select>
        if (gatewayStatus[key].id === selectedGateway) {
            selectedGateway = gatewayStatus[key].name;
        }
    });

    gateways.sort(function (a, b) {
        if (a.name > b.name) return 1;else if (a.name < b.name) return -1;else return 0;
    });

    return React.createElement(
        "div",
        null,
        React.createElement(
            "div",
            { className: "no-margin no-padding" },
            React.createElement(
                "section",
                { className: "block-list" },
                React.createElement(
                    "label",
                    { className: "left-label" },
                    React.createElement(Translate, { content: "modal.deposit_withdraw.gateway" }),
                    selectedGateway ? React.createElement(
                        Link,
                        { to: supportLink, style: { cursor: "pointer" } },
                        "\xA0",
                        React.createElement(Icon, { type: "question-circle" })
                    ) : null,
                    React.createElement(
                        "span",
                        { className: "floatRight error-msg" },
                        !error && selectedGateway && gatewayStatus[selectedGateway] && !gatewayStatus[selectedGateway].options.enabled ? React.createElement(Translate, {
                            content: "modal.deposit_withdraw.disabled",
                            "with": {
                                gateway: gatewayStatus[selectedGateway].name
                            }
                        }) : null,
                        error ? React.createElement(Translate, { content: "modal.deposit_withdraw.wallet_error" }) : null,
                        !selectedGateway && nAvailableGateways === 0 ? React.createElement(Translate, { content: "modal.deposit_withdraw.no_gateway_available" }) : null
                    )
                ),
                React.createElement(
                    "div",
                    { className: "inline-label input-wrapper" },
                    React.createElement(
                        Select,
                        {
                            optionLabelProp: "value",
                            onChange: onGatewayChanged,
                            placeholder: counterpart.translate("modal.deposit_withdraw.select_gateway"),
                            value: selectedGateway,
                            style: { width: "100%" }
                        },
                        gateways.map(function (g) {
                            if (g.options.enabled) {
                                return React.createElement(
                                    Select.Option,
                                    {
                                        key: g.name,
                                        value: g.id
                                    },
                                    g.name,
                                    balancesByAssetAndGateway && balancesByAssetAndGateway[selectedAsset] && balancesByAssetAndGateway[selectedAsset][g.id] ? React.createElement(
                                        "span",
                                        { style: { float: "right" } },
                                        utils.format_asset(balancesByAssetAndGateway[selectedAsset][g.id][0], balancesByAssetAndGateway[selectedAsset][g.id][1])
                                    ) : null
                                );
                            }
                        })
                    )
                )
            )
        )
    );
}

export { gatewaySelector, _getNumberAvailableGateways, _onAssetSelected, _getCoinToGatewayMapping };