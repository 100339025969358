var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from "react";
import AccountStore from "stores/AccountStore";
import SettingsStore from "stores/SettingsStore";
import AltContainer from "alt-container";
import BrowserNotifications from "./BrowserNotifications";

var Wrapper = function Wrapper(props) {
    return !!props.account ? React.createElement(BrowserNotifications, props) : null;
};

var BrowserNotificationsContainer = function (_React$Component) {
    _inherits(BrowserNotificationsContainer, _React$Component);

    function BrowserNotificationsContainer() {
        _classCallCheck(this, BrowserNotificationsContainer);

        return _possibleConstructorReturn(this, (BrowserNotificationsContainer.__proto__ || Object.getPrototypeOf(BrowserNotificationsContainer)).apply(this, arguments));
    }

    _createClass(BrowserNotificationsContainer, [{
        key: "render",
        value: function render() {
            return React.createElement(
                AltContainer,
                {
                    stores: [AccountStore],
                    inject: {
                        account: function account() {
                            return AccountStore.getState().currentAccount;
                        },
                        settings: function settings() {
                            return SettingsStore.getState().settings;
                        }
                    }
                },
                React.createElement(Wrapper, null)
            );
        }
    }]);

    return BrowserNotificationsContainer;
}(React.Component);

export default BrowserNotificationsContainer;