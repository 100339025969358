import React from "react";
import TranslateWithLinks from "../../Utility/TranslateWithLinks";
import MemoText from "../MemoText";

export var AssetIssue = function AssetIssue(_ref) {
    var op = _ref.op,
        changeColor = _ref.changeColor,
        fromComponent = _ref.fromComponent;

    changeColor("warning");
    var memoComponent = void 0;
    if (op[1].memo) {
        memoComponent = React.createElement(MemoText, { memo: op[1].memo });
    }
    op[1].asset_to_issue.amount = parseInt(op[1].asset_to_issue.amount, 10);

    return React.createElement(
        "span",
        null,
        React.createElement(TranslateWithLinks, {
            string: fromComponent === "proposed_operation" ? "proposal.asset_issue" : "operation.asset_issue",
            keys: [{
                type: "account",
                value: op[1].issuer,
                arg: "account"
            }, {
                type: "amount",
                value: op[1].asset_to_issue,
                arg: "amount"
            }, {
                type: "account",
                value: op[1].issue_to_account,
                arg: "to"
            }]
        }),
        memoComponent
    );
};