var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

import alt from "alt-instance";
import SettingsActions from "actions/SettingsActions";
import IntlActions from "actions/IntlActions";
import Immutable, { fromJS } from "immutable";
import ls from "common/localStorage";
import { Apis } from "bitsharesjs-ws";
import { settingsAPIs } from "api/apiConfig";
import { getDefaultTheme, getDefaultLogin, getMyMarketsBases, getMyMarketsQuotes, getUnits } from "branding";

var CORE_ASSET = "BTS"; // Setting this to BTS to prevent loading issues when used with BTS chain which is the most usual case currently

var STORAGE_KEY = "__graphene__";
var ss = new ls(STORAGE_KEY);

/**
 * SettingsStore takes care of maintaining user set settings values and notifies all listeners
 */

var SettingsStore = function () {
    function SettingsStore() {
        _classCallCheck(this, SettingsStore);

        this.exportPublicMethods({
            init: this.init.bind(this),
            getSetting: this.getSetting.bind(this),
            getLastBudgetObject: this.getLastBudgetObject.bind(this),
            setLastBudgetObject: this.setLastBudgetObject.bind(this),
            hasAnyPriceAlert: this.hasAnyPriceAlert.bind(this)
        });

        // bind actions to store
        this.bindListeners({
            onSetPriceAlert: SettingsActions.setPriceAlert,
            onSetExchangeLastExpiration: SettingsActions.setExchangeLastExpiration,
            onSetExchangeTutorialShown: SettingsActions.setExchangeTutorialShown,
            onChangeSetting: SettingsActions.changeSetting,
            onChangeViewSetting: SettingsActions.changeViewSetting,
            onChangeMarketDirection: SettingsActions.changeMarketDirection,
            onAddStarMarket: SettingsActions.addStarMarket,
            onRemoveStarMarket: SettingsActions.removeStarMarket,
            onClearStarredMarkets: SettingsActions.clearStarredMarkets,
            onAddWS: SettingsActions.addWS,
            onRemoveWS: SettingsActions.removeWS,
            onShowWS: SettingsActions.showWS,
            onHideWS: SettingsActions.hideWS,
            onHideAsset: SettingsActions.hideAsset,
            onHideMarket: SettingsActions.hideMarket,
            onClearSettings: SettingsActions.clearSettings,
            onSwitchLocale: IntlActions.switchLocale,
            onSetUserMarket: SettingsActions.setUserMarket,
            onUpdateLatencies: SettingsActions.updateLatencies,
            onModifyPreferedBases: SettingsActions.modifyPreferedBases,
            onUpdateUnits: SettingsActions.updateUnits,
            onHideNewsHeadline: SettingsActions.hideNewsHeadline
        });

        this.initDone = false;

        this.settings = Immutable.Map(this._getSetting());

        // deprecated to support existing code
        this.defaultSettings = Immutable.Map(this._getDefaultSetting());

        // this should be called choices, defaults is confusing
        this.defaults = this._getChoices();

        this.viewSettings = Immutable.Map(ss.get("viewSettings_v1"));
        this.marketDirections = Immutable.Map(ss.get("marketDirections"));

        this.hiddenAssets = Immutable.List(ss.get("hiddenAssets", []));
        this.hiddenMarkets = Immutable.List(ss.get("hiddenMarkets", []));

        this.apiLatencies = ss.get("apiLatencies", {});

        this.mainnet_faucet = ss.get("mainnet_faucet", settingsAPIs.DEFAULT_FAUCET);
        this.testnet_faucet = ss.get("testnet_faucet", settingsAPIs.TESTNET_FAUCET);

        this.exchange = fromJS(ss.get("exchange", {}));

        this.priceAlert = fromJS(ss.get("priceAlert", []));

        this.hiddenNewsHeadline = Immutable.List(ss.get("hiddenNewsHeadline", []));
    }

    /**
     * Returns the default selected values that the user can reset to
     * @returns dictionary
     * @private
     */


    _createClass(SettingsStore, [{
        key: "_getDefaultSetting",
        value: function _getDefaultSetting() {
            return {
                locale: "en",
                apiServer: settingsAPIs.DEFAULT_WS_NODE,
                faucet_address: settingsAPIs.DEFAULT_FAUCET,
                unit: CORE_ASSET,
                showSettles: false,
                showAssetPercent: false,
                walletLockTimeout: 60 * 10,
                themes: getDefaultTheme(),
                passwordLogin: getDefaultLogin() == "password",
                browser_notifications: {
                    allow: true,
                    additional: {
                        transferToMe: true
                    }
                },
                rememberMe: true,
                viewOnlyMode: true,
                showProposedTx: false
            };
        }

        /**
         * All possible choices for the settings
         * @returns dictionary
         * @private
         */

    }, {
        key: "_getDefaultChoices",
        value: function _getDefaultChoices() {
            return {
                locale: ["en", "zh", "fr", "ko", "de", "es", "it", "tr", "ru", "ja"],
                apiServer: settingsAPIs.WS_NODE_LIST.slice(0), // clone all default servers as configured in apiConfig.js
                unit: getUnits(),
                showSettles: [{ translate: "yes" }, { translate: "no" }],
                showProposedTx: [{ translate: "yes" }, { translate: "no" }],
                showAssetPercent: [{ translate: "yes" }, { translate: "no" }],
                themes: ["midnightTheme"],
                passwordLogin: [{ translate: "cloud_login" }, { translate: "local_wallet" }],
                browser_notifications: {
                    allow: [true, false],
                    additional: {
                        transferToMe: [true, false]
                    }
                },
                rememberMe: [true, false],
                viewOnlyMode: [{ translate: "show" }, { translate: "hide" }]
            };
        }

        /**
         * Checks if an object is actually empty (no keys or only empty keys)
         * @param object
         * @returns {boolean}
         * @private
         */

    }, {
        key: "_isEmpty",
        value: function _isEmpty(object) {
            var isEmpty = true;
            Object.keys(object).forEach(function (key) {
                if (object.hasOwnProperty(key) && object[key] !== null) isEmpty = false;
            });
            return isEmpty;
        }

        /**
         * Ensures that defauls are not stored in local storage, only changes, and when reading inserts all defaults.
         *
         * @param mode
         * @param settings
         * @param defaultSettings
         * @returns {{}}
         * @private
         */

    }, {
        key: "_replaceDefaults",
        value: function _replaceDefaults() {
            var mode = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "saving";

            var _this = this;

            var settings = arguments[1];
            var defaultSettings = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;

            if (defaultSettings == null) {
                // this method might be called recursively, so not always use the whole defaults
                defaultSettings = this._getDefaultSetting();
            }

            var excludedKeys = ["activeNode"];

            // avoid copy by reference
            var returnSettings = {};
            if (mode === "saving") {
                // remove every setting that is default
                Object.keys(settings).forEach(function (key) {
                    if (excludedKeys.includes(key)) {
                        return;
                    }
                    // must be of same type to be compatible
                    if (_typeof(settings[key]) === _typeof(defaultSettings[key])) {
                        // incompatible settings, dont store
                        if (_typeof(settings[key]) == "object") {
                            var newSetting = _this._replaceDefaults("saving", settings[key], defaultSettings[key]);
                            if (!_this._isEmpty(newSetting)) {
                                returnSettings[key] = newSetting;
                            }
                        } else if (settings[key] !== defaultSettings[key]) {
                            // only save if its not the default
                            returnSettings[key] = settings[key];
                        }
                    }
                    // all other cases are defaults, do not put the value in local storage
                });
            } else {
                Object.keys(defaultSettings).forEach(function (key) {
                    var setDefaults = false;
                    if (settings[key] !== undefined) {
                        // exists in saved settings, check value
                        if (_typeof(settings[key]) !== _typeof(defaultSettings[key])) {
                            // incompatible types, use default
                            setDefaults = true;
                        } else if (_typeof(settings[key]) == "object") {
                            // check all subkeys
                            returnSettings[key] = _this._replaceDefaults("loading", settings[key], defaultSettings[key]);
                        } else {
                            returnSettings[key] = settings[key];
                        }
                    } else {
                        setDefaults = true;
                    }
                    if (setDefaults) {
                        if (_typeof(settings[key]) == "object") {
                            // use defaults, deep copy
                            returnSettings[key] = JSON.parse(JSON.stringify(defaultSettings[key]));
                        } else {
                            returnSettings[key] = defaultSettings[key];
                        }
                    }
                });
                // copy all the rest as well
                Object.keys(settings).forEach(function (key) {
                    if (returnSettings[key] == undefined) {
                        // deep copy
                        returnSettings[key] = JSON.parse(JSON.stringify(settings[key]));
                    }
                });
            }
            return returnSettings;
        }

        /**
         * Returns the currently active settings, either default or from local storage
         * @returns {*}
         * @private
         */

    }, {
        key: "_getSetting",
        value: function _getSetting() {
            // migrate to new settings
            // - v3  defaults are stored as values which makes it impossible to react on changed defaults
            // - v4  refactored complete settings handling. defaults are no longer stored in local storage and
            //       set if not present on loading
            var support_v3_until = new Date("2018-10-20T00:00:00Z");

            if (!ss.has("settings_v4") && new Date() < support_v3_until) {
                // ensure backwards compatibility of settings version
                var settings_v3 = ss.get("settings_v3");
                if (!!settings_v3) {
                    if (settings_v3["themes"] === "olDarkTheme") {
                        settings_v3["themes"] = "midnightTheme";
                    }
                }
                this._saveSettings(settings_v3, this._getDefaultSetting());
            }

            return this._loadSettings();
        }

        /**
         * Overwrite configuration while utilizing call-by-reference
         * @param apiTarget
         * @param apiSource
         * @private
         */

    }, {
        key: "_injectApiConfiguration",
        value: function _injectApiConfiguration(apiTarget, apiSource) {
            // any defaults in the apiConfig are to be maintained!
            apiTarget.hidden = apiSource.hidden;
        }

        /**
         * Save settings to local storage after checking for defaults
         * @param settings
         * @private
         */

    }, {
        key: "_saveSettings",
        value: function _saveSettings() {
            var settings = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

            if (settings == null) {
                settings = this.settings.toJS();
            }
            ss.set("settings_v4", this._replaceDefaults("saving", settings));
        }

        /**
         * Load settings from local storage and fill in details
         * @returns {{}}
         * @private
         */

    }, {
        key: "_loadSettings",
        value: function _loadSettings() {
            var userSavedSettings = ss.get("settings_v4");
            // if (!!userSavedSettings) {
            //     console.log("User settings have been loaded:", userSavedSettings);
            // }
            return this._replaceDefaults("loading", userSavedSettings);
        }

        /**
         * Returns the currently active choices for settings, either default or from local storage
         * @returns {*}
         * @private
         */

    }, {
        key: "_getChoices",
        value: function _getChoices() {
            // default choices the user can select from
            var choices = this._getDefaultChoices();
            // get choices stored in local storage
            var savedChoices = this._ensureBackwardsCompatibilityChoices(ss.get("defaults_v1", { apiServer: [] }));

            // merge choices by hand (do not use merge as the order in the apiServer list may change)
            var mergedChoices = Object.assign({}, savedChoices);
            Object.keys(choices).forEach(function (key) {
                if (key != "apiServer") {
                    mergedChoices[key] = choices[key];
                }
            });
            mergedChoices.apiServer = this._getApiServerChoices(choices, savedChoices);
            return mergedChoices;
        }

        /**
         * Get all apiServer choices and mark the ones that are in the default choice as default
         * @param choices
         * @param savedChoices
         * @returns {string}
         * @private
         */

    }, {
        key: "_getApiServerChoices",
        value: function _getApiServerChoices(choices, savedChoices) {
            var _this2 = this;

            var apiServer = choices.apiServer.slice(0); // maintain order in apiConfig.js
            // add any apis that the user added and update changes
            savedChoices.apiServer.forEach(function (api) {
                var found = apiServer.find(function (a) {
                    return a.url == api.url;
                });
                if (!!found) {
                    _this2._injectApiConfiguration(found, api);
                } else {
                    if (!api.default) {
                        // always add personal nodes at end of existing nodes, arbitrary decision
                        apiServer.push(api);
                    }
                }
            });
            apiServer = apiServer.map(function (node) {
                var found = choices.apiServer.find(function (a) {
                    return a.url == node.url;
                });
                node.default = !!found;
                node.hidden = !!node.hidden; // make sure this flag exists
                return node;
            });
            return apiServer;
        }

        /**
         * Adjust loaded choices for backwards compatibility if any key names or values change
         * @param savedChoices
         * @returns {*}
         * @private
         */

    }, {
        key: "_ensureBackwardsCompatibilityChoices",
        value: function _ensureBackwardsCompatibilityChoices(savedChoices) {
            /* Fix for old clients after changing cn to zh */
            if (savedChoices && savedChoices.locale) {
                var cnIdx = savedChoices.locale.findIndex(function (a) {
                    return a === "cn";
                });
                if (cnIdx !== -1) savedChoices.locale[cnIdx] = "zh";
            }
            if (savedChoices && savedChoices.themes) {
                var olIdx = savedChoices.themes.findIndex(function (a) {
                    return a === "olDarkTheme";
                });
                if (olIdx !== -1) savedChoices.themes[olIdx] = "midnightTheme";
            }
            if (savedChoices && savedChoices.apiServer) {
                savedChoices.apiServer = savedChoices.apiServer.map(function (api) {
                    // might be only a string, be backwards compatible
                    if (typeof api === "string") {
                        api = {
                            url: api,
                            location: null
                        };
                    }
                    return api;
                });
            }
            return savedChoices;
        }
    }, {
        key: "init",
        value: function init() {
            var _this3 = this;

            return new Promise(function (resolve) {
                if (_this3.initDone) resolve();
                _this3.starredKey = _this3._getChainKey("markets");
                _this3.marketsKey = _this3._getChainKey("userMarkets");
                _this3.basesKey = _this3._getChainKey("preferredBases");
                // Default markets setup
                var topMarkets = {
                    markets_4018d784: getMyMarketsQuotes(),
                    markets_39f5e2ed: [
                    // TESTNET
                    "PEG.FAKEUSD", "BTWTY"]
                };

                var bases = {
                    markets_4018d784: getMyMarketsBases(),
                    markets_39f5e2ed: [
                    // TESTNET
                    "TEST"]
                };

                var coreAssets = {
                    markets_4018d784: "BTS",
                    markets_39f5e2ed: "TEST"
                };
                var coreAsset = coreAssets[_this3.starredKey] || "BTS";
                /*
                 * Update units depending on the chain, also make sure the 0 index
                 * asset is always the correct CORE asset name
                 */
                _this3.onUpdateUnits();
                _this3.defaults.unit[0] = coreAsset;

                var defaultBases = bases[_this3.starredKey] || bases.markets_4018d784;
                var storedBases = ss.get(_this3.basesKey, []);
                _this3.preferredBases = Immutable.List(storedBases.length ? storedBases : defaultBases);

                _this3.chainMarkets = topMarkets[_this3.starredKey] || [];

                var defaultMarkets = _this3._getDefaultMarkets();
                _this3.defaultMarkets = Immutable.Map(defaultMarkets);
                _this3.starredMarkets = Immutable.Map(ss.get(_this3.starredKey, []));
                _this3.userMarkets = Immutable.Map(ss.get(_this3.marketsKey, {}));

                _this3.initDone = true;
                resolve();
            });
        }
    }, {
        key: "_getDefaultMarkets",
        value: function _getDefaultMarkets() {
            var _this4 = this;

            var markets = [];

            this.preferredBases.forEach(function (base) {
                addMarkets(markets, base, _this4.chainMarkets);
            });

            function addMarkets(target, base, markets) {
                markets.filter(function (a) {
                    return a !== base;
                }).forEach(function (market) {
                    target.push([market + "_" + base, { quote: market, base: base }]);
                });
            }

            return markets;
        }
    }, {
        key: "getSetting",
        value: function getSetting(setting) {
            return this.settings.get(setting);
        }
    }, {
        key: "onChangeSetting",
        value: function onChangeSetting(payload) {
            var save = true;
            switch (payload.setting) {
                case "faucet_address":
                    if (payload.value.indexOf("testnet") === -1) {
                        this.mainnet_faucet = payload.value;
                        ss.set("mainnet_faucet", payload.value);
                    } else {
                        this.testnet_faucet = payload.value;
                        ss.set("testnet_faucet", payload.value);
                    }
                    break;

                case "walletLockTimeout":
                    ss.set("lockTimeout", payload.value);
                    break;

                case "activeNode":
                    // doesnt need to be saved in local storage
                    save = true;

                default:
                    break;
            }

            // check current settings
            if (this.settings.get(payload.setting) !== payload.value) {
                this.settings = this.settings.set(payload.setting, payload.value);
                if (save) {
                    this._saveSettings();
                }
            }
            // else {
            //     console.warn(
            //         "Trying to save unchanged value (" +
            //             payload.setting +
            //             ": " +
            //             payload.value +
            //             "), consider refactoring to avoid this"
            //     );
            // }
        }
    }, {
        key: "onChangeViewSetting",
        value: function onChangeViewSetting(payload) {
            for (var key in payload) {
                this.viewSettings = this.viewSettings.set(key, payload[key]);
            }

            ss.set("viewSettings_v1", this.viewSettings.toJS());
        }
    }, {
        key: "onChangeMarketDirection",
        value: function onChangeMarketDirection(payload) {
            for (var key in payload) {
                if (payload[key]) {
                    this.marketDirections = this.marketDirections.set(key, payload[key]);
                } else {
                    this.marketDirections = this.marketDirections.delete(key);
                }
            }
            ss.set("marketDirections", this.marketDirections.toJS());
        }
    }, {
        key: "onHideAsset",
        value: function onHideAsset(payload) {
            if (payload.id) {
                if (!payload.status) {
                    this.hiddenAssets = this.hiddenAssets.delete(this.hiddenAssets.indexOf(payload.id));
                } else {
                    this.hiddenAssets = this.hiddenAssets.push(payload.id);
                }
            }

            ss.set("hiddenAssets", this.hiddenAssets.toJS());
        }
    }, {
        key: "onHideMarket",
        value: function onHideMarket(payload) {
            if (payload.id) {
                if (!payload.status) {
                    this.hiddenMarkets = this.hiddenMarkets.delete(this.hiddenMarkets.indexOf(payload.id));
                } else {
                    this.hiddenMarkets = this.hiddenMarkets.push(payload.id);
                }
            }

            ss.set("hiddenMarkets", this.hiddenMarkets.toJS());
        }
    }, {
        key: "onAddStarMarket",
        value: function onAddStarMarket(market) {
            var marketID = market.quote + "_" + market.base;
            if (!this.starredMarkets.has(marketID)) {
                this.starredMarkets = this.starredMarkets.set(marketID, {
                    quote: market.quote,
                    base: market.base
                });

                ss.set(this.starredKey, this.starredMarkets.toJS());
            } else {
                return false;
            }
        }
    }, {
        key: "onSetUserMarket",
        value: function onSetUserMarket(payload) {
            var marketID = payload.quote + "_" + payload.base;
            if (payload.value) {
                this.userMarkets = this.userMarkets.set(marketID, {
                    quote: payload.quote,
                    base: payload.base
                });
            } else {
                this.userMarkets = this.userMarkets.delete(marketID);
            }
            ss.set(this.marketsKey, this.userMarkets.toJS());
        }
    }, {
        key: "onRemoveStarMarket",
        value: function onRemoveStarMarket(market) {
            var marketID = market.quote + "_" + market.base;

            this.starredMarkets = this.starredMarkets.delete(marketID);

            ss.set(this.starredKey, this.starredMarkets.toJS());
        }
    }, {
        key: "onClearStarredMarkets",
        value: function onClearStarredMarkets() {
            this.starredMarkets = Immutable.Map({});
            ss.set(this.starredKey, this.starredMarkets.toJS());
        }
    }, {
        key: "onAddWS",
        value: function onAddWS(ws) {
            if (typeof ws === "string") {
                ws = { url: ws, location: null };
            }
            this.defaults.apiServer.push(ws);
            ss.set("defaults_v1", this.defaults);
        }
    }, {
        key: "onRemoveWS",
        value: function onRemoveWS(index) {
            this.defaults.apiServer.splice(index, 1);
            ss.set("defaults_v1", this.defaults);
        }
    }, {
        key: "onHideWS",
        value: function onHideWS(url) {
            var node = this.defaults.apiServer.find(function (node) {
                return node.url === url;
            });
            node.hidden = true;
            ss.set("defaults_v1", this.defaults);
        }
    }, {
        key: "onShowWS",
        value: function onShowWS(url) {
            var node = this.defaults.apiServer.find(function (node) {
                return node.url === url;
            });
            node.hidden = false;
            ss.set("defaults_v1", this.defaults);
        }
    }, {
        key: "onClearSettings",
        value: function onClearSettings(resolve) {
            ss.remove("settings_v3");
            ss.remove("settings_v4");
            this.settings = this.defaultSettings;

            this._saveSettings();

            if (resolve) {
                resolve();
            }
        }
    }, {
        key: "onSwitchLocale",
        value: function onSwitchLocale(_ref) {
            var locale = _ref.locale;

            this.onChangeSetting({ setting: "locale", value: locale });
        }
    }, {
        key: "_getChainId",
        value: function _getChainId() {
            return (Apis.instance().chain_id || "4018d784").substr(0, 8);
        }
    }, {
        key: "_getChainKey",
        value: function _getChainKey(key) {
            var chainId = this._getChainId();
            return key + (chainId ? "_" + chainId.substr(0, 8) : "");
        }
    }, {
        key: "onUpdateLatencies",
        value: function onUpdateLatencies(latencies) {
            ss.set("apiLatencies", latencies);
            this.apiLatencies = latencies;
        }
    }, {
        key: "getLastBudgetObject",
        value: function getLastBudgetObject() {
            return ss.get(this._getChainKey("lastBudgetObject"), "2.13.1");
        }
    }, {
        key: "setLastBudgetObject",
        value: function setLastBudgetObject(value) {
            ss.set(this._getChainKey("lastBudgetObject"), value);
        }
    }, {
        key: "setExchangeSettings",
        value: function setExchangeSettings(key, value) {
            this.exchange = this.exchange.set(key, value);

            ss.set("exchange", this.exchange.toJS());
        }
    }, {
        key: "getPriceAlert",
        value: function getPriceAlert() {
            return this.priceAlert.toJS();
        }
    }, {
        key: "onSetPriceAlert",
        value: function onSetPriceAlert(value) {
            this.priceAlert = fromJS(value);

            ss.set("priceAlert", value);
        }
    }, {
        key: "hasAnyPriceAlert",
        value: function hasAnyPriceAlert(quoteAssetSymbol, baseAssetSymbol) {
            return this.priceAlert.some(function (priceAlert) {
                return priceAlert.get("quoteAssetSymbol") === quoteAssetSymbol && priceAlert.get("baseAssetSymbol") === baseAssetSymbol;
            });
        }
    }, {
        key: "getExchangeSettings",
        value: function getExchangeSettings(key) {
            return this.exchange.get(key);
        }
    }, {
        key: "onSetExchangeLastExpiration",
        value: function onSetExchangeLastExpiration(value) {
            this.setExchangeSettings("lastExpiration", fromJS(value));
        }
    }, {
        key: "onSetExchangeTutorialShown",
        value: function onSetExchangeTutorialShown(value) {
            this.setExchangeSettings("tutorialShown", value);
        }
    }, {
        key: "getExhchangeLastExpiration",
        value: function getExhchangeLastExpiration() {
            return this.getExchangeSettings("lastExpiration");
        }
    }, {
        key: "onModifyPreferedBases",
        value: function onModifyPreferedBases(payload) {
            if ("newIndex" in payload && "oldIndex" in payload) {
                /* Reorder */
                var current = this.preferredBases.get(payload.newIndex);
                this.preferredBases = this.preferredBases.set(payload.newIndex, this.preferredBases.get(payload.oldIndex));
                this.preferredBases = this.preferredBases.set(payload.oldIndex, current);
            } else if ("remove" in payload) {
                /* Remove */
                this.preferredBases = this.preferredBases.delete(payload.remove);
                var defaultMarkets = this._getDefaultMarkets();
                this.defaultMarkets = Immutable.Map(defaultMarkets);
            } else if ("add" in payload) {
                /* Add new */
                this.preferredBases = this.preferredBases.push(payload.add);
                var _defaultMarkets = this._getDefaultMarkets();
                this.defaultMarkets = Immutable.Map(_defaultMarkets);
            }

            ss.set(this.basesKey, this.preferredBases.toArray());
        }
    }, {
        key: "onUpdateUnits",
        value: function onUpdateUnits() {
            this.defaults.unit = getUnits();
            if (this.defaults.unit.indexOf(this.settings.get("unit")) === -1) {
                this.settings = this.settings.set("unit", this.defaults.unit[0]);
            }
        }
    }, {
        key: "onHideNewsHeadline",
        value: function onHideNewsHeadline(payload) {
            if (payload && this.hiddenNewsHeadline.indexOf(payload)) {
                this.hiddenNewsHeadline = this.hiddenNewsHeadline.push(payload);
                ss.set("hiddenNewsHeadline", this.hiddenNewsHeadline.toJS());
            }
        }
    }]);

    return SettingsStore;
}();

export default alt.createStore(SettingsStore, "SettingsStore");