var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from "react";
import PropTypes from "prop-types";

export var DecimalChecker = function (_React$Component) {
    _inherits(DecimalChecker, _React$Component);

    function DecimalChecker() {
        _classCallCheck(this, DecimalChecker);

        return _possibleConstructorReturn(this, (DecimalChecker.__proto__ || Object.getPrototypeOf(DecimalChecker)).apply(this, arguments));
    }

    _createClass(DecimalChecker, [{
        key: "getNumericEventValue",
        value: function getNumericEventValue(e) {
            var input = null;
            if (e.target.value == "" || e.target.value == null) {
                return "";
            } else if (parseFloat(e.target.value) == e.target.value) {
                input = e.target.value.trim();
            } else {
                input = parseFloat(e.target.value.trim().replace(/[^\d.-]/g, "")) || 0;
            }
            return input;
        }
    }, {
        key: "onPaste",
        value: function onPaste(e) {
            var allowNaN = this.props.allowNaN;
            var pasteValue = e.clipboardData.getData("text");
            var decimal = pasteValue.match(/\./g);
            var decimalCount = decimal ? decimal.length : 0;

            if (decimalCount > 1) e.preventDefault();
            if (!allowNaN && parseFloat(pasteValue) != pasteValue) e.preventDefault();
        }
    }, {
        key: "onKeyPress",
        value: function onKeyPress(e) {
            if (!e.nativeEvent.ctrlKey) {
                // allow copy-paste

                if (e.key === "." && e.target.value === "") e.target.value = "0";
                var nextValue = e.target.value + e.key;
                var decimal = nextValue.match(/\./g);
                var decimalCount = decimal ? decimal.length : 0;
                if (e.key === "." && decimalCount > 1) e.preventDefault();
                if (parseFloat(nextValue) != nextValue) e.preventDefault();

                if (this.props.onKeyPress) this.props.onKeyPress(e);
            }
        }
    }]);

    return DecimalChecker;
}(React.Component);
DecimalChecker.propTypes = {
    allowNaN: PropTypes.bool
};
DecimalChecker.defaultProps = {
    allowNaN: false
};