import React from "react";
import TranslateWithLinks from "../../Utility/TranslateWithLinks";

export var AssetUpdate = function AssetUpdate(_ref) {
    var op = _ref.op,
        changeColor = _ref.changeColor,
        fromComponent = _ref.fromComponent;

    changeColor("warning");

    return React.createElement(TranslateWithLinks, {
        string: fromComponent === "proposed_operation" ? "proposal.asset_update" : "operation.asset_update",
        keys: [{
            type: "account",
            value: op[1].issuer,
            arg: "account"
        }, {
            type: "asset",
            value: op[1].asset_to_update,
            arg: "asset"
        }]
    });
};