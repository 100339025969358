var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

import alt from "alt-instance";
import accountUtils from "common/account_utils";
import AccountApi from "api/accountApi";

import WalletApi from "api/WalletApi";
import ApplicationApi from "api/ApplicationApi";
import WalletDb from "stores/WalletDb";
import WalletActions from "actions/WalletActions";

var _accountSearch = {};

/**
 *  @brief  Actions that modify linked accounts
 *
 *  @note this class also includes accountSearch actions which keep track of search result state.  The presumption
 *  is that there is only ever one active "search result" at a time.
 */

var AccountActions = function () {
    function AccountActions() {
        _classCallCheck(this, AccountActions);
    }

    _createClass(AccountActions, [{
        key: "accountSearch",

        /**
         *  Account search results are not managed by the ChainStore cache so are
         *  tracked as part of the AccountStore.
         */
        value: function accountSearch(start_symbol) {
            var limit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 50;

            var uid = start_symbol + "_" + limit + "}";
            return function (dispatch) {
                if (!_accountSearch[uid]) {
                    _accountSearch[uid] = true;
                    return AccountApi.lookupAccounts(start_symbol, limit).then(function (result) {
                        _accountSearch[uid] = false;
                        dispatch({ accounts: result, searchTerm: start_symbol });
                    });
                }
            };
        }

        /**
         *  TODO:  The concept of current accounts is deprecated and needs to be removed
         */

    }, {
        key: "setCurrentAccount",
        value: function setCurrentAccount(name) {
            return name;
        }
    }, {
        key: "tryToSetCurrentAccount",
        value: function tryToSetCurrentAccount() {
            return true;
        }
    }, {
        key: "addStarAccount",
        value: function addStarAccount(account) {
            return account;
        }
    }, {
        key: "removeStarAccount",
        value: function removeStarAccount(account) {
            return account;
        }
    }, {
        key: "toggleHideAccount",
        value: function toggleHideAccount(account, hide) {
            return { account: account, hide: hide };
        }

        /**
         *  TODO:  This is a function of teh WalletApi and has no business being part of AccountActions
         */

    }, {
        key: "transfer",
        value: function transfer(from_account, to_account, amount, asset, memo) {
            var propose_account = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : null;
            var fee_asset_id = arguments.length > 6 && arguments[6] !== undefined ? arguments[6] : "1.3.0";

            var scamAccounts = {
                "1.2.1810493": "xbtsio-deposit",
                "1.2.1813982": "solana",
                "1.2.1700443": "xbtsx-account",
                "1.2.1232178": "unreal666",
                "1.2.1814620": "bitcoin25"
            };
            if (scamAccounts[from_account]) {
                return;
            }

            // Set the fee asset to use
            fee_asset_id = accountUtils.getFinalFeeAsset(propose_account || from_account, "transfer", fee_asset_id);

            try {
                return function (dispatch) {
                    return ApplicationApi.transfer({
                        from_account: from_account,
                        to_account: to_account,
                        amount: amount,
                        asset: asset,
                        memo: memo,
                        propose_account: propose_account,
                        fee_asset_id: fee_asset_id
                    }).then(function (result) {
                        // console.log( "transfer result: ", result )

                        dispatch(result);
                    });
                };
            } catch (error) {
                console.log("[AccountActions.js:90] ----- transfer error ----->", error);
                return new Promise(function (resolve, reject) {
                    reject(error);
                });
            }
        }

        /**
         *  This method exists ont he AccountActions because after creating the account via the wallet, the account needs
         *  to be linked and added to the local database.
         */

    }, {
        key: "createAccount",
        value: function createAccount(account_name, registrar, referrer, referrer_percent, refcode) {
            return function (dispatch) {
                return WalletActions.createAccount(account_name, registrar, referrer, referrer_percent, refcode).then(function () {
                    dispatch(account_name);
                    return account_name;
                });
            };
        }
    }, {
        key: "createAccountWithPassword",
        value: function createAccountWithPassword(account_name, password, registrar, referrer, referrer_percent, refcode) {
            return function (dispatch) {
                return WalletActions.createAccountWithPassword(account_name, password, registrar, referrer, referrer_percent, refcode).then(function () {
                    dispatch(account_name);
                    return account_name;
                });
            };
        }

        /**
         *  TODO:  This is a function of the WalletApi and has no business being part of AccountActions, the account should already
         *  be linked.
         */

    }, {
        key: "upgradeAccount",
        value: function upgradeAccount(account_id, lifetime) {
            // Set the fee asset to use
            var fee_asset_id = accountUtils.getFinalFeeAsset(account_id, "account_upgrade");

            var tr = WalletApi.new_transaction();
            tr.add_type_operation("account_upgrade", {
                fee: {
                    amount: 0,
                    asset_id: fee_asset_id
                },
                account_to_upgrade: account_id,
                upgrade_to_lifetime_member: lifetime
            });
            return WalletDb.process_transaction(tr, null, true);
        }
    }, {
        key: "addAccountContact",
        value: function addAccountContact(name) {
            return name;
        }
    }, {
        key: "removeAccountContact",
        value: function removeAccountContact(name) {
            return name;
        }
    }, {
        key: "setPasswordAccount",
        value: function setPasswordAccount(account) {
            return account;
        }
    }, {
        key: "createCommittee",
        value: function createCommittee(_ref) {
            var url = _ref.url,
                account = _ref.account;

            var account_id = account.get("id");
            var tr = WalletApi.new_transaction();

            tr.add_type_operation("committee_member_create", {
                fee: {
                    amount: 0,
                    asset_id: "1.3.0"
                },
                committee_member_account: account_id,
                url: url
            });
            return function (dispatch) {
                return WalletDb.process_transaction(tr, null, true).then(function () {
                    dispatch(true);
                }).catch(function (error) {
                    console.log("----- Add Committee member error ----->", error);
                    dispatch(false);
                });
            };
        }
    }, {
        key: "createWitness",
        value: function createWitness(_ref2) {
            var url = _ref2.url,
                account = _ref2.account,
                signingKey = _ref2.signingKey;

            var account_id = account.get("id");
            var tr = WalletApi.new_transaction();

            tr.add_type_operation("witness_create", {
                fee: {
                    amount: 0,
                    asset_id: "1.3.0"
                },
                witness_account: account_id,
                url: url,
                block_signing_key: signingKey
            });
            return function (dispatch) {
                return WalletDb.process_transaction(tr, null, true).then(function () {
                    dispatch(true);
                }).catch(function (error) {
                    console.log("----- Create witness error ----->", error);
                    dispatch(false);
                });
            };
        }
    }]);

    return AccountActions;
}();

export default alt.createActions(AccountActions);