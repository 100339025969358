var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from "react";
import { connect } from "alt-react";
import BlockchainStore from "stores/BlockchainStore";
import SettingsStore from "stores/SettingsStore";
import Translate from "react-translate-component";
import SettingsActions from "actions/SettingsActions";
import { Apis } from "bitsharesjs-ws";
import Icon from "./Icon/Icon";
import WebsocketAddModal from "./Settings/WebsocketAddModal";
import counterpart from "counterpart";
import AccessSettings from "./Settings/AccessSettings";

var SyncError = function (_React$Component) {
    _inherits(SyncError, _React$Component);

    function SyncError(props) {
        _classCallCheck(this, SyncError);

        var _this = _possibleConstructorReturn(this, (SyncError.__proto__ || Object.getPrototypeOf(SyncError)).call(this, props));

        _this.state = {
            isAddNodeModalVisible: false,
            isRemoveNodeModalVisible: false,
            removeNode: {
                name: null,
                url: null
            }
        };

        _this.showAddNodeModal = _this.showAddNodeModal.bind(_this);
        _this.hideAddNodeModal = _this.hideAddNodeModal.bind(_this);
        _this.showRemoveNodeModal = _this.showRemoveNodeModal.bind(_this);
        _this.hideRemoveNodeModal = _this.hideRemoveNodeModal.bind(_this);
        return _this;
    }

    _createClass(SyncError, [{
        key: "showAddNodeModal",
        value: function showAddNodeModal() {
            this.setState({
                isAddNodeModalVisible: true
            });
        }
    }, {
        key: "hideAddNodeModal",
        value: function hideAddNodeModal() {
            this.setState({
                isAddNodeModalVisible: false
            });
        }
    }, {
        key: "showRemoveNodeModal",
        value: function showRemoveNodeModal(url, name) {
            this.setState({
                isRemoveNodeModalVisible: true,
                removeNode: {
                    url: url,
                    name: name
                }
            });
        }
    }, {
        key: "hideRemoveNodeModal",
        value: function hideRemoveNodeModal() {
            this.setState({
                isRemoveNodeModalVisible: false,
                removeNode: {
                    url: null,
                    name: null
                }
            });
        }
    }, {
        key: "triggerModal",
        value: function triggerModal(e) {
            this.refs.ws_modal.show(e);
        }
    }, {
        key: "onChangeWS",
        value: function onChangeWS(e) {
            var _this2 = this;

            SettingsActions.changeSetting({
                setting: "apiServer",
                value: e.target.value
            });
            Apis.reset(e.target.value, true);
            setTimeout(function () {
                _this2.onReloadClick();
            }, 50);
        }
    }, {
        key: "onReloadClick",
        value: function onReloadClick(e) {
            if (e) {
                e.preventDefault();
            }
            if (window.electron) {
                window.location.hash = "";
                window.remote.getCurrentWindow().reload();
            } else window.location.href = __BASE_URL__;
        }
    }, {
        key: "triggerModal",
        value: function triggerModal(e) {
            var _refs$ws_modal;

            for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
                args[_key - 1] = arguments[_key];
            }

            (_refs$ws_modal = this.refs.ws_modal).show.apply(_refs$ws_modal, [e].concat(args));
        }
    }, {
        key: "render",
        value: function render() {
            var props = this.props;

            var options = props.apis.map(function (entry) {
                var onlyDescription = entry.url.indexOf("fake.automatic-selection") !== -1;
                var location = entry.location;

                if (location && (typeof location === "undefined" ? "undefined" : _typeof(location)) === "object" && "translate" in location) location = counterpart.translate(location.translate);

                return React.createElement(
                    "option",
                    { key: entry.url, value: entry.url },
                    location || entry.url,
                    " ",
                    !onlyDescription && location ? "(" + entry.url + ")" : null
                );
            });

            return React.createElement(
                "div",
                { className: "grid-frame vertical" },
                React.createElement(
                    "div",
                    {
                        className: "grid-container text-center",
                        style: {
                            padding: "5rem 10% 0 10%",
                            maxWidth: "100%",
                            overflowY: "auto",
                            margin: "0 !important"
                        }
                    },
                    React.createElement(
                        "h2",
                        null,
                        React.createElement(Translate, { content: "sync_fail.title" })
                    ),
                    React.createElement("br", null),
                    React.createElement(
                        "p",
                        { style: { marginBottom: 0 } },
                        React.createElement(Translate, { content: "sync_fail.sub_text_1" })
                    ),
                    React.createElement(Icon, { name: "clock", title: "icons.clock", size: "5x" }),
                    React.createElement(
                        "p",
                        null,
                        React.createElement(Translate, { unsafe: true, content: "sync_fail.sub_text_2" })
                    ),
                    React.createElement("hr", null),
                    React.createElement(AccessSettings, {
                        nodes: props.apis,
                        onChange: this.onChangeWS.bind(this),
                        showAddNodeModal: this.showAddNodeModal,
                        showRemoveNodeModal: this.showRemoveNodeModal
                    })
                ),
                React.createElement(WebsocketAddModal, {
                    removeNode: this.state.removeNode,
                    isAddNodeModalVisible: this.state.isAddNodeModalVisible,
                    isRemoveNodeModalVisible: this.state.isRemoveNodeModalVisible,
                    onAddNodeClose: this.hideAddNodeModal,
                    onRemoveNodeClose: this.hideRemoveNodeModal,
                    apis: props.apis,
                    api: props.apiServer
                })
            );
        }
    }]);

    return SyncError;
}(React.Component);

SyncError = connect(SyncError, {
    listenTo: function listenTo() {
        return [BlockchainStore, SettingsStore];
    },
    getProps: function getProps() {
        return {
            rpc_connection_status: BlockchainStore.getState().rpc_connection_status,
            apis: SettingsStore.getState().defaults.apiServer,
            apiServer: SettingsStore.getState().settings.get("apiServer"),
            defaultConnection: SettingsStore.getState().defaultSettings.get("apiServer"),
            apiLatencies: SettingsStore.getState().apiLatencies
        };
    }
});

export default SyncError;