import React from "react";
import TranslateWithLinks from "../../Utility/TranslateWithLinks";

export var AssetSettle = function AssetSettle(_ref) {
    var op = _ref.op,
        result = _ref.result,
        changeColor = _ref.changeColor,
        fromComponent = _ref.fromComponent;

    changeColor("warning");
    if (fromComponent === "proposed_operation") {
        return React.createElement(
            "span",
            null,
            React.createElement(TranslateWithLinks, {
                string: "proposal.asset_settle",
                keys: [{
                    type: "account",
                    value: op[1].account,
                    arg: "account"
                }, {
                    type: "amount",
                    value: op[1].amount,
                    arg: "amount"
                }]
            })
        );
    } else {
        var baseAmount = op[1].amount;
        var instantSettleCode = 2;
        if (result && result[0] == instantSettleCode) {
            var quoteAmount = result[1];
            return React.createElement(
                "span",
                null,
                React.createElement(TranslateWithLinks, {
                    string: "operation.asset_settle_instant",
                    keys: [{
                        type: "account",
                        value: op[1].account,
                        arg: "account"
                    }, {
                        type: "amount",
                        value: baseAmount,
                        arg: "amount"
                    }, {
                        type: "price",
                        value: {
                            base: baseAmount,
                            quote: quoteAmount
                        },
                        arg: "price"
                    }]
                })
            );
        } else {
            return React.createElement(
                "span",
                null,
                React.createElement(TranslateWithLinks, {
                    string: "operation.asset_settle",
                    keys: [{
                        type: "account",
                        value: op[1].account,
                        arg: "account"
                    }, {
                        type: "amount",
                        value: op[1].amount,
                        arg: "amount"
                    }]
                })
            );
        }
    }
};