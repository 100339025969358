var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from "react";
import { Input } from "bitshares-ui-style-guide";
import { DecimalChecker } from "../Utility/DecimalChecker";
import utils from "common/utils";

var ExchangeInput = function (_DecimalChecker) {
    _inherits(ExchangeInput, _DecimalChecker);

    function ExchangeInput() {
        _classCallCheck(this, ExchangeInput);

        return _possibleConstructorReturn(this, (ExchangeInput.__proto__ || Object.getPrototypeOf(ExchangeInput)).call(this));
    }

    _createClass(ExchangeInput, [{
        key: "componentWillReceiveProps",
        value: function componentWillReceiveProps(np) {
            if (this.props.value && !np.value) {
                this.refs.input.value = "";
            }
        }
    }, {
        key: "render",
        value: function render() {
            var _props = this.props,
                allowNaN = _props.allowNaN,
                value = _props.value;


            if (typeof value === "undefined") {
                value = "";
            } else {
                value = utils.toFixedString(value);
            }

            var props = Object.assign({}, this.props, { value: value });

            return React.createElement(Input, _extends({
                ref: "input",
                type: "text"
            }, props, {
                onPaste: this.onPaste.bind(this),
                onKeyPress: this.onKeyPress.bind(this)
            }));
        }
        /*
        render() {
            // allowNaN is no valid prop for Input, remove
            var {allowNaN, ...other} = this.props;
            return (
                <Input
                    ref="input"
                    type="text"
                    {...other}
                    onPaste={this.props.onPaste || this.onPaste.bind(this)}
                    onKeyPress={this.onKeyPress.bind(this)}
                />
            );
        }
        */

    }]);

    return ExchangeInput;
}(DecimalChecker);

export default ExchangeInput;