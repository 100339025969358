var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

import alt from "alt-instance";
import IntlActions from "actions/IntlActions";
import SettingsActions from "actions/SettingsActions";
import counterpart from "counterpart";
var locale_en = require("assets/locales/locale-en.json");
import ls from "common/localStorage";
var ss = new ls("__graphene__");

counterpart.registerTranslations("en", locale_en);
counterpart.setFallbackLocale("en");

import { addLocaleData } from "react-intl";

import localeCodes from "assets/locales";
var _iteratorNormalCompletion = true;
var _didIteratorError = false;
var _iteratorError = undefined;

try {
    for (var _iterator = localeCodes[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var localeCode = _step.value;

        addLocaleData(require("react-intl/locale-data/" + localeCode));
    }
} catch (err) {
    _didIteratorError = true;
    _iteratorError = err;
} finally {
    try {
        if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
        }
    } finally {
        if (_didIteratorError) {
            throw _iteratorError;
        }
    }
}

var IntlStore = function () {
    function IntlStore() {
        _classCallCheck(this, IntlStore);

        var storedSettings = ss.get("settings_v4", {});
        if (storedSettings.locale === undefined) {
            storedSettings.locale = "en";
        }
        this.currentLocale = storedSettings.locale;

        this.locales = ["en"];
        this.localesObject = { en: locale_en };

        this.bindListeners({
            onSwitchLocale: IntlActions.switchLocale,
            onGetLocale: IntlActions.getLocale,
            onClearSettings: SettingsActions.clearSettings
        });
    }

    _createClass(IntlStore, [{
        key: "hasLocale",
        value: function hasLocale(locale) {
            return this.locales.indexOf(locale) !== -1;
        }
    }, {
        key: "getCurrentLocale",
        value: function getCurrentLocale() {
            return this.currentLocale;
        }
    }, {
        key: "onSwitchLocale",
        value: function onSwitchLocale(_ref) {
            var locale = _ref.locale,
                localeData = _ref.localeData;

            switch (locale) {
                case "en":
                    counterpart.registerTranslations("en", this.localesObject.en);
                    break;

                default:
                    counterpart.registerTranslations(locale, localeData);
                    break;
            }

            counterpart.setLocale(locale);
            this.currentLocale = locale;
        }
    }, {
        key: "onGetLocale",
        value: function onGetLocale(locale) {
            if (this.locales.indexOf(locale) === -1) {
                this.locales.push(locale);
            }
        }
    }, {
        key: "onClearSettings",
        value: function onClearSettings() {
            this.onSwitchLocale({ locale: "en" });
        }
    }]);

    return IntlStore;
}();

export default alt.createStore(IntlStore, "IntlStore");