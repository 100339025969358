import { ChainConfig } from "bitsharesjs-ws";

export function backupName(walletName) {
    var date = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : new Date();

    var name = walletName;
    var address_prefix = ChainConfig.address_prefix.toLowerCase();
    if (name.indexOf(address_prefix) !== 0) name = address_prefix + "_" + name;

    var month = date.getMonth() + 1;
    var day = date.getDate();
    var stampedName = name + "_" + date.getFullYear() + (month >= 10 ? month : "0" + month) + (day >= 10 ? day : "0" + day);

    name = stampedName + ".bin";
    return name;
}