var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from "react";
import { FormattedNumber } from "react-intl";
import utils from "common/utils";
import AssetWrapper from "./AssetWrapper";
import AltContainer from "alt-container";
import SettingsStore from "stores/SettingsStore";
import SettingsActions from "actions/SettingsActions";
import Popover from "react-popover";
import Translate from "react-translate-component";
import AssetName from "./AssetName";
import Pulsate from "./Pulsate";
import marketUtils from "common/market_utils";
import { Asset, Price } from "common/MarketClasses";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Tooltip } from "bitshares-ui-style-guide";

/**
 *  Given an amount and an asset, render it with proper precision
 *
 *  Expected Properties:
 *     base_asset:  asset id, which will be fetched from the
 *     base_amount: the ammount of asset
 *     quote_asset:
 *     quote_amount: the ammount of asset
 *
 */

var FormattedPrice = function (_React$Component) {
    _inherits(FormattedPrice, _React$Component);

    function FormattedPrice(props) {
        _classCallCheck(this, FormattedPrice);

        var _this = _possibleConstructorReturn(this, (FormattedPrice.__proto__ || Object.getPrototypeOf(FormattedPrice)).call(this, props));

        var _marketUtils$getMarke = marketUtils.getMarketName(_this.props.base_asset, _this.props.quote_asset),
            marketName = _marketUtils$getMarke.marketName,
            first = _marketUtils$getMarke.first,
            second = _marketUtils$getMarke.second;

        _this.state = { isPopoverOpen: false, marketName: marketName, first: first, second: second };
        _this.togglePopover = _this.togglePopover.bind(_this);
        _this.closePopover = _this.closePopover.bind(_this);
        return _this;
    }

    _createClass(FormattedPrice, [{
        key: "componentWillReceiveProps",
        value: function componentWillReceiveProps(np) {
            if (np.base_asset !== this.props.base_asset || np.quote_asset !== this.props.quote_asset) {
                var _marketUtils$getMarke2 = marketUtils.getMarketName(np.base_asset, np.quote_asset),
                    marketName = _marketUtils$getMarke2.marketName,
                    first = _marketUtils$getMarke2.first,
                    second = _marketUtils$getMarke2.second;

                this.setState({ marketName: marketName, first: first, second: second });
            }
        }
    }, {
        key: "togglePopover",
        value: function togglePopover(e) {
            e.preventDefault();
            this.setState({ isPopoverOpen: !this.state.isPopoverOpen });
        }
    }, {
        key: "closePopover",
        value: function closePopover() {
            this.setState({ isPopoverOpen: false });
        }
    }, {
        key: "onFlip",
        value: function onFlip() {
            var setting = {};

            setting[this.state.marketName] = !this.props.marketDirections.get(this.state.marketName);
            SettingsActions.changeMarketDirection(setting);
        }
    }, {
        key: "shouldComponentUpdate",
        value: function shouldComponentUpdate(nextProps, nextState) {
            return nextProps.marketDirections !== this.props.marketDirections || nextProps.base_amount !== this.props.base_amount || nextProps.quote_amount !== this.props.quote_amount || nextProps.decimals !== this.props.decimals || !utils.are_equal_shallow(nextProps.pulsate, this.props.pulsate) || !utils.are_equal_shallow(nextState, this.state);
        }
    }, {
        key: "goToMarket",
        value: function goToMarket(e) {
            e.preventDefault();
            var _state = this.state,
                marketName = _state.marketName,
                first = _state.first,
                second = _state.second;

            var inverted = this.props.marketDirections.get(marketName);
            this.props.history.push("/market/" + (!inverted ? first.get("symbol") : second.get("symbol")) + "_" + (!inverted ? second.get("symbol") : first.get("symbol")));
        }
    }, {
        key: "render",
        value: function render() {
            var _props = this.props,
                base_asset = _props.base_asset,
                base_amount = _props.base_amount,
                quote_amount = _props.quote_amount,
                marketDirections = _props.marketDirections,
                hide_symbols = _props.hide_symbols,
                noPopOver = _props.noPopOver,
                pulsate = _props.pulsate;
            var _state2 = this.state,
                marketName = _state2.marketName,
                first = _state2.first,
                second = _state2.second;

            if (!first || !second) return React.createElement(
                "span",
                null,
                "--"
            );
            var inverted = marketDirections.get(marketName) || this.props.invert;
            if (this.props.force_direction && second.get("symbol") === this.props.force_direction && inverted) {
                inverted = false;
            } else if (this.props.force_direction && first.get("symbol") === this.props.force_direction && !inverted) {
                inverted = true;
            }
            var base = void 0,
                quote = void 0;
            if (inverted) {
                base = second;
                quote = first;
            } else {
                base = first;
                quote = second;
            }
            if (base.get("id") !== base_asset.get("id")) {
                var tempAmount = base_amount;
                base_amount = quote_amount;
                quote_amount = tempAmount;
            }

            var price = void 0;
            try {
                price = new Price({
                    quote: new Asset({
                        asset_id: base.get("id"),
                        precision: base.get("precision"),
                        amount: base_amount
                    }),
                    base: new Asset({
                        asset_id: quote.get("id"),
                        precision: quote.get("precision"),
                        amount: quote_amount
                    })
                });
            } catch (err) {
                return null;
            }

            var formatted_value = "";
            if (!this.props.hide_value) {
                var value = !this.props.ignorePriceFeed ? price.toReal() : quote_amount / base_amount;
                if (this.props.factor) {
                    if (this.props.negative_invert) {
                        value = inverted ? value * this.props.factor : value / this.props.factor;
                    } else {
                        value = inverted ? value / this.props.factor : value * this.props.factor;
                    }
                }

                if (isNaN(value) || !isFinite(value)) {
                    return React.createElement(
                        "span",
                        null,
                        "--"
                    );
                }
                var decimals = this.props.decimals ? this.props.decimals : price.base.precision;
                decimals = Math.min(8, decimals);
                formatted_value = React.createElement(FormattedNumber, {
                    value: value,
                    minimumFractionDigits: Math.max(2, decimals),
                    maximumFractionDigits: Math.max(2, decimals)
                });

                if (pulsate) {
                    if ((typeof pulsate === "undefined" ? "undefined" : _typeof(pulsate)) !== "object") pulsate = {};
                    formatted_value = React.createElement(
                        Pulsate,
                        _extends({ value: value }, pulsate),
                        formatted_value
                    );
                }
            }
            var symbols = hide_symbols ? "" : React.createElement(
                Tooltip,
                {
                    placement: "bottom",
                    title: noPopOver ? "Click to invert the price" : null
                },
                React.createElement(
                    "span",
                    {
                        className: noPopOver ? "clickable inline-block" : "",
                        onClick: noPopOver ? this.onFlip.bind(this) : null
                    },
                    React.createElement(AssetName, { name: quote.get("symbol"), noTip: noPopOver }),
                    "/",
                    React.createElement(AssetName, { name: base.get("symbol"), noTip: noPopOver })
                )
            );

            var currency_popover_body = !noPopOver && !hide_symbols ? React.createElement(
                "div",
                null,
                React.createElement(
                    "div",
                    { className: "button", onClick: this.onFlip.bind(this) },
                    React.createElement(Translate, { content: "exchange.invert" })
                ),
                React.createElement(
                    "div",
                    {
                        className: "button",
                        onClick: this.goToMarket.bind(this)
                    },
                    React.createElement(Translate, { content: "exchange.to_market" })
                )
            ) : null;

            var popOver = currency_popover_body ? React.createElement(
                Popover,
                {
                    isOpen: this.state.isPopoverOpen,
                    onOuterAction: this.closePopover,
                    body: currency_popover_body
                },
                React.createElement(
                    "span",
                    {
                        className: "currency click-for-help",
                        onClick: this.togglePopover
                    },
                    symbols
                )
            ) : null;

            return React.createElement(
                "span",
                { className: "formatted-price" },
                formatted_value,
                " ",
                popOver ? popOver : symbols
            );
        }
    }]);

    return FormattedPrice;
}(React.Component);

FormattedPrice.propTypes = {
    base_amount: PropTypes.any,
    quote_amount: PropTypes.any,
    decimals: PropTypes.number
};


FormattedPrice = AssetWrapper(FormattedPrice, {
    propNames: ["base_asset", "quote_asset"]
});
FormattedPrice = withRouter(FormattedPrice);

var FormattedPriceWrapper = function (_React$Component2) {
    _inherits(FormattedPriceWrapper, _React$Component2);

    function FormattedPriceWrapper() {
        _classCallCheck(this, FormattedPriceWrapper);

        return _possibleConstructorReturn(this, (FormattedPriceWrapper.__proto__ || Object.getPrototypeOf(FormattedPriceWrapper)).apply(this, arguments));
    }

    _createClass(FormattedPriceWrapper, [{
        key: "render",
        value: function render() {
            return React.createElement(
                AltContainer,
                {
                    stores: [SettingsStore],
                    inject: {
                        marketDirections: function marketDirections() {
                            return SettingsStore.getState().marketDirections;
                        }
                    }
                },
                React.createElement(FormattedPrice, this.props)
            );
        }
    }]);

    return FormattedPriceWrapper;
}(React.Component);

export default FormattedPriceWrapper;