var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import alt from "alt-instance";
import WalletDb from "stores/WalletDb";
import AccountRefsStore from "stores/AccountRefsStore";
import AccountStore from "stores/AccountStore";
import BalanceClaimActiveStore from "stores/BalanceClaimActiveStore";
import CachedPropertyStore from "stores/CachedPropertyStore";
import PrivateKeyActions from "actions/PrivateKeyActions";
import WalletActions from "actions/WalletActions";
import { ChainStore } from "bitsharesjs";
import BaseStore from "stores/BaseStore";
import iDB from "idb-instance";
import Immutable from "immutable";

/**  High-level container for managing multiple wallets.
 */

var WalletManagerStore = function (_BaseStore) {
    _inherits(WalletManagerStore, _BaseStore);

    function WalletManagerStore() {
        _classCallCheck(this, WalletManagerStore);

        var _this = _possibleConstructorReturn(this, (WalletManagerStore.__proto__ || Object.getPrototypeOf(WalletManagerStore)).call(this));

        _this.state = _this._getInitialState();
        _this.bindListeners({
            onRestore: WalletActions.restore,
            onSetWallet: WalletActions.setWallet,
            onSetBackupDate: WalletActions.setBackupDate,
            onSetBrainkeyBackupDate: WalletActions.setBrainkeyBackupDate,
            onDeleteWallet: WalletActions.deleteWallet
        });
        _get(WalletManagerStore.prototype.__proto__ || Object.getPrototypeOf(WalletManagerStore.prototype), "_export", _this).call(_this, "init", "setNewWallet", "onDeleteWallet", "onDeleteAllWallets");
        return _this;
    }

    _createClass(WalletManagerStore, [{
        key: "_getInitialState",
        value: function _getInitialState() {
            return {
                new_wallet: undefined, // pending restore
                current_wallet: undefined,
                wallet_names: Immutable.Set()
            };
        }

        /** This will change the current wallet the newly restored wallet. */

    }, {
        key: "onRestore",
        value: function onRestore(_ref) {
            var _this2 = this;

            var wallet_name = _ref.wallet_name,
                wallet_object = _ref.wallet_object;

            iDB.restore(wallet_name, wallet_object).then(function () {
                AccountStore.setWallet(wallet_name);
                return _this2.onSetWallet({ wallet_name: wallet_name });
            }).catch(function (error) {
                console.error(error);
                return Promise.reject(error);
            });
        }

        /** This may result in a new wallet name being added, only in this case
            should a <b>create_wallet_password</b> be provided.
        */

    }, {
        key: "onSetWallet",
        value: function onSetWallet(_ref2) {
            var _this3 = this;

            var _ref2$wallet_name = _ref2.wallet_name,
                wallet_name = _ref2$wallet_name === undefined ? "default" : _ref2$wallet_name,
                create_wallet_password = _ref2.create_wallet_password,
                brnkey = _ref2.brnkey,
                resolve = _ref2.resolve;

            var p = new Promise(function (res) {
                if (/[^a-z0-9_-]/.test(wallet_name) || wallet_name === "") throw new Error("Invalid wallet name");

                if (_this3.state.current_wallet === wallet_name) {
                    res();
                    return;
                }

                var add;
                if (!_this3.state.wallet_names.has(wallet_name)) {
                    var wallet_names = _this3.state.wallet_names.add(wallet_name);
                    add = iDB.root.setProperty("wallet_names", wallet_names);
                    _this3.setState({ wallet_names: wallet_names });
                }

                var current = iDB.root.setProperty("current_wallet", wallet_name);

                res(Promise.all([add, current]).then(function () {
                    // The database must be closed and re-opened first before the current
                    // application code can initialize its new state.
                    iDB.close();
                    ChainStore.clearCache();
                    BalanceClaimActiveStore.reset();
                    // Stores may reset when loadDbData is called
                    return iDB.init_instance().init_promise.then(function () {
                        // Make sure the database is ready when calling CachedPropertyStore.reset()
                        CachedPropertyStore.reset();
                        return Promise.all([WalletDb.loadDbData().then(function () {
                            return AccountStore.loadDbData();
                        }), PrivateKeyActions.loadDbData().then(function () {
                            return AccountRefsStore.loadDbData();
                        })]).then(function () {
                            // Update state here again to make sure listeners re-render

                            if (!create_wallet_password) {
                                _this3.setState({ current_wallet: wallet_name });
                                return;
                            }

                            return WalletDb.onCreateWallet(create_wallet_password, brnkey, //brainkey,
                            true, //unlock
                            wallet_name).then(function () {
                                return _this3.setState({ current_wallet: wallet_name });
                            });
                        });
                    });
                }));
            }).catch(function (error) {
                console.error(error);
                return Promise.reject(error);
            });
            if (resolve) resolve(p);
        }

        /** Used by the components during a pending wallet create. */

    }, {
        key: "setNewWallet",
        value: function setNewWallet(new_wallet) {
            this.setState({ new_wallet: new_wallet });
        }
    }, {
        key: "init",
        value: function init() {
            var _this4 = this;

            return iDB.root.getProperty("current_wallet").then(function (current_wallet) {
                return iDB.root.getProperty("wallet_names", []).then(function (wallet_names) {
                    _this4.setState({
                        wallet_names: Immutable.Set(wallet_names),
                        current_wallet: current_wallet
                    });
                    AccountStore.setWallet(current_wallet);
                });
            });
        }
    }, {
        key: "onDeleteAllWallets",
        value: function onDeleteAllWallets() {
            var _this5 = this;

            var deletes = [];
            this.state.wallet_names.forEach(function (wallet_name) {
                return deletes.push(_this5.onDeleteWallet(wallet_name));
            });
            return Promise.all(deletes);
        }
    }, {
        key: "onDeleteWallet",
        value: function onDeleteWallet(delete_wallet_name) {
            var _this6 = this;

            return new Promise(function (resolve) {
                var _state = _this6.state,
                    current_wallet = _state.current_wallet,
                    wallet_names = _state.wallet_names;

                if (!wallet_names.has(delete_wallet_name)) {
                    throw new Error("Can't delete wallet, does not exist in index");
                }
                wallet_names = wallet_names.delete(delete_wallet_name);
                iDB.root.setProperty("wallet_names", wallet_names);
                if (current_wallet === delete_wallet_name) {
                    current_wallet = wallet_names.size ? wallet_names.first() : undefined;
                    iDB.root.setProperty("current_wallet", current_wallet);
                    if (current_wallet) WalletActions.setWallet(current_wallet);
                }
                _this6.setState({ current_wallet: current_wallet, wallet_names: wallet_names });
                var database_name = iDB.getDatabaseName(delete_wallet_name);
                var req = iDB.impl.deleteDatabase(database_name);
                resolve(database_name);
            });
        }
    }, {
        key: "onSetBackupDate",
        value: function onSetBackupDate() {
            WalletDb.setBackupDate();
        }
    }, {
        key: "onSetBrainkeyBackupDate",
        value: function onSetBrainkeyBackupDate() {
            WalletDb.setBrainkeyBackupDate();
        }
    }]);

    return WalletManagerStore;
}(BaseStore);

export var WalletManagerStoreWrapped = alt.createStore(WalletManagerStore, "WalletManagerStore");
export default WalletManagerStoreWrapped;