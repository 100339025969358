var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

import alt from "alt-instance";
import TransactionConfirmActions from "actions/TransactionConfirmActions";

var TransactionConfirmStore = function () {
    function TransactionConfirmStore() {
        _classCallCheck(this, TransactionConfirmStore);

        this.bindActions(TransactionConfirmActions);
        this.state = this.getInitialState();
        this.exportPublicMethods({ reset: this.reset.bind(this) });
    }

    _createClass(TransactionConfirmStore, [{
        key: "getInitialState",
        value: function getInitialState() {
            //console.log("-- TransactionConfirmStore.getInitialState -->");
            return {
                transaction: null,
                error: null,
                broadcasting: false,
                broadcast: false,
                included: false,
                trx_id: null,
                trx_block_num: null,
                closed: true,
                broadcasted_transaction: null,
                propose: false,
                fee_paying_account: null // proposal fee_paying_account
            };
        }
    }, {
        key: "onConfirm",
        value: function onConfirm(_ref) {
            var transaction = _ref.transaction,
                resolve = _ref.resolve,
                reject = _ref.reject;

            var init_state = this.getInitialState();
            var state = _extends({}, init_state, {
                transaction: transaction,
                closed: false,
                broadcasted_transaction: null,
                resolve: resolve,
                reject: reject
            });
            //console.log("-- TransactionConfirmStore.onConfirm -->", state);
            this.setState(state);
        }
    }, {
        key: "onClose",
        value: function onClose() {
            //console.log("-- TransactionConfirmStore.onClose -->", state);
            this.setState({ closed: true });
        }
    }, {
        key: "onBroadcast",
        value: function onBroadcast(payload) {
            //console.log("-- TransactionConfirmStore.onBroadcast -->", state);
            this.setState(payload);
            if (payload.broadcasted_transaction) {
                this.setState({
                    broadcasted_transaction: this.state.transaction
                });
            }
        }
    }, {
        key: "onWasBroadcast",
        value: function onWasBroadcast(res) {
            //console.log("-- TransactionConfirmStore.onWasBroadcast -->", state);
            this.setState({ broadcasting: false, broadcast: true });
        }
    }, {
        key: "onWasIncluded",
        value: function onWasIncluded(res) {
            //console.log("-- TransactionConfirmStore.onWasIncluded -->", this.state);
            this.setState({
                error: null,
                broadcasting: false,
                broadcast: true,
                included: true,
                trx_id: res[0].id,
                trx_block_num: res[0].block_num,
                broadcasted_transaction: this.state.transaction
            });
        }
    }, {
        key: "onError",
        value: function onError(_ref2) {
            var error = _ref2.error;

            this.setState({ broadcast: false, broadcasting: false, error: error });
        }
    }, {
        key: "onTogglePropose",
        value: function onTogglePropose() {
            this.setState({ propose: !this.state.propose });
        }
    }, {
        key: "onProposeFeePayingAccount",
        value: function onProposeFeePayingAccount(fee_paying_account) {
            this.setState({ fee_paying_account: fee_paying_account });
        }
    }, {
        key: "reset",
        value: function reset() {
            //console.log("-- TransactionConfirmStore.reset -->");
            this.state = this.getInitialState();
        }
    }]);

    return TransactionConfirmStore;
}();

export default alt.createStore(TransactionConfirmStore, "TransactionConfirmStore");