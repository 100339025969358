var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from "react";
import Translate from "react-translate-component";
import utils from "common/utils";
import { requestDepositAddress } from "common/gatewayMethods";
import BlockTradesDepositAddressCache from "common/BlockTradesDepositAddressCache";
import CopyButton from "../Utility/CopyButton";
import Icon from "../Icon/Icon";
import LoadingIndicator from "../LoadingIndicator";
import { DecimalChecker } from "../Utility/DecimalChecker";
import DepositWithdrawAssetSelector from "../DepositWithdraw/DepositWithdrawAssetSelector.js";
import { gatewaySelector, _getNumberAvailableGateways, _onAssetSelected, _getCoinToGatewayMapping } from "lib/common/assetGatewayMixin";
import { availableGateways } from "common/gateways";
import { getGatewayStatusByAsset } from "common/gatewayUtils";
import CryptoLinkFormatter from "../Utility/CryptoLinkFormatter";
import counterpart from "counterpart";
import { Modal, Button } from "bitshares-ui-style-guide";
import AccountStore from "../../stores/AccountStore";

var DepositModalContent = function (_DecimalChecker) {
    _inherits(DepositModalContent, _DecimalChecker);

    function DepositModalContent() {
        _classCallCheck(this, DepositModalContent);

        var _this = _possibleConstructorReturn(this, (DepositModalContent.__proto__ || Object.getPrototypeOf(DepositModalContent)).call(this));

        _this.state = _this._intitalState();

        _this.deposit_address_cache = new BlockTradesDepositAddressCache();
        _this.addDepositAddress = _this.addDepositAddress.bind(_this);
        return _this;
    }

    _createClass(DepositModalContent, [{
        key: "onClose",
        value: function onClose() {
            this.props.hideModal();
        }
    }, {
        key: "componentWillMount",
        value: function componentWillMount() {
            var asset = this.props.asset;

            this._setDepositAsset(asset);
        }
    }, {
        key: "shouldComponentUpdate",
        value: function shouldComponentUpdate(np, ns) {
            if (np.asset !== this.props.asset) {
                this.setState(this._intitalState());
                this._setDepositAsset(np.asset);
            }
            return !utils.are_equal_shallow(ns, this.state);
        }
    }, {
        key: "onGatewayChanged",
        value: function onGatewayChanged(selectedGateway) {
            this._getDepositAddress(this.state.selectedAsset, selectedGateway);
        }
    }, {
        key: "onAssetSelected",
        value: function onAssetSelected(asset) {
            if (asset.gateway === "") return this.setState({
                selectedAsset: asset.id,
                selectedGateway: null
            });

            var _onAssetSelected$call = _onAssetSelected.call(this, asset.id, "depositAllowed", function (availableGateways, balancesByGateway) {
                if (availableGateways && availableGateways.length === 1) return availableGateways[0]; //autoselect gateway if exactly 1 item
                return null;
            }),
                selectedAsset = _onAssetSelected$call.selectedAsset,
                selectedGateway = _onAssetSelected$call.selectedGateway;

            if (selectedGateway) {
                this._getDepositAddress(selectedAsset, selectedGateway);
            }
        }
    }, {
        key: "_intitalState",
        value: function _intitalState() {
            return {
                depositAddress: "",
                selectedAsset: "",
                selectedGateway: null,
                fetchingAddress: false,
                backingAsset: null,
                gatewayStatus: availableGateways
            };
        }
    }, {
        key: "_setDepositAsset",
        value: function _setDepositAsset(asset) {
            var coinToGatewayMapping = _getCoinToGatewayMapping.call(this);
            this.setState({ coinToGatewayMapping: coinToGatewayMapping });

            if (!asset) return;

            var backedAsset = asset.split(".");
            var usingGateway = this.state.gatewayStatus[backedAsset[0]] ? true : false;

            if (usingGateway) {
                var assetName = backedAsset[1];
                var assetGateway = backedAsset[0];
                this._getDepositAddress(assetName, assetGateway);
            } else {
                this.setState({ selectedAsset: "BTS" });
            }
        }
    }, {
        key: "_getDepositObject",
        value: function _getDepositObject(assetName, fullAssetName, selectedGateway, url) {
            var props = this.props,
                state = this.state;
            var account = props.account;
            var gatewayStatus = state.gatewayStatus;


            return {
                inputCoinType: gatewayStatus[selectedGateway].useFullAssetName ? fullAssetName.toLowerCase() : assetName.toLowerCase(),
                outputCoinType: fullAssetName.toLowerCase(),
                outputAddress: account,
                url: url,
                stateCallback: this.addDepositAddress,
                selectedGateway: selectedGateway
            };
        }
    }, {
        key: "_getDepositAddress",
        value: function _getDepositAddress(selectedAsset, selectedGateway) {
            var account = this.props.account;
            var gatewayStatus = this.state.gatewayStatus;


            this.setState({
                fetchingAddress: true,
                depositAddress: null,
                gatewayStatus: getGatewayStatusByAsset.call(this, selectedAsset)
            });

            // Get Backing Asset for Gateway
            var backingAsset = this.props.backedCoins.get(selectedGateway.toUpperCase(), []).find(function (c) {
                var backingCoin = c.backingCoinType || c.backingCoin;

                if (backingCoin.toUpperCase().indexOf("EOS.") !== -1) {
                    backingCoin = backingCoin.split(".")[1];
                }

                return backingCoin.toUpperCase() === selectedAsset.toUpperCase();
            });

            if (!backingAsset) {
                console.log(selectedGateway + " does not support " + selectedAsset);
                this.setState({
                    depositAddress: null,
                    selectedAsset: selectedAsset,
                    selectedGateway: selectedGateway,
                    fetchingAddress: false
                });
                return;
            }

            var depositAddress = void 0;
            if (selectedGateway && selectedAsset) {

                depositAddress = this.deposit_address_cache.getCachedInputAddress(selectedGateway.toLowerCase(), account, selectedAsset.toLowerCase(), selectedGateway.toLowerCase() + "." + selectedAsset.toLowerCase());
            }

            if (!!gatewayStatus[selectedGateway].simpleAssetGateway) {
                this.setState({
                    depositAddress: {
                        address: backingAsset.gatewayWallet,
                        memo: !gatewayStatus[selectedGateway].fixedMemo ? account : gatewayStatus[selectedGateway].fixedMemo["prepend"] + account + gatewayStatus[selectedGateway].fixedMemo["append"]
                    },
                    fetchingAddress: false
                });
            } else {
                if (!depositAddress) {
                    var assetName = backingAsset.backingCoinType || backingAsset.backingCoin;
                    var fullAssetName = backingAsset.symbol;

                    requestDepositAddress(this._getDepositObject(assetName, fullAssetName, selectedGateway, gatewayStatus[selectedGateway].baseAPI.BASE));
                } else {
                    this.setState({
                        depositAddress: depositAddress,
                        fetchingAddress: false
                    });
                }
            }

            this.setState({
                selectedAsset: selectedAsset,
                selectedGateway: selectedGateway,
                backingAsset: backingAsset
            });
        }
    }, {
        key: "addDepositAddress",
        value: function addDepositAddress(depositAddress) {
            var _state = this.state,
                selectedGateway = _state.selectedGateway,
                selectedAsset = _state.selectedAsset;
            var account = this.props.account;


            this.deposit_address_cache.cacheInputAddress(selectedGateway.toLowerCase(), account, selectedAsset.toLowerCase(), selectedGateway.toLowerCase() + "." + selectedAsset.toLowerCase(), depositAddress.address, depositAddress.memo);
            this.setState({
                depositAddress: depositAddress,
                fetchingAddress: false
            });
        }
    }, {
        key: "render",
        value: function render() {
            var _state2 = this.state,
                selectedAsset = _state2.selectedAsset,
                selectedGateway = _state2.selectedGateway,
                depositAddress = _state2.depositAddress,
                fetchingAddress = _state2.fetchingAddress,
                gatewayStatus = _state2.gatewayStatus,
                backingAsset = _state2.backingAsset;
            var account = this.props.account;

            var usingGateway = true;

            if (selectedGateway == null && selectedAsset === "BTS") {
                usingGateway = false;
                depositAddress = { address: account };
            }

            // Count available gateways
            var nAvailableGateways = _getNumberAvailableGateways.call(this);
            var isAddressValid = depositAddress && depositAddress !== "unknown" && !depositAddress.error;

            var minDeposit = 0;
            if (!!backingAsset) {
                if (!!backingAsset.minAmount && !!backingAsset.precision) {
                    minDeposit = utils.format_number(backingAsset.minAmount / utils.get_asset_precision(backingAsset.precision), backingAsset.precision, false);
                } else if (!!backingAsset.gateFee) {
                    minDeposit = backingAsset.gateFee * 2;
                }
            }
            //let maxDeposit = backingAsset.maxAmount ? backingAsset.maxAmount : null;

            var QR = isAddressValid ? React.createElement(CryptoLinkFormatter, {
                size: 140,
                address: usingGateway ? depositAddress.address : account,
                asset: selectedAsset
            }) : React.createElement(
                "div",
                null,
                React.createElement(Icon, {
                    size: "5x",
                    name: "minus-circle",
                    title: "icons.minus_circle.wrong_address"
                }),
                React.createElement(
                    "p",
                    { className: "error-msg" },
                    React.createElement(Translate, { content: "modal.deposit.address_generation_error" })
                )
            );

            var prefix = selectedGateway;
            if (prefix === "BNB" || prefix === "WAVES" || prefix === "ETH" || prefix === "HECO" || prefix === "BSC") {
                prefix = "XBTSX";
            }

            return React.createElement(
                "div",
                { className: "grid-block vertical no-overflow" },
                React.createElement(
                    "div",
                    { className: "modal__body", style: { paddingTop: "0" } },
                    React.createElement(
                        "div",
                        { className: "container-row" },
                        React.createElement(
                            "div",
                            { className: "no-margin no-padding" },
                            React.createElement(
                                "div",
                                { className: "inline-label input-wrapper" },
                                React.createElement(DepositWithdrawAssetSelector, {
                                    defaultValue: this.state.selectedAsset,
                                    onSelect: this.onAssetSelected.bind(this),
                                    selectOnBlur: true
                                })
                            )
                        )
                    ),
                    usingGateway && selectedAsset ? gatewaySelector.call(this, {
                        selectedGateway: selectedGateway,
                        gatewayStatus: gatewayStatus,
                        nAvailableGateways: nAvailableGateways,
                        error: depositAddress && depositAddress.error,
                        onGatewayChanged: this.onGatewayChanged.bind(this)
                    }) : null,
                    !fetchingAddress ? (!usingGateway || usingGateway && selectedGateway && gatewayStatus[selectedGateway].options.enabled) && isAddressValid && !depositAddress.memo ? React.createElement(
                        "div",
                        {
                            className: "container-row",
                            style: { textAlign: "center" }
                        },
                        QR
                    ) : null : React.createElement(
                        "div",
                        {
                            className: "container-row",
                            style: { textAlign: "center", paddingTop: 15 }
                        },
                        React.createElement(LoadingIndicator, { type: "three-bounce" })
                    ),
                    selectedGateway && gatewayStatus[selectedGateway].options.enabled && isAddressValid ? React.createElement(
                        "div",
                        { className: "container-row" },
                        React.createElement(Translate, {
                            className: "grid-block container-row maxDeposit",
                            style: { fontSize: "1rem" },
                            content: "gateway.min_deposit_warning_amount",
                            minDeposit: minDeposit || 0,
                            coin: selectedAsset
                        }),
                        React.createElement(
                            "div",
                            { className: "grid-block container-row" },
                            React.createElement(
                                "div",
                                { style: { paddingRight: "1rem" } },
                                React.createElement(CopyButton, {
                                    text: depositAddress.address,
                                    className: "copyIcon"
                                })
                            ),
                            React.createElement(
                                "div",
                                { style: { wordBreak: "break-word" } },
                                React.createElement(Translate, {
                                    component: "div",
                                    style: {
                                        fontSize: "0.8rem",
                                        fontWeight: "bold",
                                        paddingBottom: "0.3rem"
                                    },
                                    content: "gateway.purchase_notice",
                                    inputAsset: selectedAsset,
                                    outputAsset: prefix + "." + selectedAsset
                                }),
                                React.createElement(
                                    "div",
                                    {
                                        className: "modal__highlight",
                                        style: {
                                            fontSize: "0.9rem",
                                            wordBreak: "break-all"
                                        }
                                    },
                                    depositAddress.address
                                )
                            )
                        ),
                        depositAddress.memo ? React.createElement(
                            "div",
                            { className: "grid-block container-row" },
                            React.createElement(
                                "div",
                                { style: { paddingRight: "1rem" } },
                                React.createElement(CopyButton, {
                                    text: depositAddress.memo,
                                    className: "copyIcon"
                                })
                            ),
                            React.createElement(
                                "div",
                                null,
                                React.createElement(Translate, {
                                    component: "div",
                                    style: {
                                        fontSize: "0.8rem",
                                        fontWeight: "bold",
                                        paddingBottom: "0.3rem"
                                    },
                                    unsafe: true,
                                    content: "gateway.purchase_notice_memo"
                                }),
                                React.createElement(
                                    "div",
                                    {
                                        className: "modal__highlight",
                                        style: { wordBreak: "break-all" }
                                    },
                                    depositAddress.memo
                                )
                            )
                        ) : null,
                        React.createElement(Translate, {
                            component: "span",
                            style: { fontSize: "0.8rem" },
                            content: "gateway.min_deposit_warning_asset",
                            minDeposit: minDeposit || 0,
                            coin: selectedAsset
                        })
                    ) : null,
                    !usingGateway ? React.createElement(
                        "div",
                        { className: "container-row deposit-directly" },
                        React.createElement(
                            "h2",
                            {
                                className: "modal__highlight",
                                style: { textAlign: "center" }
                            },
                            account
                        ),
                        React.createElement(Translate, {
                            component: "h6",
                            content: "modal.deposit.bts_transfer_description"
                        })
                    ) : null
                )
            );
        }
    }]);

    return DepositModalContent;
}(DecimalChecker);

var DepositModal = function (_React$Component) {
    _inherits(DepositModal, _React$Component);

    function DepositModal() {
        _classCallCheck(this, DepositModal);

        var _this2 = _possibleConstructorReturn(this, (DepositModal.__proto__ || Object.getPrototypeOf(DepositModal)).call(this));

        _this2.state = { open: false };
        return _this2;
    }

    _createClass(DepositModal, [{
        key: "show",
        value: function show() {
            var _this3 = this;

            this.setState({ open: true }, function () {
                _this3.props.hideModal();
            });
        }
    }, {
        key: "onClose",
        value: function onClose() {
            this.props.hideModal();
            this.setState({ open: false });
        }
    }, {
        key: "render",
        value: function render() {
            return React.createElement(
                Modal,
                {
                    title: this.props.account ? counterpart.translate("modal.deposit.header", {
                        account_name: this.props.account
                    }) : counterpart.translate("modal.deposit.header_short"),
                    id: this.props.modalId,
                    className: this.props.modalId,
                    onCancel: this.onClose.bind(this),
                    overlay: true,
                    footer: [React.createElement(
                        Button,
                        { key: "cancel", onClick: this.props.hideModal },
                        counterpart.translate("modal.close")
                    )],
                    visible: this.props.visible,
                    noCloseBtn: true
                },
                React.createElement(DepositModalContent, _extends({
                    hideModal: this.props.hideModal
                }, this.props, {
                    open: this.props.visible
                }))
            );
        }
    }]);

    return DepositModal;
}(React.Component);

export default DepositModal;