import React from "react";
import Translate from "react-translate-component";
import FormattedAsset from "../../Utility/FormattedAsset";

export var TransferToBlind = function TransferToBlind(_ref) {
    var op = _ref.op,
        linkToAccount = _ref.linkToAccount,
        fromComponent = _ref.fromComponent;

    return React.createElement(
        "span",
        null,
        linkToAccount(op[1].from),
        "\xA0",
        React.createElement(Translate, {
            component: "span",
            content: fromComponent === "proposed_operation" ? "proposal.sent" : "transaction.sent"
        }),
        "\xA0",
        React.createElement(FormattedAsset, {
            style: { fontWeight: "bold" },
            amount: op[1].amount.amount,
            asset: op[1].amount.asset_id
        })
    );
};