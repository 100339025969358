var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from "react";
import { Component } from "react";
import PropTypes from "prop-types";
import zxcvbnAsync from "zxcvbn-async";
import counterpart from "counterpart";
import { Button, Progress, Form, Input } from "bitshares-ui-style-guide";

var PasswordInput = function (_Component) {
    _inherits(PasswordInput, _Component);

    function PasswordInput() {
        _classCallCheck(this, PasswordInput);

        var _this = _possibleConstructorReturn(this, (PasswordInput.__proto__ || Object.getPrototypeOf(PasswordInput)).call(this));

        _this.onKeyDown = _this.onKeyDown.bind(_this);
        _this.state = {
            password: "",
            confirmPassword: "",
            isPasswordInputActive: false,
            isConfirmPasswordInputActive: false
        };

        _this.handlePasswordBlur = _this.handlePasswordBlur.bind(_this);
        _this.handlePasswordFocus = _this.handlePasswordFocus.bind(_this);
        _this.handlePasswordChange = _this.handlePasswordChange.bind(_this);
        _this.handleValidationChange = _this.handleValidationChange.bind(_this);
        _this.handleConfirmPasswordBlur = _this.handleConfirmPasswordBlur.bind(_this);
        _this.handleConfirmPasswordFocus = _this.handleConfirmPasswordFocus.bind(_this);
        _this.handleConfirmPasswordChange = _this.handleConfirmPasswordChange.bind(_this);
        return _this;
    }

    _createClass(PasswordInput, [{
        key: "handleConfirmPasswordBlur",
        value: function handleConfirmPasswordBlur() {
            this.setState({
                isConfirmPasswordInputActive: false
            });
        }
    }, {
        key: "handleConfirmPasswordFocus",
        value: function handleConfirmPasswordFocus() {
            this.setState({
                isConfirmPasswordInputActive: true
            });
        }
    }, {
        key: "handlePasswordBlur",
        value: function handlePasswordBlur() {
            this.setState({
                isPasswordInputActive: false
            });
        }
    }, {
        key: "handlePasswordFocus",
        value: function handlePasswordFocus() {
            this.setState({
                isPasswordInputActive: true
            });
        }
    }, {
        key: "handlePasswordChange",
        value: function handlePasswordChange(e) {
            var _this2 = this;

            this.setState({
                password: e.target.value
            }, function () {
                if (_this2.props.onChange) _this2.props.onChange(_this2.state.password);

                _this2.calculatePasswordScore(_this2.state.password || "");

                _this2.handleValidationChange();
            });
        }
    }, {
        key: "handleConfirmPasswordChange",
        value: function handleConfirmPasswordChange(e) {
            var _this3 = this;

            this.setState({
                confirmPassword: e.target.value
            }, function () {
                _this3.handleValidationChange();
            });
        }
    }, {
        key: "handleValidationChange",
        value: function handleValidationChange() {
            var validation = {
                errorMessage: this.getPasswordErrorMessage() || this.getConfirmPasswordErrorMessage() || " ",
                valid: !this.getPasswordErrorMessage() && !this.getConfirmPasswordErrorMessage()
            };

            console.log(validation);

            if (this.props.onValidationChange) this.props.onValidationChange(validation);
        }
    }, {
        key: "calculatePasswordScore",
        value: function calculatePasswordScore(password) {
            var passwordLength = this.props.passwordLength;


            var zxcvbn = zxcvbnAsync.load({ sync: true });

            var strength = zxcvbn(password || "");

            // passwordLength is min required length
            // to reach max score password length should be higher by 50% from min length
            var passwordScore = Math.floor(password.length / (passwordLength * 1.5));

            var score = Math.min(5, strength.score + passwordScore);

            console.log(score, strength.score, passwordScore, password);

            this.setState({
                score: score
            });
        }
    }, {
        key: "onKeyDown",
        value: function onKeyDown(e) {
            if (this.props.onEnter && e.keyCode === 13) this.props.onEnter(e);
        }
    }, {
        key: "getPasswordErrorMessage",
        value: function getPasswordErrorMessage() {
            var password = this.state.password;


            if (password.length < this.props.passwordLength) {
                return counterpart.translate("wallet.pass_length", {
                    minLength: this.props.passwordLength
                });
            }

            return "";
        }
    }, {
        key: "getConfirmPasswordErrorMessage",
        value: function getConfirmPasswordErrorMessage() {
            var _state = this.state,
                password = _state.password,
                confirmPassword = _state.confirmPassword;


            console.log(password, confirmPassword, password !== confirmPassword);

            if (password !== confirmPassword) {
                return counterpart.translate("wallet.confirm_error");
            }

            return "";
        }
    }, {
        key: "render",
        value: function render() {
            var _this4 = this;

            var score = this.state.score;
            var _props = this.props,
                readonly = _props.readonly,
                visible = _props.visible;


            var passwordErrorMessage = this.getPasswordErrorMessage();

            var confirmPasswordErrorMessage = this.getConfirmPasswordErrorMessage();

            var getPasswordHelp = function getPasswordHelp() {
                if (_this4.state.isPasswordInputActive || !_this4.state.password) return "";

                return passwordErrorMessage || "";
            };

            var getPasswordValidateStatus = function getPasswordValidateStatus() {
                if (_this4.state.isPasswordInputActive || !_this4.state.password) return "";

                return passwordErrorMessage && passwordErrorMessage.length ? "error" : "";
            };

            var getConfirmPasswordHelp = function getConfirmPasswordHelp() {
                if (!_this4.state.confirmPassword || !_this4.state.password) return "";

                return confirmPasswordErrorMessage || "";
            };

            var getConfirmPasswordValidateStatus = function getConfirmPasswordValidateStatus() {
                if (_this4.state.isConfirmPasswordInputActive || !_this4.state.confirmPassword || !_this4.state.password) return "";

                return confirmPasswordErrorMessage && confirmPasswordErrorMessage.length ? "error" : "";
            };

            return [React.createElement(
                Form.Item,
                {
                    label: this.props.label || counterpart.translate("wallet.enter_password"),
                    key: "password-field",
                    help: getPasswordHelp(),
                    validateStatus: getPasswordValidateStatus()
                },
                React.createElement(Input, {
                    id: "current-password",
                    onBlur: this.handlePasswordBlur,
                    onFocus: this.handlePasswordFocus,
                    type: visible ? "text" : "password",
                    name: "password",
                    placeholder: counterpart.translate("wallet.enter_password"),
                    ref: "password",
                    onChange: this.handlePasswordChange,
                    onKeyDown: this.onKeyDown,
                    value: this.state.password,
                    readOnly: readonly
                }),
                React.createElement(Progress, { percent: score * 20, showInfo: false })
            ), React.createElement(
                Form.Item,
                {
                    label: counterpart.translate("wallet.confirm"),
                    key: "confirm-password-field",
                    help: getConfirmPasswordHelp(),
                    validateStatus: getConfirmPasswordValidateStatus()
                },
                React.createElement(Input, {
                    id: "confirm-password",
                    onBlur: this.handleConfirmPasswordBlur,
                    onFocus: this.handleConfirmPasswordFocus,
                    type: visible ? "text" : "password",
                    name: "confirmPassword",
                    placeholder: counterpart.translate("wallet.enter_password"),
                    ref: "confirmPassword",
                    onChange: this.handleConfirmPasswordChange,
                    onKeyDown: this.onKeyDown,
                    value: this.state.confirmPassword,
                    readOnly: readonly
                })
            )];
        }
    }]);

    return PasswordInput;
}(Component);

PasswordInput.propTypes = {
    onChange: PropTypes.func,
    onEnter: PropTypes.func,
    confirmation: PropTypes.bool,
    wrongPassword: PropTypes.bool,
    noValidation: PropTypes.bool,
    noLabel: PropTypes.bool,
    passwordLength: PropTypes.number,
    checkStrength: PropTypes.bool,
    value: PropTypes.string,
    copy: PropTypes.bool,
    visible: PropTypes.bool,
    readonly: PropTypes.bool
};
PasswordInput.defaultProps = {
    confirmation: false,
    wrongPassword: false,
    noValidation: false,
    noLabel: false,
    passwordLength: 8,
    checkStrength: false,
    value: "",
    copy: false,
    visible: false,
    readonly: false
};


export default PasswordInput;