var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from "react";
import { Component } from "react";
import PropTypes from "prop-types";
import cname from "classnames";
import Translate from "react-translate-component";
import zxcvbnAsync from "zxcvbn-async";
import CopyButton from "../Utility/CopyButton";

var PasswordInput = function (_Component) {
    _inherits(PasswordInput, _Component);

    function PasswordInput(props) {
        _classCallCheck(this, PasswordInput);

        var _this = _possibleConstructorReturn(this, (PasswordInput.__proto__ || Object.getPrototypeOf(PasswordInput)).call(this));

        _this.handleChange = _this.handleChange.bind(_this);
        _this.onKeyDown = _this.onKeyDown.bind(_this);
        _this.state = {
            value: props.value || "",
            error: null,
            wrong: false,
            doesnt_match: false
        };
        return _this;
    }

    _createClass(PasswordInput, [{
        key: "value",
        value: function value() {
            var node = this.refs.password;
            return node ? node.value : "";
        }
    }, {
        key: "clear",
        value: function clear() {
            this.refs.password.value = "";
            if (this.props.confirmation) this.refs.confirm_password.value = "";
        }
    }, {
        key: "focus",
        value: function focus() {
            this.refs.password.focus();
        }
    }, {
        key: "valid",
        value: function valid() {
            return !(this.state.error || this.state.wrong || this.state.doesnt_match) && this.state.value.length >= this.props.passwordLength;
        }
    }, {
        key: "handleChange",
        value: function handleChange(e) {
            e.preventDefault();
            e.stopPropagation();
            var confirmation = this.props.confirmation ? this.refs.confirm_password.value : true;
            var password = this.refs.password.value;
            var doesnt_match = this.props.confirmation ? confirmation && password !== confirmation : false;

            var strength = 0,
                score = void 0;
            if (this.props.checkStrength) {
                if (this.state.value.length > 100) {
                    strength = { score: 4 };
                } else {
                    var zxcvbn = zxcvbnAsync.load({ sync: true });
                    strength = zxcvbn(this.state.value || "");
                }
                /* Require a length of passwordLength + 50% for the max score */
                score = Math.min(5, strength.score + Math.floor(this.state.value.length / (this.props.passwordLength * 1.5)));
            }

            var state = {
                valid: !this.state.error && !this.state.wrong && !(this.props.confirmation && doesnt_match) && confirmation && password.length >= this.props.passwordLength,
                value: password,
                score: score,
                doesnt_match: doesnt_match
            };
            if (this.props.onChange) this.props.onChange(state);
            this.setState(state);
        }
    }, {
        key: "onKeyDown",
        value: function onKeyDown(e) {
            if (this.props.onEnter && e.keyCode === 13) this.props.onEnter(e);
        }
    }, {
        key: "render",
        value: function render() {
            var _state = this.state,
                score = _state.score,
                value = _state.value;
            var _props = this.props,
                copy = _props.copy,
                visible = _props.visible,
                readonly = _props.readonly;

            var password_error = null,
                confirmation_error = null;
            if (this.state.wrong || this.props.wrongPassword) password_error = React.createElement(
                "div",
                null,
                React.createElement(Translate, { content: "wallet.pass_incorrect" })
            );else if (this.state.error) password_error = React.createElement(
                "div",
                null,
                this.state.error
            );
            if (!this.props.noValidation && !password_error && this.state.value.length > 0 && this.state.value.length < this.props.passwordLength) password_error = React.createElement(
                "div",
                null,
                React.createElement(Translate, {
                    content: "wallet.pass_length",
                    minLength: this.props.passwordLength
                })
            );
            if (this.state.doesnt_match) confirmation_error = React.createElement(
                "div",
                null,
                React.createElement(Translate, { content: "wallet.confirm_error" })
            );
            var password_class_name = cname("form-group", {
                "has-error": password_error
            });
            var password_confirmation_class_name = cname("form-group", {
                "has-error": this.state.doesnt_match
            });
            // let {noLabel} = this.props;

            var confirmMatch = false;
            if (this.refs.confirm_password && this.refs.confirm_password.value && !this.state.doesnt_match) {
                confirmMatch = true;
            }

            return React.createElement(
                "div",
                { className: "account-selector" },
                React.createElement(
                    "div",
                    { className: password_class_name },
                    React.createElement(
                        "section",
                        null,
                        React.createElement(
                            "label",
                            {
                                className: "left-label " + (this.props.labelClass || "")
                            },
                            React.createElement(Translate, { content: "wallet.enter_password" })
                        ),
                        React.createElement(
                            "div",
                            { className: "generated-password-section" },
                            React.createElement("input", {
                                style: {
                                    marginBottom: this.props.checkStrength ? 0 : null,
                                    display: copy ? "inline" : "block"
                                },
                                id: "current-password",
                                name: "password",
                                type: visible ? "text" : "password",
                                ref: "password",
                                autoComplete: "current-password",
                                onChange: this.handleChange,
                                onKeyDown: this.onKeyDown,
                                value: value,
                                readOnly: readonly
                            }),
                            copy && React.createElement(CopyButton, {
                                text: value,
                                tip: "tooltip.copy_password",
                                dataPlace: "top",
                                className: "button password-copy-button"
                            })
                        ),
                        this.props.checkStrength ? React.createElement("progress", {
                            style: { height: 10 },
                            className: score === 5 ? "high" : score === 4 ? "medium" : "low",
                            value: score,
                            max: "5",
                            min: "0"
                        }) : null
                    ),
                    password_error
                ),
                this.props.confirmation ? React.createElement(
                    "div",
                    { className: password_confirmation_class_name },
                    React.createElement(
                        "label",
                        { className: "left-label" },
                        React.createElement(Translate, { content: "wallet.confirm_password" })
                    ),
                    React.createElement(
                        "section",
                        {
                            style: { position: "relative", maxWidth: "30rem" }
                        },
                        React.createElement("input", {
                            id: "confirm_password",
                            name: "confirm_password",
                            type: "password",
                            ref: "confirm_password",
                            autoComplete: "confirm-password",
                            onChange: this.handleChange
                        }),
                        confirmMatch ? React.createElement(
                            "div",
                            { className: "ok-indicator success" },
                            "OK"
                        ) : null
                    ),
                    confirmation_error
                ) : null
            );
        }
    }]);

    return PasswordInput;
}(Component);

PasswordInput.propTypes = {
    onChange: PropTypes.func,
    onEnter: PropTypes.func,
    confirmation: PropTypes.bool,
    wrongPassword: PropTypes.bool,
    noValidation: PropTypes.bool,
    noLabel: PropTypes.bool,
    passwordLength: PropTypes.number,
    checkStrength: PropTypes.bool,
    value: PropTypes.string,
    copy: PropTypes.bool,
    visible: PropTypes.bool,
    readonly: PropTypes.bool
};
PasswordInput.defaultProps = {
    confirmation: false,
    wrongPassword: false,
    noValidation: false,
    noLabel: false,
    passwordLength: 8,
    checkStrength: false,
    value: "",
    copy: false,
    visible: false,
    readonly: false
};


export default PasswordInput;