import React from "react";
import Translate from "react-translate-component";
import FormattedAsset from "../../Utility/FormattedAsset";

export var BondClaimCollaterial = function BondClaimCollaterial(_ref) {
    var op = _ref.op,
        linkToAccount = _ref.linkToAccount,
        current = _ref.current;

    if (current === op[1].lender) {
        return React.createElement(
            "span",
            null,
            React.createElement(Translate, {
                component: "span",
                content: "proposal.bond_pay_collateral"
            }),
            "\xA0",
            React.createElement(FormattedAsset, {
                style: { fontWeight: "bold" },
                amount: op[1].collateral_claimed.amount,
                asset: op[1].collateral_claimed.asset_id
            }),
            React.createElement(Translate, { component: "span", content: "proposal.to" }),
            "\xA0",
            linkToAccount(op[1].claimer)
        );
    } else if (current === op[1].claimer) {
        return React.createElement(
            "span",
            null,
            React.createElement(Translate, {
                component: "span",
                content: "proposal.bond_claim_collateral"
            }),
            "\xA0",
            React.createElement(FormattedAsset, {
                style: { fontWeight: "bold" },
                amount: op[1].collateral_claimed.amount,
                asset: op[1].collateral_claimed.asset_id
            }),
            React.createElement(Translate, { component: "span", content: "proposal.from" }),
            "\xA0",
            linkToAccount(op[1].lender)
        );
    } else {
        return null;
    }
};