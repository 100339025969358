var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { FormattedDate } from "react-intl";
import { connect } from "alt-react";
import WalletActions from "actions/WalletActions";
import WalletManagerStore from "stores/WalletManagerStore";
import BackupStore from "stores/BackupStore";
import WalletDb from "stores/WalletDb";
import BackupActions, { backup, decryptWalletBackup } from "actions/BackupActions";
import { saveAs } from "file-saver";
import Translate from "react-translate-component";
import { PrivateKey } from "bitsharesjs";
import SettingsActions from "actions/SettingsActions";
import { backupName } from "common/backupUtils";
import { getWalletName } from "branding";
import { Button, Input, Notification } from "bitshares-ui-style-guide";
import counterpart from "counterpart";

var connectObject = {
    listenTo: function listenTo() {
        return [WalletManagerStore, BackupStore];
    },
    getProps: function getProps() {
        var wallet = WalletManagerStore.getState();
        var backup = BackupStore.getState();
        return { wallet: wallet, backup: backup };
    }
};

//The default component is WalletManager.jsx

var BackupCreate = function (_Component) {
    _inherits(BackupCreate, _Component);

    function BackupCreate() {
        _classCallCheck(this, BackupCreate);

        return _possibleConstructorReturn(this, (BackupCreate.__proto__ || Object.getPrototypeOf(BackupCreate)).apply(this, arguments));
    }

    _createClass(BackupCreate, [{
        key: "render",
        value: function render() {
            return React.createElement(
                "div",
                { style: { maxWidth: "40rem" } },
                React.createElement(
                    Create,
                    {
                        noText: this.props.noText,
                        newAccount: this.props.location && this.props.location.query ? this.props.location.query.newAccount : null
                    },
                    React.createElement(NameSizeModified, null),
                    this.props.noText ? null : React.createElement(Sha1, null),
                    React.createElement(Download, { downloadCb: this.props.downloadCb })
                )
            );
        }
    }]);

    return BackupCreate;
}(Component);

BackupCreate = connect(BackupCreate, connectObject);

// layout is a small project
// class WalletObjectInspector extends Component {
//     static propTypes={ walletObject: PropTypes.object }
//     render() {
//         return <div style={{overflowY:'auto'}}>
//             <Inspector
//                 data={ this.props.walletObject || {} }
//                 search={false}/>
//         </div>
//     }
// }

var BackupRestore = function (_Component2) {
    _inherits(BackupRestore, _Component2);

    function BackupRestore() {
        _classCallCheck(this, BackupRestore);

        var _this2 = _possibleConstructorReturn(this, (BackupRestore.__proto__ || Object.getPrototypeOf(BackupRestore)).call(this));

        _this2.state = {
            newWalletName: null
        };
        return _this2;
    }

    _createClass(BackupRestore, [{
        key: "componentWillMount",
        value: function componentWillMount() {
            BackupActions.reset();
        }
    }, {
        key: "render",
        value: function render() {
            var new_wallet = this.props.wallet.new_wallet;
            var has_new_wallet = this.props.wallet.wallet_names.has(new_wallet);
            var restored = has_new_wallet;
            var wallet_types = React.createElement(
                Link,
                { to: "/help/introduction/wallets" },
                counterpart.translate("wallet.wallet_types")
            );
            var backup_types = React.createElement(
                Link,
                { to: "/help/introduction/backups" },
                counterpart.translate("wallet.backup_types")
            );

            return React.createElement(
                "div",
                null,
                React.createElement(Translate, {
                    style: { textAlign: "left", maxWidth: "30rem" },
                    component: "p",
                    content: "wallet.import_backup_choose"
                }),
                React.createElement(Translate, {
                    className: "text-left",
                    component: "p",
                    wallet: wallet_types,
                    backup: backup_types,
                    content: "wallet.read_more"
                }),
                new FileReader().readAsBinaryString ? null : React.createElement(
                    "p",
                    { className: "error" },
                    "Warning! You browser doesn't support some some file operations required to restore backup, we recommend you to use Chrome or Firefox browsers to restore your backup."
                ),
                React.createElement(
                    Upload,
                    null,
                    React.createElement(NameSizeModified, null),
                    React.createElement(
                        DecryptBackup,
                        { saveWalletObject: true },
                        React.createElement(
                            NewWalletName,
                            null,
                            React.createElement(Restore, null)
                        )
                    )
                ),
                React.createElement("br", null),
                React.createElement(
                    Link,
                    { to: "/" },
                    React.createElement(
                        Button,
                        null,
                        React.createElement(Translate, { content: "wallet.back" })
                    )
                )
            );
        }
    }]);

    return BackupRestore;
}(Component);

BackupRestore = connect(BackupRestore, connectObject);

var Restore = function (_Component3) {
    _inherits(Restore, _Component3);

    function Restore() {
        _classCallCheck(this, Restore);

        var _this3 = _possibleConstructorReturn(this, (Restore.__proto__ || Object.getPrototypeOf(Restore)).call(this));

        _this3.state = {};
        return _this3;
    }

    _createClass(Restore, [{
        key: "isRestored",
        value: function isRestored() {
            var new_wallet = this.props.wallet.new_wallet;
            var has_new_wallet = this.props.wallet.wallet_names.has(new_wallet);
            return has_new_wallet;
        }
    }, {
        key: "render",
        value: function render() {
            var new_wallet = this.props.wallet.new_wallet;
            var has_new_wallet = this.isRestored();

            if (has_new_wallet) return React.createElement(
                "span",
                null,
                React.createElement(
                    "h5",
                    null,
                    React.createElement(Translate, {
                        content: "wallet.restore_success",
                        name: new_wallet.toUpperCase()
                    })
                ),
                React.createElement(
                    Link,
                    { to: "/" },
                    React.createElement(
                        Button,
                        { type: "primary" },
                        React.createElement(Translate, {
                            component: "span",
                            content: "header.dashboard"
                        })
                    )
                ),
                React.createElement(
                    "div",
                    null,
                    this.props.children
                )
            );

            return React.createElement(
                "span",
                null,
                React.createElement(
                    "h3",
                    null,
                    React.createElement(Translate, { content: "wallet.ready_to_restore" })
                ),
                React.createElement(
                    Button,
                    { type: "primary", onClick: this.onRestore.bind(this) },
                    React.createElement(Translate, {
                        content: "wallet.restore_wallet_of",
                        name: new_wallet
                    })
                )
            );
        }
    }, {
        key: "onRestore",
        value: function onRestore() {
            WalletActions.restore(this.props.wallet.new_wallet, this.props.backup.wallet_object);
            SettingsActions.changeSetting({
                setting: "passwordLogin",
                value: false
            });
        }
    }]);

    return Restore;
}(Component);

Restore = connect(Restore, connectObject);

var NewWalletName = function (_Component4) {
    _inherits(NewWalletName, _Component4);

    function NewWalletName() {
        _classCallCheck(this, NewWalletName);

        var _this4 = _possibleConstructorReturn(this, (NewWalletName.__proto__ || Object.getPrototypeOf(NewWalletName)).call(this));

        _this4.state = {
            new_wallet: null,
            accept: false
        };
        return _this4;
    }

    _createClass(NewWalletName, [{
        key: "componentWillMount",
        value: function componentWillMount() {
            var has_current_wallet = !!this.props.wallet.current_wallet;
            if (!has_current_wallet) {
                var walletName = "default";
                if (this.props.backup.name) {
                    walletName = this.props.backup.name.match(/[a-z0-9_-]*/)[0];
                }
                WalletManagerStore.setNewWallet(walletName);
                this.setState({ accept: true });
            }
            if (has_current_wallet && this.props.backup.name && !this.state.new_wallet) {
                // begning of the file name might make a good wallet name
                var new_wallet = this.props.backup.name.toLowerCase().match(/[a-z0-9_-]*/)[0];
                if (new_wallet) this.setState({ new_wallet: new_wallet });
            }
        }
    }, {
        key: "render",
        value: function render() {
            if (this.state.accept) return React.createElement(
                "span",
                null,
                this.props.children
            );

            var has_wallet_name = !!this.state.new_wallet;
            var has_wallet_name_conflict = has_wallet_name ? this.props.wallet.wallet_names.has(this.state.new_wallet) : false;
            var name_ready = !has_wallet_name_conflict && has_wallet_name;

            return React.createElement(
                "form",
                { onSubmit: this.onAccept.bind(this) },
                React.createElement(
                    "h5",
                    null,
                    React.createElement(Translate, { content: "wallet.new_wallet_name" })
                ),
                React.createElement(Input, {
                    type: "text",
                    id: "new_wallet",
                    onChange: this.formChange.bind(this),
                    value: this.state.new_wallet
                }),
                React.createElement(
                    "p",
                    null,
                    has_wallet_name_conflict ? React.createElement(Translate, { content: "wallet.wallet_exist" }) : null
                ),
                React.createElement(
                    Button,
                    {
                        onClick: this.onAccept.bind(this),
                        type: "primary",
                        disabled: !name_ready
                    },
                    React.createElement(Translate, { content: "wallet.accept" })
                )
            );
        }
    }, {
        key: "onAccept",
        value: function onAccept(e) {
            if (e) e.preventDefault();
            this.setState({ accept: true });
            WalletManagerStore.setNewWallet(this.state.new_wallet);
        }
    }, {
        key: "formChange",
        value: function formChange(event) {
            var key_id = event.target.id;
            var value = event.target.value;
            if (key_id === "new_wallet") {
                //case in-sensitive
                value = value.toLowerCase();
                // Allow only valid file name characters
                if (/[^a-z0-9_-]/.test(value)) return;
            }
            var state = {};
            state[key_id] = value;
            this.setState(state);
        }
    }]);

    return NewWalletName;
}(Component);

NewWalletName = connect(NewWalletName, connectObject);

var Download = function (_Component5) {
    _inherits(Download, _Component5);

    function Download() {
        _classCallCheck(this, Download);

        return _possibleConstructorReturn(this, (Download.__proto__ || Object.getPrototypeOf(Download)).apply(this, arguments));
    }

    _createClass(Download, [{
        key: "componentWillMount",
        value: function componentWillMount() {
            try {
                this.isFileSaverSupported = !!new Blob();
            } catch (e) {}
        }
    }, {
        key: "componentDidMount",
        value: function componentDidMount() {
            if (!this.isFileSaverSupported) {
                Notification.error({
                    message: counterpart.translate("notifications.backup_file_save_unsupported")
                });
            }

            if (this.props.confirmation) {
                this.createBackup();
            }
        }
    }, {
        key: "getBackupName",
        value: function getBackupName() {
            return backupName(this.props.wallet.current_wallet);
        }
    }, {
        key: "createBackup",
        value: function createBackup() {
            var _this6 = this;

            var backupPubkey = WalletDb.getWallet().password_pubkey;
            backup(backupPubkey).then(function (contents) {
                var name = _this6.getBackupName();
                BackupActions.incommingBuffer({ name: name, contents: contents });
            });
        }
    }, {
        key: "render",
        value: function render() {
            var _this7 = this;

            var isReady = true;
            if (this.props.confirmation) {
                isReady = this.props.checkboxActive;
            }
            return React.createElement(
                Button,
                {
                    type: "primary",
                    disabled: !isReady,
                    onClick: function onClick() {
                        _this7.onDownload();
                    },
                    style: this.props.confirmation ? { height: "initial", padding: 0 } : {}
                },
                this.props.confirmation ? React.createElement(
                    "div",
                    {
                        className: "download-block",
                        style: { padding: "1.25rem" }
                    },
                    React.createElement("img", {
                        className: "bin-img",
                        src: "/bin-file/default.svg",
                        alt: "bin"
                    }),
                    React.createElement(
                        "span",
                        { className: "text-left" },
                        React.createElement(Translate, {
                            className: "download-text",
                            content: "registration.downloadFile"
                        }),
                        React.createElement(
                            "p",
                            { className: "file-name", style: { marginBottom: 0 } },
                            this.props.backup.name
                        )
                    )
                ) : React.createElement(Translate, { content: "wallet.download" })
            );
        }
    }, {
        key: "onDownload",
        value: function onDownload() {
            var blob = new Blob([this.props.backup.contents], {
                type: "application/octet-stream; charset=us-ascii"
            });

            if (blob.size !== this.props.backup.size) {
                throw new Error("Invalid backup to download conversion");
            }
            saveAs(blob, this.props.backup.name);
            WalletActions.setBackupDate();

            if (this.props.downloadCb) {
                this.props.downloadCb();
            }
        }
    }]);

    return Download;
}(Component);

Download = connect(Download, connectObject);

var Create = function (_Component6) {
    _inherits(Create, _Component6);

    function Create() {
        _classCallCheck(this, Create);

        return _possibleConstructorReturn(this, (Create.__proto__ || Object.getPrototypeOf(Create)).apply(this, arguments));
    }

    _createClass(Create, [{
        key: "getBackupName",
        value: function getBackupName() {
            return backupName(this.props.wallet.current_wallet);
        }
    }, {
        key: "render",
        value: function render() {
            var has_backup = !!this.props.backup.contents;
            if (has_backup) return React.createElement(
                "div",
                null,
                this.props.children
            );

            var ready = WalletDb.getWallet() != null;

            return React.createElement(
                "div",
                null,
                this.props.noText ? null : React.createElement(
                    "div",
                    { style: { textAlign: "left" } },
                    this.props.newAccount ? React.createElement(Translate, {
                        component: "p",
                        content: "wallet.backup_new_account",
                        wallet_name: getWalletName()
                    }) : null,
                    React.createElement(Translate, {
                        component: "p",
                        content: "wallet.backup_explain"
                    })
                ),
                React.createElement(
                    Button,
                    {
                        type: "primary",
                        onClick: this.onCreateBackup.bind(this),
                        style: { marginBottom: 10 },
                        disabled: !ready
                    },
                    React.createElement(Translate, {
                        content: "wallet.create_backup_of",
                        name: this.props.wallet.current_wallet
                    })
                ),
                React.createElement(LastBackupDate, null)
            );
        }
    }, {
        key: "onCreateBackup",
        value: function onCreateBackup() {
            var _this9 = this;

            var backup_pubkey = WalletDb.getWallet().password_pubkey;
            backup(backup_pubkey).then(function (contents) {
                var name = _this9.getBackupName();
                BackupActions.incommingBuffer({ name: name, contents: contents });
            });
        }
    }]);

    return Create;
}(Component);

Create = connect(Create, connectObject);

var LastBackupDate = function (_Component7) {
    _inherits(LastBackupDate, _Component7);

    function LastBackupDate() {
        _classCallCheck(this, LastBackupDate);

        return _possibleConstructorReturn(this, (LastBackupDate.__proto__ || Object.getPrototypeOf(LastBackupDate)).apply(this, arguments));
    }

    _createClass(LastBackupDate, [{
        key: "render",
        value: function render() {
            if (!WalletDb.getWallet()) {
                return null;
            }
            var backup_date = WalletDb.getWallet().backup_date;
            var last_modified = WalletDb.getWallet().last_modified;
            var backup_time = backup_date ? React.createElement(
                "h4",
                null,
                React.createElement(Translate, { content: "wallet.last_backup" }),
                " ",
                React.createElement(FormattedDate, { value: backup_date })
            ) : React.createElement(Translate, {
                style: { paddingTop: 20 },
                className: "facolor-error",
                component: "p",
                content: "wallet.never_backed_up"
            });
            var needs_backup = null;
            if (backup_date) {
                needs_backup = last_modified.getTime() > backup_date.getTime() ? React.createElement(
                    "h4",
                    { className: "facolor-error" },
                    React.createElement(Translate, { content: "wallet.need_backup" })
                ) : React.createElement(
                    "h4",
                    { className: "success" },
                    React.createElement(Translate, { content: "wallet.noneed_backup" })
                );
            }
            return React.createElement(
                "span",
                null,
                backup_time,
                needs_backup
            );
        }
    }]);

    return LastBackupDate;
}(Component);

var Upload = function (_Component8) {
    _inherits(Upload, _Component8);

    function Upload() {
        _classCallCheck(this, Upload);

        return _possibleConstructorReturn(this, (Upload.__proto__ || Object.getPrototypeOf(Upload)).apply(this, arguments));
    }

    _createClass(Upload, [{
        key: "reset",
        value: function reset() {
            // debugger;
            // this.refs.file_input.value = "";
            BackupActions.reset();
        }
    }, {
        key: "render",
        value: function render() {
            var resetButton = React.createElement(
                "div",
                { style: { paddingTop: 20 } },
                React.createElement(
                    Button,
                    {
                        disabled: !this.props.backup.contents,
                        onClick: this.reset.bind(this)
                    },
                    React.createElement(Translate, { content: "wallet.reset" })
                )
            );

            if (this.props.backup.contents && this.props.backup.public_key) return React.createElement(
                "span",
                null,
                this.props.children,
                resetButton
            );

            var is_invalid = this.props.backup.contents && !this.props.backup.public_key;

            return React.createElement(
                "div",
                null,
                React.createElement("input", {
                    ref: "file_input",
                    accept: ".bin",
                    type: "file",
                    id: "backup_input_file",
                    style: { border: "solid" },
                    onChange: this.onFileUpload.bind(this)
                }),
                is_invalid ? React.createElement(
                    "h5",
                    null,
                    React.createElement(Translate, { content: "wallet.invalid_format" })
                ) : null,
                resetButton
            );
        }
    }, {
        key: "onFileUpload",
        value: function onFileUpload(evt) {
            var file = evt.target.files[0];
            BackupActions.incommingWebFile(file);
            this.forceUpdate();
        }
    }]);

    return Upload;
}(Component);

Upload = connect(Upload, connectObject);

var NameSizeModified = function (_Component9) {
    _inherits(NameSizeModified, _Component9);

    function NameSizeModified() {
        _classCallCheck(this, NameSizeModified);

        return _possibleConstructorReturn(this, (NameSizeModified.__proto__ || Object.getPrototypeOf(NameSizeModified)).apply(this, arguments));
    }

    _createClass(NameSizeModified, [{
        key: "render",
        value: function render() {
            return React.createElement(
                "span",
                null,
                React.createElement(
                    "h5",
                    null,
                    React.createElement(
                        "b",
                        null,
                        this.props.backup.name
                    ),
                    " (",
                    this.props.backup.size,
                    " ",
                    "bytes)"
                ),
                this.props.backup.last_modified ? React.createElement(
                    "div",
                    null,
                    this.props.backup.last_modified
                ) : null,
                React.createElement("br", null)
            );
        }
    }]);

    return NameSizeModified;
}(Component);

NameSizeModified = connect(NameSizeModified, connectObject);

var DecryptBackup = function (_Component10) {
    _inherits(DecryptBackup, _Component10);

    function DecryptBackup() {
        _classCallCheck(this, DecryptBackup);

        var _this13 = _possibleConstructorReturn(this, (DecryptBackup.__proto__ || Object.getPrototypeOf(DecryptBackup)).call(this));

        _this13.state = _this13._getInitialState();
        return _this13;
    }

    _createClass(DecryptBackup, [{
        key: "_getInitialState",
        value: function _getInitialState() {
            return {
                backup_password: "",
                verified: false
            };
        }
    }, {
        key: "render",
        value: function render() {
            if (this.state.verified) return React.createElement(
                "span",
                null,
                this.props.children
            );
            return React.createElement(
                "form",
                { onSubmit: this.onPassword.bind(this) },
                React.createElement(
                    "label",
                    null,
                    React.createElement(Translate, { content: "wallet.enter_password" })
                ),
                React.createElement(Input, {
                    type: "password",
                    id: "backup_password",
                    onChange: this.formChange.bind(this),
                    value: this.state.backup_password
                }),
                React.createElement(Sha1, null),
                React.createElement(
                    Button,
                    {
                        type: "primary",
                        htmlType: "submit",
                        onClick: this.onPassword.bind(this)
                    },
                    React.createElement(Translate, { content: "wallet.submit" })
                )
            );
        }
    }, {
        key: "onPassword",
        value: function onPassword(e) {
            var _this14 = this;

            if (e) e.preventDefault();
            var private_key = PrivateKey.fromSeed(this.state.backup_password || "");
            var contents = this.props.backup.contents;
            decryptWalletBackup(private_key.toWif(), contents).then(function (wallet_object) {
                _this14.setState({ verified: true });
                if (_this14.props.saveWalletObject) BackupStore.setWalletObjct(wallet_object);
            }).catch(function (error) {
                console.error("Error verifying wallet " + _this14.props.backup.name, error, error.stack);
                if (error === "invalid_decryption_key") {
                    Notification.error({
                        message: counterpart.translate("notifications.invalid_password")
                    });
                } else {
                    Notification.error({
                        message: error
                    });
                }
            });
        }
    }, {
        key: "formChange",
        value: function formChange(event) {
            var state = {};
            state[event.target.id] = event.target.value;
            this.setState(state);
        }
    }]);

    return DecryptBackup;
}(Component);

DecryptBackup.propTypes = {
    saveWalletObject: PropTypes.bool
};

DecryptBackup = connect(DecryptBackup, connectObject);

var Sha1 = function (_Component11) {
    _inherits(Sha1, _Component11);

    function Sha1() {
        _classCallCheck(this, Sha1);

        return _possibleConstructorReturn(this, (Sha1.__proto__ || Object.getPrototypeOf(Sha1)).apply(this, arguments));
    }

    _createClass(Sha1, [{
        key: "render",
        value: function render() {
            return React.createElement(
                "div",
                { className: "padding no-overflow" },
                React.createElement(
                    "pre",
                    { className: "no-overflow", style: { lineHeight: "1.2" } },
                    this.props.backup.sha1,
                    " * SHA1"
                ),
                React.createElement("br", null)
            );
        }
    }]);

    return Sha1;
}(Component);

Sha1 = connect(Sha1, connectObject);

export { BackupCreate, BackupRestore, Restore, NewWalletName, Download, Create, Upload, NameSizeModified, DecryptBackup, Sha1 };