var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from "react";
import PropTypes from "prop-types";
import { connect } from "alt-react";
import AccountStore from "stores/AccountStore";
import Translate from "react-translate-component";
import counterpart from "counterpart";
import { ChainStore, key } from "bitsharesjs/es";
import ReactTooltip from "react-tooltip";
import utils from "common/utils";
import SettingsActions from "actions/SettingsActions";
import WalletDb from "stores/WalletDb";
import AccountNameInput from "./../Forms/AccountNameInputStyleGuide";
import AccountSelect from "../Forms/AccountSelect";
import LoadingIndicator from "../LoadingIndicator";
import Icon from "../Icon/Icon";
import CopyButton from "../Utility/CopyButton";
import { Form, Input, Button, Tooltip } from "bitshares-ui-style-guide";

var AccountRegistrationForm = function (_React$Component) {
    _inherits(AccountRegistrationForm, _React$Component);

    function AccountRegistrationForm() {
        _classCallCheck(this, AccountRegistrationForm);

        var _this = _possibleConstructorReturn(this, (AccountRegistrationForm.__proto__ || Object.getPrototypeOf(AccountRegistrationForm)).call(this));

        _this.state = {
            validAccountName: false,
            accountName: "",
            registrarAccount: null,
            loading: false,
            generatedPassword: "P" + key.get_random_key().toWif(),
            confirmPassword: ""
        };
        _this.onSubmit = _this.onSubmit.bind(_this);
        _this.onRegistrarAccountChange = _this.onRegistrarAccountChange.bind(_this);
        _this.onAccountNameChange = _this.onAccountNameChange.bind(_this);
        _this.onConfirmation = _this.onConfirmation.bind(_this);
        _this.accountNameInput = null;
        return _this;
    }

    _createClass(AccountRegistrationForm, [{
        key: "componentWillMount",
        value: function componentWillMount() {
            SettingsActions.changeSetting({
                setting: "passwordLogin",
                value: true
            });
        }
    }, {
        key: "componentDidMount",
        value: function componentDidMount() {
            ReactTooltip.rebuild();
        }
    }, {
        key: "shouldComponentUpdate",
        value: function shouldComponentUpdate(nextProps, nextState) {
            return !utils.are_equal_shallow(nextState, this.state);
        }
    }, {
        key: "onAccountNameChange",
        value: function onAccountNameChange(e) {
            var state = {};
            if (e.valid !== undefined) {
                state.validAccountName = e.valid;
            }
            if (e.value !== undefined) {
                state.accountName = e.value;
            }
            this.setState(state);
        }
    }, {
        key: "onRegistrarAccountChange",
        value: function onRegistrarAccountChange(registrarAccount) {
            this.setState({ registrarAccount: registrarAccount });
        }
    }, {
        key: "onSubmit",
        value: function onSubmit(e) {
            e.preventDefault();
            if (this.isValid()) {
                this.props.continue({
                    accountName: this.state.accountName,
                    password: this.state.generatedPassword
                });
            }
        }
    }, {
        key: "onConfirmation",
        value: function onConfirmation(e) {
            var value = e.currentTarget.value;
            this.setState({
                confirmPassword: value,
                passwordConfirmed: value === this.state.generatedPassword
            });
        }
    }, {
        key: "isValid",
        value: function isValid() {
            var firstAccount = AccountStore.getMyAccounts().length === 0;
            var valid = this.state.validAccountName;
            if (!WalletDb.getWallet()) {
                valid = valid;
            }
            if (!firstAccount) {
                valid = valid && this.state.registrarAccount;
            }
            return valid;
        }
    }, {
        key: "renderAccountCreateForm",
        value: function renderAccountCreateForm() {
            var _this2 = this;

            var registrarAccount = this.state.registrarAccount;


            var myAccounts = AccountStore.getMyAccounts();
            var firstAccount = myAccounts.length === 0;
            var valid = this.isValid();
            var isLTM = false;
            var registrar = registrarAccount ? ChainStore.getAccount(registrarAccount) : null;
            if (registrar) {
                if (registrar.get("lifetime_referrer") === registrar.get("id")) {
                    isLTM = true;
                }
            }

            var getConfirmationPasswordHelp = function getConfirmationPasswordHelp() {
                return _this2.state.confirmPassword && !_this2.state.passwordConfirmed ? counterpart.translate("wallet.confirm_error") : "";
            };

            var getConfirmationPasswordValidateStatus = function getConfirmationPasswordValidateStatus() {
                return _this2.state.confirmPassword && !_this2.state.passwordConfirmed ? "error" : "";
            };

            return React.createElement(
                "div",
                null,
                React.createElement(
                    Form,
                    { onSubmit: this.onSubmit, layout: "vertical" },
                    React.createElement(AccountNameInput, {
                        cheapNameOnly: firstAccount,
                        onChange: this.onAccountNameChange,
                        accountShouldNotExist: true,
                        placeholder: counterpart.translate("account.name"),
                        label: React.createElement(
                            "span",
                            null,
                            React.createElement(
                                "span",
                                { className: "vertical-middle" },
                                counterpart.translate("account.name")
                            ),
                            "\xA0",
                            React.createElement(
                                Tooltip,
                                {
                                    title: counterpart.translate("tooltip.registration.accountName")
                                },
                                React.createElement(
                                    "span",
                                    null,
                                    React.createElement(Icon, {
                                        name: "question-in-circle",
                                        className: "icon-14px question-icon vertical-middle"
                                    })
                                )
                            )
                        ),
                        noLabel: true
                    }),
                    React.createElement(
                        Form.Item,
                        {
                            label: counterpart.translate("wallet.generated")
                        },
                        React.createElement(Input.TextArea, {
                            disabled: true,
                            style: { paddingRight: "50px" },
                            rows: 2,
                            id: "password",
                            value: this.state.generatedPassword
                        }),
                        React.createElement(CopyButton, {
                            text: this.state.generatedPassword,
                            tip: "tooltip.copy_password",
                            dataPlace: "top",
                            className: "button registration-layout--copy-password-btn"
                        })
                    ),
                    React.createElement(
                        Form.Item,
                        {
                            label: counterpart.translate("wallet.confirm_password"),
                            help: getConfirmationPasswordHelp(),
                            validateStatus: getConfirmationPasswordValidateStatus()
                        },
                        React.createElement(Input, {
                            placeholder: counterpart.translate("wallet.confirm_password"),
                            type: "password",
                            name: "password",
                            id: "confirmPassword",
                            value: this.state.confirmPassword,
                            onChange: this.onConfirmation
                        })
                    ),
                    firstAccount ? null : React.createElement(
                        "div",
                        { className: "full-width-content form-group no-overflow" },
                        React.createElement(
                            "label",
                            { htmlFor: "account" },
                            React.createElement(Translate, { content: "account.pay_from" })
                        ),
                        React.createElement(AccountSelect, {
                            id: "account",
                            account_names: myAccounts,
                            onChange: this.onRegistrarAccountChange
                        }),
                        registrarAccount && !isLTM ? React.createElement(
                            "div",
                            {
                                style: { textAlign: "left" },
                                className: "facolor-error"
                            },
                            React.createElement(Translate, { content: "wallet.must_be_ltm" })
                        ) : null
                    ),
                    this.state.loading ? React.createElement(LoadingIndicator, { type: "three-bounce" }) : React.createElement(
                        Button,
                        {
                            htmlType: "submit",
                            type: "primary",
                            disabled: !valid || !this.state.passwordConfirmed || registrarAccount && !isLTM
                        },
                        React.createElement(Translate, { content: "registration.continue" })
                    )
                )
            );
        }
    }, {
        key: "renderAccountCreateText",
        value: function renderAccountCreateText() {
            var myAccounts = AccountStore.getMyAccounts();
            var firstAccount = myAccounts.length === 0;

            return React.createElement(
                "div",
                null,
                React.createElement(Translate, {
                    component: "p",
                    className: "model-description",
                    content: "registration.accountDescription"
                }),
                firstAccount ? null : React.createElement(Translate, {
                    component: "p",
                    content: "wallet.not_first_account"
                })
            );
        }
    }, {
        key: "render",
        value: function render() {
            return React.createElement(
                "div",
                null,
                this.renderAccountCreateText(),
                this.renderAccountCreateForm()
            );
        }
    }]);

    return AccountRegistrationForm;
}(React.Component);

AccountRegistrationForm.propTypes = {
    continue: PropTypes.func.isRequired
};


export default connect(AccountRegistrationForm, {
    listenTo: function listenTo() {
        return [AccountStore];
    },
    getProps: function getProps() {
        return {};
    }
});