var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from "react";
import WalletDb from "stores/WalletDb";
import Translate from "react-translate-component";
import Loadable from "react-loadable";
import LoadingIndicator from "./components/LoadingIndicator";
import { getWalletURL } from "./branding";

var Settings = Loadable({
    loader: function loader() {
        return import( /* webpackChunkName: "settings" */"./components/Settings/SettingsContainer");
    },
    loading: LoadingIndicator
});

var Deprecate = function (_React$Component) {
    _inherits(Deprecate, _React$Component);

    function Deprecate() {
        _classCallCheck(this, Deprecate);

        return _possibleConstructorReturn(this, (Deprecate.__proto__ || Object.getPrototypeOf(Deprecate)).apply(this, arguments));
    }

    _createClass(Deprecate, [{
        key: "hasWallet",
        value: function hasWallet() {
            return !!WalletDb.getWallet();
        }
    }, {
        key: "renderForWallet",
        value: function renderForWallet() {
            return React.createElement(
                "div",
                null,
                React.createElement(Translate, { content: "migration.text_1", component: "h4" }),
                React.createElement(Translate, {
                    content: "migration.text_2",
                    component: "p",
                    unsafe: true,
                    wallet_url: getWalletURL()
                })
            );
        }
    }, {
        key: "renderForCloud",
        value: function renderForCloud() {
            return React.createElement(
                "div",
                null,
                React.createElement(Translate, {
                    content: "migration.text_3",
                    unsafe: true,
                    component: "p",
                    wallet_url: getWalletURL()
                })
            );
        }
    }, {
        key: "render",
        value: function render() {
            return React.createElement(
                "div",
                { className: "grid-frame" },
                React.createElement(
                    "div",
                    {
                        className: "grid-block vertical",
                        style: { paddingBottom: "3rem" }
                    },
                    React.createElement(
                        "div",
                        { className: "grid-content large-offset-2 large-8 shrink" },
                        React.createElement(Translate, { content: "migration.title", component: "h2" }),
                        React.createElement(Translate, {
                            content: "migration.announcement_1",
                            unsafe: true,
                            component: "p"
                        }),
                        React.createElement(
                            "p",
                            null,
                            React.createElement(
                                "a",
                                {
                                    href: getWalletURL(),
                                    target: "blank",
                                    rel: "noopener noreferrer"
                                },
                                getWalletURL()
                            )
                        ),
                        this.hasWallet() ? this.renderForWallet() : this.renderForCloud()
                    ),
                    this.hasWallet() ? React.createElement(Settings, _extends({}, this.props, { deprecated: true })) : null
                )
            );
        }
    }]);

    return Deprecate;
}(React.Component);

export default Deprecate;