var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import alt from "alt-instance";
import iDB from "idb-instance";
import Immutable from "immutable";
import BaseStore from "./BaseStore";
import { ChainStore } from "bitsharesjs";
import { Apis } from "bitsharesjs-ws";
import PrivateKeyStore from "stores/PrivateKeyStore";
import PrivateKeyActions from "actions/PrivateKeyActions";
import chainIds from "chain/chainIds";

var AccountRefsStore = function (_BaseStore) {
    _inherits(AccountRefsStore, _BaseStore);

    function AccountRefsStore() {
        _classCallCheck(this, AccountRefsStore);

        var _this = _possibleConstructorReturn(this, (AccountRefsStore.__proto__ || Object.getPrototypeOf(AccountRefsStore)).call(this));

        _this._export("loadDbData", "getAccountRefs");
        _this.state = _this._getInitialState();
        _this.bindListeners({ onAddPrivateKey: PrivateKeyActions.addKey });
        _this.no_account_refs = Immutable.Set(); // Set of account ids
        ChainStore.subscribe(_this.chainStoreUpdate.bind(_this));
        return _this;
    }

    _createClass(AccountRefsStore, [{
        key: "_getInitialState",
        value: function _getInitialState() {
            this.chainstore_account_ids_by_key = null;
            this.chainstore_account_ids_by_account = null;
            var account_refs = new Immutable.Map();
            account_refs = account_refs.set(this._getChainId(), Immutable.Set());
            return {
                account_refs: account_refs
            };
        }
    }, {
        key: "getAccountRefs",
        value: function getAccountRefs() {
            var chainId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this._getChainId();

            return this.state.account_refs.get(chainId, Immutable.Set());
        }
    }, {
        key: "_getChainId",
        value: function _getChainId() {
            return Apis.instance().chain_id || chainIds.MAIN_NET;
        }
    }, {
        key: "onAddPrivateKey",
        value: function onAddPrivateKey(_ref) {
            var private_key_object = _ref.private_key_object;

            if (ChainStore.getAccountRefsOfKey(private_key_object.pubkey) !== undefined) this.chainStoreUpdate();
        }
    }, {
        key: "loadDbData",
        value: function loadDbData() {
            var _this2 = this;

            this.chainstore_account_ids_by_key = null;
            this.chainstore_account_ids_by_account = null;
            this.no_account_refs = Immutable.Set();

            var account_refs = new Immutable.Map();
            account_refs = account_refs.set(this._getChainId(), Immutable.Set());
            this.state = { account_refs: account_refs };
            return loadNoAccountRefs().then(function (no_account_refs) {
                return _this2.no_account_refs = no_account_refs;
            }).then(function () {
                return _this2.chainStoreUpdate();
            });
        }
    }, {
        key: "chainStoreUpdate",
        value: function chainStoreUpdate() {
            if (this.chainstore_account_ids_by_key === ChainStore.account_ids_by_key && this.chainstore_account_ids_by_account === ChainStore.account_ids_by_account) return;
            this.chainstore_account_ids_by_key = ChainStore.account_ids_by_key;
            this.chainstore_account_ids_by_account = ChainStore.account_ids_by_account;
            this.checkPrivateKeyStore();
        }
    }, {
        key: "checkPrivateKeyStore",
        value: function checkPrivateKeyStore() {
            var no_account_refs = this.no_account_refs;
            var temp_account_refs = Immutable.Set();
            PrivateKeyStore.getState().keys.keySeq().forEach(function (pubkey) {
                if (no_account_refs.has(pubkey)) return;
                var refs = ChainStore.getAccountRefsOfKey(pubkey);
                if (refs === undefined) return;
                if (!refs.size) {
                    // Performance optimization...
                    // There are no references for this public key, this is going
                    // to block it.  There many be many TITAN keys that do not have
                    // accounts for example.
                    {
                        // Do Not block brainkey generated keys.. Those are new and
                        // account references may be pending.
                        var private_key_object = PrivateKeyStore.getState().keys.get(pubkey);
                        if (typeof private_key_object.brainkey_sequence === "number") {
                            return;
                        }
                    }
                    no_account_refs = no_account_refs.add(pubkey);
                    return;
                }
                temp_account_refs = temp_account_refs.add(refs.valueSeq());
            });
            temp_account_refs = temp_account_refs.flatten();

            /* Discover accounts referenced by account name in permissions */
            temp_account_refs.forEach(function (account) {
                var refs = ChainStore.getAccountRefsOfAccount(account);
                if (refs === undefined) return;
                if (!refs.size) return;
                temp_account_refs = temp_account_refs.add(refs.valueSeq());
            });
            temp_account_refs = temp_account_refs.flatten();
            if (!this.getAccountRefs().equals(temp_account_refs)) {
                this.state.account_refs = this.state.account_refs.set(this._getChainId(), temp_account_refs);
                // console.log("AccountRefsStore account_refs",account_refs.size);
            }
            if (!this.no_account_refs.equals(no_account_refs)) {
                this.no_account_refs = no_account_refs;
                saveNoAccountRefs(no_account_refs);
            }
        }
    }]);

    return AccountRefsStore;
}(BaseStore);

export default alt.createStore(AccountRefsStore, "AccountRefsStore");

/*
*  Performance optimization for large wallets, no_account_refs tracks pubkeys
*  that do not have a corresponding account and excludes them from future api calls
*  to get_account_refs. The arrays are stored in the indexed db, one per chain id
*/
function loadNoAccountRefs() {
    var chain_id = Apis.instance().chain_id;
    var refKey = "no_account_refs" + (!!chain_id ? "_" + chain_id.substr(0, 8) : "");
    return iDB.root.getProperty(refKey, []).then(function (array) {
        return Immutable.Set(array);
    });
}

function saveNoAccountRefs(no_account_refs) {
    var array = [];
    var chain_id = Apis.instance().chain_id;
    var refKey = "no_account_refs" + (!!chain_id ? "_" + chain_id.substr(0, 8) : "");
    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
        for (var _iterator = no_account_refs[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var pubkey = _step.value;
            array.push(pubkey);
        }
    } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
    } finally {
        try {
            if (!_iteratorNormalCompletion && _iterator.return) {
                _iterator.return();
            }
        } finally {
            if (_didIteratorError) {
                throw _iteratorError;
            }
        }
    }

    iDB.root.setProperty(refKey, array);
}