import _debounce from "lodash/debounce";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

import React from "react";


import utils from "common/utils";
import counterpart from "counterpart";

import AmountSelector from "../Utility/AmountSelector";
import { Asset } from "common/MarketClasses";
import BalanceComponent from "../Utility/BalanceComponent";

import CryptoBridgeActions from "actions/CryptoBridgeActions";

import Translate from "react-translate-component";

import { checkFeeStatusAsync, checkBalance } from "common/trxHelper";

export var AccountStakingInfo = function () {
    function AccountStakingInfo() {
        _classCallCheck(this, AccountStakingInfo);
    }

    _createClass(AccountStakingInfo, null, [{
        key: "getStakingPeriodByPeriodValue",
        value: function getStakingPeriodByPeriodValue(value) {
            return this.stakingPeriods.find(function (period) {
                return period.value === parseInt(value);
            });
        }
    }]);

    return AccountStakingInfo;
}();

AccountStakingInfo.stakingPeriods = [{
    name1: "xbtsx.account.month_12",
    bonus: "100%",
    name: counterpart.translate("xbtsx.account.month_12", {
        bonus: "100%"
    }),
    monthName: "12 Months",
    value: 31536000
}, {
    name1: "xbtsx.account.month_6",
    bonus: "50%",
    name: counterpart.translate("xbtsx.account.month_6", {
        bonus: "50%"
    }),
    monthName: "6 Months",
    value: 15552000
}, {
    name1: "xbtsx.account.month_3",
    bonus: "20%",
    name: counterpart.translate("xbtsx.account.month_3", {
        bonus: "20%"
    }),
    monthName: "3 Months",
    value: 7776000
}, {
    name1: "xbtsx.account.month_1",
    bonus: "0%",
    name: counterpart.translate("xbtsx.account.month_1", {
        bonus: "0%"
    }),
    monthName: "1 Month",
    value: 2678400
}];

var AccountStakeCreateNew = function (_React$Component) {
    _inherits(AccountStakeCreateNew, _React$Component);

    function AccountStakeCreateNew(props) {
        _classCallCheck(this, AccountStakeCreateNew);

        var _this = _possibleConstructorReturn(this, (AccountStakeCreateNew.__proto__ || Object.getPrototypeOf(AccountStakeCreateNew)).call(this));

        _this._onAmountChanged = function (_ref) {
            var amount = _ref.amount;
            var asset = _this.state.asset;

            asset.setAmount({
                real: parseFloat(amount)
            });
            _this.setState({
                asset: asset
            });
        };

        _this._setTotalStakeAmount = function (currentBalance) {
            var _this$state = _this.state,
                feeAmount = _this$state.feeAmount,
                asset = _this$state.asset;


            var amount = currentBalance && feeAmount ? parseFloat(currentBalance.get("balance") / Math.pow(10, asset.precision) - feeAmount.getAmount({ real: true })).toFixed(asset.precision) : 0;

            asset.setAmount({
                real: parseFloat(amount)
            });

            _this.setState({
                asset: asset
            });
        };

        _this._setStakingPeriod = function (stakingPeriodValue) {
            _this.setState({
                stakingPeriodValue: parseInt(stakingPeriodValue.target.value, 10)
            });
        };

        _this._stakeBalance = function () {
            if (!_this.state.confirmationCheckboxChecked) {
                _this.setState({
                    showValidationErrors: true
                });
            } else {
                var account = _this.props.account;
                var _this$state2 = _this.state,
                    asset = _this$state2.asset,
                    stakingPeriodValue = _this$state2.stakingPeriodValue;


                if (asset.getAmount({ real: true }) > 99900) {
                    CryptoBridgeActions.stakeBalance(account.get("id"), stakingPeriodValue, asset.getAmount({ real: true }));
                }
            }
        };

        _this._onUnderstandCheckboxChange = function () {
            _this.setState({
                confirmationCheckboxChecked: !_this.state.confirmationCheckboxChecked
            });
        };

        _this.state = {
            feeStatus: {},
            asset: props.asset,
            stakingPeriodValue: 31536000,
            confirmationCheckboxChecked: false,
            showValidationErrors: false
        };

        _this._checkFeeStatus = _this._checkFeeStatus.bind(_this);
        _this._checkBalance = _this._checkBalance.bind(_this);
        _this._getCurrentBalance = _this._getCurrentBalance.bind(_this);
        _this._getFee = _this._getFee.bind(_this);
        _this._onAmountChanged = _this._onAmountChanged.bind(_this);
        _this._updateFee = _debounce(_this._updateFee.bind(_this), 250);
        return _this;
    }

    _createClass(AccountStakeCreateNew, [{
        key: "componentWillMount",
        value: function componentWillMount() {
            this._updateFee();
            this._checkFeeStatus();
        }
    }, {
        key: "_checkFeeStatus",
        value: function _checkFeeStatus() {
            var _this2 = this;

            var account = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.props.account;

            if (!account) return;

            var assets = ["1.3.0", this.state.asset.asset_id];
            var feeStatus = {};
            var p = [];
            assets.forEach(function (a) {
                p.push(checkFeeStatusAsync({
                    accountID: account.get("id"),
                    feeID: a,
                    type: "vesting_balance_create"
                }));
            });
            Promise.all(p).then(function (status) {
                assets.forEach(function (a, idx) {
                    feeStatus[a] = status[idx];
                });
                if (!utils.are_equal_shallow(_this2.state.feeStatus, feeStatus)) {
                    _this2.setState({
                        feeStatus: feeStatus
                    });
                }
                _this2._checkBalance();
            }).catch(function (err) {
                console.error(err);
            });
        }
    }, {
        key: "_updateFee",
        value: function _updateFee() {
            var _this3 = this;

            if (!this.props.account) return null;
            checkFeeStatusAsync({
                accountID: this.props.account.get("id"),
                feeID: this.state.asset.asset_id,
                type: "vesting_balance_create"
            }).then(function (_ref2) {
                var fee = _ref2.fee,
                    hasBalance = _ref2.hasBalance,
                    hasPoolBalance = _ref2.hasPoolBalance;

                _this3.setState({
                    feeAmount: fee,
                    hasBalance: hasBalance,
                    hasPoolBalance: hasPoolBalance,
                    error: !hasBalance || !hasPoolBalance
                }, _this3._checkFeeStatus);
            });
        }
    }, {
        key: "_getCurrentBalance",
        value: function _getCurrentBalance() {
            var _this4 = this;

            return this.props.balances.find(function (b) {
                return b && b.get("asset_type") === _this4.state.asset.asset_id;
            });
        }
    }, {
        key: "_checkBalance",
        value: function _checkBalance() {
            var _state = this.state,
                feeAmount = _state.feeAmount,
                asset = _state.asset;

            var balance = this._getCurrentBalance();
            if (!balance || !feeAmount) return;
            var hasBalance = checkBalance(asset.getAmount({ real: true }), asset, this._getFee(), balance, this._getGateFee());

            if (hasBalance === null) return;
            if (this.state.balanceError !== !hasBalance) this.setState({ balanceError: !hasBalance });

            return hasBalance;
        }
    }, {
        key: "_getFee",
        value: function _getFee() {
            var defaultFee = {
                getAmount: function getAmount() {
                    return 0;
                },
                asset_id: this.state.asset.asset_id
            };

            if (!this.state.feeStatus || !this.state.feeAmount) return defaultFee;

            var coreStatus = this.state.feeStatus["1.3.0"];
            var withdrawAssetStatus = this.state.feeStatus[this.state.asset.asset_id];
            // Use core asset to pay the fees if present and balance is sufficient since it's cheapest
            if (coreStatus && coreStatus.hasBalance) return coreStatus.fee;
            // Use same asset as withdraw if not
            if (coreStatus && !coreStatus.hasBalance && withdrawAssetStatus && withdrawAssetStatus.hasBalance) {
                return withdrawAssetStatus.fee;
            }
            return coreStatus ? coreStatus.fee : defaultFee;
        }
    }, {
        key: "_getGateFee",
        value: function _getGateFee() {
            var gateFee = this.props.gateFee;
            var asset = this.state.asset;

            return new Asset({
                real: parseFloat(gateFee ? gateFee.replace(",", "") : 0),
                asset_id: asset.asset_id,
                precision: asset.precision
            });
        }
    }, {
        key: "render",
        value: function render() {
            var _state2 = this.state,
                feeAmount = _state2.feeAmount,
                asset = _state2.asset,
                showValidationErrors = _state2.showValidationErrors,
                stakingPeriodValue = _state2.stakingPeriodValue,
                confirmationCheckboxChecked = _state2.confirmationCheckboxChecked;


            var fee = feeAmount && feeAmount.getAmount({ real: true }) || 0;
            var reclaimFee = (feeAmount && feeAmount.getAmount({ real: true }) || 0) * 2;
            var currentBalance = this._getCurrentBalance();
            var amount = asset.getAmount({ real: true });

            var balance = React.createElement(
                "span",
                {
                    style: { borderBottom: "#A09F9F 1px dotted", cursor: "pointer" },
                    onClick: this._setTotalStakeAmount.bind(this, currentBalance)
                },
                " ",
                currentBalance && currentBalance.get("balance") ? React.createElement(
                    "span",
                    null,
                    React.createElement(Translate, {
                        component: "span",
                        content: "xbtsx.account.sth_available"
                    }),
                    React.createElement(BalanceComponent, { balance: currentBalance.get("id") })
                ) : React.createElement(Translate, {
                    component: "span",
                    content: "xbtsx.account.sth_not_available"
                }),
                " "
            );

            var stakingPeriod = AccountStakingInfo.getStakingPeriodByPeriodValue(stakingPeriodValue);

            return React.createElement(
                "div",
                null,
                React.createElement(Translate, {
                    component: "h2",
                    content: "xbtsx.account.title"
                }),
                React.createElement(Translate, {
                    component: "p",
                    content: "xbtsx.account.staking_text1",
                    "with": { percent: "50%" },
                    unsafe: true
                }),
                React.createElement(Translate, {
                    component: "p",
                    content: "xbtsx.account.staking_text2",
                    "with": {
                        fee: fee,
                        reclaimFee: reclaimFee
                    },
                    unsafe: true
                }),
                React.createElement(Translate, {
                    component: "p",
                    content: "xbtsx.account.staking_text3",
                    unsafe: true
                }),
                React.createElement(
                    "div",
                    { className: "grid-block no-margin small-12 medium-6" },
                    React.createElement(Translate, {
                        component: "label",
                        unsafe: true,
                        content: "xbtsx.account.amount_sth"
                    }),
                    React.createElement(AmountSelector, {
                        label: "transfer.amount",
                        amount: amount,
                        display_balance: balance,
                        onChange: this._onAmountChanged.bind(this),
                        asset: asset.asset_id,
                        assets: [asset.asset_id],
                        placeholder: "0.0",
                        tabIndex: 0,
                        style: {
                            width: "100%"
                        }
                    })
                ),
                React.createElement(
                    "div",
                    { className: "grid-block no-margin small-12 medium-6" },
                    React.createElement(Translate, {
                        component: "label",
                        style: { marginTop: "1rem" },
                        content: "xbtsx.account.length"
                    }),
                    React.createElement(
                        "select",
                        {
                            role: "combobox",
                            onChange: this._setStakingPeriod,
                            value: stakingPeriodValue
                        },
                        AccountStakingInfo.stakingPeriods.map(function (p, i) {
                            return React.createElement(
                                "option",
                                {
                                    key: "stakingPeriod" + i,
                                    value: p.value
                                },
                                counterpart.translate(p.name1, {
                                    bonus: p.bonus
                                })
                            );
                        })
                    ),
                    amount > 0 ? React.createElement(
                        "label",
                        {
                            className: showValidationErrors && !confirmationCheckboxChecked ? "has-error" : ""
                        },
                        React.createElement("input", {
                            type: "checkbox",
                            onChange: this._onUnderstandCheckboxChange.bind(this),
                            checked: confirmationCheckboxChecked
                        }),
                        counterpart("xbtsx.account.understand", {
                            amount: amount,
                            month: stakingPeriod.monthName
                        })
                    ) : null,
                    React.createElement(
                        "div",
                        { style: { width: "100%", textAlign: "right" } },
                        React.createElement(
                            "button",
                            {
                                onClick: this._stakeBalance.bind(this),
                                className: "button"
                            },
                            React.createElement(Translate, { content: "xbtsx.account.stake_sth" })
                        )
                    )
                )
            );
        }
    }]);

    return AccountStakeCreateNew;
}(React.Component);

AccountStakeCreateNew.propTypes = {};
AccountStakeCreateNew.defaultProps = {};


export default AccountStakeCreateNew;