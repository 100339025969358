import React from "react";
import TranslateWithLinks from "../../Utility/TranslateWithLinks";

export var HtlcRefund = function HtlcRefund(_ref) {
    var op = _ref.op,
        changeColor = _ref.changeColor;

    changeColor("warning");
    return React.createElement(
        "span",
        { className: "right-td" },
        React.createElement(TranslateWithLinks, {
            string: "operation.htlc_refund",
            keys: [{
                value: op[1].htlc_id,
                arg: "htlc_id"
            }, {
                type: "account",
                value: op[1].to,
                arg: "to"
            }]
        })
    );
};