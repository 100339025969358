var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

import alt from "alt-instance";

var NotificationActions = function () {
    function NotificationActions() {
        _classCallCheck(this, NotificationActions);
    }

    _createClass(NotificationActions, [{
        key: "addNotification",
        value: function addNotification(notification) {
            notification = normalize(notification);
            return notification;
        }

        // Creating aliases: success, error, warning and info

    }, {
        key: "success",
        value: function success(notification) {
            notification = normalize(notification, "success");
            return notification;
        }
    }, {
        key: "error",
        value: function error(notification) {
            notification = normalize(notification, "error");
            return notification;
        }
    }, {
        key: "warning",
        value: function warning(notification) {
            notification = normalize(notification, "warning");
            return notification;
        }
    }, {
        key: "info",
        value: function info(notification) {
            notification = normalize(notification, "info");
            return notification;
        }
    }]);

    return NotificationActions;
}();

export default alt.createActions(NotificationActions);

var normalize = function normalize(notification, level) {
    if (typeof notification == "string") notification = { message: notification };
    if (level) notification.level = level;
    // Adjust the css position for notices.. bottom messages can't be seen
    //if(notification.level === "success" && ! notification.position)
    //    notification.position = 'br' //bottom right
    return notification;
};