var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

import alt from "alt-instance";
import NotificationActions from "actions/NotificationActions";

var NotificationStore = function () {
    function NotificationStore() {
        _classCallCheck(this, NotificationStore);

        this.bindListeners({
            addNotification: [NotificationActions.addNotification, NotificationActions.success, NotificationActions.warning, NotificationActions.error, NotificationActions.info]
        });

        this.state = {
            notification: null
        };
    }

    _createClass(NotificationStore, [{
        key: "addNotification",
        value: function addNotification(notification) {
            this.setState({ notification: notification });
        }
    }]);

    return NotificationStore;
}();

export default alt.createStore(NotificationStore, "NotificationStore");