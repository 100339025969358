import React from "react";
import TranslateWithLinks from "../../Utility/TranslateWithLinks";
import MemoText from "../MemoText";

export var Transfer = function Transfer(_ref) {
    var op = _ref.op,
        proposer = _ref.proposer,
        index = _ref.index,
        changeColor = _ref.changeColor;

    changeColor("success"); // color of a label
    var memoComponent = null;

    if (op[1].memo) {
        memoComponent = React.createElement(MemoText, { memo: op[1].memo });
    }
    op[1].amount.amount = parseFloat(op[1].amount.amount);

    return React.createElement(
        "span",
        { className: "right-td" },
        React.createElement(
            "div",
            { className: "inline-block" },
            React.createElement(
                "div",
                null,
                React.createElement(TranslateWithLinks, {
                    string: "operation.transfer",
                    keys: [{
                        type: "account",
                        value: op[1].from,
                        arg: "from"
                    }, {
                        type: "amount",
                        value: op[1].amount,
                        arg: "amount"
                    }, {
                        type: "account",
                        value: op[1].to,
                        arg: "to"
                    }]
                }),
                memoComponent
            )
        )
    );
};