var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

import Immutable from "immutable";
import alt from "alt-instance";
import BlockchainActions from "actions/BlockchainActions";
import { ChainStore } from "bitsharesjs";
// import {Block} from "./tcomb_structs";

var BlockchainStore = function () {
    function BlockchainStore() {
        _classCallCheck(this, BlockchainStore);

        // This might not need to be an immutable map, a normal structure might suffice..
        this.blocks = Immutable.Map();
        this.latestBlocks = Immutable.List();
        this.latestTransactions = Immutable.List();
        this.rpc_connection_status = null;
        this.no_ws_connection = false;
        this.blockHeaders = new Map();

        this.bindListeners({
            onGetBlock: BlockchainActions.getBlock,
            onGetLatest: BlockchainActions.getLatest,
            onGetHeader: BlockchainActions.getHeader,
            onUpdateRpcConnectionStatus: BlockchainActions.updateRpcConnectionStatus
        });

        this.maxBlocks = 30;
    }

    _createClass(BlockchainStore, [{
        key: "onGetHeader",
        value: function onGetHeader(_ref) {
            var header = _ref.header,
                height = _ref.height;

            if (header && height) {
                if (!/Z$/.test(header.timestamp)) {
                    header.timestamp += "Z";
                }
                header.timestamp = new Date(header.timestamp);
                this.blockHeaders.set(height, header);
            } else {
                return false;
            }
        }
    }, {
        key: "onGetBlock",
        value: function onGetBlock(block) {
            if (!this.blocks.get(block.id)) {
                if (!/Z$/.test(block.timestamp)) {
                    block.timestamp += "Z";
                }
                block.timestamp = new Date(block.timestamp);
                this.blocks = this.blocks.set(block.id, block);
            }
        }
    }, {
        key: "onGetLatest",
        value: function onGetLatest(payload) {
            var _this = this;

            var block = payload.block,
                maxBlock = payload.maxBlock;

            if (typeof block.timestamp === "string") {
                if (!/Z$/.test(block.timestamp)) {
                    block.timestamp += "Z";
                }
            }
            block.timestamp = new Date(block.timestamp);
            this.blocks = this.blocks.set(block.id, block);
            if (block.id > maxBlock - this.maxBlocks) {
                this.latestBlocks = this.latestBlocks.unshift(block);
                if (this.latestBlocks.size > this.maxBlocks) {
                    this.latestBlocks = this.latestBlocks.pop();
                }

                if (block.transactions.length > 0) {
                    block.transactions.forEach(function (trx) {
                        trx.block_num = block.id;
                        _this.latestTransactions = _this.latestTransactions.unshift(trx);
                    });
                }

                if (this.latestTransactions.size > this.maxBlocks) {
                    this.latestTransactions = this.latestTransactions.pop();
                }
            }
        }
    }, {
        key: "onUpdateRpcConnectionStatus",
        value: function onUpdateRpcConnectionStatus(status) {
            var prev_status = this.rpc_connection_status;
            if (status === "reconnect") ChainStore.resetCache(false);else this.rpc_connection_status = status;
            if (prev_status === null && status === "error") this.no_ws_connection = true;
            if (this.no_ws_connection && status === "open") this.no_ws_connection = false;
            if (status === "closed") this.no_ws_connection = true;
        }
    }]);

    return BlockchainStore;
}();

export default alt.createStore(BlockchainStore, "BlockchainStore");