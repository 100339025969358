var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React from "react";
import Translate from "react-translate-component";
import PropTypes from "prop-types";
import { Form, Select } from "bitshares-ui-style-guide";
import utils from "common/utils";
import counterpart from "counterpart";
import ChainTypes from "../Utility/ChainTypes";
import BindToChainState from "../Utility/BindToChainState";
import { Map } from "immutable";
import AssetName from "../Utility/AssetName";

var AssetSelectView = function AssetSelectView(_ref) {
    var label = _ref.label,
        assets = _ref.assets,
        selectStyle = _ref.selectStyle,
        formItemStyle = _ref.formItemStyle,
        style = _ref.style,
        placeholder = _ref.placeholder,
        value = _ref.value,
        props = _objectWithoutProperties(_ref, ["label", "assets", "selectStyle", "formItemStyle", "style", "placeholder", "value"]);

    var select = React.createElement(
        Select,
        _extends({
            showSearch: true,
            style: selectStyle,
            placeholder: React.createElement(Translate, {
                content: placeholder || "utility.asset_select_placeholder"
            }),
            value: React.createElement(AssetName, { noTip: true, name: value })
        }, props, {
            optionFilterProp: "children",
            filterOption: function filterOption(input, option) {
                return option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            },
            disabled: assets.filter(Map.isMap).length <= 1,
            notFoundContent: counterpart.translate("global.not_found")
        }),
        assets.filter(Map.isMap).map(function (asset) {
            var _utils$replaceName = utils.replaceName(asset),
                replacedName = _utils$replaceName.name,
                prefix = _utils$replaceName.prefix;

            return React.createElement(
                Select.Option,
                {
                    key: "" + (prefix || "") + replacedName,
                    value: asset.get("id")
                },
                React.createElement(AssetName, { noTip: true, name: asset.get("symbol") })
            );
        })
    );
    return React.createElement(
        "div",
        { className: "asset-select", style: style },
        label ? React.createElement(
            Form.Item,
            {
                colon: false,
                label: React.createElement(Translate, { content: label }),
                style: formItemStyle
            },
            select
        ) : select
    );
};

AssetSelectView.propTypes = {
    assets: ChainTypes.ChainAssetsList, // an array of assets
    placeholder: PropTypes.string, // a translation key for the placeholder text to be displayed when there is no preselected value
    // defaults to "utility.asset_select_placeholder"
    label: PropTypes.string, // translation key for the label
    style: PropTypes.object, // container div style
    formItemStyle: PropTypes.object, // Form.Item component style (used only if a label is passed)
    selectStyle: PropTypes.object // Select style

    // all other props are passed to the Select component
};

AssetSelectView.defaultPropTypes = {
    assets: [],
    placeholder: null,
    label: null,
    style: "",
    formItemStyle: "",
    selectStyle: ""
};

var AssetSelect = BindToChainState(AssetSelectView);

export default AssetSelect;