import React from "react";
import Translate from "react-translate-component";
import TranslateWithLinks from "../../Utility/TranslateWithLinks";
import ProposedOperation from "../ProposedOperation";

var ShortObjectId = function ShortObjectId(_ref) {
    var objectId = _ref.objectId;

    if (typeof objectId === "string") {
        var parts = objectId.split(".");
        var length = parts.length;

        if (length > 0) return "#" + parts[length - 1];
    }
    return objectId;
};

export var ProposalCreate = function ProposalCreate(_ref2) {
    var op = _ref2.op,
        result = _ref2.result,
        fromComponent = _ref2.fromComponent;

    if (fromComponent === "proposed_operation") {
        return React.createElement(
            "span",
            null,
            React.createElement(Translate, {
                component: "span",
                content: "proposal.proposal_create"
            })
        );
    } else {
        return React.createElement(
            "div",
            { className: "inline-block" },
            React.createElement(
                "span",
                null,
                React.createElement(TranslateWithLinks, {
                    string: "operation.proposal_create",
                    keys: [{
                        type: "account",
                        value: op[1] && op[1].fee_paying_account,
                        arg: "account"
                    }, {
                        value: result ? React.createElement(ShortObjectId, { objectId: result[1] }) : "",
                        arg: "proposal"
                    }]
                }),
                ":"
            ),
            React.createElement(
                "div",
                null,
                op[1] && op[1].proposed_ops.map(function (o, index) {
                    return React.createElement(ProposedOperation, {
                        op: o.op,
                        key: index,
                        index: index,
                        inverted: false,
                        hideFee: true,
                        hideOpLabel: true,
                        hideDate: true,
                        proposal: true
                    });
                })
            )
        );
    }
};