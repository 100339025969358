var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

import alt from "alt-instance";
import WalletUnlockActions from "actions/WalletUnlockActions";
import SettingsActions from "actions/SettingsActions";
import WalletDb from "stores/WalletDb";
import ls from "common/localStorage";
import { setLocalStorageType, isPersistantType } from "../lib/common/localStorage";

var STORAGE_KEY = "__graphene__";
var ss = new ls(STORAGE_KEY);

var WalletUnlockStore = function () {
    function WalletUnlockStore() {
        _classCallCheck(this, WalletUnlockStore);

        this.bindActions(WalletUnlockActions);

        // can't use settings store due to possible initialization race conditions
        var storedSettings = ss.get("settings_v4", {});
        if (storedSettings.passwordLogin === undefined) {
            storedSettings.passwordLogin = true;
        }
        var passwordLogin = storedSettings.passwordLogin;
        this.state = {
            locked: true,
            passwordLogin: passwordLogin,
            rememberMe: storedSettings.rememberMe === undefined ? true : storedSettings.rememberMe
        };

        this.walletLockTimeout = this._getTimeout(); // seconds (10 minutes)
        this.timeout = null;

        this.bindListeners({
            onChangeSetting: SettingsActions.changeSetting
        });

        // let timeoutSetting = this._getTimeout();

        // if (timeoutSetting) {
        //     this.walletLockTimeout = timeoutSetting;
        // }
    }

    _createClass(WalletUnlockStore, [{
        key: "onUnlock",
        value: function onUnlock(_ref) {
            var resolve = _ref.resolve,
                reject = _ref.reject;

            //DEBUG console.log('... onUnlock setState', WalletDb.isLocked())
            //
            this._setLockTimeout();
            if (!WalletDb.isLocked()) {
                this.setState({ locked: false });
                resolve();
                return;
            }

            this.setState({ resolve: resolve, reject: reject, locked: WalletDb.isLocked() });
        }
    }, {
        key: "onLock",
        value: function onLock(_ref2) {
            var resolve = _ref2.resolve;

            //DEBUG console.log('... WalletUnlockStore\tprogramatic lock', WalletDb.isLocked())
            if (WalletDb.isLocked()) {
                resolve();
                return;
            }
            WalletDb.onLock();
            this.setState({
                resolve: null,
                reject: null,
                locked: WalletDb.isLocked()
            });
            if (!this.state.rememberMe && !isPersistantType()) {
                setLocalStorageType("persistant");
            }
            resolve();
        }
    }, {
        key: "onCancel",
        value: function onCancel() {
            if (typeof this.state.reject === "function") this.state.reject({ isCanceled: true });
            this.setState({ resolve: null, reject: null });
        }
    }, {
        key: "onChange",
        value: function onChange() {
            this.setState({ locked: WalletDb.isLocked() });
        }
    }, {
        key: "onChangeSetting",
        value: function onChangeSetting(payload) {
            if (payload.setting === "walletLockTimeout") {
                this.walletLockTimeout = payload.value;
                this._clearLockTimeout();
                this._setLockTimeout();
            } else if (payload.setting === "passwordLogin") {
                this.setState({
                    passwordLogin: payload.value
                });
            } else if (payload.setting === "rememberMe") {
                this.setState({
                    rememberMe: payload.rememberMe
                });
            }
        }
    }, {
        key: "_setLockTimeout",
        value: function _setLockTimeout() {
            var _this = this;

            this._clearLockTimeout();
            /* If the timeout is different from zero, auto unlock the wallet using a timeout */
            if (!!this.walletLockTimeout) {
                this.timeout = setTimeout(function () {
                    if (!WalletDb.isLocked()) {
                        console.log("auto locking after", _this.walletLockTimeout, "s");
                        WalletDb.onLock();
                        _this.setState({ locked: true });
                    }
                }, this.walletLockTimeout * 1000);
            }
        }
    }, {
        key: "_clearLockTimeout",
        value: function _clearLockTimeout() {
            if (this.timeout) {
                clearTimeout(this.timeout);
                this.timeout = null;
            }
        }
    }, {
        key: "_getTimeout",
        value: function _getTimeout() {
            return parseInt(ss.get("lockTimeout", 600), 10);
        }
    }, {
        key: "onCheckLock",
        value: function onCheckLock() {
            this.setState({ locked: WalletDb.isLocked() });
        }
    }]);

    return WalletUnlockStore;
}();

export default alt.createStore(WalletUnlockStore, "WalletUnlockStore");