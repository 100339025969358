var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from "react";
import Translate from "react-translate-component";
import SettingsActions from "actions/SettingsActions";
import counterpart from "counterpart";
import { Modal, Button, Form, Input } from "bitshares-ui-style-guide";

var ws = "ws://";
var wss = "wss://";

var WebsocketAddModal = function (_React$Component) {
    _inherits(WebsocketAddModal, _React$Component);

    function WebsocketAddModal() {
        _classCallCheck(this, WebsocketAddModal);

        var _this = _possibleConstructorReturn(this, (WebsocketAddModal.__proto__ || Object.getPrototypeOf(WebsocketAddModal)).call(this));

        var protocol = window.location.protocol;
        _this.state = {
            protocol: protocol,
            ws: wss,
            name: "My node",
            type: "remove",
            remove: {},
            addError: null,
            existsError: null
        };

        _this.onRemoveSubmit = _this.onRemoveSubmit.bind(_this);
        return _this;
    }

    _createClass(WebsocketAddModal, [{
        key: "onServerInput",
        value: function onServerInput(e) {
            var state = {
                ws: e.target.value
            };

            if (this.apiExists(state.ws)) {
                state.existsError = true;
            } else {
                state.existsError = null;
            }

            if (state.ws.indexOf(wss) !== 0 && state.ws.indexOf(ws) !== 0) {
                state.addError = true;
            } else {
                state.addError = null;
            }

            this.setState(state);
        }
    }, {
        key: "apiExists",
        value: function apiExists(url) {
            return !!this.props.apis.find(function (api) {
                return api.url === url;
            });
        }
    }, {
        key: "onNameInput",
        value: function onNameInput(e) {
            this.setState({ name: e.target.value });
        }
    }, {
        key: "close",
        value: function close() {
            this.setState({
                isModalVisible: false
            });
        }
    }, {
        key: "onAddSubmit",
        value: function onAddSubmit(e) {
            e.preventDefault();

            SettingsActions.addWS({ location: this.state.name, url: this.state.ws });

            this.setState({
                ws: this.state.protocol === "https:" ? wss : ws,
                name: ""
            });
            this.props.onAddNodeClose();
        }
    }, {
        key: "onRemoveSubmit",
        value: function onRemoveSubmit(e) {
            var _this2 = this;

            e.preventDefault();
            var removeIndex = void 0;
            this.props.apis.forEach(function (api, index) {
                if (api.url === _this2.props.removeNode.url) {
                    removeIndex = index;
                }
            });

            /* Set default if removing currently active API server */
            if (this.props.api === this.props.apis[removeIndex].url) {
                SettingsActions.changeSetting.defer({
                    setting: "apiServer",
                    value: this.props.apis[0].url
                });
                this.props.changeConnection(this.props.apis[0].url);
            }

            SettingsActions.removeWS(removeIndex);
            this.props.onRemoveNodeClose();
        }
    }, {
        key: "_renderAddModal",
        value: function _renderAddModal() {
            var help = "";
            var validateStatus = "";

            if (this.state.existsError) {
                validateStatus = "error";
                help = counterpart.translate("settings.node_already_exists");
            }

            if (this.state.addError) {
                validateStatus = "error";
                help = counterpart.translate("settings.valid_node_url");
            }

            return React.createElement(
                Modal,
                {
                    visible: this.props.isAddNodeModalVisible,
                    id: "ws_modal_add",
                    ref: "ws_modal_add",
                    title: counterpart.translate("settings.add_ws"),
                    overlay: true,
                    onCancel: this.props.onAddNodeClose,
                    overlayClose: false,
                    footer: [React.createElement(
                        Button,
                        {
                            key: "confirm",
                            type: "primary",
                            disabled: this.state.addError || this.state.existsError,
                            onClick: this.onAddSubmit.bind(this)
                        },
                        counterpart.translate("transfer.confirm")
                    ), React.createElement(
                        Button,
                        { key: "cancel", onClick: this.props.onAddNodeClose },
                        counterpart.translate("account.perm.cancel")
                    )]
                },
                React.createElement(
                    "div",
                    { className: "grid-content" },
                    React.createElement(
                        Form,
                        { layout: "vertical" },
                        React.createElement(
                            Form.Item,
                            { label: "Name" },
                            React.createElement(Input, {
                                onChange: this.onNameInput.bind(this),
                                value: this.state.name
                            })
                        ),
                        React.createElement(
                            Form.Item,
                            {
                                label: "Address",
                                validateStatus: validateStatus,
                                help: help
                            },
                            React.createElement(Input, {
                                value: this.state.ws,
                                onChange: this.onServerInput.bind(this)
                            })
                        )
                    )
                )
            );
        }
    }, {
        key: "_renderRemoveModal",
        value: function _renderRemoveModal() {
            if (!this.props.api) {
                return null;
            }

            var footer = [React.createElement(
                Button,
                { key: "submit", onClick: this.onRemoveSubmit },
                counterpart.translate("transfer.confirm")
            ), React.createElement(
                Button,
                {
                    key: "cancel",
                    type: "primary",
                    onClick: this.props.onRemoveNodeClose
                },
                counterpart.translate("modal.cancel")
            )];

            return React.createElement(
                Modal,
                {
                    onCancel: this.props.onRemoveNodeClose,
                    title: counterpart.translate("settings.remove_ws"),
                    visible: this.props.isRemoveNodeModalVisible,
                    footer: footer
                },
                React.createElement(
                    "div",
                    { className: "grid-content no-overflow" },
                    React.createElement(
                        "section",
                        { className: "block-list" },
                        React.createElement(
                            "p",
                            null,
                            React.createElement(Translate, {
                                component: "span",
                                content: "settings.confirm_remove",
                                "with": {
                                    name: this.props.removeNode && this.props.removeNode.name
                                }
                            })
                        )
                    )
                )
            );
        }
    }, {
        key: "render",
        value: function render() {
            return React.createElement(
                "div",
                null,
                this._renderAddModal(),
                this._renderRemoveModal()
            );
        }
    }]);

    return WebsocketAddModal;
}(React.Component);

export default WebsocketAddModal;