var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

import utils from "./utils";
import { BigNumber } from "bignumber.js";

var GRAPHENE_100_PERCENT = 10000;

function limitByPrecision(value) {
    var p = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 8;

    if (typeof p !== "number") throw new Error("Input must be a number");
    var valueString = value.toString();
    var splitString = valueString.split(".");
    if (splitString.length === 1 || splitString.length === 2 && splitString[1].length <= p) {
        return parseFloat(valueString);
    } else {
        return parseFloat(splitString[0] + "." + splitString[1].substr(0, p));
    }
}

function precisionToRatio(p) {
    if (typeof p !== "number") throw new Error("Input must be a number");
    return Math.pow(10, p);
}

function didOrdersChange(newOrders, oldOrders) {
    var changed = oldOrders && oldOrders.size !== newOrders.size;
    if (changed) return changed;

    newOrders.forEach(function (a, key) {
        var oldOrder = oldOrders.get(key);
        if (!oldOrder) {
            changed = true;
        } else {
            if (a.market_base === oldOrder.market_base) {
                changed = changed || a.ne(oldOrder);
            }
        }
    });
    return changed;
}

var Asset = function () {
    function Asset() {
        var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
            _ref$asset_id = _ref.asset_id,
            asset_id = _ref$asset_id === undefined ? "1.3.0" : _ref$asset_id,
            _ref$amount = _ref.amount,
            amount = _ref$amount === undefined ? 0 : _ref$amount,
            _ref$precision = _ref.precision,
            precision = _ref$precision === undefined ? 5 : _ref$precision,
            _ref$real = _ref.real,
            real = _ref$real === undefined ? null : _ref$real;

        _classCallCheck(this, Asset);

        this.satoshi = precisionToRatio(precision * 1);
        this.asset_id = asset_id;
        this.setAmount({ sats: amount, real: real });
        this.precision = precision;
    }

    _createClass(Asset, [{
        key: "hasAmount",
        value: function hasAmount() {
            return this.amount > 0;
        }
    }, {
        key: "toSats",
        value: function toSats() {
            var amount = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;

            // Return the full integer amount in 'satoshis'
            // Round to prevent floating point math errors
            return Math.round(amount * this.satoshi);
        }
    }, {
        key: "setAmount",
        value: function setAmount(_ref2) {
            var sats = _ref2.sats,
                real = _ref2.real;

            if (typeof sats === "string") sats = parseInt(sats, 10);
            if (typeof real === "string") real = parseFloat(real);

            if (typeof sats !== "number" && typeof real !== "number") {
                throw new Error("Invalid arguments for setAmount");
            }
            if (typeof real === "number") {
                this.amount = this.toSats(real);
                this._clearCache();
            } else if (typeof sats === "number") {
                this.amount = Math.floor(sats);
                this._clearCache();
            } else {
                throw new Error("Invalid setAmount input");
            }
        }
    }, {
        key: "_clearCache",
        value: function _clearCache() {
            this._real_amount = null;
        }
    }, {
        key: "getAmount",
        value: function getAmount() {
            var _ref3 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
                _ref3$real = _ref3.real,
                real = _ref3$real === undefined ? false : _ref3$real;

            if (real) {
                if (this._real_amount) return this._real_amount;
                return this._real_amount = limitByPrecision(this.amount / this.toSats(), this.precision);
            } else {
                return Math.floor(this.amount);
            }
        }
    }, {
        key: "plus",
        value: function plus(asset) {
            if (asset.asset_id !== this.asset_id) throw new Error("Assets are not the same type");
            this.amount += asset.amount;
            this._clearCache();
        }
    }, {
        key: "minus",
        value: function minus(asset) {
            if (asset.asset_id !== this.asset_id) throw new Error("Assets are not the same type");
            this.amount -= asset.amount;
            this.amount = Math.max(0, this.amount);
            this._clearCache();
        }
    }, {
        key: "equals",
        value: function equals(asset) {
            return this.asset_id === asset.asset_id && this.getAmount() === asset.getAmount();
        }
    }, {
        key: "ne",
        value: function ne(asset) {
            return !this.equals(asset);
        }
    }, {
        key: "gt",
        value: function gt(asset) {
            return this.getAmount() > asset.getAmount();
        }
    }, {
        key: "lt",
        value: function lt(asset) {
            return this.getAmount() < asset.getAmount();
        }
    }, {
        key: "times",
        value: function times(p) {
            var isBid = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

            // asset amount times a price p
            var temp = void 0,
                amount = void 0;
            if (this.asset_id === p.base.asset_id) {
                temp = this.amount * p.quote.amount / p.base.amount;
                amount = Math.floor(temp);
                /*
                 * Sometimes prices are inexact for the relevant amounts, in the case
                 * of bids this means we need to round up in order to pay 1 sat more
                 * than the floored price, if we don't do this the orders don't match
                 */
                if (isBid && temp !== amount) {
                    amount += 1;
                }
                if (amount === 0) amount = 1;
                return new Asset({
                    asset_id: p.quote.asset_id,
                    amount: amount,
                    precision: p.quote.precision
                });
            } else if (this.asset_id === p.quote.asset_id) {
                temp = this.amount * p.base.amount / p.quote.amount;
                amount = Math.floor(temp);
                /*
                 * Sometimes prices are inexact for the relevant amounts, in the case
                 * of bids this means we need to round up in order to pay 1 sat more
                 * than the floored price, if we don't do this the orders don't match
                 */
                if (isBid && temp !== amount) {
                    amount += 1;
                }
                if (amount === 0) amount = 1;
                return new Asset({
                    asset_id: p.base.asset_id,
                    amount: amount,
                    precision: p.base.precision
                });
            }
            throw new Error("Invalid asset types for price multiplication");
        }
    }, {
        key: "divide",
        value: function divide(quote) {
            var base = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this;

            return new Price({ base: base, quote: quote });
        }
    }, {
        key: "toObject",
        value: function toObject() {
            return {
                asset_id: this.asset_id,
                amount: this.amount
            };
        }
    }, {
        key: "clone",
        value: function clone() {
            var amount = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.amount;

            return new Asset({
                amount: amount,
                asset_id: this.asset_id,
                precision: this.precision
            });
        }
    }]);

    return Asset;
}();

/**
 * @brief The price struct stores asset prices in the Graphene system.
 *
 * A price is defined as a ratio between two assets, and represents a possible exchange rate between those two
 * assets. prices are generally not stored in any simplified form, i.e. a price of (1000 CORE)/(20 USD) is perfectly
 * normal.
 *
 * The assets within a price are labeled base and quote. Throughout the Graphene code base, the convention used is
 * that the base asset is the asset being sold, and the quote asset is the asset being purchased, where the price is
 * represented as base/quote, so in the example price above the seller is looking to sell CORE asset and get USD in
 * return.
 */

var Price = function () {
    function Price() {
        var _ref4 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
            base = _ref4.base,
            quote = _ref4.quote,
            _ref4$real = _ref4.real,
            real = _ref4$real === undefined ? false : _ref4$real;

        _classCallCheck(this, Price);

        if (!base || !quote) {
            throw new Error("Base and Quote assets must be defined");
        }
        if (base.asset_id === quote.asset_id) {
            throw new Error("Base and Quote assets must be different");
        }

        if (!base.asset_id || !("amount" in base) || !quote.asset_id || !("amount" in quote)) {
            throw new Error("Invalid Price inputs");
        }

        this.base = base.clone();
        this.quote = quote.clone();

        this.setPriceFromReal(real);
    }

    _createClass(Price, [{
        key: "setPriceFromReal",
        value: function setPriceFromReal(real) {
            var base = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this.base;
            var quote = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : this.quote;

            if (real && typeof real === "number") {
                /*
                 * In order to make large numbers work properly, we assume numbers
                 * larger than 100k do not need more than 5 decimals. Without this we
                 * quickly encounter JavaScript floating point errors for large numbers.
                 */

                if (real > 100000) {
                    real = limitByPrecision(real, 5);
                }
                var frac = new BigNumber(real.toString()).toFraction();
                var baseSats = base.toSats(),
                    quoteSats = quote.toSats();
                var numRatio = baseSats / quoteSats,
                    denRatio = quoteSats / baseSats;

                if (baseSats >= quoteSats) {
                    denRatio = 1;
                } else {
                    numRatio = 1;
                }

                base.setAmount({ sats: frac[0] * numRatio });
                quote.setAmount({ sats: frac[1] * denRatio });
            } else if (real === 0) {
                base.setAmount({ sats: 0 });
                quote.setAmount({ sats: 0 });
            }
        }
    }, {
        key: "getUnits",
        value: function getUnits() {
            return this.base.asset_id + "_" + this.quote.asset_id;
        }
    }, {
        key: "isValid",
        value: function isValid() {
            return this.base.amount !== 0 && this.quote.amount !== 0 && !isNaN(this.toReal()) && isFinite(this.toReal());
        }
    }, {
        key: "toReal",
        value: function toReal() {
            var sameBase = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

            var key = sameBase ? "_samebase_real" : "_not_samebase_real";
            if (this[key]) {
                return this[key];
            }
            var real = sameBase ? this.quote.amount * this.base.toSats() / (this.base.amount * this.quote.toSats()) : this.base.amount * this.quote.toSats() / (this.quote.amount * this.base.toSats());
            return this[key] = parseFloat(real.toFixed(8)); // toFixed and parseFloat helps avoid floating point errors for really big or small numbers
        }
    }, {
        key: "invert",
        value: function invert() {
            return new Price({
                base: this.quote,
                quote: this.base
            });
        }
    }, {
        key: "clone",
        value: function clone() {
            var real = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

            return new Price({
                base: this.base,
                quote: this.quote,
                real: real
            });
        }
    }, {
        key: "equals",
        value: function equals(b) {
            if (this.base.asset_id !== b.base.asset_id || this.quote.asset_id !== b.quote.asset_id) {
                // console.error("Cannot compare prices for different assets");
                return false;
            }
            var amult = b.quote.amount * this.base.amount;
            var bmult = this.quote.amount * b.base.amount;

            return amult === bmult;
        }
    }, {
        key: "lt",
        value: function lt(b) {
            if (this.base.asset_id !== b.base.asset_id || this.quote.asset_id !== b.quote.asset_id) {
                throw new Error("Cannot compare prices for different assets");
            }
            var amult = b.quote.amount * this.base.amount;
            var bmult = this.quote.amount * b.base.amount;

            return amult < bmult;
        }
    }, {
        key: "lte",
        value: function lte(b) {
            return this.equals(b) || this.lt(b);
        }
    }, {
        key: "ne",
        value: function ne(b) {
            return !this.equals(b);
        }
    }, {
        key: "gt",
        value: function gt(b) {
            return !this.lte(b);
        }
    }, {
        key: "gte",
        value: function gte(b) {
            return !this.lt(b);
        }
    }, {
        key: "toObject",
        value: function toObject() {
            return {
                base: this.base.toObject(),
                quote: this.quote.toObject()
            };
        }
    }, {
        key: "times",
        value: function times(p) {
            var common = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "1.3.0";

            var p2 = p.base.asset_id === common && this.quote.asset_id === common || p.quote.asset_id === common && this.base.asset_id === common ? p.clone() : p.invert();

            var np = p2.toReal() * this.toReal();
            return new Price({
                base: p2.base,
                quote: this.quote,
                real: np
            });
        }
    }]);

    return Price;
}();

var FeedPrice = function (_Price) {
    _inherits(FeedPrice, _Price);

    function FeedPrice(_ref5) {
        var priceObject = _ref5.priceObject,
            assets = _ref5.assets,
            market_base = _ref5.market_base,
            sqr = _ref5.sqr,
            _ref5$real = _ref5.real,
            real = _ref5$real === undefined ? false : _ref5$real;

        _classCallCheck(this, FeedPrice);

        if (!priceObject || (typeof priceObject === "undefined" ? "undefined" : _typeof(priceObject)) !== "object" || !market_base || !assets || !sqr) {
            throw new Error("Invalid FeedPrice inputs");
        }

        if (priceObject.toJS) {
            priceObject = priceObject.toJS();
        }

        var inverted = market_base === priceObject.base.asset_id;

        var base = new Asset({
            asset_id: priceObject.base.asset_id,
            amount: priceObject.base.amount,
            precision: assets[priceObject.base.asset_id].precision
        });

        var quote = new Asset({
            asset_id: priceObject.quote.asset_id,
            amount: priceObject.quote.amount,
            precision: assets[priceObject.quote.asset_id].precision
        });

        var _this = _possibleConstructorReturn(this, (FeedPrice.__proto__ || Object.getPrototypeOf(FeedPrice)).call(this, {
            base: inverted ? quote : base,
            quote: inverted ? base : quote,
            real: real
        }));

        _this.sqr = parseInt(sqr, 10) / 1000;
        _this.inverted = inverted;
        return _this;
    }

    _createClass(FeedPrice, [{
        key: "getSqueezePrice",
        value: function getSqueezePrice() {
            var _ref6 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
                _ref6$real = _ref6.real,
                real = _ref6$real === undefined ? false : _ref6$real;

            if (!this._squeeze_price) {
                this._squeeze_price = this.clone();
                if (this.inverted) {
                    this._squeeze_price.base.amount = Math.floor(this._squeeze_price.base.amount * this.sqr * 1000);
                    this._squeeze_price.quote.amount *= 1000;
                } else if (!this.inverted) {
                    this._squeeze_price.quote.amount = Math.floor(this._squeeze_price.quote.amount * this.sqr * 1000);
                    this._squeeze_price.base.amount *= 1000;
                }
            }

            if (real) {
                return this._squeeze_price.toReal();
            }
            return this._squeeze_price;
        }
    }]);

    return FeedPrice;
}(Price);

var LimitOrderCreate = function () {
    function LimitOrderCreate() {
        var _ref7 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
            for_sale = _ref7.for_sale,
            to_receive = _ref7.to_receive,
            _ref7$seller = _ref7.seller,
            seller = _ref7$seller === undefined ? "" : _ref7$seller,
            _ref7$expiration = _ref7.expiration,
            expiration = _ref7$expiration === undefined ? new Date() : _ref7$expiration,
            _ref7$fill_or_kill = _ref7.fill_or_kill,
            fill_or_kill = _ref7$fill_or_kill === undefined ? false : _ref7$fill_or_kill,
            _ref7$fee = _ref7.fee,
            fee = _ref7$fee === undefined ? { amount: 0, asset_id: "1.3.0" } : _ref7$fee;

        _classCallCheck(this, LimitOrderCreate);

        if (!for_sale || !to_receive) {
            throw new Error("Missing order amounts");
        }

        if (for_sale.asset_id === to_receive.asset_id) {
            throw new Error("Order assets cannot be the same");
        }

        this.amount_for_sale = for_sale;
        this.min_to_receive = to_receive;
        this.setExpiration(expiration);
        this.fill_or_kill = fill_or_kill;
        this.seller = seller;
        this.fee = fee;
    }

    _createClass(LimitOrderCreate, [{
        key: "setExpiration",
        value: function setExpiration() {
            var expiration = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

            if (!expiration) {
                expiration = new Date();
                expiration.setYear(expiration.getFullYear() + 5);
            }
            this.expiration = expiration;
        }
    }, {
        key: "getExpiration",
        value: function getExpiration() {
            return this.expiration;
        }
    }, {
        key: "toObject",
        value: function toObject() {
            return {
                seller: this.seller,
                min_to_receive: this.min_to_receive.toObject(),
                amount_to_sell: this.amount_for_sale.toObject(),
                expiration: this.expiration,
                fill_or_kill: this.fill_or_kill,
                fee: this.fee
            };
        }
    }]);

    return LimitOrderCreate;
}();

var LimitOrder = function () {
    function LimitOrder(order, assets, market_base) {
        _classCallCheck(this, LimitOrder);

        if (!market_base) {
            throw new Error("LimitOrder requires a market_base id");
        }
        this.order = order;
        this.assets = assets;
        this.market_base = market_base;
        this.id = order.id;
        this.sellers = [order.seller];
        this.expiration = order.expiration && new Date(utils.makeISODateString(order.expiration));
        this.seller = order.seller;
        this.for_sale = parseInt(order.for_sale, 10); // asset id is sell_price.base.asset_id

        var base = new Asset({
            asset_id: order.sell_price.base.asset_id,
            amount: parseInt(order.sell_price.base.amount, 10),
            precision: assets[order.sell_price.base.asset_id].precision
        });
        var quote = new Asset({
            asset_id: order.sell_price.quote.asset_id,
            amount: parseInt(order.sell_price.quote.amount, 10),
            precision: assets[order.sell_price.quote.asset_id].precision
        });

        this.sell_price = new Price({
            base: base,
            quote: quote
        });

        this.fee = order.deferred_fee;
    }

    _createClass(LimitOrder, [{
        key: "getPrice",
        value: function getPrice() {
            var p = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.sell_price;

            if (this._real_price) {
                return this._real_price;
            }
            return this._real_price = p.toReal(p.base.asset_id === this.market_base);
        }
    }, {
        key: "isBid",
        value: function isBid() {
            return !(this.sell_price.base.asset_id === this.market_base);
        }
    }, {
        key: "isCall",
        value: function isCall() {
            return false;
        }
    }, {
        key: "sellPrice",
        value: function sellPrice() {
            return this.sell_price;
        }
    }, {
        key: "amountForSale",
        value: function amountForSale() {
            if (this._for_sale) return this._for_sale;
            return this._for_sale = new Asset({
                asset_id: this.sell_price.base.asset_id,
                amount: this.for_sale,
                precision: this.assets[this.sell_price.base.asset_id].precision
            });
        }
    }, {
        key: "amountToReceive",
        value: function amountToReceive() {
            var isBid = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.isBid();

            if (this._to_receive) return this._to_receive;
            this._to_receive = this.amountForSale().times(this.sell_price, isBid);
            return this._to_receive;
        }
    }, {
        key: "sum",
        value: function sum(order) {
            var newOrder = this.clone();
            if (newOrder.sellers.indexOf(order.seller) === -1) {
                newOrder.sellers.push(order.seller);
            }
            newOrder.for_sale += order.for_sale;

            return newOrder;
        }
    }, {
        key: "isMine",
        value: function isMine(id) {
            return this.sellers.indexOf(id) !== -1;
        }
    }, {
        key: "clone",
        value: function clone() {
            return new LimitOrder(this.order, this.assets, this.market_base);
        }
    }, {
        key: "ne",
        value: function ne(order) {
            return this.sell_price.ne(order.sell_price) || this.for_sale !== order.for_sale;
        }
    }, {
        key: "equals",
        value: function equals(order) {
            return !this.ne(order);
        }
    }, {
        key: "setTotalToReceive",
        value: function setTotalToReceive(total) {
            this.total_to_receive = total;
        }
    }, {
        key: "setTotalForSale",
        value: function setTotalForSale(total) {
            this.total_for_sale = total;
            this._total_to_receive = null;
        }
    }, {
        key: "totalToReceive",
        value: function totalToReceive() {
            var _ref8 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
                _ref8$noCache = _ref8.noCache,
                noCache = _ref8$noCache === undefined ? false : _ref8$noCache;

            if (!noCache && this._total_to_receive) return this._total_to_receive;
            this._total_to_receive = (this.total_to_receive || this.amountToReceive()).clone();
            return this._total_to_receive;
        }
    }, {
        key: "totalForSale",
        value: function totalForSale() {
            var _ref9 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
                _ref9$noCache = _ref9.noCache,
                noCache = _ref9$noCache === undefined ? false : _ref9$noCache;

            if (!noCache && this._total_for_sale) return this._total_for_sale;
            return this._total_for_sale = (this.total_for_sale || this.amountForSale()).clone();
        }
    }]);

    return LimitOrder;
}();

var CallOrder = function () {
    function CallOrder(order, assets, market_base, feed, mcr) {
        var is_prediction_market = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : false;

        _classCallCheck(this, CallOrder);

        if (!order || !assets || !market_base || !feed || !mcr) {
            throw new Error("CallOrder missing inputs");
        }

        this.mcr = mcr;
        this.isSum = false;
        this.order = order;
        this.assets = assets;
        this.market_base = market_base;
        this.is_prediction_market = is_prediction_market;
        /* inverted = price in collateral / debt, !inverted = price in debt / collateral */
        this.inverted = market_base === order.call_price.base.asset_id;
        this.id = order.id;
        this.borrower = order.borrower;
        this.borrowers = [order.borrower];
        this.target_collateral_ratio = order.target_collateral_ratio ? order.target_collateral_ratio / 1000 : null;
        /* Collateral asset type is call_price.base.asset_id */
        this.collateral = parseInt(order.collateral, 10);
        this.collateral_id = order.call_price.base.asset_id;
        /* Debt asset type is call_price.quote.asset_id */
        this.debt = parseInt(order.debt, 10);
        this.debt_id = order.call_price.quote.asset_id;

        this.precisionsRatio = precisionToRatio(assets[this.debt_id].precision) / precisionToRatio(assets[this.collateral_id].precision);

        /*
         * The call price is DEBT * MCR / COLLATERAL.
         * Since bitshares-core 3.0.0 this is no longer done by the witness_node.
         * Deal with the MCR (maintenance collateral ratio) here.
         */

        var base = new Asset({
            asset_id: this.collateral_id,
            amount: order.collateral,
            precision: assets[this.collateral_id].precision
        });
        var quote = new Asset({
            asset_id: this.debt_id,
            amount: order.debt * (mcr / 1000),
            precision: assets[this.debt_id].precision
        });

        this.call_price = new Price({
            base: base,
            quote: quote
        });

        if (this.inverted) this.call_price = this.call_price.invert();

        if (feed.base.asset_id !== this.call_price.base.asset_id) {
            throw new Error("Feed price assets and call price assets must be the same");
        }

        this.feed_price = feed;

        /* BSIP38 implementation */
        this.assignMaxDebtAndCollateral();

        this.expiration = { toLocaleString: function toLocaleString() {
                return null;
            } };
    }

    _createClass(CallOrder, [{
        key: "clone",
        value: function clone() {
            var f = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.feed_price;

            return new CallOrder(this.order, this.assets, this.market_base, f, this.mcr);
        }
    }, {
        key: "setFeed",
        value: function setFeed(f) {
            this.feed_price = f;
            this._clearCache();
        }
    }, {
        key: "getPrice",
        value: function getPrice() {
            var squeeze = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
            var p = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this.call_price;

            if (squeeze) {
                return this.getSqueezePrice();
            }
            if (this._real_price) {
                return this._real_price;
            }
            return this._real_price = p.toReal(p.base.asset_id === this.market_base);
        }
    }, {
        key: "getFeedPrice",
        value: function getFeedPrice() {
            var f = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.feed_price;

            if (this._feed_price) {
                return this._feed_price;
            }
            return this._feed_price = f.toReal(f.base.asset_id === this.market_base);
        }
    }, {
        key: "getSqueezePrice",
        value: function getSqueezePrice() {
            var f = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.feed_price;

            if (this._squeeze_price) {
                return this._squeeze_price;
            }
            return this._squeeze_price = f.getSqueezePrice().toReal();
        }
    }, {
        key: "isMarginCalled",
        value: function isMarginCalled() {
            if (this.is_prediction_market) return false;
            return this.isBid() ? this.call_price.lt(this.feed_price) : this.call_price.gt(this.feed_price);
        }
    }, {
        key: "isBid",
        value: function isBid() {
            return !this.inverted;
        }
    }, {
        key: "isCall",
        value: function isCall() {
            return true;
        }
    }, {
        key: "sellPrice",
        value: function sellPrice() {
            var squeeze = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

            if (squeeze) {
                return this.isBid() ? this.feed_price.getSqueezePrice() : this.feed_price.getSqueezePrice().invert();
            }
            return this.call_price;
        }
    }, {
        key: "getCollateral",
        value: function getCollateral() {
            if (this._collateral) return this._collateral;
            return this._collateral = new Asset({
                amount: this.collateral,
                asset_id: this.collateral_id,
                precision: this.assets[this.collateral_id].precision
            });
        }
    }, {
        key: "_getMaxCollateralToSell",
        value: function _getMaxCollateralToSell() {
            /*
            BSIP38: https://github.com/bitshares/bsips/blob/master/bsip-0038.md
            * max_amount_to_sell = (debt * target_CR - collateral * feed_price)
            * / (target_CR * match_price - feed_price)
            */
            if (this.target_collateral_ratio && this.getRatio() < this.target_collateral_ratio) {
                var feed_price = this._getFeedPrice();
                var match_price = this._getMatchPrice();
                var nominator = this.debt * this.target_collateral_ratio - this.collateral * feed_price;
                var denominator = this.target_collateral_ratio * match_price - feed_price;
                return nominator / denominator;
            } else {
                return this.collateral;
            }
        }
    }, {
        key: "_getMaxDebtToCover",
        value: function _getMaxDebtToCover() {
            var max_collateral_to_sell = this._getMaxCollateralToSell();
            var match_price = this._getMatchPrice();
            return max_collateral_to_sell * match_price;
        }

        /* Returns satoshi feed price in consistent units of debt/collateral */

    }, {
        key: "_getFeedPrice",
        value: function _getFeedPrice() {
            return (this.inverted ? this.getFeedPrice() : this.feed_price.invert().toReal()) * this.precisionsRatio;
        }

        /* Returns satoshi match price in consistent units of debt/collateral */

    }, {
        key: "_getMatchPrice",
        value: function _getMatchPrice() {
            return (this.inverted ? this.getSqueezePrice() : parseFloat((1 / this.getSqueezePrice()).toFixed(8))) * this.precisionsRatio;
        }
    }, {
        key: "assignMaxDebtAndCollateral",
        value: function assignMaxDebtAndCollateral() {
            if (!this.target_collateral_ratio) return;
            var match_price = this._getMatchPrice();
            var max_debt_to_cover = this._getMaxDebtToCover(),
                max_debt_to_cover_int = void 0;
            /*
             * We may calculate like this: if max_debt_to_cover has no fractional
             * component (e.g. 5.00 as opposed to 5.23), plus it by one Satoshi;
             * otherwise, round it up. An effectively same approach is to round
             * down then add one Satoshi onto the result:
             */
            if (Math.round(max_debt_to_cover) !== max_debt_to_cover) {
                max_debt_to_cover_int = Math.floor(max_debt_to_cover) + 1;
            }

            /*
             * With max_debt_to_cover_int in integer, max_amount_to_sell_int in
             * integer can be calculated as: max_amount_to_sell_int =
             * round_up(max_debt_to_cover_int / match_price)
             */
            var max_collateral_to_sell_int = Math.ceil(max_debt_to_cover_int / match_price);

            /* Assign to Assets */
            this.max_debt_to_cover = new Asset({
                amount: max_debt_to_cover_int,
                asset_id: this.debt_id,
                precision: this.assets[this.debt_id].precision
            });

            this.max_collateral_to_sell = new Asset({
                amount: max_collateral_to_sell_int,
                asset_id: this.collateral_id,
                precision: this.assets[this.collateral_id].precision
            });
        }

        /*
         * Assume a USD:BTS market
         * The call order will always be selling BTS in order to buy USD
         * The asset being sold is always the collateral, which is call_price.base.asset_id.
         * The amount being sold depends on how big the debt is, only enough
         * collateral will be sold to cover the debt
         */

    }, {
        key: "amountForSale",
        value: function amountForSale() {
            var isBid = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.isBid();

            /*
            BSIP38:
            * max_amount_to_sell = (debt * target_CR - collateral * feed_price)
            * / (target_CR * match_price - feed_price)
            */
            if (this._for_sale) return this._for_sale;
            if (this._useTargetCR() || this.isSum) {
                return this._for_sale = this.max_collateral_to_sell;
            }
            return this._for_sale = this.amountToReceive().times(this.feed_price.getSqueezePrice(), isBid);
        }
    }, {
        key: "_useTargetCR",
        value: function _useTargetCR() {
            // if (!!this.target_collateral_ratio &&
            // this.getRatio() < this.target_collateral_ratio) {
            //     console.log("Using target cr", this.target_collateral_ratio, "getRatio", this.getRatio());
            // }
            return !!this.target_collateral_ratio && this.getRatio() < this.target_collateral_ratio;
        }
    }, {
        key: "amountToReceive",
        value: function amountToReceive() {
            if (this._to_receive) return this._to_receive;
            if (this._useTargetCR() || this.isSum) {
                return this._to_receive = this.max_debt_to_cover;
            }
            return this._to_receive = new Asset({
                asset_id: this.debt_id,
                amount: this.debt,
                precision: this.assets[this.debt_id].precision
            });
        }
    }, {
        key: "sum",
        value: function sum(order) {
            var newOrder = this.clone();
            if (newOrder.borrowers.indexOf(order.borrower) === -1) {
                newOrder.borrowers.push(order.borrower);
            }

            var orderUseCR = order._useTargetCR();
            var newOrderUseCR = newOrder._useTargetCR();
            /* Determine which debt values to use */
            var orderDebt = order.iSum ? order.debt : orderUseCR ? order.max_debt_to_cover.getAmount() : order.amountToReceive().getAmount();
            var newOrderDebt = newOrder.iSum ? newOrder.debt : newOrderUseCR ? newOrder.max_debt_to_cover.getAmount() : newOrder.amountToReceive().getAmount();

            /* Determine which collateral values to use */
            var orderCollateral = order.iSum ? order.collateral : orderUseCR ? order.max_collateral_to_sell.getAmount() : order.amountForSale().getAmount();
            var newOrderCollateral = newOrder.iSum ? newOrder.collateral : newOrderUseCR ? newOrder.max_collateral_to_sell.getAmount() : newOrder.amountForSale().getAmount();

            newOrder.debt = newOrderDebt + orderDebt;
            newOrder.collateral = newOrderCollateral + orderCollateral;
            newOrder._clearCache();

            /* Assign max collateral to sell and max debt to buy as the summed amounts */
            newOrder.max_debt_to_cover = new Asset({
                amount: newOrder.debt,
                asset_id: this.debt_id,
                precision: this.assets[this.debt_id].precision
            });

            newOrder.max_collateral_to_sell = new Asset({
                amount: newOrder.collateral,
                asset_id: this.collateral_id,
                precision: this.assets[this.collateral_id].precision
            });

            newOrder.isSum = true;

            return newOrder;
        }
    }, {
        key: "_clearCache",
        value: function _clearCache() {
            this._for_sale = null;
            this._to_receive = null;
            this._feed_price = null;
            this._squeeze_price = null;
            this._total_to_receive = null;
            this._total_for_sale = null;
        }
    }, {
        key: "ne",
        value: function ne(order) {
            return this.call_price.ne(order.call_price) || this.feed_price.ne(order.feed_price) || this.debt !== order.debt || this.collateral !== order.collateral;
        }
    }, {
        key: "equals",
        value: function equals(order) {
            return !this.ne(order);
        }
    }, {
        key: "setTotalToReceive",
        value: function setTotalToReceive(total) {
            this.total_to_receive = total;
        }
    }, {
        key: "setTotalForSale",
        value: function setTotalForSale(total) {
            this.total_for_sale = total;
        }
    }, {
        key: "totalToReceive",
        value: function totalToReceive() {
            var _ref10 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
                _ref10$noCache = _ref10.noCache,
                noCache = _ref10$noCache === undefined ? false : _ref10$noCache;

            if (!noCache && this._total_to_receive) return this._total_to_receive;
            this._total_to_receive = (this.total_to_receive || this.amountToReceive()).clone();
            return this._total_to_receive;
        }
    }, {
        key: "totalForSale",
        value: function totalForSale() {
            var _ref11 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
                _ref11$noCache = _ref11.noCache,
                noCache = _ref11$noCache === undefined ? false : _ref11$noCache;

            if (!noCache && this._total_for_sale) return this._total_for_sale;
            return this._total_for_sale = (this.total_for_sale || this.amountForSale()).clone();
        }
    }, {
        key: "getRatio",
        value: function getRatio() {
            return this.collateral / ( // CORE
            this.debt / // DEBT
            this._getFeedPrice()) // DEBT/CORE
            ;
        }
    }, {
        key: "getStatus",
        value: function getStatus() {
            var mr = this.assets[this.debt_id].bitasset.current_feed.maintenance_collateral_ratio / 1000;
            var cr = this.getRatio();

            if (isNaN(cr)) return null;
            if (cr < mr) {
                return "danger";
            } else if (cr < mr + 0.5) {
                return "warning";
            } else {
                return "";
            }
        }
    }, {
        key: "isMine",
        value: function isMine(id) {
            return this.borrowers.indexOf(id) !== -1;
        }
    }]);

    return CallOrder;
}();

var SettleOrder = function (_LimitOrder) {
    _inherits(SettleOrder, _LimitOrder);

    function SettleOrder(order, assets, market_base, feed_price, bitasset_options) {
        _classCallCheck(this, SettleOrder);

        if (!feed_price || !bitasset_options) {
            throw new Error("SettleOrder needs feed_price and bitasset_options inputs");
        }

        order.sell_price = feed_price.toObject();
        order.seller = order.owner;

        var _this2 = _possibleConstructorReturn(this, (SettleOrder.__proto__ || Object.getPrototypeOf(SettleOrder)).call(this, order, assets, market_base));

        _this2.offset_percent = bitasset_options.force_settlement_offset_percent;
        _this2.settlement_date = new Date(utils.makeISODateString(order.settlement_date));

        _this2.for_sale = new Asset({
            amount: order.balance.amount,
            asset_id: order.balance.asset_id,
            precision: assets[order.balance.asset_id].precision
        });

        _this2.inverted = _this2.for_sale.asset_id === market_base;
        _this2.feed_price = feed_price[_this2.inverted ? "invert" : "clone"]();
        return _this2;
    }

    _createClass(SettleOrder, [{
        key: "isBefore",
        value: function isBefore(order) {
            return this.settlement_date < order.settlement_date;
        }
    }, {
        key: "amountForSale",
        value: function amountForSale() {
            return this.for_sale;
        }
    }, {
        key: "amountToReceive",
        value: function amountToReceive() {
            var to_receive = this.for_sale.times(this.feed_price, this.isBid());
            to_receive.setAmount({
                sats: to_receive.getAmount() * ((GRAPHENE_100_PERCENT - this.offset_percent) / GRAPHENE_100_PERCENT)
            });
            return this._to_receive = to_receive;
        }
    }, {
        key: "isBid",
        value: function isBid() {
            return !this.inverted;
        }
    }]);

    return SettleOrder;
}(LimitOrder);

var GroupedOrder = function () {
    function GroupedOrder(order, assets, is_bid) {
        _classCallCheck(this, GroupedOrder);

        if (is_bid === undefined) {
            throw new Error("GroupedOrder requires is_bid");
        }
        this.order = order;
        this.assets = assets;
        this.is_bid = is_bid;
        this.max_price = order.max_price;
        this.min_price = order.min_price;
        this.for_sale = parseInt(order.total_for_sale, 10);
        this._for_sale = null;
        this._to_receive = null;

        var base = this.is_bid ? new Asset({
            asset_id: order.min_price.base.asset_id,
            amount: parseInt(order.min_price.base.amount, 10),
            precision: assets[order.min_price.base.asset_id].precision
        }) : new Asset({
            asset_id: order.max_price.base.asset_id,
            amount: parseInt(order.max_price.base.amount, 10),
            precision: assets[order.max_price.base.asset_id].precision
        });
        var quote = this.is_bid ? new Asset({
            asset_id: order.min_price.quote.asset_id,
            amount: parseInt(order.min_price.quote.amount, 10),
            precision: assets[order.min_price.quote.asset_id].precision
        }) : new Asset({
            asset_id: order.max_price.quote.asset_id,
            amount: parseInt(order.max_price.quote.amount, 10),
            precision: assets[order.max_price.quote.asset_id].precision
        });

        this.sell_price = new Price({
            base: base,
            quote: quote
        });

        this.base = base;
        this.quote = quote;

        this.total_for_sale = this.amountForSale().getAmount({ real: true });
        this.total_to_receive = this.amountToReceive().getAmount({ real: true });
        this._total_for_sale = null;
        this._total_to_receive = null;

        this.market_base = this.is_bid ? this.base.asset_id : this.quote.asset_id;
    }

    _createClass(GroupedOrder, [{
        key: "isBid",
        value: function isBid() {
            return this.is_bid;
        }
    }, {
        key: "sellPrice",
        value: function sellPrice() {
            return this.sell_price;
        }
    }, {
        key: "getPrice",
        value: function getPrice() {
            var p = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.sell_price;

            if (this._real_price) {
                return this._real_price;
            }
            return this._real_price = p.toReal(p.base.asset_id !== this.market_base);
        }
    }, {
        key: "amountForSale",
        value: function amountForSale() {
            if (this._for_sale) return this._for_sale;
            return this._for_sale = new Asset({
                asset_id: this.sell_price.base.asset_id,
                amount: this.for_sale,
                precision: this.assets[this.sell_price.base.asset_id].precision
            });
        }
    }, {
        key: "amountToReceive",
        value: function amountToReceive() {
            var isBid = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.isBid();

            if (this._to_receive) return this._to_receive;
            this._to_receive = this.amountForSale().times(this.sell_price, isBid);
            return this._to_receive;
        }
    }, {
        key: "clone",
        value: function clone() {
            return new GroupedOrder(this.order, this.assets, this.is_bid);
        }

        // sum(order) {
        // }

    }, {
        key: "_clearCache",
        value: function _clearCache() {
            this._for_sale = null;
            this._to_receive = null;
            this._total_to_receive = null;
            this._total_for_sale = null;
            this._real_price = null;
        }
    }, {
        key: "ne",
        value: function ne(order) {
            return this.sell_price.ne(order.sell_price) || this.for_sale !== order.for_sale;
        }
    }, {
        key: "equals",
        value: function equals(order) {
            return !this.ne(order);
        }
    }, {
        key: "setTotalToReceive",
        value: function setTotalToReceive(total) {
            this.total_to_receive = total;
        }
    }, {
        key: "setTotalForSale",
        value: function setTotalForSale(total) {
            this.total_for_sale = total;
            this._total_to_receive = null;
        }
    }, {
        key: "totalToReceive",
        value: function totalToReceive() {
            var _ref12 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
                _ref12$noCache = _ref12.noCache,
                noCache = _ref12$noCache === undefined ? false : _ref12$noCache;

            if (!noCache && this._total_to_receive) return this._total_to_receive;
            this._total_to_receive = (this.total_to_receive || this.amountToReceive()).clone();
            return this._total_to_receive;
        }
    }, {
        key: "totalForSale",
        value: function totalForSale() {
            var _ref13 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
                _ref13$noCache = _ref13.noCache,
                noCache = _ref13$noCache === undefined ? false : _ref13$noCache;

            if (!noCache && this._total_for_sale) return this._total_for_sale;
            this._total_for_sale = (this.total_for_sale || this.amountForSale()).clone();
            return this._total_for_sale;
        }
    }]);

    return GroupedOrder;
}();

var FillOrder = function () {
    function FillOrder(fill, assets, market_base) {
        _classCallCheck(this, FillOrder);

        /* Check if the fill op is from a user history object */
        if ("virtual_op" in fill) {
            fill = {
                id: fill.id,
                op: fill.op[1],
                time: null,
                block: fill.block_num
            };
        }
        if (!market_base) {
            throw new Error("LimitOrder requires a market_base id");
        }
        this.op = fill.op;
        this.assets = assets;
        this.market_base = market_base;
        this.id = fill.id;
        this.order_id = fill.op.order_id;
        this.isCall = utils.is_object_type(fill.op.order_id, "call_order") ? true : false;

        this.isBid = fill.op.receives.asset_id === this.market_base;
        this.className = this.isCall ? "orderHistoryCall" : this.isBid ? "orderHistoryBid" : "orderHistoryAsk";
        this.time = fill.time && new Date(utils.makeISODateString(fill.time));
        this.block = fill.block;
        this.account = fill.op.account || fill.op.account_id;

        this.is_maker = fill.op.is_maker;

        var pays = new Asset({
            asset_id: fill.op.pays.asset_id,
            amount: parseInt(fill.op.pays.amount, 10),
            precision: assets[fill.op.pays.asset_id].precision
        });
        var receives = new Asset({
            asset_id: fill.op.receives.asset_id,
            amount: parseInt(fill.op.receives.amount, 10),
            precision: assets[fill.op.receives.asset_id].precision
        });

        this.pays = this.isBid ? pays : receives;
        this.receives = this.isBid ? receives : pays;

        if (fill.op.fill_price) {
            this.fill_price = new Price({
                base: new Asset({
                    asset_id: fill.op.fill_price.base.asset_id,
                    amount: parseInt(fill.op.fill_price.base.amount, 10),
                    precision: assets[fill.op.fill_price.base.asset_id].precision
                }),
                quote: new Asset({
                    asset_id: fill.op.fill_price.quote.asset_id,
                    amount: parseInt(fill.op.fill_price.quote.amount, 10),
                    precision: assets[fill.op.fill_price.quote.asset_id].precision
                })
            });
        }

        this.fee = new Asset({
            asset_id: fill.op.fee.asset_id,
            amount: parseInt(fill.op.fee.amount, 10),
            precision: assets[fill.op.fee.asset_id].precision
        });
    }

    _createClass(FillOrder, [{
        key: "_calculatePrice",
        value: function _calculatePrice() {
            if (this._cached_price) {
                return this._cached_price;
            } else {
                return this._cached_price = new Price({
                    base: this.pays,
                    quote: this.receives
                }).toReal(false);
            }
        }
    }, {
        key: "getPrice",
        value: function getPrice() {
            if (this.fill_price) {
                return this.fill_price.toReal(this.fill_price.base.asset_id === this.receives.asset_id);
            } else {
                return this._calculatePrice();
            }
        }
    }, {
        key: "amountToReceive",
        value: function amountToReceive() {
            var amount = this.fill_price ? this.pays.times(this.fill_price).getAmount({ real: true }) : this.receives.getAmount({ real: true });

            return utils.format_number(amount, this.receives.precision);
        }
    }, {
        key: "amountToPay",
        value: function amountToPay() {
            return utils.format_number(this.pays.getAmount({ real: true }), this.pays.precision);
        }
    }]);

    return FillOrder;
}();

var CollateralBid = function () {
    function CollateralBid(order, assets, market_base, feed) {
        _classCallCheck(this, CollateralBid);

        if (!order || !assets) {
            throw new Error("Collateral Bid missing inputs");
        }

        this.market_base = market_base;
        this.inverted = market_base === order.inv_swan_price.base.asset_id;

        this.id = order.id;
        this.bidder = order.bidder;
        this.collateral = parseInt(order.inv_swan_price.base.amount, 10);
        this.debt = parseInt(order.inv_swan_price.quote.amount, 10);

        this.bid = new Price({
            base: new Asset({
                asset_id: order.inv_swan_price.base.asset_id,
                amount: parseInt(order.inv_swan_price.base.amount, 10),
                precision: assets[order.inv_swan_price.base.asset_id].precision
            }),
            quote: new Asset({
                asset_id: order.inv_swan_price.quote.asset_id,
                amount: parseInt(order.inv_swan_price.quote.amount, 10),
                precision: assets[order.inv_swan_price.quote.asset_id].precision
            })
        });

        this.precisionsRatio = precisionToRatio(assets[order.inv_swan_price.base.asset_id].precision) / precisionToRatio(assets[order.inv_swan_price.quote.asset_id].precision);

        if (this.inverted) this.bid = this.bid.invert();
        this.feed_price = feed;
    }

    _createClass(CollateralBid, [{
        key: "getFeedPrice",
        value: function getFeedPrice() {
            var f = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.feed_price;

            if (this._feed_price) {
                return this._feed_price;
            }
            return this._feed_price = f.toReal(f.base.asset_id === this.market_base);
        }

        /* Returns satoshi feed price in consistent units of debt/collateral */

    }, {
        key: "_getFeedPrice",
        value: function _getFeedPrice() {
            return (this.inverted ? this.getFeedPrice() : this.feed_price.invert().toReal()) * this.precisionsRatio;
        }
    }, {
        key: "getRatio",
        value: function getRatio() {
            return this.collateral / ( // CORE
            this.debt / // DEBT
            this._getFeedPrice()) / // DEBT/CORE
            100;
        }
    }]);

    return CollateralBid;
}();

export { Asset, Price, FeedPrice, LimitOrderCreate, limitByPrecision, precisionToRatio, LimitOrder, CallOrder, CollateralBid, SettleOrder, didOrdersChange, GroupedOrder, FillOrder };