import React from "react";
import Translate from "react-translate-component";
import FormattedPrice from "../../Utility/FormattedPrice";
import TranslateWithLinks from "../../Utility/TranslateWithLinks";

export var AssetGlobalSettle = function AssetGlobalSettle(_ref) {
    var op = _ref.op,
        changeColor = _ref.changeColor,
        linkToAsset = _ref.linkToAsset,
        fromComponent = _ref.fromComponent;

    changeColor("warning");
    if (fromComponent === "proposed_operation") {
        return React.createElement(
            "span",
            null,
            React.createElement(Translate, {
                component: "span",
                content: "proposal.asset_global_settle"
            }),
            "\xA0",
            linkToAsset(op[1].asset_to_settle),
            "\xA0",
            React.createElement(Translate, { component: "span", content: "proposal.at" }),
            "\xA0",
            React.createElement(FormattedPrice, {
                style: { fontWeight: "bold" },
                quote_amount: op[1].settle_price.quote.amount,
                quote_asset: op[1].settle_price.quote.asset_id,
                base_asset: op[1].settle_price.base.asset_id,
                base_amount: op[1].settle_price.base.amount
            })
        );
    } else {
        return React.createElement(
            "span",
            null,
            React.createElement(TranslateWithLinks, {
                string: "operation.asset_global_settle",
                keys: [{
                    type: "account",
                    value: op[1].issuer,
                    arg: "account"
                }, {
                    type: "asset",
                    value: op[1].asset_to_settle,
                    arg: "asset"
                }, {
                    type: "price",
                    value: op[1].settle_price,
                    arg: "price"
                }]
            })
        );
    }
};