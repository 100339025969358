var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from "react";
import { Link } from "react-router-dom";
import AssetWrapper from "./AssetWrapper";
import AssetName from "./AssetName";
import AssetImage from "../Utility/AssetImage";

var LinkToAssetById = function (_React$Component) {
    _inherits(LinkToAssetById, _React$Component);

    function LinkToAssetById() {
        _classCallCheck(this, LinkToAssetById);

        return _possibleConstructorReturn(this, (LinkToAssetById.__proto__ || Object.getPrototypeOf(LinkToAssetById)).apply(this, arguments));
    }

    _createClass(LinkToAssetById, [{
        key: "render",
        value: function render() {
            var symbol = this.props.asset.get("symbol");
            var assetName = React.createElement(AssetName, { name: symbol, noTip: true });
            var assetImage = React.createElement(AssetImage, { replaceNoneToBts: false, maxWidth: 18, name: symbol });
            return this.props.noLink ? assetName : React.createElement(
                Link,
                { to: "/asset/" + symbol + "/" },
                assetImage,
                " ",
                assetName
            );
        }
    }]);

    return LinkToAssetById;
}(React.Component);

export default AssetWrapper(LinkToAssetById);