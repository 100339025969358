import React from "react";
import counterpart from "counterpart";
import ClipboardButton from "react-clipboard.js";
import Icon from "../Icon/Icon";
import { Tooltip } from "bitshares-ui-style-guide";

var CopyButton = function CopyButton(_ref) {
    var _ref$className = _ref.className,
        className = _ref$className === undefined ? "button" : _ref$className,
        _ref$text = _ref.text,
        text = _ref$text === undefined ? "" : _ref$text,
        _ref$tip = _ref.tip,
        tip = _ref$tip === undefined ? "tooltip.copy_tip" : _ref$tip,
        _ref$dataPlace = _ref.dataPlace,
        dataPlace = _ref$dataPlace === undefined ? "right" : _ref$dataPlace,
        _ref$buttonIcon = _ref.buttonIcon,
        buttonIcon = _ref$buttonIcon === undefined ? "clippy" : _ref$buttonIcon,
        _ref$buttonText = _ref.buttonText,
        buttonText = _ref$buttonText === undefined ? "" : _ref$buttonText;

    return React.createElement(
        Tooltip,
        { placement: dataPlace, title: counterpart.translate(tip) },
        React.createElement(
            "div",
            null,
            React.createElement(
                ClipboardButton,
                {
                    "data-clipboard-text": text,
                    className: className
                },
                !buttonText ? React.createElement(Icon, { name: buttonIcon, title: "icons.clippy.copy" }) : buttonText
            )
        )
    );
};

export default CopyButton;