import React from "react";
import TranslateWithLinks from "../../Utility/TranslateWithLinks";

export var HtlcRedeemed = function HtlcRedeemed(_ref) {
    var op = _ref.op;

    return React.createElement(
        "span",
        { className: "right-td" },
        React.createElement(TranslateWithLinks, {
            string: "operation.htlc_redeemed",
            keys: [{
                type: "account",
                value: op[1].to,
                arg: "to"
            }, {
                type: "account",
                value: op[1].from,
                arg: "from"
            }, {
                type: "amount",
                value: op[1].amount,
                arg: "amount",
                decimalOffset: op[1].amount.asset_id === "1.3.0" ? 5 : null
            }, {
                value: op[1].htlc_id,
                arg: "htlc_id"
            }]
        })
    );
};