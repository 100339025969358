var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from "react";
import { connect } from "alt-react";
import MarketsStore from "../stores/MarketsStore";
import SettingsStore from "../stores/SettingsStore";
import { PRICE_ALERT_TYPES } from "../services/Exchange";
import { Notification, Icon } from "bitshares-ui-style-guide";
import Translate from "react-translate-component";
import counterpart from "counterpart";
import SettingsActions from "../actions/SettingsActions";
import AssetName from "./Utility/AssetName";

var PriceAlertNotifications = function (_React$Component) {
    _inherits(PriceAlertNotifications, _React$Component);

    function PriceAlertNotifications() {
        _classCallCheck(this, PriceAlertNotifications);

        return _possibleConstructorReturn(this, (PriceAlertNotifications.__proto__ || Object.getPrototypeOf(PriceAlertNotifications)).apply(this, arguments));
    }

    _createClass(PriceAlertNotifications, [{
        key: "_getRulesForCheck",
        value: function _getRulesForCheck(priceAlertRules, markets) {
            return priceAlertRules.map(function (rule, key) {
                var pair = rule.quoteAssetSymbol + "_" + rule.baseAssetSymbol;

                var price = null;

                try {
                    var market = markets.get(pair);

                    price = market && market.price && market.price.toReal();
                } catch (e) {
                    console.error("PriceAlertNotifications: Unable to get real price for pair " + pair + ": ", e);
                }

                return {
                    ruleKey: key,
                    type: rule.type,
                    pair: pair,
                    quoteAssetSymbol: rule.quoteAssetSymbol,
                    baseAssetSymbol: rule.baseAssetSymbol,
                    actualPrice: price,
                    expectedPrice: rule.price
                };
            });
        }
    }, {
        key: "_getFulfilledRules",
        value: function _getFulfilledRules(rules) {
            return rules.filter(function (rule) {
                if (isNaN(Number(rule.actualPrice))) return false;

                if (Number(rule.type) === Number(PRICE_ALERT_TYPES.HIGHER_THAN) && Number(rule.actualPrice) >= Number(rule.expectedPrice)) {
                    return true;
                } else if (Number(rule.type) === Number(PRICE_ALERT_TYPES.LOWER_THAN) && Number(rule.actualPrice) <= Number(rule.expectedPrice)) {
                    return true;
                }

                return false;
            });
        }
    }, {
        key: "_filterByFulfilledRules",
        value: function _filterByFulfilledRules(fulfilledRules) {
            return function (rule, key) {
                return !fulfilledRules.some(function (fulfilledRule) {
                    return key === fulfilledRule.ruleKey;
                });
            };
        }
    }, {
        key: "notifyAboutRules",
        value: function notifyAboutRules(rules) {
            // Notification.

            rules.forEach(function (rule) {
                if (Number(rule.type) === Number(PRICE_ALERT_TYPES.LOWER_THAN)) {
                    Notification.info({
                        duration: 30,
                        message: counterpart.translate("exchange.price_alert.title"),
                        description: React.createElement(Translate, {
                            content: "exchange.price_alert.notification.lower_than",
                            component: "div",
                            pair: React.createElement(
                                "span",
                                { className: "price-alert--notification--pair-name" },
                                React.createElement(AssetName, { name: rule.quoteAssetSymbol }),
                                "/",
                                React.createElement(AssetName, { name: rule.baseAssetSymbol })
                            ),
                            expectedPrice: React.createElement(
                                "span",
                                { className: "price-alert--notification--expected-price" },
                                rule.expectedPrice
                            ),
                            actualPrice: React.createElement(
                                "span",
                                { className: "price-alert--notification--actual-price price-alert--notification--actual-price-down" },
                                rule.actualPrice
                            )
                        }),
                        icon: React.createElement(Icon, {
                            type: "caret-down",
                            className: "price-alert--notification--icon price-alert--notification--icon--down"
                        })
                    });
                }

                if (Number(rule.type) === Number(PRICE_ALERT_TYPES.HIGHER_THAN)) {
                    Notification.info({
                        duration: 30,
                        message: counterpart.translate("exchange.price_alert.title"),
                        description: React.createElement(Translate, {
                            content: "exchange.price_alert.notification.higher_than",
                            component: "div",
                            pair: React.createElement(
                                "span",
                                { className: "price-alert--notification--pair-name" },
                                React.createElement(AssetName, { name: rule.quoteAssetSymbol }),
                                "/",
                                React.createElement(AssetName, { name: rule.baseAssetSymbol })
                            ),
                            expectedPrice: React.createElement(
                                "span",
                                { className: "price-alert--notification--expected-price" },
                                rule.expectedPrice
                            ),
                            actualPrice: React.createElement(
                                "span",
                                { className: "price-alert--notification--actual-price price-alert--notification--actual-price-up" },
                                rule.actualPrice
                            )
                        }),
                        icon: React.createElement(Icon, {
                            type: "caret-up",
                            className: "price-alert--notification--icon price-alert--notification--icon--up"
                        })
                    });
                }
            });
        }
    }, {
        key: "render",
        value: function render() {
            var _props = this.props,
                priceAlert = _props.priceAlert,
                allMarketStats = _props.allMarketStats;


            if (!priceAlert || !priceAlert.length || !allMarketStats || !allMarketStats.size) {
                return null;
            }

            var notificationsRules = this._getRulesForCheck(priceAlert, allMarketStats);

            // do notifications for
            var fulfilledRules = this._getFulfilledRules(notificationsRules);

            this.notifyAboutRules(fulfilledRules);

            // update notifications array
            var updatedPriceAlert = this.props.priceAlert.filter(this._filterByFulfilledRules(fulfilledRules));

            if (updatedPriceAlert.length !== this.props.priceAlert.length) {
                SettingsActions.setPriceAlert(updatedPriceAlert);
            }

            return null;
        }
    }]);

    return PriceAlertNotifications;
}(React.Component);

export default connect(PriceAlertNotifications, {
    listenTo: function listenTo() {
        return [MarketsStore, SettingsStore];
    },
    getProps: function getProps() {
        return {
            allMarketStats: MarketsStore.getState().allMarketStats,
            priceAlert: SettingsStore.getState().priceAlert.toJS()
        };
    }
});