var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from "react";
import PropTypes from "prop-types";
import Icon from "../Icon/Icon";
import Translate from "react-translate-component";
import { Tooltip } from "bitshares-ui-style-guide";

var Showcase = function (_Component) {
    _inherits(Showcase, _Component);

    function Showcase() {
        _classCallCheck(this, Showcase);

        return _possibleConstructorReturn(this, (Showcase.__proto__ || Object.getPrototypeOf(Showcase)).call(this));
    }

    _createClass(Showcase, [{
        key: "render",
        value: function render() {
            if (!!this.props.disabled || !!this.props.comingSoon) {
                return React.createElement(
                    Tooltip,
                    {
                        title: typeof this.props.disabled == "string" ? this.props.disabled : "Coming soon"
                    },
                    React.createElement(
                        "div",
                        {
                            className: "showcases-grid--wrapper--item--wrapper--disabled disabled",
                            onClick: function onClick() {},
                            tabIndex: "0"
                        },
                        React.createElement(
                            "h2",
                            { className: "no-margin" },
                            !!this.props.comingSoon && React.createElement(Icon, {
                                style: { float: "right" },
                                name: "coming_soon",
                                size: "4x"
                            }),
                            React.createElement(Translate, { content: this.props.title })
                        ),
                        React.createElement(
                            "div",
                            {
                                className: "showcases-grid--wrapper--item--wrapper--content disabled"
                            },
                            React.createElement(Icon, { name: this.props.icon, size: "5x" }),
                            React.createElement(
                                "span",
                                {
                                    className: "padding showcases-grid--wrapper--item--wrapper--content--description disabled"
                                },
                                React.createElement(Translate, { content: this.props.description })
                            )
                        )
                    )
                );
            } else {
                return React.createElement(
                    "div",
                    {
                        className: "showcases-grid--wrapper--item--wrapper",
                        onClick: this.props.target,
                        tabIndex: "0"
                    },
                    React.createElement(Translate, {
                        content: this.props.title,
                        className: "no-margin",
                        component: "h2"
                    }),
                    React.createElement(
                        "div",
                        {
                            className: "showcases-grid--wrapper--item--wrapper--content"
                        },
                        React.createElement(Icon, { name: this.props.icon, size: "5x" }),
                        React.createElement(
                            "span",
                            {
                                className: "padding showcases-grid--wrapper--item--wrapper--content--description"
                            },
                            React.createElement(Translate, { content: this.props.description })
                        )
                    )
                );
            }
        }
    }]);

    return Showcase;
}(Component);

Showcase.propTypes = {
    target: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    comingSoon: PropTypes.bool
};
Showcase.defaultProps = {
    disabled: false
};
export default Showcase;