var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from "react";
import { FormattedRelative } from "react-intl";
import { ChainStore } from "bitsharesjs";
import PropTypes from "prop-types";
import { Tooltip } from "bitshares-ui-style-guide";

var TimeAgo = function (_React$Component) {
    _inherits(TimeAgo, _React$Component);

    function TimeAgo() {
        _classCallCheck(this, TimeAgo);

        return _possibleConstructorReturn(this, (TimeAgo.__proto__ || Object.getPrototypeOf(TimeAgo)).apply(this, arguments));
    }

    _createClass(TimeAgo, [{
        key: "shouldComponentUpdate",
        value: function shouldComponentUpdate(nextProps) {
            return nextProps.time !== this.props.time;
        }
    }, {
        key: "render",
        value: function render() {
            var _props = this.props,
                time = _props.time,
                chain_time = _props.chain_time;

            var offset_mills = chain_time ? ChainStore.getEstimatedChainTimeOffset() : 0;
            if (!time) {
                return null;
            }
            if (typeof time === "string" && time.indexOf("+") === -1 && !/Z$/.test(time)) {
                time += "Z";
            }

            var timePassed = Math.round((new Date().getTime() - new Date(time).getTime() + offset_mills) / 1000);
            var interval = void 0;

            if (timePassed < 60) {
                // 60s
                interval = 500; // 0.5s
            } else if (timePassed < 60 * 60) {
                // 1 hour
                interval = 60 * 500; // 30 seconds
            } else {
                interval = 60 * 60 * 500; // 30 minutes
            }

            return React.createElement(
                Tooltip,
                { placement: "bottom", title: new Date(time).toString() },
                React.createElement(
                    "span",
                    {
                        className: "tooltip inline-block " + this.props.className,
                        ref: "timeago_ttip_" + time
                    },
                    React.createElement(FormattedRelative, {
                        updateInterval: interval,
                        value: new Date(time).getTime() + offset_mills * 0.75,
                        initialNow: Date.now()
                    })
                )
            );
        }
    }]);

    return TimeAgo;
}(React.Component);

TimeAgo.propTypes = {
    time: PropTypes.any.isRequired,
    chain_time: PropTypes.bool,
    component: PropTypes.element,
    className: PropTypes.string
};
TimeAgo.defaultProps = {
    chain_time: true
};


export default TimeAgo;