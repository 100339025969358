import React from "react";
import Translate from "react-translate-component";
import { ChainStore } from "bitsharesjs";
import FormattedAsset from "../../Utility/FormattedAsset";
import TranslateWithLinks from "../../Utility/TranslateWithLinks";

export var AssetFundFeePool = function AssetFundFeePool(_ref) {
    var op = _ref.op,
        changeColor = _ref.changeColor,
        linkToAccount = _ref.linkToAccount,
        fromComponent = _ref.fromComponent;

    changeColor("warning");
    if (fromComponent === "proposed_operation") {
        var asset = ChainStore.getAsset(op[1].asset_id);
        if (asset) asset = asset.get("symbol");else asset = op[1].asset_id;
        return React.createElement(
            "span",
            null,
            linkToAccount(op[1].from_account),
            " \xA0",
            React.createElement(Translate, {
                component: "span",
                content: "proposal.fund_pool",
                asset: asset
            }),
            "\xA0",
            React.createElement(FormattedAsset, {
                style: { fontWeight: "bold" },
                amount: op[1].amount,
                asset: "1.3.0"
            })
        );
    } else {
        return React.createElement(
            "span",
            null,
            React.createElement(TranslateWithLinks, {
                string: "operation.asset_fund_fee_pool",
                keys: [{
                    type: "account",
                    value: op[1].from_account,
                    arg: "account"
                }, {
                    type: "asset",
                    value: op[1].asset_id,
                    arg: "asset"
                }, {
                    type: "amount",
                    value: {
                        amount: op[1].amount,
                        asset_id: "1.3.0"
                    },
                    arg: "amount"
                }]
            })
        );
    }
};