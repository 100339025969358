var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

import assetConstants from "../chain/asset_constants";
import sanitize from "sanitize";
import utils from "./utils";

var AssetUtils = function () {
    function AssetUtils() {
        _classCallCheck(this, AssetUtils);
    }

    _createClass(AssetUtils, null, [{
        key: "getFlagBooleans",
        value: function getFlagBooleans(mask) {
            var isBitAsset = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

            var booleans = {
                charge_market_fee: false,
                white_list: false,
                override_authority: false,
                transfer_restricted: false,
                disable_force_settle: false,
                global_settle: false,
                disable_confidential: false,
                witness_fed_asset: false,
                committee_fed_asset: false
            };

            if (mask === "all") {
                for (var flag in booleans) {
                    if (!isBitAsset && assetConstants.uia_permission_mask.indexOf(flag) === -1) {
                        delete booleans[flag];
                    } else {
                        booleans[flag] = true;
                    }
                }
                return booleans;
            }

            for (var _flag in booleans) {
                if (!isBitAsset && assetConstants.uia_permission_mask.indexOf(_flag) === -1) {
                    delete booleans[_flag];
                } else {
                    if (mask & assetConstants.permission_flags[_flag]) {
                        booleans[_flag] = true;
                    }
                }
            }

            return booleans;
        }
    }, {
        key: "getFlags",
        value: function getFlags(flagBooleans) {
            var keys = Object.keys(assetConstants.permission_flags);

            var flags = 0;

            keys.forEach(function (key) {
                if (flagBooleans[key] && key !== "global_settle") {
                    flags += assetConstants.permission_flags[key];
                }
            });

            return flags;
        }
    }, {
        key: "getPermissions",
        value: function getPermissions(flagBooleans) {
            var isBitAsset = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

            var permissions = isBitAsset ? Object.keys(assetConstants.permission_flags) : assetConstants.uia_permission_mask;
            var flags = 0;
            permissions.forEach(function (permission) {
                if (flagBooleans[permission] && permission !== "global_settle") {
                    flags += assetConstants.permission_flags[permission];
                }
            });

            if (isBitAsset && flagBooleans["global_settle"]) {
                flags += assetConstants.permission_flags["global_settle"];
            }

            return flags;
        }
    }, {
        key: "parseDescription",
        value: function parseDescription(description) {
            var parsed = void 0;
            description = utils.sanitize(description);
            try {
                parsed = JSON.parse(description);
            } catch (error) {}
            for (var key in parsed) {
                parsed[key] = utils.sanitize(parsed[key]);
            }
            return parsed ? parsed : { main: description };
        }
    }, {
        key: "extractRawFeedPrice",
        value: function extractRawFeedPrice(asset) {
            /**
             * The naming convention is confusing!
             *
             * bitshares-core knows only settlement_price, which is the feed price as known from UI!
             *
             * UI definition:
             *  - Feed Price: Witness fed price, given by backend as settlement_price
             *  - Settlement Price: feed price * force settlement offset factor
             *
             */
            if (!!asset.bitasset) {
                return asset.bitasset.current_feed.settlement_price;
            }
            if (!!asset.current_feed) {
                return asset.current_feed.settlement_price;
            }
            if (!!asset.settlement_price) {
                return asset.settlement_price;
            }
            if (!!asset.get("bitasset")) {
                return asset.getIn(["bitasset", "current_feed", "settlement_price"]);
            }
            if (!!asset.get("settlement_price")) {
                return asset.getIn(["settlement_price"]);
            }
            if (!!asset.get("current_feed")) {
                return asset.getIn(["current_feed", "settlement_price"]);
            }
            throw "Feed price not found!";
        }
    }]);

    return AssetUtils;
}();

export default AssetUtils;