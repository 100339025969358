var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from "react";
import utils from "common/utils";
import { connect } from "alt-react";
import AccountStore from "stores/AccountStore";
import AccountActions from "actions/AccountActions";
import { ChainStore, PublicKey, ChainValidation, FetchChain } from "bitsharesjs";
import ChainTypes from "../Utility/ChainTypes";
import BindToChainState from "../Utility/BindToChainState";
import classnames from "classnames";
import counterpart from "counterpart";
import Icon from "../Icon/Icon";
import accountUtils from "common/account_utils";
import PropTypes from "prop-types";
import { Form, Input, Tooltip } from "bitshares-ui-style-guide";

/**
 * @brief Allows the user to enter an account by name or #ID
 *
 * This component is designed to be stateless as possible.  It's primary responsbility is to
 * manage the layout of data and to filter the user input.
 *
 */

var AccountSelector = function (_React$Component) {
    _inherits(AccountSelector, _React$Component);

    function AccountSelector(props) {
        _classCallCheck(this, AccountSelector);

        var _this = _possibleConstructorReturn(this, (AccountSelector.__proto__ || Object.getPrototypeOf(AccountSelector)).call(this, props));

        _this.state = {
            inputChanged: false
        };
        return _this;
    }

    _createClass(AccountSelector, [{
        key: "componentDidMount",
        value: function componentDidMount() {
            var _props = this.props,
                account = _props.account,
                accountName = _props.accountName;


            if (typeof account === "undefined") account = ChainStore.getAccount(accountName);

            if (this.props.onAccountChanged && account) this.props.onAccountChanged(account);

            if (!this.props.typeahead && accountName) this.onInputChanged(accountName);
        }
    }, {
        key: "componentWillReceiveProps",
        value: function componentWillReceiveProps(newProps) {
            if (newProps.account && newProps.account !== this.props.account) {
                if (this.props.onAccountChanged) this.props.onAccountChanged(newProps.account);
            }
        }

        // can be used in parent component: this.refs.account_selector.getAccount()

    }, {
        key: "getAccount",
        value: function getAccount() {
            return this.props.account;
        }
    }, {
        key: "getError",
        value: function getError() {
            var _props2 = this.props,
                account = _props2.account,
                error = _props2.error;


            if (!error && account && !this.getInputType(account.get("name"))) error = counterpart.translate("account.errors.invalid");

            return error;
        }
    }, {
        key: "getInputType",
        value: function getInputType(value) {
            // OK
            if (!value) return null;
            if (value[0] === "#" && utils.is_object_id("1.2." + value.substring(1))) return "id";
            if (ChainValidation.is_account_name(value, true)) return "name";
            if (this.props.allowPubKey && PublicKey.fromPublicKeyString(value)) return "pubkey";
            return null;
        }
    }, {
        key: "onSelected",
        value: function onSelected(e) {
            this.setState({ inputChanged: false });
            this._notifyOnChange(e);
        }
    }, {
        key: "_notifyOnChange",
        value: function _notifyOnChange(e) {
            var _props3 = this.props,
                onChange = _props3.onChange,
                onAccountChanged = _props3.onAccountChanged,
                accountName = _props3.accountName;


            var _accountName = this.getVerifiedAccountName(e);

            if (_accountName === accountName) {
                // nothing has changed, don't notify
                return;
            }

            // Synchronous onChange for input change
            if (!!onChange && (!!_accountName || _accountName === "")) onChange(_accountName);

            // asynchronous onAccountChanged for checking on chain
            if (!!onAccountChanged) {
                FetchChain("getAccount", _accountName, undefined, _defineProperty({}, _accountName, false)).then(function (_account) {
                    if (!!_account) {
                        onAccountChanged(_account);
                    }
                }).catch(function (err) {
                    // error fetching
                    console.log(err);
                });
            }
        }
    }, {
        key: "onInputChanged",
        value: function onInputChanged(e) {
            this.setState({ inputChanged: true });
            this._notifyOnChange(e);
        }
    }, {
        key: "getVerifiedAccountName",
        value: function getVerifiedAccountName(e) {
            var allowUppercase = this.props.allowUppercase;


            var value = null;
            if (typeof e === "string") {
                value = e;
            } else if (e && e.target) {
                value = e.target.value.trim();
            } else {
                value = "";
            }

            if (!allowUppercase) value = value.toLowerCase();

            // If regex matches ^.*#/account/account-name/.*$, parse out account-name
            var _value = value.replace("#", "").match(/(?:\/account\/)(.*)/);
            if (_value) value = _value[1];

            return value;
        }
    }, {
        key: "onKeyDown",
        value: function onKeyDown(e) {
            if (e.keyCode === 13) this.onAction(e);
        }
    }, {
        key: "_onAddContact",
        value: function _onAddContact() {
            AccountActions.addAccountContact(this.props.accountName);
        }
    }, {
        key: "_onRemoveContact",
        value: function _onRemoveContact() {
            AccountActions.removeAccountContact(this.props.accountName);
        }
    }, {
        key: "onAction",
        value: function onAction(e) {
            var _props4 = this.props,
                onAction = _props4.onAction,
                disableActionButton = _props4.disableActionButton,
                account = _props4.account,
                accountName = _props4.accountName;

            e.preventDefault();
            if (!this.getError() && onAction && !disableActionButton) {
                if (account) onAction(account);else if (this.getInputType(accountName) === "pubkey") onAction(accountName);
            }
        }
    }, {
        key: "render",
        value: function render() {
            var _this2 = this;

            var labelWrapper = function labelWrapper(children) {
                return _this2.props.label ? React.createElement(
                    "div",
                    null,
                    React.createElement(
                        Form.Item,
                        {
                            label: counterpart.translate(_this2.props.label),
                            hasFeedback: true,
                            validateStatus: error ? "error" : account ? "success" : "",
                            help: error ? error : account ? React.createElement(
                                "span",
                                { className: "positive" },
                                account && account.statusText,
                                " ",
                                !!displayText && displayText
                            ) : false
                        },
                        children
                    )
                ) : children;
            };

            var _props5 = this.props,
                accountName = _props5.accountName,
                account = _props5.account,
                allowPubKey = _props5.allowPubKey,
                typeahead = _props5.typeahead,
                disableActionButton = _props5.disableActionButton,
                contacts = _props5.contacts,
                myActiveAccounts = _props5.myActiveAccounts,
                noPlaceHolder = _props5.noPlaceHolder,
                useHR = _props5.useHR,
                labelClass = _props5.labelClass,
                reserveErrorSpace = _props5.reserveErrorSpace;


            var inputType = this.getInputType(accountName);

            var typeAheadAccounts = [];
            var error = this.getError();
            var linkedAccounts = myActiveAccounts;
            linkedAccounts = linkedAccounts.concat(contacts);

            // Selected Account
            var displayText = void 0;
            if (account) {
                account.isKnownScammer = accountUtils.isKnownScammer(account.get("name"));
                account.accountType = this.getInputType(account.get("name"));
                account.accountStatus = ChainStore.getAccountMemberStatus(account);
                account.statusText = !account.isKnownScammer ? counterpart.translate("account.member." + account.accountStatus) : counterpart.translate("account.member.suspected_scammer");
                displayText = account.accountType === "name" ? "#" + account.get("id").substring(4) : account.accountType === "id" ? account.get("name") : null;
            }

            // Without Typeahead Error Handling
            if (!typeahead) {
                if (!account && accountName && inputType !== "pubkey") {
                    error = counterpart.translate("account.errors.unknown");
                }
            } else {
                if (!(allowPubKey && inputType === "pubkey") && !error && accountName && !account) error = counterpart.translate("account.errors.unknown");
            }
            if (allowPubKey && inputType === "pubkey") displayText = "Public Key";

            if (account && linkedAccounts) account.isFavorite = myActiveAccounts.has(account.get("name")) || contacts.has(account.get("name"));

            if (typeahead && linkedAccounts) {
                linkedAccounts.map(function (accountName) {
                    if (_this2.props.excludeAccounts.indexOf(accountName) !== -1) return null;
                    var account = ChainStore.getAccount(accountName);
                    var account_status = ChainStore.getAccountMemberStatus(account);
                    var account_status_text = !accountUtils.isKnownScammer(accountName) ? "account.member." + account_status : "account.member.suspected_scammer";

                    typeAheadAccounts.push({
                        id: accountName,
                        label: accountName,
                        status: counterpart.translate(account_status_text),
                        className: accountUtils.isKnownScammer(accountName) ? "negative" : "positive"
                    });
                }).filter(function (a) {
                    return !!a;
                });
            }

            var typeaheadHasAccount = !!accountName ? typeAheadAccounts.reduce(function (boolean, a) {
                return boolean || a.label === accountName;
            }, false) : false;

            if (!!accountName && !typeaheadHasAccount && this.state.inputChanged) {
                var _account = ChainStore.getAccount(accountName);
                var _account_status = _account ? ChainStore.getAccountMemberStatus(_account) : null;
                var _account_status_text = _account ? !accountUtils.isKnownScammer(_account.get("name")) ? counterpart.translate("account.member." + _account_status) : counterpart.translate("account.member.suspected_scammer") : counterpart.translate("account.errors.unknown");

                typeAheadAccounts.push({
                    id: this.props.accountName,
                    label: this.props.accountName,
                    status: _account_status_text,
                    className: accountUtils.isKnownScammer(accountName) || !_account ? "negative" : null,
                    disabled: !_account ? true : false
                });
            }

            typeAheadAccounts.sort(function (a, b) {
                if (a.label > b.label) return 1;else return -1;
            });

            var linked_status = !this.props.account ? null : myActiveAccounts.has(account.get("name")) || contacts.has(account.get("name")) ? React.createElement(
                Tooltip,
                {
                    placement: "top",
                    title: counterpart.translate("tooltip.follow_user"),
                    onClick: this._onRemoveContact.bind(this)
                },
                React.createElement(
                    "span",
                    { className: "tooltip green" },
                    React.createElement(Icon, {
                        style: {
                            position: "absolute",
                            top: "-0.15em",
                            right: ".2em"
                        },
                        name: "user",
                        title: "icons.user.following"
                    })
                )
            ) : React.createElement(
                Tooltip,
                {
                    placement: "top",
                    title: counterpart.translate("tooltip.follow_user_add"),
                    onClick: this._onAddContact.bind(this)
                },
                React.createElement(
                    "span",
                    { className: "tooltip" },
                    React.createElement(Icon, {
                        style: {
                            position: "absolute",
                            top: "-0.05em",
                            right: ".2em"
                        },
                        name: "plus-circle",
                        title: "icons.plus_circle.add_contact"
                    })
                )
            );

            var action_class = classnames("button", {
                disabled: !(account || inputType === "pubkey") || error || disableActionButton
            });

            return React.createElement(
                "div",
                { className: "account-selector", style: this.props.style },
                React.createElement(
                    "div",
                    { className: "content-area" },
                    labelWrapper(React.createElement(Input, {
                        style: {
                            textTransform: this.getInputType(accountName) === "pubkey" ? null : "lowercase",
                            fontVariant: "initial"
                        },
                        name: "username",
                        id: "username",
                        autoComplete: "username",
                        type: "text",
                        value: this.props.accountName || "",
                        placeholder: this.props.placeholder || counterpart.translate("account.name"),
                        ref: this.props.inputRef || "user_input",
                        onChange: this.onInputChanged.bind(this),
                        onKeyDown: this.onKeyDown.bind(this),
                        tabIndex: this.props.tabIndex
                    }))
                )
            );
        }
    }]);

    return AccountSelector;
}(React.Component);

AccountSelector.propTypes = {
    label: PropTypes.string, // a translation key for the label
    error: PropTypes.element, // the error message override
    placeholder: PropTypes.string, // the placeholder text to be displayed when there is no user_input
    onChange: PropTypes.func, // a method to be called any time user input changes
    onAccountChanged: PropTypes.func, // a method to be called when existing account is selected
    onAction: PropTypes.func, // a method called when Add button is clicked
    accountName: PropTypes.string, // the current value of the account selector, the string the user enters
    account: ChainTypes.ChainAccount, // account object retrieved via BindToChainState decorator (not input)
    tabIndex: PropTypes.number, // tabindex property to be passed to input tag
    disableActionButton: PropTypes.bool, // use it if you need to disable action button,
    allowUppercase: PropTypes.bool, // use it if you need to allow uppercase letters
    typeahead: PropTypes.bool,
    excludeAccounts: PropTypes.array // array of accounts to exclude from the typeahead
};
AccountSelector.defaultProps = {
    autosubscribe: false,
    excludeAccounts: []
};


AccountSelector = BindToChainState(AccountSelector);

AccountSelector = connect(AccountSelector, {
    listenTo: function listenTo() {
        return [AccountStore];
    },
    getProps: function getProps() {
        return {
            myActiveAccounts: AccountStore.getState().myActiveAccounts,
            contacts: AccountStore.getState().accountContacts
        };
    }
});

export default AccountSelector;