var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import alt from "alt-instance";
import Immutable from "immutable";
import BaseStore from "stores/BaseStore";
import { key } from "bitsharesjs";
import { Apis } from "bitsharesjs-ws";
import iDB from "idb-instance";
import BalanceClaimActiveActions from "actions/BalanceClaimActiveActions";
import TransactionConfirmActions from "actions/TransactionConfirmActions";

var BalanceClaimActiveStore = function (_BaseStore) {
    _inherits(BalanceClaimActiveStore, _BaseStore);

    function BalanceClaimActiveStore() {
        _classCallCheck(this, BalanceClaimActiveStore);

        var _this = _possibleConstructorReturn(this, (BalanceClaimActiveStore.__proto__ || Object.getPrototypeOf(BalanceClaimActiveStore)).call(this));

        _this.state = _this._getInitialState();
        _this.no_balance_address = new Set(); // per chain
        _this._export("reset");
        // ChainStore.subscribe(this.chainStoreUpdate.bind(this))
        _this.bindListeners({
            onSetPubkeys: BalanceClaimActiveActions.setPubkeys,
            onSetSelectedBalanceClaims: BalanceClaimActiveActions.setSelectedBalanceClaims,
            onClaimAccountChange: BalanceClaimActiveActions.claimAccountChange,
            onTransactionBroadcasted: TransactionConfirmActions.wasBroadcast
        });
        return _this;
    }

    _createClass(BalanceClaimActiveStore, [{
        key: "_getInitialState",
        value: function _getInitialState() {
            // reset for each wallet
            this.pubkeys = null;
            this.addresses = new Set();
            var state = this.getInitialViewState();
            state.address_to_pubkey = new Map();
            return state;
        }
    }, {
        key: "getInitialViewState",
        value: function getInitialViewState() {
            // reset in-between balance claims
            return {
                balances: undefined,
                checked: Immutable.Map(),
                selected_balances: Immutable.Seq(),
                claim_account_name: undefined,
                loading: true
            };
        }

        /** Reset for each wallet load or change */

    }, {
        key: "reset",
        value: function reset() {
            this.setState(this._getInitialState());
        }

        // onImportBalance() {
        //     // Imorted balance just ran, not included in the blockchain yet
        //     this.setState(this.getInitialViewState())
        // }

    }, {
        key: "onTransactionBroadcasted",
        value: function onTransactionBroadcasted() {
            // Balance claims are included in a block...
            // chainStoreUpdate did not include removal of balance claim objects
            // This is a hack to refresh balance claims after a transaction.
            this.refreshBalances();
        }

        // chainStoreUpdate did not include removal of balance claim objects
        // chainStoreUpdate() {
        //     if(this.balance_objects_by_address !== ChainStore.balance_objects_by_address) {
        //         console.log("ChainStore.balance_objects_by_address")
        //         this.balance_objects_by_address = ChainStore.balance_objects_by_address
        //     }
        // }

        // param: Immutable Seq or array

    }, {
        key: "onSetPubkeys",
        value: function onSetPubkeys(pubkeys) {
            var _this2 = this;

            if (Array.isArray(pubkeys)) pubkeys = Immutable.Seq(pubkeys);
            if (this.pubkeys && this.pubkeys.equals(pubkeys)) return;
            this.reset();
            this.pubkeys = pubkeys;
            if (pubkeys.size === 0) {
                this.setState({ loading: false });
                return true;
            }
            this.setState({ loading: true });
            this.loadNoBalanceAddresses().then(function () {
                // for(let pubkey of pubkeys) {
                _this2.indexPubkeys(pubkeys);
                // }

                _this2.refreshBalances();
                return false;
            }).catch(function (error) {
                return console.error(error);
            });
        }
    }, {
        key: "onSetSelectedBalanceClaims",
        value: function onSetSelectedBalanceClaims(checked) {
            var selected_balances = checked.valueSeq().flatten().toSet();
            this.setState({ checked: checked, selected_balances: selected_balances });
        }
    }, {
        key: "onClaimAccountChange",
        value: function onClaimAccountChange(claim_account_name) {
            this.setState({ claim_account_name: claim_account_name });
        }
    }, {
        key: "loadNoBalanceAddresses",
        value: function loadNoBalanceAddresses() {
            var _this3 = this;

            if (this.no_balance_address.size) return Promise.resolve();
            return iDB.root.getProperty("no_balance_address", []).then(function (array) {
                // console.log("loadNoBalanceAddresses", array.length)
                _this3.no_balance_address = new Set(array);
            });
        }
    }, {
        key: "indexPubkeys",
        value: function indexPubkeys(pubkeys) {
            var address_to_pubkey = this.state.address_to_pubkey;
            var _iteratorNormalCompletion = true;
            var _didIteratorError = false;
            var _iteratorError = undefined;

            try {

                for (var _iterator = pubkeys[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                    var pubkey = _step.value;
                    var _iteratorNormalCompletion2 = true;
                    var _didIteratorError2 = false;
                    var _iteratorError2 = undefined;

                    try {
                        for (var _iterator2 = key.addresses(pubkey)[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
                            var address_string = _step2.value;

                            if (!this.no_balance_address.has(address_string)) {
                                // AddressIndex indexes all addresses .. Here only 1 address is involved
                                address_to_pubkey.set(address_string, pubkey);
                                this.addresses.add(address_string);
                            }
                        }
                    } catch (err) {
                        _didIteratorError2 = true;
                        _iteratorError2 = err;
                    } finally {
                        try {
                            if (!_iteratorNormalCompletion2 && _iterator2.return) {
                                _iterator2.return();
                            }
                        } finally {
                            if (_didIteratorError2) {
                                throw _iteratorError2;
                            }
                        }
                    }
                }
            } catch (err) {
                _didIteratorError = true;
                _iteratorError = err;
            } finally {
                try {
                    if (!_iteratorNormalCompletion && _iterator.return) {
                        _iterator.return();
                    }
                } finally {
                    if (_didIteratorError) {
                        throw _iteratorError;
                    }
                }
            }

            this.setState({ address_to_pubkey: address_to_pubkey });
        }
    }, {
        key: "indexPubkey",
        value: function indexPubkey(pubkey) {
            var _iteratorNormalCompletion3 = true;
            var _didIteratorError3 = false;
            var _iteratorError3 = undefined;

            try {
                for (var _iterator3 = key.addresses(pubkey)[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
                    var address_string = _step3.value;

                    if (!this.no_balance_address.has(address_string)) {
                        // AddressIndex indexes all addresses .. Here only 1 address is involved
                        this.state.address_to_pubkey.set(address_string, pubkey);
                        this.addresses.add(address_string);
                    }
                }
            } catch (err) {
                _didIteratorError3 = true;
                _iteratorError3 = err;
            } finally {
                try {
                    if (!_iteratorNormalCompletion3 && _iterator3.return) {
                        _iterator3.return();
                    }
                } finally {
                    if (_didIteratorError3) {
                        throw _iteratorError3;
                    }
                }
            }

            this.setState({ address_to_pubkey: this.state.address_to_pubkey });
        }
    }, {
        key: "refreshBalances",
        value: function refreshBalances() {
            var _this4 = this;

            this.lookupBalanceObjects().then(function (balances) {
                var state = _this4.getInitialViewState();
                state.balances = balances;
                state.loading = false;
                _this4.setState(state);
            });
        }

        /** @return Promise.resolve(balances) */

    }, {
        key: "lookupBalanceObjects",
        value: function lookupBalanceObjects() {
            var _this5 = this;

            var db = Apis.instance().db_api();
            var no_balance_address = new Set(this.no_balance_address);
            var no_bal_size = no_balance_address.size;
            var _iteratorNormalCompletion4 = true;
            var _didIteratorError4 = false;
            var _iteratorError4 = undefined;

            try {
                for (var _iterator4 = this.addresses[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
                    var addy = _step4.value;
                    no_balance_address.add(addy);
                } // for(let addy of this.addresses) ChainStore.getBalanceObjects(addy) // Test with ChainStore
            } catch (err) {
                _didIteratorError4 = true;
                _iteratorError4 = err;
            } finally {
                try {
                    if (!_iteratorNormalCompletion4 && _iterator4.return) {
                        _iterator4.return();
                    }
                } finally {
                    if (_didIteratorError4) {
                        throw _iteratorError4;
                    }
                }
            }

            return db.exec("get_balance_objects", [Array.from(this.addresses)]).then(function (result) {
                var balance_ids = [];
                var _iteratorNormalCompletion5 = true;
                var _didIteratorError5 = false;
                var _iteratorError5 = undefined;

                try {
                    for (var _iterator5 = result[Symbol.iterator](), _step5; !(_iteratorNormalCompletion5 = (_step5 = _iterator5.next()).done); _iteratorNormalCompletion5 = true) {
                        var balance = _step5.value;
                        balance_ids.push(balance.id);
                    }
                } catch (err) {
                    _didIteratorError5 = true;
                    _iteratorError5 = err;
                } finally {
                    try {
                        if (!_iteratorNormalCompletion5 && _iterator5.return) {
                            _iterator5.return();
                        }
                    } finally {
                        if (_didIteratorError5) {
                            throw _iteratorError5;
                        }
                    }
                }

                return db.exec("get_vested_balances", [balance_ids]).then(function (vested_balances) {
                    var balances = Immutable.List().withMutations(function (balance_list) {
                        for (var i = 0; i < result.length; i++) {
                            var balance = result[i];
                            no_balance_address.delete(balance.owner);
                            if (balance.vesting_policy) balance.vested_balance = vested_balances[i];
                            balance_list.push(balance);
                        }
                        if (no_bal_size !== no_balance_address.size) _this5.saveNoBalanceAddresses(no_balance_address).catch(function (error) {
                            return console.error(error);
                        });
                    });
                    return balances;
                });
            });
        }
    }, {
        key: "saveNoBalanceAddresses",
        value: function saveNoBalanceAddresses(no_balance_address) {
            this.no_balance_address = no_balance_address;
            var array = [];
            var _iteratorNormalCompletion6 = true;
            var _didIteratorError6 = false;
            var _iteratorError6 = undefined;

            try {
                for (var _iterator6 = this.no_balance_address[Symbol.iterator](), _step6; !(_iteratorNormalCompletion6 = (_step6 = _iterator6.next()).done); _iteratorNormalCompletion6 = true) {
                    var addy = _step6.value;
                    array.push(addy);
                } // console.log("saveNoBalanceAddresses", array.length)
            } catch (err) {
                _didIteratorError6 = true;
                _iteratorError6 = err;
            } finally {
                try {
                    if (!_iteratorNormalCompletion6 && _iterator6.return) {
                        _iterator6.return();
                    }
                } finally {
                    if (_didIteratorError6) {
                        throw _iteratorError6;
                    }
                }
            }

            return iDB.root.setProperty("no_balance_address", array);
        }
    }]);

    return BalanceClaimActiveStore;
}(BaseStore);

export var BalanceClaimActiveStoreWrapped = alt.createStore(BalanceClaimActiveStore, "BalanceClaimActiveStore");
export default BalanceClaimActiveStoreWrapped;