import React from "react";
import Translate from "react-translate-component";
import TranslateWithLinks from "../../Utility/TranslateWithLinks";

export var AccountUpgrade = function AccountUpgrade(_ref) {
    var op = _ref.op,
        linkToAccount = _ref.linkToAccount,
        fromComponent = _ref.fromComponent;

    if (fromComponent === "proposed_operation") {
        if (op[1].upgrade_to_lifetime_member) {
            return React.createElement(
                "span",
                null,
                linkToAccount(op[1].account_to_upgrade),
                " \xA0",
                React.createElement(Translate, {
                    component: "span",
                    content: "proposal.lifetime_upgrade_account"
                })
            );
        } else {
            return React.createElement(
                "span",
                null,
                linkToAccount(op[1].account_to_upgrade),
                " \xA0",
                React.createElement(Translate, {
                    component: "span",
                    content: "proposal.annual_upgrade_account"
                })
            );
        }
    } else {
        return React.createElement(
            "span",
            null,
            React.createElement(TranslateWithLinks, {
                string: op[1].upgrade_to_lifetime_member ? "operation.lifetime_upgrade_account" : "operation.annual_upgrade_account",
                keys: [{
                    type: "account",
                    value: op[1].account_to_upgrade,
                    arg: "account"
                }]
            })
        );
    }
};