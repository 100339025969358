var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from "react";
import PropTypes from "prop-types";
import cname from "classnames";
import { hash, key } from "bitsharesjs";
import { Input } from "bitshares-ui-style-guide";

var dictionary_set = void 0;

if (__ELECTRON__) {
    dictionary_set = new Set(require("common/dictionary_en.json").en.split(","));
}

var BrainkeyInput = function (_Component) {
    _inherits(BrainkeyInput, _Component);

    function BrainkeyInput() {
        _classCallCheck(this, BrainkeyInput);

        var _this = _possibleConstructorReturn(this, (BrainkeyInput.__proto__ || Object.getPrototypeOf(BrainkeyInput)).call(this));

        _this.state = {
            brnkey: "",
            loading: true
        };
        return _this;
    }

    _createClass(BrainkeyInput, [{
        key: "componentWillMount",
        value: function componentWillMount() {
            var _this2 = this;

            if (!__ELECTRON__) {
                fetch(__BASE_URL__ + "dictionary.json").then(function (reply) {
                    return reply.json().then(function (result) {
                        dictionary_set = new Set(result.en.split(","));
                        _this2.setState({
                            loading: false
                        });
                    });
                }).catch(function (err) {
                    console.log("fetch dictionary error:", err);
                });
            } else {
                this.setState({
                    loading: false
                });
            }
        }
    }, {
        key: "_checkBrainKey",
        value: function _checkBrainKey() {
            var spellcheck_words = this.state.brnkey.split(" ");
            var checked_words = [];
            spellcheck_words.forEach(function (word, i) {
                if (word === "") return;
                var spellcheckword = word.toLowerCase();
                spellcheckword = spellcheckword.match(/[a-z]+/); //just spellcheck letters
                if (spellcheckword === null || dictionary_set.has(spellcheckword[0])) checked_words.push(React.createElement(
                    "span",
                    { key: i, style: { padding: "1px", margin: "1px" } },
                    word
                ));else checked_words.push(React.createElement(
                    MisspelledWord,
                    { key: i },
                    word
                ));
            });
            // this.ready = checked_words.length > 0
            var word_count_label = void 0;
            var warn = true;
            var valid = true;
            if (checked_words.length > 0) {
                if (this.state.brnkey.length < 50) {
                    word_count_label = this.state.brnkey.length + " characters (50 minimum)";
                    valid = false;
                } else {
                    if (checked_words.length < 16) word_count_label = checked_words.length + " words (16 recommended)";else {
                        word_count_label = checked_words.length + " words";
                        warn = false;
                    }
                }
            }

            return { warn: warn, valid: valid, word_count_label: word_count_label, checked_words: checked_words };
        }
    }, {
        key: "render",
        value: function render() {
            if (this.state.loading || !dictionary_set) {
                return React.createElement(
                    "div",
                    { style: { padding: 20 } },
                    "Fetching dictionary...."
                );
            }

            var _checkBrainKey2 = this._checkBrainKey(),
                warn = _checkBrainKey2.warn,
                word_count_label = _checkBrainKey2.word_count_label,
                checked_words = _checkBrainKey2.checked_words;

            return React.createElement(
                "span",
                { className: "" },
                React.createElement(
                    "div",
                    null,
                    React.createElement(Input.TextArea, {
                        tabIndex: this.props.tabIndex || 1,
                        onChange: this.formChange.bind(this),
                        value: this.state.brnkey,
                        id: "brnkey",
                        style: { height: 100, minWidth: 450 }
                    }),
                    React.createElement(
                        "div",
                        {
                            style: { textAlign: "left" },
                            className: "grid-content no-padding no-overflow"
                        },
                        checked_words
                    ),
                    this.state.check_digits && !this.props.hideCheckDigits ? React.createElement(
                        "div",
                        null,
                        React.createElement("br", null),
                        React.createElement(
                            "pre",
                            { className: "no-overflow" },
                            this.state.check_digits,
                            " * Check Digits"
                        ),
                        React.createElement("br", null)
                    ) : null,
                    React.createElement(
                        "p",
                        null,
                        React.createElement(
                            "i",
                            { className: cname({ error: warn }) },
                            word_count_label
                        )
                    )
                )
            );
        }
    }, {
        key: "formChange",
        value: function formChange(event) {
            var _event$target = event.target,
                id = _event$target.id,
                value = _event$target.value;

            var _checkBrainKey3 = this._checkBrainKey(),
                valid = _checkBrainKey3.valid;

            var state = {};
            state[id] = value;
            if (id === "brnkey") {
                var brnkey = key.normalize_brainKey(value);
                this.props.onChange(brnkey.length < 50 ? null : brnkey);
                state.check_digits = brnkey.length < 50 ? null : hash.sha1(brnkey).toString("hex").substring(0, 4);
            }
            this.setState(state);

            if (this.props.errorCallback) {
                this.props.errorCallback(valid);
            }
        }
    }]);

    return BrainkeyInput;
}(Component);

BrainkeyInput.propTypes = {
    onChange: PropTypes.func.isRequired
};
export default BrainkeyInput;

var MisspelledWord = function (_Component2) {
    _inherits(MisspelledWord, _Component2);

    function MisspelledWord() {
        _classCallCheck(this, MisspelledWord);

        return _possibleConstructorReturn(this, (MisspelledWord.__proto__ || Object.getPrototypeOf(MisspelledWord)).apply(this, arguments));
    }

    _createClass(MisspelledWord, [{
        key: "render",
        value: function render() {
            return React.createElement(
                "span",
                {
                    style: {
                        borderBottom: "1px dotted #ff0000",
                        padding: "1px",
                        margin: "1px"
                    }
                },
                React.createElement(
                    "span",
                    { style: { borderBottom: "1px dotted #ff0000" } },
                    this.props.children
                )
            );
        }
    }]);

    return MisspelledWord;
}(Component);