import React from "react";
import Translate from "react-translate-component";
import TranslateWithLinks from "../../Utility/TranslateWithLinks";

export var AccountCreate = function AccountCreate(_ref) {
    var op = _ref.op,
        current = _ref.current,
        linkToAccount = _ref.linkToAccount,
        fromComponent = _ref.fromComponent;

    if (fromComponent === "proposed_operation") {
        if (current === op[1].registrar) {
            return React.createElement(
                "span",
                null,
                React.createElement(Translate, {
                    component: "span",
                    content: "proposal.reg_account"
                }),
                "\xA0",
                linkToAccount(op[1].name)
            );
        } else {
            return React.createElement(
                "span",
                null,
                linkToAccount(op[1].name),
                "\xA0",
                React.createElement(Translate, {
                    component: "span",
                    content: "proposal.was_reg_account"
                }),
                "\xA0",
                linkToAccount(op[1].registrar)
            );
        }
    } else {
        return React.createElement(TranslateWithLinks, {
            string: "operation.reg_account",
            keys: [{
                type: "account",
                value: op[1].registrar,
                arg: "registrar"
            }, {
                type: "account",
                value: op[1].name,
                arg: "new_account"
            }]
        });
    }
};