var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from "react";
import { FormattedNumber } from "react-intl";
import utils from "common/utils";
import assetUtils from "common/asset_utils";
import PropTypes from "prop-types";
import { Popover } from "bitshares-ui-style-guide";
import HelpContent from "./HelpContent";
import AssetName from "./AssetName";
import Pulsate from "./Pulsate";
import { ChainStore } from "bitsharesjs";
import AssetWrapper from "./AssetWrapper";
import BindToChainState from "./BindToChainState";
import ChainTypes from "./ChainTypes";

/**
 *  Given an amount and an asset, render it with proper precision
 *
 *  Expected Properties:
 *     asset:  asset id, which will be fetched from the
 *     amount: the ammount of asset
 *
 */

var SupplyPercentage = function (_React$Component) {
    _inherits(SupplyPercentage, _React$Component);

    function SupplyPercentage() {
        _classCallCheck(this, SupplyPercentage);

        return _possibleConstructorReturn(this, (SupplyPercentage.__proto__ || Object.getPrototypeOf(SupplyPercentage)).apply(this, arguments));
    }

    _createClass(SupplyPercentage, [{
        key: "render",
        value: function render() {
            var supply = parseInt(this.props.do.get("current_supply"), 10);
            var percent = utils.format_number(this.props.amount / supply * 100, 4);
            return React.createElement(
                "span",
                { className: this.props.colorClass },
                percent,
                "%"
            );
        }
    }]);

    return SupplyPercentage;
}(React.Component);

SupplyPercentage.propTypes = {
    do: ChainTypes.ChainObject.isRequired
};

SupplyPercentage = BindToChainState(SupplyPercentage);

var FormattedAsset = function (_React$Component2) {
    _inherits(FormattedAsset, _React$Component2);

    function FormattedAsset(props) {
        _classCallCheck(this, FormattedAsset);

        var _this2 = _possibleConstructorReturn(this, (FormattedAsset.__proto__ || Object.getPrototypeOf(FormattedAsset)).call(this, props));

        _this2.state = { isPopoverOpen: false };
        _this2.togglePopover = _this2.togglePopover.bind(_this2);
        _this2.closePopover = _this2.closePopover.bind(_this2);
        return _this2;
    }

    _createClass(FormattedAsset, [{
        key: "togglePopover",
        value: function togglePopover(e) {
            e.preventDefault();
            this.setState({ isPopoverOpen: !this.state.isPopoverOpen });
        }
    }, {
        key: "closePopover",
        value: function closePopover() {
            this.setState({ isPopoverOpen: false });
        }
    }, {
        key: "render",
        value: function render() {
            var _props = this.props,
                amount = _props.amount,
                decimalOffset = _props.decimalOffset,
                color = _props.color,
                asset = _props.asset,
                hide_asset = _props.hide_asset,
                hide_amount = _props.hide_amount,
                asPercentage = _props.asPercentage,
                pulsate = _props.pulsate;


            if (amount === undefined || amount == null) return null; // still loading

            if (asset && asset.toJS) asset = asset.toJS();

            var colorClass = color ? "facolor-" + color : "";

            var precision = utils.get_asset_precision(asset.precision);

            var decimals = Math.max(0, asset.precision - decimalOffset);
            if (hide_amount) {
                colorClass += " no-amount";
            }

            if (asPercentage) {
                return React.createElement(SupplyPercentage, {
                    amount: amount,
                    colorClass: colorClass,
                    "do": asset.dynamic_asset_data_id
                });
            }

            var issuer = ChainStore.getObject(asset.issuer, false, false);
            var issuerName = issuer ? issuer.get("name") : "";

            var description = assetUtils.parseDescription(asset.options.description);

            var currency_popover_body = !hide_asset && this.props.assetInfo && React.createElement(
                "div",
                null,
                React.createElement(HelpContent, {
                    path: "assets/Asset",
                    section: "summary",
                    symbol: asset.symbol,
                    description: description.short_name ? description.short_name : description.main,
                    issuer: issuerName
                }),
                this.props.assetInfo
            );

            var formattedValue = null;
            if (!hide_amount) {
                var value = this.props.exact_amount ? amount : amount / precision;
                formattedValue = React.createElement(FormattedNumber, {
                    value: value,
                    minimumFractionDigits: Math.max(decimals, 0),
                    maximumFractionDigits: Math.max(decimals, 0)
                });

                if (pulsate) {
                    if ((typeof pulsate === "undefined" ? "undefined" : _typeof(pulsate)) !== "object") pulsate = {};
                    formattedValue = React.createElement(
                        Pulsate,
                        _extends({ value: value }, pulsate),
                        formattedValue
                    );
                }
            }
            return React.createElement(
                "span",
                { className: colorClass },
                formattedValue,
                !hide_asset && (this.props.assetInfo ? React.createElement(
                    "span",
                    null,
                    "\xA0",
                    React.createElement(
                        Popover,
                        {
                            trigger: "click",
                            content: currency_popover_body,
                            mouseEnterDelay: 0.5
                        },
                        React.createElement(
                            "span",
                            { className: "currency click-for-help" },
                            React.createElement(AssetName, { name: asset.symbol })
                        )
                    )
                ) : React.createElement(
                    "span",
                    { className: "currency" },
                    !hide_amount ? React.createElement(
                        "span",
                        null,
                        "\xA0"
                    ) : null,
                    React.createElement(AssetName, {
                        noTip: this.props.noTip,
                        noPrefix: this.props.noPrefix,
                        name: asset.symbol,
                        replace: this.props.replace
                    })
                ))
            );
        }
    }]);

    return FormattedAsset;
}(React.Component);

FormattedAsset.propTypes = {
    amount: PropTypes.any, // amount could be undefined or null when component is loading
    exact_amount: PropTypes.bool,
    decimalOffset: PropTypes.number,
    color: PropTypes.string,
    hide_asset: PropTypes.bool,
    hide_amount: PropTypes.bool,
    asPercentage: PropTypes.bool,
    assetInfo: PropTypes.node
};
FormattedAsset.defaultProps = {
    amount: null,
    decimalOffset: 0,
    hide_asset: false,
    hide_amount: false,
    asPercentage: false,
    assetInfo: null,
    replace: true
};

FormattedAsset = AssetWrapper(FormattedAsset);

export default FormattedAsset;