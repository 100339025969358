var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from "react";
import PropTypes from "prop-types";

var LoadingIndicator = function (_React$Component) {
    _inherits(LoadingIndicator, _React$Component);

    function LoadingIndicator(props) {
        _classCallCheck(this, LoadingIndicator);

        var _this = _possibleConstructorReturn(this, (LoadingIndicator.__proto__ || Object.getPrototypeOf(LoadingIndicator)).call(this, props));

        _this.state = { progress: 0 };
        return _this;
    }

    _createClass(LoadingIndicator, [{
        key: "render",
        value: function render() {
            switch (this.props.type) {
                case "three-bounce":
                    return React.createElement(
                        "div",
                        { className: "three-bounce" },
                        React.createElement("div", { className: "bounce1" }),
                        React.createElement("div", { className: "bounce2" }),
                        React.createElement("div", { className: "bounce3" })
                    );
                    break;
                case "circle":
                    return React.createElement(
                        "div",
                        { className: "circle-wrapper" },
                        React.createElement("div", { className: "circle1 circle" }),
                        React.createElement("div", { className: "circle2 circle" }),
                        React.createElement("div", { className: "circle3 circle" }),
                        React.createElement("div", { className: "circle4 circle" }),
                        React.createElement("div", { className: "circle5 circle" }),
                        React.createElement("div", { className: "circle6 circle" }),
                        React.createElement("div", { className: "circle7 circle" }),
                        React.createElement("div", { className: "circle8 circle" }),
                        React.createElement("div", { className: "circle9 circle" }),
                        React.createElement("div", { className: "circle10 circle" }),
                        React.createElement("div", { className: "circle11 circle" }),
                        React.createElement("div", { className: "circle12 circle" })
                    );
                    break;
                case "circle-small":
                    return React.createElement(
                        "div",
                        {
                            className: "circle-wrapper",
                            style: { height: "15px", minHeight: "15px" }
                        },
                        React.createElement("div", { className: "circle1 circle" }),
                        React.createElement("div", { className: "circle2 circle" }),
                        React.createElement("div", { className: "circle3 circle" }),
                        React.createElement("div", { className: "circle4 circle" }),
                        React.createElement("div", { className: "circle5 circle" }),
                        React.createElement("div", { className: "circle6 circle" }),
                        React.createElement("div", { className: "circle7 circle" }),
                        React.createElement("div", { className: "circle8 circle" }),
                        React.createElement("div", { className: "circle9 circle" }),
                        React.createElement("div", { className: "circle10 circle" }),
                        React.createElement("div", { className: "circle11 circle" }),
                        React.createElement("div", { className: "circle12 circle" })
                    );
                    break;
                default:
                    var classes = "loading-overlay";
                    if (this.progress > 0) {
                        classes += " with-progress";
                    }
                    return React.createElement(
                        "div",
                        { className: classes },
                        React.createElement(
                            "div",
                            { className: "loading-panel" },
                            this.props.loadingText && React.createElement(
                                "div",
                                {
                                    className: "text-center",
                                    style: { paddingTop: "10px", color: "black" }
                                },
                                this.props.loadingText
                            ),
                            React.createElement(
                                "div",
                                { className: "spinner" },
                                React.createElement("div", { className: "bounce1" }),
                                React.createElement("div", { className: "bounce2" }),
                                React.createElement("div", { className: "bounce3" })
                            ),
                            React.createElement(
                                "div",
                                { className: "progress-indicator" },
                                React.createElement(
                                    "span",
                                    null,
                                    this.state.progress
                                )
                            )
                        )
                    );
            }
        }
    }]);

    return LoadingIndicator;
}(React.Component);

LoadingIndicator.propTypes = {
    type: PropTypes.string,
    loadingText: PropTypes.string
};
LoadingIndicator.defaultProps = {
    type: null,
    loadingText: null
};


export default LoadingIndicator;