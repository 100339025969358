var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

import alt from "alt-instance";
import { Apis } from "bitsharesjs-ws";

var latestBlocks = {};

var headerQueue = {};

var BlockchainActions = function () {
    function BlockchainActions() {
        _classCallCheck(this, BlockchainActions);
    }

    _createClass(BlockchainActions, [{
        key: "getHeader",
        value: function getHeader(height) {
            if (headerQueue[height]) return {};
            headerQueue[height] = true;
            return function (dispatch) {
                return Apis.instance().db_api().exec("get_block_header", [height]).then(function (header) {
                    dispatch({
                        header: {
                            timestamp: header.timestamp,
                            witness: header.witness
                        },
                        height: height
                    });
                });
            };
        }
    }, {
        key: "getLatest",
        value: function getLatest(height, maxBlock) {
            // let start = new Date();
            return function (dispatch) {
                if (!latestBlocks[height] && maxBlock) {
                    latestBlocks[height] = true;
                    Apis.instance().db_api().exec("get_block", [height]).then(function (result) {
                        if (!result) {
                            return;
                        }
                        result.id = height; // The returned object for some reason does not include the block height..
                        // console.log("time to fetch block #" + height,":", new Date() - start, "ms");

                        dispatch({ block: result, maxBlock: maxBlock });
                    }).catch(function (error) {
                        console.log("Error in BlockchainActions.getLatest: ", error);
                    });
                }
            };
        }
    }, {
        key: "getBlock",
        value: function getBlock(height) {
            return function (dispatch) {
                Apis.instance().db_api().exec("get_block", [height]).then(function (result) {
                    if (!result) {
                        return false;
                    }
                    result.id = height; // The returned object for some reason does not include the block height..

                    dispatch(result);
                }).catch(function (error) {
                    console.log("Error in BlockchainActions.getBlock: ", error);
                });
            };
        }
    }, {
        key: "updateRpcConnectionStatus",
        value: function updateRpcConnectionStatus(status) {
            return status;
        }
    }]);

    return BlockchainActions;
}();

var BlockchainActionsInstance = alt.createActions(BlockchainActions);
Apis.setRpcConnectionStatusCallback(BlockchainActionsInstance.updateRpcConnectionStatus);

export default BlockchainActionsInstance;