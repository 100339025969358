var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from "react";
import { TreeSelect } from "antd";
import { settingsAPIs, nodeRegions } from "api/apiConfig";
import PropTypes from "prop-types";
import { connect } from "alt-react";
import SettingsStore from "../../stores/SettingsStore";
import SettingsActions from "../../actions/SettingsActions";
import counterpart from "counterpart";
import { Icon as AntIcon, Icon, Tooltip } from "bitshares-ui-style-guide";

var SHOW_PARENT = TreeSelect.SHOW_PARENT;

var NodeSelector = function (_React$Component) {
    _inherits(NodeSelector, _React$Component);

    _createClass(NodeSelector, [{
        key: "_getTreeData",
        value: function _getTreeData() {
            var nodesPerRegion = {};
            settingsAPIs.WS_NODE_LIST.forEach(function (item) {
                if (item.url.indexOf("fake.automatic-selection") !== -1) {
                    return;
                }
                var region = item.region || "Unknown";
                if (item.url.indexOf("127.0.0.1") !== -1) {
                    region = " Localhost";
                }
                if (item.url.indexOf("testnet") !== -1) {
                    region = " " + region;
                }
                if (!nodesPerRegion[region]) {
                    nodesPerRegion[region] = {
                        title: region,
                        key: region,
                        value: region,
                        children: []
                    };
                }
                nodesPerRegion[region].children.push({
                    title: item.url,
                    key: item.url,
                    value: item.url,
                    item: item
                });
            });
            var sortedList = Object.values(nodesPerRegion).sort(function (a, b) {
                var aIdx = nodeRegions.indexOf(a.title);
                var bIdx = nodeRegions.indexOf(b.title);
                if (aIdx !== -1 && bIdx !== -1) {
                    return aIdx > bIdx ? 1 : bIdx > aIdx ? -1 : 0;
                }
                if (aIdx !== -1) {
                    return -1;
                }
                if (bIdx !== -1) {
                    return 1;
                }
                return a.title > b.title ? -1 : b.title > a.title ? 1 : 0;
            });
            sortedList.forEach(function (region) {
                region.title = region.title + " (" + region.children.length + ")";
            });
            return sortedList;
        }
    }]);

    function NodeSelector(props) {
        _classCallCheck(this, NodeSelector);

        var _this = _possibleConstructorReturn(this, (NodeSelector.__proto__ || Object.getPrototypeOf(NodeSelector)).call(this, props));

        _this.state = {
            treeData: _this._getTreeData()
        };

        _this.onChange = function (value) {
            SettingsActions.changeSetting({
                setting: "filteredApiServers",
                value: value
            });
            if (!!_this.props.onChange) {
                _this.props.onChange(value);
            }
        };

        return _this;
    }

    _createClass(NodeSelector, [{
        key: "componentDidMount",
        value: function componentDidMount() {}
    }, {
        key: "render",
        value: function render() {
            var tProps = {
                treeData: this.state.treeData,
                value: this.props.filteredApiServers,
                onChange: this.onChange,
                treeCheckable: true,
                showCheckedStrategy: SHOW_PARENT,
                searchPlaceholder: counterpart.translate("connection.narrow_down_nodes"), // narrow_down_nodes_tooltip
                size: this.props.size,
                style: {
                    width: "88%"
                },
                key: "nodeSelector",
                getPopupContainer: function getPopupContainer(node) {
                    return document.getElementById("node-selector--drop-down");
                }
            };
            return React.createElement(
                React.Fragment,
                null,
                React.createElement(
                    "div",
                    {
                        style: {
                            width: "100%",
                            minWidth: "250px"
                        }
                    },
                    React.createElement(TreeSelect, _extends({}, tProps, {
                        dropdownPopupAlign: {
                            points: ["tl", "bl"],
                            offset: [0, 4],
                            overflow: false
                        }
                    })),
                    React.createElement(
                        Tooltip,
                        {
                            title: counterpart.translate("connection.narrow_down_nodes_tooltip")
                        },
                        React.createElement(Icon, {
                            style: {
                                fontSize: "1.3rem",
                                marginLeft: "0.5rem",
                                marginTop: "0.3rem"
                            },
                            type: "question-circle"
                        })
                    ),
                    React.createElement("div", {
                        id: "node-selector--drop-down",
                        className: "node-selector--drop-down"
                    })
                )
            );
        }
    }]);

    return NodeSelector;
}(React.Component);

NodeSelector.propTypes = {
    onChange: PropTypes.func,
    size: PropTypes.string
};
NodeSelector.defaultProps = {
    onChange: null,
    size: undefined
};

NodeSelector = connect(NodeSelector, {
    listenTo: function listenTo() {
        return [SettingsStore];
    },
    getProps: function getProps() {
        return {
            filteredApiServers: SettingsStore.getState().settings.get("filteredApiServers", [])
        };
    }
});
export default NodeSelector;