var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

import { Apis } from "bitsharesjs-ws";
import GatewayActions from "actions/GatewayActions";
import availableGateways, { gatewayPrefixes } from "common/gateways";
import counterpart from "counterpart";

export function getGatewayName(asset) {
    if (asset.get("issuer") === "1.2.0") {
        return counterpart.translate("exchange.native");
    }

    var prefix = asset.get("symbol") === "PPY" ? "RUDEX" : asset.get("symbol").split(".")[0];

    var assetName = asset.get("symbol") === "PPY" ? "RUDEX.PPY" : asset.get("symbol");

    if (hasGatewayPrefix(assetName)) {
        return availableGateways[prefix].name;
    }
    return null;
}

export function hasGatewayPrefix(name) {
    var prefix = "";
    if (name === "PPY") {
        prefix = "RUDEX";
    } else {
        prefix = name.split(".")[0];
    }

    if (gatewayPrefixes.indexOf(prefix) !== -1) {
        return true;
    }
    return false;
}

export function getGatewayStatusByAsset(selectedAsset) {
    var _this = this;

    var boolCheck = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "depositAllowed";
    var gatewayStatus = this.state.gatewayStatus;
    //console.log("gatewayStatus", gatewayStatus);

    var _loop = function _loop(g) {
        gatewayStatus[g].options.enabled = false;
        _this.props.backedCoins.get(g.toUpperCase(), []).find(function (coin) {
            var backingCoin = coin.backingCoinType || coin.backingCoin;
            var isAvailable = typeof coin.isAvailable == "undefined" || typeof coin.isAvailable == "boolean" && coin.isAvailable;

            // Gateway has EOS.* asset names
            if (backingCoin.toUpperCase().indexOf("EOS.") !== -1) {
                var _backingCoin$split = backingCoin.split("."),
                    _backingCoin$split2 = _slicedToArray(_backingCoin$split, 2),
                    _network = _backingCoin$split2[0],
                    _coin = _backingCoin$split2[1];

                backingCoin = _coin;
            }

            if (coin[boolCheck] && isAvailable && selectedAsset === backingCoin && (g === "XBTSX" || g === "BNB" || g === "WAVES" || g === "ETH" || g === "HECO" || g === "BSC")) {
                gatewayStatus[g].options.enabled = true;
            }
        });
    };

    for (var g in gatewayStatus) {
        _loop(g);
    }
    return gatewayStatus;
}

export function getIntermediateAccount(symbol, backedCoins) {
    var _getAssetAndGateway = getAssetAndGateway(symbol),
        selectedGateway = _getAssetAndGateway.selectedGateway;

    var coin = getBackedCoin(symbol, backedCoins);
    if (!coin) return undefined;
    //else if (selectedGateway === "RUDEX") return coin.issuerId || coin.issuer;
    else if (selectedGateway === "XBTSX") return coin.issuerId || coin.issuer;
        //else if (selectedGateway === "BNB") return coin.issuerId || coin.issuer;
        //else if (selectedGateway === "WAVES") return coin.issuerId || coin.issuer;
        else return coin.intermediateAccount || coin.issuer;
}

export function getBackedCoin(symbol, backedCoins) {
    var _getAssetAndGateway2 = getAssetAndGateway(symbol),
        selectedGateway = _getAssetAndGateway2.selectedGateway;

    return backedCoins.get(selectedGateway, []).find(function (c) {
        return c.symbol.toUpperCase() === symbol.toUpperCase();
    }) || {};
}

export function getAssetAndGateway(symbol) {
    var _symbol$split = symbol.split("."),
        _symbol$split2 = _slicedToArray(_symbol$split, 2),
        selectedGateway = _symbol$split2[0],
        selectedAsset = _symbol$split2[1];

    return { selectedGateway: selectedGateway, selectedAsset: selectedAsset };
}

export function updateGatewayBackers() {
    var chain = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "4018d784";

    // Only fetch this when on desired chain, default to main chain
    if (!Apis.instance().chain_id) return;
    if (Apis.instance().chain_id.substr(0, 8) === chain) {
        // bridge bt
        GatewayActions.fetchPairs.defer();

        // Walk all Gateways
        for (var gateway in availableGateways) {
            if (!!availableGateways[gateway].isEnabled) {
                if (!!availableGateways[gateway].isSimple) {
                    GatewayActions.fetchCoinsSimple.defer({
                        backer: availableGateways[gateway].id,
                        url: availableGateways[gateway].baseAPI.BASE + availableGateways[gateway].baseAPI.COINS_LIST
                    });
                } else {
                    GatewayActions.fetchCoins.defer({
                        backer: availableGateways[gateway].id,
                        url: availableGateways[gateway].baseAPI.BASE + availableGateways[gateway].baseAPI.COINS_LIST,
                        urlBridge: availableGateways[gateway].baseAPI.BASE + availableGateways[gateway].baseAPI.TRADING_PAIRS,
                        urlWallets: availableGateways[gateway].baseAPI.BASE + availableGateways[gateway].baseAPI.ACTIVE_WALLETS
                    });
                }
            }
        }
    }
}