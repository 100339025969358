import React from "react";
import TranslateWithLinks from "../../Utility/TranslateWithLinks";

export var OverrideTransfer = function OverrideTransfer(_ref) {
    var op = _ref.op,
        fromComponent = _ref.fromComponent;

    return React.createElement(TranslateWithLinks, {
        string: fromComponent === "proposed_operation" ? "proposal.override_transfer" : "operation.override_transfer",
        keys: [{
            type: "account",
            value: op[1].issuer,
            arg: "issuer"
        }, { type: "account", value: op[1].from, arg: "from" }, { type: "account", value: op[1].to, arg: "to" }, { type: "amount", value: op[1].amount, arg: "amount" }]
    });
};