import _zipObject from "lodash-es/zipObject";

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from "react";

import counterpart from "counterpart";
import utils from "common/utils";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import sanitize from "sanitize";

var req = require.context("../../help", true, /\.md/);
var HelpData = {};

function endsWith(str, suffix) {
    return str.indexOf(suffix, str.length - suffix.length) !== -1;
}

function split_into_sections(str) {
    var sections = str.split(/\[#\s?(.+?)\s?\]/);
    if (sections.length === 1) return sections[0];
    if (sections[0].length < 4) sections.splice(0, 1);

    for (var i = sections.length - 1; i >= 1; i -= 2) {
        // remove extra </p> and <p>
        sections[i] = sections[i].replace(/(^<\/p>|<p>$)/g, "");
        sections[i - 1] = [sections[i - 1], sections[i]];
        sections.splice(i, 1);
    }

    return _zipObject(sections);
}

function adjust_links(str) {
    return str.replace(/\<a\shref\=\"(.+?)\"/gi, function (match, text) {
        text = utils.sanitize(text);

        if (text.indexOf((__HASH_HISTORY__ ? "#" : "") + "/") === 0) return "<a href=\"" + text + "\" onclick=\"_onClickLink(event)\"";
        if (text.indexOf("http") === 0) return "<a href=\"" + text + "\" rel=\"noopener noreferrer\" class=\"external-link\" target=\"_blank\"";
        var page = endsWith(text, ".md") ? text.substr(0, text.length - 3) : text;
        if (!page.startsWith("/help")) {
            page = "/help/" + page;
        } else if (page.startsWith("help")) {
            page = "/" + page;
        }
        return "<a href=\"" + (__HASH_HISTORY__ ? "#" : "") + page + "\" onclick=\"_onClickLink(event)\"";
    });
}

// console.log("-- HelpData -->", HelpData);

var HelpContent = function (_React$Component) {
    _inherits(HelpContent, _React$Component);

    function HelpContent(props) {
        _classCallCheck(this, HelpContent);

        var _this = _possibleConstructorReturn(this, (HelpContent.__proto__ || Object.getPrototypeOf(HelpContent)).call(this, props));

        window._onClickLink = _this.onClickLink.bind(_this);
        return _this;
    }

    _createClass(HelpContent, [{
        key: "componentWillMount",
        value: function componentWillMount() {
            var locale = this.props.locale || counterpart.getLocale() || "en";

            // Only load helpData for the current locale as well as the fallback 'en'
            req.keys().filter(function (a) {
                return a.indexOf("/" + locale + "/") !== -1 || a.indexOf("/en/") !== -1;
            }).forEach(function (filename) {
                var res = filename.match(/\/(.+?)\/(.+)\./);
                var locale = res[1];
                var key = res[2];
                var help_locale = HelpData[locale];
                if (!help_locale) HelpData[locale] = help_locale = {};
                var content = req(filename);
                help_locale[key] = split_into_sections(adjust_links(content));
            });
        }
    }, {
        key: "onClickLink",
        value: function onClickLink(e) {
            e.preventDefault();
            var path = (__HASH_HISTORY__ ? e.target.hash : e.target.pathname).split("/").filter(function (p) {
                return p && p !== "#";
            });
            if (path.length === 0) return false;
            var route = "/" + path.join("/");
            this.props.history.push(route);
            return false;
        }
    }, {
        key: "setVars",
        value: function setVars(str, hideIssuer) {
            var _this2 = this;

            if (hideIssuer == "true") {
                str = str.replace(/<p>[^<]*{issuer}[^<]*<\/p>/gm, "");
            }

            return str.replace(/(\{.+?\})/gi, function (match, text) {
                var key = text.substr(1, text.length - 2);
                var value = _this2.props[key] !== undefined ? _this2.props[key] : text;
                if (value && typeof value === "string") value = utils.sanitize(value);
                if (value.amount && value.asset) value = utils.format_asset(value.amount, value.asset, false, false);
                if (value.date) value = utils.format_date(value.date);
                if (value.time) value = utils.format_time(value.time);

                return value;
            });
        }
    }, {
        key: "render",
        value: function render() {
            var locale = this.props.locale || counterpart.getLocale() || "en";
            if (!HelpData[locale]) {
                console.error("missing locale '" + locale + "' help files, rolling back to 'en'");
                locale = "en";
            }

            var value = HelpData[locale][this.props.path];

            if (!value && locale !== "en") {
                console.warn("missing path '" + this.props.path + "' for locale '" + locale + "' help files, rolling back to 'en'");
                value = HelpData["en"][this.props.path];
            }

            if (!value && this.props.alt_path) {
                console.warn("missing path '" + this.props.path + "' for locale '" + locale + "' help files, rolling back to alt_path '" + this.props.alt_path + "'");
                value = HelpData[locale][this.props.alt_path];
            }

            if (!value && this.props.alt_path && locale != "en") {
                console.warn("missing alt_path '" + this.props.alt_path + "' for locale '" + locale + "' help files, rolling back to 'en'");
                value = HelpData["en"][this.props.alt_path];
            }

            if (!value) {
                console.error("help file not found '" + this.props.path + "' for locale '" + locale + "'");
                return !null;
            }

            if (this.props.section) {
                /* The previously used remarkable-loader parsed the md properly as an object, the new one does not */
                for (var key in value) {
                    if (!!key.match(this.props.section)) {
                        value = key.replace(new RegExp("^" + this.props.section + ","), "");
                        break;
                    }
                }
            }

            if (!value) {
                console.error("help section not found " + this.props.path + "#" + this.props.section);
                return null;
            }

            if ((typeof value === "undefined" ? "undefined" : _typeof(value)) === "object") {
                console.error("help section content invalid " + this.props.path + "#" + this.props.section);
                return null;
            }

            return React.createElement("div", {
                style: this.props.style,
                className: "help-content",
                dangerouslySetInnerHTML: {
                    __html: this.setVars(value, this.props.hide_issuer)
                }
            });
        }
    }]);

    return HelpContent;
}(React.Component);

HelpContent.propTypes = {
    path: PropTypes.string.isRequired,
    section: PropTypes.string
};
HelpContent.defaultProps = {
    hide_issuer: "false"
};


export default withRouter(HelpContent);