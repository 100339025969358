import React from "react";
import Translate from "react-translate-component";
import FormattedAsset from "../../Utility/FormattedAsset";

export var WitnessWithdrawPay = function WitnessWithdrawPay(_ref) {
    var op = _ref.op,
        current = _ref.current,
        linkToAccount = _ref.linkToAccount,
        fromComponent = _ref.fromComponent;

    if (fromComponent === "proposed_operation") {
        if (current === op[1].witness_account) {
            return React.createElement(
                "span",
                null,
                React.createElement(Translate, {
                    component: "span",
                    content: "proposal.witness_pay"
                }),
                "\xA0",
                React.createElement(FormattedAsset, {
                    style: { fontWeight: "bold" },
                    amount: op[1].amount,
                    asset: "1.3.0"
                }),
                React.createElement(Translate, { component: "span", content: "proposal.to" }),
                "\xA0",
                linkToAccount(op[1].witness_account)
            );
        } else {
            return React.createElement(
                "span",
                null,
                React.createElement(Translate, { component: "span", content: "proposal.received" }),
                "\xA0",
                React.createElement(FormattedAsset, {
                    style: { fontWeight: "bold" },
                    amount: op[1].amount,
                    asset: "1.3.0"
                }),
                React.createElement(Translate, { component: "span", content: "proposal.from" }),
                "\xA0",
                linkToAccount(op[1].witness_account)
            );
        }
    } else {
        if (current === op[1].witness_account) {
            return React.createElement(
                "span",
                null,
                React.createElement(Translate, {
                    component: "span",
                    content: "transaction.witness_pay"
                }),
                "\xA0",
                React.createElement(FormattedAsset, { amount: op[1].amount, asset: "1.3.0" }),
                React.createElement(Translate, { component: "span", content: "transaction.to" }),
                "\xA0",
                linkToAccount(op[1].witness_account)
            );
        } else {
            return React.createElement(
                "span",
                null,
                React.createElement(Translate, {
                    component: "span",
                    content: "transaction.received"
                }),
                "\xA0",
                React.createElement(FormattedAsset, { amount: op[1].amount, asset: "1.3.0" }),
                React.createElement(Translate, { component: "span", content: "transaction.from" }),
                "\xA0",
                linkToAccount(op[1].witness_account)
            );
        }
    }
};