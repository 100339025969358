import React from "react";
import AltContainer from "alt-container";
import counterpart from "counterpart";
import { Form, Select } from "bitshares-ui-style-guide";
import WalletUnlockStore from "stores/WalletUnlockStore";
import SettingsActions from "actions/SettingsActions";

var LoginTypeSelectorView = function LoginTypeSelectorView(_ref) {
    var value = _ref.value,
        onChange = _ref.onChange;
    return React.createElement(
        Form.Item,
        { label: counterpart.translate("account.login_with") },
        React.createElement(
            Select,
            { onChange: onChange, value: value },
            React.createElement(
                Select.Option,
                { value: "cloud" },
                counterpart.translate("account.name"),
                "(",
                counterpart.translate("wallet.password_model").toLowerCase(),
                ")"
            ),
            React.createElement(
                Select.Option,
                { value: "local" },
                counterpart.translate("wallet.key_file"),
                " (",
                counterpart.translate("wallet.wallet_model").toLowerCase(),
                ")"
            )
        )
    );
};

var LoginTypeSelector = function LoginTypeSelector(props) {
    return React.createElement(
        AltContainer,
        {
            stores: [WalletUnlockStore],
            inject: {
                value: function value() {
                    return WalletUnlockStore.getState().passwordLogin ? "cloud" : "local";
                }
            },
            actions: function actions() {
                return {
                    onChange: function onChange(value) {
                        var newType = value;
                        var validValues = ["cloud", "local"];
                        if (!newType in validValues) throw new Error("Invalid login type value");
                        return SettingsActions.changeSetting({
                            setting: "passwordLogin",
                            value: newType === "cloud"
                        });
                    }
                };
            }
        },
        React.createElement(LoginTypeSelectorView, props)
    );
};

export default LoginTypeSelector;