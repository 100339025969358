var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from "react";
import utils from "common/utils";
import asset_utils from "common/asset_utils";
import AssetWrapper from "./AssetWrapper";
import counterpart from "counterpart";
import PropTypes from "prop-types";
import { Popover } from "bitshares-ui-style-guide";
import { ChainStore, FetchChainObjects } from "bitsharesjs";

var AssetName = function (_React$Component) {
    _inherits(AssetName, _React$Component);

    function AssetName(props) {
        _classCallCheck(this, AssetName);

        var _this = _possibleConstructorReturn(this, (AssetName.__proto__ || Object.getPrototypeOf(AssetName)).call(this, props));

        _this.state = {
            assetIssuerName: ChainStore.getAccountName(props.asset.get("issuer"))
        };
        _this._load();
        return _this;
    }

    _createClass(AssetName, [{
        key: "shouldComponentUpdate",
        value: function shouldComponentUpdate(np, ns) {
            return this.props.replace !== np.replace || this.props.asset !== np.asset || this.props.noPrefix !== np.noPrefix || this.props.noTip !== np.noTip || this.props.dataPlace !== np.dataPlace || this.state.assetIssuerName !== ns.assetIssuerName;
        }
    }, {
        key: "_load",
        value: function _load() {
            var _this2 = this;

            // cache asset issuer name
            if (!this.state.assetIssuerName && this.props.asset && this.props.asset.get) {
                FetchChainObjects(ChainStore.getAccountName, [this.props.asset.get("issuer")]).then(function (result) {
                    // re-render, ChainStore cache now has the object
                    if (_this2._isMounted) {
                        // re-render, ChainStore cache now has the object
                        _this2.setState({ assetIssuerName: result[0] });
                    }
                });
            }
        }
    }, {
        key: "componentDidUpdate",
        value: function componentDidUpdate() {
            this._load();
        }
    }, {
        key: "render",
        value: function render() {
            var _props = this.props,
                replace = _props.replace,
                asset = _props.asset,
                noPrefix = _props.noPrefix,
                customClass = _props.customClass,
                noTip = _props.noTip;

            if (!asset) return null;
            var name = asset.get("symbol");
            var assetIssuerName = this.state.assetIssuerName;
            var isBitAsset = asset.has("bitasset");
            var isPredMarket = isBitAsset && asset.getIn(["bitasset", "is_prediction_market"]);

            var _utils$replaceName = utils.replaceName(asset),
                replacedName = _utils$replaceName.name,
                prefix = _utils$replaceName.prefix;

            var hasBitPrefix = prefix === "bit";

            var includeBitAssetDescription = isBitAsset && !isPredMarket && hasBitPrefix;

            if (replace && replacedName !== name || isBitAsset) {
                var desc = asset_utils.parseDescription(asset.getIn(["options", "description"]));

                var realPrefix = name.split(".");
                realPrefix = realPrefix.length > 1 ? realPrefix[0] : null;
                if (realPrefix) realPrefix += ".";
                var optional = "";

                try {
                    optional = realPrefix || includeBitAssetDescription ? counterpart.translate("gateway.assets." + (hasBitPrefix ? "bit" : realPrefix.replace(".", "").toLowerCase()), {
                        asset: name,
                        backed: includeBitAssetDescription ? desc.main : replacedName
                    }) : "";
                } catch (e) {}
                if (isBitAsset && name === "CNY") {
                    optional = optional + " " + counterpart.translate("gateway.assets.bitcny");
                }

                var upperCasePrefix = prefix && prefix === "bit" ? prefix : !!prefix ? prefix.toUpperCase() : prefix;
                var assetDiv = React.createElement(
                    "div",
                    {
                        className: "inline-block" + (this.props.noTip ? "" : " tooltip") + (customClass ? " " + customClass : "")
                    },
                    React.createElement(
                        "span",
                        { className: "asset-prefix-replaced" },
                        prefix
                    ),
                    React.createElement(
                        "span",
                        null,
                        replacedName
                    )
                );
                if (!!noTip) {
                    return assetDiv;
                } else {
                    var title = (upperCasePrefix || "") + replacedName.toUpperCase();
                    var popoverContent = React.createElement(
                        "div",
                        { style: { maxWidth: "25rem" } },
                        desc.short ? desc.short : desc.main || "",
                        optional !== "" && React.createElement("br", null),
                        optional !== "" && React.createElement("br", null),
                        optional,
                        React.createElement("br", null),
                        React.createElement("br", null),
                        assetIssuerName && counterpart.translate("explorer.assets.issuer") + ": " + assetIssuerName
                    );
                    return React.createElement(
                        Popover,
                        {
                            placement: this.props.dataPlace,
                            content: popoverContent,
                            title: title,
                            mouseEnterDelay: 0.5
                        },
                        assetDiv
                    );
                }
            } else {
                var _assetDiv = React.createElement(
                    "span",
                    { className: customClass ? customClass : null },
                    React.createElement(
                        "span",
                        { className: !noPrefix ? "asset-prefix-replaced" : "" },
                        !noPrefix ? prefix : null
                    ),
                    React.createElement(
                        "span",
                        null,
                        replacedName
                    )
                );
                if (!!noTip) {
                    return _assetDiv;
                } else {
                    var _desc = null;
                    if (replacedName == "BTS") {
                        _desc = { main: counterpart.translate("assets.BTS") };
                    } else {
                        _desc = asset_utils.parseDescription(asset.getIn(["options", "description"]));
                    }
                    var _title = (prefix || "") + replacedName.toUpperCase();
                    var _popoverContent = React.createElement(
                        "div",
                        { style: { maxWidth: "25rem" } },
                        _desc.short ? _desc.short : _desc.main || "",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        assetIssuerName && counterpart.translate("explorer.assets.issuer") + ": " + assetIssuerName
                    );
                    return React.createElement(
                        Popover,
                        {
                            placement: this.props.dataPlace,
                            content: _popoverContent,
                            title: _title,
                            mouseEnterDelay: 0.5
                        },
                        _assetDiv
                    );
                }
            }
        }
    }]);

    return AssetName;
}(React.Component);

AssetName.propTypes = {
    replace: PropTypes.bool.isRequired,
    dataPlace: PropTypes.string.isRequired
};
AssetName.defaultProps = {
    replace: true,
    noPrefix: false,
    noTip: false,
    dataPlace: "bottom"
};


AssetName = AssetWrapper(AssetName);

var AssetNameWrapper = function (_React$Component2) {
    _inherits(AssetNameWrapper, _React$Component2);

    function AssetNameWrapper() {
        _classCallCheck(this, AssetNameWrapper);

        return _possibleConstructorReturn(this, (AssetNameWrapper.__proto__ || Object.getPrototypeOf(AssetNameWrapper)).apply(this, arguments));
    }

    _createClass(AssetNameWrapper, [{
        key: "render",
        value: function render() {
            return React.createElement(AssetName, _extends({}, this.props, { asset: this.props.name }));
        }
    }]);

    return AssetNameWrapper;
}(React.Component);

export default AssetNameWrapper;