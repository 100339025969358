var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

import alt from "alt-instance";

import WalletDb from "stores/WalletDb";
import { TransactionBuilder } from "bitsharesjs/es";

var BCO_ASSET_ID = "1.3.4099";

var CryptoBridgeActions = function () {
    function CryptoBridgeActions() {
        _classCallCheck(this, CryptoBridgeActions);
    }

    _createClass(CryptoBridgeActions, [{
        key: "stakeBalance",
        value: function stakeBalance(account, period, amount) {
            var tr = new TransactionBuilder();

            tr.add_type_operation("vesting_balance_create", {
                fee: { amount: "0", asset_id: BCO_ASSET_ID },
                creator: account,
                owner: account,
                amount: {
                    amount: amount * Math.pow(10, 6),
                    asset_id: BCO_ASSET_ID
                },
                policy: [1, {
                    start_claim: new Date().toISOString().slice(0, 19),
                    vesting_seconds: period
                }]
            });

            return WalletDb.process_transaction(tr, null, true).then(function (result) {}).catch(function (err) {
                console.log("vesting_balance_create err:", err);
            });
        }
    }, {
        key: "claimStakingBalance",
        value: function claimStakingBalance(account, cvb) {
            var tr = new TransactionBuilder();

            var balance = cvb.balance.amount;

            tr.add_type_operation("vesting_balance_withdraw", {
                fee: { amount: "0", asset_id: BCO_ASSET_ID },
                owner: account,
                vesting_balance: cvb.id,
                amount: {
                    amount: Math.floor(balance),
                    asset_id: cvb.balance.asset_id
                }
            });

            return WalletDb.process_transaction(tr, null, true).then(function (result) {}).catch(function (err) {
                console.log("vesting_balance_withdraw err:", err);
            });
        }
    }]);

    return CryptoBridgeActions;
}();

export default alt.createActions(CryptoBridgeActions);